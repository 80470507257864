<div *ngIf="!failedAuth && !invalidUrl && !cookieNotFound; else failed" class="sk-container">
  <div class="sk-circle sk-center">
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
    <div class="sk-circle-dot"></div>
  </div>
</div>
<ng-template #failed>
  <div class="auth-error-container">
    <div class="card">
      <div class="card-body">
        <i class="ion ion-md-warning text-danger mb-2" style="font-size: 64px;"></i>
        <span *ngIf="failedAuth">
          CtrlFill was unable to log you in, please try to log in again.
        </span>
        <span *ngIf="invalidUrl">
          For security concerns, this login URL has expired. Please request a new one.
        </span>
        <span *ngIf="cookieNotFound">
          An authentication cookie was blocked, ensure you are using an up-to-date browser.
        </span>
        <span>
          We are sorry for this inconvenience!
        </span>
        <span>
          <button type="button" class="btn btn-primary my-2" (click)="login()">Log in</button>
        </span>
      </div>
    </div>
  </div>
</ng-template>