import { Component, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-special-request',
  templateUrl: './view-special-request.component.html',
  styleUrls: ['./view-special-request.component.scss']
})
export class ViewSpecialRequestComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
      "TYPE": new FormControl({value: '', disabled: true}),
      "FULL_ADDRESS": new FormControl({value: '', disabled: true}),
      "YARDSIGN_COMMENTS": new FormControl({value: '', disabled: true}),
      "WRITTEN_REQUEST": new FormControl({value: '', disabled: true})
    });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';

      console.log(formId, this.form.get(formId).value);

      newDiv.innerHTML = this.form.get(formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }

}
