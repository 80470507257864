<ng-container *ngIf="response; else loading">
  <div class="modal-header">
     <h5 class="modal-title d-flex flex-column">
        <span>{{ formName }} - {{ response.data.FULL_ADDRESS }}</span>
        <small class="text-muted">{{ response.user.email || 'N/A'}} - updated {{ response.timeUpdated | amTimeAgo }}</small>
     </h5>
     <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
     <div class="printable-form" [formGroup]="form">
      
      <div id="Requests">

        <div class="form-group">
          <label class="form-label text-danger">Request Type*</label>
          <ng-select
            formControlName="TYPE"
            [multiple]="false">
            <ng-option value="YARDSIGN">Yard Sign</ng-option>
            <ng-option value="OTHER">Other</ng-option>
          </ng-select>
        </div>

        <hr>

        <ng-container *ngIf="form.get('TYPE').value == 'YARDSIGN'">
          <div class="form-group">
            <label class="form-label text-danger">Listing Address*</label>
            <input 
              type="text"
              class="form-control"
              placeholder="123 Example Avenue"
              formControlName="FULL_ADDRESS">
          </div>
          <div class="form-group">
            <label class="form-label">Comments/Additional Details</label>
            <textarea
              class="form-control"
              rows="4"
              formControlName="YARDSIGN_COMMENTS"></textarea>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('TYPE').value == 'OTHER'">
          <div class="form-group">
            <label class="form-label text-danger">Written Request*</label>
            <textarea
              class="form-control"
              rows="4"
              formControlName="WRITTEN_REQUEST"></textarea>
          </div>
        </ng-container>


      </div>

     </div>
  </div>
  <div class="modal-footer">
    <!--
   <button type="button" class="btn btn-primary" (click)="download()" [disabled]="isDownloading">
      <span *ngIf="isDownloading" class="spinner-border" role="status" aria-hidden="true"></span>
      Download as PDF
    </button>
    -->
   <button type="button" class="btn btn-default" (click)="close()">Close</button>
  </div>
</ng-container>
<ng-template #loading>
  <div class="modal-header">
    <h5 class="modal-title d-flex flex-column">
      <span>{{ formName }}</span>
    </h5>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body h-100">
    <div class="sk-circle sk-center">
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  </div>
</ng-template>