import { Component, Input, HostBinding, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { LayoutService } from 'src/app/services/layout.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { Permissions } from '../../../models/role.model';

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styleUrls: ['./layout-sidenav.component.scss']
})
export class LayoutSidenavComponent implements AfterViewInit {
  @Input() orientation = 'vertical';
  @Input() hidden: boolean = false;

  @HostBinding('class.layout-sidenav') private hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') private hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') private hostClassFlex = false;

  public role$: Observable<number> = this.authService.userRole$;
  public PERMISSIONS: any = Permissions;

  constructor(
    private router: Router, 
    private appService: AppService, 
    private layoutService: LayoutService,
    private authService: AuthService
  ) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
  }

  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} ${this.hidden ? 'd-none' : ''} bg-${bg}`;
  }

  isActive(url) {
    return this.router.url.includes(url);
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  canAccess(permission: Permissions): Observable<boolean> {
    return this.authService.canAccess(permission)
  }
}
