<ng-container *ngIf="response; else loading">
  <div class="modal-header">
     <h5 class="modal-title d-flex flex-column">
        <span>{{ formName }} - {{ response.data.STREET_ADDRESS }}</span>
        <small class="text-muted">{{ response.user.email || 'N/A'}} - updated {{ response.timeUpdated | amTimeAgo }}</small>
     </h5>
     <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="printable-form" [formGroup]="form">
      <div id="Requests">

        <div class="form-group">
          <label class="form-label text-danger">Request Type*</label>
          <ng-select
            formControlName="TYPE"
            [multiple]="false"
            required>
            <ng-option value="INSTALL">Sign Install</ng-option>
            <ng-option value="REMOVAL">Sign Removal</ng-option>
            <ng-option value="CHANGE">Change to Existing Sign</ng-option>
            <ng-option value="DAMAGELOSS">Report Sign Damage or Loss</ng-option>
            <ng-option value="OTHER">Other</ng-option>
          </ng-select>
        </div>

        <hr>
        <ng-container *ngIf="form.get('TYPE').value == 'CHANGE'">
          <div class="text-danger">Please note that a fee of $25 will be passed on to the agent for any change requests that require an additional trip to the property (within service boundaries)</div>
        </ng-container>

        <ng-container *ngIf="form.get('TYPE').value != ''">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="form-label text-danger">Street Address*</label>
              <input 
                type="text"
                class="form-control"
                placeholder="123 Example Avenue"
                formControlName="FULL_ADDRESS"
                required>
            </div>
            <div class="form-group col-md-3">
              <label class="form-label text-danger">City*</label>
              <input 
                type="text"
                class="form-control"
                placeholder="City"
                formControlName="CITY"
                required>
            </div>
            <div class="form-group col-md-3">
              <label class="form-label text-danger">Zip Code*</label>
              <input 
                type="text"
                class="form-control"
                placeholder="12345-1234"
                mask="00000-9999"
                formControlName="ZIP"
                required>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label text-danger">Cross Streets*</label>
              <input 
                type="text"
                class="form-control"
                placeholder="123 Example Avenue"
                formControlName="CROSS_STREETS"
                required>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="form-label text-danger">Agent Name*</label>
              <input 
                type="text"
                class="form-control"
                placeholder="John Doe"
                formControlName="AGENT_NAME"
                required>
            </div>
            <div class="form-group col-md-6">
              <label class="form-label text-danger">Phone Number*</label>
              <input 
                type="text"
                class="form-control"
                placeholder="(123) 123-1234"
                formControlName="PHONE_NUMBER"
                required
                mask="(000) 000-0000">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('TYPE').value == 'INSTALL'">
          <div class="row">
            <div class="form-group col-md-10">
              <label class="form-label text-danger">Preferred Install Date*</label>
              <input
                type="text"
                class="form-control bg-white"
                mwlFlatpickr
                placeholder="January 1, 2022"
                [altInput]="true" 
                [convertModelValue]="true"
                formControlName="INSTALL_DATE"
                required>
            </div>
            <div class="form-group col-md-2">
              <label class="form-label text-danger">Quantity (max 5)*</label>
              <input 
                type="number"
                class="form-control"
                max="5"
                placeholder="1"
                formControlName="QUANTITY"
                required>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label text-danger">Additional Riders Needed?*</label>
              <ng-select
                formControlName="RIDERS"
                [multiple]="false"
                required>
                <ng-option value="APPOINTMENT">Appointment Only</ng-option>
                <ng-option value="POOL">Pool</ng-option>
                <ng-option value="NONE">None</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label text-danger">Is this listing land, farm, or lot?*</label>
              <ng-select
                formControlName="MAPPABLE"
                [multiple]="false"
                required>
                <ng-option value="YES">Yes</ng-option>
                <ng-option value="NO">No</ng-option>
              </ng-select>
            </div>

            <div *ngIf="f.MAPPABLE.value === 'YES'" class="w-100">
              <div class="form-group col-md-12">
                <label class="form-label text-danger">Link to Google Maps with location: </label>
                <a target="_blank" href="javascript:void(0)" (click)="openGoogleMaps()">Googles Maps Location</a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label">Comments/Others</label>
              <textarea
                class="form-control"
                rows="4"
                placeholder="Flyer boxes, lights, etc..."
                formControlName="COMMENTS"></textarea>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('TYPE').value == 'REMOVAL'">
          <div class="row">
            <div class="form-group col-md-10">
              <label class="form-label text-danger">Preferred Removal Date*</label>
              <input
                type="text"
                class="form-control bg-white"
                mwlFlatpickr
                placeholder="January 1, 2022"
                [altInput]="true" 
                [convertModelValue]="true"
                formControlName="REMOVAL_DATE"
                required>
            </div>
            <div class="form-group col-md-2">
              <label class="form-label text-danger">Quantity (max 5)*</label>
              <input 
                type="number"
                class="form-control"
                max="5"
                placeholder="1"
                formControlName="QUANTITY"
                required>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label">Comments/Others</label> <br>
              <small>If sign has already been removed and needs to be picked up from a specified location, please provide details</small>
              <textarea
                class="form-control"
                rows="4"
                placeholder="Details about removal..."
                formControlName="COMMENTS"></textarea>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('TYPE').value == 'CHANGE'">
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label text-danger">Additional Riders Needed?*</label>
              <ng-select
                formControlName="RIDERS"
                [multiple]="false"
                required>
                <ng-option value="APPOINTMENT">Appointment Only</ng-option>
                <ng-option value="POOL">Pool</ng-option>
                <ng-option value="PENDING">Pending</ng-option>
                <ng-option value="SOLD">Sold</ng-option>
                <ng-option value="NONE">None</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label">Comments/Other</label> <br>
              <textarea
                class="form-control"
                rows="4"
                placeholder="Details about change (change of location, add sign rider, etc...)"
                formControlName="COMMENTS"></textarea>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('TYPE').value == 'DAMAGELOSS'">
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label text-danger">Replace or remove sign?*</label>
              <ng-select
                formControlName="REPLACEREMOVE"
                [multiple]="false"
                required>
                <ng-option value="REPLACE">Replace</ng-option>
                <ng-option value="REMOVE">Remove</ng-option>
                <ng-option value="DONOTHING">Do Nothing</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label text-danger">Please explain what happened to the sign</label>
              <textarea
                class="form-control"
                rows="4"
                placeholder="Details about sign damage/loss..."
                formControlName="DAMAGELOSS"></textarea>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('TYPE').value == 'OTHER'">
          <div class="row">
            <div class="form-group col-md-12">
              <label class="form-label">Comments/Other</label>
              <textarea
                class="form-control"
                rows="4"
                placeholder="Details/notes..."
                formControlName="COMMENTS"></textarea>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!--
   <button type="button" class="btn btn-primary" (click)="download()" [disabled]="isDownloading">
      <span *ngIf="isDownloading" class="spinner-border" role="status" aria-hidden="true"></span>
      Download as PDF
    </button>
    -->
   <button type="button" class="btn btn-default" (click)="close()">Close</button>
  </div>
</ng-container>
<ng-template #loading>
  <div class="modal-header">
    <h5 class="modal-title d-flex flex-column">
      <span>{{ formName }}</span>
    </h5>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body h-100">
    <div class="sk-circle sk-center">
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  </div>
</ng-template>