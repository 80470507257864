import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessService } from 'src/app/services/access.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {
  public isEmailValid = true;
  public isNameValid = true;
  public isAddingMember = false;
  public memberEmail = '';
  public memberName = '';
  public memberRole: number = 1;

  public roles = [{name: 'User', value: 1}, {name: 'Admin', value: 3}]

  constructor(
    private ngbModal: NgbModal,
    private ngbActiveModal: NgbActiveModal,
    private accessService: AccessService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.ngbModal.dismissAll();
    this.isNameValid = true;
    this.isEmailValid = true;
    this.isAddingMember = false;
    this.memberEmail = '';
    this.memberRole = 1;
  }

  addMember() {
    // TODO: Clean this and transform form into a reactive form. 
    // REGEX taken from Angular's validator source 
    const NAME_REGEXP = /^[a-zA-Z\s]*$/; 
    const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if(!EMAIL_REGEXP.test(this.memberEmail)) {
      this.isEmailValid = false;
    } 
    else if(!NAME_REGEXP.test(this.memberName) || this.memberName.length == 0) {
      this.isNameValid = false;
    }
    else {
      this.accessService.addMember(this.memberEmail, this.memberName, this.memberRole).subscribe(res => {
        this.ngbActiveModal.close({data: res, input: {
          email: this.memberEmail,
          name: this.memberName,
          role: this.roles[this.roles.findIndex(x => x.value === this.memberRole)].name
        }});
        this.close();
      });
    }
  }

}
