import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { LayoutService } from 'src/app/services/layout.service';
import { CustomNavigationMode } from '../../services/custom-navigation';
import { CustomFormsService } from '../../services/custom-forms.service';
import { Response } from 'src/app/models/response.model';
import { ModalService } from 'src/app/modals/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-farm',
  templateUrl: './farm.component.html',
  styleUrls: ['./farm.component.scss']
})
export class FarmComponent implements OnInit, OnDestroy {
  @ViewChild('submitSuccess') private submitSuccess: SwalComponent;
  @ViewChild('submitError') private submitError: SwalComponent;
  @ViewChild('wizard') private wizard: WizardComponent;
  public errorList = [];

  public form: FormGroup
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public numErrors: number = 0;
  public key: string = '';
  public responseCache: Response = null;

  private routeSubscription: Subscription;
  private dataSubscription: Subscription;
  private formSubscription: Subscription;

  public navigationMode: CustomNavigationMode;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private layoutService: LayoutService,
    private customFormsService: CustomFormsService,
    private modalService: ModalService,
    private toastrService: ToastrService) {
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(true);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl(''),

      "County_ComboBox": new FormControl('', [Validators.required]),
      "APN_1": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_2": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_3": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "New_Construction": new FormControl(''),
      "Street_Number": new FormControl(''),
      "Street_Number_Modifier": new FormControl(''),
      "Street_Direction": new FormControl(''),
      "Street_Address": new FormControl(''),
      "Street_Suffix": new FormControl(''),
      "Post_Direction": new FormControl(''),
      "Unit_Number": new FormControl(''),
      "City_Fill_In": new FormControl('', [Validators.required]),
      "Zip_Code": new FormControl('', [Validators.required]),
      "Zip_Plus_4": new FormControl(''),
      "Cross_Street": new FormControl('', [Validators.required]),
      "Office_ID": new FormControl('', [Validators.required]),
      "Office_MLS_ID": new FormControl('', [Validators.required]),
      "Agent_MLS_ID": new FormControl('', [Validators.required]),
      "Co_Office_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Agent_MLS_ID').value != '')]),
      "Co_Agent_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Office_MLS_ID').value != '')]),
      "Prop_Subtype_0001": new FormControl(''),
      "Prop_Subtype_0002": new FormControl(''),
      "Prop_Subtype_0003": new FormControl(''),
      "Transaction": new FormControl('', [Validators.required]),
      "SSLE": new FormControl(''),
      "REBK": new FormControl('', [Validators.required]),
      "Lease_Price": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Lease_Type": new FormControl(''),
      "Sub_Lease": new FormControl(''),
      "Available_Date": new FormControl(''),
      "Lease_Expiration": new FormControl(''),
      "APNS": new FormControl(''),
      "0100APN2": new FormControl(''),
      "0110APN3": new FormControl(''),
      "0120APN4": new FormControl(''),
      "0130APN5": new FormControl(''),
      "Listing_Price_1": new FormControl('', [Validators.required]),
      "Listing_Price_2": new FormControl(''),
      "Agreement_Type_ComboBox": new FormControl('', [Validators.required]),
      "SCOP": new FormControl(''),
      "0255SCOP": new FormControl(''),
      "DUAL": new FormControl(''),
      "0240DUAL": new FormControl(''),
      "Publish_To_Internet": new FormControl(''),
      "Show_AVM_On_VOW": new FormControl('', [Validators.required]),
      "Show_Address_to_Public": new FormControl('', [Validators.required]),
      "Show_Comments_On_VOW": new FormControl('', [Validators.required]),
      //"Dollar_Percent": new FormControl('', [Validators.required]),
      //"Commission_1": new FormControl(''),
      //"Commission_Amount": new FormControl('', [Validators.required]),
      //"Variable_Rate_Comm": new FormControl('', [Validators.required]),
      "Comm_Comments": new FormControl(''),
      "EOFF": new FormControl('', [Validators.required]),
      "0380EOAD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('EOFF').value == 'YESY')]),
      "Listing_Date": new FormControl('', [Validators.required]),
      "Expiration_Date": new FormControl('', [Validators.required]),
      "Owner_Name": new FormControl(''),
      "Owner_Phone_Area": new FormControl(''),
      "Owner_Phone_Prefix": new FormControl(''),
      "Owner_Phone_Suffix": new FormControl(''),
      "Occupant_Type": new FormControl('', [Validators.required]),
      "Occupant_Name": new FormControl(''),
      "Phone_to_Show_Area": new FormControl(''),
      "Phone_to_Show_Prefix": new FormControl(''),
      "Phone_to_Show_Suffix": new FormControl(''),
      "Bedrooms_ComboBox": new FormControl(''),
      "Bathrooms_ComboBox": new FormControl(''),
      "Struc_Sq_Ft": new FormControl(''),
      "SqFt_Source": new FormControl(''),
      "Lot_Sq_Ft": new FormControl(''),
      "Lot_Measurement": new FormControl(''),
      //"Lot_Measurement": new FormControl(''),
      "LotSize_Source": new FormControl(''),
      "Year_Built": new FormControl(''),
      "Unknown_Year": new FormControl(''),
      "Property_Description": new FormControl('', [Validators.required]),
      "Agent_Only_Remarks": new FormControl(''),
      "Directions": new FormControl(''),
      "School_District_Fill_In": new FormControl('', [Validators.required]),
      "School_Code_1": new FormControl('', [Validators.required]),
      "School_Code_2": new FormControl('', [Validators.required]),
      "School_Code_3": new FormControl('', [Validators.required]),
      "0590SCMT": new FormControl(''),
      "0620MCRT": new FormControl('', [Validators.required]),
      "CPIN": new FormControl(''),
      "0630CPIN": new FormControl(''),
      "0640PLV1": new FormControl(''),
      "ACR1": new FormControl(''),
      "0650ACR1": new FormControl(''),
      "0660AGE1": new FormControl(''),
      "0670PRO1": new FormControl(''),
      "0680PLV2": new FormControl(''),
      "ACR2": new FormControl(''),
      "0690ACR2": new FormControl(''),
      "0700AGE2": new FormControl(''),
      "0710PRO2": new FormControl(''),
      "0720PLV3": new FormControl(''),
      "ACR3": new FormControl(''),
      "0730ACR3": new FormControl(''),
      "0740AGE3": new FormControl(''),
      "0750PRO3": new FormControl(''),
      "0760PLV4": new FormControl(''),
      "ACR4": new FormControl(''),
      "0770ACR4": new FormControl(''),
      "0780AGE4": new FormControl(''),
      "0790PRO4": new FormControl(''),
      "0800PLV5": new FormControl(''),
      "ACR5": new FormControl(''),
      "0810ACR5": new FormControl(''),
      "0820AGE5": new FormControl(''),
      "0830PRO5": new FormControl(''),
      "CNSS": new FormControl(''),
      "0870CNSS": new FormControl(''),
      "0880IRDT": new FormControl(''),
      "0890HWAY": new FormControl(''),
      "AREA": new FormControl('', [Validators.required]),
      "0900AREA": new FormControl(''),
      "Area_Fill_In": new FormControl('', [Validators.required]),
      "0940TOPO": new FormControl('', [Validators.required]),
      "DIST": new FormControl(''),
      "0950DIST": new FormControl(''),
      "WATERSOURCE": new FormGroup({
        "WTRSWELL": new FormControl(''),
        "Checkbox_Comment_WTRSWELL": new FormControl(''),
        "WTRSDSTW": new FormControl(''),
        "Checkbox_Comment_WTRSDSTW": new FormControl(''),
        "WTRSSCRK": new FormControl(''),
        "Checkbox_Comment_WTRSSCRK": new FormControl(''),
        "WTRSLAKE": new FormControl(''),
        "Checkbox_Comment_WTRSLAKE": new FormControl(''),
        "WTRSSPNG": new FormControl(''),
        "Checkbox_Comment_WTRSSPNG": new FormControl(''),
        "WTRSWSTS": new FormControl(''),
        "Checkbox_Comment_WTRSWSTS": new FormControl(''),
        "WTRSPOND": new FormControl(''),
        "Checkbox_Comment_WTRSPOND": new FormControl(''),
        "WTRSNONE": new FormControl(''),
        "Checkbox_Comment_WTRSNONE": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "IRRIGATIONWELLS": new FormGroup({
        "IRRWNONE": new FormControl(''),
        "Checkbox_Comment_IRRWNONE": new FormControl(''),
        "IRRWONE": new FormControl(''),
        "Checkbox_Comment_IRRWONE": new FormControl(''),
        "IRRWTWOM": new FormControl(''),
        "Checkbox_Comment_IRRWTWOM": new FormControl(''),
        "IRRWELEC": new FormControl(''),
        "Checkbox_Comment_IRRWELEC": new FormControl(''),
        "IRRWGEAR": new FormControl(''),
        "Checkbox_Comment_IRRWGEAR": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "DOMESTICWELLS": new FormGroup({
        "DOMWNONE": new FormControl(''),
        "Checkbox_Comment_DOMWNONE": new FormControl(''),
        "DOMWONE": new FormControl(''),
        "Checkbox_Comment_DOMWONE": new FormControl(''),
        "DOMWTWOM": new FormControl(''),
        "Checkbox_Comment_DOMWTWOM": new FormControl(''),
        "DOMWCITW": new FormControl(''),
        "Checkbox_Comment_DOMWCITW": new FormControl(''),
        "DOMWSHAR": new FormControl(''),
        "Checkbox_Comment_DOMWSHAR": new FormControl(''),
        "DOMWSUBM": new FormControl(''),
        "Checkbox_Comment_DOMWSUBM": new FormControl(''),
        "DOMWTURB": new FormControl(''),
        "Checkbox_Comment_DOMWTURB": new FormControl(''),
        "DOMWWIND": new FormControl(''),
        "Checkbox_Comment_DOMWWIND": new FormControl(''),
        "DOMWGNRA": new FormControl(''),
        "Checkbox_Comment_DOMWGNRA": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SEWERSEPTIC": new FormGroup({
        "SEWRNONE": new FormControl(''),
        "Checkbox_Comment_SEWRNONE": new FormControl(''),
        "SEWRCITS": new FormControl(''),
        "Checkbox_Comment_SEWRCITS": new FormControl(''),
        "SEWRSEPT": new FormControl(''),
        "Checkbox_Comment_SEWRSEPT": new FormControl(''),
        "SEWRSHAR": new FormControl(''),
        "Checkbox_Comment_SEWRSHAR": new FormControl(''),
        "SEWRENGN": new FormControl(''),
        "Checkbox_Comment_SEWRENGN": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "UTILITIES": new FormGroup({
        "UTILNONE": new FormControl(''),
        "Checkbox_Comment_UTILNONE": new FormControl(''),
        "UTILELEC": new FormControl(''),
        "Checkbox_Comment_UTILELEC": new FormControl(''),
        "UTILGAS": new FormControl(''),
        "Checkbox_Comment_UTILGAS": new FormControl(''),
        "UTILPROP": new FormControl(''),
        "Checkbox_Comment_UTILPROP": new FormControl(''),
        "UTILELET": new FormControl(''),
        "Checkbox_Comment_UTILELET": new FormControl(''),
        "UTILPHON": new FormControl(''),
        "Checkbox_Comment_UTILPHON": new FormControl(''),
        "UTILELEN": new FormControl(''),
        "Checkbox_Comment_UTILELEN": new FormControl(''),
        "UTILGASC": new FormControl(''),
        "Checkbox_Comment_UTILGASC": new FormControl(''),
        "UTILGASN": new FormControl(''),
        "Checkbox_Comment_UTILGASN": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SPECSPSZ": new FormControl(''),
      "Checkbox_Comment_SPECSPSZ": new FormControl(''),
      "SPECTOXI": new FormControl(''),
      "Checkbox_Comment_SPECTOXI": new FormControl(''),
      "SPECFLIT": new FormControl(''),
      "Checkbox_Comment_SPECFLIT": new FormControl(''),
      "SPECASCN": new FormControl(''),
      "Checkbox_Comment_SPECASCN": new FormControl(''),
      "SPECAORD": new FormControl(''),
      "Checkbox_Comment_SPECAORD": new FormControl(''),
      "SPECSPSA": new FormControl(''),
      "Checkbox_Comment_SPECSPSA": new FormControl(''),
      "SPECOTHR": new FormControl(''),
      "Checkbox_Comment_SPECOTHR": new FormControl(''),
      "SPECSEER": new FormControl(''),
      "Checkbox_Comment_SPECSEER": new FormControl(''),
      "SPECWILL": new FormControl(''),
      "Checkbox_Comment_SPECWILL": new FormControl(''),
      "DILOPLOT": new FormControl(''),
      "Checkbox_Comment_DILOPLOT": new FormControl(''),
      "DILOLEGA": new FormControl(''),
      "Checkbox_Comment_DILOLEGA": new FormControl(''),
      "DILODEED": new FormControl(''),
      "Checkbox_Comment_DILODEED": new FormControl(''),
      "DILOZONR": new FormControl(''),
      "Checkbox_Comment_DILOZONR": new FormControl(''),
      "DILOBLDR": new FormControl(''),
      "Checkbox_Comment_DILOBLDR": new FormControl(''),
      "DILOSURV": new FormControl(''),
      "Checkbox_Comment_DILOSURV": new FormControl(''),
      "DILOSOIL": new FormControl(''),
      "Checkbox_Comment_DILOSOIL": new FormControl(''),
      "DILOPERC": new FormControl(''),
      "Checkbox_Comment_DILOPERC": new FormControl(''),
      "DILOAERA": new FormControl(''),
      "Checkbox_Comment_DILOAERA": new FormControl(''),
      "DILOTOPO": new FormControl(''),
      "Checkbox_Comment_DILOTOPO": new FormControl(''),
      "DILOLEAS": new FormControl(''),
      "Checkbox_Comment_DILOLEAS": new FormControl(''),
      "DILOAPPR": new FormControl(''),
      "Checkbox_Comment_DILOAPPR": new FormControl(''),
      "DILOEQPM": new FormControl(''),
      "Checkbox_Comment_DILOEQPM": new FormControl(''),
      "DILOPROD": new FormControl(''),
      "Checkbox_Comment_DILOPROD": new FormControl(''),
      "DILOLESS": new FormControl(''),
      "Checkbox_Comment_DILOLESS": new FormControl(''),
      "LBOX": new FormControl('', [Validators.required]),
      "1030LBOX": new FormControl(''),
      "1040LOCL": new FormControl(''),
      "Showing_Instructions": new FormControl(''),
      "SHOWINGINSTRUCTIONS": new FormGroup({
        "SHOWLOCK": new FormControl(''),
        "Checkbox_Comment_SHOWLOCK": new FormControl(''),
        "SHOWKEYO": new FormControl(''),
        "Checkbox_Comment_SHOWKEYO": new FormControl(''),
        "SHOWGOOC": new FormControl(''),
        "Checkbox_Comment_SHOWGOOC": new FormControl(''),
        "SHOWCALL": new FormControl(''),
        "Checkbox_Comment_SHOWCALL": new FormControl(''),
        "SHOWAPPT": new FormControl(''),
        "Checkbox_Comment_SHOWAPPT": new FormControl(''),
        "SHOW24HR": new FormControl(''),
        "Checkbox_Comment_SHOW24HR": new FormControl(''),
        "SHOWNOSN": new FormControl(''),
        "Checkbox_Comment_SHOWNOSN": new FormControl(''),
        "SHOWSNST": new FormControl(''),
        "Checkbox_Comment_SHOWSNST": new FormControl(''),
        "SHOWCLAG": new FormControl(''),
        "Checkbox_Comment_SHOWCLAG": new FormControl(''),
        "SHOWVAC": new FormControl(''),
        "Checkbox_Comment_SHOWVAC": new FormControl(''),
        "SHOWDOGI": new FormControl(''),
        "Checkbox_Comment_SHOWDOGI": new FormControl(''),
        "SHOWDOGO": new FormControl(''),
        "Checkbox_Comment_SHOWDOGO": new FormControl(''),
        "SHOWRSTC": new FormControl(''),
        "Checkbox_Comment_SHOWRSTC": new FormControl(''),
        "SHOWSEAS": new FormControl(''),
        "Checkbox_Comment_SHOWSEAS": new FormControl(''),
        "SHOWFWDR": new FormControl(''),
        "Checkbox_Comment_SHOWFWDR": new FormControl(''),
        "SHOWCBSC": new FormControl(''),
        "Checkbox_Comment_SHOWCBSC": new FormControl(''),
        "SHOWVTGS": new FormControl(''),
        "Checkbox_Comment_SHOWVTGS": new FormControl(''),
        "SHOWALRM": new FormControl(''),
        "Checkbox_Comment_SHOWALRM": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "BUILDINGONSITE": new FormGroup({
        "BONSNONE": new FormControl(''),
        "Checkbox_Comment_BONSNONE": new FormControl(''),
        "BONSHOUS": new FormControl(''),
        "Checkbox_Comment_BONSHOUS": new FormControl(''),
        "BONSRENT": new FormControl(''),
        "Checkbox_Comment_BONSRENT": new FormControl(''),
        "BONSMOBL": new FormControl(''),
        "Checkbox_Comment_BONSMOBL": new FormControl(''),
        "BONSBARN": new FormControl(''),
        "Checkbox_Comment_BONSBARN": new FormControl(''),
        "BONSSHOP": new FormControl(''),
        "Checkbox_Comment_BONSSHOP": new FormControl(''),
        "BONSPOLE": new FormControl(''),
        "Checkbox_Comment_BONSPOLE": new FormControl(''),
        "BONSOUTB": new FormControl(''),
        "Checkbox_Comment_BONSOUTB": new FormControl(''),
        "BONSOTHR": new FormControl(''),
        "Checkbox_Comment_BONSOTHR": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "MNRLNONE": new FormControl(''),
      "Checkbox_Comment_MNRLNONE": new FormControl(''),
      "MNRLPART": new FormControl(''),
      "Checkbox_Comment_MNRLPART": new FormControl(''),
      "MNRLAVAL": new FormControl(''),
      "Checkbox_Comment_MNRLAVAL": new FormControl(''),
      "MNRLLEAS": new FormControl(''),
      "Checkbox_Comment_MNRLLEAS": new FormControl(''),
      "TRANCITS": new FormControl(''),
      "Checkbox_Comment_TRANCITS": new FormControl(''),
      "TRANCNTR": new FormControl(''),
      "Checkbox_Comment_TRANCNTR": new FormControl(''),
      "TRANPRIV": new FormControl(''),
      "Checkbox_Comment_TRANPRIV": new FormControl(''),
      "TRANSTHG": new FormControl(''),
      "Checkbox_Comment_TRANSTHG": new FormControl(''),
      "TRANFRWY": new FormControl(''),
      "Checkbox_Comment_TRANFRWY": new FormControl(''),
      "TRANEASM": new FormControl(''),
      "Checkbox_Comment_TRANEASM": new FormControl(''),
      "TRANPAVE": new FormControl(''),
      "Checkbox_Comment_TRANPAVE": new FormControl(''),
      "TRANGRAV": new FormControl(''),
      "Checkbox_Comment_TRANGRAV": new FormControl(''),
      "TRANRAIL": new FormControl(''),
      "Checkbox_Comment_TRANRAIL": new FormControl(''),
      "TRANAIRP": new FormControl(''),
      "Checkbox_Comment_TRANAIRP": new FormControl(''),
      "TRAN4WDR": new FormControl(''),
      "Checkbox_Comment_TRAN4WDR": new FormControl(''),
      "MISCCRFN": new FormControl(''),
      "Checkbox_Comment_MISCCRFN": new FormControl(''),
      "MISCESMT": new FormControl(''),
      "Checkbox_Comment_MISCESMT": new FormControl(''),
      "MISCLEAS": new FormControl(''),
      "Checkbox_Comment_MISCLEAS": new FormControl(''),
      "MISCHUNT": new FormControl(''),
      "Checkbox_Comment_MISCHUNT": new FormControl(''),
      "MISCGRAZ": new FormControl(''),
      "Checkbox_Comment_MISCGRAZ": new FormControl(''),
      "SALETOPO": new FormControl(''),
      "Checkbox_Comment_SALETOPO": new FormControl(''),
      "SALESUB": new FormControl(''),
      "Checkbox_Comment_SALESUB": new FormControl(''),
      "SALEBULD": new FormControl(''),
      "Checkbox_Comment_SALEBULD": new FormControl(''),
      "SALEADJL": new FormControl(''),
      "Checkbox_Comment_SALEADJL": new FormControl(''),
      "1SLNASSM": new FormControl(''),
      "Checkbox_Comment_1SLNASSM": new FormControl(''),
      "1SLNNASS": new FormControl(''),
      "Checkbox_Comment_1SLNNASS": new FormControl(''),
      "1SLNPRVT": new FormControl(''),
      "Checkbox_Comment_1SLNPRVT": new FormControl(''),
      "1SLNFRCL": new FormControl(''),
      "Checkbox_Comment_1SLNFRCL": new FormControl(''),
      "2LNASSM": new FormControl(''),
      "Checkbox_Comment_2LNASSM": new FormControl(''),
      "2LNNASS": new FormControl(''),
      "Checkbox_Comment_2LNNASS": new FormControl(''),
      "2LNPRVT": new FormControl(''),
      "Checkbox_Comment_2LNPRVT": new FormControl(''),
      "TERMCASH": new FormControl(''),
      "Checkbox_Comment_TERMCASH": new FormControl(''),
      "TERMSUBT": new FormControl(''),
      "Checkbox_Comment_TERMSUBT": new FormControl(''),
      "TERMLEAS": new FormControl(''),
      "Checkbox_Comment_TERMLEAS": new FormControl(''),
      "TERMOWNR": new FormControl(''),
      "Checkbox_Comment_TERMOWNR": new FormControl(''),
      "TERMLIMI": new FormControl(''),
      "Checkbox_Comment_TERMLIMI": new FormControl(''),
      "HSSB": new FormControl(''),
      "STTD": new FormControl(''),
      "1250STTD": new FormControl(''),
      "SDTD": new FormControl(''),
      "1260SDTD": new FormControl(''),
      "1270SSSS": new FormControl(''),
      "ADDD": new FormControl(''),
      "1271ADDD": new FormControl(''),
      "UNIQBANK": new FormControl(''),
      "Checkbox_Comment_UNIQBANK": new FormControl(''),
      "UNIQPROB": new FormControl(''),
      "Checkbox_Comment_UNIQPROB": new FormControl(''),
      "UNIQESTA": new FormControl(''),
      "Checkbox_Comment_UNIQESTA": new FormControl(''),
      "UNIQCORT": new FormControl(''),
      "Checkbox_Comment_UNIQCORT": new FormControl(''),
      "UNIQBID": new FormControl(''),
      "Checkbox_Comment_UNIQBID": new FormControl(''),
      "UNIQEXCH": new FormControl(''),
      "Checkbox_Comment_UNIQEXCH": new FormControl('')
    }, { updateOn: 'change' });


    // Customized conditionals and form setters
    //this.form.get('POOLTYPE').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('Pool').value == 'Yes')]);
    //this.form.get('HOAAMENITIES').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('HOAA').value == 'Yes')]);

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      const temp = value;
      Object.keys(temp).forEach(key => {
        if(!temp[key] || temp[key] == '' || temp[key] == null)
          delete temp[key];
      });
      localStorage.setItem('cachedFormData', JSON.stringify(temp));

      this.form.get('Co_Office_MLS_ID').updateValueAndValidity({onlySelf: true});
      this.form.get('Co_Agent_MLS_ID').updateValueAndValidity({onlySelf: true});
    })

    this.form.get('Office_ID').valueChanges.subscribe(val => {
      this.form.get('Office_MLS_ID').setValue(val);
    })

    this.formSubscription.add(this.form.get('Street_Number').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Direction').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Address').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Suffix').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.navigationMode = new CustomNavigationMode(this.form);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.key = params.key;
      if(params.key) {
        this.dataSubscription = this.responseService.getResponse(params.key).subscribe(payload => {
          this.form.patchValue(payload.data);
          this.responseCache = payload;
        })
      } else {
        if(localStorage.getItem('cachedFormData')) {
          let confirmation = confirm("You have saved form data, would you like to load it?")

          if(confirmation) {
            let data = localStorage.getItem('cachedFormData');
            this.form.patchValue(JSON.parse(data));
          } else {
            localStorage.removeItem('cachedFormData');
          }
        }
      }
    })
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if(this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if(this.formSubscription)
      this.formSubscription.unsubscribe();

    if(this.responseCache)
      this.responseCache = null;
  }

  get f() { return this.form.controls; }

  submit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if(this.form.valid) {
      this.isLoading = true;
      let temp = this.form.value;
      //if(temp["Dollar_Percent"] == '$') {
      //  temp["Commission_1"] = '$' + temp["Commission_Amount"];
      //} else if(temp["Dollar_Percent"] == '%') {
      //  temp["Commission_1"] = temp["Commission_Amount"] + '%';
      //}
      temp["Office_MLS_ID"] = temp["Office_ID"]; 


      const data = this.customFormsService.sanitizeFormPayload(temp);

      if(!this.responseCache) {
        const response = {
          form: '5f8b8a57c2e3261d9d86748a',
          organization: '',
          name: 'Save_' + Math.random().toString(36).substr(2, 5),
          data: temp,
          submitted: true
        }
        this.responseService.postResponse(response).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        })
      } else {
        this.responseService.patchResponse(this.responseCache._id, {data: data, submitted: true }).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        });
      }
    } else {
      this.numErrors = 0;
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.numErrors++;
              console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });
      this.toastrService.error('You have not completed the form!', 'Submission Failed');
    }
  }

  save() {
    const self = this;
    this.isSaving = true;

    // Remove empty/null inputs to store less
    const data = this.customFormsService.sanitizeFormPayload(this.form.value);
    if(!this.responseCache) {
      this.modalService.openModal('SaveResponse', { backdrop: 'static', keyboard: false }, {formData: data, formId: '5f8b8a57c2e3261d9d86748a'}, function(data) {
        console.log(data);
        if(data.err)
          self.toastrService.error('Save was unsuccessful, please try again.', 'Error 500');
        else {
          self.toastrService.success('Save was successful', 'Success');
          self.router.navigate(['.'], { relativeTo: self.route, queryParams: {key: data._id}, queryParamsHandling: 'merge' });
          self.form.markAsPristine();
        }
      });
    } else {
      if(this.form.dirty) {
        this.responseService.patchResponse(this.responseCache._id, {data: data}).subscribe(res => {
          this.isSaving = false;
          this.responseCache = res;
          this.form.markAsPristine();
          this.toastrService.success('Save was successful', 'Success');
        });
      } else {
        this.toastrService.info('No changes detected', 'Unsuccessful Save');
      }
    }
  }

  validate() {
    this.submitted = true;
    this.numErrors = 0;
    this.errorList = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.numErrors++;
          //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          let errorElem = document.querySelector("[formControlName='" + key + "']");
          if(!errorElem) {
            let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
            errorElem = document.querySelector("[formControlName='" + childKey + "']");
          }

          let stepId = errorElem.closest('aw-wizard-step').getAttribute('stepid')
          this.errorList.push({
            key: key,
            stepId: stepId,
          })
        });
      }
    });
  }

  goToError(error) {
    const key = error.key
    const index = this.wizard.getIndexOfStepWithId(error.stepId);
    this.wizard.goToStep(index);

    let errorElem = document.querySelector("[formControlName='" + key + "']");
    if(!errorElem) {
      let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
      errorElem = document.querySelector("[formControlName='" + childKey + "']");
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + childKey + "']").getBoundingClientRect().top - 250
      }, 100)
    } else {
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + error.key + "']").getBoundingClientRect().top - 200
      }, 100)
    }
  }

  enableControl(condition: boolean, controlNames: string[]) {
    if(condition) {
      controlNames.forEach(name => {
        this.form.controls[name].enable();
      })
    } else {
      controlNames.forEach(name => {
        this.form.controls[name].disable();
      })
    }
  }

  complete() {
    this.layoutService.setSideNavHidden(false);
    this.router.navigate(['../history'], { relativeTo: this.route });
  }

  navigateToSign() {
    this.router.navigate(['../sign-request'], { relativeTo: this.route });
  }
}
