import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseService } from 'src/app/services/response.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-save-response',
  templateUrl: './save-response.component.html',
  styleUrls: ['./save-response.component.scss']
})
export class SaveResponseComponent {
  @Input() data: any;
  @Output() return: EventEmitter<any> = new EventEmitter();

  public isValid: boolean = true;
  public saveName: string = '';

  public isSaving: boolean = false;

  constructor(
    private ngbModal: NgbModal,
    private ngbActiveModal: NgbActiveModal,
    private responseService: ResponseService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  close() {
    this.ngbModal.dismissAll();
    this.isValid = true;
    this.isSaving = false;
    this.saveName = '';
  }

  saveResponse() {
    if(this.saveName.length < 1 || !/^[a-zA-Z0-9\s]*$/g.test(this.saveName))
      this.isValid = false;
    else {
      this.isValid = true;
      this.isSaving = true;

      const newResponse = {
        form: this.data.formId,
        organization: '',
        name: this.saveName,
        data: this.data.formData,
        submitted: false
      }

      this.responseService.postResponse(newResponse).subscribe(res => {
        this.isSaving = false;
        this.ngbActiveModal.close(res);
      })
    }
  }
}
