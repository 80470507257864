<div class="modal-header">
  <h5 class="modal-title">
    Add a member
  </h5>
  <button type="button" class="close" (click)="close()">&times;</button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col">
      <label class="form-label">Email</label>
      <input type="email" class="form-control" [ngClass]="{'is-invalid': !isEmailValid}" [(ngModel)]="memberEmail" #email="ngModel" email="true">
      <small *ngIf="!isEmailValid" class="form-text text-danger">Must be a valid email</small>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label class="form-label">Full Name</label>
      <input type="text" class="form-control" [ngClass]="{'is-invalid': !isNameValid}" [(ngModel)]="memberName">
      <small *ngIf="!isNameValid" class="form-text text-danger">Full name must only contain letters and cannot be empty</small>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label class="form-label">Role</label>
      <ng-select 
        [clearable]="false"
        [searchable]="false"
        [items]="roles"
        bindLabel="name"
        bindValue="value"
        [(ngModel)]="memberRole">
      </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="addMember()" [disabled]="isAddingMember">
    <span *ngIf="isAddingMember" class="spinner-border" role="status" aria-hidden="true"></span>
    Add
  </button>
</div>