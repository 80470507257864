import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { LayoutService } from 'src/app/services/layout.service';
import { CustomNavigationMode } from '../../services/custom-navigation';
import { CustomFormsService } from '../../services/custom-forms.service';
import { Response } from 'src/app/models/response.model';
import { ModalService } from 'src/app/modals/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-land-lots',
  templateUrl: './land-lots.component.html',
  styleUrls: ['./land-lots.component.scss']
})
export class LandLotsComponent implements OnInit, OnDestroy {
  @ViewChild('submitSuccess') private submitSuccess: SwalComponent;
  @ViewChild('submitError') private submitError: SwalComponent;
  @ViewChild('wizard') private wizard: WizardComponent;
  public errorList = [];

  public form: FormGroup
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public numErrors: number = 0;
  public key: string = '';
  public responseCache: Response = null;

  private routeSubscription: Subscription;
  private dataSubscription: Subscription;
  private formSubscription: Subscription;

  public navigationMode: CustomNavigationMode;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private layoutService: LayoutService,
    private customFormsService: CustomFormsService,
    private modalService: ModalService,
    private toastrService: ToastrService) {
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(true);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl(''),

      "County_ComboBox": new FormControl('', [Validators.required]),
      "APN_1": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_2": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_3": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "New_Construction": new FormControl(''),
      "Street_Number": new FormControl(''),
      "Street_Number_Modifier": new FormControl(''),
      "Street_Direction": new FormControl(''),
      "Street_Address": new FormControl('', [Validators.required]),
      "Street_Suffix": new FormControl(''),
      "Post_Direction": new FormControl(''),
      "Unit_Number": new FormControl(''),
      "City_Fill_In": new FormControl('', [Validators.required]),
      "Zip_Code": new FormControl('', [Validators.required]),
      "Zip_Plus_4": new FormControl(''),
      "Cross_Street": new FormControl('', [Validators.required]),
      "Office_ID": new FormControl('', [Validators.required]),
      "Office_MLS_ID": new FormControl('', [Validators.required]),
      "Agent_MLS_ID": new FormControl('', [Validators.required]),
      "Co_Office_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Agent_MLS_ID').value != '')]),
      "Co_Agent_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Office_MLS_ID').value != '')]),
      "Prop_Subtype_0001": new FormControl('', [Validators.required]),
      "Prop_Subtype_0002": new FormControl(''),
      "Prop_Subtype_0003": new FormControl(''),
      "Transaction": new FormControl('', [Validators.required]),
      "SSLE": new FormControl('', [Validators.required]),
      "REBK": new FormControl('', [Validators.required]),
      "Lease_Price": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Lease_Type": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Sub_Lease": new FormControl(''),
      "Available_Date": new FormControl(''),
      "Lease_Expiration": new FormControl(''),
      "APNS": new FormControl(''),
      "0100APN2": new FormControl(''),
      "0110APN3": new FormControl(''),
      "0120APN4": new FormControl(''),
      "0130APN5": new FormControl(''),
      "Listing_Price_1": new FormControl('', [Validators.required]),
      "Listing_Price_2": new FormControl(''),
      "Agreement_Type_ComboBox": new FormControl('', [Validators.required]),
      "SCOP": new FormControl(''),
      "0235SCOP": new FormControl(''),
      "DUAL": new FormControl(''),
      "0240DUAL": new FormControl(''),
      "Publish_To_Internet": new FormControl(''),
      "Show_AVM_On_VOW": new FormControl('', [Validators.required]),
      "Show_Address_to_Public": new FormControl('', [Validators.required]),
      "Show_Comments_On_VOW": new FormControl('', [Validators.required]),
      //"Dollar_Percent": new FormControl('', [Validators.required]),
      //"Commission_1": new FormControl(''),
      //"Commission_Amount": new FormControl('', [Validators.required]),
      //"Variable_Rate_Comm": new FormControl('', [Validators.required]),
      "Comm_Comments": new FormControl(''),
      "EOFF": new FormControl('', [Validators.required]),
      "0380EOAD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('EOFF').value == 'YESY')]),
      "Lot_Sq_Ft": new FormControl(''),
      "Lot_Measurement": new FormControl(''),
      "0410ZONE": new FormControl(''),
      "LotSize_Source": new FormControl(''),
      "Listing_Date": new FormControl('', [Validators.required]),
      "Expiration_Date": new FormControl('', [Validators.required]),
      "Owner_Name": new FormControl(''),
      "Owner_Phone_Area": new FormControl(''),
      "Owner_Phone_Prefix": new FormControl(''),
      "Owner_Phone_Suffix": new FormControl(''),
      "Showing_Instructions": new FormControl(''),
      "Phone_to_Show_Area": new FormControl(''),
      "Phone_to_Show_Prefix": new FormControl(''),
      "Phone_to_Show_Suffix": new FormControl(''),
      "Property_Description": new FormControl(''),
      "Agent_Only_Remarks": new FormControl(''),
      "Directions": new FormControl('', [Validators.required]),
      "School_District_Fill_In": new FormControl(''),
      "School_Code_1": new FormControl(''),
      "School_Code_2": new FormControl(''),
      "School_Code_3": new FormControl(''),
      "0570SCMT": new FormControl(''),
      "CNSS": new FormControl(''),
      "0650CNSS": new FormControl(''),
      "AREA": new FormControl(''),
      "0660AREA": new FormControl(''),
      "Area_Fill_In": new FormControl(''),
      "LANDLOTDESC": new FormGroup({
        "LOTDIRRE": new FormControl(''),
        "Checkbox_Comment_LOTDIRRE": new FormControl(''),
        "LOTDLESS": new FormControl(''),
        "Checkbox_Comment_LOTDLESS": new FormControl(''),
        "LOTDLEVL": new FormControl(''),
        "Checkbox_Comment_LOTDLEVL": new FormControl(''),
        "LOTDROLL": new FormControl(''),
        "Checkbox_Comment_LOTDROLL": new FormControl(''),
        "LOTDHILL": new FormControl(''),
        "Checkbox_Comment_LOTDHILL": new FormControl(''),
        "LOTDELEV": new FormControl(''),
        "Checkbox_Comment_LOTDELEV": new FormControl(''),
        "LOTDAC15": new FormControl(''),
        "Checkbox_Comment_LOTDAC15": new FormControl(''),
        "LOTDMOR5": new FormControl(''),
        "Checkbox_Comment_LOTDMOR5": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SEWERWATER": new FormGroup({
        "WATRPWTR": new FormControl(''),
        "Checkbox_Comment_WATRPWTR": new FormControl(''),
        "WATRPWEL": new FormControl(''),
        "Checkbox_Comment_WATRPWEL": new FormControl(''),
        "WATRDWTR": new FormControl(''),
        "Checkbox_Comment_WATRDWTR": new FormControl(''),
        "WATRSWEL": new FormControl(''),
        "Checkbox_Comment_WATRSWEL": new FormControl(''),
        "WATRSEPT": new FormControl(''),
        "Checkbox_Comment_WATRSEPT": new FormControl(''),
        "WATRCWEL": new FormControl(''),
        "Checkbox_Comment_WATRCWEL": new FormControl(''),
        "WATRCSEW": new FormControl(''),
        "Checkbox_Comment_WATRCSEW": new FormControl(''),
        "WATRNONE": new FormControl(''),
        "Checkbox_Comment_WATRNONE": new FormControl(''),
        "WATRPWLL": new FormControl(''),
        "Checkbox_Comment_WATRPWLL": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "GASELECTRIC": new FormGroup({
        "GSECPROP": new FormControl(''),
        "Checkbox_Comment_GSECPROP": new FormControl(''),
        "GSECNOGS": new FormControl(''),
        "Checkbox_Comment_GSECNOGS": new FormControl(''),
        "GSECPUBU": new FormControl(''),
        "Checkbox_Comment_GSECPUBU": new FormControl(''),
        "GSECNOEL": new FormControl(''),
        "Checkbox_Comment_GSECNOEL": new FormControl(''),
        "GSECNONE": new FormControl(''),
        "Checkbox_Comment_GSECNONE": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SPECFLOD": new FormControl(''),
      "Checkbox_Comment_SPECFLOD": new FormControl(''),
      "SPECTOXI": new FormControl(''),
      "Checkbox_Comment_SPECTOXI": new FormControl(''),
      "SPECPUBL": new FormControl(''),
      "Checkbox_Comment_SPECPUBL": new FormControl(''),
      "SPECSPSA": new FormControl(''),
      "Checkbox_Comment_SPECSPSA": new FormControl(''),
      "SPECSPFE": new FormControl(''),
      "Checkbox_Comment_SPECSPFE": new FormControl(''),
      "SPECFLIT": new FormControl(''),
      "Checkbox_Comment_SPECFLIT": new FormControl(''),
      "SPECSEER": new FormControl(''),
      "Checkbox_Comment_SPECSEER": new FormControl(''),
      "IRPTHOME": new FormControl(''),
      "Checkbox_Comment_IRPTHOME": new FormControl(''),
      "IRPTROOF": new FormControl(''),
      "Checkbox_Comment_IRPTROOF": new FormControl(''),
      "IRPTPEST": new FormControl(''),
      "Checkbox_Comment_IRPTPEST": new FormControl(''),
      "IRPTSEPT": new FormControl(''),
      "Checkbox_Comment_IRPTSEPT": new FormControl(''),
      "IRPTWELR": new FormControl(''),
      "Checkbox_Comment_IRPTWELR": new FormControl(''),
      "IRPTWTRP": new FormControl(''),
      "Checkbox_Comment_IRPTWTRP": new FormControl(''),
      "IRPTCC&R": new FormControl(''),
      "Checkbox_Comment_IRPTCC&R": new FormControl(''),
      "IRPTRULE": new FormControl(''),
      "Checkbox_Comment_IRPTRULE": new FormControl(''),
      "IRPTBYLW": new FormControl(''),
      "Checkbox_Comment_IRPTBYLW": new FormControl(''),
      "IRPTBDGT": new FormControl(''),
      "Checkbox_Comment_IRPTBDGT": new FormControl(''),
      "IRPTFINC": new FormControl(''),
      "Checkbox_Comment_IRPTFINC": new FormControl(''),
      "IRPTOTHR": new FormControl(''),
      "Checkbox_Comment_IRPTOTHR": new FormControl(''),
      "POTERESI": new FormControl(''),
      "Checkbox_Comment_POTERESI": new FormControl(''),
      "POTEMULT": new FormControl(''),
      "POTECONP": new FormControl(''),
      "Checkbox_Comment_POTECONP": new FormControl(''),
      "POTECOMM": new FormControl(''),
      "Checkbox_Comment_POTECOMM": new FormControl(''),
      "POTEINDU": new FormControl(''),
      "Checkbox_Comment_POTEINDU": new FormControl(''),
      "POTEMANU": new FormControl(''),
      "Checkbox_Comment_POTEMANU": new FormControl(''),
      "POTEOFFI": new FormControl(''),
      "Checkbox_Comment_POTEOFFI": new FormControl(''),
      "POTERETA": new FormControl(''),
      "Checkbox_Comment_POTERETA": new FormControl(''),
      "POTEMOHO": new FormControl(''),
      "Checkbox_Comment_POTEMOHO": new FormControl(''),
      "POTEMOBL": new FormControl(''),
      "Checkbox_Comment_POTEMOBL": new FormControl(''),
      "POTEHORS": new FormControl(''),
      "Checkbox_Comment_POTEHORS": new FormControl(''),
      "IMPROVEMENTS": new FormGroup({
        "IMPRNONE": new FormControl(''),
        "Checkbox_Comment_IMPRNONE": new FormControl(''),
        "IMPRSTRM": new FormControl(''),
        "Checkbox_Comment_IMPRSTRM": new FormControl(''),
        "IMPRSTRL": new FormControl(''),
        "Checkbox_Comment_IMPRSTRL": new FormControl(''),
        "IMPRSIDE": new FormControl(''),
        "Checkbox_Comment_IMPRSIDE": new FormControl(''),
        "IMPRCURB": new FormControl(''),
        "Checkbox_Comment_IMPRCURB": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "TRANCITS": new FormControl(''),
      "Checkbox_Comment_TRANCITS": new FormControl(''),
      "TRANCNTR": new FormControl(''),
      "Checkbox_Comment_TRANCNTR": new FormControl(''),
      "TRANPRIV": new FormControl(''),
      "Checkbox_Comment_TRANPRIV": new FormControl(''),
      "TRANSTHG": new FormControl(''),
      "Checkbox_Comment_TRANSTHG": new FormControl(''),
      "TRANFRWY": new FormControl(''),
      "Checkbox_Comment_TRANFRWY": new FormControl(''),
      "TRANEASM": new FormControl(''),
      "Checkbox_Comment_TRANEASM": new FormControl(''),
      "TRANPAVE": new FormControl(''),
      "Checkbox_Comment_TRANPAVE": new FormControl(''),
      "TRANGRVL": new FormControl(''),
      "Checkbox_Comment_TRANGRVL": new FormControl(''),
      "TRANRAIL": new FormControl(''),
      "Checkbox_Comment_TRANRAIL": new FormControl(''),
      "TRANAIRP": new FormControl(''),
      "Checkbox_Comment_TRANAIRP": new FormControl(''),
      "TRAN4WDR": new FormControl(''),
      "Checkbox_Comment_TRAN4WDR": new FormControl(''),
      "TRANSEAS": new FormControl(''),
      "Checkbox_Comment_TRANSEAS": new FormControl(''),
      "SITECRNR": new FormControl(''),
      "Checkbox_Comment_SITECRNR": new FormControl(''),
      "SITECDES": new FormControl(''),
      "SITEBLUF": new FormControl(''),
      "Checkbox_Comment_SITEBLUF": new FormControl(''),
      "SITEGOLF": new FormControl(''),
      "Checkbox_Comment_SITEGOLF": new FormControl(''),
      "SITERIVR": new FormControl(''),
      "Checkbox_Comment_SITERIVR": new FormControl(''),
      "SITELAKE": new FormControl(''),
      "Checkbox_Comment_SITELAKE": new FormControl(''),
      "SITEWATR": new FormControl(''),
      "Checkbox_Comment_SITEWATR": new FormControl(''),
      "SITECREK": new FormControl(''),
      "Checkbox_Comment_SITECREK": new FormControl(''),
      "SITESCRK": new FormControl(''),
      "Checkbox_Comment_SITESCRK": new FormControl(''),
      "SITEGATE": new FormControl(''),
      "Checkbox_Comment_SITEGATE": new FormControl(''),
      "SITEPRKV": new FormControl(''),
      "Checkbox_Comment_SITEPRKV": new FormControl(''),
      "SALETOPO": new FormControl(''),
      "Checkbox_Comment_SALETOPO": new FormControl(''),
      "SALESUBD": new FormControl(''),
      "Checkbox_Comment_SALESUBD": new FormControl(''),
      "SALEBTOS": new FormControl(''),
      "Checkbox_Comment_SALEBTOS": new FormControl(''),
      "SALEADJL": new FormControl(''),
      "Checkbox_Comment_SALEADJL": new FormControl(''),
      "1SLNASSM": new FormControl(''),
      "Checkbox_Comment_1SLNASSM": new FormControl(''),
      "1SLNPRVT": new FormControl(''),
      "Checkbox_Comment_1SLNPRVT": new FormControl(''),
      "1SLNFRCL": new FormControl(''),
      "Checkbox_Comment_1SLNFRCL": new FormControl(''),
      "2LNASSM": new FormControl(''),
      "Checkbox_Comment_2LNASSM": new FormControl(''),
      "2LNPRVT": new FormControl(''),
      "Checkbox_Comment_2LNPRVT": new FormControl(''),
      "TERMCONV": new FormControl(''),
      "Checkbox_Comment_TERMCONV": new FormControl(''),
      "TERMSUB": new FormControl(''),
      "Checkbox_Comment_TERMSUB": new FormControl(''),
      "TERMLSOP": new FormControl(''),
      "Checkbox_Comment_TERMLSOP": new FormControl(''),
      "TERMCASH": new FormControl(''),
      "Checkbox_Comment_TERMCASH": new FormControl(''),
      "TERMOWNR": new FormControl(''),
      "Checkbox_Comment_TERMOWNR": new FormControl(''),
      "TERMLIMI": new FormControl(''),
      "Checkbox_Comment_TERMLIMI": new FormControl(''),
      "UNIQPROB": new FormControl(''),
      "Checkbox_Comment_UNIQPROB": new FormControl(''),
      "UNIQESTA": new FormControl(''),
      "Checkbox_Comment_UNIQESTA": new FormControl(''),
      "UNIQCORT": new FormControl(''),
      "Checkbox_Comment_UNIQCORT": new FormControl(''),
      "UNIQEXCH": new FormControl(''),
      "Checkbox_Comment_UNIQEXCH": new FormControl('')
    }, { updateOn: 'change' });


    // Customized conditionals and form setters
    //this.form.get('POOLTYPE').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('Pool').value == 'Yes')]);
    //this.form.get('HOAAMENITIES').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('HOAA').value == 'Yes')]);

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      const temp = value;
      Object.keys(temp).forEach(key => {
        if(!temp[key] || temp[key] == '' || temp[key] == null)
          delete temp[key];
      });
      localStorage.setItem('cachedFormData', JSON.stringify(temp));

      this.form.get('Co_Office_MLS_ID').updateValueAndValidity({onlySelf: true});
      this.form.get('Co_Agent_MLS_ID').updateValueAndValidity({onlySelf: true});
    })

    this.form.get('Office_ID').valueChanges.subscribe(val => {
      this.form.get('Office_MLS_ID').setValue(val);
    })

    this.formSubscription.add(this.form.get('Street_Number').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Direction').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Address').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Suffix').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.navigationMode = new CustomNavigationMode(this.form);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.key = params.key;
      if(params.key) {
        this.dataSubscription = this.responseService.getResponse(params.key).subscribe(payload => {
          this.form.patchValue(payload.data);
          this.responseCache = payload;
        })
      } else {
        if(localStorage.getItem('cachedFormData')) {
          let confirmation = confirm("You have saved form data, would you like to load it?")

          if(confirmation) {
            let data = localStorage.getItem('cachedFormData');
            this.form.patchValue(JSON.parse(data));
          } else {
            localStorage.removeItem('cachedFormData');
          }
        }
      }
    })
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if(this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if(this.formSubscription)
      this.formSubscription.unsubscribe();

    if(this.responseCache)
      this.responseCache = null;
  }

  get f() { return this.form.controls; }

  submit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if(this.form.valid) {
      this.isLoading = true;
      let temp = this.form.value;
      //if(temp["Dollar_Percent"] == '$') {
      //  temp["Commission_1"] = '$' + temp["Commission_Amount"];
      //} else if(temp["Dollar_Percent"] == '%') {
      //  temp["Commission_1"] = temp["Commission_Amount"] + '%';
      //}
      temp["Office_MLS_ID"] = temp["Office_ID"]; 


      const data = this.customFormsService.sanitizeFormPayload(temp);

      if(!this.responseCache) {
        const response = {
          form: '5f836c4e6d8e0808f9166a74',
          organization: '',
          name: 'Save_' + Math.random().toString(36).substr(2, 5),
          data: temp,
          submitted: true
        }
        this.responseService.postResponse(response).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        })
      } else {
        this.responseService.patchResponse(this.responseCache._id, {data: data, submitted: true }).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        });
      }
    } else {
      this.numErrors = 0;
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.numErrors++;
              console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });
      this.toastrService.error('You have not completed the form!', 'Submission Failed');
    }
  }

  save() {
    const self = this;
    this.isSaving = true;

    // Remove empty/null inputs to store less
    const data = this.customFormsService.sanitizeFormPayload(this.form.value);
    if(!this.responseCache) {
      this.modalService.openModal('SaveResponse', { backdrop: 'static', keyboard: false }, {formData: data, formId: '5f836c4e6d8e0808f9166a74'}, function(data) {
        console.log(data);
        if(data.err)
          self.toastrService.error('Save was unsuccessful, please try again.', 'Error 500');
        else {
          self.toastrService.success('Save was successful', 'Success');
          self.router.navigate(['.'], { relativeTo: self.route, queryParams: {key: data._id}, queryParamsHandling: 'merge' });
          self.form.markAsPristine();
        }
      });
    } else {
      if(this.form.dirty) {
        this.responseService.patchResponse(this.responseCache._id, {data: data}).subscribe(res => {
          this.isSaving = false;
          this.responseCache = res;
          this.form.markAsPristine();
          this.toastrService.success('Save was successful', 'Success');
        });
      } else {
        this.toastrService.info('No changes detected', 'Unsuccessful Save');
      }
    }
  }

  validate() {
    this.submitted = true;
    this.numErrors = 0;
    this.errorList = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.numErrors++;
          //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          let errorElem = document.querySelector("[formControlName='" + key + "']");
          if(!errorElem) {
            let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
            errorElem = document.querySelector("[formControlName='" + childKey + "']");
          }

          let stepId = errorElem.closest('aw-wizard-step').getAttribute('stepid')
          this.errorList.push({
            key: key,
            stepId: stepId,
          })
        });
      }
    });
  }

  goToError(error) {
    const key = error.key
    const index = this.wizard.getIndexOfStepWithId(error.stepId);
    this.wizard.goToStep(index);

    let errorElem = document.querySelector("[formControlName='" + key + "']");
    if(!errorElem) {
      let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
      errorElem = document.querySelector("[formControlName='" + childKey + "']");
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + childKey + "']").getBoundingClientRect().top - 250
      }, 100)
    } else {
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + error.key + "']").getBoundingClientRect().top - 200
      }, 100)
    }
  }

  enableControl(condition: boolean, controlNames: string[]) {
    if(condition) {
      controlNames.forEach(name => {
        this.form.controls[name].enable();
      })
    } else {
      controlNames.forEach(name => {
        this.form.controls[name].disable();
      })
    }
  }

  complete() {
    this.layoutService.setSideNavHidden(false);
    this.router.navigate(['../history'], { relativeTo: this.route });
  }

  navigateToSign() {
    this.router.navigate(['../sign-request'], { relativeTo: this.route });
  }
}
