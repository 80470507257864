import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResidentialComponent } from './components/residential/residential.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ArchwizardModule } from 'angular-archwizard';
import { MomentModule } from 'ngx-moment';
import { NgxMaskModule } from 'ngx-mask';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CommercialComponent } from './components/commercial/commercial.component';
import { ViewResidentialComponent } from './modals/view-residential/view-residential.component';
import { ViewCommercialComponent } from './modals/view-commercial/view-commercial.component';
import { BusinessComponent } from './components/business/business.component';
import { ViewBusinessComponent } from './modals/view-business/view-business.component';
import { LandLotsComponent } from './components/land-lots/land-lots.component';
import { MultiFamilyComponent } from './components/multi-family/multi-family.component';
import { FarmComponent } from './components/farm/farm.component';
import { ViewLandlotsComponent } from './modals/view-landlots/view-landlots.component';
import { ViewMultifamilyComponent } from './modals/view-multifamily/view-multifamily.component';
import { ViewFarmComponent } from './modals/view-farm/view-farm.component';
import { SpecialRequestComponent } from './components/special-request/special-request.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ViewSpecialRequestComponent } from './modals/view-special-request/view-special-request.component';
import { SignRequestComponent } from './components/sign-request/sign-request.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ViewSignRequestComponent } from './modals/view-sign-request/view-sign-request.component';


@NgModule({
  declarations: [
    ResidentialComponent,
    CommercialComponent,
    ViewResidentialComponent,
    ViewCommercialComponent,
    BusinessComponent,
    ViewBusinessComponent,
    LandLotsComponent,
    MultiFamilyComponent,
    FarmComponent,
    ViewLandlotsComponent,
    ViewMultifamilyComponent,
    ViewFarmComponent,
    SpecialRequestComponent,
    ViewSpecialRequestComponent,
    SignRequestComponent,
    ViewSignRequestComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    MomentModule,
    SweetAlert2Module,
    NgxMaskModule,
    NgSelectModule,
    FlatpickrModule,
    LeafletModule
  ],
  exports: [
    ResidentialComponent
  ]
})
export class CustomFormsModule { }
