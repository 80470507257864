import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  private subscription: Subscription

  public invalidUrl = false;
  public failedAuth = false;
  public cookieNotFound = false;

  constructor(private auth: AuthService, private route: ActivatedRoute) {
    this.subscription = this.route.queryParams.subscribe(queryParams => {
      const code = queryParams.code;

      if(!code)
        this.invalidUrl = true;
      else {
        this.auth.handleAuthCallback$(code).pipe(
          catchError(err => {
            console.log(err);
            if(err.status == 401)
              this.cookieNotFound = true;
            else
              this.failedAuth = true;
            return throwError(err);
          })
        ).subscribe();
      }
    })
  }

  login() {
    this.auth.login();
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.invalidUrl = false;
    this.failedAuth = false;
    this.cookieNotFound = false;

    if(this.subscription) 
      this.subscription.unsubscribe();
  }

}
