import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateOrganizationComponent } from '../components/create-organization/create-organization.component';
import { NewUserInitComponent } from '../components/new-user-init/new-user-init.component';
import { AddMemberComponent } from '../components/add-member/add-member.component';
import { SaveResponseComponent } from '../components/save-response/save-response.component';
import { SwitchOrganizationComponent } from '../components/switch-organization/switch-organization.component';
import { EditMemberComponent } from '../components/edit-member/edit-member.component';
import { DeleteMemberComponent } from '../components/delete-member/delete-member.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalComponents = {
    'CreateOrganization': CreateOrganizationComponent,
    'NewUserInit': NewUserInitComponent,
    'AddMember': AddMemberComponent,
    'EditMember': EditMemberComponent,
    'DeleteMember': DeleteMemberComponent,
    'SaveResponse': SaveResponseComponent,
    'SwitchOrganization': SwitchOrganizationComponent,
  }

  constructor(private ngbModal: NgbModal) { }

  public openModal(key: string = 'CreateOrganization', options: NgbModalOptions, data?: any, resultFunction?: (data: any) => void) {
    const ref = this.ngbModal.open(this.modalComponents[key], options)
    if(data)
      ref.componentInstance.data = data;

    if(resultFunction)
      ref.result.then(resultFunction);
  }
}
