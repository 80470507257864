<div [formGroup]="form" class="h-100">
  <aw-wizard class="h-100" #wizard
    [awNavigationMode]="navigationMode"
    navBarLocation="left"
    navBarLayout="large-empty-symbols">
    <div class="form-header p-2">
      <h4 *ngIf="!responseCache; else saveTitle" class="font-weight-bold">
        FRESNO MLS
        <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Business Opp. Listing Input</small></div>
      </h4>
      <ng-template #saveTitle>
        <h4 class="font-weight-bold">
          {{ responseCache.name }}
          <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Updated {{ responseCache.timeUpdated | amTimeAgo }}</small></div>
        </h4>
      </ng-template>
      <img src="assets/images/RClogo.png" class="img-fluid" width="120" alt="Realty Concepts"/>
    </div>
    <div class="form-container card card-body">
      <aw-wizard-step
        stepId="Listing Information"
        stepTitle="Listing Information" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">1</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Listing Information Form">
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between pt-1">
              <label for="selCountyComboBox" class= "reqtext pr-2 py-2">*County:</label>
              <select id="selCountyComboBox" formControlName="County_ComboBox" class="form-control col-md-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.County_ComboBox.errors }" required>
                  <option value="Fresno" selected>Fresno</option>
                  <option value="Kern">Kern</option>
                  <option value="Kings">Kings</option>
                  <option value="Madera">Madera</option>
                  <option value="Mariposa">Mariposa</option>
                  <option value="Merced">Merced</option>
                  <option value="Mono">Mono</option>
                  <option value="Orange">Orange</option>
                  <option value="Placer">Placer</option>
                  <option value="Riverside">Riverside</option>
                  <option value="San Bernardino">San Bernardino</option>
                  <option value="San Joaquin">San Joaquin</option>
                  <option value="Santa Clara">Santa Clara</option>
                  <option value="Stanislaus">Stanislaus</option>
                  <option value="Tehama">Tehama</option>
                  <option value="Tulare">Tulare</option>
                  <option value="Tuolumne">Tuolumne</option>
                  <option value="Ventura">Ventura</option>
                  <option value="Out of Area">Out of Area</option>
                  <option value="Unknown">Unknown</option>
                  <option value="Alameda">Alameda</option>
                  <option value="Alpine">Alpine</option>
                  <option value="Amador">Amador</option>
                  <option value="Butte">Butte</option>
                  <option value="Calaveras">Calaveras</option>
                  <option value="Colusa">Colusa</option>
                  <option value="Contra Costa">Contra Costa</option>
                  <option value="Del Norte">Del Norte</option>
                  <option value="El Dorado">El Dorado</option>
                  <option value="Glenn">Glenn</option>
                  <option value="Humboldt">Humboldt</option>
                  <option value="Imperial">Imperial</option>
                  <option value="Inyo">Inyo</option>
                  <option value="Lake">Lake</option>
                  <option value="Lassen">Lassen</option>
                  <option value="Los Angeles">Los Angeles</option>
                  <option value="Marin">Marin</option>
                  <option value="Mendocino">Mendocino</option>
                  <option value="Modoc">Modoc</option>
                  <option value="Monterey">Monterey</option>
                  <option value="Napa">Napa</option>
                  <option value="Nevada">Nevada</option>
                  <option value="Plumas">Plumas</option>
                  <option value="Sacramento">Sacramento</option>
                  <option value="San Benito">San Benito</option>
                  <option value="San Diego">San Diego</option>
                  <option value="San Francisco">San Francisco</option>
                  <option value="San Luis Obispo">San Luis Obispo</option>
                  <option value="San Mateo">San Mateo</option>
                  <option value="Santa Barbara">Santa Barbara</option>
                  <option value="Santa Cruz">Santa Cruz</option>
                  <option value="Shasta">Shasta</option>
                  <option value="Sierra">Sierra</option>
                  <option value="Siskiyou">Siskiyou</option>
                  <option value="Solano">Solano</option>
                  <option value="Sonoma">Sonoma</option>
                  <option value="Sutter">Sutter</option>
                  <option value="Trinity">Trinity</option>
                  <option value="Yolo">Yolo</option>
                  <option value="Yuba">Yuba</option>
              </select>			
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="APN_1" class="reqtext pr-2 py-2">*APN:</label>
              <div class="d-flex">
                <input type="text" id="APN_1" formControlName="APN_1" class="form-control" size="3" maxlength="3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_1.errors }" required>
                <label for="APN_2">&nbsp;-&nbsp;</label>
                <input type="text" id="APN_2" formControlName="APN_2" class="form-control" size="3" maxlength="3"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_2.errors }" required>
                <label for="APN_3">&nbsp;-&nbsp;</label>
                <input type="text" id="APN_3" formControlName="APN_3" class="form-control" size="3" maxlength="3"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_3.errors }" required>
              </div>
            </div>
          </div>
    
    
          <div class="row fixcheck form-inline">
            <div class="form-check col-12 justify-content-end">
              <label class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="New_Construction" formControlName="New_Construction" class="form-check-input">New / Under Contruction / NA</label>
            </div>
          </div>
    
    
          <!---->
          <h6 class="subtitle p-1">Address</h6>
          <!---->
          <input type="text" formControlName="FULL_ADDRESS" class="d-none form-control">
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Number" class= "reqtext pr-2 py-2">*Street # - Modifier:</label>
              <div class="d-flex">
                <input type="text" id="Street_Number" formControlName="Street_Number" class="form-control" size="6" maxlength="6"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.Street_Number.errors }" required>
                <label for="Street_Number_Modifier">-</label>
                <input type="text" id="Street_Number_Modifier" formControlName="Street_Number_Modifier" class="form-control" size="6" maxlength="8">
              </div>
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Direction" class="nonreqtext pr-2 py-2">Direction:</label>
              <select id="Street_Direction" formControlName="Street_Direction" class="form-control col-md-6 col-lg-8">
                <option value="" selected="">  </option>
                <option value="E"> East </option>
                <option value="N"> North </option>
                <option value="NE"> Northeast </option>
                <option value="NW"> Northwest </option>
                <option value="S"> South </option>
                <option value="SE"> Southeast </option>
                <option value="SW"> Southwest </option>
                <option value="W"> West </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Address" class= "reqtext pr-2 py-2">*Street Name:</label>
              <input type="text" id="Street_Address" formControlName="Street_Address" class="form-control col-sm-8" size="" maxlength="30"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.Street_Address.errors }" required>
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Suffix" class="nonreqtext pr-2 py-2">Sufix:</label>
              <select id="Street_Suffix"  formControlName="Street_Suffix" class="form-control col-md-6 col-lg-8">
                  <option value="" selected=""> </option>
                  <option value="Ave"> Avenue </option>
                  <option value="Blvd"> Boulevard </option>
                  <option value="Cir"> Circle </option>
                  <option value="Ct"> Court </option>
                  <option value="Dr"> Drive </option>
                  <option value="Jct"> Junction </option>
                  <option value="Ln"> Lane </option>
                  <option value="Pass"> Pass </option>
                  <option value="Pkwy"> Parkway </option>
                  <option value="Pl"> Place </option>
                  <option value="Rd"> Road </option>
                  <option value="Sqr"> Square </option>
                  <option value="St"> Street </option>
                  <option value="Way"> Way </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 offset-md-6 justify-content-between">
              <label for="Post_Direction" class="nonreqtext pr-2 py-2">Post Direction:</label>
              <select id="Post_Direction" formControlName="Post_Direction" class="form-control col-md-6 col-lg-8">
                  <option value="" selected="">  </option>
                  <option value="E"> East </option>
                  <option value="N"> North </option>
                  <option value="NE"> Northeast </option>
                  <option value="NW"> Northwest </option>
                  <option value="S"> South </option>
                  <option value="SE"> Southeast </option>
                  <option value="SW"> Southwest </option>
                  <option value="W"> West </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Unit_Number" class="nonreqtext pr-2 py-2">Unit #:</label>
              <input type="text" id="Unit_Number" formControlName="Unit_Number" class="form-control col-md-8" size="6" maxlength="5">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="City_Fill_In" class= "reqtext pr-2 py-2">*City:</label>
              <input type="text" id="City_Fill_In" formControlName="City_Fill_In" size="16" maxlength="20" class="form-control col-sm-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.City_Fill_In.errors }" required>
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Zip_Code" class="reqtext pr-2 py-2">*Zip Code:</label>
              <div class="d-flex">
                <input type="text" id="Zip_Code" formControlName="Zip_Code" class="form-control" size="5" maxlength="5" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Zip_Code.errors }" required>
                <label for="Zip_Plus_4">
                -</label>
                  <input type="text" id="Zip_Plus_4" formControlName="Zip_Plus_4" class="form-control" size="4" maxlength="4">
              </div>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Cross_Street" class="reqtext pr-2 py-2">
              *Cross Street:</label>
                <input type="text" id="Cross_Street" formControlName="Cross_Street" class="form-control col-sm-8" size="16" maxlength="30" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Cross_Street.errors }" required>
            </div>
            </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="txtOfficeAuto" class= "reqtext pr-2 py-2">
              *Office ID:</label>
              <select id="Office_ID" formControlName="Office_ID" class="form-control col-sm-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Office_ID.errors }" required>
                <option value=""> </option>
                <option value="630">630-Fresno</option>
                <option value="63004">63004-Clovis</option>
                <option value="63005">63005-Commercial</option>
                <option value="63006">63006-Oakhurst2</option>
              </select>
              <input type="text" id="txtOfficeAuto" formControlName="Office_MLS_ID" class="d-none form-control col-sm-8" size="40" maxlength="50" required>
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Agent_MLS_ID" class= "reqtext pr-2 py-2">
              *Agent ID:</label>
                <input type="text" id="Agent_MLS_ID" formControlName="Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Agent_MLS_ID.errors }" required>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Office_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Office ID:</label>
                <input type="text" id="Co_Office_MLS_ID" formControlName="Co_Office_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Co_Office_MLS_ID.errors }">
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Agent_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Agent ID:</label>
                <input type="text" id="Co_Agent_MLS_ID" formControlName="Co_Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Co_Agent_MLS_ID.errors }">
            </div>
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="row pb-2">
            <h6 class="col reqtext">*Property Subtypes (select all that apply):</h6>
          </div>
    
    
          <div class="row form-inline">
    
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0001" class= "nonreqtext pr-2 py-2">Primary:</label>
              <select id="Prop_Subtype_0001" formControlName="Prop_Subtype_0001" class="form-control">
                  <option value=""> </option>
                  <option value="RETL">Retail</option>
                  <option value="WHOL">Wholesale</option>
                  <option value="MNFG">Manufacturing</option>
                  <option value="SERV">Service</option>
              </select>
            </div>
    
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0002" class= "nonreqtext pr-2 py-2">Secondary:</label>
              <select id="Prop_Subtype_0002" formControlName="Prop_Subtype_0002" class="form-control">
                  <option value=""> </option>
                  <option value="RETL">Retail</option>
                  <option value="WHOL">Wholesale</option>
                  <option value="MNFG">Manufacturing</option>
                  <option value="SERV">Service</option>
              </select>
            </div>
    
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0003" class= "nonreqtext pr-2 py-2">Other:</label>
              <select id="Prop_Subtype_0003" formControlName="Prop_Subtype_0003" class="form-control">
                  <option value=""> </option>
                  <option value="RETL">Retail</option>
                  <option value="WHOL">Wholesale</option>
                  <option value="MNFG">Manufacturing</option>
                  <option value="SERV">Service</option>
              </select>
            </div>
    
          </div>
            
    
          <div class="row form-inline">
            <div class="form-group col-md-4 justify-content-between">
              <label for="Transaction" class= "reqtext pr-2 py-2">*Transaction Type:</label>
              <select id="Transaction" formControlName="Transaction" (change)="enableControl(f.Transaction.value == 'L', ['Lease_Price', 'Lease_Type'])"  class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Transaction.errors }" required>
                  <option value="S" selected="">Sale</option>
                  <option value="L">Lease</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="CB_SSLE" class= "reqtext pr-2 py-2">*Short Sale:</label>
              <select id="CB_SSLE" formControlName="SSLE" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.SSLE.errors }" required>
                  <option value=""> </option>
                  <option value="YESS">Yes</option>
                  <option value="NONO">No</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="CB_REBK" class= "reqtext pr-2 py-2">*REO/Bank Owned:</label>
              <select id="CB_REBK" formControlName="REBK" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.REBK.errors }" required>
                  <option value=""> </option>
                  <option value="YESS">Yes</option>
                  <option value="NONO">No</option>
              </select>
            </div>
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
          <ng-container *ngIf="form.get('Transaction').value == 'L'">
            <div class="row form-inline">
              <div class="form-group col-md-6 justify-content-between">
                <label for="Lease_Price" class= "reqtext pr-2 pb-2 ">*Lease (Monthly):</label>
                <input type="text" id="Lease_Price" formControlName="Lease_Price" class="form-control col-md-8" size="10" maxlength="10" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Lease_Price.errors }" required>
              </div>
      
              <div class="form-group col-md-6 justify-content-between">
                <label for="Lease_Type" class="reqtext pr-2 py-2">*Lease Type:</label>
                <select id="Lease_Type" formControlName="Lease_Type" class="form-control col-md-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Lease_Type.errors }" required>
                    <option value="" selected="">  </option>
                    <option value="G"> Gross </option>
                    <option value="N"> Net </option>
                    <option value="O"> Other </option>
                </select>
              </div>
            </div>
      
      
            <div class="row form-inline">
              <div class="form-group col-md-6 justify-content-between">
                <label for="Sub_Lease" class="nonreqtext pr-2 py-2">Sub-Lease:</label>
                <select id="Sub_Lease" formControlName="Sub_Lease" class="form-control">
                    <option value="" selected=""> </option>
                    <option value="Y"> Yes </option>
                                <option value="N"> No </option>
                </select>
                <label for="Available_Date" class="nonreqtext pr-2 py-2">Available Date:</label>
                <input type="text" id="Available_Date" formControlName="Available_Date" class="form-control" size="10" maxlength="8" value="">
              </div>
      
              <div class="form-group col-md-6 justify-content-between">
                <label for="Lease_Expiration" class="nonreqtext pr-2 py-2">Sub-Lease Expiration:</label>
                <input type="text" id="Lease_Expiration" formControlName="Lease_Expiration" class="form-control" size="9" maxlength="8" value="">
              </div>
            </div>
      
            <!----><div><hr class="HRstyle"></div><!---->
        </ng-container>
    
          <div class="d-flex row form-inline">
            <div class="col-sm-6">
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="CB_APNS" class= "nonreqtext pr-2 py-2">Additional APNs:</label>
                </div>
                <div class="form-group col-sm-4">
                  <select id="CB_APNS" formControlName="APNS" class="form-control">
                  <option value=" "></option>
                  <option class="mBlackText" value="YESS">Yes</option>
                  <option class="mBlackText" value="NONO">No</option>
                  </select>			
                </div>
              </div>
            </div>
          </div>
    
    
          <div class="d-flex form-inline row">
    
            <!-- Left Column-->
            <div class="col-md-6 align-self-start">
    
    
              <!-- One row for an input -->
              <div class="row">
                  
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0100APN2" class="nonreqtext pr-2 py-2 col-form-label">APN #2:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0100APN2" formControlName="0100APN2" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
              <!-- One row for an input -->
              <div class="row">
                  
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0110APN3" class="nonreqtext pr-2 py-2 col-form-label">APN #3:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0110APN3" formControlName="0110APN3" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
            <!--End of left column-->
            </div>
    
    
            <div class="col-md-6 align-self-start">
            <!--Right Column-->
    
    
              <!-- One row for an input -->
              <div class="row">
                  
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0120APN4" class="nonreqtext pr-2 py-2 col-form-label">APN #4:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0120APN4" formControlName="0120APN4" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="0130APN5" class="nonreqtext pr-2 py-2 col-form-label">APN #5:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0130APN5" formControlName="0130APN5" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
            <!--End of right column-->
            </div>
    
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-md-5 justify-content-between">
              <label for="Listing_Price_1" class= "reqtext pr-2 py-2">Listing Price:</label>
              <div class="d-flex">
                <input type="text" id="Listing_Price_1" formControlName="Listing_Price_1" class="form-control" size="6" maxlength="6" value="" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Listing_Price_1.errors }" required>
                <label for="Listing_Price_2">&nbsp;,&nbsp;</label>
                <input type="text" id="Listing_Price_2" formControlName="Listing_Price_2" class="form-control" size="3" maxlength="3" value="000">
              </div>
            </div>
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Agreement_Type_ComboBox" class="reqtext pr-2 py-2">*Agreement Type:</label>
              <select id="Agreement_Type_ComboBox" formControlName="Agreement_Type_ComboBox" class="form-control col-sm-5" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Agreement_Type_ComboBox.errors }" required>
                  <option class="mBlackText" value="" selected=""> </option>
                  <option class="mBlackText" value="ERTS"> Excl. Right to Sell </option>
                  <option class="mBlackText" value="EXAG"> Exclusive Agency </option>
                  <option class="mBlackText" value="OPEN"> Open </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_SCOP" class="nonreqtext pr-2 py-2">Scope of Service:</label>
              <select id="CB_SCOP" formControlName="SCOP" class="form-control col-sm-5">
                  <option value=" "> </option>
                  <option class="mBlackText" value="ENTY">Entry Only </option>
                  <option class="mBlackText" value="LMTD">Limited Service </option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0235SCOP" formControlName="0235SCOP" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_DUAL" class="nonreqtext pr-2 py-2">Dual Agency:</label>
              <select id="CB_DUAL" formControlName="DUAL" class="form-control col-sm-3">
                <option value=""> </option>
                <option class="mBlackText" value="YES">Yes</option>
                <option class="mBlackText" value="NO">No</option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0240DUAL" formControlName="0240DUAL" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Publish_To_Internet" class="nonreqtext pr-2 py-2">Public Viewable:</label>
              <select id="Publish_To_Internet" formControlName="Publish_To_Internet" class="form-control px-sm-0 px-md-3 col-sm-3">
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_AVM_On_VOW" class="reqtext pr-2 py-2">*Show AVM on VOW:</label>
              <select id="Show_AVM_On_VOW" formControlName="Show_AVM_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_AVM_On_VOW.errors }" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Address_to_Public" class="reqtext pr-2 py-2">*Show Addr to Public:</label>
              <select id="Show_Address_to_Public" formControlName="Show_Address_to_Public" class="form-control px-sm-0 px-md-3 col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_Address_to_Public.errors }" required>
                  <option value="yes" selected="">Yes</option>
                  <option value="no">No</option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Comments_On_VOW" class="reqtext pr-2 py-2">*Show Cmnts on VOW:</label>
              <select id="Show_Comments_On_VOW" formControlName="Show_Comments_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_Comments_On_VOW.errors }" required>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
              </select>
            </div>
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
    
    
          <!--Begin Section-->
          <div class="d-flex form-inline row">
    
            <!--Begin column-->
            <div class="col-lg-5">
    
              <!--entries-->
              <div class="row">
    
                <div class="form-group col-sm-6">
                  <label for="CB_EOFF" class="reqtext pr-2 py-2 col-form-label">*Email Offers:</label>
                </div>
    
                <div class="form-group col-sm-4 offset-sm-2 pr-xs-0">
                  <select id="CB_EOFF" (change)="enableControl(f.EOFF.value == 'YESY', ['0390EOAD'])" formControlName="EOFF" class="form-control col-sm-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.EOFF.errors }" required>
                    <option value=""></option>
                    <option class="mBlackText" value="YESY"> Yes </option>
                    <option class="mBlackText" value="NONO"> No </option>
                  </select>
                </div>
    
              </div>
    
            </div>
    
    
            <!--Begin column-->
            <div class="col-lg-7">
              <!--entries-->
              <div class="row">
    
                <div class="form-group col-sm-3 justify-content-end">
                  <label for="0390EOAD" class="reqtext pr-2 py-2 col-form-label">*Email:</label>
                </div>
    
                <div class="form-group col-sm-9 justify-content-between">
                  <input type="email" id="0390EOAD" formControlName="0390EOAD" class="form-control col-sm-12" rows="1" cols="40" maxlength="60" [ngClass]="{ 'is-invalid alert-danger': submitted && f['0390EOAD'].errors }">
                </div>
    
              </div>
    
            </div>
    
          </div> <!--end of section-->
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <!---->
          <h6 class="subtitle pr-1 py-2">Listing Period:</h6>
          <!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Listing_Date" class="reqtext pr-2 py-2">*Listing Date:</label>
              <input type="text" id="Listing_Date" formControlName="Listing_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Listing_Date.errors }" required>
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Expiration_Date" class="reqtext pr-2 py-2">*Expiration Date:</label>
              <input type="text" id="Expiration_Date" formControlName="Expiration_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Expiration_Date.errors }">						
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Name" class= "nonreqtext pr-2 py-2">Owner Name:</label>
              <input type="text" id="Owner_Name" formControlName="Owner_Name" class="form-control col-sm-8" size="30" maxlength="50" value="">
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Phone_Area" class="nonreqtext pr-2 py-2">Owner Phone #:</label>
              <div class="d-flex">
                <input type="text" id="Owner_Phone_Area" formControlName="Owner_Phone_Area" class="form-control" size="3" maxlength="3" value="">
                <label for="Owner_Phone_Prefix">&nbsp;-&nbsp;</label>
                <input type="text" id="Owner_Phone_Prefix" formControlName="Owner_Phone_Prefix" class="form-control" size="3" maxlength="3" value="">
                <label for="Owner_Phone_Suffix">&nbsp;-&nbsp;</label>
                <input type="text" id="Owner_Phone_Suffix" formControlName="Owner_Phone_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Type" class="reqtext pr-2 py-2">*Occupant Type:</label>
              <select id="Occupant_Type" formControlName="Occupant_Type" class="form-control col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Occupant_Type.errors }" required>
                  <option value="" selected="">  </option>
                  <option value="C"> Caretaker </option>
                  <option value="O"> Owner </option>
                  <option value="T"> Tenant </option>
                  <option value="V"> Vacant </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Name" class= "nonreqtext pr-2 py-2">Occupant Name:</label>
              <input type="text" id="Occupant_Name" formControlName="Occupant_Name" class="form-control col-sm-8" size="30" maxlength="30" value="">
              </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Phone_to_Show_Area" class="nonreqtext pr-2 py-2">Phone to Show:</label>
              <div class="d-flex">
                <input type="text" id="Phone_to_Show_Area" formControlName="Phone_to_Show_Area" class="form-control" size="3" maxlength="3" value="">
                <label for="Phone_to_Show_Prefix">&nbsp;-&nbsp;</label>
                <input type="text" id="Phone_to_Show_Prefix" formControlName="Phone_to_Show_Prefix" class="form-control" size="3" maxlength="3" value="">
                <label for="Phone_to_Show_Suffix">&nbsp;-&nbsp;</label>
                <input type="text" id="Phone_to_Show_Suffix" formControlName="Phone_to_Show_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
            </div>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step
        stepId="Basic Information"
        stepTitle="Basic Information" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">2</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Basic Information Form">
        <!---->
        <h6 class="subtitle pr-2 py-2">Property Information</h6>
        <!---->


        <div class="d-flex form-inline row">

          <!-- Left Column-->
          <div class="col-md-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="Bedrooms_ComboBox" class="nonreqtext pr-2 py-2">Bedroom(s):</label>
              </div>
              <div class="form-group col-sm-3">
                <select id="Bedrooms_ComboBox" formControlName="Bedrooms_ComboBox" class="form-control col-sm-10">
                    <option value="  0.00" selected> </option>
                    <option value="  1.00">1 </option>
                    <option value="  2.00">2 </option>
                    <option value="  3.00">3 </option>
                    <option value="  4.00">4 </option>
                    <option value="  5.00">5 </option>
                    <option value="  6.00">6 </option>
                    <option value="  7.00">7 </option>
                    <option value="  8.00">8 </option>
                    <option value="  9.00">9 </option>
                    <option value=" 10.00">10 </option>
                    <option value=" 11.00">11 </option>
                    <option value=" 12.00">12 </option>
                    <option value=" 13.00">13 </option>
                    <option value=" 14.00">14 </option>
                    <option value=" 15.00">15 </option>
                    <option value=" 16.00">16 </option>
                    <option value=" 17.00">17 </option>
                    <option value=" 18.00">18 </option>
                    <option value=" 19.00">19 </option>
                    <option value=" 20.00">20 </option>
                    <option value=" 21.00">21 </option>
                    <option value=" 22.00">22 </option>
                    <option value=" 23.00">23 </option>
                    <option value=" 24.00">24 </option>
                    <option value=" 25.00">25 </option>
                    <option value=" 26.00">26 </option>
                    <option value=" 27.00">27 </option>
                    <option value=" 28.00">28 </option>
                    <option value=" 29.00">29 </option>
                    <option value=" 30.00">30 </option>
                    <option value=" 31.00">31 </option>
                    <option value=" 32.00">32 </option>
                    <option value=" 33.00">33 </option>
                    <option value=" 34.00">34 </option>
                    <option value=" 35.00">35 </option>
                    <option value=" 36.00">36 </option>
                    <option value=" 37.00">37 </option>
                    <option value=" 38.00">38 </option>
                    <option value=" 39.00">39 </option>
                    <option value=" 40.00">40 </option>
                    <option value=" 41.00">41 </option>
                    <option value=" 42.00">42 </option>
                    <option value=" 43.00">43 </option>
                    <option value=" 44.00">44 </option>
                    <option value=" 45.00">45 </option>
                    <option value=" 46.00">46 </option>
                    <option value=" 47.00">47 </option>
                    <option value=" 48.00">48 </option>
                    <option value=" 49.00">49 </option>
                    <option value=" 50.00">50 </option>
                    <option value=" 51.00">51 </option>
                    <option value=" 52.00">52 </option>
                    <option value=" 53.00">53 </option>
                    <option value=" 54.00">54 </option>
                    <option value=" 55.00">55 </option>
                    <option value=" 56.00">56 </option>
                    <option value=" 57.00">57 </option>
                    <option value=" 58.00">58 </option>
                    <option value=" 59.00">59 </option>
                    <option value=" 60.00">60 </option>
                    <option value=" 61.00">61 </option>
                    <option value=" 62.00">62 </option>
                    <option value=" 63.00">63 </option>
                    <option value=" 64.00">64 </option>
                    <option value=" 65.00">65 </option>
                    <option value=" 66.00">66 </option>
                    <option value=" 67.00">67 </option>
                    <option value=" 68.00">68 </option>
                    <option value=" 69.00">69 </option>
                    <option value=" 70.00">70 </option>
                    <option value=" 71.00">71 </option>
                    <option value=" 72.00">72 </option>
                    <option value=" 73.00">73 </option>
                    <option value=" 74.00">74 </option>
                    <option value=" 75.00">75 </option>
                    <option value=" 76.00">76 </option>
                    <option value=" 77.00">77 </option>
                    <option value=" 78.00">78 </option>
                    <option value=" 79.00">79 </option>
                    <option value=" 80.00">80 </option>
                    <option value=" 81.00">81 </option>
                    <option value=" 82.00">82 </option>
                    <option value=" 83.00">83 </option>
                    <option value=" 84.00">84 </option>
                    <option value=" 85.00">85 </option>
                    <option value=" 86.00">86 </option>
                    <option value=" 87.00">87 </option>
                    <option value=" 88.00">88 </option>
                    <option value=" 89.00">89 </option>
                    <option value=" 90.00">90 </option>
                </select>
              </div>
            </div>
          </div> <!--end of left column-->

          <!--Right Column-->
          <div class="col-md-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="Bathrooms_ComboBox" class="nonreqtext pr-2 py-2">Bathrooms:</label>
              </div>
              <div class="form-group col-sm-3">
                <select id="Bathrooms_ComboBox" formControlName="Bathrooms_ComboBox" class="form-control col-sm-10">
                  <option value="  0.00" selected></option>
                  <option value="  1.00"> 1 </option>
                  <option value="  1.50"> 1 1/2 </option>
                  <option value="  1.75"> 1 3/4 </option>
                  <option value="  2.00"> 2 </option>
                  <option value="  2.50"> 2 1/2 </option>
                  <option value="  2.75"> 2 3/4 </option>
                  <option value="  3.00"> 3 </option>
                  <option value="  3.50"> 3 1/2 </option>
                  <option value="  3.75"> 3 3/4 </option>
                  <option value="  4.00"> 4 </option>
                  <option value="  4.50"> 4 1/2 </option>
                  <option value="  4.75"> 4 3/4 </option>
                  <option value="  5.00"> 5 </option>
                  <option value="  6.00"> 6 </option>
                  <option value="  7.00"> 7 </option>
                  <option value="  8.00"> 8 </option>
                  <option value="  9.00"> 9 </option>
                  <option value=" 10.00"> 10 </option>
                  <option value=" 11.00"> 11 </option>
                  <option value=" 12.00"> 12 </option>
                  <option value=" 13.00"> 13 </option>
                  <option value=" 14.00"> 14 </option>
                  <option value=" 15.00"> 15 </option>
                  <option value=" 16.00"> 16 </option>
                  <option value=" 17.00"> 17 </option>
                  <option value=" 18.00"> 18 </option>
                  <option value=" 19.00"> 19 </option>
                  <option value=" 20.00"> 20 </option>
                  <option value=" 21.00"> 21 </option>
                  <option value=" 22.00"> 22 </option>
                  <option value=" 23.00"> 23 </option>
                  <option value=" 24.00"> 24 </option>
                  <option value=" 25.00"> 25 </option>
                  <option value=" 26.00"> 26 </option>
                  <option value=" 27.00"> 27 </option>
                  <option value=" 28.00"> 28 </option>
                  <option value=" 29.00"> 29 </option>
                  <option value=" 30.00"> 30 </option>
                  <option value=" 31.00"> 31 </option>
                  <option value=" 32.00"> 32 </option>
                  <option value=" 33.00"> 33 </option>
                  <option value=" 34.00"> 34 </option>
                  <option value=" 35.00"> 35 </option>
                  <option value=" 36.00"> 36 </option>
                  <option value=" 37.00"> 37 </option>
                  <option value=" 38.00"> 38 </option>
                  <option value=" 39.00"> 39 </option>
                  <option value=" 40.00"> 40 </option>
                  <option value=" 41.00"> 41 </option>
                  <option value=" 42.00"> 42 </option>
                  <option value=" 43.00"> 43 </option>
                  <option value=" 44.00"> 44 </option>
                  <option value=" 45.00"> 45 </option>
                  <option value=" 46.00"> 46 </option>
                  <option value=" 47.00"> 47 </option>
                  <option value=" 48.00"> 48 </option>
                  <option value=" 49.00"> 49 </option>
                  <option value=" 50.00"> 50 </option>
                  <option value=" 51.00"> 51 </option>
                  <option value=" 52.00"> 52 </option>
                  <option value=" 53.00"> 53 </option>
                  <option value=" 54.00"> 54 </option>
                  <option value=" 55.00"> 55 </option>
                  <option value=" 56.00"> 56 </option>
                  <option value=" 57.00"> 57 </option>
                  <option value=" 58.00"> 58 </option>
                  <option value=" 59.00"> 59 </option>
                  <option value=" 60.00"> 60 </option>
                  <option value=" 61.00"> 61 </option>
                  <option value=" 62.00"> 62 </option>
                  <option value=" 63.00"> 63 </option>
                  <option value=" 64.00"> 64 </option>
                  <option value=" 65.00"> 65 </option>
                  <option value=" 66.00"> 66 </option>
                  <option value=" 67.00"> 67 </option>
                  <option value=" 68.00"> 68 </option>
                  <option value=" 69.00"> 69 </option>
                  <option value=" 70.00"> 70 </option>
                  <option value=" 71.00"> 71 </option>
                  <option value=" 72.00"> 72 </option>
                  <option value=" 73.00"> 73 </option>
                  <option value=" 74.00"> 74 </option>
                  <option value=" 75.00"> 75 </option>
                  <option value=" 76.00"> 76 </option>
                  <option value=" 77.00"> 77 </option>
                  <option value=" 78.00"> 78 </option>
                  <option value=" 79.00"> 79 </option>
                  <option value=" 80.00"> 80 </option>
                  <option value=" 81.00"> 81 </option>
                  <option value=" 82.00"> 82 </option>
                  <option value=" 83.00"> 83 </option>
                  <option value=" 84.00"> 84 </option>
                  <option value=" 85.00"> 85 </option>
                  <option value=" 86.00"> 86 </option>
                  <option value=" 87.00"> 87 </option>
                  <option value=" 88.00"> 88 </option>
                  <option value=" 89.00"> 89 </option>
                  <option value=" 90.00"> 90 </option>
                </select>
              </div>
            </div>
          </div> <!--end of right column-->

        </div> <!--end of page/section-->


        <div class="d-flex form-inline row">

          <!-- Left Column-->
          <div class="col-md-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="Struc_Sq_Ft" class= "reqtext pr-2 py-2">*Aprx Sq Ft </label>
              </div>
              <div class="form-group col-sm-4">
                <input type="text" id="Struc_Sq_Ft" formControlName="Struc_Sq_Ft" class="form-control col-sm-10" size="9" maxlength="9" placeholder="0"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.Struc_Sq_Ft.errors }" required>
              </div>
            </div>
          </div> <!--end of left column-->

          <!--Right Column-->
          <div class="col-md-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="selSqFtSource" class="reqtext pr-2 py-2">*Sq Ft Source:</label>
              </div>
              <div class="form-group col-sm-4">
                <select id="selSqFtSource" formControlName="SqFt_Source" class="form-control col-sm-10"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.SqFt_Source.errors }" required>
                    <option value="" selected="">  </option>
                    <option value="SLLR"> Seller </option>
                    <option value="APPR"> Appraiser </option>
                    <option value="TAXR"> Tax Records </option>
                    <option value="OTHR"> Other </option>
                </select>
              </div>
            </div>
          </div> <!--end of right column-->

        </div> <!--end of page/section-->


        <div class="d-flex form-inline row">

          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="Lot_Sq_Ft" class= "nonreqtext pr-2 py-2">Lot Size:</label>
              </div>
              <div class="form-group col-sm-4">
                <input type="text" id="Lot_Sq_Ft" formControlName="Lot_Sq_Ft" class="form-control col-sm-10" size="12" maxlength="9" value="">
              </div>
              <div class="d-flex form-group col-sm-4">
                <label for="Lot_Measurement" class="nonreqtext px-1">
                  <input type="radio" id="Lot_Measurement" formControlName="Lot_Measurement" class="form-control" value="S" checked="">&nbsp;SqFt
                </label>
                <label for="Lot_Measurement_Acres" class="nonreqtext px-1">
                  <input type="radio" id="Lot_Measurement_Acres" formControlName="Lot_Measurement" class="form-control" value="A">&nbsp;Acres
                </label>
              </div>
            </div>
          </div> <!--end of left column-->

          <!--Right Column-->
          <div class="col-lg-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="selLotSizeSource" class="nonreqtext pr-2 py-2">Lot Size Source:</label>
              </div>
              <div class="form-group col-sm-4">
                <select id="selLotSizeSource" formControlName="LotSize_Source" class="form-control col-sm-10">
                  <option value="" selected="">  </option>
                  <option value="APPR"> Appraiser </option>
                  <option value="OTHR"> Other </option>
                  <option value="SLLR"> Seller </option>
                  <option value="TAXR"> Tax Records </option>
                </select>
              </div>
            </div>
          </div> <!--end of right column-->

        </div> <!--end of page/section-->


        <div class="d-flex form-inline row">

          <!-- Left Column-->
          <div class="col-md-4 align-self-start">
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="Year_Built" class= "reqtext pr-2 py-2">*Year Built:</label>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="Year_Built" formControlName="Year_Built" class="form-control col-sm-10" size="8" maxlength="4" value=""  [ngClass]="{ 'is-invalid alert-danger': submitted && f.Year_Built.errors }" required>
              </div>
            </div>
          </div> <!--end of left column-->

          <!--Right Column-->
          <div class="col-md-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-5">
                <label for="Unknown_Year" class="nonreqtext p-2">- or (select one) -</label>
              </div>
              <div class="form-group col-sm-6">
                <select id="Unknown_Year" formControlName="Unknown_Year" class="form-control">
                    <option value="0" selected="">  </option>
                    <option value="1"> New Construction </option>
                    <option value="2"> Under Construction </option>
                    <option value="3"> Unknown </option>
                </select>
              </div>
            </div>
          </div> <!--end of right column-->

        </div> <!--end of page/section-->


        <br>


        <div class="row">
          <div class="col">
            <h6><label for="Property_Description_contnet"><span class="reqsubtitle pr-1">*Marketing Remarks:</span></label><span class="reqtext formnote">&nbsp;(Maximum 1000)</span></h6>
            <textarea id="Property_Description" formControlName="Property_Description" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.Property_Description.errors }" required></textarea>
            <span class="reqtext formnote">&nbsp;{{ (1000 - f.Property_Description.value.length) }} characters left</span>
          </div>
        </div>


        <br>


        <div class="row">
          <div class="col">
            <h6><label for="Agent_Only_Remarks"><span class="subtitle pr-1">Agent Remarks:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 1000)</span></h6>
            <textarea id="Agent_Only_Remarks" formControlName="Agent_Only_Remarks" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"></textarea>
            <span class="reqtext formnote">&nbsp;{{ (1000 - f.Agent_Only_Remarks.value.length) }} characters left</span>
          </div>
        </div>


        <br>


        <div class="row">
          <div class="col">
            <h6><label for="Directions"><span class="reqsubtitle pr-1">*Directions to Property:</span></label><span class="reqtext formnote">&nbsp;(Maximum 250)</span></h6>
            <textarea id="Directions" formControlName="Directions" class="form-control" spellcheck="true" rows="4" cols=""  maxlength="250"  [ngClass]="{ 'is-invalid alert-danger': submitted && f.Directions.errors }" required></textarea>
            <span class="reqtext formnote">&nbsp;{{ (250 - f.Directions.value.length) }} characters left</span>
          </div>
        </div>

        </div>
      </aw-wizard-step>
      <aw-wizard-step  
        stepId="General Features"
        stepTitle="General Features" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">3</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="General Features Form">
          <div class="d-flex form-inline row pt-1">


            <!-- Left Column-->
    
    
            <div class="col-lg-6 align-self-start">
    
    
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="0600NAME" class="nonreqtext pr-2 py-2 col-form-label">Business Name:</label>
                </div>
                <div class="form-group col-sm-8 justify-content-end">
                  <input type="text" id="0600NAME" formControlName="0600NAME" class="form-control flex-grow-1" size="" maxlength="40" placeholder="">
                </div>
              </div>
    
              <br>
    
              <!--break for next group in the left column-->
              <br>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_CNSS" class= "nonreqtext pr-2 py-2 col-form-label">Census Tract:</label>
                  <input type="text" id="NUM_CNSS" formControlName="CNSS" class="form-control col-sm-5" size="" maxlength="6">
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0610CNSS" formControlName="0610CNSS" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                </div>
              </div>
      
    
            <!--End of left column-->
            </div>
    
            <!--Right Column-->
    
          <div class="col-lg-6 align-self-start">
    
    
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_AREA" class="reqtext pr-2 py-2 col-form-label">*Region:</label>
                <select id="CB_AREA" formControlName="AREA" class="form-control col-sm-5" [ngClass]="{ 'is-invalid alert-danger': submitted && f.AREA.errors }" required>
                  <option value=""></option>
                  <option class="mBlackText" value="URBN">Urban </option>
                  <option class="mBlackText" value="SUBU">Rural </option>
                  <option class="mBlackText" value="FTHL">Foothill </option>
                  <option class="mBlackText" value="MNTN">Mountain </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="0620AREA" formControlName="0620AREA" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
              </div>
            </div>
    
    
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="Area_Fill_In" class="reqtext pr-2 py-2 col-form-label">*Area:</label>
                <input type="text" id="Area_Fill_In" formControlName="Area_Fill_In" class="form-control col-sm-5" size="" maxlength="60" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Area_Fill_In.errors }" required>
              </div>
            </div>
    
    
            <div class="row form-group col-sm-12">
              <span class="reqtext pr-2 py-2 formnote">AREA refers to the last 3 digits of the property zipcode. Example: 93711. AREA = 711.</span>
            </div>
    
    
            <!--break for next group in the right column-->
            <br>
    
    
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="0650ZONE" class="nonreqtext pr-2 py-2 col-form-label">Zoning:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0650ZONE" formControlName="0650ZONE" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
    
    
            <!--End of right column-->
          </div>				
        </div>
    
    
        <!-------------------------------------------------------------->
        <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
        <!-------------------------------------------------------------->
    
    
          <div class="d-flex form-inline row">
    
    
            <!-- Left Column-->
    
    
            <div class="col-lg-6 align-self-start">
    
    
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.BUSINESSTYPE.errors }"><span class="pr-2 py-2 reqtext">*Business Type:</span></span>
    
              <!-- One row for a new input group -->
              <div formGroupName="BUSINESSTYPE" required>
              <div class="row fixcheck">
    
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="BUSTREST" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTREST" formControlName="BUSTREST" class="form-check-input">Restaurant</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTREST" formControlName="Checkbox_Comment_BUSTREST" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTBUTY" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTBUTY" formControlName="BUSTBUTY" class="form-check-input">Beauty</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTBUTY" formControlName="Checkbox_Comment_BUSTBUTY" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTGRCY" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTGRCY" formControlName="BUSTGRCY" class="form-check-input">Grocery</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTGRCY" formControlName="Checkbox_Comment_BUSTGRCY" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTFSTF" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTFSTF" formControlName="BUSTFSTF" class="form-check-input">Fast Food</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTFSTF" formControlName="Checkbox_Comment_BUSTFSTF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTMNIM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTMNIM" formControlName="BUSTMNIM" class="form-check-input">Mini Mart</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTMNIM" formControlName="Checkbox_Comment_BUSTMNIM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTLIQR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTLIQR" formControlName="BUSTLIQR" class="form-check-input">Liquor Store</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTLIQR" formControlName="Checkbox_Comment_BUSTLIQR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTBAR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTBAR" formControlName="BUSTBAR" class="form-check-input">Bar</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTBAR" formControlName="Checkbox_Comment_BUSTBAR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTMRCH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTMRCH" formControlName="BUSTMRCH" class="form-check-input">Merchandise</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTMRCH" formControlName="Checkbox_Comment_BUSTMRCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTCLTH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTCLTH" formControlName="BUSTCLTH" class="form-check-input">Clothing</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTCLTH" formControlName="Checkbox_Comment_BUSTCLTH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>

              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTAPPL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTAPPL" formControlName="BUSTAPPL" class="form-check-input">Appliance</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTAPPL" formControlName="Checkbox_Comment_BUSTAPPL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>

                                          <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTCONT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTCONT" formControlName="BUSTCONT" class="form-check-input">Contract</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTCONT" formControlName="Checkbox_Comment_BUSTCONT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>

              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="BUSTCOMM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="BUSTCOMM" formControlName="BUSTCOMM" class="form-check-input">See Comments</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_BUSTCOMM" formControlName="Checkbox_Comment_BUSTCOMM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
            <br>
            <!--End of left column-->
            </div>
    
    
            <!--Right Column-->
    
            <div class="col-lg-6 align-self-start">
    
    
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.LOCATION.errors }"><span class="pr-2 py-2 reqtext">*Location:</span></span>
    
    
              <div formGroupName="LOCATION" required>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="LCTNCRNR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNCRNR" formControlName="LCTNCRNR" class="form-check-input">Corner</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNCRNR" formControlName="Checkbox_Comment_LCTNCRNR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNBUSD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNBUSD" formControlName="LCTNBUSD" class="form-check-input">Business District</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNBUSD" formControlName="Checkbox_Comment_LCTNBUSD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNINDD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNINDD" formControlName="LCTNINDD" class="form-check-input">Industrial District</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNINDD" formControlName="Checkbox_Comment_LCTNINDD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNPARK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNPARK" formControlName="LCTNPARK" class="form-check-input">Park</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNPARK" formControlName="Checkbox_Comment_LCTNPARK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNBLOK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNBLOK" formControlName="LCTNBLOK" class="form-check-input">Mid Block</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNBLOK" formControlName="Checkbox_Comment_LCTNBLOK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNSTRP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNSTRP" formControlName="LCTNSTRP" class="form-check-input">Strip Center</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNSTRP" formControlName="Checkbox_Comment_LCTNSTRP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNSHOP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNSHOP" formControlName="LCTNSHOP" class="form-check-input">Shopping Center</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNSHOP" formControlName="Checkbox_Comment_LCTNSHOP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNPROF" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNPROF" formControlName="LCTNPROF" class="form-check-input">Professional Center</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNPROF" formControlName="Checkbox_Comment_LCTNPROF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
              <br>
    
            <!--End of right column-->
            </div>
    
    
          </div>
    
    
        <!-------------------------------------------------------------->
        <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
        <!-------------------------------------------------------------->
    
    
          <div class="d-flex form-inline row">
    
    
            <!-- Left Column-->
    
    
            <div class="col-lg-6 align-self-start">
              
    
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.INCLUDEDINSALE.errors }"><span class="pr-2 py-2 reqtext">*Included in Sale:</span></span>
    
    
              <!-- One row for a new input group -->
              <div formGroupName="INCLUDEDINSALE" required>
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="INCLASST" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLASST" formControlName="INCLASST" class="form-check-input">Assets only</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLASST" formControlName="Checkbox_Comment_INCLASST" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLBUSE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLBUSE" formControlName="INCLBUSE" class="form-check-input">Business Entity</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLBUSE" formControlName="Checkbox_Comment_INCLBUSE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLINVN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLINVN" formControlName="INCLINVN" class="form-check-input">Inventory</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLINVN" formControlName="Checkbox_Comment_INCLINVN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLFIXT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLFIXT" formControlName="INCLFIXT" class="form-check-input">Fixtures</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLFIXT" formControlName="Checkbox_Comment_INCLFIXT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLEQIP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLEQIP" formControlName="INCLEQIP" class="form-check-input">Equipment</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLEQIP" formControlName="Checkbox_Comment_INCLEQIP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLNONC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLNONC" formControlName="INCLNONC" class="form-check-input">Non Compete</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLNONC" formControlName="Checkbox_Comment_INCLNONC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLTRNG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLTRNG" formControlName="INCLTRNG" class="form-check-input">Training</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLTRNG" formControlName="Checkbox_Comment_INCLTRNG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLLICN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLLICN" formControlName="INCLLICN" class="form-check-input">Licenses</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLLICN" formControlName="Checkbox_Comment_INCLLICN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLLEAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLLEAS" formControlName="INCLLEAS" class="form-check-input">Leased Equipment</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLLEAS" formControlName="Checkbox_Comment_INCLLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLPHON" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLPHON" formControlName="INCLPHON" class="form-check-input">Phone System</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLPHON" formControlName="Checkbox_Comment_INCLPHON" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLSECR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLSECR" formControlName="INCLSECR" class="form-check-input">Security System</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLSECR" formControlName="Checkbox_Comment_INCLSECR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="INCLFRCH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLFRCH" formControlName="INCLFRCH" class="form-check-input">Franchise</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLFRCH" formControlName="Checkbox_Comment_INCLFRCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="INCLGOOD" formControlName="INCLGOOD" class="form-check-input">Goodwill</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_INCLGOOD" formControlName="Checkbox_Comment_INCLGOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
    
    
              <!--break for next group in the left column-->
              <br>
    
    
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Licenses & Permits:</span></span>
    
              <!-- One row for a new input group -->
              <div class="row fixcheck">
    
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="XLICBEER" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICBEER" formControlName="XLICBEER" class="form-check-input">Beer & Wine</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICBEER" formControlName="Checkbox_Comment_XLICBEER" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="XLICLIQR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICLIQR" formControlName="XLICLIQR" class="form-check-input">Liquor</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICLIQR" formControlName="Checkbox_Comment_XLICLIQR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="XLICPROF" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICPROF" formControlName="XLICPROF" class="form-check-input">Professional</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICPROF" formControlName="Checkbox_Comment_XLICPROF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="XLICRSTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICRSTR" formControlName="XLICRSTR" class="form-check-input">Restrictions</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICRSTR" formControlName="Checkbox_Comment_XLICRSTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="XLICPERM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICPERM" formControlName="XLICPERM" class="form-check-input">Use Permit</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICPERM" formControlName="Checkbox_Comment_XLICPERM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="XLICBUSL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICBUSL" formControlName="XLICBUSL" class="form-check-input">Business License</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICBUSL" formControlName="Checkbox_Comment_XLICBUSL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="XLICONSL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICONSL" formControlName="XLICONSL" class="form-check-input">On Sale</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICONSL" formControlName="Checkbox_Comment_XLICONSL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="XLICOFSL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="XLICOFSL" formControlName="XLICOFSL" class="form-check-input">Off Sale</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_XLICOFSL" formControlName="Checkbox_Comment_XLICOFSL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <br>
    
    
            </div> <!--end of left column-->
    
    
            <!--Right Column-->
    
    
            <div class="col-lg-6 align-self-start">
    
    
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Facilities:</span></span>
    
              <!-- One row for a new input group -->
              <div class="row fixcheck">
    
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="FCLTREST" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTREST" formControlName="FCLTREST" class="form-check-input">Restrooms</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTREST" formControlName="Checkbox_Comment_FCLTREST" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTDOOR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTDOOR" formControlName="FCLTDOOR" class="form-check-input">Truck Door</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTDOOR" formControlName="Checkbox_Comment_FCLTDOOR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTDOCK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTDOCK" formControlName="FCLTDOCK" class="form-check-input">Loading Dock</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTDOCK" formControlName="Checkbox_Comment_FCLTDOCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTSPRK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTSPRK" formControlName="FCLTSPRK" class="form-check-input">Fire Sprinklers</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTSPRK" formControlName="Checkbox_Comment_FCLTSPRK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTSECS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTSECS" formControlName="FCLTSECS" class="form-check-input">Security System</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTSECS" formControlName="Checkbox_Comment_FCLTSECS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTDISP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTDISP" formControlName="FCLTDISP" class="form-check-input">Display Window</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTDISP" formControlName="Checkbox_Comment_FCLTDISP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTSIGN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTSIGN" formControlName="FCLTSIGN" class="form-check-input">Signage</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTSIGN" formControlName="Checkbox_Comment_FCLTSIGN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTHAND" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTHAND" formControlName="FCLTHAND" class="form-check-input">Handicap Access</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTHAND" formControlName="Checkbox_Comment_FCLTHAND" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FCLTELEV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FCLTELEV" formControlName="FCLTELEV" class="form-check-input">Elevator</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FCLTELEV" formControlName="Checkbox_Comment_FCLTELEV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
    
              <!--break for next group in the right column-->
              <br>
    
    
    
    
                <div class="row">
                  <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_PRKS" class="nonreqtext pr-2 py-2 col-form-label">Parking Spaces:</label>
                    <input type="text" id="NUM_PRKS" formControlName="PRKS" class="form-control col-sm-4" value="0" size="" maxlength="3">
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0710PRKS" formControlName="0710PRKS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                  </div>
                </div>
    
    
              <!--break for next group in the right column-->
              <br>
    
    
                <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.ADDITIONALPARKING.errors }"><span class="pr-2 py-2 reqtext">*Additional Parking:</span></span>
    
    
                <!-- One row for a new input group -->
                <div formGroupName="ADDITIONALPARKING" required>
                <div class="row fixcheck">
                  
                  <!-- Within that row, specify column length  -->
                  <div class="form-group col-sm-6">
                    <label for="PARKUNCV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKUNCV" formControlName="PARKUNCV" class="form-check-input">Uncovered</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKUNCV" formControlName="Checkbox_Comment_PARKUNCV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                  </div>
                </div>
      
      
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="PARKCOVR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKCOVR" formControlName="PARKCOVR" class="form-check-input">Covered</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKCOVR" formControlName="Checkbox_Comment_PARKCOVR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
      
      
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="PARKGARG" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKGARG" formControlName="PARKGARG" class="form-check-input">Garage</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKGARG" formControlName="Checkbox_Comment_PARKGARG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
      
      
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="PARKASSN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKASSN" formControlName="PARKASSN" class="form-check-input">Assigned Spaces</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKASSN" formControlName="Checkbox_Comment_PARKASSN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
      
      
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="PARKSTRT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKSTRT" formControlName="PARKSTRT" class="form-check-input">Street</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKSTRT" formControlName="Checkbox_Comment_PARKSTRT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
      
      
      
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="PARKNONE" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKNONE" formControlName="PARKNONE" class="form-check-input">None</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKNONE" formControlName="Checkbox_Comment_PARKNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                </div>
      
                <!--break for next group in the right column-->
                <br>
    
    
                <div class="row form-group col-sm-12 justify-content-between">
                  <label for="CB_FEMP" class="reqtext pr-2 py-2 col-form-label">*Full Time Employees:</label>
                </div>
                <div class="row">	
                  <div class="form-group col-sm-6 justify-content-end">
                    <select id="CB_FEMP" formControlName="FEMP" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.FEMP.errors }" required>
                      <option value=""></option>
                      <option class="mBlackText" value="1TO5">1 - 5 </option>
                      <option class="mBlackText" value="6T10">6 - 10 </option>
                      <option class="mBlackText" value="1115">11 - 15 </option>
                      <option class="mBlackText" value="1620">16 - 20 </option>
                      <option class="mBlackText" value="21PS">21 plus </option>
                    </select>
                  </div>
                  <div class="form-group col-sm-6">
                    <input type="text" id="0730FEMP" formControlName="0730FEMP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                  </div>
                </div>
      
    
                <!--break for next group in the right column-->
                <br>
    
    
            </div> <!--end of right column-->
    
    
          </div> <!--end of page/section-->
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
          <div formGroupName="DOCUMENTSAVAILABLE" required>
          <div class="d-flex form-inline row">
    
    
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
    
    
    
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.DOCUMENTSAVAILABLE.errors }"><span class="pr-2 py-2 reqtext">*Documents Available:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="DILOPRLS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOPRLS" formControlName="DILOPRLS" class="form-check-input">Profit & Loss</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOPRLS" formControlName="Checkbox_Comment_DILOPRLS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILOAPPR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOAPPR" formControlName="DILOAPPR" class="form-check-input">Appraisal</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOAPPR" formControlName="Checkbox_Comment_DILOAPPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILOFIXT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOFIXT" formControlName="DILOFIXT" class="form-check-input">List of Fixtures</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOFIXT" formControlName="Checkbox_Comment_DILOFIXT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILOLIQP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOLIQP" formControlName="DILOLIQP" class="form-check-input">List of Equipment</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOLIQP" formControlName="Checkbox_Comment_DILOLIQP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILOINVT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOINVT" formControlName="DILOINVT" class="form-check-input">List of Inventory</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOINVT" formControlName="Checkbox_Comment_DILOINVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
            </div> <!--end of left column-->
    
    
            <!--Right Column-->
    
    
            <div class="col-lg-6 align-self-start">
    
    
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <div class="p-2 hidebreak"><br></div>
                </div>
              </div> 
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILOLEAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOLEAS" formControlName="DILOLEAS" class="form-check-input">Lease</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOLEAS" formControlName="Checkbox_Comment_DILOLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILOITAX" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOITAX" formControlName="DILOITAX" class="form-check-input">Income Tax Returns</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOITAX" formControlName="Checkbox_Comment_DILOITAX" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILOCASH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILOCASH" formControlName="DILOCASH" class="form-check-input">Cash Flow Analysis</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILOCASH" formControlName="Checkbox_Comment_DILOCASH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="DILONDOC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="DILONDOC" formControlName="DILONDOC" class="form-check-input">No Documents</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_DILONDOC" formControlName="Checkbox_Comment_DILONDOC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
            <br>
    
    
            </div> <!--end of right column-->
          </div>
    
          </div> <!--end of page/section-->
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="d-flex form-inline row">
    
    
            <!-- Left Column-->
    
    
            <div class="col-lg-6 align-self-start">
    
    
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.SHOWINGINSTRUCTIONS.errors }"><span class="pr-2 py-2 reqtext">*Showing Instructions:</span></span>
    
    
              <!-- One row for a new input group -->
              <div formGroupName="SHOWINGINSTRUCTIONS" required>
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="SHOWCALA" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWCALA" formControlName="SHOWCALA" class="form-check-input">Call Agent</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWCALA" formControlName="Checkbox_Comment_SHOWCALA" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWDNDB" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWDNDB" formControlName="SHOWDNDB" class="form-check-input">Do Not Disturb Bus.</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWDNDB" formControlName="Checkbox_Comment_SHOWDNDB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWDRIV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWDRIV" formControlName="SHOWDRIV" class="form-check-input">Drive By</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWDRIV" formControlName="Checkbox_Comment_SHOWDRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWCALO" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWCALO" formControlName="SHOWCALO" class="form-check-input">Call Owner</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWCALO" formControlName="Checkbox_Comment_SHOWCALO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWCALT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWCALT" formControlName="SHOWCALT" class="form-check-input">Call Tenant</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWCALT" formControlName="Checkbox_Comment_SHOWCALT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <br>
    
    
            </div> <!--end of left column-->
            </div>
    
            <!--Right Column-->
    
    
            <div class="col-lg-6 align-self-start">
    
    
              <div class="row">
                <div class="form-group col-sm-8">
                  <label for="Showing_Instructions" class="nonreqtext pr-2 py-2 col-form-label">Showing Instructions:&nbsp;<span class="formnote">(Maximum 60)</span></label>
                </div>
                <div class="form-group col-sm-12 justify-content-end">
                  <textarea id="Showing_Instructions" formControlName="Showing_Instructions" class="form-control flex-grow-1" spellcheck="true" rows="" cols="50" maxlength="60"></textarea>
                </div>
              </div>
    
    
            </div> <!--end of right column-->
    
    
          </div> <!--end of page/section-->
        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepId="Financial Data"
        stepTitle="Financial Data" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">4</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Financial Data Form">
          <div class="d-flex form-inline row pt-1">


            <!-- Left Column-->
    
    
            <div class="col-lg-6 align-self-start">
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_GSAL" class="nonreqtext pr-2 py-2 col-form-label">Gross Sales:</label>
                  <span class="nonreqtext">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$</span>
                  <input type="text" id="NUM_GSAL" formControlName="GSAL" class="form-control col-sm-4" size="" maxlength="5" value="">
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0820GSAL" formControlName="0820GSAL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_GEXP" class="nonreqtext pr-2 py-2 col-form-label">Gross Expense:</label>
                  <span class="nonreqtext">$</span>
                  <input type="text" id="NUM_GEXP" formControlName="GEXP" class="form-control col-sm-4" size="" maxlength="5" value="">
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0830GEXP" formControlName="0830GEXP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_NINC" class="nonreqtext pr-2 py-2 col-form-label">Net Income:</label>
                  <span class="nonreqtext">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$</span>
                  <input type="text" id="NUM_NINC" formControlName="NINC" class="form-control col-sm-4" size="" maxlength="5" value="">
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0840NINC" formControlName="0840NINC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
    
    
    
            </div> <!--end of left column-->
    
    
            <!--Right Column-->
    
            <div class="col-lg-6 align-self-start">
    
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_REIN" class="reqtext pr-2 py-2 col-form-label">*Real Estate Included:</label>
                  <select id="CB_REIN" formControlName="REIN" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.REIN.errors }" required>
                    <option value=""></option>
                    <option class="mBlackText" value="YESY">Yes </option>
                    <option class="mBlackText" value="NONO">No </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0850REIN" formControlName="0850REIN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_REAL" class="reqtext pr-2 py-2 col-form-label">*Real Estate:</label>
                  <select id="CB_REAL" formControlName="REAL" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.REAL.errors }" required>
                    <option value=""></option>
                    <option class="mBlackText" value="OWND">Owned </option>
                    <option class="mBlackText" value="LEAS">Leased </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0860REAL" formControlName="0860REAL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_FRCH" class="reqtext pr-2 py-2 col-form-label">*Franchise:</label>
                  <select id="CB_FRCH" formControlName="FRCH" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.FRCH.errors }" required>
                    <option value=""></option>
                    <option class="mBlackText" value="YESY">Yes </option>
                    <option class="mBlackText" value="NONO">No </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0870FRCH" formControlName="0870FRCH" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_CHAN" class="reqtext pr-2 py-2 col-form-label">*Chain:</label>
                  <select id="CB_CHAN" formControlName="CHAN" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.CHAN.errors }" required>
                    <option value=""></option>
                    <option class="mBlackText" value="YESY">Yes </option>
                    <option class="mBlackText" value="NONO">No </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0880CHAN" formControlName="0880CHAN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="0890EQTY" class="nonreqtext pr-2 py-2 col-form-label">Equity:</label>
                </div>
                <div class="form-group col-sm-8 justify-content-end">
                  <input type="text" id="0890EQTY" formControlName="0890EQTY" class="form-control flex-grow-1" size="" maxlength="40" placeholder="">
                </div>
              </div>
    
    
            </div> <!--end of right column-->
    
    
          </div> <!--end of page/section-->
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="d-flex form-inline row">
    
    
            <!-- Left Column-->
    
            <div class="col-lg-6 align-self-start">
    
    
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Lease Information:</span></span>
    
              <!-- One row for a new input group -->
              <div class="row fixcheck">
    
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="LEASSR8T" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASSR8T" formControlName="LEASSR8T" class="form-check-input">Straight</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASSR8T" formControlName="Checkbox_Comment_LEASSR8T" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASMTOM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASMTOM" formControlName="LEASMTOM" class="form-check-input">Month to Month</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASMTOM" formControlName="Checkbox_Comment_LEASMTOM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASPRCT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASPRCT" formControlName="LEASPRCT" class="form-check-input">Percentage</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASPRCT" formControlName="Checkbox_Comment_LEASPRCT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASESCL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASESCL" formControlName="LEASESCL" class="form-check-input">Escalations</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASESCL" formControlName="Checkbox_Comment_LEASESCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASRENW" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASRENW" formControlName="LEASRENW" class="form-check-input">Option to Renew</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASRENW" formControlName="Checkbox_Comment_LEASRENW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASPRCH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASPRCH" formControlName="LEASPRCH" class="form-check-input">Option to Purchase</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASPRCH" formControlName="Checkbox_Comment_LEASPRCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASNET" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASNET" formControlName="LEASNET" class="form-check-input">Net</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASNET" formControlName="Checkbox_Comment_LEASNET" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASTNET" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASTNET" formControlName="LEASTNET" class="form-check-input">Triple Net</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASTNET" formControlName="Checkbox_Comment_LEASTNET" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASASSN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASASSN" formControlName="LEASASSN" class="form-check-input">Assigned</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASASSN" formControlName="Checkbox_Comment_LEASASSN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LEASNEGO" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LEASNEGO" formControlName="LEASNEGO" class="form-check-input">Negotiable</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LEASNEGO" formControlName="Checkbox_Comment_LEASNEGO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
            </div> <!--end of left column-->
    
    
            <!--Right Column-->
    
            <div class="col-lg-6 align-self-start">
    
    
              <br>
    
    
              <div class="row ">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_LRMN" class="nonreqtext pr-2 py-2 col-form-label">Lease Remaining:</label>
                  <select id="CB_LRMN" formControlName="LRMN" class="form-control col-sm-5">
                      <option value=""> </option>
                      <option class="mBlackText" value="LESS">Less Than 1 Year&nbsp;</option>
                      <option class="mBlackText" value="12YR">1 - 2 years </option>
                      <option class="mBlackText" value="25YR">2 - 5 years </option>
                      <option class="mBlackText" value="510Y">5 - 10 years </option>
                      <option class="mBlackText" value="CALL">Call Agent </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0910LRMN" formControlName="0910LRMN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--break for next group in the right column-->
              <br>
    
    
              <div class="row form-group col-sm-12 justify-content-between">
                  <label for="CB_FORM" class="reqtext pr-2 py-2 col-form-label">*Form of Ownership:</label>
              </div>
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-end">
                  <select id="CB_FORM" formControlName="FORM" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.FORM.errors }" required>
                    <option value=""></option>
                    <option class="mBlackText" value="SOLE">Sole Proprietor </option>
                    <option class="mBlackText" value="GNRL">General Partnership </option>
                    <option class="mBlackText" value="LMTD">Limited Partnership </option>
                    <option class="mBlackText" value="CORP">Corporation </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0920FORM" formControlName="0920FORM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
    
    
                <!--break for next group in the right column-->
                <br>
    
    
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.SOURCEOFDATA.errors }"><span class="pr-2 py-2 reqtext">*Source of Data:</span></span>
    
              <div formGroupName="SOURCEOFDATA" required>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="SOFDTAXR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOFDTAXR" formControlName="SOFDTAXR" class="form-check-input">Tax Returns</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOFDTAXR" formControlName="Checkbox_Comment_SOFDTAXR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SOFDACCT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOFDACCT" formControlName="SOFDACCT" class="form-check-input">Accountant</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOFDACCT" formControlName="Checkbox_Comment_SOFDACCT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SOFDOWNR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOFDOWNR" formControlName="SOFDOWNR" class="form-check-input">Owner</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOFDOWNR" formControlName="Checkbox_Comment_SOFDOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SOFDESTM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOFDESTM" formControlName="SOFDESTM" class="form-check-input">Estimate</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOFDESTM" formControlName="Checkbox_Comment_SOFDESTM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SOFDPROJ" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOFDPROJ" formControlName="SOFDPROJ" class="form-check-input">Projected</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOFDPROJ" formControlName="Checkbox_Comment_SOFDPROJ" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SOFDINCM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOFDINCM" formControlName="SOFDINCM" class="form-check-input">Incomplete</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOFDINCM" formControlName="Checkbox_Comment_SOFDINCM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
            </div>
            </div> <!--end of right column-->
    
    
          </div> <!--end of page/section-->
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="d-flex form-inline row">
    
            <!-- Left Column-->
    
            <div class="col-lg-6 align-self-start">
    
    
    
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Tenant Expenses:</span></span>
    
              <!-- One row for a new input group -->
              <div class="row fixcheck">
    
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="TEXPGAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPGAS" formControlName="TEXPGAS" class="form-check-input">Gas</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPGAS" formControlName="Checkbox_Comment_TEXPGAS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPELEC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPELEC" formControlName="TEXPELEC" class="form-check-input">Electric</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPELEC" formControlName="Checkbox_Comment_TEXPELEC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPTRSH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPTRSH" formControlName="TEXPTRSH" class="form-check-input">Trash</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPTRSH" formControlName="Checkbox_Comment_TEXPTRSH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPWATR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPWATR" formControlName="TEXPWATR" class="form-check-input">Water</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPWATR" formControlName="Checkbox_Comment_TEXPWATR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPSEWR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPSEWR" formControlName="TEXPSEWR" class="form-check-input">Sewer</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPSEWR" formControlName="Checkbox_Comment_TEXPSEWR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPTAXS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPTAXS" formControlName="TEXPTAXS" class="form-check-input">Taxes</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPTAXS" formControlName="Checkbox_Comment_TEXPTAXS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPJNTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPJNTR" formControlName="TEXPJNTR" class="form-check-input">Janitorial</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPJNTR" formControlName="Checkbox_Comment_TEXPJNTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPIMNT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPIMNT" formControlName="TEXPIMNT" class="form-check-input">Interior Maintenence</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPIMNT" formControlName="Checkbox_Comment_TEXPIMNT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPEMNT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPEMNT" formControlName="TEXPEMNT" class="form-check-input">Exterior Maintenence</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPEMNT" formControlName="Checkbox_Comment_TEXPEMNT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPSMNT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPSMNT" formControlName="TEXPSMNT" class="form-check-input">Site Maintenence</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPSMNT" formControlName="Checkbox_Comment_TEXPSMNT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
            </div> <!--end of left column-->
    
            <!--Right Column-->
    
            <div class="col-lg-6 align-self-start split">
    
    
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <div class="p-2 hidebreak"><br></div>
                </div>
              </div> 
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPCMNT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPCMNT" formControlName="TEXPCMNT" class="form-check-input">Common Area Maint.</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPCMNT" formControlName="Checkbox_Comment_TEXPCMNT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPIVAC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPIVAC" formControlName="TEXPIVAC" class="form-check-input">HVAC Maintenence</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPIVAC" formControlName="Checkbox_Comment_TEXPIVAC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPROOF" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPROOF" formControlName="TEXPROOF" class="form-check-input">Roof Maintenence</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPROOF" formControlName="Checkbox_Comment_TEXPROOF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPFIRE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPFIRE" formControlName="TEXPFIRE" class="form-check-input">Fire Insurance</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPFIRE" formControlName="Checkbox_Comment_TEXPFIRE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPLIAB" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPLIAB" formControlName="TEXPLIAB" class="form-check-input">Liability Insurance</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPLIAB" formControlName="Checkbox_Comment_TEXPLIAB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPGLAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPGLAS" formControlName="TEXPGLAS" class="form-check-input">Glass Insurance</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPGLAS" formControlName="Checkbox_Comment_TEXPGLAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPELEV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPELEV" formControlName="TEXPELEV" class="form-check-input">Elevator</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPELEV" formControlName="Checkbox_Comment_TEXPELEV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPTXEC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPTXEC" formControlName="TEXPTXEC" class="form-check-input">Tax Escalation Claus</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPTXEC" formControlName="Checkbox_Comment_TEXPTXEC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TEXPMRCH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TEXPMRCH" formControlName="TEXPMRCH" class="form-check-input">Merchants Assoc.</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TEXPMRCH" formControlName="Checkbox_Comment_TEXPMRCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
            </div> <!--end of right column-->
    
          </div> <!--end of page/section-->
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="d-flex form-inline row">
    
            <!-- Left Column-->
    
            <div class="col-lg-6 align-self-start">
    
    
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Terms:</span></span>
    
              <!-- One row for a new input group -->
              <div class="row fixcheck">
    
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="TERMCONV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMCONV" formControlName="TERMCONV" class="form-check-input">Conventional</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMCONV" formControlName="Checkbox_Comment_TERMCONV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMSBA" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMSBA" formControlName="TERMSBA" class="form-check-input">SBA</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMSBA" formControlName="Checkbox_Comment_TERMSBA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMOWNR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMOWNR" formControlName="TERMOWNR" class="form-check-input">Owner Will Carry</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMOWNR" formControlName="Checkbox_Comment_TERMOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMCASH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMCASH" formControlName="TERMCASH" class="form-check-input">Cash</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMCASH" formControlName="Checkbox_Comment_TERMCASH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMOTHR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMOTHR" formControlName="TERMOTHR" class="form-check-input">Other</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMOTHR" formControlName="Checkbox_Comment_TERMOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
            </div> <!--end of left column-->
    
            <!--Right Column-->
    
            <div class="col-lg-6 align-self-start split">
    
    
    
            </div> <!--end of right column-->
    
          </div> <!--end of page/section-->
    
          <br>
        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepTitle="Review" (stepEnter)="validate()" awOptionalStep class="h-100">
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">5</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <ng-container *ngIf="form.valid; else notValidForm">
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-success">All required fields completed!</div>
              <div class="font-weight-light mb-2">Please review to make sure all information is correct and up-to-date before submitting.</div>
              <button class="btn btn-lg btn-primary w-100" (click)="submit()">Submit</button>
            </div>
          </div>
        </ng-container>
        <ng-template #notValidForm>
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-danger">Missing required fields!</div>
              <div class="font-weight-light mb-2">Please review below to make sure all required fields are completed.</div>
              <div class="card overflow-auto p-2 my-2" style="height: 250px;">
                <ul *ngFor="let error of errorList" class="list-group list-group-flush">
                  <li class="d-flex justify-content-between align-items-center list-group-item">{{error.key}}<button class="btn btn-sm btn-secondary" (click)="goToError(error)">Go to</button></li>
                </ul>
              </div>
              <button class="btn btn-lg btn-primary w-100" disabled>Submit</button>
            </div>
          </div>
        </ng-template>
      </aw-wizard-step>
    </div>
    <div class="form-footer p-2">
      <button type="button" class="btn btn-secondary mx-1" (click)="save()">Save</button>
      <div class="btn-group mx-1">
        <button type="button" class="btn btn-secondary" awPreviousStep>Previous</button>
        <button type="button" class="btn btn-secondary" awNextStep>Next</button>
      </div>
    </div>
  </aw-wizard>
</div>

<swal
  #submitSuccess
  title="Submission Sent"
  text="Your response was submitted successfully!"
  icon="success"
  confirmButtonText="Finish"
  (confirm)="complete()">

  <div *swalPortal class="alert alert-info">
    <div class="additional-form-container">
      <small class="mb-2">Request a sign install by selecting the button below!</small>
      <button class="btn btn-primary btn-lg" (click)="navigateToSign()">REQUEST SIGN INSTALL HERE</button>
    </div>
    <!--
    Do you need to fill any additional forms?
    <div class="additional-form-container mt-2">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h6>Request Sign Request Here</h6>
              <button class="btn btn-primary" (click)="navigateToSign()">Request Sign Request</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>

</swal>

<swal
  #submitError
  title="Submission Error"
  text="An error occurred during submission, please try again!"
  icon="error">
</swal>
