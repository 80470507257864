<div class="modal-header">
  <h5 class="modal-title">
    Member Deletion Confirmation
  </h5>
  <button type="button" class="close" (click)="close()">&times;</button>
</div>
<div class="modal-body">
  Are you sure you want to remove user, <b>{{ memberEmail }}</b>, from your organization? <br>
  This user's data will no longer exist and cannot be undone!
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="deleteMember()">
    Confirm
  </button>
</div>