<div class="layout-wrapper layout-1" [ngClass]="{'layout-loading': !initialized}">
  <div class="layout-inner">
    <app-layout-navbar [sidenavToggle]="!(hidden$ | async)"></app-layout-navbar>

    <div class="layout-container">
      <app-layout-sidenav [hidden]="(hidden$ | async)"></app-layout-sidenav>

      <div class="layout-content">
        <div class="container-fluid router-transitions d-flex align-items-stretch flex-grow-1 container-p-y">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="layout-overlay" (click)="closeSidenav()"></div>
