import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organization } from '../models/organization.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private organizationSubject$ = new BehaviorSubject<Organization>(null);
  public organization$ = this.organizationSubject$.asObservable();

  constructor(private http: HttpClient) { }

  public getOrganization(id: string, select: string = ''): Observable<Organization> {
    return this.http.get<Organization>('/api/organization/' + id, {params: {select: select}}).pipe(
      tap(res => {
        this.organizationSubject$.next(res);
      })
    )
  }

  public postOrganization(body: Organization): Observable<Organization> {
    return this.http.post<Organization>('/api/organization', body)
  }

}
