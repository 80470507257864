<ng-container *ngIf="response; else loading">
  <div class="modal-header">
     <h5 class="modal-title d-flex flex-column">
        <span>{{ formName }} - {{ response.data.FULL_ADDRESS }}</span>
        <small class="text-muted">{{ response.user.email || 'N/A'}} - updated {{ response.timeUpdated | amTimeAgo }}</small>
     </h5>
     <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
     <div class="printable-form" [formGroup]="form">
        <div class="section text-white p-1">Listing Information</div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between pt-1">
              <label for="selCountyComboBox" class= "reqtext pr-2 py-2">*County:</label>
              <select id="selCountyComboBox" formControlName="County_ComboBox" class="form-control col-md-8">
                 <option value="Fresno" selected>Fresno</option>
                 <option value="Kern">Kern</option>
                 <option value="Kings">Kings</option>
                 <option value="Madera">Madera</option>
                 <option value="Mariposa">Mariposa</option>
                 <option value="Merced">Merced</option>
                 <option value="Mono">Mono</option>
                 <option value="Orange">Orange</option>
                 <option value="Placer">Placer</option>
                 <option value="Riverside">Riverside</option>
                 <option value="San Bernardino">San Bernardino</option>
                 <option value="San Joaquin">San Joaquin</option>
                 <option value="Santa Clara">Santa Clara</option>
                 <option value="Stanislaus">Stanislaus</option>
                 <option value="Tehama">Tehama</option>
                 <option value="Tulare">Tulare</option>
                 <option value="Tuolumne">Tuolumne</option>
                 <option value="Ventura">Ventura</option>
                 <option value="Out of Area">Out of Area</option>
                 <option value="Unknown">Unknown</option>
                 <option value="Alameda">Alameda</option>
                 <option value="Alpine">Alpine</option>
                 <option value="Amador">Amador</option>
                 <option value="Butte">Butte</option>
                 <option value="Calaveras">Calaveras</option>
                 <option value="Colusa">Colusa</option>
                 <option value="Contra Costa">Contra Costa</option>
                 <option value="Del Norte">Del Norte</option>
                 <option value="El Dorado">El Dorado</option>
                 <option value="Glenn">Glenn</option>
                 <option value="Humboldt">Humboldt</option>
                 <option value="Imperial">Imperial</option>
                 <option value="Inyo">Inyo</option>
                 <option value="Lake">Lake</option>
                 <option value="Lassen">Lassen</option>
                 <option value="Los Angeles">Los Angeles</option>
                 <option value="Marin">Marin</option>
                 <option value="Mendocino">Mendocino</option>
                 <option value="Modoc">Modoc</option>
                 <option value="Monterey">Monterey</option>
                 <option value="Napa">Napa</option>
                 <option value="Nevada">Nevada</option>
                 <option value="Plumas">Plumas</option>
                 <option value="Sacramento">Sacramento</option>
                 <option value="San Benito">San Benito</option>
                 <option value="San Diego">San Diego</option>
                 <option value="San Francisco">San Francisco</option>
                 <option value="San Luis Obispo">San Luis Obispo</option>
                 <option value="San Mateo">San Mateo</option>
                 <option value="Santa Barbara">Santa Barbara</option>
                 <option value="Santa Cruz">Santa Cruz</option>
                 <option value="Shasta">Shasta</option>
                 <option value="Sierra">Sierra</option>
                 <option value="Siskiyou">Siskiyou</option>
                 <option value="Solano">Solano</option>
                 <option value="Sonoma">Sonoma</option>
                 <option value="Sutter">Sutter</option>
                 <option value="Trinity">Trinity</option>
                 <option value="Yolo">Yolo</option>
                 <option value="Yuba">Yuba</option>
              </select>
           </div>
           <div class="form-group col-md-6 justify-content-between">
              <label for="APN_1" class="reqtext pr-2 py-2">*APN:</label>
              <div class="d-flex">
                 <input type="text" id="APN_1" formControlName="APN_1" class="form-control" size="3" maxlength="3">
                 <label for="APN_2">&nbsp;-&nbsp;</label>
                 <input type="text" id="APN_2" formControlName="APN_2" class="form-control" size="3" maxlength="3">
                 <label for="APN_3">&nbsp;-&nbsp;</label>
                 <input type="text" id="APN_3" formControlName="APN_3" class="form-control" size="3" maxlength="3">
              </div>
           </div>
        </div>
        <div class="row fixcheck form-inline">
           <div class="form-check col-12 justify-content-end">
              <label class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="New_Construction" formControlName="New_Construction" class="form-check-input">New / Under Contruction / NA</label>
           </div>
        </div>
        <!---->
        <h6 class="subtitle p-1">Address</h6>
        <!---->
        <input type="text" formControlName="FULL_ADDRESS" class="d-none form-control">
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Number" class= "reqtext pr-2 py-2">*Street # - Modifier:</label>
              <div class="d-flex">
                 <input type="text" id="Street_Number" formControlName="Street_Number" class="form-control" size="6" maxlength="6">
                 <label for="Street_Number_Modifier">-</label>
                 <input type="text" id="Street_Number_Modifier" formControlName="Street_Number_Modifier" class="form-control" size="6" maxlength="8">
              </div>
           </div>
           <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Direction" class="nonreqtext pr-2 py-2">Direction:</label>
              <select id="Street_Direction" formControlName="Street_Direction" class="form-control col-md-6 col-lg-8">
                 <option value="" selected="">  </option>
                 <option value="E"> East </option>
                 <option value="N"> North </option>
                 <option value="NE"> Northeast </option>
                 <option value="NW"> Northwest </option>
                 <option value="S"> South </option>
                 <option value="SE"> Southeast </option>
                 <option value="SW"> Southwest </option>
                 <option value="W"> West </option>
              </select>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Address" class= "reqtext pr-2 py-2">*Street Name:</label>
              <input type="text" id="Street_Address" formControlName="Street_Address" class="form-control col-sm-8" size="" maxlength="30">
           </div>
           <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Suffix" class="nonreqtext pr-2 py-2">Sufix:</label>
              <select id="Street_Suffix"  formControlName="Street_Suffix" class="form-control col-md-6 col-lg-8">
               <option value="" selected=""> </option>
               <option value="Ave"> Avenue </option>
               <option value="Blvd"> Boulevard </option>
               <option value="Cir"> Circle </option>
               <option value="Ct"> Court </option>
               <option value="Dr"> Drive </option>
               <option value="Jct"> Junction </option>
               <option value="Ln"> Lane </option>
               <option value="Pass"> Pass </option>
               <option value="Pkwy"> Parkway </option>
               <option value="Pl"> Place </option>
               <option value="Rd"> Road </option>
               <option value="Sqr"> Square </option>
               <option value="St"> Street </option>
               <option value="Way"> Way </option>
              </select>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 offset-md-6 justify-content-between">
              <label for="Post_Direction" class="nonreqtext pr-2 py-2">Post Direction:</label>
              <select id="Post_Direction" formControlName="Post_Direction" class="form-control col-md-6 col-lg-8">
                 <option value="" selected="">  </option>
                 <option value="E"> East </option>
                 <option value="N"> North </option>
                 <option value="NE"> Northeast </option>
                 <option value="NW"> Northwest </option>
                 <option value="S"> South </option>
                 <option value="SE"> Southeast </option>
                 <option value="SW"> Southwest </option>
                 <option value="W"> West </option>
              </select>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Unit_Number" class="nonreqtext pr-2 py-2">Unit #:</label>
              <input type="text" id="Unit_Number" formControlName="Unit_Number" class="form-control col-md-8" size="6" maxlength="5">
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="City_Fill_In" class= "reqtext pr-2 py-2">*City:</label>
              <input type="text" id="City_Fill_In" formControlName="City_Fill_In" size="16" maxlength="20" class="form-control col-sm-8">
           </div>
           <div class="form-group col-md-6 justify-content-between">
              <label for="Zip_Code" class="reqtext pr-2 py-2">*Zip Code:</label>
              <div class="d-flex">
                 <input type="text" id="Zip_Code" formControlName="Zip_Code" class="form-control" size="5" maxlength="5">
                 <label for="Zip_Plus_4">
                 -</label>
                 <input type="text" id="Zip_Plus_4" formControlName="Zip_Plus_4" class="form-control" size="4" maxlength="4">
              </div>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Cross_Street" class="reqtext pr-2 py-2">
              *Cross Street:</label>
              <input type="text" id="Cross_Street" formControlName="Cross_Street" class="form-control col-sm-8" size="16" maxlength="30">
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="txtOfficeAuto" class= "reqtext pr-2 py-2">
              *Office ID:</label>
              <select id="Office_ID" formControlName="Office_ID" class="form-control col-sm-8">
                 <option value=""> </option>
                 <option value="630">630-Fresno</option>
                 <option value="63004">63004-Clovis</option>
                 <option value="63005">63005-Commercial</option>
                 <option value="63006">63006-Oakhurst2</option>
              </select>
              <input type="text" id="txtOfficeAuto" formControlName="Office_MLS_ID" class="d-none form-control col-sm-8" size="40" maxlength="50" required>
           </div>
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Agent_MLS_ID" class= "reqtext pr-2 py-2">
              *Agent ID:</label>
              <input type="text" id="Agent_MLS_ID" formControlName="Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50">
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Office_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Office ID:</label>
              <input type="text" id="Co_Office_MLS_ID" formControlName="Co_Office_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50">
           </div>
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Agent_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Agent ID:</label>
              <input type="text" id="Co_Agent_MLS_ID" formControlName="Co_Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50">
           </div>
        </div>
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="row pb-2">
           <h6 class="col reqtext">*Property Subtypes (select all that apply):</h6>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0001" class= "reqtext pr-2 py-2">*Primary:</label>
              <select id="Prop_Subtype_0001" formControlName="Prop_Subtype_0001" class="form-control">
                 <option value=""> </option>
                 <option value="SFRS">Single Family</option>
                 <option value="COND">Condominium</option>
                 <option value="PUDC">PUD</option>
                 <option value="MOBL">Mobile Home</option>
                 <option value="MNFG">Manufactured Home</option>
              </select>
           </div>
           <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0002" class= "nonreqtext pr-2 py-2">Secondary:</label>
              <select id="Prop_Subtype_0002" formControlName="Prop_Subtype_0002" class="form-control">
                  <option value=""> </option>
                  <option value="SFRS">Single Family</option>
                  <option value="COND">Condominium</option>
                  <option value="PUDC">PUD</option>
                  <option value="MOBL">Mobile Home</option>
                  <option value="MNFG">Manufactured Home</option>
              </select>
           </div>
           <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0003" class= "nonreqtext pr-2 py-2">Other:</label>
              <select id="Prop_Subtype_0003" formControlName="Prop_Subtype_0003" class="form-control">
                  <option value=""> </option>
                  <option value="SFRS">Single Family</option>
                  <option value="COND">Condominium</option>
                  <option value="PUDC">PUD</option>
                  <option value="MOBL">Mobile Home</option>
                  <option value="MNFG">Manufactured Home</option>
              </select>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-4 justify-content-between">
              <label for="Transaction" class= "reqtext pr-2 py-2">*Transaction Type:</label>
              <select id="Transaction" formControlName="Transaction" class="form-control">
                 <option value="S" selected="">Sale</option>
                 <option value="L">Lease</option>
              </select>
           </div>
           <div class="form-group col-md-4 justify-content-between">
              <label for="CB_SSLE" class= "reqtext pr-2 py-2">*Short Sale:</label>
              <select id="CB_SSLE" formControlName="SSLE" class="form-control">
                 <option value=""> </option>
                 <option value="YESS">Yes</option>
                 <option value="NONO">No</option>
              </select>
           </div>
           <div class="form-group col-md-4 justify-content-between">
              <label for="CB_REBK" class= "reqtext pr-2 py-2">*REO/Bank Owned:</label>
              <select id="CB_REBK" formControlName="REBK" class="form-control">
                 <option value=""> </option>
                 <option value="YESS">Yes</option>
                 <option value="NONO">No</option>
              </select>
           </div>
        </div>
        <!-- Conditional: IF Transcation Type == 'Lease'-->
        <ng-container *ngIf="form.get('Transaction').value == 'L'">
           <!---->
           <div>
              <hr class="HRstyle">
           </div>
           <!---->
           <div class="row form-inline">
              <div class="form-group col-md-6 justify-content-between">
                 <label for="Lease_Price" class= "reqtext pr-2 pb-2 ">*Lease (Monthly):</label>
                 <input type="text" id="Lease_Price" formControlName="Lease_Price" class="form-control col-md-8" size="10" maxlength="10">
              </div>
              <div class="form-group col-md-6 justify-content-between">
                 <label for="Lease_Type" class="reqtext pr-2 py-2">*Lease Type:</label>
                 <select id="Lease_Type"  formControlName="Lease_Type" class="form-control col-md-8">
                    <option value="" selected="">  </option>
                    <option value="G"> Gross </option>
                    <option value="N"> Net </option>
                    <option value="O"> Other </option>
                 </select>
              </div>
           </div>
           <div class="row form-inline">
              <div class="form-group col-md-6 justify-content-between">
                 <label for="Sub_Lease" class="nonreqtext pr-2 py-2">Sub-Lease:</label>
                 <select id="Sub_Lease" formControlName="Sub_Lease" class="form-control">
                    <option value="" selected=""> </option>
                    <option value="Y"> Yes </option>
                    <option value="N"> No </option>
                 </select>
                 <label for="Available_Date" class="nonreqtext pr-2 py-2">Available Date:</label>
                 <input type="text" id="Available_Date" formControlName="Available_Date" class="form-control" size="10" maxlength="8" value="">
              </div>
              <div class="form-group col-md-6 justify-content-between">
                 <label for="Lease_Expiration" class="nonreqtext pr-2 py-2">Sub-Lease Expiration:</label>
                 <input type="text" id="Lease_Expiration" formControlName="Lease_Expiration" class="form-control" size="9" maxlength="8" value="">
              </div>
           </div>
        </ng-container>
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="d-flex row form-inline">
           <div class="col-sm-6">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_APNS" class= "nonreqtext pr-2 py-2">Additional APNs:</label>
                 </div>
                 <div class="form-group col-sm-4">
                    <select id="CB_APNS" formControlName="APNS" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="YESS">Yes</option>
                       <option class="mBlackText" value="NONO">No</option>
                    </select>
                 </div>
              </div>
           </div>
        </div>
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-md-6 align-self-start">
              <!-- One row for an input -->
              <div class="row">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="0100APN2" class="nonreqtext pr-2 py-2 col-form-label">APN #2:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0100APN2" formControlName="0100APN2" class="form-control flex-grow-1" size="" maxlength="20" value="">
                 </div>
              </div>
              <!-- One row for an input -->
              <div class="row">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="0110APN3" class="nonreqtext pr-2 py-2 col-form-label">APN #3:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0110APN3" formControlName="0110APN3" class="form-control flex-grow-1" size="" maxlength="20" value="">
                 </div>
              </div>
              <!--End of left column-->
           </div>
           <div class="col-md-6 align-self-start">
              <!--Right Column-->
              <!-- One row for an input -->
              <div class="row">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="0120APN4" class="nonreqtext pr-2 py-2 col-form-label">APN #4:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0120APN4" formControlName="0120APN4" class="form-control flex-grow-1" size="" maxlength="20" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="0130APN5" class="nonreqtext pr-2 py-2 col-form-label">APN #5:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0130APN5" formControlName="0130APN5" class="form-control flex-grow-1" size="" maxlength="20" value="">
                 </div>
              </div>
              <!--End of right column-->
           </div>
        </div>
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="row form-inline">
           <div class="form-group col-md-5 justify-content-between">
              <label for="Listing_Price_1" class= "reqtext pr-2 py-2">Listing Price:</label>
              <div class="d-flex">
                 <input type="text" id="Listing_Price_1" formControlName="Listing_Price_1" class="form-control" size="6" maxlength="6" value="">
                 <label for="Listing_Price_2">&nbsp;,&nbsp;</label>
                 <input type="text" id="Listing_Price_2" formControlName="Listing_Price_2" class="form-control" size="3" maxlength="3" value="000">
              </div>
           </div>
        </div>
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Agreement_Type_ComboBox" class="reqtext pr-2 py-2">*Agreement Type:</label>
              <select id="Agreement_Type_ComboBox" formControlName="Agreement_Type_ComboBox" class="form-control col-sm-5">
               <option class="mBlackText" value="" selected=""> </option>
               <option class="mBlackText" value="ERTS"> Excl. Right to Sell </option>
               <option class="mBlackText" value="EXAG"> Exclusive Agency </option>
               <option class="mBlackText" value="OPEN"> Open </option>
              </select>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_SCOP" class="nonreqtext pr-2 py-2">Scope of Service:</label>
              <select id="CB_SCOP" formControlName="SCOP" class="form-control col-sm-5">
               <option value=""> </option>
               <option class="mBlackText" value="ENTY">Entry Only </option>
               <option class="mBlackText" value="LMTD">Limited Service </option>
              </select>
           </div>
           <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0225SCOP" formControlName="0225SCOP" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_DUAL" class="nonreqtext pr-2 py-2">Dual Agency:</label>
              <select id="CB_DUAL" formControlName="DUAL" class="form-control col-sm-3">
                 <option value=""> </option>
                 <option class="mBlackText" value="YES">Yes</option>
                 <option class="mBlackText" value="NO">No</option>
              </select>
           </div>
           <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0230DUAL" formControlName="0230DUAL" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Publish_To_Internet" class="reqtext pr-2 py-2">*Public Viewable:</label>
              <select id="Publish_To_Internet" formControlName="Publish_To_Internet" class="form-control px-sm-0 px-md-3 col-sm-3">
                 <option value="yes">Yes</option>
                 <option value="no">No</option>
              </select>
           </div>
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_AVM_On_VOW" class="reqtext pr-2 py-2">*Show AVM on VOW:</label>
              <select id="Show_AVM_On_VOW" formControlName="Show_AVM_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3">
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
              </select>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Address_to_Public" class="reqtext pr-2 py-2">*Show Addr to Public:</label>
              <select id="Show_Address_to_Public" formControlName="Show_Address_to_Public" class="form-control px-sm-0 px-md-3 col-sm-3">
                 <option value="yes" selected="">Yes</option>
                 <option value="no">No</option>
              </select>
           </div>
           <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Comments_On_VOW" class="reqtext pr-2 py-2">*Show Cmnts on VOW:</label>
              <select id="Show_Comments_On_VOW" formControlName="Show_Comments_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3">
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
              </select>
           </div>
        </div>
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <!--Begin Section-->
        <div class="d-flex form-inline row">
           <!--Begin column-->
           <div class="col-lg-5">
              <!--entries-->
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_EOFF" class="reqtext pr-2 py-2 col-form-label">*Email Offers:</label>
                 </div>
                 <div class="form-group col-sm-4 offset-sm-2 pr-xs-0">
                    <select id="CB_EOFF" formControlName="EOFF" class="form-control col-sm-8">
                       <option value=""></option>
                       <option class="mBlackText" value="YESY"> Yes </option>
                       <option class="mBlackText" value="NONO"> No </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--Begin column-->
           <div class="col-lg-7">
              <!--entries-->
              <div class="row">
                 <div class="form-group col-sm-3 justify-content-end">
                    <label for="0400EOAD" class="reqtext pr-2 py-2 col-form-label">*Email:</label>
                 </div>
                 <div class="form-group col-sm-9 justify-content-between">
                    <input type="email" id="0400EOAD" formControlName="0400EOAD" class="form-control col-sm-12">
                 </div>
              </div>
           </div>
        </div>
        <!--end of section-->
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <!---->
        <h6 class="subtitle pr-1 py-2">Listing Period:</h6>
        <!---->
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Listing_Date" class="reqtext pr-2 py-2">*Listing Date:</label>
              <input type="text" id="Listing_Date" formControlName="Listing_Date" class="form-control col-sm-3" size="8" maxlength="8" value="">
           </div>
           <div class="form-group col-md-6 justify-content-between">
              <label for="Expiration_Date" class="reqtext pr-2 py-2">*Expiration Date:</label>
              <input type="text" id="Expiration_Date" formControlName="Expiration_Date" class="form-control col-sm-3" size="8" maxlength="8" value="">						
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Name" class= "nonreqtext pr-2 py-2">Owner Name:</label>
              <input type="text" id="Owner_Name" formControlName="Owner_Name" class="form-control col-sm-8" size="30" maxlength="50" value="">
           </div>
           <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Phone_Area" class="nonreqtext pr-2 py-2">Owner Phone #:</label>
              <div class="d-flex">
                 <input type="text" id="Owner_Phone_Area" formControlName="Owner_Phone_Area" class="form-control" size="3" maxlength="3" value="">
                 <label for="Owner_Phone_Prefix">&nbsp;-&nbsp;</label>
                 <input type="text" id="Owner_Phone_Prefix" formControlName="Owner_Phone_Prefix" class="form-control" size="3" maxlength="3" value="">
                 <label for="Owner_Phone_Suffix">&nbsp;-&nbsp;</label>
                 <input type="text" id="Owner_Phone_Suffix" formControlName="Owner_Phone_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Type" class="reqtext pr-2 py-2">*Occupant Type:</label>
              <select id="Occupant_Type" formControlName="Occupant_Type" class="form-control col-sm-3">
                 <option value="" selected="">  </option>
                 <option value="C"> Caretaker </option>
                 <option value="O"> Owner </option>
                 <option value="T"> Tenant </option>
                 <option value="V"> Vacant </option>
              </select>
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Name" class= "nonreqtext pr-2 py-2">Occupant Name:</label>
              <input type="text" id="Occupant_Name" formControlName="Occupant_Name" class="form-control col-sm-8" size="30" maxlength="30" value="">
           </div>
           <div class="form-group col-md-6 justify-content-between">
              <label for="Phone_to_Show_Area" class="nonreqtext pr-2 py-2">Phone to Show:</label>
              <div class="d-flex">
                 <input type="text" id="Phone_to_Show_Area" formControlName="Phone_to_Show_Area" class="form-control" size="3" maxlength="3" value="">
                 <label for="Phone_to_Show_Prefix">&nbsp;-&nbsp;</label>
                 <input type="text" id="Phone_to_Show_Prefix" formControlName="Phone_to_Show_Prefix" class="form-control" size="3" maxlength="3" value="">
                 <label for="Phone_to_Show_Suffix">&nbsp;-&nbsp;</label>
                 <input type="text" id="Phone_to_Show_Suffix" formControlName="Phone_to_Show_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
           </div>
        </div>
        <br>
        <div class="section text-white p-1">Basic Information</div>
        <h6 class="subtitle pr-2 py-2">Property Information</h6>
        <!---->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-md-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-4">
                    <label for="Bedrooms_ComboBox" class="reqtext pr-2 py-2">*Bedroom(s):</label>
                 </div>
                 <div class="form-group col-sm-3">
                    <select id="Bedrooms_ComboBox" formControlName="Bedrooms_ComboBox" class="form-control col-sm-10">
                     <option value="  0.00" selected> </option>
                     <option value="  1.00">1 </option>
                     <option value="  2.00">2 </option>
                     <option value="  3.00">3 </option>
                     <option value="  4.00">4 </option>
                     <option value="  5.00">5 </option>
                     <option value="  6.00">6 </option>
                     <option value="  7.00">7 </option>
                     <option value="  8.00">8 </option>
                     <option value="  9.00">9 </option>
                     <option value=" 10.00">10 </option>
                     <option value=" 11.00">11 </option>
                     <option value=" 12.00">12 </option>
                     <option value=" 13.00">13 </option>
                     <option value=" 14.00">14 </option>
                     <option value=" 15.00">15 </option>
                     <option value=" 16.00">16 </option>
                     <option value=" 17.00">17 </option>
                     <option value=" 18.00">18 </option>
                     <option value=" 19.00">19 </option>
                     <option value=" 20.00">20 </option>
                     <option value=" 21.00">21 </option>
                     <option value=" 22.00">22 </option>
                     <option value=" 23.00">23 </option>
                     <option value=" 24.00">24 </option>
                     <option value=" 25.00">25 </option>
                     <option value=" 26.00">26 </option>
                     <option value=" 27.00">27 </option>
                     <option value=" 28.00">28 </option>
                     <option value=" 29.00">29 </option>
                     <option value=" 30.00">30 </option>
                     <option value=" 31.00">31 </option>
                     <option value=" 32.00">32 </option>
                     <option value=" 33.00">33 </option>
                     <option value=" 34.00">34 </option>
                     <option value=" 35.00">35 </option>
                     <option value=" 36.00">36 </option>
                     <option value=" 37.00">37 </option>
                     <option value=" 38.00">38 </option>
                     <option value=" 39.00">39 </option>
                     <option value=" 40.00">40 </option>
                     <option value=" 41.00">41 </option>
                     <option value=" 42.00">42 </option>
                     <option value=" 43.00">43 </option>
                     <option value=" 44.00">44 </option>
                     <option value=" 45.00">45 </option>
                     <option value=" 46.00">46 </option>
                     <option value=" 47.00">47 </option>
                     <option value=" 48.00">48 </option>
                     <option value=" 49.00">49 </option>
                     <option value=" 50.00">50 </option>
                     <option value=" 51.00">51 </option>
                     <option value=" 52.00">52 </option>
                     <option value=" 53.00">53 </option>
                     <option value=" 54.00">54 </option>
                     <option value=" 55.00">55 </option>
                     <option value=" 56.00">56 </option>
                     <option value=" 57.00">57 </option>
                     <option value=" 58.00">58 </option>
                     <option value=" 59.00">59 </option>
                     <option value=" 60.00">60 </option>
                     <option value=" 61.00">61 </option>
                     <option value=" 62.00">62 </option>
                     <option value=" 63.00">63 </option>
                     <option value=" 64.00">64 </option>
                     <option value=" 65.00">65 </option>
                     <option value=" 66.00">66 </option>
                     <option value=" 67.00">67 </option>
                     <option value=" 68.00">68 </option>
                     <option value=" 69.00">69 </option>
                     <option value=" 70.00">70 </option>
                     <option value=" 71.00">71 </option>
                     <option value=" 72.00">72 </option>
                     <option value=" 73.00">73 </option>
                     <option value=" 74.00">74 </option>
                     <option value=" 75.00">75 </option>
                     <option value=" 76.00">76 </option>
                     <option value=" 77.00">77 </option>
                     <option value=" 78.00">78 </option>
                     <option value=" 79.00">79 </option>
                     <option value=" 80.00">80 </option>
                     <option value=" 81.00">81 </option>
                     <option value=" 82.00">82 </option>
                     <option value=" 83.00">83 </option>
                     <option value=" 84.00">84 </option>
                     <option value=" 85.00">85 </option>
                     <option value=" 86.00">86 </option>
                     <option value=" 87.00">87 </option>
                     <option value=" 88.00">88 </option>
                     <option value=" 89.00">89 </option>
                     <option value=" 90.00">90 </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-md-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-4">
                    <label for="Bathrooms_ComboBox" class="reqtext pr-2 py-2">*Bathrooms:</label>
                 </div>
                 <div class="form-group col-sm-3">
                    <select id="Bathrooms_ComboBox" formControlName="Bathrooms_ComboBox" class="form-control col-sm-10">
                     <option value="  0.00" selected></option>
                     <option value="  1.00"> 1 </option>
                     <option value="  1.50"> 1 1/2 </option>
                     <option value="  1.75"> 1 3/4 </option>
                     <option value="  2.00"> 2 </option>
                     <option value="  2.50"> 2 1/2 </option>
                     <option value="  2.75"> 2 3/4 </option>
                     <option value="  3.00"> 3 </option>
                     <option value="  3.50"> 3 1/2 </option>
                     <option value="  3.75"> 3 3/4 </option>
                     <option value="  4.00"> 4 </option>
                     <option value="  4.50"> 4 1/2 </option>
                     <option value="  4.75"> 4 3/4 </option>
                     <option value="  5.00"> 5 </option>
                     <option value="  6.00"> 6 </option>
                     <option value="  7.00"> 7 </option>
                     <option value="  8.00"> 8 </option>
                     <option value="  9.00"> 9 </option>
                     <option value=" 10.00"> 10 </option>
                     <option value=" 11.00"> 11 </option>
                     <option value=" 12.00"> 12 </option>
                     <option value=" 13.00"> 13 </option>
                     <option value=" 14.00"> 14 </option>
                     <option value=" 15.00"> 15 </option>
                     <option value=" 16.00"> 16 </option>
                     <option value=" 17.00"> 17 </option>
                     <option value=" 18.00"> 18 </option>
                     <option value=" 19.00"> 19 </option>
                     <option value=" 20.00"> 20 </option>
                     <option value=" 21.00"> 21 </option>
                     <option value=" 22.00"> 22 </option>
                     <option value=" 23.00"> 23 </option>
                     <option value=" 24.00"> 24 </option>
                     <option value=" 25.00"> 25 </option>
                     <option value=" 26.00"> 26 </option>
                     <option value=" 27.00"> 27 </option>
                     <option value=" 28.00"> 28 </option>
                     <option value=" 29.00"> 29 </option>
                     <option value=" 30.00"> 30 </option>
                     <option value=" 31.00"> 31 </option>
                     <option value=" 32.00"> 32 </option>
                     <option value=" 33.00"> 33 </option>
                     <option value=" 34.00"> 34 </option>
                     <option value=" 35.00"> 35 </option>
                     <option value=" 36.00"> 36 </option>
                     <option value=" 37.00"> 37 </option>
                     <option value=" 38.00"> 38 </option>
                     <option value=" 39.00"> 39 </option>
                     <option value=" 40.00"> 40 </option>
                     <option value=" 41.00"> 41 </option>
                     <option value=" 42.00"> 42 </option>
                     <option value=" 43.00"> 43 </option>
                     <option value=" 44.00"> 44 </option>
                     <option value=" 45.00"> 45 </option>
                     <option value=" 46.00"> 46 </option>
                     <option value=" 47.00"> 47 </option>
                     <option value=" 48.00"> 48 </option>
                     <option value=" 49.00"> 49 </option>
                     <option value=" 50.00"> 50 </option>
                     <option value=" 51.00"> 51 </option>
                     <option value=" 52.00"> 52 </option>
                     <option value=" 53.00"> 53 </option>
                     <option value=" 54.00"> 54 </option>
                     <option value=" 55.00"> 55 </option>
                     <option value=" 56.00"> 56 </option>
                     <option value=" 57.00"> 57 </option>
                     <option value=" 58.00"> 58 </option>
                     <option value=" 59.00"> 59 </option>
                     <option value=" 60.00"> 60 </option>
                     <option value=" 61.00"> 61 </option>
                     <option value=" 62.00"> 62 </option>
                     <option value=" 63.00"> 63 </option>
                     <option value=" 64.00"> 64 </option>
                     <option value=" 65.00"> 65 </option>
                     <option value=" 66.00"> 66 </option>
                     <option value=" 67.00"> 67 </option>
                     <option value=" 68.00"> 68 </option>
                     <option value=" 69.00"> 69 </option>
                     <option value=" 70.00"> 70 </option>
                     <option value=" 71.00"> 71 </option>
                     <option value=" 72.00"> 72 </option>
                     <option value=" 73.00"> 73 </option>
                     <option value=" 74.00"> 74 </option>
                     <option value=" 75.00"> 75 </option>
                     <option value=" 76.00"> 76 </option>
                     <option value=" 77.00"> 77 </option>
                     <option value=" 78.00"> 78 </option>
                     <option value=" 79.00"> 79 </option>
                     <option value=" 80.00"> 80 </option>
                     <option value=" 81.00"> 81 </option>
                     <option value=" 82.00"> 82 </option>
                     <option value=" 83.00"> 83 </option>
                     <option value=" 84.00"> 84 </option>
                     <option value=" 85.00"> 85 </option>
                     <option value=" 86.00"> 86 </option>
                     <option value=" 87.00"> 87 </option>
                     <option value=" 88.00"> 88 </option>
                     <option value=" 89.00"> 89 </option>
                     <option value=" 90.00"> 90 </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-md-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-4">
                    <label for="Struc_Sq_Ft" class= "reqtext pr-2">*Aprx Sq Ft </label>
                 </div>
                 <div class="form-group col-sm-4">
                    <input type="text" id="Struc_Sq_Ft" formControlName="Struc_Sq_Ft" class="form-control col-sm-10" size="9" maxlength="9" placeholder="0">
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-md-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-4">
                    <label for="selSqFtSource" class="reqtext pr-2 py-2">*Sq Ft Source:</label>
                 </div>
                 <div class="form-group col-sm-4">
                    <select id="selSqFtSource" formControlName="SqFt_Source" class="form-control col-sm-10">
                       <option value="" selected="">  </option>
                       <option value="SLLR"> Seller </option>
                       <option value="APPR"> Appraiser </option>
                       <option value="TAXR"> Tax Records </option>
                       <option value="OTHR"> Other </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-4">
                    <label for="Lot_Sq_Ft" class= "nonreqtext pr-2 py-2">Lot Size:</label>
                 </div>
                 <div class="form-group col-sm-4">
                    <input type="text" id="Lot_Sq_Ft" formControlName="Lot_Sq_Ft" class="form-control col-sm-10" size="12" maxlength="9" value="">
                 </div>
                 <div class="d-flex form-group col-sm-4">
                    <label for="Lot_Measurement" class="nonreqtext px-1">
                    <input type="radio" id="Lot_Measurement" formControlName="Lot_Measurement" class="form-control" value="S" checked="">&nbsp;SqFt
                    </label>
                    <label for="Lot_Measurement_Acres" class="nonreqtext px-1">
                    <input type="radio" id="Lot_Measurement_Acres" formControlName="Lot_Measurement" class="form-control" value="A">&nbsp;Acres
                    </label>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-4">
                    <label for="selLotSizeSource" class="reqtext pr-2">*Lot Size Source:</label>
                 </div>
                 <div class="form-group col-sm-4">
                    <select id="selLotSizeSource" formControlName="LotSize_Source" class="form-control col-sm-10">
                       <option value="" selected="">  </option>
                       <option value="APPR"> Appraiser </option>
                       <option value="OTHR"> Other </option>
                       <option value="SLLR"> Seller </option>
                       <option value="TAXR"> Tax Records </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-md-4 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="Year_Built" class= "reqtext pr-2 py-2">*Year Built:</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="Year_Built" formControlName="Year_Built" class="form-control col-sm-10" size="8" maxlength="4" value="">
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-md-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-5">
                    <label for="Unknown_Year" class="nonreqtext p-2">- or (select one) -</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="Unknown_Year" formControlName="Unknown_Year" class="form-control">
                     <option value="0" selected="">  </option>
                     <option value="1"> New Construction </option>
                     <option value="2"> Under Construction </option>
                     <option value="3"> Unknown </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <br>
        <div class="row">
           <div class="col">
              <h6><label for="Property_Description"><span class="reqsubtitle pr-1">*Marketing Remarks:</span></label><span class="reqtext formnote">&nbsp;(Maximum 1000)</span></h6>
              <textarea id="Property_Description" formControlName="Property_Description" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"></textarea>
           </div>
        </div>
        <br>
        <div class="row">
           <div class="col">
              <h6><label for="Agent_Only_Remarks"><span class="subtitle pr-1">Agent Remarks:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 1000)</span></h6>
              <textarea id="Agent_Only_Remarks" formControlName="Agent_Only_Remarks" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"></textarea>
           </div>
        </div>
        <br>
        <div class="row">
           <div class="col">
              <h6><label for="0560XSLE"><span class="subtitle pr-1">Excluded From Sale:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 100)</span></h6>
              <textarea id="0560XSLE" formControlName="0560XSLE" class="form-control" spellcheck="true" rows="3" cols="" maxlength="100"></textarea>
           </div>
        </div>
        <br>
        <div class="row">
           <div class="col">
              <h6><label for="Directions"><span class="reqsubtitle pr-1">*Directions to Property:</span></label><span class="reqtext formnote">&nbsp;(Maximum 250)</span></h6>
              <textarea id="Directions" formControlName="Directions" class="form-control" spellcheck="true" rows="4" cols=""  maxlength="250"></textarea>
           </div>
        </div>
        <br>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="School_District_Fill_In" class= "reqtext pr-2 py-2">*School District:</label>
              <input type="text" id="School_District_Fill_In" formControlName="School_District_Fill_In" class="form-control col-sm-8" size="20" maxlength="20" value="">
           </div>
           <div class="form-group col-sm-6 justify-content-between">
              <label for="School_Code_1" class= "reqtext pr-2 py-2">*Grade School:</label>
              <input type="text" id="School_Code_1" formControlName="School_Code_1" class="form-control col-sm-8" size="10" maxlength="20" value="">
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="School_Code_2" class= "reqtext pr-2 py-2">*Jr High School :</label>
              <input type="text" id="School_Code_2" formControlName="School_Code_2" class="form-control col-sm-8" size="20" maxlength="20" value="">
           </div>
           <div class="form-group col-sm-6 justify-content-between">
              <label for="School_Code_3" class= "reqtext pr-2 py-2">*Sr High School:</label>
              <input type="text" id="School_Code_3" formControlName="School_Code_3" class="form-control col-sm-8" size="20" maxlength="20" value="">
           </div>
        </div>
        <div class="row form-inline">
           <div class="form-group col-sm-6 justify-content-between">
              <label for="0870SCMT" class= "nonreqtext pr-1 py-1">Comments:<span class="formnote">&nbsp;(Maximum 60)&nbsp;</span></label>
              <textarea id="0870SCMT" formControlName="0870SCMT" class="form-control col-sm-8" spellcheck="true" rows="2" cols="" maxlength="60"></textarea>
           </div>
        </div>
        <br>
        <div class="section text-white p-1">General Information</div>
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Style:</span></span>
              <!-- One row for an input -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="STYLRNCH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLRNCH" formControlName="STYLRNCH" class="form-check-input">Ranch</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLRNCH" formControlName="Checkbox_Comment_STYLRNCH" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLBUNG" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLBUNG" formControlName="STYLBUNG" class="form-check-input">Bungalow</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLBUNG" formControlName="Checkbox_Comment_STYLBUNG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLCLNL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLCLNL" formControlName="STYLCLNL" class="form-check-input">Colonial</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLCLNL" formControlName="Checkbox_Comment_STYLCLNL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLSPAN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLSPAN" formControlName="STYLSPAN" class="form-check-input">Spanish</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLSPAN" formControlName="Checkbox_Comment_STYLSPAN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLCONT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLCONT" formControlName="STYLCONT" class="form-check-input">Contemporary</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLCONT" formControlName="Checkbox_Comment_STYLCONT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLTUDR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLTUDR" formControlName="STYLTUDR" class="form-check-input">Tudor</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLTUDR" formControlName="Checkbox_Comment_STYLTUDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLVICT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLVICT" formControlName="STYLVICT" class="form-check-input">Victorian</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLVICT" formControlName="Checkbox_Comment_STYLVICT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLMEDI" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLMEDI" formControlName="STYLMEDI" class="form-check-input">Mediterranean</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLMEDI" formControlName="Checkbox_Comment_STYLMEDI" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLTOWN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLTOWN" formControlName="STYLTOWN" class="form-check-input">Townhouse</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLTOWN" formControlName="Checkbox_Comment_STYLTOWN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLCOTT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLCOTT" formControlName="STYLCOTT" class="form-check-input">Cottage</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLCOTT" formControlName="Checkbox_Comment_STYLCOTT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLCABN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLCABN" formControlName="STYLCABN" class="form-check-input">Cabin</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLCABN" formControlName="Checkbox_Comment_STYLCABN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="STYLMDCM" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="STYLMDCM" formControlName="STYLMDCM" class="form-check-input">Mid-Century Modern</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_STYLMDCM" formControlName="Checkbox_Comment_STYLMDCM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_CNSS" class= "nonreqtext pr-2 py-2 col-form-label">Census Tract:</label>
                    <input type="text" id="NUM_CNSS" formControlName="CNSS" class="form-control col-sm-5" size="" maxlength="6">
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0650CNSS" formControlName="0650CNSS" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="CB_AREA" class="reqtext pr-2 py-2 col-form-label">*Region:</label>
                    <select id="CB_AREA" formControlName="AREA" class="form-control col-sm-5">
                       <option value=""></option>
                       <option class="mBlackText" value="URBN">Urban </option>
                       <option class="mBlackText" value="SUBU">Rural </option>
                       <option class="mBlackText" value="FTHL">Foothill </option>
                       <option class="mBlackText" value="MNTN">Mountain </option>
                    </select>
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0660AREA" formControlName="0660AREA" class="form-control flex-grow-1" size="" maxlength="40">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="Area_Fill_In" class="reqtext pr-2 py-2 col-form-label">*Area:</label>
                    <input type="text" id="Area_Fill_In" formControlName="Area_Fill_In" class="form-control col-sm-5" size="" maxlength="60">
                 </div>
              </div>
              <div class="row form-group col-sm-12">
                 <span class="reqtext pr-2 py-2 formnote">AREA refers to the last 3 digits of the property zipcode. Example: 93711. AREA = 711.</span>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="0690ZONE" class="nonreqtext pr-2 py-2 col-form-label">Zoning:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0690ZONE" formControlName="0690ZONE" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="0700LTDM" class="nonreqtext pr-2 py-2 col-form-label">Lot Dimensions:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0700LTDM" formControlName="0700LTDM" class="form-control flex-grow-1" size="" maxlength="40">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="0710TRCT" class="nonreqtext pr-2 py-2 col-form-label">Tract/Builder:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0710TRCT" formControlName="0710TRCT" class="form-control flex-grow-1" size="" maxlength="40">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 reqtext">*# of Stories:</span></span>
              <ng-container formGroupName="NUMOFSTORIES">
                 <!-- One row for an input -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="STORSNGL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="STORSNGL" formControlName="STORSNGL" class="form-check-input">Single Story</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_STORSNGL" formControlName="Checkbox_Comment_STORSNGL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="STORTWOS" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="STORTWOS" formControlName="STORTWOS" class="form-check-input">Two Story</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_STORTWOS" formControlName="Checkbox_Comment_STORTWOS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="STORSPLT" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="STORSPLT" formControlName="STORSPLT" class="form-check-input">Multi/Split</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_STORSPLT" formControlName="Checkbox_Comment_STORSPLT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </ng-container>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_MBLV" class="reqtext pr-2 py-2 col-form-label">*Master Bedroom Level:</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_MBLV" formControlName="MBLV" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWR">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPR">Upper </option>
                    </select>
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 reqtext">*Sewer/Water:</span></span>
              <ng-container formGroupName="SEWERWATER">
                 <!-- One row for an input -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="WATRPWTR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="WATRPWTR" formControlName="WATRPWTR" class="form-check-input">Public Water</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_WATRPWTR" formControlName="Checkbox_Comment_WATRPWTR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="WATRPWEL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="WATRPWEL" formControlName="WATRPWEL" class="form-check-input">Public Sewer</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_WATRPWEL" formControlName="Checkbox_Comment_WATRPWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="WATRSEPT" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="WATRSEPT" formControlName="WATRSEPT" class="form-check-input">Septic Tank</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_WATRSEPT" formControlName="Checkbox_Comment_WATRSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="WATRSWEL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="WATRSWEL" formControlName="WATRSWEL" class="form-check-input">Shared Well</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_WATRSWEL" formControlName="Checkbox_Comment_WATRSWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="WATRCWEL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="WATRCWEL" formControlName="WATRCWEL" class="form-check-input">Community Well</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_WATRCWEL" formControlName="Checkbox_Comment_WATRCWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="WATRPRVT" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="WATRPRVT" formControlName="WATRPRVT" class="form-check-input">Private Well</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_WATRPRVT" formControlName="Checkbox_Comment_WATRPRVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </ng-container>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 reqtext">*Gas/Electric:</span></span>
              <ng-container formGroupName="GASELECTRIC">
                 <!-- One row for an input -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="GSELPUBU" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="GSELPUBU" formControlName="GSELPUBU" class="form-check-input">Public Utilities</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_GSELPUBU" formControlName="Checkbox_Comment_GSELPUBU" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="GSELPROP" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="GSELPROP" formControlName="GSELPROP" class="form-check-input">Propane</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_GSELPROP" formControlName="Checkbox_Comment_GSELPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="GSELNOGS" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="GSELNOGS" formControlName="GSELNOGS" class="form-check-input">No Gas</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_GSELNOGS" formControlName="Checkbox_Comment_GSELNOGS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="GSELALEL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="GSELALEL" formControlName="GSELALEL" class="form-check-input">All Electric</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_GSELALEL" formControlName="Checkbox_Comment_GSELALEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="GSELGENR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="GSELGENR" formControlName="GSELGENR" class="form-check-input">Generator</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_GSELGENR" formControlName="Checkbox_Comment_GSELGENR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="GSELNOEL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="GSELNOEL" formControlName="GSELNOEL" class="form-check-input">No Electric</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_GSELNOEL" formControlName="Checkbox_Comment_GSELNOEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </ng-container>
              <!--End of left column-->
           </div>
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Unit Description:</span></span>
              <!-- One row for an input -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="UNDSGRDF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNDSGRDF" formControlName="UNDSGRDF" class="form-check-input">Ground Floor</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNDSGRDF" formControlName="Checkbox_Comment_UNDSGRDF" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNDSSECF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNDSSECF" formControlName="UNDSSECF" class="form-check-input">Second Floor</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNDSSECF" formControlName="Checkbox_Comment_UNDSSECF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNDSSPLT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNDSSPLT" formControlName="UNDSSPLT" class="form-check-input">Split Level</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNDSSPLT" formControlName="Checkbox_Comment_UNDSSPLT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNDSENDU" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNDSENDU" formControlName="UNDSENDU" class="form-check-input">End Unit</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNDSENDU" formControlName="Checkbox_Comment_UNDSENDU" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNDSINSD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNDSINSD" formControlName="UNDSINSD" class="form-check-input">Inside</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNDSINSD" formControlName="Checkbox_Comment_UNDSINSD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNDSFRNT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNDSFRNT" formControlName="UNDSFRNT" class="form-check-input">Front</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNDSFRNT" formControlName="Checkbox_Comment_UNDSFRNT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNDSREAR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNDSREAR" formControlName="UNDSREAR" class="form-check-input">Rear</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNDSREAR" formControlName="Checkbox_Comment_UNDSREAR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Special Features:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="SPFTISBD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTISBD" formControlName="SPFTISBD" class="form-check-input">Isolated Bedroom</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTISBD" formControlName="Checkbox_Comment_SPFTISBD" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTISBT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTISBT" formControlName="SPFTISBT" class="form-check-input">Isolated Bathroom</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTISBT" formControlName="Checkbox_Comment_SPFTISBT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTSTDO" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTSTDO" formControlName="SPFTSTDO" class="form-check-input">Studio</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTSTDO" formControlName="Checkbox_Comment_SPFTSTDO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTSSYS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTSSYS" formControlName="SPFTSSYS" class="form-check-input">Security System</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTSSYS" formControlName="Checkbox_Comment_SPFTSSYS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTSGAT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTSGAT" formControlName="SPFTSGAT" class="form-check-input">Security Gate</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTSGAT" formControlName="Checkbox_Comment_SPFTSGAT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTCABN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTCABN" formControlName="SPFTCABN" class="form-check-input">Built In Cabinets</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTCABN" formControlName="Checkbox_Comment_SPFTCABN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTCTLV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTCTLV" formControlName="SPFTCTLV" class="form-check-input">Central Vac</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTCTLV" formControlName="Checkbox_Comment_SPFTCTLV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTSKYL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTSKYL" formControlName="SPFTSKYL" class="form-check-input">Skylight</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTSKYL" formControlName="Checkbox_Comment_SPFTSKYL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTWTRS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTWTRS" formControlName="SPFTWTRS" class="form-check-input">Water Softener</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTWTRS" formControlName="Checkbox_Comment_SPFTWTRS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTWTRF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTWTRF" formControlName="SPFTWTRF" class="form-check-input">Water Filter</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTWTRF" formControlName="Checkbox_Comment_SPFTWTRF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTBARR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTBARR" formControlName="SPFTBARR" class="form-check-input">Wet/Dry Bar</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTBARR" formControlName="Checkbox_Comment_SPFTBARR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTDISH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTDISH" formControlName="SPFTDISH" class="form-check-input">Satelite Dish</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTDISH" formControlName="Checkbox_Comment_SPFTDISH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTINTC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTINTC" formControlName="SPFTINTC" class="form-check-input">Intercom</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTINTC" formControlName="Checkbox_Comment_SPFTINTC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTHTEK" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTHTEK" formControlName="SPFTHTEK" class="form-check-input">High Tech Cable</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTHTEK" formControlName="Checkbox_Comment_SPFTHTEK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTMPHN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTMPHN" formControlName="SPFTMPHN" class="form-check-input">Multi Phone Line</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTMPHN" formControlName="Checkbox_Comment_SPFTMPHN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTTENN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTTENN" formControlName="SPFTTENN" class="form-check-input">Private Tennis Court</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTTENN" formControlName="Checkbox_Comment_SPFTTENN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTOTHR" formControlName="SPFTOTHR" class="form-check-input">Other/See Remarks</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTOTHR" formControlName="Checkbox_Comment_SPFTOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTHSAL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTHSAL" formControlName="SPFTHSAL" class="form-check-input">Horses Allowed</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTHSAL" formControlName="Checkbox_Comment_SPFTHSAL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPFTHSET" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPFTHSET" formControlName="SPFTHSET" class="form-check-input">Horse Setup</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPFTHSET" formControlName="Checkbox_Comment_SPFTHSET" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Disabled Features:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="DSFTWHCR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTWHCR" formControlName="DSFTWHCR" class="form-check-input">Wheel Chair Access</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTWHCR" formControlName="Checkbox_Comment_DSFTWHCR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFTLWHC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTLWHC" formControlName="DSFTLWHC" class="form-check-input">Limited Whl Chr Acc</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTLWHC" formControlName="Checkbox_Comment_DSFTLWHC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFTRAMP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTRAMP" formControlName="DSFTRAMP" class="form-check-input">Ramp</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTRAMP" formControlName="Checkbox_Comment_DSFTRAMP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFTWDDR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTWDDR" formControlName="DSFTWDDR" class="form-check-input">Wide Doors</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTWDDR" formControlName="Checkbox_Comment_DSFTWDDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFTHDRL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTHDRL" formControlName="DSFTHDRL" class="form-check-input">Hand Rails</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTHDRL" formControlName="Checkbox_Comment_DSFTHDRL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFTBTHF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTBTHF" formControlName="DSFTBTHF" class="form-check-input">Bathroom Fixtures</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTBTHF" formControlName="Checkbox_Comment_DSFTBTHF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFTWCHS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTWCHS" formControlName="DSFTWCHS" class="form-check-input">Whl Chr Acc/Shower</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTWCHS" formControlName="Checkbox_Comment_DSFTWCHS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFT1LVL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFT1LVL" formControlName="DSFT1LVL" class="form-check-input">One Level Floor</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFT1LVL" formControlName="Checkbox_Comment_DSFT1LVL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DSFTELEV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DSFTELEV" formControlName="DSFTELEV" class="form-check-input">Elevator</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DSFTELEV" formControlName="Checkbox_Comment_DSFTELEV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--End of right column-->
           </div>
        </div>
        <!-------------------------------------------------------------->
        <!--Page Break-->
        <div>
           <hr class="HRstyle">
        </div>
        <!--Page Break-->
        <!-------------------------------------------------------------->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Energy Efficiency:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="ENERDPWN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="ENERDPWN" formControlName="ENERDPWN" class="form-check-input">Dual Pane Windows</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_ENERDPWN" formControlName="Checkbox_Comment_ENERDPWN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="ENERTPWN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="ENERTPWN" formControlName="ENERTPWN" class="form-check-input">Triple Pane Windows</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_ENERTPWN" formControlName="Checkbox_Comment_ENERTPWN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="ENERSLPH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="ENERSLPH" formControlName="ENERSLPH" class="form-check-input">Solar Pool Heater</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_ENERSLPH" formControlName="Checkbox_Comment_ENERSLPH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="ENERSLWH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="ENERSLWH" formControlName="ENERSLWH" class="form-check-input">Solar Water Heater</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_ENERSLWH" formControlName="Checkbox_Comment_ENERSLWH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="ENERSEAC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="ENERSEAC" formControlName="ENERSEAC" class="form-check-input">13+ SEER A/C</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_ENERSEAC" formControlName="Checkbox_Comment_ENERSEAC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="ENERTANK" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="ENERTANK" formControlName="ENERTANK" class="form-check-input">Tankless WaterHeater</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_ENERTANK" formControlName="Checkbox_Comment_ENERTANK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="ENEROTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="ENEROTHR" formControlName="ENEROTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_ENEROTHR" formControlName="Checkbox_Comment_ENEROTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="CB_AREA" class="reqtext pr-2 py-2 col-form-label">*Solar:</label>
                    <select id="CB_SOLA" formControlName="SOLA" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="YESS">Yes </option>
                       <option class="mBlackText" value="NONO">No </option>
                    </select>
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0810SOLR" formControlName="0810SOLR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!-- Conditional: IF SOLAR Type == 'YESS'-->
              <ng-container *ngIf="form.get('SOLA').value == 'YESS'">
                 <div class="row">
                    <div class="form-group col-sm-6">
                       <label for="CB_SOLR" class="reqtext pr-2 py-2">*Solar Type:</label>
                    </div>
                    <div class="form-group col-sm-6">
                       <select id="CB_SOLR" formControlName="SOLR" class="form-control flex-grow-1">
                          <option value=""></option>
                          <option class="mBlackText" value="LEAS">Lease </option>
                          <option class="mBlackText" value="OWNE">Owned </option>
                          <option class="mBlackText" value="POWE">Power Purchase AGMT </option>
                          <option class="mBlackText" value="OTHR">Other </option>
                       </select>
                    </div>
                 </div>
              </ng-container>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Leased Equipment:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="LSDESECS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="LSDESECS" formControlName="LSDESECS" class="form-check-input">Security System</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_LSDESECS" formControlName="Checkbox_Comment_LSDESECS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="LSDEPROP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="LSDEPROP" formControlName="LSDEPROP" class="form-check-input">Propane</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_LSDEPROP" formControlName="Checkbox_Comment_LSDEPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="LSDEWTRS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="LSDEWTRS" formControlName="LSDEWTRS" class="form-check-input">Water Softner</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_LSDEWTRS" formControlName="Checkbox_Comment_LSDEWTRS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="LSDESATD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="LSDESATD" formControlName="LSDESATD" class="form-check-input">Satelite Dish</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_LSDESATD" formControlName="Checkbox_Comment_LSDESATD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="LSDESOLP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="LSDESOLP" formControlName="LSDESOLP" class="form-check-input">Solar Panels</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_LSDESOLP" formControlName="Checkbox_Comment_LSDESOLP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="CB_SECO" class="nonreqtext pr-2 py-2 col-form-label">Age Restrictions:</label>
                    <select id="CB_SECO" formControlName="SECO" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="YESS">Yes </option>
                       <option class="mBlackText" value="NONO">No </option>
                    </select>
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0830SECO" formControlName="0830SECO" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="CB_LBOX" class="reqtext pr-2 py-2 col-form-label">*Supra Lockbox:</label>
                    <select id="CB_LBOX" formControlName="LBOX" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="YES">Yes </option>
                       <option class="mBlackText" value="NO">No </option>
                    </select>
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0840LBOX" formControlName="0840LBOX" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-8">
                    <label for="1120LOCL" class="nonreqtext pr-2 py-2 col-form-label">Lockbox Location:&nbsp;<span class="formnote">(Maximum 60)</span></label>
                 </div>
                 <div class="form-group col-sm-12 justify-content-end">
                    <textarea id="1120LOCL" formControlName="1120LOCL" class="form-control flex-grow-1" rows="" cols="40" maxlength="60"></textarea>
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="0890GTEC" class="nonreqtext pr-2 py-2 col-form-label">Gate Code:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0890GTEC" formControlName="0890GTEC" class="form-control flex-grow-1" size="" maxlength="10" value="">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-8">
                    <label for="Showing_Instructions" class="nonreqtext pr-2 py-2 col-form-label">Showing Instructions:&nbsp;<span class="formnote">(Maximum 60)</span></label>
                 </div>
                 <div class="form-group col-sm-12 justify-content-end">
                    <textarea id="Showing_Instructions" formControlName="Showing_Instructions" class="form-control flex-grow-1" spellcheck="true" rows="" cols="50" maxlength="60"></textarea>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 reqtext">*Showing:</span></span>
              <ng-container formGroupName="SHOWING">
                 <!-- One row for a new input group -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="SHOWLOCK" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWLOCK" formControlName="SHOWLOCK" class="form-check-input">Lock Box</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWLOCK" formControlName="Checkbox_Comment_SHOWLOCK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWKEYO" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWKEYO" formControlName="SHOWKEYO" class="form-check-input">Key in Office</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWKEYO" formControlName="Checkbox_Comment_SHOWKEYO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWGOOC" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWGOOC" formControlName="SHOWGOOC" class="form-check-input">Go Occupied</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWGOOC" formControlName="Checkbox_Comment_SHOWGOOC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWCALL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWCALL" formControlName="SHOWCALL" class="form-check-input">Call first/LM/Go</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWCALL" formControlName="Checkbox_Comment_SHOWCALL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWAPPT" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWAPPT" formControlName="SHOWAPPT" class="form-check-input">Confirmed Appt Only</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWAPPT" formControlName="Checkbox_Comment_SHOWAPPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOW24HR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOW24HR" formControlName="SHOW24HR" class="form-check-input">24 Hr. Notice</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOW24HR" formControlName="Checkbox_Comment_SHOW24HR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWNOSN" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWNOSN" formControlName="SHOWNOSN" class="form-check-input">Not Sunday</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWNOSN" formControlName="Checkbox_Comment_SHOWNOSN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWSNST" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWSNST" formControlName="SHOWSNST" class="form-check-input">Sunset Fri/Sat</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWSNST" formControlName="Checkbox_Comment_SHOWSNST" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWCLAG" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWCLAG" formControlName="SHOWCLAG" class="form-check-input">Call Agent</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWCLAG" formControlName="Checkbox_Comment_SHOWCLAG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWDOGI" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWDOGI" formControlName="SHOWDOGI" class="form-check-input">Dog Inside</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWDOGI" formControlName="Checkbox_Comment_SHOWDOGI" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWDOGO" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWDOGO" formControlName="SHOWDOGO" class="form-check-input">Dog Outside</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWDOGO" formControlName="Checkbox_Comment_SHOWDOGO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWRSTC" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWRSTC" formControlName="SHOWRSTC" class="form-check-input">Restricted Hours</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWRSTC" formControlName="Checkbox_Comment_SHOWRSTC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWLKGT" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWLKGT" formControlName="SHOWLKGT" class="form-check-input">Locked Gate</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWLKGT" formControlName="Checkbox_Comment_SHOWLKGT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWSEAS" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWSEAS" formControlName="SHOWSEAS" class="form-check-input">Seasonal Access</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWSEAS" formControlName="Checkbox_Comment_SHOWSEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                  <!--next row-->
                  <div class="row fixcheck">
                     <div class="form-group col-sm-6">
                           <label for="SHOWSHOW" class="form-check-label nonreqtext p-2">
                           <input type="checkbox" id="SHOWSHOW" formControlName="SHOWSHOW" class="form-check-input">Showing Time</label>
                     </div>
                     <div class="form-group col-sm-6 justify-content-end">
                           <input type="text" id="Checkbox_Comment_SHOWSEAS" formControlName="Checkbox_Comment_SHOWSHOW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                     </div>
                  </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWFWDR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWFWDR" formControlName="SHOWFWDR" class="form-check-input">4 Wheel Drive</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWFWDR" formControlName="Checkbox_Comment_SHOWFWDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWCBSC" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWCBSC" formControlName="SHOWCBSC" class="form-check-input">CBS Code Required</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWCBSC" formControlName="Checkbox_Comment_SHOWCBSC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWVTGS" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWVTGS" formControlName="SHOWVTGS" class="form-check-input">Vacant Go Show</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWVTGS" formControlName="Checkbox_Comment_SHOWVTGS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="SHOWALRM" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="SHOWALRM" formControlName="SHOWALRM" class="form-check-input">Alarm</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_SHOWALRM" formControlName="Checkbox_Comment_SHOWALRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <span class="row col"><span class="p-2 py-2 reqtext formnote">Alarm code may only be entered in MLS with sellers written consent</span></span>
              </ng-container>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                  <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_UDRS" class="reqtext pr-2 py-2 col-form-label">*Under Surveillance:</label>
                  <select id="CB_UDRS" formControlName="CB_UDRS" class="form-control">
                     <option value=""></option>
                     <option class="mBlackText" value="YESS">Yes </option>
                     <option class="mBlackText" value="NONO">No </option>
                  </select>
                  </div>
                  <div class="form-group col-sm-6">
                  <input type="text" id="1190UDRS" formControlName="1190UDRS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                  </div>
               </div>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_WLDP" class="nonreqtext pr-2 py-2 col-form-label">Well Depth:</label>
                    <input type="text" id="NUM_WLDP" formControlName="WLDP" class="form-control col-sm-6" size="" maxlength=" 8" value="">
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0910WLDP" formControlName="0910WLDP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_WTRL" class="nonreqtext pr-2 py-2 col-form-label">Water Level:</label>
                    <input type="text" id="NUM_WTRL" formControlName="WTRL" class="form-control col-sm-6" size="" maxlength=" 8" value="">
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0930WTRL" formControlName="0930WTRL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_GPM" class="nonreqtext pr-2 py-2 col-form-label">G.P.M.:</label>
                    <input type="text" id="NUM_GPM" formControlName="GPM" class="form-control col-sm-6" size="" maxlength=" 8" value="">
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0940GPM" formControlName="0940GPM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_WLHP" class="nonreqtext pr-2 py-2 col-form-label">Well H.P.:</label>
                    <input type="text" id="NUM_WLHP" formControlName="WLHP" class="form-control col-sm-6" size="" maxlength=" 8" value="">
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="0950WLHP" formControlName="0950WLHP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <h6 class="subtitle pr-2 py-2">Interior Features:</h6>
              <span class="row col"><span class="pr-2 py-2 reqtext">*Heating & Cooling:</span></span>
              <ng-container formGroupName="HEATINGANDCOOLING">
                 <!-- One row for a new input group -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="HTCLCNHC" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLCNHC" formControlName="HTCLCNHC" class="form-check-input">Central Heat & Cool</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLCNHC" formControlName="Checkbox_Comment_HTCLCNHC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLCNHT" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLCNHT" formControlName="HTCLCNHT" class="form-check-input">Central Heating</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLCNHT" formControlName="Checkbox_Comment_HTCLCNHT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLCNCL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLCNCL" formControlName="HTCLCNCL" class="form-check-input">Central Cooling</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLCNCL" formControlName="Checkbox_Comment_HTCLCNCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLDCSP" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLDCSP" formControlName="HTCLDCSP" class="form-check-input">Ductless of Split</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLDCSP" formControlName="Checkbox_Comment_HTCLDCSP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLEVAP" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLEVAP" formControlName="HTCLEVAP" class="form-check-input">Evaporative Cool</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLEVAP" formControlName="Checkbox_Comment_HTCLEVAP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLFLWL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLFLWL" formControlName="HTCLFLWL" class="form-check-input">Floor or Wall Unit</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLFLWL" formControlName="Checkbox_Comment_HTCLFLWL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLWNWL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLWNWL" formControlName="HTCLWNWL" class="form-check-input">Window or Wall A/C</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLWNWL" formControlName="Checkbox_Comment_HTCLWNWL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLWHOL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLWHOL" formControlName="HTCLWHOL" class="form-check-input">Whole House Fan</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLWHOL" formControlName="Checkbox_Comment_HTCLWHOL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLNONE" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLNONE" formControlName="HTCLNONE" class="form-check-input">None</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLNONE" formControlName="Checkbox_Comment_HTCLNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="HTCLOTHR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="HTCLOTHR" formControlName="HTCLOTHR" class="form-check-input">Other</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_HTCLOTHR" formControlName="Checkbox_Comment_HTCLOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </ng-container>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <br><br>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_WLHP" class="reqtext pr-2 py-2 col-form-label">*# of fireplaces:</label>
                    <input type="text" id="NUM_FRPN" formControlName="FRPN" class="form-control col-sm-3" size="" maxlength="2" value="" >
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="1010FRPN" formControlName="1010FRPN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Fireplace Type:</span></span>
              <!-- One row for an input -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="FRPTMASN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FRPTMASN" formControlName="FRPTMASN" class="form-check-input">Masonry</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FRPTMASN" formControlName="Checkbox_Comment_FRPTMASN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FRPTGASO" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FRPTGASO" formControlName="FRPTGASO" class="form-check-input">Gas Insert</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FRPTGASO" formControlName="Checkbox_Comment_FRPTGASO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FRPTWOOD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FRPTWOOD" formControlName="FRPTWOOD" class="form-check-input">Wood Insert</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FRPTWOOD" formControlName="Checkbox_Comment_FRPTWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FRPTZERO" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FRPTZERO" formControlName="FRPTZERO" class="form-check-input">Zero Clearance</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FRPTZERO" formControlName="Checkbox_Comment_FRPTZERO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FRPTFREE" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FRPTFREE" formControlName="FRPTFREE" class="form-check-input">Free Standing</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FRPTFREE" formControlName="Checkbox_Comment_FRPTFREE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FRPTPELL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FRPTPELL" formControlName="FRPTPELL" class="form-check-input">Pellet</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FRPTPELL" formControlName="Checkbox_Comment_FRPTPELL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <br>
              <div class="row">
                 <div class="form-group col-sm-8">
                    <label for="1030FIRL" class="nonreqtext pr-2 py-2 col-form-label">Fireplace Location(s):&nbsp;<span class="formnote">(Maximum 100)</span></label>
                 </div>
                 <div class="form-group col-sm-12 justify-content-end">
                    <textarea id="1030FIRL" formControlName="1030FIRL" class="form-control flex-grow-1" rows="" cols="40" maxlength="100"></textarea>
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 pb-2 nonreqtext">Flooring:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="FLORCARP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FLORCARP" formControlName="FLORCARP" class="form-check-input">Carpet</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FLORCARP" formControlName="Checkbox_Comment_FLORCARP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FLORTILE" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FLORTILE" formControlName="FLORTILE" class="form-check-input">Tile</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FLORTILE" formControlName="Checkbox_Comment_FLORTILE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FLORVINY" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FLORVINY" formControlName="FLORVINY" class="form-check-input">Vinyl</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FLORVINY" formControlName="Checkbox_Comment_FLORVINY" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FLORHDWD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FLORHDWD" formControlName="FLORHDWD" class="form-check-input">Hardwood</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FLORHDWD" formControlName="Checkbox_Comment_FLORHDWD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="FLOROTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="FLOROTHR" formControlName="FLOROTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_FLOROTHR" formControlName="Checkbox_Comment_FLOROTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_FLOO" class="reqtext pr-2 py-2">*Foundation:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <select id="CB_FLOO" formControlName="FLOO" class="form-control flex-grow-1">
                       <option value=""></option>
                       <option class="mBlackText" value="CONC">Concrete </option>
                       <option class="mBlackText" value="WOOD">Wood Sub Floor </option>
                       <option class="mBlackText" value="BOTT">Both </option>
                    </select>
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="0990FLOO" class="nonreqtext pr-2 py-2 col-form-label">Foundation Comment:</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="0990FLOO" formControlName="0990FLOO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 reqtext">*Laundry:</span></span>
              <ng-container formGroupName="LAUNDRY">
                 <!-- One row for a new input group -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="LNDRINSD" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRINSD" formControlName="LNDRINSD" class="form-check-input">Inside</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRINSD" formControlName="Checkbox_Comment_LNDRINSD" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRINGR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRINGR" formControlName="LNDRINGR" class="form-check-input">In Garage</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRINGR" formControlName="Checkbox_Comment_LNDRINGR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRINCL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRINCL" formControlName="LNDRINCL" class="form-check-input">In Closet</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRINCL" formControlName="Checkbox_Comment_LNDRINCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRUTRM" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRUTRM" formControlName="LNDRUTRM" class="form-check-input">Utility Room</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRUTRM" formControlName="Checkbox_Comment_LNDRUTRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRONPR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRONPR" formControlName="LNDRONPR" class="form-check-input">On Porch</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRONPR" formControlName="Checkbox_Comment_LNDRONPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRONUP" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRONUP" formControlName="LNDRONUP" class="form-check-input">On Upper Level</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRONUP" formControlName="Checkbox_Comment_LNDRONUP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRONLW" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRONLW" formControlName="LNDRONLW" class="form-check-input">On Lower Level</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRONLW" formControlName="Checkbox_Comment_LNDRONLW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRGSHK" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRGSHK" formControlName="LNDRGSHK" class="form-check-input">Gas Hook Up</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRGSHK" formControlName="Checkbox_Comment_LNDRGSHK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRELHK" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRELHK" formControlName="LNDRELHK" class="form-check-input">Electric Hook Up</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRELHK" formControlName="Checkbox_Comment_LNDRELHK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRCMMN" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRCMMN" formControlName="LNDRCMMN" class="form-check-input">Common</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRCMMN" formControlName="Checkbox_Comment_LNDRCMMN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRCOIN" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRCOIN" formControlName="LNDRCOIN" class="form-check-input">Coin Op</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRCOIN" formControlName="Checkbox_Comment_LNDRCOIN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="LNDRNONE" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="LNDRNONE" formControlName="LNDRNONE" class="form-check-input">None</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_LNDRNONE" formControlName="Checkbox_Comment_LNDRNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </ng-container>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Bath Features:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="BTHFTUBS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BTHFTUBS" formControlName="BTHFTUBS" class="form-check-input">Tub/Shower</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BTHFTUBS" formControlName="Checkbox_Comment_BTHFTUBS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BTHFSHWR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BTHFSHWR" formControlName="BTHFSHWR" class="form-check-input">Shower</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BTHFSHWR" formControlName="Checkbox_Comment_BTHFSHWR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BTHFTUB" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BTHFTUB" formControlName="BTHFTUB" class="form-check-input">Tub</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BTHFTUB" formControlName="Checkbox_Comment_BTHFTUB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BTHFOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BTHFOTHR" formControlName="BTHFOTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BTHFOTHR" formControlName="Checkbox_Comment_BTHFOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BTHFJTUB" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BTHFJTUB" formControlName="BTHFJTUB" class="form-check-input">Jetted Tub</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BTHFJTUB" formControlName="Checkbox_Comment_BTHFJTUB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BTHFOTUB" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BTHFOTUB" formControlName="BTHFOTUB" class="form-check-input">Oval Tub</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BTHFOTUB" formControlName="Checkbox_Comment_BTHFOTUB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BTHFRTUB" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BTHFRTUB" formControlName="BTHFRTUB" class="form-check-input">Roman Tub</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BTHFRTUB" formControlName="Checkbox_Comment_BTHFRTUB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="NUM_BSSQ" class="nonreqtext pr-2 py-2 col-form-label">Basement Sq Ft:</label>
                    <input type="text" id="NUM_BSSQ" formControlName="BSSQ" class="form-control col-sm-4" size="" maxlength="5" value="">
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="1080BSSQ" formControlName="1080BSSQ" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Basement:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="BSMDFULL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BSMDFULL" formControlName="BSMDFULL" class="form-check-input">Full</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BSMDFULL" formControlName="Checkbox_Comment_BSMDFULL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BSMDPART" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BSMDPART" formControlName="BSMDPART" class="form-check-input">Partial</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BSMDPART" formControlName="Checkbox_Comment_BSMDPART" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BSMDFINS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BSMDFINS" formControlName="BSMDFINS" class="form-check-input">Finished</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BSMDFINS" formControlName="Checkbox_Comment_BSMDFINS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BSMDUNFN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BSMDUNFN" formControlName="BSMDUNFN" class="form-check-input">Unfinished</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BSMDUNFN" formControlName="Checkbox_Comment_BSMDUNFN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="BSMDNONE" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="BSMDNONE" formControlName="BSMDNONE" class="form-check-input">None</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_BSMDNONE" formControlName="Checkbox_Comment_BSMDNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <div class="p-2 showbreak">&nbsp;</div>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Kitchen:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="KITCRNOV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCRNOV" formControlName="KITCRNOV" class="form-check-input">F/S Range/Oven</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCRNOV" formControlName="Checkbox_Comment_KITCRNOV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCBLRO" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCBLRO" formControlName="KITCBLRO" class="form-check-input">Built In Range/Oven</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCBLRO" formControlName="Checkbox_Comment_KITCBLRO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCGASA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCGASA" formControlName="KITCGASA" class="form-check-input">Gas Appliances</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCGASA" formControlName="Checkbox_Comment_KITCGASA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCELCA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCELCA" formControlName="KITCELCA" class="form-check-input">Electric Appliances</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCELCA" formControlName="Checkbox_Comment_KITCELCA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCDISP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCDISP" formControlName="KITCDISP" class="form-check-input">Disposal</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCDISP" formControlName="Checkbox_Comment_KITCDISP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCDISH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCDISH" formControlName="KITCDISH" class="form-check-input">Dishwasher</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCDISH" formControlName="Checkbox_Comment_KITCDISH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCMICR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCMICR" formControlName="KITCMICR" class="form-check-input">Microwave</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCMICR" formControlName="Checkbox_Comment_KITCMICR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCTRSH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCTRSH" formControlName="KITCTRSH" class="form-check-input">Trash Compactor</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCTRSH" formControlName="Checkbox_Comment_KITCTRSH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCEATA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCEATA" formControlName="KITCEATA" class="form-check-input">Eating Area</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCEATA" formControlName="Checkbox_Comment_KITCEATA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCBBAR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCBBAR" formControlName="KITCBBAR" class="form-check-input">Breakfast Bar</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCBBAR" formControlName="Checkbox_Comment_KITCBBAR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCPANT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCPANT" formControlName="KITCPANT" class="form-check-input">Pantry</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCPANT" formControlName="Checkbox_Comment_KITCPANT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCFRIG" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCFRIG" formControlName="KITCFRIG" class="form-check-input">Refrigerator</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCFRIG" formControlName="Checkbox_Comment_KITCFRIG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCWINR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCWINR" formControlName="KITCWINR" class="form-check-input">Wine Refrigerator</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCWINR" formControlName="Checkbox_Comment_KITCWINR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="KITCOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="KITCOTHR" formControlName="KITCOTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_KITCOTHR" formControlName="Checkbox_Comment_KITCOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Dining Room Features:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="DINFFRML" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DINFFRML" formControlName="DINFFRML" class="form-check-input">Formal</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DINFFRML" formControlName="Checkbox_Comment_DINFFRML" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DINFLVRA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DINFLVRA" formControlName="DINFLVRA" class="form-check-input">Living Room/Area</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DINFLVRA" formControlName="Checkbox_Comment_DINFLVRA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DINFFMRA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DINFFMRA" formControlName="DINFFMRA" class="form-check-input">Family Room/Area</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DINFFMRA" formControlName="Checkbox_Comment_DINFFMRA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Extra Rooms:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="XTRRGRTR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRRGRTR" formControlName="XTRRGRTR" class="form-check-input">Great Room</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRRGRTR" formControlName="Checkbox_Comment_XTRRGRTR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRROFC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRROFC" formControlName="XTRROFC" class="form-check-input">Office</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRROFC" formControlName="Checkbox_Comment_XTRROFC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRRFMRM" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRRFMRM" formControlName="XTRRFMRM" class="form-check-input">Family Room</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRRFMRM" formControlName="Checkbox_Comment_XTRRFMRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRRDENS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRRDENS" formControlName="XTRRDENS" class="form-check-input">Den/Study</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRRDENS" formControlName="Checkbox_Comment_XTRRDENS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRRLOFT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRRLOFT" formControlName="XTRRLOFT" class="form-check-input">Loft</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRRLOFT" formControlName="Checkbox_Comment_XTRRLOFT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRRGAME" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRRGAME" formControlName="XTRRGAME" class="form-check-input">Game Room</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRRGAME" formControlName="Checkbox_Comment_XTRRGAME" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRRCNVG" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRRCNVG" formControlName="XTRRCNVG" class="form-check-input">Converted Garage</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRRCNVG" formControlName="Checkbox_Comment_XTRRCNVG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRRWINE" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRRWINE" formControlName="XTRRWINE" class="form-check-input">Wine Room</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRRWINE" formControlName="Checkbox_Comment_XTRRWINE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="XTRROTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="XTRROTHR" formControlName="XTRROTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_XTRROTHR" formControlName="Checkbox_Comment_XTRROTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <!---->
        <h6 class="subtitle pr-1 py-2">Rooms:</h6>
        <!---->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_LIVL" class="nonreqtext pr-2 py-2 col-form-label">Living Room Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_LIVL" formControlName="LIVL" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1130LIVL" formControlName="1130LIVL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_FRML" class="nonreqtext pr-2 py-2 col-form-label">Formal Dining Room Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_FRML" formControlName="FRML" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1140FRML" formControlName="1140FRML" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_KTCL" class="nonreqtext pr-2 py-2 col-form-label">Kitchen Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_KTCL" formControlName="KTCL" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1150KTCL" formControlName="1150KTCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_EATL" class="nonreqtext pr-2 py-2 col-form-label">Eating Area Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_EATL" formControlName="EATL" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1160EATL" formControlName="1160EATL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_FAML" class="nonreqtext pr-2 py-2 col-form-label">Family Room Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_FAML" formControlName="FAML" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1170FAML" formControlName="1170FAML" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_GRTL" class="nonreqtext pr-2 py-2 col-form-label">Great Room Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_GRTL" formControlName="GRTL" class="form-control">
                       <option value=" "></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1180GRTL" formControlName="1180GRTL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_MAIL" class="nonreqtext pr-2 py-2 col-form-label">Main Bedroom Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_MAIL" formControlName="MAIL" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1190MAIL" formControlName="1190MAIL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_SBDR" class="nonreqtext pr-2 py-2 col-form-label">2nd Bedroom Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_SBDR" formControlName="SBDR" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1200SBDR" formControlName="1200SBDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_TBDR" class="nonreqtext pr-2 py-2 col-form-label">3rd Bedroom Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_TBDR" formControlName="TBDR" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1210TBDR" formControlName="1210TBDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_FBDR" class="nonreqtext pr-2 py-2 col-form-label">4th Bedroom Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_FBDR" formControlName="FBDR" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1220FBDR" formControlName="1220FBDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_XTRL" class="nonreqtext pr-2 py-2 col-form-label">Extra Room Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_XTRL" formControlName="XTRL" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1230XTRL" formControlName="1230XTRL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-6">
                    <label for="CB_UTLL" class="nonreqtext pr-2 py-2 col-form-label">Utility Room Level</label>
                 </div>
                 <div class="form-group col-sm-6">
                    <select id="CB_UTLL" formControlName="UTLL" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="LOWE">Lower </option>
                       <option class="mBlackText" value="MAIN">Main </option>
                       <option class="mBlackText" value="UPPE">Upper </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <div class="row">
                 <div class="form-group col-sm-12">
                    <input type="text" id="1240UTLL" formControlName="1240UTLL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <!---->
        <h6 class="subtitle pr-1 py-2">Exterior Features:</h6>
        <!---->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Site Amenities:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="SITECRNR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITECRNR" formControlName="SITECRNR" class="form-check-input">Corner</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITECRNR" formControlName="Checkbox_Comment_SITECRNR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITECULD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITECULD" formControlName="SITECULD" class="form-check-input">Cul De Sac</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITECULD" formControlName="Checkbox_Comment_SITECULD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITEBLUF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITEBLUF" formControlName="SITEBLUF" class="form-check-input">Bluff View</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITEBLUF" formControlName="Checkbox_Comment_SITEBLUF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITEGOLF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITEGOLF" formControlName="SITEGOLF" class="form-check-input">Golf Course</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITEGOLF" formControlName="Checkbox_Comment_SITEGOLF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITERIVR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITERIVR" formControlName="SITERIVR" class="form-check-input">Riverview</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITERIVR" formControlName="Checkbox_Comment_SITERIVR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITELAKE" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITELAKE" formControlName="SITELAKE" class="form-check-input">Lakeview</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITELAKE" formControlName="Checkbox_Comment_SITELAKE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITEPRKV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITEPRKV" formControlName="SITEPRKV" class="form-check-input">Park View</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITEPRKV" formControlName="Checkbox_Comment_SITEPRKV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITEWTRF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITEWTRF" formControlName="SITEWTRF" class="form-check-input">Water Front</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITEWTRF" formControlName="Checkbox_Comment_SITEWTRF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITECRSF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITECRSF" formControlName="SITECRSF" class="form-check-input">Cross Fenced</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITECRSF" formControlName="Checkbox_Comment_SITECRSF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITEPAST" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITEPAST" formControlName="SITEPAST" class="form-check-input">Pasture</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITEPAST" formControlName="Checkbox_Comment_SITEPAST" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SITEOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SITEOTHR" formControlName="SITEOTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SITEOTHR" formControlName="Checkbox_Comment_SITEOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <div class="p-2 showbreak">&nbsp;</div>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Parking Features:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="PARKEXRV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKEXRV" formControlName="PARKEXRV" class="form-check-input">Existing RV Parking</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKEXRV" formControlName="Checkbox_Comment_PARKEXRV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKPTRV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKPTRV" formControlName="PARKPTRV" class="form-check-input">Potential RV Parking</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKPTRV" formControlName="Checkbox_Comment_PARKPTRV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKCRPT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKCRPT" formControlName="PARKCRPT" class="form-check-input">Carport</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKCRPT" formControlName="Checkbox_Comment_PARKCRPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <ng-container *ngIf="form.get('PARKCRPT').value == true">
                 <div class="row">
                    <div class="form-group col-sm-6 justify-content-center">
                       <label for="NUM_CRPT" class="form-check-label reqtext p-2">*Carport Spaces:</label>
                    </div>
                    <div class="form-group col-sm-6">
                       <input type="text" id="NUM_CRPT" formControlName="CRPT" class="form-control col-sm-5" size="" maxlength="8" value="">
                    </div>
                 </div>
              </ng-container>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKUNCV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKUNCV" formControlName="PARKUNCV" class="form-check-input">Uncovered</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKUNCV" formControlName="Checkbox_Comment_PARKUNCV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKWKSH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKWKSH" formControlName="PARKWKSH" class="form-check-input">Work/Shop Area</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKWKSH" formControlName="Checkbox_Comment_PARKWKSH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKDRTH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKDRTH" formControlName="PARKDRTH" class="form-check-input">Drive Thru</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKDRTH" formControlName="Checkbox_Comment_PARKDRTH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKAOPN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKAOPN" formControlName="PARKAOPN" class="form-check-input">Auto Opener</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKAOPN" formControlName="Checkbox_Comment_PARKAOPN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKSTRT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKSTRT" formControlName="PARKSTRT" class="form-check-input">Street</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKSTRT" formControlName="Checkbox_Comment_PARKSTRT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKCIRC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKCIRC" formControlName="PARKCIRC" class="form-check-input">Circular Driveway</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKCIRC" formControlName="Checkbox_Comment_PARKCIRC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKTNDM" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKTNDM" formControlName="PARKTNDM" class="form-check-input">Tandem Garage</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKTNDM" formControlName="Checkbox_Comment_PARKTNDM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PARKOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PARKOTHR" formControlName="PARKOTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PARKOTHR" formControlName="Checkbox_Comment_PARKOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <!--break in page-->
        <br>
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-md-6 align-self-start">
              <!--next row-->
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="Garage_Spaces" class="reqtext pr-2 py-2 col-form-label">*Garage Spaces:</label>
                    <input type="text" id="Garage_Spaces" formControlName="Garage_Spaces" class="form-control col-sm-4" value="0" size="" maxlength="3">
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <!-- Conditional: IF Garage Space > 0-->
           <ng-container *ngIf="form.get('Garage_Spaces').value > 0">
              <div class="col-md-6 align-self-start">
                 <span class="row col"><span class="pr-2 py-2 reqtext"><label for="CB_GART">*Garage Type:</label></span></span>
                 <!--next row-->
                 <div class="row">
                    <div class="form-group col-sm-6">
                       <select id="CB_GART" formControlName="GART" class="form-control">
                          <option value=""></option>
                          <option class="mBlackText" value="ATTD">Attached </option>
                          <option class="mBlackText" value="DTTD">Detached </option>
                       </select>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="1290GART" formControlName="1290GART" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
              </div>
              <!--end of right column-->
           </ng-container>
        </div>
        <!--end of page/section-->
        <!--break in page-->
        <br>
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-md-6 align-self-start">
              <!--next row-->
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="Garage_Spaces" class="reqtext pr-2 py-2 col-form-label">*Pool:</label>
                    <select id="Pool" formControlName="Pool" class="form-control col-sm-4">
                       <option value="" selected=""> </option>
                       <option value="Y">Yes </option>
                       <option value="N">No </option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <!-- Conditional: IF Garage Space > 0-->
           <ng-container *ngIf="form.get('Pool').value == 'Yes'">
              <div class="col-md-6 align-self-start">
                 <span class="row col"><span class="pr-2 py-2 reqtext"><label for="CB_GART">*Pool Type:</label></span></span>
                 <ng-container formGroupName="POOLTYPE">
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="POLTINGR" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="POLTINGR" formControlName="POLTINGR" class="form-check-input">In Ground</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_POLTINGR" formControlName="Checkbox_Comment_POLTINGR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="POLTABGR" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="POLTABGR" formControlName="POLTABGR" class="form-check-input">Above Ground</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_POLTABGR" formControlName="Checkbox_Comment_POLTABGR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                 </ng-container>
              </div>
              <!--end of right column-->
           </ng-container>
        </div>
        <!--end of page/section-->
        <!--break in page-->
        <br>
        <div class="d-flex form-inline row">
           <div class="col-lg-12 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Pool Features:</span></span>
              <!-- Top input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-md-12 justify-content-between">
                    <div class="p-2">
                       <label for="PLFEFNCD" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEFNCD" formControlName="PLFEFNCD" class="form-check-input">Fenced</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFEFIBR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEFIBR" formControlName="PLFEFIBR" class="form-check-input ">Fiberglass</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFEVINY" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEVINY" formControlName="PLFEVINY" class="form-check-input">Vinyl</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFEPEBB" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEPEBB" formControlName="PLFEPEBB" class="form-check-input">Pebble</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFEFIBO" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEFIBO" formControlName="PLFEFIBO" class="form-check-input">Fiber Optic Lighting</label>
                    </div>
                 </div>
              </div>
              <!-- Bottom input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-md-12 justify-content-between">
                    <div class="p-2">
                       <label for="PLFEGUNI" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEGUNI" formControlName="PLFEGUNI" class="form-check-input">Gunite</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFEGRAS" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEGRAS" formControlName="PLFEGRAS" class="form-check-input">Grassy Area</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFEHEAT" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEHEAT" formControlName="PLFEHEAT" class="form-check-input">Heated</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFECMTY" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFECMTY" formControlName="PLFECMTY" class="form-check-input">Community Pool</label>
                    </div>
                    <div class="p-2">
                       <label for="PLFEWTRF" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="PLFEWTRF" formControlName="PLFEWTRF" class="form-check-input">Water Feature</label>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <!--break in page-->
        <br>
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 reqtext">*Roofing:</span></span>
              <ng-container formGroupName="ROOFING">
                 <!-- One row for a new input group -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="ROOFCOMP" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFCOMP" formControlName="ROOFCOMP" class="form-check-input">Composition</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFCOMP" formControlName="Checkbox_Comment_ROOFCOMP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="ROOFTILE" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFTILE" formControlName="ROOFTILE" class="form-check-input">Tile</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFTILE" formControlName="Checkbox_Comment_ROOFTILE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="ROOFWOOD" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFWOOD" formControlName="ROOFWOOD" class="form-check-input">Wood</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFWOOD" formControlName="Checkbox_Comment_ROOFWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="ROOFMETL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFMETL" formControlName="ROOFMETL" class="form-check-input">Metal</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFMETL" formControlName="Checkbox_Comment_ROOFMETL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="ROOFTARG" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFTARG" formControlName="ROOFTARG" class="form-check-input">Tar/Gravel</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFTARG" formControlName="Checkbox_Comment_ROOFTARG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="ROOFROLL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFROLL" formControlName="ROOFROLL" class="form-check-input">Rolled/Hot Mop</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFROLL" formControlName="Checkbox_Comment_ROOFROLL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="ROOFFIBR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFFIBR" formControlName="ROOFFIBR" class="form-check-input">Fiberglass</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFFIBR" formControlName="Checkbox_Comment_ROOFFIBR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="ROOFOTHR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="ROOFOTHR" formControlName="ROOFOTHR" class="form-check-input">Other</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_ROOFOTHR" formControlName="Checkbox_Comment_ROOFOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </ng-container>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Yard:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="YARDSPKF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDSPKF" formControlName="YARDSPKF" class="form-check-input">Sprinklers Front</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDSPKF" formControlName="Checkbox_Comment_YARDSPKF" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDSPKB" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDSPKB" formControlName="YARDSPKB" class="form-check-input">Sprinklers Back</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDSPKB" formControlName="Checkbox_Comment_YARDSPKB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDSPKA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDSPKA" formControlName="YARDSPKA" class="form-check-input">Sprinklers Auto</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDSPKA" formControlName="Checkbox_Comment_YARDSPKA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDSPKM" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDSPKM" formControlName="YARDSPKM" class="form-check-input">Sprinklers Manual</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDSPKM" formControlName="Checkbox_Comment_YARDSPKM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDFNCD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDFNCD" formControlName="YARDFNCD" class="form-check-input">Fenced</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDFNCD" formControlName="Checkbox_Comment_YARDFNCD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDMTRL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDMTRL" formControlName="YARDMTRL" class="form-check-input">Mature Landscape</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDMTRL" formControlName="Checkbox_Comment_YARDMTRL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDFRUT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDFRUT" formControlName="YARDFRUT" class="form-check-input">Fruit/Nut Trees</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDFRUT" formControlName="Checkbox_Comment_YARDFRUT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDGRDA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDGRDA" formControlName="YARDGRDA" class="form-check-input">Garden Area</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDGRDA" formControlName="Checkbox_Comment_YARDGRDA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDSYNT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDSYNT" formControlName="YARDSYNT" class="form-check-input">Synthetic Lawn</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDSYNT" formControlName="Checkbox_Comment_YARDSYNT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDDRIP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDDRIP" formControlName="YARDDRIP" class="form-check-input">Drip System</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDDRIP" formControlName="Checkbox_Comment_YARDDRIP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="YARDOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="YARDOTHR" formControlName="YARDOTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_YARDOTHR" formControlName="Checkbox_Comment_YARDOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <div class="p-2 showbreak">&nbsp;</div>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 reqtext">*Exterior:</span></span>
              <ng-container formGroupName="EXTERIOR">
                 <!-- One row for a new input group -->
                 <div class="row fixcheck">
                    <!-- Within that row, specify column length  -->
                    <div class="form-group col-sm-6">
                       <label for="EXTRSTUC" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRSTUC" formControlName="EXTRSTUC" class="form-check-input">Stucco</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRSTUC" formControlName="Checkbox_Comment_EXTRSTUC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRWOOD" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRWOOD" formControlName="EXTRWOOD" class="form-check-input">Wood</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRWOOD" formControlName="Checkbox_Comment_EXTRWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRBRIK" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRBRIK" formControlName="EXTRBRIK" class="form-check-input">Brick</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRBRIK" formControlName="Checkbox_Comment_EXTRBRIK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRBLCK" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRBLCK" formControlName="EXTRBLCK" class="form-check-input">Block</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRBLCK" formControlName="Checkbox_Comment_EXTRBLCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRSTON" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRSTON" formControlName="EXTRSTON" class="form-check-input">Stone</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRSTON" formControlName="Checkbox_Comment_EXTRSTON" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRMETL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRMETL" formControlName="EXTRMETL" class="form-check-input">Metal</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRMETL" formControlName="Checkbox_Comment_EXTRMETL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRSHGL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRSHGL" formControlName="EXTRSHGL" class="form-check-input">Shingle</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRSHGL" formControlName="Checkbox_Comment_EXTRSHGL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRLOGG" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRLOGG" formControlName="EXTRLOGG" class="form-check-input">Log</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRLOGG" formControlName="Checkbox_Comment_EXTRLOGG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTRVNYL" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTRVNYL" formControlName="EXTRVNYL" class="form-check-input">Vinyl</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTRVNYL" formControlName="Checkbox_Comment_EXTRVNYL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
                 <!--next row-->
                 <div class="row fixcheck">
                    <div class="form-group col-sm-6">
                       <label for="EXTROTHR" class="form-check-label nonreqtext p-2">
                       <input type="checkbox" id="EXTROTHR" formControlName="EXTROTHR" class="form-check-input">Other</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="Checkbox_Comment_EXTROTHR" formControlName="Checkbox_Comment_EXTROTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </ng-container>
              <!--break for next group in the right column-->
              <br>
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="CB_SPAY" class="nonreqtext pr-2 py-2 col-form-label">Spa:</label>
                    <select id="CB_SPAY" formControlName="SPAY" class="form-control">
                       <option value=""></option>
                       <option class="mBlackText" value="YESY">Yes </option>
                       <option class="mBlackText" value="NONO">No </option>
                    </select>
                 </div>
                 <div class="form-group col-sm-6">
                    <input type="text" id="1380SPAY" formControlName="1380SPAY" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Spa Type:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="SPATINGR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPATINGR" formControlName="SPATINGR" class="form-check-input">In Ground</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPATINGR" formControlName="Checkbox_Comment_SPATINGR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPATABGR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPATABGR" formControlName="SPATABGR" class="form-check-input">Above Ground</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPATABGR" formControlName="Checkbox_Comment_SPATABGR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPATCOMS" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPATCOMS" formControlName="SPATCOMS" class="form-check-input">Community Spa</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPATCOMS" formControlName="Checkbox_Comment_SPATCOMS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <!---->
        <div>
           <hr class="HRstyle">
        </div>
        <!---->
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Patio Features:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="PATOCVRD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOCVRD" formControlName="PATOCVRD" class="form-check-input">Covered</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOCVRD" formControlName="Checkbox_Comment_PATOCVRD" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOUNCV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOUNCV" formControlName="PATOUNCV" class="form-check-input">Uncovered</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOUNCV" formControlName="Checkbox_Comment_PATOUNCV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOENCL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOENCL" formControlName="PATOENCL" class="form-check-input">Enclosed</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOENCL" formControlName="Checkbox_Comment_PATOENCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOBRCK" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOBRCK" formControlName="PATOBRCK" class="form-check-input">Brick</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOBRCK" formControlName="Checkbox_Comment_PATOBRCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOCONC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOCONC" formControlName="PATOCONC" class="form-check-input">Concrete</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOCONC" formControlName="Checkbox_Comment_PATOCONC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOWOOD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOWOOD" formControlName="PATOWOOD" class="form-check-input">Wood Deck</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOWOOD" formControlName="Checkbox_Comment_PATOWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOSTON" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOSTON" formControlName="PATOSTON" class="form-check-input">Stone</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOSTON" formControlName="Checkbox_Comment_PATOSTON" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOSCRN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOSCRN" formControlName="PATOSCRN" class="form-check-input">Screened</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOSCRN" formControlName="Checkbox_Comment_PATOSCRN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="PATOOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="PATOOTHR" formControlName="PATOOTHR" class="form-check-input">Other</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_PATOOTHR" formControlName="Checkbox_Comment_PATOOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <div class="p-2 showbreak">&nbsp;</div>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Detached Structures:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="DETSSHOP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETSSHOP" formControlName="DETSSHOP" class="form-check-input">Shop</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETSSHOP" formControlName="Checkbox_Comment_DETSSHOP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DETSGEST" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETSGEST" formControlName="DETSGEST" class="form-check-input">Guest House</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETSGEST" formControlName="Checkbox_Comment_DETSGEST" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DETSBARN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETSBARN" formControlName="DETSBARN" class="form-check-input">Barn</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETSBARN" formControlName="Checkbox_Comment_DETSBARN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DETSKENL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETSKENL" formControlName="DETSKENL" class="form-check-input">Kennel</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETSKENL" formControlName="Checkbox_Comment_DETSKENL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DETSSHED" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETSSHED" formControlName="DETSSHED" class="form-check-input">Shed</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETSSHED" formControlName="Checkbox_Comment_DETSSHED" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DETS2HOU" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETS2HOU" formControlName="DETS2HOU" class="form-check-input">2 Homes on Lot</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETS2HOU" formControlName="Checkbox_Comment_DETS2HOU" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DETSGRNH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETSGRNH" formControlName="DETSGRNH" class="form-check-input">Green House</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETSGRNH" formControlName="Checkbox_Comment_DETSGRNH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="DETSOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="DETSOTHR" formControlName="DETSOTHR" class="form-check-input">Other/See Remarks</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_DETSOTHR" formControlName="Checkbox_Comment_DETSOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <br>
        <div class="section text-white p-1">Financial Data</div>
        <br>
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Special Disclosure:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="SPECFLOD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPECFLOD" formControlName="SPECFLOD" class="form-check-input">Flood Zone</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPECFLOD" formControlName="Checkbox_Comment_SPECFLOD" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPECAGTR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPECAGTR" formControlName="SPECAGTR" class="form-check-input">Agent/Owner Related</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPECAGTR" formControlName="Checkbox_Comment_SPECAGTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPECDEPR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPECDEPR" formControlName="SPECDEPR" class="form-check-input">Death On Property</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPECDEPR" formControlName="Checkbox_Comment_SPECDEPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPECFLIT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPECFLIT" formControlName="SPECFLIT" class="form-check-input">Flight Path</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPECFLIT" formControlName="Checkbox_Comment_SPECFLIT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="SPECSEER" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="SPECSEER" formControlName="SPECSEER" class="form-check-input">See Remrks/Attchmnts</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_SPECSEER" formControlName="Checkbox_Comment_SPECSEER" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">1st Loan Type:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="1SLNASSM" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="1SLNASSM" formControlName="1SLNASSM" class="form-check-input">Assumable</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_1SLNASSM" formControlName="Checkbox_Comment_1SLNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="1SLNNONA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="1SLNNONA" formControlName="1SLNNONA" class="form-check-input">Non-Assumable</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_1SLNNONA" formControlName="Checkbox_Comment_1SLNNONA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="1SLNPRVT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="1SLNPRVT" formControlName="1SLNPRVT" class="form-check-input">Private</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_1SLNPRVT" formControlName="Checkbox_Comment_1SLNPRVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="1SLNFRCL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="1SLNFRCL" formControlName="1SLNFRCL" class="form-check-input">Free & Clear</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_1SLNFRCL" formControlName="Checkbox_Comment_1SLNFRCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">2nd Loan Type:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="2LNASSM" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="2LNASSM" formControlName="2LNASSM" class="form-check-input">Assumable</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_2LNASSM" formControlName="Checkbox_Comment_2LNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="2LNPRVT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="2LNPRVT" formControlName="2LNPRVT" class="form-check-input">Private</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_2LNPRVT" formControlName="Checkbox_Comment_2LNPRVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="2LNNONA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="2LNNONA" formControlName="2LNNONA" class="form-check-input">Non-Assumable</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_2LNNONA" formControlName="Checkbox_Comment_2LNNONA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Seller Will Consider:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="TERMGOVT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="TERMGOVT" formControlName="TERMGOVT" class="form-check-input">Government</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_TERMGOVT" formControlName="Checkbox_Comment_TERMGOVT" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="TERMCONV" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="TERMCONV" formControlName="TERMCONV" class="form-check-input">Conventional</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_TERMCONV" formControlName="Checkbox_Comment_TERMCONV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="TERMLSOP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="TERMLSOP" formControlName="TERMLSOP" class="form-check-input">Lease Option</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_TERMLSOP" formControlName="Checkbox_Comment_TERMLSOP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="TERMCASH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="TERMCASH" formControlName="TERMCASH" class="form-check-input">Cash</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_TERMCASH" formControlName="Checkbox_Comment_TERMCASH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="TERMOWNR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="TERMOWNR" formControlName="TERMOWNR" class="form-check-input">Owner Carry</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_TERMOWNR" formControlName="Checkbox_Comment_TERMOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="TERMLIMI" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="TERMLIMI" formControlName="TERMLIMI" class="form-check-input">Limited Financing</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_TERMLIMI" formControlName="Checkbox_Comment_TERMLIMI" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Unique Conditions:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="UNIQPROB" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNIQPROB" formControlName="UNIQPROB" class="form-check-input">Probate</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNIQPROB" formControlName="Checkbox_Comment_UNIQPROB" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNIQESTA" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNIQESTA" formControlName="UNIQESTA" class="form-check-input">Estate Sale</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNIQESTA" formControlName="Checkbox_Comment_UNIQESTA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNIQBKRP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNIQBKRP" formControlName="UNIQBKRP" class="form-check-input">Bankruptcy</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNIQBKRP" formControlName="Checkbox_Comment_UNIQBKRP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNIQCORT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNIQCORT" formControlName="UNIQCORT" class="form-check-input">Court Approvel Req</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNIQCORT" formControlName="Checkbox_Comment_UNIQCORT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNIQRELO" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNIQRELO" formControlName="UNIQRELO" class="form-check-input">Relo Company</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNIQRELO" formControlName="Checkbox_Comment_UNIQRELO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="UNIQEXCH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="UNIQEXCH" formControlName="UNIQEXCH" class="form-check-input">1031 Exchange</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_UNIQEXCH" formControlName="Checkbox_Comment_UNIQEXCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <div class="p-2 showbreak">&nbsp;</div>
                 </div>
              </div>
           </div>
           <!--end of left column-->
           <!--Right Column-->
           <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Reports Available:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                 <!-- Within that row, specify column length  -->
                 <div class="form-group col-sm-6">
                    <label for="IRPTHOME" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTHOME" formControlName="IRPTHOME" class="form-check-input">Home Inspection</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTHOME" formControlName="Checkbox_Comment_IRPTHOME" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTROOF" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTROOF" formControlName="IRPTROOF" class="form-check-input">Roof</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTROOF" formControlName="Checkbox_Comment_IRPTROOF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTPEST" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTPEST" formControlName="IRPTPEST" class="form-check-input">Pest</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTPEST" formControlName="Checkbox_Comment_IRPTPEST" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTSEPT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTSEPT" formControlName="IRPTSEPT" class="form-check-input">Septic</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTSEPT" formControlName="Checkbox_Comment_IRPTSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTWELR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTWELR" formControlName="IRPTWELR" class="form-check-input">Well</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTWELR" formControlName="Checkbox_Comment_IRPTWELR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTWTRP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTWTRP" formControlName="IRPTWTRP" class="form-check-input">Water Potability</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTWTRP" formControlName="Checkbox_Comment_IRPTWTRP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTCC&R" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTCC&R" formControlName="IRPTCC&R" class="form-check-input">CC & R's</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTCC&R" formControlName="Checkbox_Comment_IRPTCC&R" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTRULE" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTRULE" formControlName="IRPTRULE" class="form-check-input">Rules & Regulations</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTRULE" formControlName="Checkbox_Comment_IRPTRULE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTBYLW" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTBYLW" formControlName="IRPTBYLW" class="form-check-input">By Laws</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTBYLW" formControlName="Checkbox_Comment_IRPTBYLW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTBDGT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTBDGT" formControlName="IRPTBDGT" class="form-check-input">Budget</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTBDGT" formControlName="Checkbox_Comment_IRPTBDGT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTFINC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTFINC" formControlName="IRPTFINC" class="form-check-input">Financial Statement</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTFINC" formControlName="Checkbox_Comment_IRPTFINC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTOTHR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTOTHR" formControlName="IRPTOTHR" class="form-check-input">Other HOA Docs</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTOTHR" formControlName="Checkbox_Comment_IRPTOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTAPPR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTAPPR" formControlName="IRPTAPPR" class="form-check-input">Appraisal</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTAPPR" formControlName="Checkbox_Comment_IRPTAPPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTNHDD" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTNHDD" formControlName="IRPTNHDD" class="form-check-input">NHD</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTNHDD" formControlName="Checkbox_Comment_IRPTNHDD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                 <div class="form-group col-sm-6">
                    <label for="IRPTPREL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="IRPTPREL" formControlName="IRPTPREL" class="form-check-input">Prelim Title Report</label>
                 </div>
                 <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_IRPTPREL" formControlName="Checkbox_Comment_IRPTPREL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                 </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <!--next row-->
              <div class="row">
                 <div class="form-group col-md-6 justify-content-between">
                    <label for="CB_AUCT" class="reqtext pr-2 py-2 col-form-label">*Auction:</label>
                 </div>
                 <div class="form-group col-md-4">
                    <select id="CB_AUCT" formControlName="AUCT" class="form-control col-sm-8">
                       <option value=""></option>
                       <option class="mBlackText" value="YESS">Yes </option>
                       <option class="mBlackText" value="NONO">No </option>
                    </select>
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-md-6 justify-content-between">
                    <label for="CB_HSSB" class="nonreqtext pr-2 py-2 col-form-label">Hardship Submitted:</label>
                 </div>
                 <div class="form-group col-md-4">
                    <select id="CB_HSSB" formControlName="HSSB" class="form-control col-sm-8">
                       <option value=""></option>
                       <option class="mBlackText" value="YESS">Yes </option>
                       <option class="mBlackText" value="NONO">No </option>
                    </select>
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-md-6 justify-content-between">
                    <label for="NUM_STTD" class="nonreqtext pr-2 py-2 col-form-label">1st Trust Deed Amount:</label>
                 </div>
                 <div class="form-group col-md-4">
                    <input type="text" id="NUM_STTD" formControlName="STTD" class="form-control col-sm-8" size="" maxlength="11" value="">
                 </div>
                 <div class="form-group col-md-6 ml-auto">
                    <input type="text" id="1530STTD" formControlName="1530STTD" class="form-control col-sm-12" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-md-6 justify-content-between">
                    <label for="NUM_SDTD" class="nonreqtext pr-2 py-2 col-form-label">2nd Trust Deed Amount:</label>
                 </div>
                 <div class="form-group col-md-4">
                    <input type="text" id="NUM_SDTD" formControlName="SDTD" class="form-control col-sm-8" size="" maxlength="11" value="">
                 </div>
                 <div class="form-group col-md-6 ml-auto">
                    <input type="text" id="1540SDTD" formControlName="1540SDTD" class="form-control col-sm-12" size="" maxlength="40" value="">
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-sm-8">
                    <label for="1550SSSS" class="nonreqtext pr-2 py-2 col-form-label">Notes:&nbsp;<span class="formnote">(Maximum 100)</span></label>
                 </div>
                 <div class="form-group col-sm-12 justify-content-end">
                    <textarea id="1550SSSS" formControlName="1550SSSS" class="form-control flex-grow-1" spellcheck="true" rows="" cols="50" maxlength="100"></textarea>
                 </div>
              </div>
              <div class="row">
                 <div class="form-group col-md-6">
                    <label for="NUM_ADDD" class="nonreqtext pr-2 py-2 col-form-label">Additional Debt:</label>
                 </div>
                 <div class="form-group col-md-4">
                    <input type="text" id="NUM_ADDD" formControlName="ADDD" class="form-control col-sm-8" size="" maxlength="11" value="">
                 </div>
                 <div class="form-group col-md-6 ml-auto">
                    <input type="text" id="1560ADDD" formControlName="1560ADDD" class="form-control col-sm-12" size="" maxlength="40" value="">
                 </div>
              </div>
           </div>
           <!--end of right column-->
        </div>
        <!--end of page/section-->
        <br>
        <div class="section text-white p-1">Extras</div>
        <br>
        <div class="d-flex form-inline row">
           <!-- Left Column-->
           <div class="col-lg-6 align-self-start">
              <!--next row-->
              <div class="row">
                 <div class="form-group col-sm-6 justify-content-between">
                    <label for="CB_HOAA" class="reqtext pr-2 py-2 col-form-label">*HOA:</label>
                    <select id="CB_HOAA" formControlName="HOAA" class="form-control col-sm-4">
                       <option value=""></option>
                       <option class="mBlackText" value="YESS">Yes</option>
                       <option class="mBlackText" value="NONO">No</option>
                    </select>
                 </div>
              </div>
           </div>
           <!--end of left column-->
        </div>
        <!--end of page/section-->
        <!-- Conditional: IF Transcation Type == 'Lease'-->
        <ng-container *ngIf="form.get('HOAA').value == 'YESS'">
           <div class="d-flex form-inline row">
              <!-- Left Column-->
              <div class="col-lg-6 align-self-start">
                 <!--next row-->
                 <div class="row">
                    <div class="form-group col-sm-6 justify-content-between">
                       <label for="NUM_RENT" class="reqtext pr-2 py-2 col-form-label">*HOA Dues:</label>
                       <input type="text" id="NUM_RENT" formControlName="RENT" class="form-control col-sm-4" size="" maxlength="9" value="">
                    </div>
                    <div class="form-group col-sm-6">
                       <input type="text" id="1610RENT" formControlName="1610RENT" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
              </div>
              <!--end of left column-->
              <!--Right Column-->
              <div class="col-lg-6 align-self-start">
                 <!--next row-->
                 <div class="row">
                    <div class="form-group col-sm-6">
                       <label for="1620FREQ" class="reqtext pr-2 py-2 col-form-label">*Frequency:</label>
                    </div>
                    <div class="form-group col-sm-6 justify-content-end">
                       <input type="text" id="1620FREQ" formControlName="1620FREQ" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
              <!--end of right column-->
           </div>
           <!--end of page/section-->
           <div class="d-flex form-inline row">
              <!-- Left Column-->
              <ng-container formGroupName="HOAAMENITIES">
                 <div class="col-lg-6 align-self-start">
                    <span class="row col"><span class="pr-2 py-2 reqtext">*HOA Amenities:</span></span>
                    <!-- One row for a new input group -->
                    <div class="row fixcheck">
                       <!-- Within that row, specify column length  -->
                       <div class="form-group col-sm-6">
                          <label for="PRKFPOOL" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFPOOL" formControlName="PRKFPOOL" class="form-check-input">Pool</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFPOOL" formControlName="Checkbox_Comment_PRKFPOOL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFSPA" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFSPA" formControlName="PRKFSPA" class="form-check-input">Spa</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFSPA" formControlName="Checkbox_Comment_PRKFSPA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFSAUN" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFSAUN" formControlName="PRKFSAUN" class="form-check-input">Sauna</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFSAUN" formControlName="Checkbox_Comment_PRKFSAUN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFCLUB" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFCLUB" formControlName="PRKFCLUB" class="form-check-input">Club House</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFCLUB" formControlName="Checkbox_Comment_PRKFCLUB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFEXRM" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFEXRM" formControlName="PRKFEXRM" class="form-check-input">Exercise Room</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFEXRM" formControlName="Checkbox_Comment_PRKFEXRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFGRNA" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFGRNA" formControlName="PRKFGRNA" class="form-check-input">Green Area</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFGRNA" formControlName="Checkbox_Comment_PRKFGRNA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFRVST" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFRVST" formControlName="PRKFRVST" class="form-check-input">RV Storage</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFRVST" formControlName="Checkbox_Comment_PRKFRVST" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFTENN" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFTENN" formControlName="PRKFTENN" class="form-check-input">Tennis</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFTENN" formControlName="Checkbox_Comment_PRKFTENN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                 </div>
                 <!--end of left column-->
                 <!--Right Column-->
                 <div class="col-lg-6 align-self-start">
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <div class="p-2 hidebreak">&nbsp;</div>
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFRQTB" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFRQTB" formControlName="PRKFRQTB" class="form-check-input">Racquet Ball</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFRQTB" formControlName="Checkbox_Comment_PRKFRQTB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFSECG" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFSECG" formControlName="PRKFSECG" class="form-check-input">Security Guard</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFSECG" formControlName="Checkbox_Comment_PRKFSECG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFLKPN" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFLKPN" formControlName="PRKFLKPN" class="form-check-input">Lake/Pond</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFLKPN" formControlName="Checkbox_Comment_PRKFLKPN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFPLAY" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFPLAY" formControlName="PRKFPLAY" class="form-check-input">Playground</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFPLAY" formControlName="Checkbox_Comment_PRKFPLAY" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFLDSC" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFLDSC" formControlName="PRKFLDSC" class="form-check-input">Lanscape Maint.</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFLDSC" formControlName="Checkbox_Comment_PRKFLDSC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFGATE" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFGATE" formControlName="PRKFGATE" class="form-check-input">Gated Community</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFGATE" formControlName="Checkbox_Comment_PRKFGATE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                    <!--next row-->
                    <div class="row fixcheck">
                       <div class="form-group col-sm-6">
                          <label for="PRKFOTNN" class="form-check-label nonreqtext p-2">
                          <input type="checkbox" id="PRKFOTNN" formControlName="PRKFOTNN" class="form-check-input">Other/None</label>
                       </div>
                       <div class="form-group col-sm-6 justify-content-end">
                          <input type="text" id="Checkbox_Comment_PRKFOTNN" formControlName="Checkbox_Comment_PRKFOTNN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                       </div>
                    </div>
                 </div>
                 <!--end of right column-->
              </ng-container>
           </div>
           <!--end of page/section-->
           <br>
           <div class="d-flex form-inline row">
              <!-- Left Column-->
              <div class="col-lg-6 align-self-start">
                 <!--next row-->
                 <div class="row">
                    <div class="form-group col-sm-6 justify-content-between">
                       <label for="CB_SEON" class="reqtext pr-2 py-2 col-form-label">*Seniors Only:</label>
                       <select id="CB_SEON" formControlName="SEON" class="form-control col-sm-4">
                          <option value=""></option>
                          <option class="mBlackText" value="Yes">Yes </option>
                          <option class="mBlackText" value="No">No </option>
                       </select>
                    </div>
                    <div class="form-group col-sm-6">
                       <input type="text" id="1640SEON" formControlName="1640SEON" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                    </div>
                 </div>
              </div>
              <!--end of left column-->
           </div>
           <!--end of page/section-->
        </ng-container>
        <hr class="HRstyle">
        <!--Begin Section-->
        <div class="d-flex form-inline row">
           <!--Begin column-->
           <div class="col-lg-12 align-self-start">
              <!--entries-->
              <div class="row">
                 <div class="form-group col-md-3">
                    <label for="CB_INPK" class="reqtext pr-2 py-2 col-form-label">*Mobile Home in Park:</label>
                 </div>
                 <div class="form-group col-md-3">
                    <select id="CB_INPK" formControlName="INPK" class="form-control col-sm-5 px-md-1 px-lg-3">
                       <option value=""></option>
                       <option class="mBlackText" value="YES">Yes </option>
                       <option class="mBlackText" value="NO">No </option>
                    </select>
                 </div>
                 <div class="form-group col-md-3 pr-md-0">
                    <input type="text" id="1720INPK" formControlName="1720INPK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                 </div>
              </div>
           </div>
        </div>
        <!--end of section-->
        <!-- Conditional: IF Mobile Home In Park == 'YES'-->
        <ng-container *ngIf="form.get('INPK').value == 'YES'">
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="NUM_RAMT" class="reqtext pr-2 py-2 col-form-label">*Space Rent:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <input type="text" id="NUM_RAMT" formControlName="RAMT" class="form-control col-sm-6" size="" maxlength="9" value="">
                    </div>
                    <div class="form-group col-md-3 pr-md-0">
                       <input type="text" id="1730RAMT" formControlName="1730RAMT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="CB_DESC" class="reqtext pr-2 py-2 col-form-label">*Park Description:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <select id="CB_DESC" formControlName="DESC" class="form-control col-sm-12 px-md-1 px-lg-3">
                          <option value=""></option>
                          <option class="mBlackText" value="SPRN">Park Space Rented </option>
                          <option class="mBlackText" value="PKOW">Park Space Owned </option>
                          <option class="mBlackText" value="ONTR">On County Tax Roll </option>
                       </select>
                    </div>
                    <div class="form-group col-md-3 pr-md-0">
                       <input type="text" id="1740DESC" formControlName="1740DESC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="CB_TYPE" class="reqtext pr-2 py-2 col-form-label">*Mobile Home Type:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <select id="CB_TYPE" formControlName="TYPE" class="form-control col-sm-12">
                          <option value=""></option>
                          <option class="mBlackText" value="SNGL">Single Wide </option>
                          <option class="mBlackText" value="DBL">Double Wide </option>
                          <option class="mBlackText" value="TRIP">Triple Wide </option>
                          <option class="mBlackText" value="SGLW">Expando </option>
                          <option class="mBlackText" value="OTHR">Other </option>
                       </select>
                    </div>
                    <div class="form-group col-md-3 pr-md-0">
                       <input type="text" id="1750TYPE" formControlName="1750TYPE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="CB_PRMF" class="reqtext pr-2 py-2 col-form-label">*Permament Foundation:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <select id="CB_PRMF" formControlName="PRMF" class="form-control col-sm-5 px-md-1 px-lg-3">
                          <option value=""></option>
                          <option class="mBlackText" value="YESS">Yes </option>
                          <option class="mBlackText" value="NONO">No </option>
                       </select>
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="1770HUDN" class="reqtext pr-2 py-2 col-form-label">*HUD or Insig #:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <input type="text" id="1770HUDN" formControlName="1770HUDN" class="form-control col-sm-12" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="1780LICS" class="reqtext pr-2 py-2 col-form-label">*License #:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <input type="text" id="1780LICS" formControlName="1780LICS" class="form-control col-sm-12" size="" maxlength="40" value=""> 
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="1790SNUM" class="reqtext pr-2 py-2 col-form-label">*Serial #:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <input type="text" id="1790SNUM" formControlName="1790SNUM" class="form-control col-sm-12" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="1800PKNM" class="reqtext pr-2 py-2 col-form-label">*Park Name:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <input type="text" id="1800PKNM" formControlName="1800PKNM" class="form-control col-sm-12" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
           <!--Begin Section-->
           <div class="d-flex form-inline row">
              <!--Begin column-->
              <div class="col-lg-12 align-self-start">
                 <!--entries-->
                 <div class="row">
                    <div class="form-group col-md-3">
                       <label for="1810MODL" class="reqtext pr-2 py-2 col-form-label">*Make or Model:</label>
                    </div>
                    <div class="form-group col-md-3">
                       <input type="text" id="1810MODL" formControlName="1810MODL" class="form-control col-sm-12" size="" maxlength="40" value="">
                    </div>
                 </div>
              </div>
           </div>
           <!--end of section-->
        </ng-container>

         <!--Begin Section-->
         <div class="d-flex form-inline row">
         <!--Begin column-->
         <div class="col-lg-12 align-self-start">
            <!--entries-->
            <div class="row">
               <div class="form-group col-md-3">
                  <label for="CB_INPK" class="reqtext pr-2 py-2 col-form-label">*Do you want a yard sign installed?</label>
               </div>
               <div class="form-group col-md-3">
                  <select id="CB_INPK" formControlName="WANTSIGN" class="form-control col-sm-5 px-md-1 px-lg-3">
                  <option value=""></option>
                  <option class="mBlackText" value="YESS">Yes </option>
                  <option class="mBlackText" value="NONO">No </option>
                  </select>
               </div>
               <div class="form-group col-md-3 pr-md-0">
                  <input type="text" id="WANTSIGNCOMMENT" formControlName="WANTSIGNCOMMENT" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
               </div>
            </div>
         </div>
         </div>
     </div>
  </div>
  <div class="modal-footer">
   <button type="button" class="btn btn-primary" (click)="download()" [disabled]="isDownloading">
      <span *ngIf="isDownloading" class="spinner-border" role="status" aria-hidden="true"></span>
      Download as PDF
    </button>
   <button type="button" class="btn btn-default" (click)="close()">Close</button>
  </div>
</ng-container>
<ng-template #loading>
  <div class="modal-header">
    <h5 class="modal-title d-flex flex-column">
      <span>{{ formName }}</span>
    </h5>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body h-100">
    <div class="sk-circle sk-center">
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  </div>
</ng-template>
