import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessService } from 'src/app/services/access.service';

@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.scss']
})
export class EditMemberComponent implements OnInit {
  @Input() data: any;

  public isEmailValid = true;
  public isNameValid = true;
  public isSavingMember = false;
  public memberEmail = '';
  public memberName = '';
  public memberRole: number = 1;

  public roles = [{name: 'User', value: 1}, {name: 'Admin', value: 3}]

  constructor(
    private ngbModal: NgbModal,
    private ngbActiveModal: NgbActiveModal,
    private accessService: AccessService
  ) { }

  ngOnInit(): void {
    this.memberName = this.data.user_metadata.full_name;
    this.memberEmail = this.data.name;
    this.memberRole = this.data.role_number;
  }

  close() {
    this.ngbModal.dismissAll();
    this.isNameValid = true;
    this.isEmailValid = true;
    this.isSavingMember = false;
    this.memberEmail = '';
    this.memberRole = 1;
  }

  editMember(): void {
    // TODO: Clean this and transform form into a reactive form. 
    // TODO: Only update if an field was actually updated/changed/dirty
    // REGEX taken from Angular's validator source 
    const NAME_REGEXP = /^[a-zA-Z\s]*$/; 
    const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if(!EMAIL_REGEXP.test(this.memberEmail)) {
      this.isEmailValid = false;
    } 
    else if(!NAME_REGEXP.test(this.memberName) || this.memberName.length == 0) {
      this.isNameValid = false;
    }
    else {
      this.accessService.editMember(this.data.id, {email: this.memberEmail, name: this.memberName, role: this.memberRole }).subscribe(res => {
        this.isSavingMember = false;
        this.ngbActiveModal.close({
          id: res._id,
          name: this.memberName,
          email: this.memberEmail,
          role: this.roles[this.roles.findIndex(x => x.value === this.memberRole)].name
        });
      })
    }
  }

}
