import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AccessService } from 'src/app/services/access.service';
import { concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-switch-organization',
  templateUrl: './switch-organization.component.html',
  styleUrls: ['./switch-organization.component.scss']
})
export class SwitchOrganizationComponent implements OnInit {

  public accesses$: Observable<any[]>;

  constructor(
    private ngbModal: NgbModal,
    private accessService: AccessService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.accesses$ = this.accessService.getCurrentUsersAccesses();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  navigate(access: any) {
    console.log(access);
    this.router.navigate([access.organization.alias]);
    this.close();
  }
}
