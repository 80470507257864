import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { LayoutService } from 'src/app/services/layout.service';
import { CustomNavigationMode } from '../../services/custom-navigation';
import { CustomFormsService } from '../../services/custom-forms.service';
import { Response } from 'src/app/models/response.model';
import { ModalService } from 'src/app/modals/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {
  @ViewChild('submitSuccess') private submitSuccess: SwalComponent;
  @ViewChild('submitError') private submitError: SwalComponent;
  @ViewChild('wizard') private wizard: WizardComponent;
  public errorList = [];

  public form: FormGroup
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public numErrors: number = 0;
  public key: string = '';
  public responseCache: Response = null;

  private routeSubscription: Subscription;
  private dataSubscription: Subscription;
  private formSubscription: Subscription;

  public navigationMode: CustomNavigationMode;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private layoutService: LayoutService,
    private customFormsService: CustomFormsService,
    private modalService: ModalService,
    private toastrService: ToastrService) {
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(true);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl(''),

      "County_ComboBox": new FormControl('', [Validators.required]),
      "APN_1": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_2": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_3": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "New_Construction": new FormControl(''),
      "Street_Number": new FormControl('', [Validators.required]),
      "Street_Number_Modifier": new FormControl(''),
      "Street_Direction": new FormControl(''),
      "Street_Address": new FormControl('', [Validators.required]),
      "Street_Suffix": new FormControl(''),
      "Post_Direction": new FormControl(''),
      "Unit_Number": new FormControl(''),
      "City_Fill_In": new FormControl('', [Validators.required]),
      "Zip_Code": new FormControl('', [Validators.required]),
      "Zip_Plus_4": new FormControl(''),
      "Cross_Street": new FormControl('', [Validators.required]),
      "Office_ID": new FormControl('', [Validators.required]),
      "Office_MLS_ID": new FormControl('', [Validators.required]),
      "Agent_MLS_ID": new FormControl('', [Validators.required]),
      "Co_Office_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Agent_MLS_ID').value != '')]),
      "Co_Agent_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Office_MLS_ID').value != '')]),
      "Prop_Subtype_0001": new FormControl(''),
      "Prop_Subtype_0002": new FormControl(''),
      "Prop_Subtype_0003": new FormControl(''),
      "Transaction": new FormControl('', [Validators.required]),
      "SSLE": new FormControl('', [Validators.required]),
      "REBK": new FormControl('', [Validators.required]),
      "Lease_Price": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Lease_Type": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Sub_Lease": new FormControl(''),
      "Available_Date": new FormControl(''),
      "Lease_Expiration": new FormControl(''),
      "APNS": new FormControl(''),
      "0100APN2": new FormControl(''),
      "0110APN3": new FormControl(''),
      "0120APN4": new FormControl(''),
      "0130APN5": new FormControl(''),
      "Listing_Price_1": new FormControl('', [Validators.required]),
      "Listing_Price_2": new FormControl(''),
      "Agreement_Type_ComboBox": new FormControl('', [Validators.required]),
      "SCOP": new FormControl(''),
      "0235SCOP": new FormControl(''),
      "DUAL": new FormControl(''),
      "0240DUAL": new FormControl(''),
      "Publish_To_Internet": new FormControl(''),
      "Show_AVM_On_VOW": new FormControl('', [Validators.required]),
      "Show_Address_to_Public": new FormControl('', [Validators.required]),
      "Show_Comments_On_VOW": new FormControl('', [Validators.required]),
      //"Dollar_Percent": new FormControl('', [Validators.required]),
      //"Commission_1": new FormControl(''),
      //"Commission_Amount": new FormControl('', [Validators.required]),
      //"Variable_Rate_Comm": new FormControl('', [Validators.required]),
      "Comm_Comments": new FormControl(''),
      "EOFF": new FormControl('', [Validators.required]),
      "0390EOAD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('EOFF').value == 'YESY')]),
      "Listing_Date": new FormControl('', [Validators.required]),
      "Expiration_Date": new FormControl('', [Validators.required]),
      "Owner_Name": new FormControl(''),
      "Owner_Phone_Area": new FormControl(''),
      "Owner_Phone_Prefix": new FormControl(''),
      "Owner_Phone_Suffix": new FormControl(''),
      "Occupant_Type": new FormControl('', [Validators.required]),
      "Occupant_Name": new FormControl(''),
      "Phone_to_Show_Area": new FormControl(''),
      "Phone_to_Show_Prefix": new FormControl(''),
      "Phone_to_Show_Suffix": new FormControl(''),
      "Bedrooms_ComboBox": new FormControl(''),
      "Bathrooms_ComboBox": new FormControl(''),
      "Struc_Sq_Ft": new FormControl('', [Validators.required]),
      "SqFt_Source": new FormControl('', [Validators.required]),
      "Lot_Sq_Ft": new FormControl(''),
      "Lot_Measurement": new FormControl(''),
      "LotSize_Source": new FormControl(''),
      "Year_Built": new FormControl('', [Validators.required]),
      "Unknown_Year": new FormControl(''),
      "Property_Description": new FormControl('', [Validators.required]),
      "Agent_Only_Remarks": new FormControl(''),
      "Directions": new FormControl('', [Validators.required]),
      "0600NAME": new FormControl(''),
      "CNSS": new FormControl(''),
      "0610CNSS": new FormControl(''),
      "AREA": new FormControl('', [Validators.required]),
      "0620AREA": new FormControl(''),
      "Area_Fill_In": new FormControl('', [Validators.required]),
      "0650ZONE": new FormControl(''),
      "BUSINESSTYPE": new FormGroup({
        "BUSTREST": new FormControl(''),
        "Checkbox_Comment_BUSTREST": new FormControl(''),
        "BUSTBUTY": new FormControl(''),
        "Checkbox_Comment_BUSTBUTY": new FormControl(''),
        "BUSTGRCY": new FormControl(''),
        "Checkbox_Comment_BUSTGRCY": new FormControl(''),
        "BUSTFSTF": new FormControl(''),
        "Checkbox_Comment_BUSTFSTF": new FormControl(''),
        "BUSTMNIM": new FormControl(''),
        "Checkbox_Comment_BUSTMNIM": new FormControl(''),
        "BUSTLIQR": new FormControl(''),
        "Checkbox_Comment_BUSTLIQR": new FormControl(''),
        "BUSTBAR": new FormControl(''),
        "Checkbox_Comment_BUSTBAR": new FormControl(''),
        "BUSTMRCH": new FormControl(''),
        "Checkbox_Comment_BUSTMRCH": new FormControl(''),
        "BUSTCLTH": new FormControl(''),
        "Checkbox_Comment_BUSTCLTH": new FormControl(''),
        "BUSTAPPL": new FormControl(''),
        "Checkbox_Comment_BUSTAPPL": new FormControl(''),
        "BUSTCONT": new FormControl(''),
        "Checkbox_Comment_BUSTCONT": new FormControl(''),
        "BUSTCOMM": new FormControl(''),
        "Checkbox_Comment_BUSTCOMM": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "LOCATION": new FormGroup({
        "LCTNCRNR": new FormControl(''),
        "Checkbox_Comment_LCTNCRNR": new FormControl(''),
        "LCTNBUSD": new FormControl(''),
        "Checkbox_Comment_LCTNBUSD": new FormControl(''),
        "LCTNINDD": new FormControl(''),
        "Checkbox_Comment_LCTNINDD": new FormControl(''),
        "LCTNPARK": new FormControl(''),
        "Checkbox_Comment_LCTNPARK": new FormControl(''),
        "LCTNBLOK": new FormControl(''),
        "Checkbox_Comment_LCTNBLOK": new FormControl(''),
        "LCTNSTRP": new FormControl(''),
        "Checkbox_Comment_LCTNSTRP": new FormControl(''),
        "LCTNSHOP": new FormControl(''),
        "Checkbox_Comment_LCTNSHOP": new FormControl(''),
        "LCTNPROF": new FormControl(''),
        "Checkbox_Comment_LCTNPROF": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "INCLUDEDINSALE": new FormGroup({
        "INCLASST": new FormControl(''),
        "Checkbox_Comment_INCLASST": new FormControl(''),
        "INCLBUSE": new FormControl(''),
        "Checkbox_Comment_INCLBUSE": new FormControl(''),
        "INCLINVN": new FormControl(''),
        "Checkbox_Comment_INCLINVN": new FormControl(''),
        "INCLFIXT": new FormControl(''),
        "Checkbox_Comment_INCLFIXT": new FormControl(''),
        "INCLEQIP": new FormControl(''),
        "Checkbox_Comment_INCLEQIP": new FormControl(''),
        "INCLNONC": new FormControl(''),
        "Checkbox_Comment_INCLNONC": new FormControl(''),
        "INCLTRNG": new FormControl(''),
        "Checkbox_Comment_INCLTRNG": new FormControl(''),
        "INCLLICN": new FormControl(''),
        "Checkbox_Comment_INCLLICN": new FormControl(''),
        "INCLLEAS": new FormControl(''),
        "Checkbox_Comment_INCLLEAS": new FormControl(''),
        "INCLPHON": new FormControl(''),
        "Checkbox_Comment_INCLPHON": new FormControl(''),
        "INCLSECR": new FormControl(''),
        "Checkbox_Comment_INCLSECR": new FormControl(''),
        "INCLFRCH": new FormControl(''),
        "Checkbox_Comment_INCLFRCH": new FormControl(''),
        "INCLGOOD": new FormControl(''),
        "Checkbox_Comment_INCLGOOD": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "XLICBEER": new FormControl(''),
      "Checkbox_Comment_XLICBEER": new FormControl(''),
      "XLICLIQR": new FormControl(''),
      "Checkbox_Comment_XLICLIQR": new FormControl(''),
      "XLICPROF": new FormControl(''),
      "Checkbox_Comment_XLICPROF": new FormControl(''),
      "XLICRSTR": new FormControl(''),
      "Checkbox_Comment_XLICRSTR": new FormControl(''),
      "XLICPERM": new FormControl(''),
      "Checkbox_Comment_XLICPERM": new FormControl(''),
      "XLICBUSL": new FormControl(''),
      "Checkbox_Comment_XLICBUSL": new FormControl(''),
      "XLICONSL": new FormControl(''),
      "Checkbox_Comment_XLICONSL": new FormControl(''),
      "XLICOFSL": new FormControl(''),
      "Checkbox_Comment_XLICOFSL": new FormControl(''),
      "FCLTREST": new FormControl(''),
      "Checkbox_Comment_FCLTREST": new FormControl(''),
      "FCLTDOOR": new FormControl(''),
      "Checkbox_Comment_FCLTDOOR": new FormControl(''),
      "FCLTDOCK": new FormControl(''),
      "Checkbox_Comment_FCLTDOCK": new FormControl(''),
      "FCLTSPRK": new FormControl(''),
      "Checkbox_Comment_FCLTSPRK": new FormControl(''),
      "FCLTSECS": new FormControl(''),
      "Checkbox_Comment_FCLTSECS": new FormControl(''),
      "FCLTDISP": new FormControl(''),
      "Checkbox_Comment_FCLTDISP": new FormControl(''),
      "FCLTSIGN": new FormControl(''),
      "Checkbox_Comment_FCLTSIGN": new FormControl(''),
      "FCLTHAND": new FormControl(''),
      "Checkbox_Comment_FCLTHAND": new FormControl(''),
      "FCLTELEV": new FormControl(''),
      "Checkbox_Comment_FCLTELEV": new FormControl(''),
      "PRKS": new FormControl(''),
      "0710PRKS": new FormControl(''),
      "ADDITIONALPARKING": new FormGroup({
        "PARKUNCV": new FormControl(''),
        "Checkbox_Comment_PARKUNCV": new FormControl(''),
        "PARKCOVR": new FormControl(''),
        "Checkbox_Comment_PARKCOVR": new FormControl(''),
        "PARKGARG": new FormControl(''),
        "Checkbox_Comment_PARKGARG": new FormControl(''),
        "PARKASSN": new FormControl(''),
        "Checkbox_Comment_PARKASSN": new FormControl(''),
        "PARKSTRT": new FormControl(''),
        "Checkbox_Comment_PARKSTRT": new FormControl(''),
        "PARKNONE": new FormControl(''),
        "Checkbox_Comment_PARKNONE": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "FEMP": new FormControl('', [Validators.required]),
      "0730FEMP": new FormControl(''),
      "DOCUMENTSAVAILABLE": new FormGroup({
        "DILOPRLS": new FormControl(''),
        "Checkbox_Comment_DILOPRLS": new FormControl(''),
        "DILOAPPR": new FormControl(''),
        "Checkbox_Comment_DILOAPPR": new FormControl(''),
        "DILOFIXT": new FormControl(''),
        "Checkbox_Comment_DILOFIXT": new FormControl(''),
        "DILOLIQP": new FormControl(''),
        "Checkbox_Comment_DILOLIQP": new FormControl(''),
        "DILOINVT": new FormControl(''),
        "Checkbox_Comment_DILOINVT": new FormControl(''),
        "DILOLEAS": new FormControl(''),
        "Checkbox_Comment_DILOLEAS": new FormControl(''),
        "DILOITAX": new FormControl(''),
        "Checkbox_Comment_DILOITAX": new FormControl(''),
        "DILOCASH": new FormControl(''),
        "Checkbox_Comment_DILOCASH": new FormControl(''),
        "DILONDOC": new FormControl(''),
        "Checkbox_Comment_DILONDOC": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SHOWINGINSTRUCTIONS": new FormGroup({
        "SHOWCALA": new FormControl(''),
        "Checkbox_Comment_SHOWCALA": new FormControl(''),
        "SHOWDNDB": new FormControl(''),
        "Checkbox_Comment_SHOWDNDB": new FormControl(''),
        "SHOWDRIV": new FormControl(''),
        "Checkbox_Comment_SHOWDRIV": new FormControl(''),
        "SHOWCALO": new FormControl(''),
        "Checkbox_Comment_SHOWCALO": new FormControl(''),
        "SHOWCALT": new FormControl(''),
        "Checkbox_Comment_SHOWCALT": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "Showing_Instructions": new FormControl(''),
      "GSAL": new FormControl(''),
      "0820GSAL": new FormControl(''),
      "GEXP": new FormControl(''),
      "0830GEXP": new FormControl(''),
      "NINC": new FormControl(''),
      "0840NINC": new FormControl(''),
      "REIN": new FormControl('', [Validators.required]),
      "0850REIN": new FormControl(''),
      "REAL": new FormControl('', [Validators.required]),
      "0860REAL": new FormControl(''),
      "FRCH": new FormControl('', [Validators.required]),
      "0870FRCH": new FormControl(''),
      "CHAN": new FormControl('', [Validators.required]),
      "0880CHAN": new FormControl(''),
      "0890EQTY": new FormControl(''),
      "LEASSR8T": new FormControl(''),
      "Checkbox_Comment_LEASSR8T": new FormControl(''),
      "LEASMTOM": new FormControl(''),
      "Checkbox_Comment_LEASMTOM": new FormControl(''),
      "LEASPRCT": new FormControl(''),
      "Checkbox_Comment_LEASPRCT": new FormControl(''),
      "LEASESCL": new FormControl(''),
      "Checkbox_Comment_LEASESCL": new FormControl(''),
      "LEASRENW": new FormControl(''),
      "Checkbox_Comment_LEASRENW": new FormControl(''),
      "LEASPRCH": new FormControl(''),
      "Checkbox_Comment_LEASPRCH": new FormControl(''),
      "LEASNET": new FormControl(''),
      "Checkbox_Comment_LEASNET": new FormControl(''),
      "LEASTNET": new FormControl(''),
      "Checkbox_Comment_LEASTNET": new FormControl(''),
      "LEASASSN": new FormControl(''),
      "Checkbox_Comment_LEASASSN": new FormControl(''),
      "LEASNEGO": new FormControl(''),
      "Checkbox_Comment_LEASNEGO": new FormControl(''),
      "LRMN": new FormControl(''),
      "0910LRMN": new FormControl(''),
      "FORM": new FormControl('', [Validators.required]),
      "0920FORM": new FormControl(''),
      "SOURCEOFDATA": new FormGroup({
        "SOFDTAXR": new FormControl(''),
        "Checkbox_Comment_SOFDTAXR": new FormControl(''),
        "SOFDACCT": new FormControl(''),
        "Checkbox_Comment_SOFDACCT": new FormControl(''),
        "SOFDOWNR": new FormControl(''),
        "Checkbox_Comment_SOFDOWNR": new FormControl(''),
        "SOFDESTM": new FormControl(''),
        "Checkbox_Comment_SOFDESTM": new FormControl(''),
        "SOFDPROJ": new FormControl(''),
        "Checkbox_Comment_SOFDPROJ": new FormControl(''),
        "SOFDINCM": new FormControl(''),
        "Checkbox_Comment_SOFDINCM": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "TEXPGAS": new FormControl(''),
      "Checkbox_Comment_TEXPGAS": new FormControl(''),
      "TEXPELEC": new FormControl(''),
      "Checkbox_Comment_TEXPELEC": new FormControl(''),
      "TEXPTRSH": new FormControl(''),
      "Checkbox_Comment_TEXPTRSH": new FormControl(''),
      "TEXPWATR": new FormControl(''),
      "Checkbox_Comment_TEXPWATR": new FormControl(''),
      "TEXPSEWR": new FormControl(''),
      "Checkbox_Comment_TEXPSEWR": new FormControl(''),
      "TEXPTAXS": new FormControl(''),
      "Checkbox_Comment_TEXPTAXS": new FormControl(''),
      "TEXPJNTR": new FormControl(''),
      "Checkbox_Comment_TEXPJNTR": new FormControl(''),
      "TEXPIMNT": new FormControl(''),
      "Checkbox_Comment_TEXPIMNT": new FormControl(''),
      "TEXPEMNT": new FormControl(''),
      "Checkbox_Comment_TEXPEMNT": new FormControl(''),
      "TEXPSMNT": new FormControl(''),
      "Checkbox_Comment_TEXPSMNT": new FormControl(''),
      "TEXPCMNT": new FormControl(''),
      "Checkbox_Comment_TEXPCMNT": new FormControl(''),
      "TEXPIVAC": new FormControl(''),
      "Checkbox_Comment_TEXPIVAC": new FormControl(''),
      "TEXPROOF": new FormControl(''),
      "Checkbox_Comment_TEXPROOF": new FormControl(''),
      "TEXPFIRE": new FormControl(''),
      "Checkbox_Comment_TEXPFIRE": new FormControl(''),
      "TEXPLIAB": new FormControl(''),
      "Checkbox_Comment_TEXPLIAB": new FormControl(''),
      "TEXPGLAS": new FormControl(''),
      "Checkbox_Comment_TEXPGLAS": new FormControl(''),
      "TEXPELEV": new FormControl(''),
      "Checkbox_Comment_TEXPELEV": new FormControl(''),
      "TEXPTXEC": new FormControl(''),
      "Checkbox_Comment_TEXPTXEC": new FormControl(''),
      "TEXPMRCH": new FormControl(''),
      "Checkbox_Comment_TEXPMRCH": new FormControl(''),
      "TERMCONV": new FormControl(''),
      "Checkbox_Comment_TERMCONV": new FormControl(''),
      "TERMSBA": new FormControl(''),
      "Checkbox_Comment_TERMSBA": new FormControl(''),
      "TERMOWNR": new FormControl(''),
      "Checkbox_Comment_TERMOWNR": new FormControl(''),
      "TERMCASH": new FormControl(''),
      "Checkbox_Comment_TERMCASH": new FormControl(''),
      "TERMOTHR": new FormControl(''),
      "Checkbox_Comment_TERMOTHR": new FormControl('')
    }, { updateOn: 'change' });


    // Customized conditionals and form setters
    //this.form.get('POOLTYPE').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('Pool').value == 'Yes')]);
    //this.form.get('HOAAMENITIES').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('HOAA').value == 'Yes')]);

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      const temp = value;
      Object.keys(temp).forEach(key => {
        if(!temp[key] || temp[key] == '' || temp[key] == null)
          delete temp[key];
      });
      localStorage.setItem('cachedFormData', JSON.stringify(temp));

      this.form.get('Co_Office_MLS_ID').updateValueAndValidity({onlySelf: true});
      this.form.get('Co_Agent_MLS_ID').updateValueAndValidity({onlySelf: true});
    })

    this.form.get('Office_ID').valueChanges.subscribe(val => {
      this.form.get('Office_MLS_ID').setValue(val);
    })

    this.formSubscription.add(this.form.get('Street_Number').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Direction').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Address').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Suffix').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.navigationMode = new CustomNavigationMode(this.form);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.key = params.key;
      if(params.key) {
        this.dataSubscription = this.responseService.getResponse(params.key).subscribe(payload => {
          this.form.patchValue(payload.data);
          this.responseCache = payload;
        })
      } else {
        if(localStorage.getItem('cachedFormData')) {
          let confirmation = confirm("You have saved form data, would you like to load it?")

          if(confirmation) {
            let data = localStorage.getItem('cachedFormData');
            this.form.patchValue(JSON.parse(data));
          } else {
            localStorage.removeItem('cachedFormData');
          }
        }
      }
    })
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if(this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if(this.formSubscription)
      this.formSubscription.unsubscribe();

    if(this.responseCache)
      this.responseCache = null;
  }

  get f() { return this.form.controls; }

  submit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if(this.form.valid) {
      this.isLoading = true;
      let temp = this.form.value;
      //if(temp["Dollar_Percent"] == '$') {
      //  temp["Commission_1"] = '$' + temp["Commission_Amount"];
      //} else if(temp["Dollar_Percent"] == '%') {
      //  temp["Commission_1"] = temp["Commission_Amount"] + '%';
      //}
      temp["Office_MLS_ID"] = temp["Office_ID"]; 


      const data = this.customFormsService.sanitizeFormPayload(temp);

      if(!this.responseCache) {
        const response = {
          form: '5f060f8cdcb9073aee212bfa',
          organization: '',
          name: 'Save_' + Math.random().toString(36).substr(2, 5),
          data: temp,
          submitted: true
        }
        this.responseService.postResponse(response).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        })
      } else {
        this.responseService.patchResponse(this.responseCache._id, {data: data, submitted: true }).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        });
      }
    } else {
      this.numErrors = 0;
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.numErrors++;
              console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });
      this.toastrService.error('You have not completed the form!', 'Submission Failed');
    }
  }

  save() {
    const self = this;
    this.isSaving = true;

    // Remove empty/null inputs to store less
    const data = this.customFormsService.sanitizeFormPayload(this.form.value);
    if(!this.responseCache) {
      this.modalService.openModal('SaveResponse', { backdrop: 'static', keyboard: false }, {formData: data, formId: '5f060f8cdcb9073aee212bfa'}, function(data) {
        console.log(data);
        if(data.err)
          self.toastrService.error('Save was unsuccessful, please try again.', 'Error 500');
        else {
          self.toastrService.success('Save was successful', 'Success');
          self.router.navigate(['.'], { relativeTo: self.route, queryParams: {key: data._id}, queryParamsHandling: 'merge' });
          self.form.markAsPristine();
        }
      });
    } else {
      if(this.form.dirty) {
        this.responseService.patchResponse(this.responseCache._id, {data: data}).subscribe(res => {
          this.isSaving = false;
          this.responseCache = res;
          this.form.markAsPristine();
          this.toastrService.success('Save was successful', 'Success');
        });
      } else {
        this.toastrService.info('No changes detected', 'Unsuccessful Save');
      }
    }
  }

  validate() {
    this.submitted = true;
    this.numErrors = 0;
    this.errorList = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.numErrors++;
          //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          let errorElem = document.querySelector("[formControlName='" + key + "']");
          if(!errorElem) {
            let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
            errorElem = document.querySelector("[formControlName='" + childKey + "']");
          }

          let stepId = errorElem.closest('aw-wizard-step').getAttribute('stepid')
          this.errorList.push({
            key: key,
            stepId: stepId,
          })
        });
      }
    });
  }

  goToError(error) {
    const key = error.key
    const index = this.wizard.getIndexOfStepWithId(error.stepId);
    this.wizard.goToStep(index);

    let errorElem = document.querySelector("[formControlName='" + key + "']");
    if(!errorElem) {
      let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
      errorElem = document.querySelector("[formControlName='" + childKey + "']");
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + childKey + "']").getBoundingClientRect().top - 250
      }, 100)
    } else {
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + error.key + "']").getBoundingClientRect().top - 200
      }, 100)
    }
  }

  enableControl(condition: boolean, controlNames: string[]) {
    if(condition) {
      controlNames.forEach(name => {
        this.form.controls[name].enable();
      })
    } else {
      controlNames.forEach(name => {
        this.form.controls[name].disable();
      })
    }
  }

  complete() {
    this.layoutService.setSideNavHidden(false);
    this.router.navigate(['../history'], { relativeTo: this.route });
  }

  navigateToSign() {
    this.router.navigate(['../sign-request'], { relativeTo: this.route });
  }
}
