import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import { AccessService } from 'src/app/services/access.service';

@Component({
  selector: 'app-delete-member',
  templateUrl: './delete-member.component.html',
  styleUrls: ['./delete-member.component.scss']
})
export class DeleteMemberComponent implements OnInit {
  @Input() data: any;

  public memberEmail = '';

  constructor(
    private ngbModal: NgbModal,
    private ngbActiveModal: NgbActiveModal,
    private accessService: AccessService
  ) { }

  close() {
    this.ngbModal.dismissAll();
    this.memberEmail = '';
  }

  ngOnInit(): void {
    this.memberEmail = this.data.name;
  }

  deleteMember(): void {
    this.accessService.deleteMember(this.data.id).subscribe(res => {
      // Temporary fix for no data being sent to front-end
      this.ngbActiveModal.close({data: res});
    });
  }
}
