import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Organization } from '../models/organization.model';
import { AuthService } from '../services/auth.service';
import { Observable, concat } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';

@Injectable({ providedIn: 'root' })
export class OrganizationResolver implements Resolve<Organization> {
  constructor(
    private authService: AuthService,
    private organizationService: OrganizationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    this.authService.setUser();
    return concat(
      this.organizationService.getOrganization(route.paramMap.get('id'), 'name').pipe(
        concatMap(res => this.authService.setOrganization$(res._id))
      ),
      this.authService.setRole$()
    )
  }
}