<div [formGroup]="form" class="h-100">
  <aw-wizard class="h-100" #wizard
    [awNavigationMode]="navigationMode"
    navBarLocation="left"
    navBarLayout="large-empty-symbols">
    <div class="form-header p-2">
      <h4 *ngIf="!responseCache; else saveTitle" class="font-weight-bold">
        FRESNO MLS
        <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Farm Listing Input</small></div>
      </h4>
      <ng-template #saveTitle>
        <h4 class="font-weight-bold">
          {{ responseCache.name }}
          <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Updated {{ responseCache.timeUpdated | amTimeAgo }}</small></div>
        </h4>
      </ng-template>
      <img src="assets/images/RClogo.png" class="img-fluid" width="120" alt="Realty Concepts"/>
    </div>
    <div class="form-container card card-body">
      <aw-wizard-step
        stepId="Listing Information"
        stepTitle="Listing Information" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">1</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Listing Information Form">
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between pt-1">
              <label for="selCountyComboBox" class= "reqtext pr-2 py-2">*County:</label>
              <select id="selCountyComboBox" formControlName="County_ComboBox" class="form-control col-md-8" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.County_ComboBox.errors }">
                  <option value="Fresno" selected>Fresno</option>
                  <option value="Kern">Kern</option>
                  <option value="Kings">Kings</option>
                  <option value="Madera">Madera</option>
                  <option value="Mariposa">Mariposa</option>
                  <option value="Merced">Merced</option>
                  <option value="Mono">Mono</option>
                  <option value="Orange">Orange</option>
                  <option value="Placer">Placer</option>
                  <option value="Riverside">Riverside</option>
                  <option value="San Bernardino">San Bernardino</option>
                  <option value="San Joaquin">San Joaquin</option>
                  <option value="Santa Clara">Santa Clara</option>
                  <option value="Stanislaus">Stanislaus</option>
                  <option value="Tehama">Tehama</option>
                  <option value="Tulare">Tulare</option>
                  <option value="Tuolumne">Tuolumne</option>
                  <option value="Ventura">Ventura</option>
                  <option value="Out of Area">Out of Area</option>
                  <option value="Unknown">Unknown</option>
                  <option value="Alameda">Alameda</option>
                  <option value="Alpine">Alpine</option>
                  <option value="Amador">Amador</option>
                  <option value="Butte">Butte</option>
                  <option value="Calaveras">Calaveras</option>
                  <option value="Colusa">Colusa</option>
                  <option value="Contra Costa">Contra Costa</option>
                  <option value="Del Norte">Del Norte</option>
                  <option value="El Dorado">El Dorado</option>
                  <option value="Glenn">Glenn</option>
                  <option value="Humboldt">Humboldt</option>
                  <option value="Imperial">Imperial</option>
                  <option value="Inyo">Inyo</option>
                  <option value="Lake">Lake</option>
                  <option value="Lassen">Lassen</option>
                  <option value="Los Angeles">Los Angeles</option>
                  <option value="Marin">Marin</option>
                  <option value="Mendocino">Mendocino</option>
                  <option value="Modoc">Modoc</option>
                  <option value="Monterey">Monterey</option>
                  <option value="Napa">Napa</option>
                  <option value="Nevada">Nevada</option>
                  <option value="Plumas">Plumas</option>
                  <option value="Sacramento">Sacramento</option>
                  <option value="San Benito">San Benito</option>
                  <option value="San Diego">San Diego</option>
                  <option value="San Francisco">San Francisco</option>
                  <option value="San Luis Obispo">San Luis Obispo</option>
                  <option value="San Mateo">San Mateo</option>
                  <option value="Santa Barbara">Santa Barbara</option>
                  <option value="Santa Cruz">Santa Cruz</option>
                  <option value="Shasta">Shasta</option>
                  <option value="Sierra">Sierra</option>
                  <option value="Siskiyou">Siskiyou</option>
                  <option value="Solano">Solano</option>
                  <option value="Sonoma">Sonoma</option>
                  <option value="Sutter">Sutter</option>
                  <option value="Trinity">Trinity</option>
                  <option value="Yolo">Yolo</option>
                  <option value="Yuba">Yuba</option>
              </select>			
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="APN_1" class="reqtext pr-2 py-2">*APN:</label>
              <div class="d-flex">
                <input type="text" id="APN_1" formControlName="APN_1" class="form-control" size="3" maxlength="3" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_1.errors }">
                <label for="APN_2">&nbsp;-&nbsp;</label>
                <input type="text" id="APN_2" formControlName="APN_2" class="form-control" size="3" maxlength="3" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_2.errors }">
                <label for="APN_3">&nbsp;-&nbsp;</label>
                <input type="text" id="APN_3" formControlName="APN_3" class="form-control" size="3" maxlength="3" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_3.errors }">
              </div>
            </div>
          </div>
    
    
          <div class="row fixcheck form-inline">
            <div class="form-check col-12 justify-content-end">
              <label class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="New_Construction" formControlName="New_Construction" class="form-check-input">New / Under Contruction / NA</label>
            </div>
          </div>
    
    
          <!---->
          <h6 class="subtitle p-1">Address</h6>
          <input type="text" formControlName="FULL_ADDRESS" class="d-none form-control">
          <!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Number" class= "nonreqtext pr-2 py-2">Street # - Modifier:</label>
              <div class="d-flex">
                <input type="text" id="Street_Number" formControlName="Street_Number" class="form-control" size="6" maxlength="6">
                <label for="Street_Number_Modifier">-</label>
                <input type="text" id="Street_Number_Modifier" formControlName="Street_Number_Modifier" class="form-control" size="6" maxlength="8">
              </div>
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Direction" class="nonreqtext pr-2 py-2">Direction:</label>
              <select id="Street_Direction" formControlName="Street_Direction" class="form-control col-md-6 col-lg-8">
                <option value="" selected="">  </option>
                <option value="E"> East </option>
                <option value="N"> North </option>
                <option value="NE"> Northeast </option>
                <option value="NW"> Northwest </option>
                <option value="S"> South </option>
                <option value="SE"> Southeast </option>
                <option value="SW"> Southwest </option>
                <option value="W"> West </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Address" class= "nonreqtext pr-2 py-2">Street Name:</label>
              <input type="text" id="Street_Address" formControlName="Street_Address" class="form-control col-sm-8" size="" maxlength="30">
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Suffix" class="nonreqtext pr-2 py-2">Suffix:</label>
              <select id="Street_Suffix"  formControlName="Street_Suffix" class="form-control col-md-6 col-lg-8">
                  <option value="" selected=""> </option>
                  <option value="Ave"> Avenue </option>
                  <option value="Blvd"> Boulevard </option>
                  <option value="Cir"> Circle </option>
                  <option value="Ct"> Court </option>
                  <option value="Dr"> Drive </option>
                  <option value="Jct"> Junction </option>
                  <option value="Ln"> Lane </option>
                  <option value="Pass"> Pass </option>
                  <option value="Pkwy"> Parkway </option>
                  <option value="Pl"> Place </option>
                  <option value="Rd"> Road </option>
                  <option value="Sqr"> Square </option>
                  <option value="St"> Street </option>
                  <option value="Way"> Way </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 offset-md-6 justify-content-between">
              <label for="Post_Direction" class="nonreqtext pr-2 py-2">Post Direction:</label>
              <select id="Post_Direction" formControlName="Post_Direction" class="form-control col-md-6 col-lg-8">
                  <option value="" selected="">  </option>
                  <option value="E"> East </option>
                  <option value="N"> North </option>
                  <option value="NE"> Northeast </option>
                  <option value="NW"> Northwest </option>
                  <option value="S"> South </option>
                  <option value="SE"> Southeast </option>
                  <option value="SW"> Southwest </option>
                  <option value="W"> West </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Unit_Number" class="nonreqtext pr-2 py-2">Unit #:</label>
              <input type="text" id="Unit_Number" formControlName="Unit_Number" class="form-control col-md-8" size="6" maxlength="5">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="City_Fill_In" class= "reqtext pr-2 py-2">*City:</label>
              <input type="text" id="City_Fill_In" formControlName="City_Fill_In" size="16" maxlength="20" class="form-control col-sm-8" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.City_Fill_In.errors }">
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Zip_Code" class="reqtext pr-2 py-2">*Zip Code:</label>
              <div class="d-flex">
                <input type="text" id="Zip_Code" formControlName="Zip_Code" class="form-control" size="5" maxlength="5" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Zip_Code.errors }">
                <label for="Zip_Plus_4">
                -</label>
                  <input type="text" id="Zip_Plus_4" formControlName="Zip_Plus_4" class="form-control" size="4" maxlength="4">
              </div>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Cross_Street" class="reqtext pr-2 py-2">
              *Cross Street:</label>
                <input type="text" id="Cross_Street" formControlName="Cross_Street" class="form-control col-sm-8" size="16" maxlength="30" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Cross_Street.errors }">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="txtOfficeAuto" class= "reqtext pr-2 py-2">
              *Office ID:</label>
              <select id="Office_ID" formControlName="Office_ID" class="form-control col-sm-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Office_ID.errors }" required>
                  <option value=""> </option>
                  <option value="630">630-Fresno</option>
                  <option value="63004">63004-Clovis</option>
                  <option value="63005">63005-Commercial</option>
                  <option value="63006">63006-Oakhurst2</option>
              </select>
              <input type="text" id="txtOfficeAuto" formControlName="Office_MLS_ID" class="d-none form-control col-sm-8" size="40" maxlength="50" required>
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Agent_MLS_ID" class= "reqtext pr-2 py-2">
              *Agent ID:</label>
                <input type="text" id="Agent_MLS_ID" formControlName="Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Agent_MLS_ID.errors }">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Office_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Office ID:</label>
                <input type="text" id="Co_Office_MLS_ID" formControlName="Co_Office_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Co_Office_MLS_ID.errors }">
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Agent_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Agent ID:</label>
                <input type="text" id="Co_Agent_MLS_ID" formControlName="Co_Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Co_Agent_MLS_ID.errors }">
            </div>
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="row pb-2">
            <h6 class="col reqtext">*Property Subtypes (select all that apply):</h6>
          </div>
    
    
          <div class="row form-inline">
    
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0001" class= "nonreqtext pr-2 py-2">Primary:</label>
              <select id="Prop_Subtype_0001" formControlName="Prop_Subtype_0001" class="form-control">
                <option value=""></option>
                <option value="IRRE">Irrigated Field Crop</option>
                <option value="PAST">Pasture</option>
                <option value="RANG">Range</option>
                            <option value="VINE">Vineyard</option>
                <option value="CTRS">Citrus</option>
                <option value="NUTC">Nut Crops</option>
                <option value="PLTR">Poultry</option>
                <option value="DARY">Dairy</option>
                <option value="OLIV">Olives</option>
                <option value="TRFR">Tree Fruit</option>
                <option value="FIGS">Figs</option>
                <option value="OPEN">Open</option>
                <option value="OTHR">Other</option>
              </select>
            </div>
    
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0002" class= "nonreqtext pr-2 py-2">Secondary:</label>
              <select id="Prop_Subtype_0002" formControlName="Prop_Subtype_0002" class="form-control">
                <option value="IRRE">Irrigated Field Crop</option>
                <option value="PAST">Pasture</option>
                <option value="RANG">Range</option>
                            <option value="VINE">Vineyard</option>
                <option value="CTRS">Citrus</option>
                <option value="NUTC">Nut Crops</option>
                <option value="PLTR">Poultry</option>
                <option value="DARY">Dairy</option>
                <option value="OLIV">Olives</option>
                <option value="TRFR">Tree Fruit</option>
                <option value="FIGS">Figs</option>
                <option value="OPEN">Open</option>
                <option value="OTHR">Other</option>
              </select>
            </div>
    
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0003" class= "nonreqtext pr-2 py-2">Other:</label>
              <select id="Prop_Subtype_0003" formControlName="Prop_Subtype_0003" class="form-control">
                <option value="IRRE">Irrigated Field Crop</option>
                <option value="PAST">Pasture</option>
                <option value="RANG">Range</option>
                            <option value="VINE">Vineyard</option>
                <option value="CTRS">Citrus</option>
                <option value="NUTC">Nut Crops</option>
                <option value="PLTR">Poultry</option>
                <option value="DARY">Dairy</option>
                <option value="OLIV">Olives</option>
                <option value="TRFR">Tree Fruit</option>
                <option value="FIGS">Figs</option>
                <option value="OPEN">Open</option>
                <option value="OTHR">Other</option>
              </select>
            </div>
    
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-md-4 justify-content-between">
              <label for="Transaction" class= "reqtext pr-2 py-2">*Transaction Type:</label>
              <select id="Transaction" formControlName="Transaction" (change)="enableControl(f.Transaction.value == 'L', ['Lease_Price'])"  class="form-control" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Transaction.errors }">
                  <option value="S" selected="">Sale</option>
                  <option value="L">Lease</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="CB_SSLE" class= "nonreqtext pr-2 py-2">Short Sale:</label>
              <select id="CB_SSLE" formControlName="SSLE" class="form-control">
                  <option value=""> </option>
                  <option value="YESS">Yes</option>
                  <option value="NONO">No</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="CB_REBK" class= "reqtext pr-2 py-2">*REO/Bank Owned:</label>
              <select id="CB_REBK" formControlName="REBK" class="form-control" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.REBK.errors }">
                  <option value=""> </option>
                  <option value="YESS">Yes</option>
                  <option value="NONO">No</option>
              </select>
            </div>
          </div>
    
    
                <br>
    
          <ng-container *ngIf="form.get('Transaction').value == 'L'">
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Lease_Price" class= "reqtext pr-2 pb-2 ">*Lease (acre):</label>
              <input type="text" id="Lease_Price" formControlName="Lease_Price" class="form-control col-md-8" size="10" maxlength="10" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Lease_Price.errors }">
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Lease_Type" class="nonreqtext pr-2 py-2">Lease Type:</label>
              <select id="Lease_Type"  formControlName="Lease_Type" class="form-control col-md-8">
                  <option value="" selected="">  </option>
                  <option value="G"> Gross </option>
                  <option value="N"> Net </option>
                  <option value="O"> Other </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Sub_Lease" class="nonreqtext pr-2 py-2">Sub-Lease:</label>
              <select id="Sub_Lease" formControlName="Sub_Lease" class="form-control">
                  <option value="" selected=""> </option>
                  <option value="Y"> Yes </option>
                              <option value="N"> No </option>
              </select>
              <label for="Available_Date" class="nonreqtext pr-2 py-2">Available Date:</label>
              <input type="text" id="Available_Date" formControlName="Available_Date" class="form-control" size="10" maxlength="8" value="">
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Lease_Expiration" class="nonreqtext pr-2 py-2">Sub-Lease Expiration:</label>
              <input type="text" id="Lease_Expiration" formControlName="Lease_Expiration" class="form-control" size="9" maxlength="8" value="">
            </div>
          </div>
    
          </ng-container>
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="d-flex row form-inline">
            <div class="col-sm-6">
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="CB_APNS" class= "nonreqtext pr-2 py-2">Additional APNs:</label>
                </div>
                <div class="form-group col-sm-4">
                  <select id="CB_APNS" formControlName="APNS" class="form-control">
                  <option value=" "></option>
                  <option class="mBlackText" value="YESS">Yes</option>
                  <option class="mBlackText" value="NONO">No</option>
                  </select>			
                </div>
              </div>
            </div>
          </div>
    
    
          <div class="d-flex form-inline row">
    
            <!-- Left Column-->
            <div class="col-md-6 align-self-start">
    
    
              <!-- One row for an input -->
              <div class="row">
                  
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0100APN2" class="nonreqtext pr-2 py-2 col-form-label">APN #2:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0100APN2" formControlName="0100APN2" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
              <!-- One row for an input -->
              <div class="row">
                  
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0110APN3" class="nonreqtext pr-2 py-2 col-form-label">APN #3:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0110APN3" formControlName="0110APN3" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
            <!--End of left column-->
            </div>
    
    
            <div class="col-md-6 align-self-start">
            <!--Right Column-->
    
    
              <!-- One row for an input -->
              <div class="row">
                  
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0120APN4" class="nonreqtext pr-2 py-2 col-form-label">APN #4:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0120APN4" formControlName="0120APN4" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="0130APN5" class="nonreqtext pr-2 py-2 col-form-label">APN #5:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0130APN5" formControlName="0130APN5" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
    
    
            <!--End of right column-->
            </div>
    
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-md-5 justify-content-between">
              <label for="Listing_Price_1" class= "reqtext pr-2 py-2">Listing Price:</label>
              <div class="d-flex">
                <input type="text" id="Listing_Price_1" formControlName="Listing_Price_1" class="form-control" size="6" maxlength="6" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Listing_Price_1.errors }">
                <label for="Listing_Price_2">&nbsp;,&nbsp;</label>
                <input type="text" id="Listing_Price_2" formControlName="Listing_Price_2" class="form-control" size="3" maxlength="3" value="000">
              </div>
            </div>
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Agreement_Type_ComboBox" class="reqtext pr-2 py-2">*Agreement Type:</label>
              <select id="Agreement_Type_ComboBox" formControlName="Agreement_Type_ComboBox" class="form-control col-sm-5" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Agreement_Type_ComboBox.errors }">
                  <option class="mBlackText" value="" selected=""> </option>
                  <option class="mBlackText" value="ERTS"> Excl. Right to Sell </option>
                  <option class="mBlackText" value="EXAG"> Exclusive Agency </option>
                  <option class="mBlackText" value="OPEN"> Open </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_SCOP" class="nonreqtext pr-2 py-2">Scope of Service:</label>
              <select id="CB_SCOP" formControlName="SCOP" class="form-control col-sm-5">
                  <option value=" "> </option>
                  <option class="mBlackText" value="ENTY">Entry Only </option>
                  <option class="mBlackText" value="LMTD">Limited Service </option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0255SCOP" formControlName="0255SCOP" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_DUAL" class="nonreqtext pr-2 py-2">Dual Agency:</label>
              <select id="CB_DUAL" formControlName="DUAL" class="form-control col-sm-3">
                <option value=""> </option>
                <option class="mBlackText" value="YES">Yes</option>
                <option class="mBlackText" value="NO">No</option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0240DUAL" formControlName="0240DUAL" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Publish_To_Internet" class="nonreqtext pr-2 py-2">Public Viewable:</label>
              <select id="Publish_To_Internet" formControlName="Publish_To_Internet" class="form-control px-sm-0 px-md-3 col-sm-3">
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_AVM_On_VOW" class="reqtext pr-2 py-2">*Show AVM on VOW:</label>
              <select id="Show_AVM_On_VOW" formControlName="Show_AVM_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_AVM_On_VOW.errors }">
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Address_to_Public" class="reqtext pr-2 py-2">*Show Addr to Public:</label>
              <select id="Show_Address_to_Public" formControlName="Show_Address_to_Public" class="form-control px-sm-0 px-md-3 col-sm-3" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_Address_to_Public.errors }">
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
              </select>
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Comments_On_VOW" class="reqtext pr-2 py-2">*Show Cmnts on VOW:</label>
              <select id="Show_Comments_On_VOW" formControlName="Show_Comments_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_Comments_On_VOW.errors }">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
              </select>
            </div>
          </div>
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
 
    
    
          <!--Begin Section-->
          <div class="d-flex form-inline row">
    
            <!--Begin column-->
            <div class="col-lg-5">
    
              <!--entries-->
              <div class="row">
    
                <div class="form-group col-sm-6">
                  <label for="CB_EOFF" class="reqtext pr-2 py-2 col-form-label">*Email Offers:</label>
                </div>
    
                <div class="form-group col-sm-4 offset-sm-2 pr-xs-0">
                  <select id="CB_EOFF" formControlName="EOFF" (change)="enableControl(f.EOFF.value == 'YESY', ['0380EOAD'])"  class="form-control col-sm-8" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.EOFF.errors }">
                    <option value=""></option>
                    <option class="mBlackText" value="YESY"> Yes </option>
                    <option class="mBlackText" value="NONO"> No </option>
                  </select>
                </div>
    
              </div>
    
            </div>
    
    
            <!--Begin column-->
            <div class="col-lg-7">
              <!--entries-->
              <div class="row">
    
                <div class="form-group col-sm-3 justify-content-end">
                  <label for="0380EOAD" class="reqtext pr-2 py-2 col-form-label">*Email:</label>
                </div>
    
                <div class="form-group col-sm-9 justify-content-between">
                  <input type="email" id="0380EOAD" formControlName="0380EOAD" class="form-control col-sm-12" rows="1" cols="40" maxlength="60" [ngClass]="{ 'is-invalid alert-danger': submitted && f['0380EOAD'].errors }" >
                </div>
    
              </div>
    
            </div>
    
          </div> <!--end of section-->
    
    
          <!----><div><hr class="HRstyle"></div><!---->
    
    
          <!---->
          <h6 class="subtitle pr-1 py-2">Listing Period:</h6>
          <!---->
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Listing_Date" class="reqtext pr-2 py-2">*Listing Date:</label>
              <input type="text" id="Listing_Date" formControlName="Listing_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Listing_Date.errors }">
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Expiration_Date" class="reqtext pr-2 py-2">*Expiration Date:</label>
              <input type="text" id="Expiration_Date" formControlName="Expiration_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Expiration_Date.errors }">						
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Name" class= "nonreqtext pr-2 py-2">Owner Name:</label>
              <input type="text" id="Owner_Name" formControlName="Owner_Name" class="form-control col-sm-8" size="30" maxlength="50" value="">
            </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Phone_Area" class="nonreqtext pr-2 py-2">Owner Phone #:</label>
              <div class="d-flex">
                <input type="text" id="Owner_Phone_Area" formControlName="Owner_Phone_Area" class="form-control" size="3" maxlength="3" value="">
                <label for="Owner_Phone_Prefix">&nbsp;-&nbsp;</label>
                <input type="text" id="Owner_Phone_Prefix" formControlName="Owner_Phone_Prefix" class="form-control" size="3" maxlength="3" value="">
                <label for="Owner_Phone_Suffix">&nbsp;-&nbsp;</label>
                <input type="text" id="Owner_Phone_Suffix" formControlName="Owner_Phone_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Type" class="reqtext pr-2 py-2">*Occupant Type:</label>
              <select id="Occupant_Type" formControlName="Occupant_Type" class="form-control col-sm-3" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Occupant_Type.errors }">
                  <option value="" selected="">  </option>
                  <option value="C"> Caretaker </option>
                  <option value="O"> Owner </option>
                  <option value="T"> Tenant </option>
                  <option value="V"> Vacant </option>
              </select>
            </div>
          </div>
    
    
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Name" class= "nonreqtext pr-2 py-2">Occupant Name:</label>
              <input type="text" id="Occupant_Name" formControlName="Occupant_Name" class="form-control col-sm-8" size="30" maxlength="30" value="">
              </div>
    
            <div class="form-group col-md-6 justify-content-between">
              <label for="Phone_to_Show_Area" class="nonreqtext pr-2 py-2">Phone to Show:</label>
              <div class="d-flex">
                <input type="text" id="Phone_to_Show_Area" formControlName="Phone_to_Show_Area" class="form-control" size="3" maxlength="3" value="">
                <label for="Phone_to_Show_Prefix">&nbsp;-&nbsp;</label>
                <input type="text" id="Phone_to_Show_Prefix" formControlName="Phone_to_Show_Prefix" class="form-control" size="3" maxlength="3" value="">
                <label for="Phone_to_Show_Suffix">&nbsp;-&nbsp;</label>
                <input type="text" id="Phone_to_Show_Suffix" formControlName="Phone_to_Show_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
            </div>
          </div>
    
    
          <br>
        </div>
      </aw-wizard-step>
      <aw-wizard-step
        stepId="Basic Information"
        stepTitle="Basic Information" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">2</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Basic Information Form">
	<!---->
  <h6 class="subtitle pr-2 py-2">Property Information</h6>
  <!---->


  <div class="d-flex form-inline row">

    <!-- Left Column-->
    <div class="col-md-6 align-self-start">
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="Bedrooms_ComboBox" class="nonreqtext pr-2 py-2">Bedrooms:</label>
        </div>
        <div class="form-group col-sm-3">
          <select id="Bedrooms_ComboBox" formControlName="Bedrooms_ComboBox" class="form-control col-sm-10">
              <option value="" selected=""> </option>
              <option value="  0.00">0 </option>
              <option value="  1.00">1 </option>
              <option value="  2.00">2 </option>
              <option value="  3.00">3 </option>
              <option value="  4.00">4 </option>
              <option value="  5.00">5 </option>
              <option value="  6.00">6 </option>
              <option value="  7.00">7 </option>
              <option value="  8.00">8 </option>
              <option value="  9.00">9 </option>
              <option value=" 10.00">10 </option>
              <option value=" 11.00">11 </option>
              <option value=" 12.00">12 </option>
              <option value=" 13.00">13 </option>
              <option value=" 14.00">14 </option>
              <option value=" 15.00">15 </option>
              <option value=" 16.00">16 </option>
              <option value=" 17.00">17 </option>
              <option value=" 18.00">18 </option>
              <option value=" 19.00">19 </option>
              <option value=" 20.00">20 </option>
              <option value=" 21.00">21 </option>
              <option value=" 22.00">22 </option>
              <option value=" 23.00">23 </option>
              <option value=" 24.00">24 </option>
              <option value=" 25.00">25 </option>
              <option value=" 26.00">26 </option>
              <option value=" 27.00">27 </option>
              <option value=" 28.00">28 </option>
              <option value=" 29.00">29 </option>
              <option value=" 30.00">30 </option>
              <option value=" 31.00">31 </option>
              <option value=" 32.00">32 </option>
              <option value=" 33.00">33 </option>
              <option value=" 34.00">34 </option>
              <option value=" 35.00">35 </option>
              <option value=" 36.00">36 </option>
              <option value=" 37.00">37 </option>
              <option value=" 38.00">38 </option>
              <option value=" 39.00">39 </option>
              <option value=" 40.00">40 </option>
              <option value=" 41.00">41 </option>
              <option value=" 42.00">42 </option>
              <option value=" 43.00">43 </option>
              <option value=" 44.00">44 </option>
              <option value=" 45.00">45 </option>
              <option value=" 46.00">46 </option>
              <option value=" 47.00">47 </option>
              <option value=" 48.00">48 </option>
              <option value=" 49.00">49 </option>
              <option value=" 50.00">50 </option>
              <option value=" 51.00">51 </option>
              <option value=" 52.00">52 </option>
              <option value=" 53.00">53 </option>
              <option value=" 54.00">54 </option>
              <option value=" 55.00">55 </option>
              <option value=" 56.00">56 </option>
              <option value=" 57.00">57 </option>
              <option value=" 58.00">58 </option>
              <option value=" 59.00">59 </option>
              <option value=" 60.00">60 </option>
              <option value=" 61.00">61 </option>
              <option value=" 62.00">62 </option>
              <option value=" 63.00">63 </option>
              <option value=" 64.00">64 </option>
              <option value=" 65.00">65 </option>
              <option value=" 66.00">66 </option>
              <option value=" 67.00">67 </option>
              <option value=" 68.00">68 </option>
              <option value=" 69.00">69 </option>
              <option value=" 70.00">70 </option>
              <option value=" 71.00">71 </option>
              <option value=" 72.00">72 </option>
              <option value=" 73.00">73 </option>
              <option value=" 74.00">74 </option>
              <option value=" 75.00">75 </option>
              <option value=" 76.00">76 </option>
              <option value=" 77.00">77 </option>
              <option value=" 78.00">78 </option>
              <option value=" 79.00">79 </option>
              <option value=" 80.00">80 </option>
              <option value=" 81.00">81 </option>
              <option value=" 82.00">82 </option>
              <option value=" 83.00">83 </option>
              <option value=" 84.00">84 </option>
              <option value=" 85.00">85 </option>
              <option value=" 86.00">86 </option>
              <option value=" 87.00">87 </option>
              <option value=" 88.00">88 </option>
              <option value=" 89.00">89 </option>
              <option value=" 90.00">90 </option>
          </select>
        </div>
      </div>
    </div> <!--end of left column-->

    <!--Right Column-->
    <div class="col-md-6 align-self-start">
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="Bathrooms_ComboBox" class="nonreqtext pr-2 py-2">Bathrooms:</label>
        </div>
        <div class="form-group col-sm-3">
          <select id="Bathrooms_ComboBox" formControlName="Bathrooms_ComboBox" class="form-control col-sm-10">
            <option value="" selected="">  </option>
            <option value="  0.00"> 0 </option>
            <option value="  1.00"> 1 </option>
            <option value="  1.50"> 1 1/2 </option>
            <option value="  1.75"> 1 3/4 </option>
            <option value="  2.00"> 2 </option>
            <option value="  2.50"> 2 1/2 </option>
            <option value="  2.75"> 2 3/4 </option>
            <option value="  3.00"> 3 </option>
            <option value="  3.50"> 3 1/2 </option>
            <option value="  3.75"> 3 3/4 </option>
            <option value="  4.00"> 4 </option>
            <option value="  4.50"> 4 1/2 </option>
            <option value="  4.75"> 4 3/4 </option>
            <option value="  5.00"> 5 </option>
            <option value="  6.00"> 6 </option>
            <option value="  7.00"> 7 </option>
            <option value="  8.00"> 8 </option>
            <option value="  9.00"> 9 </option>
            <option value=" 10.00"> 10 </option>
            <option value=" 11.00"> 11 </option>
            <option value=" 12.00"> 12 </option>
            <option value=" 13.00"> 13 </option>
            <option value=" 14.00"> 14 </option>
            <option value=" 15.00"> 15 </option>
            <option value=" 16.00"> 16 </option>
            <option value=" 17.00"> 17 </option>
            <option value=" 18.00"> 18 </option>
            <option value=" 19.00"> 19 </option>
            <option value=" 20.00"> 20 </option>
            <option value=" 21.00"> 21 </option>
            <option value=" 22.00"> 22 </option>
            <option value=" 23.00"> 23 </option>
            <option value=" 24.00"> 24 </option>
            <option value=" 25.00"> 25 </option>
            <option value=" 26.00"> 26 </option>
            <option value=" 27.00"> 27 </option>
            <option value=" 28.00"> 28 </option>
            <option value=" 29.00"> 29 </option>
            <option value=" 30.00"> 30 </option>
            <option value=" 31.00"> 31 </option>
            <option value=" 32.00"> 32 </option>
            <option value=" 33.00"> 33 </option>
            <option value=" 34.00"> 34 </option>
            <option value=" 35.00"> 35 </option>
            <option value=" 36.00"> 36 </option>
            <option value=" 37.00"> 37 </option>
            <option value=" 38.00"> 38 </option>
            <option value=" 39.00"> 39 </option>
            <option value=" 40.00"> 40 </option>
            <option value=" 41.00"> 41 </option>
            <option value=" 42.00"> 42 </option>
            <option value=" 43.00"> 43 </option>
            <option value=" 44.00"> 44 </option>
            <option value=" 45.00"> 45 </option>
            <option value=" 46.00"> 46 </option>
            <option value=" 47.00"> 47 </option>
            <option value=" 48.00"> 48 </option>
            <option value=" 49.00"> 49 </option>
            <option value=" 50.00"> 50 </option>
            <option value=" 51.00"> 51 </option>
            <option value=" 52.00"> 52 </option>
            <option value=" 53.00"> 53 </option>
            <option value=" 54.00"> 54 </option>
            <option value=" 55.00"> 55 </option>
            <option value=" 56.00"> 56 </option>
            <option value=" 57.00"> 57 </option>
            <option value=" 58.00"> 58 </option>
            <option value=" 59.00"> 59 </option>
            <option value=" 60.00"> 60 </option>
            <option value=" 61.00"> 61 </option>
            <option value=" 62.00"> 62 </option>
            <option value=" 63.00"> 63 </option>
            <option value=" 64.00"> 64 </option>
            <option value=" 65.00"> 65 </option>
            <option value=" 66.00"> 66 </option>
            <option value=" 67.00"> 67 </option>
            <option value=" 68.00"> 68 </option>
            <option value=" 69.00"> 69 </option>
            <option value=" 70.00"> 70 </option>
            <option value=" 71.00"> 71 </option>
            <option value=" 72.00"> 72 </option>
            <option value=" 73.00"> 73 </option>
            <option value=" 74.00"> 74 </option>
            <option value=" 75.00"> 75 </option>
            <option value=" 76.00"> 76 </option>
            <option value=" 77.00"> 77 </option>
            <option value=" 78.00"> 78 </option>
            <option value=" 79.00"> 79 </option>
            <option value=" 80.00"> 80 </option>
            <option value=" 81.00"> 81 </option>
            <option value=" 82.00"> 82 </option>
            <option value=" 83.00"> 83 </option>
            <option value=" 84.00"> 84 </option>
            <option value=" 85.00"> 85 </option>
            <option value=" 86.00"> 86 </option>
            <option value=" 87.00"> 87 </option>
            <option value=" 88.00"> 88 </option>
            <option value=" 89.00"> 89 </option>
            <option value=" 90.00"> 90 </option>
          </select>
        </div>
      </div>
    </div> <!--end of right column-->

  </div> <!--end of page/section-->


  <div class="d-flex form-inline row">

    <!-- Left Column-->
    <div class="col-md-6 align-self-start">
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="Struc_Sq_Ft" class= "nonreqtext pr-2 py-2">Aprx Sq Ft </label>
        </div>
        <div class="form-group col-sm-4">
          <input type="text" id="Struc_Sq_Ft" formControlName="Struc_Sq_Ft" class="form-control col-sm-10" size="9" maxlength="9" placeholder="0">
        </div>
      </div>
    </div> <!--end of left column-->

    <!--Right Column-->
    <div class="col-md-6 align-self-start">
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="selSqFtSource" class="nonreqtext pr-2 py-2">Sq Ft Source:</label>
        </div>
        <div class="form-group col-sm-4">
          <select id="selSqFtSource" formControlName="SqFt_Source" class="form-control col-sm-10">
              <option value="" selected="">  </option>
              <option value="SLLR"> Seller </option>
              <option value="APPR"> Appraiser </option>
              <option value="TAXR"> Tax Records </option>
              <option value="OTHR"> Other </option>
          </select>
        </div>
      </div>
    </div> <!--end of right column-->

  </div> <!--end of page/section-->


  <div class="d-flex form-inline row">

    <!-- Left Column-->
    <div class="col-lg-6 align-self-start">
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="Lot_Sq_Ft" class= "nonreqtext pr-2 py-2">Lot Size:</label>
        </div>
        <div class="form-group col-sm-4">
          <input type="text" id="Lot_Sq_Ft" formControlName="Lot_Sq_Ft" class="form-control col-sm-10" size="12" maxlength="9" value="">
        </div>
        <div class="d-flex form-group col-sm-4">
          <label for="Lot_Measurement" class="nonreqtext px-1">
            <input type="radio" id="Lot_Measurement" formControlName="Lot_Measurement" class="form-control" value="S" checked="">&nbsp;SqFt
          </label>
          <label for="Lot_Measurement_Acres" class="nonreqtext px-1">
            <input type="radio" id="Lot_Measurement_Acres" formControlName="Lot_Measurement" class="form-control" value="A">&nbsp;Acres
          </label>
        </div>
      </div>
    </div> <!--end of left column-->

    <!--Right Column-->
    <div class="col-lg-6 align-self-start">
      <div class="row">
        <div class="form-group col-sm-4">
          <label for="selLotSizeSource" class="nonreqtext pr-2 py-2">Lot Size Source:</label>
        </div>
        <div class="form-group col-sm-4">
          <select id="selLotSizeSource" formControlName="LotSize_Source" class="form-control col-sm-10">
            <option value="" selected="">  </option>
            <option value="APPR"> Appraiser </option>
            <option value="OTHR"> Other </option>
            <option value="SLLR"> Seller </option>
            <option value="TAXR"> Tax Records </option>
          </select>
        </div>
      </div>
    </div> <!--end of right column-->


  </div> <!--end of page/section-->


  <div class="d-flex form-inline row">

    <!-- Left Column-->
    <div class="col-md-4 align-self-start">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="Year_Built" class= "nonreqtext pr-2 py-2">Year Built:</label>
        </div>
        <div class="form-group col-sm-6">
          <input type="text" id="Year_Built" formControlName="Year_Built" class="form-control col-sm-10" size="8" maxlength="4" value="">
        </div>
      </div>
    </div> <!--end of left column-->

    <!--Right Column-->
    <div class="col-md-6 align-self-start">
      <div class="row">
        <div class="form-group col-sm-5">
          <label for="Unknown_Year" class="nonreqtext p-2">- or (select one) -</label>
        </div>
        <div class="form-group col-sm-6">
          <select id="Unknown_Year" formControlName="Unknown_Year" class="form-control">
              <option value="0" selected="">  </option>
              <option value="1"> New Construction </option>
              <option value="2"> Under Construction </option>
              <option value="3"> Unknown </option>
          </select>
        </div>
      </div>
    </div> <!--end of right column-->

  </div> <!--end of page/section-->


  <br>


  <div class="row">
    <div class="col">
      <h6><label for="Property_Description"><span class="reqsubtitle pr-1">*Marketing Remarks:</span></label><span class="reqtext formnote">&nbsp;(Maximum 1500)</span></h6>
      <textarea id="Property_Description" formControlName="Property_Description" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1500" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Property_Description.errors }"></textarea>
      <span class="reqtext formnote">&nbsp;{{ (1000 - f.Property_Description.value.length) }} characters left</span>
    </div>
  </div>


  <br>


  <div class="row">
    <div class="col">
      <h6><label for="Agent_Only_Remarks"><span class="subtitle pr-1">Agent Remarks:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 1000)</span></h6>
      <textarea id="Agent_Only_Remarks" formControlName="Agent_Only_Remarks" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"></textarea>
      <span class="reqtext formnote">&nbsp;{{ (1000 - f.Agent_Only_Remarks.value.length) }} characters left</span>
    </div>
  </div>


  <br>


  <div class="row">
    <div class="col">
      <h6><label for="Directions"><span class="nonreqsubtitle pr-1">Directions to Property:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 250)</span></h6>
      <textarea id="Directions" formControlName="Directions" class="form-control" spellcheck="true" rows="4" cols=""  maxlength="250"></textarea>
      <span class="reqtext formnote">&nbsp;{{ (250 - f.Directions.value.length) }} characters left</span>
    </div>
  </div>


  <br>


  <div class="row form-inline">
    <div class="form-group col-sm-6 justify-content-between">
      <label for="School_District_Fill_In" class= "reqtext pr-2 py-2">*School District:</label>
      <input type="text" id="School_District_Fill_In" formControlName="School_District_Fill_In" class="form-control col-sm-8" size="20" maxlength="20" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.School_District_Fill_In.errors }">
    </div>

    <div class="form-group col-sm-6 justify-content-between">
      <label for="School_Code_1" class= "reqtext pr-2 py-2">*Grade School:</label>
      <input type="text" id="School_Code_1" formControlName="School_Code_1" class="form-control col-sm-8" size="10" maxlength="20" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.School_Code_1.errors }">
    </div>
  </div>


  <div class="row form-inline">
    <div class="form-group col-sm-6 justify-content-between">
      <label for="School_Code_2" class= "reqtext pr-2 py-2">*Jr High School :</label>
      <input type="text" id="School_Code_2" formControlName="School_Code_2" class="form-control col-sm-8" size="20" maxlength="20" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.School_Code_2.errors }">
    </div>

    <div class="form-group col-sm-6 justify-content-between">
      <label for="School_Code_3" class= "reqtext pr-2 py-2">*Sr High School:</label>
      <input type="text" id="School_Code_3" formControlName="School_Code_3" class="form-control col-sm-8" size="20" maxlength="20" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.School_Code_3.errors }">
    </div>
  </div>


  <div class="row form-inline">
    <div class="form-group col-sm-6 justify-content-between">
      <label for="0590SCMT" class= "nonreqtext pr-1 py-1">Comments:<span class="formnote">&nbsp;(Maximum 60)&nbsp;</span></label>
      <textarea id="0590SCMT" formControlName="0590SCMT" class="form-control col-sm-8" spellcheck="true" rows="2" cols="" maxlength="60"></textarea>
    </div>
  </div>


  <br>
        </div>
      </aw-wizard-step>
      <aw-wizard-step  
        stepId="Plant Variety/Crop"
        stepTitle="Plant Variety/Crop" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">3</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Plant Variety/Crop Form">
        	<br>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0620MCRT" class= "reqtext pr-2 pb-2 ">*Major Crop Type:</label>
					<input type="text" id="0620MCRT" formControlName="0620MCRT" class="form-control col-md-8" size="10" maxlength="10" required [ngClass]="{ 'is-invalid alert-danger': submitted && f['0620MCRT'].errors }">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-sm-6 justify-content-between">
					<label for="CB_CPIN" class="nonreqtext pr-2 py-2">Crops Included:</label>
					<select id="CB_CPIN" formControlName="CPIN" class="form-control col-sm-2">
						<option value=""> </option>
						<option class="mBlackText" value="YESS">Yes</option>
						<option class="mBlackText" value="NONO">No</option>
					</select>
				</div>

				<div class="form-group col-sm-6 justify-content-end">
					<input type="text" id="0630CPIN" formControlName="0630CPIN" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0640PLV1" class= "nonreqtext pr-2 pb-2 ">Plant Variety/Crop:</label>
					<input type="text" id="0640PLV1" formControlName="0640PLV1" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-sm-6 justify-content-between">
					<label for="NUM_ACR1" class="nonreqtext pr-2 py-2">Acres 1:</label>
					<input type="text" id="NUM_ACR1" formControlName="ACR1" class="form-control col-md-4" size="10" maxlength="10">
				</div>

				<div class="form-group col-sm-6 justify-content-end">
					<input type="text" id="0650ACR1" formControlName="0650ACR1" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
				</div>

			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0660AGE1" class= "nonreqtext pr-2 pb-2 ">Age 1:</label>
					<input type="text" id="0660AGE1" formControlName="0660AGE1" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0670PRO1" class= "nonreqtext pr-2 pb-2 ">Production 1:</label>
					<input type="text" id="0670PRO1" formControlName="0670PRO1" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0680PLV2" class= "nonreqtext pr-2 pb-2 ">Plant Variety/Crop 2:</label>
					<input type="text" id="0680PLV2" formControlName="0680PLV2" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-sm-6 justify-content-between">
					<label for="NUM_ACR2" class="nonreqtext pr-2 py-2">Acres 2:</label>
					<input type="text" id="NUM_ACR2" formControlName="ACR2" class="form-control col-md-4" size="10" maxlength="10">
				</div>

				<div class="form-group col-sm-6 justify-content-end">
					<input type="text" id="0690ACR2" formControlName="0690ACR2" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
				</div>

			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0700AGE2" class= "nonreqtext pr-2 pb-2 ">Age 2:</label>
					<input type="text" id="0700AGE2" formControlName="0700AGE2" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0710PRO2" class= "nonreqtext pr-2 pb-2 ">Production 2:</label>
					<input type="text" id="0710PRO2" formControlName="0710PRO2" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0720PLV3" class= "nonreqtext pr-2 pb-2 ">Plant Variety/Crop 3:</label>
					<input type="text" id="0720PLV3" formControlName="0720PLV3" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-sm-6 justify-content-between">
					<label for="NUM_ACR3" class="nonreqtext pr-2 py-2">Acres 3:</label>
					<input type="text" id="NUM_ACR3" formControlName="ACR3" class="form-control col-md-4" size="10" maxlength="10">
				</div>

				<div class="form-group col-sm-6 justify-content-end">
					<input type="text" id="0730ACR3" formControlName="0730ACR3" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
				</div>

			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0740AGE3" class= "nonreqtext pr-2 pb-2 ">Age 3:</label>
					<input type="text" id="0740AGE3" formControlName="0740AGE3" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0750PRO3" class= "nonreqtext pr-2 pb-2 ">Production 3:</label>
					<input type="text" id="0750PRO3" formControlName="0750PRO3" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0760PLV4" class= "nonreqtext pr-2 pb-2 ">Plant Variety/Crop 4:</label>
					<input type="text" id="0760PLV4" formControlName="0760PLV4" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-sm-6 justify-content-between">
					<label for="NUM_ACR4" class="nonreqtext pr-2 py-2">Acres 4:</label>
					<input type="text" id="NUM_ACR4" formControlName="ACR4" class="form-control col-md-4" size="10" maxlength="10">
				</div>

				<div class="form-group col-sm-6 justify-content-end">
					<input type="text" id="0770ACR4" formControlName="0770ACR4" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
				</div>

			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0780AGE4" class= "nonreqtext pr-2 pb-2 ">Age 4:</label>
					<input type="text" id="0780AGE4" formControlName="0780AGE4" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0790PRO4" class= "nonreqtext pr-2 pb-2 ">Production 4:</label>
					<input type="text" id="0790PRO4" formControlName="0790PRO4" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0800PLV5" class= "nonreqtext pr-2 pb-2 ">Plant Variety/Crop 5:</label>
					<input type="text" id="0800PLV5" formControlName="0800PLV5" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-sm-6 justify-content-between">
					<label for="NUM_ACR5" class="nonreqtext pr-2 py-2">Acres 5:</label>
					<input type="text" id="NUM_ACR5" formControlName="ACR5" class="form-control col-md-4" size="10" maxlength="10">
				</div>

				<div class="form-group col-sm-6 justify-content-end">
					<input type="text" id="0810ACR5" formControlName="0810ACR5" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
				</div>

			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0820AGE5" class= "nonreqtext pr-2 pb-2 ">Age 5:</label>
					<input type="text" id="0820AGE5" formControlName="0820AGE5" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<div class="row form-inline">
				<div class="form-group col-md-6 justify-content-between">
					<label for="0830PRO5" class= "nonreqtext pr-2 pb-2 ">Production 5:</label>
					<input type="text" id="0830PRO5" formControlName="0830PRO5" class="form-control col-md-8" size="10" maxlength="10">
				</div>
			</div>


			<br>

        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepId="General Information"
        stepTitle="General Information" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">4</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="General Information Form">
          <br>

          <div class="d-flex form-inline row">
    
    
            <!-- Left Column-->
    
    
            <div class="col-lg-6 align-self-start">
              
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_CNSS" class= "nonreqtext pr-2 py-2 col-form-label">Census Tract:</label>
                  <input type="text" id="NUM_CNSS" formControlName="CNSS" class="form-control col-sm-5" size="" maxlength="6">
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0870CNSS" formControlName="0870CNSS" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="0880IRDT" class= "nonreqtext pr-2 py-2 col-form-label">Irrigation District:</label>
                </div>
                <div class="form-group col-sm-5 justify-content-end">
                  <input type="text" id="0880IRDT" formControlName="0880IRDT" class="form-control flex-grow-1" size="" maxlength="40">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="0890HWAY" class= "nonreqtext pr-2 py-2 col-form-label">Nearest Highway:</label>
                </div>
                <div class="form-group col-sm-5 justify-content-end">
                  <input type="text" id="0890HWAY" formControlName="0890HWAY" class="form-control flex-grow-1" size="" maxlength="40">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_AREA" class="reqtext pr-2 py-2 col-form-label">*Region:</label>
                  <select id="CB_AREA" formControlName="AREA" class="form-control col-sm-5" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.AREA.errors }">
                    <option value=""></option>
                    <option class="mBlackText" value="URBN">Urban </option>
                    <option class="mBlackText" value="SUBU">Rural </option>
                    <option class="mBlackText" value="FTHL">Foothill </option>
                    <option class="mBlackText" value="MNTN">Mountain </option>
                    <option class="mBlackText" value="RURL">Rural </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0900AREA" formControlName="0900AREA" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                </div>
              </div>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="Area_Fill_In" class="reqtext pr-2 py-2 col-form-label">*Area:</label>
                  <input type="text" id="Area_Fill_In" formControlName="Area_Fill_In" class="form-control col-sm-5" size="" maxlength="60" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Area_Fill_In.errors }">
                </div>
              </div>
    
    
              <div class="row form-group col-sm-12">
                <span class="reqtext pr-2 py-2 formnote">AREA refers to the last 3 digits of the property zipcode. Example: 93711. AREA = 711.</span>
              </div>
              
    
              <!--break for next group in the left column-->
              <br>
    
    
              <div class="row">
                <div class="form-group col-sm-12 justify-content-between">
                                <h6><label for="0940TOPO"><span class="reqtext pr-1">*Topography:</span></label><span class="reqtext formnote">&nbsp;(Maximum 255)</span></h6>
                                    <textarea id="0940TOPO" formControlName="0940TOPO" class="form-control flex-grow-1" spellcheck="true" rows="3" cols=""  maxlength="255" required [ngClass]="{ 'is-invalid alert-danger': submitted && f['0940TOPO'].errors }"></textarea>
                            </div>
                        </div>
    
    
              <!--break for next group in the left column-->
              <br>
    
    
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_DIST" class="nonreqtext pr-2 py-2 col-form-label">Distance from Town:</label>
                  <select id="CB_DIST" formControlName="DIST" class="form-control col-sm-9">
                    <option value=""></option>
                    <option class="mBlackText" value="LT10">Less than 10 Miles </option>
                    <option class="mBlackText" value="1025">10 - 25 Miles </option>
                    <option class="mBlackText" value="2550">25 - 50 Miles </option>
                    <option class="mBlackText" value="OV50">Over 50 miles </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0950DIST" formControlName="0950DIST" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                </div>
              </div>
    
    
              <!--break for next group in the left column-->
              <br>
    
              <div formGroupName="WATERSOURCE" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.WATERSOURCE.errors }"><span class="pr-2 py-2 reqtext">*Water Source:</span></span>
    
    
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="WTRSWELL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSWELL" formControlName="WTRSWELL" class="form-check-input">Well</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSWELL" formControlName="Checkbox_Comment_WTRSWELL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WTRSDSTW" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSDSTW" formControlName="WTRSDSTW" class="form-check-input">District Water Avail</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSDSTW" formControlName="Checkbox_Comment_WTRSDSTW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WTRSSCRK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSSCRK" formControlName="WTRSSCRK" class="form-check-input">Seasonal Creek</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSSCRK" formControlName="Checkbox_Comment_WTRSSCRK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WTRSLAKE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSLAKE" formControlName="WTRSLAKE" class="form-check-input">Lake</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSLAKE" formControlName="Checkbox_Comment_WTRSLAKE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WTRSSPNG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSSPNG" formControlName="WTRSSPNG" class="form-check-input">Spring</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSSPNG" formControlName="Checkbox_Comment_WTRSSPNG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WTRSWSTS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSWSTS" formControlName="WTRSWSTS" class="form-check-input">Water Storage Tank</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSWSTS" formControlName="Checkbox_Comment_WTRSWSTS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WTRSPOND" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSPOND" formControlName="WTRSPOND" class="form-check-input">Pond</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSPOND" formControlName="Checkbox_Comment_WTRSPOND" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WTRSNONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WTRSNONE" formControlName="WTRSNONE" class="form-check-input">None</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WTRSNONE" formControlName="Checkbox_Comment_WTRSNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
    
              <!--break for next group in the left column-->
              <br>
    
              <div formGroupName="IRRIGATIONWELLS" required>
              <span class="row col"  [ngClass]="{ 'alert-danger': submitted && f.IRRIGATIONWELLS.errors }"><span class="pr-2 py-2 reqtext">*Irrigation Wells:</span></span>
    
    
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="IRRWNONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="IRRWNONE" formControlName="IRRWNONE" class="form-check-input">None</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_IRRWNONE" formControlName="Checkbox_Comment_IRRWNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="IRRWONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="IRRWONE" formControlName="IRRWONE" class="form-check-input">One</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_IRRWONE" formControlName="Checkbox_Comment_IRRWONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="IRRWTWOM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="IRRWTWOM" formControlName="IRRWTWOM" class="form-check-input">Two or More</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_IRRWTWOM" formControlName="Checkbox_Comment_IRRWTWOM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="IRRWELEC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="IRRWELEC" formControlName="IRRWELEC" class="form-check-input">Electric</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_IRRWELEC" formControlName="Checkbox_Comment_IRRWELEC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="IRRWGEAR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="IRRWGEAR" formControlName="IRRWGEAR" class="form-check-input">Gearhead</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_IRRWGEAR" formControlName="Checkbox_Comment_IRRWGEAR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
                        <div class="row fixcheck">
                            <div class="form-group col-sm-6">
                                <div class="p-2 showbreak">&nbsp;</div>
                            </div>
                        </div>
    
              </div>
            <!--End of left column-->
          </div>
    
          <!--Right Column-->
    
          <div class="col-lg-6 align-self-start">
    
            <div formGroupName="DOMESTICWELLS" required>
            <span class="row col"  [ngClass]="{ 'alert-danger': submitted && f.DOMESTICWELLS.errors }"><span class="pr-2 py-2 reqtext">*Domestic Wells:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
    
    
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="DOMWNONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWNONE" formControlName="DOMWNONE" class="form-check-input">None</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWNONE" formControlName="Checkbox_Comment_DOMWNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWONE" formControlName="DOMWONE" class="form-check-input">One</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWONE" formControlName="Checkbox_Comment_DOMWONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWTWOM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWTWOM" formControlName="DOMWTWOM" class="form-check-input">Two or More</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWTWOM" formControlName="Checkbox_Comment_DOMWTWOM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWCITW" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWCITW" formControlName="DOMWCITW" class="form-check-input">City Water</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWCITW" formControlName="Checkbox_Comment_DOMWCITW" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWSHAR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWSHAR" formControlName="DOMWSHAR" class="form-check-input">Shared System</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWSHAR" formControlName="Checkbox_Comment_DOMWSHAR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWSUBM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWSUBM" formControlName="DOMWSUBM" class="form-check-input">Submersible</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWSUBM" formControlName="Checkbox_Comment_DOMWSUBM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWTURB" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWTURB" formControlName="DOMWTURB" class="form-check-input">Turbine</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWTURB" formControlName="Checkbox_Comment_DOMWTURB" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWWIND" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWWIND" formControlName="DOMWWIND" class="form-check-input">Windmill</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWWIND" formControlName="Checkbox_Comment_DOMWWIND" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DOMWGNRA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DOMWGNRA" formControlName="DOMWGNRA" class="form-check-input">Generator</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DOMWGNRA" formControlName="Checkbox_Comment_DOMWGNRA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            </div>
              <!--break for next group in the right column-->
              <br>
    
              <div formGroupName="SEWERSEPTIC" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.SEWERSEPTIC.errors }"><span class="pr-2 py-2 reqtext">*Sewer/Septic:</span></span>
    
    
              <!-- One row for an input -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="SEWRNONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SEWRNONE" formControlName="SEWRNONE" class="form-check-input">None</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SEWRNONE" formControlName="Checkbox_Comment_SEWRNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SEWRCITS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SEWRCITS" formControlName="SEWRCITS" class="form-check-input">City Sewer Avail.</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SEWRCITS" formControlName="Checkbox_Comment_SEWRCITS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SEWRSEPT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SEWRSEPT" formControlName="SEWRSEPT" class="form-check-input">Septic Tank</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SEWRSEPT" formControlName="Checkbox_Comment_SEWRSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SEWRSHAR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SEWRSHAR" formControlName="SEWRSHAR" class="form-check-input">Shared Septic</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SEWRSHAR" formControlName="Checkbox_Comment_SEWRSHAR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SEWRENGN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SEWRENGN" formControlName="SEWRENGN" class="form-check-input">Engineered System</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SEWRENGN" formControlName="Checkbox_Comment_SEWRENGN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              </div>
              <!--break for next group in the right column-->
              <br>
    
            <div formGroupName="UTILITIES" required>
            <span class="row col"  [ngClass]="{ 'alert-danger': submitted && f.UTILITIES.errors }"><span class="pr-2 py-2 reqtext">*Utilities:</span></span>
    
    
            <!--First row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILNONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILNONE" formControlName="UTILNONE" class="form-check-input">None</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILNONE" formControlName="Checkbox_Comment_UTILNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILELEC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILELEC" formControlName="UTILELEC" class="form-check-input">Electricity Available</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILELEC" formControlName="Checkbox_Comment_UTILELEC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILGAS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILGAS" formControlName="UTILGAS" class="form-check-input">Natural Gas Available</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILGAS" formControlName="Checkbox_Comment_UTILGAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILPROP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILPROP" formControlName="UTILPROP" class="form-check-input">Propane</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILPROP" formControlName="Checkbox_Comment_UTILPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILELET" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILELET" formControlName="UTILELET" class="form-check-input">Electricity Connected</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILELET" formControlName="Checkbox_Comment_UTILELET" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILPHON" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILPHON" formControlName="UTILPHON" class="form-check-input">Telephone</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILPHON" formControlName="Checkbox_Comment_UTILPHON" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILELEN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILELEN" formControlName="UTILELEN" class="form-check-input">Electricity Not Available</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILELEN" formControlName="Checkbox_Comment_UTILELEN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILGASC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILGASC" formControlName="UTILGASC" class="form-check-input">Natural Gas Connected</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILGASC" formControlName="Checkbox_Comment_UTILGASC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UTILGASN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UTILGASN" formControlName="UTILGASN" class="form-check-input">Natural Gas Not Available</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UTILGASN" formControlName="Checkbox_Comment_UTILGASN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            </div>
            <!--End of right column-->
          </div>				
        </div>
    
    
        <!-------------------------------------------------------------->
        <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
        <!-------------------------------------------------------------->
    
    
        <div class="d-flex form-inline row">
    
    
          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
    
    
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Special Disclosure:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
    
    
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="SPECSPSZ" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECSPSZ" formControlName="SPECSPSZ" class="form-check-input">Special Studies Zone</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECSPSZ" formControlName="Checkbox_Comment_SPECSPSZ" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECTOXI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECTOXI" formControlName="SPECTOXI" class="form-check-input">Toxic Waste Area</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECTOXI" formControlName="Checkbox_Comment_SPECTOXI" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECFLIT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECFLIT" formControlName="SPECFLIT" class="form-check-input">Flight Path</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECFLIT" formControlName="Checkbox_Comment_SPECFLIT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECASCN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECASCN" formControlName="SPECASCN" class="form-check-input">Special Construction</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECASCN" formControlName="Checkbox_Comment_SPECASCN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECAORD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECAORD" formControlName="SPECAORD" class="form-check-input">Agent/Owner Related</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECAORD" formControlName="Checkbox_Comment_SPECAORD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECSPSA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECSPSA" formControlName="SPECSPSA" class="form-check-input">Special Studies Area</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECSPSA" formControlName="Checkbox_Comment_SPECSPSA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECOTHR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECOTHR" formControlName="SPECOTHR" class="form-check-input">Other</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECOTHR" formControlName="Checkbox_Comment_SPECOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECSEER" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECSEER" formControlName="SPECSEER" class="form-check-input">See Remarks/Attachments</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECSEER" formControlName="Checkbox_Comment_SPECSEER" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECWILL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECWILL" formControlName="SPECWILL" class="form-check-input">Williamson Act</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECWILL" formControlName="Checkbox_Comment_SPECWILL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <div class="p-2 showbreak">&nbsp;</div>
              </div>
            </div>
    
    
          </div> <!--end of left column-->
    
    
          <!--Right Column-->
    
    
          <div class="col-lg-6 align-self-start">
    
    
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Documents Available:&nbsp;<span class="nonreqtext formnote">(Up to 9 entries allowed)</span></span></span>
    
    
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="DILOPLOT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOPLOT" formControlName="DILOPLOT" class="form-check-input">Plot Map</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOPLOT" formControlName="Checkbox_Comment_DILOPLOT" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOLEGA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOLEGA" formControlName="DILOLEGA" class="form-check-input">Legal Description</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOLEGA" formControlName="Checkbox_Comment_DILOLEGA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILODEED" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILODEED" formControlName="DILODEED" class="form-check-input">Deed Restrictions</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILODEED" formControlName="Checkbox_Comment_DILODEED" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOZONR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOZONR" formControlName="DILOZONR" class="form-check-input">Zoning Restrictions</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOZONR" formControlName="Checkbox_Comment_DILOZONR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOBLDR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOBLDR" formControlName="DILOBLDR" class="form-check-input">Building Restrictions</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOBLDR" formControlName="Checkbox_Comment_DILOBLDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOSURV" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOSURV" formControlName="DILOSURV" class="form-check-input">Survey</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOSURV" formControlName="Checkbox_Comment_DILOSURV" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOSOIL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOSOIL" formControlName="DILOSOIL" class="form-check-input">Soils Report</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOSOIL" formControlName="Checkbox_Comment_DILOSOIL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOPERC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOPERC" formControlName="DILOPERC" class="form-check-input">Percolation Report</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOPERC" formControlName="Checkbox_Comment_DILOPERC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOAERA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOAERA" formControlName="DILOAERA" class="form-check-input">Aerial Photographs</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOAERA" formControlName="Checkbox_Comment_DILOAERA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOTOPO" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOTOPO" formControlName="DILOTOPO" class="form-check-input">Topographical Map</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOTOPO" formControlName="Checkbox_Comment_DILOTOPO" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOLEAS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOLEAS" formControlName="DILOLEAS" class="form-check-input">Lease Information</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOLEAS" formControlName="Checkbox_Comment_DILOLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOAPPR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOAPPR" formControlName="DILOAPPR" class="form-check-input">Appraisal</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOAPPR" formControlName="Checkbox_Comment_DILOAPPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOEQPM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOEQPM" formControlName="DILOEQPM" class="form-check-input">Equipment List</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOEQPM" formControlName="Checkbox_Comment_DILOEQPM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOPROD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOPROD" formControlName="DILOPROD" class="form-check-input">Production Records</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOPROD" formControlName="Checkbox_Comment_DILOPROD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="DILOLESS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="DILOLESS" formControlName="DILOLESS" class="form-check-input">Leases</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_DILOLESS" formControlName="Checkbox_Comment_DILOLESS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
          <!--End of right column-->
          </div>				
        </div>
    
    
        <!-------------------------------------------------------------->
        <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
        <!-------------------------------------------------------------->
    
    
        <div class="d-flex form-inline row">
    
    
          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
    
            <div formGroupName="SHOWINGINSTRUCTIONS" required>
            <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.SHOWINGINSTRUCTIONS.errors }"><span class="pr-2 py-2 reqtext">*Showing Instructions:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
    
    
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="SHOWLOCK" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWLOCK" formControlName="SHOWLOCK" class="form-check-input">Lockbox</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWLOCK" formControlName="Checkbox_Comment_SHOWLOCK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWKEYO" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWKEYO" formControlName="SHOWKEYO" class="form-check-input">Key in Office</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWKEYO" formControlName="Checkbox_Comment_SHOWKEYO" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWGOOC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWGOOC" formControlName="SHOWGOOC" class="form-check-input">Go/Occupied</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWGOOC" formControlName="Checkbox_Comment_SHOWGOOC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCALL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCALL" formControlName="SHOWCALL" class="form-check-input">Call First/LM/Go</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCALL" formControlName="Checkbox_Comment_SHOWCALL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWAPPT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWAPPT" formControlName="SHOWAPPT" class="form-check-input">Confirmed Appt Only</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWAPPT" formControlName="Checkbox_Comment_SHOWAPPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOW24HR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOW24HR" formControlName="SHOW24HR" class="form-check-input">24 hour Notice</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOW24HR" formControlName="Checkbox_Comment_SHOW24HR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWNOSN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWNOSN" formControlName="SHOWNOSN" class="form-check-input">Not on Sunday</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWNOSN" formControlName="Checkbox_Comment_SHOWNOSN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWSNST" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWSNST" formControlName="SHOWSNST" class="form-check-input">Sunset Fri/Sat</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWSNST" formControlName="Checkbox_Comment_SHOWSNST" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCLAG" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCLAG" formControlName="SHOWCLAG" class="form-check-input">Call Agent</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCLAG" formControlName="Checkbox_Comment_SHOWCLAG" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWVAC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWVAC" formControlName="SHOWVAC" class="form-check-input">Vacant</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWVAC" formControlName="Checkbox_Comment_SHOWVAC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWDOGI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWDOGI" formControlName="SHOWDOGI" class="form-check-input">Dog Inside</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWDOGI" formControlName="Checkbox_Comment_SHOWDOGI" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWDOGO" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWDOGO" formControlName="SHOWDOGO" class="form-check-input">Dog Outside</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWDOGO" formControlName="Checkbox_Comment_SHOWDOGO" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWRSTC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWRSTC" formControlName="SHOWRSTC" class="form-check-input">Restricted Hours</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWRSTC" formControlName="Checkbox_Comment_SHOWRSTC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWSEAS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWSEAS" formControlName="SHOWSEAS" class="form-check-input">Seasonal Access</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWSEAS" formControlName="Checkbox_Comment_SHOWSEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWFWDR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWFWDR" formControlName="SHOWFWDR" class="form-check-input">4 Wheel Drive</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWFWDR" formControlName="Checkbox_Comment_SHOWFWDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCBSC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCBSC" formControlName="SHOWCBSC" class="form-check-input">CBS Code Required</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCBSC" formControlName="Checkbox_Comment_SHOWCBSC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWVTGS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWVTGS" formControlName="SHOWVTGS" class="form-check-input">Vacant Go Show</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWVTGS" formControlName="Checkbox_Comment_SHOWVTGS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWALRM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWALRM" formControlName="SHOWALRM" class="form-check-input">Alarm</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWALRM" formControlName="Checkbox_Comment_SHOWALRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <span class="row col"><span class="p-2 py-2 reqtext formnote">Alarm code may only be entered in MLS with sellers written consent</span></span>
    
            </div>
          </div> <!--end of left column-->
    
    
          <!--Right Column-->
    
    
          <div class="col-lg-6 align-self-start">
    
        
    
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_LBOX" class="reqtext pr-2 py-2 col-form-label">*Supra Lockbox:</label>
                <select id="CB_LBOX" formControlName="LBOX" class="form-control" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.LBOX.errors }">
                  <option value=""></option>
                  <option class="mBlackText" value="YES">Yes </option>
                  <option class="mBlackText" value="NO">No </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="1030LBOX" formControlName="1030LBOX" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
                    <div class="row">
                        <div class="form-group col-sm-8">
                            <label for="1040LOCL" class="nonreqtext pr-2 py-2 col-form-label">Lockbox Location:&nbsp;<span class="formnote">(Maximum 60)</span></label>
                        </div>
                        <div class="form-group col-sm-12 justify-content-end">
                            <textarea id="1040LOCL" formControlName="1040LOCL" class="form-control flex-grow-1" rows="" cols="40" maxlength="60"></textarea>
                        </div>
                    </div>
    
    
            <!--break for next group in the left column-->
            <br>
    
            <div class="row">
              <div class="form-group col-sm-8">
                <label for="Showing_Instructions" class="nonreqtext pr-2 py-2 col-form-label">Showing Instructions:&nbsp;<span class="formnote">(Maximum 60)</span></label>
              </div>
              <div class="form-group col-sm-12 justify-content-end">
                <textarea id="Showing_Instructions" formControlName="Showing_Instructions" class="form-control flex-grow-1" spellcheck="true" rows="" cols="50" maxlength="60"></textarea>
              </div>
            </div>
    
    
            <br>
    
          <!--End of right column-->
          </div>				
        </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepId="Property Features"
        stepTitle="Property Features" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">5</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Property Features Form">
        	<br>


			<div class="d-flex form-inline row">
				<!-- Left Column-->
				<div class="col-lg-6 align-self-start">

          <div formGroupName="BUILDINGONSITE" required>
					<span class="row col" [ngClass]="{ 'alert-danger': submitted && f.BUILDINGONSITE.errors }"><span class="pr-2 py-2 reqtext">*Building on Site:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>


					<!--First row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSNONE" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSNONE" formControlName="BONSNONE" class="form-check-input">None</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSNONE" formControlName="Checkbox_Comment_BONSNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSHOUS" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSHOUS" formControlName="BONSHOUS" class="form-check-input">House</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSHOUS" formControlName="Checkbox_Comment_BONSHOUS" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSRENT" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSRENT" formControlName="BONSRENT" class="form-check-input">Rental House</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSRENT" formControlName="Checkbox_Comment_BONSRENT" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSMOBL" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSMOBL" formControlName="BONSMOBL" class="form-check-input">Mobile Home</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSMOBL" formControlName="Checkbox_Comment_BONSMOBL" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSBARN" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSBARN" formControlName="BONSBARN" class="form-check-input">Barn</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSBARN" formControlName="Checkbox_Comment_BONSBARN" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSSHOP" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSSHOP" formControlName="BONSSHOP" class="form-check-input">Shop</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSSHOP" formControlName="Checkbox_Comment_BONSSHOP" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSPOLE" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSPOLE" formControlName="BONSPOLE" class="form-check-input">Pole Shed</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSPOLE" formControlName="Checkbox_Comment_BONSPOLE" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSOUTB" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSOUTB" formControlName="BONSOUTB" class="form-check-input">Outbuilding</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSOUTB" formControlName="Checkbox_Comment_BONSOUTB" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="BONSOTHR" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="BONSOTHR" formControlName="BONSOTHR" class="form-check-input">Other</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_BONSOTHR" formControlName="Checkbox_Comment_BONSOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>

          </div>
					<!--break for next group in the left column-->
					<br>


					<span class="row col"><span class="pr-2 py-2 nonreqtext">Mineral Rights:</span></span>


					<!-- One row for a new input group -->
					<div class="row fixcheck">
						
						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="MNRLNONE" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MNRLNONE" formControlName="MNRLNONE" class="form-check-input">None</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MNRLNONE" formControlName="Checkbox_Comment_MNRLNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="MNRLPART" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MNRLPART" formControlName="MNRLPART" class="form-check-input">Partial</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MNRLPART" formControlName="Checkbox_Comment_MNRLPART" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="MNRLAVAL" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MNRLAVAL" formControlName="MNRLAVAL" class="form-check-input">All Available</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MNRLAVAL" formControlName="Checkbox_Comment_MNRLAVAL" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="MNRLLEAS" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MNRLLEAS" formControlName="MNRLLEAS" class="form-check-input">Leased</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MNRLLEAS" formControlName="Checkbox_Comment_MNRLLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<div class="p-2 showbreak">&nbsp;</div>
						</div>
					</div>


				</div> <!--end of left column-->


				<!--Right Column-->
				<div class="col-lg-6 align-self-start">


					<span class="row col"><span class="pr-2 py-2 nonreqtext">Accessibility:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>


					<!-- One row for a new input group -->
					<div class="row fixcheck">
						
						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="TRANCITS" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANCITS" formControlName="TRANCITS" class="form-check-input">City Streets</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANCITS" formControlName="Checkbox_Comment_TRANCITS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANCNTR" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANCNTR" formControlName="TRANCNTR" class="form-check-input">County Road</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANCNTR" formControlName="Checkbox_Comment_TRANCNTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANPRIV" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANPRIV" formControlName="TRANPRIV" class="form-check-input">Private Road</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANPRIV" formControlName="Checkbox_Comment_TRANPRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANSTHG" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANSTHG" formControlName="TRANSTHG" class="form-check-input">State Highway</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANSTHG" formControlName="Checkbox_Comment_TRANSTHG" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANFRWY" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANFRWY" formControlName="TRANFRWY" class="form-check-input">Freeway</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANFRWY" formControlName="Checkbox_Comment_TRANFRWY" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANEASM" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANEASM" formControlName="TRANEASM" class="form-check-input">Easement Road</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANEASM" formControlName="Checkbox_Comment_TRANEASM" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANPAVE" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANPAVE" formControlName="TRANPAVE" class="form-check-input">Paved Road</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANPAVE" formControlName="Checkbox_Comment_TRANPAVE" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANGRAV" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANGRAV" formControlName="TRANGRAV" class="form-check-input">Gravel Road</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANGRAV" formControlName="Checkbox_Comment_TRANGRAV" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANRAIL" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANRAIL" formControlName="TRANRAIL" class="form-check-input">Railroad Siding</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANRAIL" formControlName="Checkbox_Comment_TRANRAIL" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRANAIRP" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRANAIRP" formControlName="TRANAIRP" class="form-check-input">Airport 1 mile</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRANAIRP" formControlName="Checkbox_Comment_TRANAIRP" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TRAN4WDR" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TRAN4WDR" formControlName="TRAN4WDR" class="form-check-input">Four Wheel Drive</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TRAN4WDR" formControlName="Checkbox_Comment_TRAN4WDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--break for next group in the right column-->
					<br>


					<span class="row col"><span class="pr-2 py-2 nonreqtext">Miscellaneous:</span></span>


					<!-- One row for a new input group -->
					<div class="row fixcheck">
						
						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="MISCCRFN" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MISCCRFN" formControlName="MISCCRFN" class="form-check-input">Crossed Fence</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MISCCRFN" formControlName="Checkbox_Comment_MISCCRFN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="MISCESMT" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MISCESMT" formControlName="MISCESMT" class="form-check-input">Easements</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MISCESMT" formControlName="Checkbox_Comment_MISCESMT" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="MISCLEAS" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MISCLEAS" formControlName="MISCLEAS" class="form-check-input">Ground Lease</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MISCLEAS" formControlName="Checkbox_Comment_MISCLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="MISCHUNT" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MISCHUNT" formControlName="MISCHUNT" class="form-check-input">Hunting Rights Lease</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MISCHUNT" formControlName="Checkbox_Comment_MISCHUNT" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="MISCGRAZ" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="MISCGRAZ" formControlName="MISCGRAZ" class="form-check-input">Grazing Rights Lease</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_MISCGRAZ" formControlName="Checkbox_Comment_MISCGRAZ" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>

					<br>

				</div> <!--end of right column-->
			</div> <!--end of page/section-->
        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepId="Financial Data"
        stepTitle="Financial Data" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">6</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Financial Data Form">
        	<br>


			<div class="d-flex form-inline row pt-1">


				<!-- Left Column-->
				<div class="col-lg-6 align-self-start">


					<span class="row col"><span class="pr-2 py-2 nonreqtext">Sale Options:</span></span>

					<!-- One row for a new input group -->
					<div class="row fixcheck">

						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="SALETOPO" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="SALETOPO" formControlName="SALETOPO" class="form-check-input">Total Parcel Only</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_SALETOPO" formControlName="Checkbox_Comment_SALETOPO" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>

					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="SALESUB" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="SALESUB" formControlName="SALESUB" class="form-check-input">Subdivided in Escrow</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_SALESUB" formControlName="Checkbox_Comment_SALESUB" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>

					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="SALEBULD" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="SALEBULD" formControlName="SALEBULD" class="form-check-input">Build to Suit</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_SALEBULD" formControlName="Checkbox_Comment_SALEBULD" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="SALEADJL" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="SALEADJL" formControlName="SALEADJL" class="form-check-input">Adjacent Land Avail</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_SALEADJL" formControlName="Checkbox_Comment_SALEADJL" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--break for next group in the left column-->
					<br>


					<span class="row col"><span class="pr-2 py-2 nonreqtext">1st Loan Type:</span></span>

					<!-- One row for a new input group -->
					<div class="row fixcheck">

						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="1SLNASSM" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="1SLNASSM" formControlName="1SLNASSM" class="form-check-input">Assumable</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_1SLNASSM" formControlName="Checkbox_Comment_1SLNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="1SLNNASS" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="1SLNNASS" formControlName="1SLNNASS" class="form-check-input">Non-Assumable</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_1SLNNASS" formControlName="Checkbox_Comment_1SLNNASS" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="1SLNPRVT" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="1SLNPRVT" formControlName="1SLNPRVT" class="form-check-input">Private</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_1SLNPRVT" formControlName="Checkbox_Comment_1SLNPRVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>

					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="1SLNFRCL" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="1SLNFRCL" formControlName="1SLNFRCL" class="form-check-input">Free & Clear</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_1SLNFRCL" formControlName="Checkbox_Comment_1SLNFRCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>

					<!--break for next group in the left column-->
					<br>


					<span class="row col"><span class="pr-2 py-2 nonreqtext">2nd Loan Type:</span></span>

					<!-- One row for a new input group -->
					<div class="row fixcheck">

						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="2LNASSM" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="2LNASSM" formControlName="2LNASSM" class="form-check-input">Assumable</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_2LNASSM" formControlName="Checkbox_Comment_2LNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>

					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="2LNNASS" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="2LNNASS" formControlName="2LNNASS" class="form-check-input">Non-Assumable</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_2LNNASS" formControlName="Checkbox_Comment_2LNNASS" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="2LNPRVT" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="2LNPRVT" formControlName="2LNPRVT" class="form-check-input">Private</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_2LNPRVT" formControlName="Checkbox_Comment_2LNPRVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>






				<!--End of left column-->
                </div>

                <!--Right Column-->
                <div class="col-lg-6 align-self-start">


					<span class="row col"><span class="pr-2 py-2 nonreqtext">Seller Will Consider:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>


					<!-- One row for a new input group -->
					<div class="row fixcheck">
						
						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="TERMCASH" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TERMCASH" formControlName="TERMCASH" class="form-check-input">Cash</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TERMCASH" formControlName="Checkbox_Comment_TERMCASH" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TERMSUBT" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TERMSUBT" formControlName="TERMSUBT" class="form-check-input">Subordinate</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TERMSUBT" formControlName="Checkbox_Comment_TERMSUBT" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TERMLEAS" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TERMLEAS" formControlName="TERMLEAS" class="form-check-input">Lease Option</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TERMLEAS" formControlName="Checkbox_Comment_TERMLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TERMOWNR" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TERMOWNR" formControlName="TERMOWNR" class="form-check-input">Owner Carry</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TERMOWNR" formControlName="Checkbox_Comment_TERMOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>
	
	
					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="TERMLIMI" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="TERMLIMI" formControlName="TERMLIMI" class="form-check-input">Limited Financing</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_TERMLIMI" formControlName="Checkbox_Comment_TERMLIMI" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--break for next group in the left column-->
					<br>


					<div class="row">
						<div class="form-group col-sm-7 justify-content-between">
							<label for="CB_HSSB" class="nonreqtext pr-2 py-2 col-form-label">Hardship Submitted:</label>
							<select id="CB_HSSB" formControlName="HSSB" class="form-control col-sm-3">
								<option value=""></option>
								<option class="mBlackText" value="YESS">Yes </option>
								<option class="mBlackText" value="NONO">No </option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="form-group col-sm-7 justify-content-between">
							<label for="NUM_STTD" class= "nonreqtext pr-2 py-2 col-form-label">1st Trust Deed Amt.:&nbsp;&nbsp;</label><span class="nonreqtext">$</span>
							<input type="text" id="NUM_STTD" formControlName="STTD" class="form-control col-sm-4" size="" maxlength="6">
						</div>
						<div class="form-group col-sm-5 justify-content-end">
							<input type="text" id="1250STTD" formControlName="1250STTD" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
						</div>
					</div>


					<div class="row">
						<div class="form-group col-sm-7 justify-content-between">
							<label for="NUM_SDTD" class= "nonreqtext pr-2 py-2 col-form-label">2nd Trust Deed Amt.:</label><span class="nonreqtext">$</span>
							<input type="text" id="NUM_SDTD" formControlName="SDTD" class="form-control col-sm-4" size="" maxlength="6">
						</div>
						<div class="form-group col-sm-5 justify-content-end">
							<input type="text" id="1260SDTD" formControlName="1260SDTD" class="form-control flex-grow-1" size="" maxlength="40">
						</div>
					</div>


					<!--break for next group in the right column-->
					<br>


					<div class="row form-inline">
						<div class="form-group col-sm-12 justify-content-between">
							<label for="1270SSSS" class= "nonreqtext pr-1 py-1">Notes:<span class="formnote">&nbsp;(Maximum 100)&nbsp;</span></label>
							<textarea id="1270SSSS" formControlName="1270SSSS" class="form-control col-sm-8" spellcheck="true" rows="2" cols="" maxlength="100"></textarea>
						</div>
					</div>


					<!--break for next group in the right column-->
					<br>


					<div class="row">
						<div class="form-group col-sm-7 justify-content-between">
							<label for="NUM_ADDD" class= "nonreqtext pr-2 py-2 col-form-label">Additional Debt:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label><span class="nonreqtext">$</span>
							<input type="text" id="NUM_ADDD" formControlName="ADDD" class="form-control col-sm-4" size="" maxlength="6">
						</div>
						<div class="form-group col-sm-5 justify-content-end">
							<input type="text" id="1271ADDD" formControlName="1271ADDD" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
						</div>
					</div>


					<!--break for next group in the right column-->
					<br>



					<span class="row col"><span class="pr-2 py-2 nonreqtext">Special Conditions:</span></span>

					<!-- One row for a new input group -->
					<div class="row fixcheck">

						<!-- Within that row, specify column length  -->
						<div class="form-group col-sm-6">
							<label for="UNIQBANK" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="UNIQBANK" formControlName="UNIQBANK" class="form-check-input">Bankruptcy Property</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_UNIQBANK" formControlName="Checkbox_Comment_UNIQBANK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="UNIQPROB" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="UNIQPROB" formControlName="UNIQPROB" class="form-check-input">Probate Listing</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_UNIQPROB" formControlName="Checkbox_Comment_UNIQPROB" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="UNIQESTA" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="UNIQESTA" formControlName="UNIQESTA" class="form-check-input">Estate Sale</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_UNIQESTA" formControlName="Checkbox_Comment_UNIQESTA" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="UNIQCORT" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="UNIQCORT" formControlName="UNIQCORT" class="form-check-input">Court Approval Req.</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_UNIQCORT" formControlName="Checkbox_Comment_UNIQCORT" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="UNIQBID" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="UNIQBID" formControlName="UNIQBID" class="form-check-input">Bidding Required</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_UNIQBID" formControlName="Checkbox_Comment_UNIQBID" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


					<!--next row-->
					<div class="row fixcheck">
						<div class="form-group col-sm-6">
							<label for="UNIQEXCH" class="form-check-label nonreqtext p-2">
							<input type="checkbox" id="UNIQEXCH" formControlName="UNIQEXCH" class="form-check-input">1031 Exchange</label>
						</div>
						<div class="form-group col-sm-6 justify-content-end">
							<input type="text" id="Checkbox_Comment_UNIQEXCH" formControlName="Checkbox_Comment_UNIQEXCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
						</div>
					</div>


				<!--End of right column-->
			    </div>				
		    </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepTitle="Review" (stepEnter)="validate()" awOptionalStep class="h-100">
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">7</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <ng-container *ngIf="form.valid; else notValidForm">
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-success">All required fields completed!</div>
              <div class="font-weight-light mb-2">Please review to make sure all information is correct and up-to-date before submitting.</div>
              <button class="btn btn-lg btn-primary w-100" (click)="submit()">Submit</button>
            </div>
          </div>
        </ng-container>
        <ng-template #notValidForm>
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-danger">Missing required fields!</div>
              <div class="font-weight-light mb-2">Please review below to make sure all required fields are completed.</div>
              <div class="card overflow-auto p-2 my-2" style="height: 250px;">
                <ul *ngFor="let error of errorList" class="list-group list-group-flush">
                  <li class="d-flex justify-content-between align-items-center list-group-item">{{error.key}}<button class="btn btn-sm btn-secondary" (click)="goToError(error)">Go to</button></li>
                </ul>
              </div>
              <button class="btn btn-lg btn-primary w-100" disabled>Submit</button>
            </div>
          </div>
        </ng-template>
      </aw-wizard-step>
    </div>
    <div class="form-footer p-2">
      <button type="button" class="btn btn-secondary mx-1" (click)="save()">Save</button>
      <div class="btn-group mx-1">
        <button type="button" class="btn btn-secondary" awPreviousStep>Previous</button>
        <button type="button" class="btn btn-secondary" awNextStep>Next</button>
      </div>
    </div>
  </aw-wizard>
</div>

<swal
  #submitSuccess
  title="Submission Sent"
  text="Your response was submitted successfully!"
  icon="success"
  confirmButtonText="Finish"
  (confirm)="complete()">

  <div *swalPortal class="alert alert-info">
    <div class="additional-form-container">
      <small class="mb-2">Request a sign install by selecting the button below!</small>
      <button class="btn btn-primary btn-lg" (click)="navigateToSign()">REQUEST SIGN INSTALL HERE</button>
    </div>
    <!--
    Do you need to fill any additional forms?
    <div class="additional-form-container mt-2">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h6>Request Sign Request Here</h6>
              <button class="btn btn-primary" (click)="navigateToSign()">Request Sign Request</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>

</swal>

<swal
  #submitError
  title="Submission Error"
  text="An error occurred during submission, please try again!"
  icon="error">
</swal>
