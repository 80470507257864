<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">

  <!-- Brand -->
  <a [routerLink]="['/']" class="navbar-brand app-brand py-0 mr-4">
    <img src="assets/images/ctrlfill_logo_white.png" class="media-body d-block" style="width: 180px" alt="CtrlFill">
  </a>

  <!-- Sidenav toggle -->
  <div class="navbar-nav align-items-lg-center mr-auto mr-lg-4" *ngIf="sidenavToggle">
    <a class="nav-item nav-link px-0 ml-2" href="javascript:void(0)" (click)="toggleSidenav()">
      <i class="ion ion-md-menu text-large align-middle"></i>
    </a>
  </div>

  <!-- Navbar toggle -->
  <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
    <div class="navbar-nav align-items-lg-center ml-auto">

      <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>
      <div *ngIf="(profile$ | async) as profile" ngbDropdown class="navbar-user nav-item">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <span class="px-1 ml-lg-2 mr-2 mr-lg-0"> {{ profile.email }}</span>
            <img [src]="profile.picture" alt class="d-block ui-w-30 rounded-circle">
          </span>
        </a>
        <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
          <!--
          <a href="javascript:void(0)" class="dropdown-item" (click)="openModal('CreateOrganization')"><i class="ion ion-md-add text-light"></i> &nbsp; Create organization</a>
          <a href="javascript:void(0)" class="dropdown-item" (click)="openModal('SwitchOrganization')"><i class="ion ion-md-sync text-light"></i> &nbsp; Switch organization</a>
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-person text-light"></i> &nbsp; My profile</a>
          <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-md-settings text-light"></i> &nbsp; Account settings</a>
          <div class="dropdown-divider"></div>
          -->
          <a href="javascript:void(0)" class="dropdown-item" (click)="logout()"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</a>
        </div>
      </div>

    </div>
  </div>

</nav>
