import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CreateOrganizationComponent } from './components/create-organization/create-organization.component';
import { NewUserInitComponent } from './components/new-user-init/new-user-init.component';
import { AddMemberComponent } from './components/add-member/add-member.component';
import { SaveResponseComponent } from './components/save-response/save-response.component';
import { SwitchOrganizationComponent } from './components/switch-organization/switch-organization.component';
import { EditMemberComponent } from './components/edit-member/edit-member.component';
import { DeleteMemberComponent } from './components/delete-member/delete-member.component';

@NgModule({
  declarations: [
    CreateOrganizationComponent,
    NewUserInitComponent,
    AddMemberComponent,
    SaveResponseComponent,
    SwitchOrganizationComponent,
    EditMemberComponent,
    DeleteMemberComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule
  ],
  exports: [
    CreateOrganizationComponent,
    NewUserInitComponent
  ]
})
export class ModalModule { }
