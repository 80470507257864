<div class="modal-header">
  <h5 class="modal-title">
    <span> Save response </span> <br>
    <small class="text-muted">Input a name to save your response</small>
  </h5> <br>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col">
      <label class="form-label">Save Name</label>
      <input type="text" class="form-control" [ngClass]="{'is-invalid': !isValid}" maxlength="30" [(ngModel)]="saveName">
      <small *ngIf="!isValid" class="form-text text-danger">Organization name must only contain alphanumeric characters</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="saveResponse()" [disabled]="isSaving">
    <span *ngIf="isSaving" class="spinner-border" role="status" aria-hidden="true"></span>
    Save
  </button>
</div>