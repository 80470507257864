import { Component, OnInit, OnDestroy, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectUnsubscribedError, Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { LayoutService } from 'src/app/services/layout.service';
import { CustomNavigationMode } from '../../services/custom-navigation';
import { CustomFormsService } from '../../services/custom-forms.service';
import { Response } from 'src/app/models/response.model';
import { ModalService } from 'src/app/modals/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { WizardComponent } from 'angular-archwizard';

@Component({
  selector: 'app-residential',
  templateUrl: './residential.component.html',
  styleUrls: ['./residential.component.css']
})
export class ResidentialComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('submitSuccess') private submitSuccess: SwalComponent;
  @ViewChild('submitError') private submitError: SwalComponent;
  @ViewChild('wizard') private wizard: WizardComponent;
  public errorList = [];

  public form: FormGroup
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public numErrors: number = 0;
  public key: string = '';
  public responseCache: Response = null;

  private routeSubscription: Subscription;
  private dataSubscription: Subscription;
  private formSubscription: Subscription;

  public navigationMode: CustomNavigationMode;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private layoutService: LayoutService,
    private customFormsService: CustomFormsService,
    private modalService: ModalService,
    private toastrService: ToastrService) {
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(true);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl(''),

      //"Commission_1": new FormControl(''),
      "Property_Type_ComboBox": new FormControl(''),
      "Office_ID": new FormControl('', [Validators.required]),
      "Office_MLS_ID": new FormControl('', [Validators.required]),
      "Agent_MLS_ID": new FormControl('', [Validators.required]),
      "Co_Office_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Agent_MLS_ID').value != '')]),
      "Co_Agent_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Office_MLS_ID').value != '')]),
      "Prop_Subtype_0001": new FormControl('', [Validators.required]),
      "Prop_Subtype_0002": new FormControl(''),
      "Prop_Subtype_0003": new FormControl(''),
      "Transaction": new FormControl('', [Validators.required]),
      "APN_1": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_2": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_3": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      //"Dollar_Percent": new FormControl('', [Validators.required]),
      "SSLE": new FormControl('', [Validators.required]),
      "REBK": new FormControl('', [Validators.required]),
      "Lease_Price": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Lease_Type": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Sub_Lease": new FormControl(''),
      "Available_Date": new FormControl(''),
      "Lease_Expiration": new FormControl(''),
      "County_ComboBox": new FormControl('', [Validators.required]),
      "New_Construction": new FormControl(''),
      "APNS": new FormControl(''),
      "0100APN2": new FormControl(''),
      "0110APN3": new FormControl(''),
      "0120APN4": new FormControl(''),
      "0130APN5": new FormControl(''),
      "Street_Number": new FormControl('', [Validators.required]),
      "Street_Number_Modifier": new FormControl(''),
      "Street_Direction": new FormControl(''),
      "Street_Address": new FormControl('', [Validators.required]),
      "Street_Suffix": new FormControl(''),
      "Post_Direction": new FormControl(''),
      "Unit_Number": new FormControl(''),
      "City_Fill_In": new FormControl('', [Validators.required]),
      "Zip_Code": new FormControl('', [Validators.required]),
      "Zip_Plus_4": new FormControl(''),
      "Cross_Street": new FormControl('', [Validators.required]),
      "Listing_Price_1": new FormControl('', [Validators.required]),
      "Listing_Price_2": new FormControl(''),
      "Agreement_Type_ComboBox": new FormControl('', [Validators.required]),
      "SCOP": new FormControl(''),
      "0225SCOP": new FormControl(''),
      "DUAL": new FormControl(''),
      "0230DUAL": new FormControl(''),
      "Publish_To_Internet": new FormControl('', [Validators.required]),
      "Show_Address_to_Public": new FormControl('', [Validators.required]),
      "Show_AVM_On_VOW": new FormControl('', [Validators.required]),
      "Show_Comments_On_VOW": new FormControl('', [Validators.required]),
      //"Commission_Amount": new FormControl('', [Validators.required]),
      //"Variable_Rate_Comm": new FormControl('', [Validators.required]),
      "Comm_Comments": new FormControl(''),
      "EOFF": new FormControl('', [Validators.required]),
      "0400EOAD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('EOFF').value == 'Yes')]),
      "Bedrooms_ComboBox": new FormControl('', [Validators.required]),
      "Bathrooms_ComboBox": new FormControl('', [Validators.required]),
      "Struc_Sq_Ft": new FormControl('', [Validators.required]),
      "SqFt_Source": new FormControl('', [Validators.required]),
      "Lot_Sq_Ft": new FormControl(''),
      "Lot_Measurement": new FormControl(''),
      "LotSize_Source": new FormControl('', [Validators.required]),
      "Year_Built": new FormControl('', [Validators.required]),
      "Unknown_Year": new FormControl(''),
      "Listing_Date": new FormControl('', [Validators.required]),
      "Expiration_Date": new FormControl('', [Validators.required]),
      "OnMarket_Date": new FormControl(''),
      "Owner_Name": new FormControl(''),
      "Owner_Phone_Area": new FormControl(''),
      "Owner_Phone_Prefix": new FormControl(''),
      "Owner_Phone_Suffix": new FormControl(''),
      "OCCUPANTTYPE": new FormGroup({
        "OCUTCARE": new FormControl(''),
        "OCUTOWNR": new FormControl(''),
        "OCUTTENA": new FormControl(''),
        "OCUTVACA": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      //"Occupant_Type": new FormControl('', [Validators.required]),
      "Occupant_Name": new FormControl(''),

      "PADU": new FormControl('', [Validators.required]),
      "ATYP": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "SADU": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "SADS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "APRK": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "SADR": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "0540ADUA": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('SADR').value == 'YESS')]),

      "GASM": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "WATM": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "ELRM": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "ADBD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "ADBH": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "OCCP": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "ATTC": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "AKIT": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "KITCHENFEATURES": new FormGroup({
        "ADKFRNOV": new FormControl(''),
        "ADKFGASA": new FormControl(''),
        "ADKFDISP": new FormControl(''),
        "ADKFMICR": new FormControl(''),
        "ADKFEATA": new FormControl(''),
        "ADKFPANT": new FormControl(''),
        "ADKFWINR": new FormControl(''),
        "ADKFBLRO": new FormControl(''),
        "ADKFELCA": new FormControl(''),
        "ADKFDISH": new FormControl(''),
        "ADKFTRSH": new FormControl(''),
        "ADKFBBAR": new FormControl(''),
        "ADKFFRIG": new FormControl(''),
        "ADKFOTHR": new FormControl(''),
      }),

      "ENTY": new FormControl(''),
      "YRBS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "0650ADUS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "RNTD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),
      "RTDR": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('RNTD').value == 'YESS')]),

      "YRBT": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PADU').value == 'YESS')]),

      "Phone_to_Show_Area": new FormControl(''),
      "Phone_to_Show_Prefix": new FormControl(''),
      "Phone_to_Show_Suffix": new FormControl(''),
      "Enable_Character_Count": new FormControl(''),
      "Property_Description": new FormControl('', [Validators.required]),
      "Agent_Only_Remarks": new FormControl(''),
      "0560XSLE": new FormControl(''),
      "Directions": new FormControl('', [Validators.required]),
      "School_District_Fill_In": new FormControl('', [Validators.required]),
      "SchoolType1": new FormControl('Grade School'),
      "School_Code_1": new FormControl('', [Validators.required]),
      "SchoolType2": new FormControl('Jr High School'),
      "School_Code_2": new FormControl('', [Validators.required]),
      "SchoolType3": new FormControl('Sr High School'),
      "School_Code_3": new FormControl('', [Validators.required]),
      "0870SCMT": new FormControl(''),
      "STYLRNCH": new FormControl(''),
      "Checkbox_Comment_STYLRNCH": new FormControl(''),
      "STYLBUNG": new FormControl(''),
      "Checkbox_Comment_STYLBUNG": new FormControl(''),
      "STYLCLNL": new FormControl(''),
      "Checkbox_Comment_STYLCLNL": new FormControl(''),
      "STYLSPAN": new FormControl(''),
      "Checkbox_Comment_STYLSPAN": new FormControl(''),
      "STYLCONT": new FormControl(''),
      "Checkbox_Comment_STYLCONT": new FormControl(''),
      "STYLTUDR": new FormControl(''),
      "Checkbox_Comment_STYLTUDR": new FormControl(''),
      "STYLVICT": new FormControl(''),
      "Checkbox_Comment_STYLVICT": new FormControl(''),
      "STYLMEDI": new FormControl(''),
      "Checkbox_Comment_STYLMEDI": new FormControl(''),
      "STYLTOWN": new FormControl(''),
      "Checkbox_Comment_STYLTOWN": new FormControl(''),
      "STYLCOTT": new FormControl(''),
      "Checkbox_Comment_STYLCOTT": new FormControl(''),
      "STYLCABN": new FormControl(''),
      "Checkbox_Comment_STYLCABN": new FormControl(''),
      "STYLMDCM": new FormControl(''),
      "Checkbox_Comment_STYLMDCM": new FormControl(''),
      "CNSS": new FormControl(''),
      "0650CNSS": new FormControl(''),
      "AREA": new FormControl('', [Validators.required]),
      "0660AREA": new FormControl(''),
      "Area_Fill_In": new FormControl('', [Validators.required]),
      "0690ZONE": new FormControl(''),
      "0700LTDM": new FormControl(''),
      "0710TRCT": new FormControl(''),
      "NUMOFSTORIES": new FormGroup({
        "STORSNGL": new FormControl(''),
        "STORTWOS": new FormControl(''),
        "STORSPLT": new FormControl(''),
        "Checkbox_Comment_STORSNGL": new FormControl(''),
        "Checkbox_Comment_STORTWOS": new FormControl(''),
        "Checkbox_Comment_STORSPLT": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "MBLV": new FormControl('', [Validators.required]),
      "SEWERWATER": new FormGroup({
        "WATRPWTR": new FormControl(''),
        "Checkbox_Comment_WATRPWTR": new FormControl(''),
        "WATRPWEL": new FormControl(''),
        "Checkbox_Comment_WATRPWEL": new FormControl(''),
        "WATRSEPT": new FormControl(''),
        "Checkbox_Comment_WATRSEPT": new FormControl(''),
        "WATRSWEL": new FormControl(''),
        "Checkbox_Comment_WATRSWEL": new FormControl(''),
        "WATRCWEL": new FormControl(''),
        "Checkbox_Comment_WATRCWEL": new FormControl(''),
        "WATRPRVT": new FormControl(''),
        "Checkbox_Comment_WATRPRVT": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "GASELECTRIC": new FormGroup({
        "GSELPUBU": new FormControl(''),
        "Checkbox_Comment_GSELPUBU": new FormControl(''),
        "GSELPROP": new FormControl(''),
        "Checkbox_Comment_GSELPROP": new FormControl(''),
        "GSELNOGS": new FormControl(''),
        "Checkbox_Comment_GSELNOGS": new FormControl(''),
        "GSELALEL": new FormControl(''),
        "Checkbox_Comment_GSELALEL": new FormControl(''),
        "GSELGENR": new FormControl(''),
        "Checkbox_Comment_GSELGENR": new FormControl(''),
        "GSELNOEL": new FormControl(''),
        "Checkbox_Comment_GSELNOEL": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "UNDSGRDF": new FormControl(''),
      "Checkbox_Comment_UNDSGRDF": new FormControl(''),
      "UNDSSECF": new FormControl(''),
      "Checkbox_Comment_UNDSSECF": new FormControl(''),
      "UNDSSPLT": new FormControl(''),
      "Checkbox_Comment_UNDSSPLT": new FormControl(''),
      "UNDSENDU": new FormControl(''),
      "Checkbox_Comment_UNDSENDU": new FormControl(''),
      "UNDSINSD": new FormControl(''),
      "Checkbox_Comment_UNDSINSD": new FormControl(''),
      "UNDSFRNT": new FormControl(''),
      "Checkbox_Comment_UNDSFRNT": new FormControl(''),
      "UNDSREAR": new FormControl(''),
      "Checkbox_Comment_UNDSREAR": new FormControl(''),
      "SPFTISBD": new FormControl(''),
      "Checkbox_Comment_SPFTISBD": new FormControl(''),
      "SPFTISBT": new FormControl(''),
      "Checkbox_Comment_SPFTISBT": new FormControl(''),
      "SPFTSTDO": new FormControl(''),
      "Checkbox_Comment_SPFTSTDO": new FormControl(''),
      "SPFTSSYS": new FormControl(''),
      "Checkbox_Comment_SPFTSSYS": new FormControl(''),
      "SPFTSGAT": new FormControl(''),
      "Checkbox_Comment_SPFTSGAT": new FormControl(''),
      "SPFTCABN": new FormControl(''),
      "Checkbox_Comment_SPFTCABN": new FormControl(''),
      "SPFTCTLV": new FormControl(''),
      "Checkbox_Comment_SPFTCTLV": new FormControl(''),
      "SPFTSKYL": new FormControl(''),
      "Checkbox_Comment_SPFTSKYL": new FormControl(''),
      "SPFTWTRS": new FormControl(''),
      "Checkbox_Comment_SPFTWTRS": new FormControl(''),
      "SPFTWTRF": new FormControl(''),
      "Checkbox_Comment_SPFTWTRF": new FormControl(''),
      "SPFTBARR": new FormControl(''),
      "Checkbox_Comment_SPFTBARR": new FormControl(''),
      "SPFTDISH": new FormControl(''),
      "Checkbox_Comment_SPFTDISH": new FormControl(''),
      "SPFTINTC": new FormControl(''),
      "Checkbox_Comment_SPFTINTC": new FormControl(''),
      "SPFTHTEK": new FormControl(''),
      "Checkbox_Comment_SPFTHTEK": new FormControl(''),
      "SPFTMPHN": new FormControl(''),
      "Checkbox_Comment_SPFTMPHN": new FormControl(''),
      "SPFTTENN": new FormControl(''),
      "Checkbox_Comment_SPFTTENN": new FormControl(''),
      "SPFTOTHR": new FormControl(''),
      "Checkbox_Comment_SPFTOTHR": new FormControl(''),
      "SPFTHSAL": new FormControl(''),
      "Checkbox_Comment_SPFTHSAL": new FormControl(''),
      "SPFTHSET": new FormControl(''),
      "Checkbox_Comment_SPFTHSET": new FormControl(''),
      "DSFTWHCR": new FormControl(''),
      "Checkbox_Comment_DSFTWHCR": new FormControl(''),
      "DSFTLWHC": new FormControl(''),
      "Checkbox_Comment_DSFTLWHC": new FormControl(''),
      "DSFTRAMP": new FormControl(''),
      "Checkbox_Comment_DSFTRAMP": new FormControl(''),
      "DSFTWDDR": new FormControl(''),
      "Checkbox_Comment_DSFTWDDR": new FormControl(''),
      "DSFTHDRL": new FormControl(''),
      "Checkbox_Comment_DSFTHDRL": new FormControl(''),
      "DSFTBTHF": new FormControl(''),
      "Checkbox_Comment_DSFTBTHF": new FormControl(''),
      "DSFTWCHS": new FormControl(''),
      "Checkbox_Comment_DSFTWCHS": new FormControl(''),
      "DSFT1LVL": new FormControl(''),
      "Checkbox_Comment_DSFT1LVL": new FormControl(''),
      "DSFTELEV": new FormControl(''),
      "Checkbox_Comment_DSFTELEV": new FormControl(''),
      "ENERDPWN": new FormControl(''),
      "Checkbox_Comment_ENERDPWN": new FormControl(''),
      "ENERTPWN": new FormControl(''),
      "Checkbox_Comment_ENERTPWN": new FormControl(''),
      "ENERSLPH": new FormControl(''),
      "Checkbox_Comment_ENERSLPH": new FormControl(''),
      "ENERSLWH": new FormControl(''),
      "Checkbox_Comment_ENERSLWH": new FormControl(''),
      "ENERSEAC": new FormControl(''),
      "Checkbox_Comment_ENERSEAC": new FormControl(''),
      "ENERTANK": new FormControl(''),
      "Checkbox_Comment_ENERTANK": new FormControl(''),
      "ENEROTHR": new FormControl(''),
      "Checkbox_Comment_ENEROTHR": new FormControl(''),
      "SOLA": new FormControl('', [Validators.required]),
      "SOLR": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('SOLA').value == 'Yes')]),
      "0810SOLR": new FormControl(''),
      "LSDESECS": new FormControl(''),
      "Checkbox_Comment_LSDESECS": new FormControl(''),
      "LSDEPROP": new FormControl(''),
      "Checkbox_Comment_LSDEPROP": new FormControl(''),
      "LSDEWTRS": new FormControl(''),
      "Checkbox_Comment_LSDEWTRS": new FormControl(''),
      "LSDESATD": new FormControl(''),
      "Checkbox_Comment_LSDESATD": new FormControl(''),
      "LSDESOLP": new FormControl(''),
      "Checkbox_Comment_LSDESOLP": new FormControl(''),
      "SECO": new FormControl(''),
      "0830SECO": new FormControl(''),
      "LBOX": new FormControl('', [Validators.required]),
      "0840LBOX": new FormControl(''),
      "1120LOCL": new FormControl(''),
      "SHOWING": new FormGroup({
        "SHOWLOCK": new FormControl(''),
        "Checkbox_Comment_SHOWLOCK": new FormControl(''),
        "SHOWKEYO": new FormControl(''),
        "Checkbox_Comment_SHOWKEYO": new FormControl(''),
        "SHOWGOOC": new FormControl(''),
        "Checkbox_Comment_SHOWGOOC": new FormControl(''),
        "SHOWCALL": new FormControl(''),
        "Checkbox_Comment_SHOWCALL": new FormControl(''),
        "SHOWAPPT": new FormControl(''),
        "Checkbox_Comment_SHOWAPPT": new FormControl(''),
        "SHOW24HR": new FormControl(''),
        "Checkbox_Comment_SHOW24HR": new FormControl(''),
        "SHOWNOSN": new FormControl(''),
        "Checkbox_Comment_SHOWNOSN": new FormControl(''),
        "SHOWSNST": new FormControl(''),
        "Checkbox_Comment_SHOWSNST": new FormControl(''),
        "SHOWCLAG": new FormControl(''),
        "Checkbox_Comment_SHOWCLAG": new FormControl(''),
        "SHOWDOGI": new FormControl(''),
        "Checkbox_Comment_SHOWDOGI": new FormControl(''),
        "SHOWDOGO": new FormControl(''),
        "Checkbox_Comment_SHOWDOGO": new FormControl(''),
        "SHOWRSTC": new FormControl(''),
        "Checkbox_Comment_SHOWRSTC": new FormControl(''),
        "SHOWLKGT": new FormControl(''),
        "Checkbox_Comment_SHOWLKGT": new FormControl(''),
        "SHOWSEAS": new FormControl(''),
        "Checkbox_Comment_SHOWSEAS": new FormControl(''),
        "SHOWSHOW": new FormControl(''),
        "Checkbox_Comment_SHOWSHOW": new FormControl(''),
        "SHOWFWDR": new FormControl(''),
        "Checkbox_Comment_SHOWFWDR": new FormControl(''),
        "SHOWCBSC": new FormControl(''),
        "Checkbox_Comment_SHOWCBSC": new FormControl(''),
        "SHOWVTGS": new FormControl(''),
        "Checkbox_Comment_SHOWVTGS": new FormControl(''),
        "SHOWALRM": new FormControl(''),
        "Checkbox_Comment_SHOWALRM": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "0890GTEC": new FormControl(''),
      "Showing_Instructions": new FormControl(''),
      "WLDP": new FormControl(''),
      "0910WLDP": new FormControl(''),
      "WTRL": new FormControl(''),
      "0930WTRL": new FormControl(''),
      "GPM": new FormControl(''),
      "0940GPM": new FormControl(''),
      "WLHP": new FormControl(''),
      "0950WLHP": new FormControl(''),
      "HEATINGANDCOOLING": new FormGroup({
        "HTCLDCSP": new FormControl(''),
        "Checkbox_Comment_HTCLDCSP": new FormControl(''),
        "HTCLCNHC": new FormControl(''),
        "Checkbox_Comment_HTCLCNHC": new FormControl(''),
        "HTCLCNHT": new FormControl(''),
        "Checkbox_Comment_HTCLCNHT": new FormControl(''),
        "HTCLCNCL": new FormControl(''),
        "Checkbox_Comment_HTCLCNCL": new FormControl(''),
        "HTCLEVAP": new FormControl(''),
        "Checkbox_Comment_HTCLEVAP": new FormControl(''),
        "HTCLFLWL": new FormControl(''),
        "Checkbox_Comment_HTCLFLWL": new FormControl(''),
        "HTCLWNWL": new FormControl(''),
        "Checkbox_Comment_HTCLWNWL": new FormControl(''),
        "HTCLWHOL": new FormControl(''),
        "Checkbox_Comment_HTCLWHOL": new FormControl(''),
        "HTCLNONE": new FormControl(''),
        "Checkbox_Comment_HTCLNONE": new FormControl(''),
        "HTCLOTHR": new FormControl(''),
        "Checkbox_Comment_HTCLOTHR": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "CB_UDRS": new FormControl('', [Validators.required]),
      "1190UDRS": new FormControl(''),
      "FLOO": new FormControl('', [Validators.required]),
      "0990FLOO": new FormControl(''),
      "FLORCARP": new FormControl(''),
      "Checkbox_Comment_FLORCARP": new FormControl(''),
      "FLORTILE": new FormControl(''),
      "Checkbox_Comment_FLORTILE": new FormControl(''),
      "FLORVINY": new FormControl(''),
      "Checkbox_Comment_FLORVINY": new FormControl(''),
      "FLORHDWD": new FormControl(''),
      "Checkbox_Comment_FLORHDWD": new FormControl(''),
      "FLOROTHR": new FormControl(''),
      "Checkbox_Comment_FLOROTHR": new FormControl(''),
      "FRPN": new FormControl('', [Validators.required]),
      "1010FRPN": new FormControl(''),
      "FRPTMASN": new FormControl(''),
      "Checkbox_Comment_FRPTMASN": new FormControl(''),
      "FRPTGASO": new FormControl(''),
      "Checkbox_Comment_FRPTGASO": new FormControl(''),
      "FRPTWOOD": new FormControl(''),
      "Checkbox_Comment_FRPTWOOD": new FormControl(''),
      "FRPTZERO": new FormControl(''),
      "Checkbox_Comment_FRPTZERO": new FormControl(''),
      "FRPTFREE": new FormControl(''),
      "Checkbox_Comment_FRPTFREE": new FormControl(''),
      "FRPTPELL": new FormControl(''),
      "Checkbox_Comment_FRPTPELL": new FormControl(''),
      "1030FIRL": new FormControl(''),
      "LAUNDRY": new FormGroup({
        "LNDRINSD": new FormControl(''),
        "Checkbox_Comment_LNDRINSD": new FormControl(''),
        "LNDRINGR": new FormControl(''),
        "Checkbox_Comment_LNDRINGR": new FormControl(''),
        "LNDRINCL": new FormControl(''),
        "Checkbox_Comment_LNDRINCL": new FormControl(''),
        "LNDRUTRM": new FormControl(''),
        "Checkbox_Comment_LNDRUTRM": new FormControl(''),
        "LNDRONPR": new FormControl(''),
        "Checkbox_Comment_LNDRONPR": new FormControl(''),
        "LNDRONUP": new FormControl(''),
        "Checkbox_Comment_LNDRONUP": new FormControl(''),
        "LNDRONLW": new FormControl(''),
        "Checkbox_Comment_LNDRONLW": new FormControl(''),
        "LNDRGSHK": new FormControl(''),
        "Checkbox_Comment_LNDRGSHK": new FormControl(''),
        "LNDRELHK": new FormControl(''),
        "Checkbox_Comment_LNDRELHK": new FormControl(''),
        "LNDRCMMN": new FormControl(''),
        "Checkbox_Comment_LNDRCMMN": new FormControl(''),
        "LNDRCOIN": new FormControl(''),
        "Checkbox_Comment_LNDRCOIN": new FormControl(''),
        "LNDRNONE": new FormControl(''),
        "Checkbox_Comment_LNDRNONE": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "BTHFTUBS": new FormControl(''),
      "Checkbox_Comment_BTHFTUBS": new FormControl(''),
      "BTHFSHWR": new FormControl(''),
      "Checkbox_Comment_BTHFSHWR": new FormControl(''),
      "BTHFTUB": new FormControl(''),
      "Checkbox_Comment_BTHFTUB": new FormControl(''),
      "BTHFOTHR": new FormControl(''),
      "Checkbox_Comment_BTHFOTHR": new FormControl(''),
      "BTHFJTUB": new FormControl(''),
      "Checkbox_Comment_BTHFJTUB": new FormControl(''),
      "BTHFOTUB": new FormControl(''),
      "Checkbox_Comment_BTHFOTUB": new FormControl(''),
      "BTHFRTUB": new FormControl(''),
      "Checkbox_Comment_BTHFRTUB": new FormControl(''),
      "KITCRNOV": new FormControl(''),
      "Checkbox_Comment_KITCRNOV": new FormControl(''),
      "KITCBLRO": new FormControl(''),
      "Checkbox_Comment_KITCBLRO": new FormControl(''),
      "KITCGASA": new FormControl(''),
      "Checkbox_Comment_KITCGASA": new FormControl(''),
      "KITCELCA": new FormControl(''),
      "Checkbox_Comment_KITCELCA": new FormControl(''),
      "KITCDISP": new FormControl(''),
      "Checkbox_Comment_KITCDISP": new FormControl(''),
      "KITCDISH": new FormControl(''),
      "Checkbox_Comment_KITCDISH": new FormControl(''),
      "KITCMICR": new FormControl(''),
      "Checkbox_Comment_KITCMICR": new FormControl(''),
      "KITCTRSH": new FormControl(''),
      "Checkbox_Comment_KITCTRSH": new FormControl(''),
      "KITCEATA": new FormControl(''),
      "Checkbox_Comment_KITCEATA": new FormControl(''),
      "KITCBBAR": new FormControl(''),
      "Checkbox_Comment_KITCBBAR": new FormControl(''),
      "KITCPANT": new FormControl(''),
      "Checkbox_Comment_KITCPANT": new FormControl(''),
      "KITCFRIG": new FormControl(''),
      "Checkbox_Comment_KITCFRIG": new FormControl(''),
      "KITCWINR": new FormControl(''),
      "Checkbox_Comment_KITCWINR": new FormControl(''),
      "KITCOTHR": new FormControl(''),
      "Checkbox_Comment_KITCOTHR": new FormControl(''),
      "DINFFRML": new FormControl(''),
      "Checkbox_Comment_DINFFRML": new FormControl(''),
      "DINFLVRA": new FormControl(''),
      "Checkbox_Comment_DINFLVRA": new FormControl(''),
      "DINFFMRA": new FormControl(''),
      "Checkbox_Comment_DINFFMRA": new FormControl(''),
      "BSSQ": new FormControl(''),
      "1080BSSQ": new FormControl(''),
      "BSMDFULL": new FormControl(''),
      "Checkbox_Comment_BSMDFULL": new FormControl(''),
      "BSMDPART": new FormControl(''),
      "Checkbox_Comment_BSMDPART": new FormControl(''),
      "BSMDFINS": new FormControl(''),
      "Checkbox_Comment_BSMDFINS": new FormControl(''),
      "BSMDUNFN": new FormControl(''),
      "Checkbox_Comment_BSMDUNFN": new FormControl(''),
      "BSMDNONE": new FormControl(''),
      "Checkbox_Comment_BSMDNONE": new FormControl(''),
      "XTRRGRTR": new FormControl(''),
      "Checkbox_Comment_XTRRGRTR": new FormControl(''),
      "XTRROFC": new FormControl(''),
      "Checkbox_Comment_XTRROFC": new FormControl(''),
      "XTRRFMRM": new FormControl(''),
      "Checkbox_Comment_XTRRFMRM": new FormControl(''),
      "XTRRDENS": new FormControl(''),
      "Checkbox_Comment_XTRRDENS": new FormControl(''),
      "XTRRLOFT": new FormControl(''),
      "Checkbox_Comment_XTRRLOFT": new FormControl(''),
      "XTRRGAME": new FormControl(''),
      "Checkbox_Comment_XTRRGAME": new FormControl(''),
      "XTRRCNVG": new FormControl(''),
      "Checkbox_Comment_XTRRCNVG": new FormControl(''),
      "XTRRWINE": new FormControl(''),
      "Checkbox_Comment_XTRRWINE": new FormControl(''),
      "XTRROTHR": new FormControl(''),
      "Checkbox_Comment_XTRROTHR": new FormControl(''),
      "LIVL": new FormControl(''),
      "1130LIVL": new FormControl(''),
      "FRML": new FormControl(''),
      "1140FRML": new FormControl(''),
      "KTCL": new FormControl(''),
      "1150KTCL": new FormControl(''),
      "EATL": new FormControl(''),
      "1160EATL": new FormControl(''),
      "FAML": new FormControl(''),
      "1170FAML": new FormControl(''),
      "GRTL": new FormControl(''),
      "1180GRTL": new FormControl(''),
      "MAIL": new FormControl(''),
      "1190MAIL": new FormControl(''),
      "SBDR": new FormControl(''),
      "1200SBDR": new FormControl(''),
      "TBDR": new FormControl(''),
      "1210TBDR": new FormControl(''),
      "FBDR": new FormControl(''),
      "1220FBDR": new FormControl(''),
      "XTRL": new FormControl(''),
      "1230XTRL": new FormControl(''),
      "UTLL": new FormControl(''),
      "1240UTLL": new FormControl(''),
      "SITECRNR": new FormControl(''),
      "Checkbox_Comment_SITECRNR": new FormControl(''),
      "SITECULD": new FormControl(''),
      "Checkbox_Comment_SITECULD": new FormControl(''),
      "SITEBLUF": new FormControl(''),
      "Checkbox_Comment_SITEBLUF": new FormControl(''),
      "SITEGOLF": new FormControl(''),
      "Checkbox_Comment_SITEGOLF": new FormControl(''),
      "SITERIVR": new FormControl(''),
      "Checkbox_Comment_SITERIVR": new FormControl(''),
      "SITELAKE": new FormControl(''),
      "Checkbox_Comment_SITELAKE": new FormControl(''),
      "SITEPRKV": new FormControl(''),
      "Checkbox_Comment_SITEPRKV": new FormControl(''),
      "SITEWTRF": new FormControl(''),
      "Checkbox_Comment_SITEWTRF": new FormControl(''),
      "SITECRSF": new FormControl(''),
      "Checkbox_Comment_SITECRSF": new FormControl(''),
      "SITEPAST": new FormControl(''),
      "Checkbox_Comment_SITEPAST": new FormControl(''),
      "SITEOTHR": new FormControl(''),
      "Checkbox_Comment_SITEOTHR": new FormControl(''),
      "Garage_Spaces": new FormControl('', [Validators.required]),
      "GART": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Garage_Spaces').value > 0)]),
      "1290GART": new FormControl(''),
      "CRPT": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('PARKCRPT').value == true)]),
      "1310CRPT": new FormControl(''),
      "PARKEXRV": new FormControl(''),
      "Checkbox_Comment_PARKEXRV": new FormControl(''),
      "PARKPTRV": new FormControl(''),
      "Checkbox_Comment_PARKPTRV": new FormControl(''),
      "PARKCRPT": new FormControl(''),
      "Checkbox_Comment_PARKCRPT": new FormControl(''),
      "PARKUNCV": new FormControl(''),
      "Checkbox_Comment_PARKUNCV": new FormControl(''),
      "PARKWKSH": new FormControl(''),
      "Checkbox_Comment_PARKWKSH": new FormControl(''),
      "PARKDRTH": new FormControl(''),
      "Checkbox_Comment_PARKDRTH": new FormControl(''),
      "PARKAOPN": new FormControl(''),
      "Checkbox_Comment_PARKAOPN": new FormControl(''),
      "PARKSTRT": new FormControl(''),
      "Checkbox_Comment_PARKSTRT": new FormControl(''),
      "PARKCIRC": new FormControl(''),
      "Checkbox_Comment_PARKCIRC": new FormControl(''),
      "PARKTNDM": new FormControl(''),
      "Checkbox_Comment_PARKTNDM": new FormControl(''),
      "PARKOTHR": new FormControl(''),
      "Checkbox_Comment_PARKOTHR": new FormControl(''),
      "Pool": new FormControl('', [Validators.required]),
      "POLS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Pool').value == 'Y')]),
      "POOLTYPE": new FormGroup({
        "POLTINGR": new FormControl(''),
        "Checkbox_Comment_POLTINGR": new FormControl(''),
        "POLTABGR": new FormControl(''),
        "Checkbox_Comment_POLTABGR": new FormControl(''),
      }),
      "PLFEFNCD": new FormControl(''),
      "PLFECMTY": new FormControl(''),
      "PLFEGUNI": new FormControl(''),
      "PLFEVINY": new FormControl(''),
      "PLFEFIBR": new FormControl(''),
      "PLFEHEAT": new FormControl(''),
      "PLFEGRAS": new FormControl(''),
      "PLFEFIBO": new FormControl(''),
      "PLFEPEBB": new FormControl(''),
      "PLFEWTRF": new FormControl(''),
      "ROOFING": new FormGroup({
        "ROOFCOMP": new FormControl(''),
        "Checkbox_Comment_ROOFCOMP": new FormControl(''),
        "ROOFTILE": new FormControl(''),
        "Checkbox_Comment_ROOFTILE": new FormControl(''),
        "ROOFWOOD": new FormControl(''),
        "Checkbox_Comment_ROOFWOOD": new FormControl(''),
        "ROOFMETL": new FormControl(''),
        "Checkbox_Comment_ROOFMETL": new FormControl(''),
        "ROOFTARG": new FormControl(''),
        "Checkbox_Comment_ROOFTARG": new FormControl(''),
        "ROOFROLL": new FormControl(''),
        "Checkbox_Comment_ROOFROLL": new FormControl(''),
        "ROOFFIBR": new FormControl(''),
        "Checkbox_Comment_ROOFFIBR": new FormControl(''),
        "ROOFOTHR": new FormControl(''),
        "Checkbox_Comment_ROOFOTHR": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "EXTERIOR": new FormGroup({
        "EXTRSTUC": new FormControl(''),
        "Checkbox_Comment_EXTRSTUC": new FormControl(''),
        "EXTRWOOD": new FormControl(''),
        "Checkbox_Comment_EXTRWOOD": new FormControl(''),
        "EXTRBRIK": new FormControl(''),
        "Checkbox_Comment_EXTRBRIK": new FormControl(''),
        "EXTRBLCK": new FormControl(''),
        "Checkbox_Comment_EXTRBLCK": new FormControl(''),
        "EXTRSTON": new FormControl(''),
        "Checkbox_Comment_EXTRSTON": new FormControl(''),
        "EXTRMETL": new FormControl(''),
        "Checkbox_Comment_EXTRMETL": new FormControl(''),
        "EXTRSHGL": new FormControl(''),
        "Checkbox_Comment_EXTRSHGL": new FormControl(''),
        "EXTRLOGG": new FormControl(''),
        "Checkbox_Comment_EXTRLOGG": new FormControl(''),
        "EXTRVNYL": new FormControl(''),
        "Checkbox_Comment_EXTRVNYL": new FormControl(''),
        "EXTROTHR": new FormControl(''),
        "Checkbox_Comment_EXTROTHR": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "YARDSPKF": new FormControl(''),
      "Checkbox_Comment_YARDSPKF": new FormControl(''),
      "YARDSPKB": new FormControl(''),
      "Checkbox_Comment_YARDSPKB": new FormControl(''),
      "YARDSPKA": new FormControl(''),
      "Checkbox_Comment_YARDSPKA": new FormControl(''),
      "YARDSPKM": new FormControl(''),
      "Checkbox_Comment_YARDSPKM": new FormControl(''),
      "YARDFNCD": new FormControl(''),
      "Checkbox_Comment_YARDFNCD": new FormControl(''),
      "YARDMTRL": new FormControl(''),
      "Checkbox_Comment_YARDMTRL": new FormControl(''),
      "YARDFRUT": new FormControl(''),
      "Checkbox_Comment_YARDFRUT": new FormControl(''),
      "YARDGRDA": new FormControl(''),
      "Checkbox_Comment_YARDGRDA": new FormControl(''),
      "YARDSYNT": new FormControl(''),
      "Checkbox_Comment_YARDSYNT": new FormControl(''),
      "YARDDRIP": new FormControl(''),
      "Checkbox_Comment_YARDDRIP": new FormControl(''),
      "YARDOTHR": new FormControl(''),
      "Checkbox_Comment_YARDOTHR": new FormControl(''),
      "SPAY": new FormControl(''),
      "1380SPAY": new FormControl(''),
      "SPATINGR": new FormControl(''),
      "Checkbox_Comment_SPATINGR": new FormControl(''),
      "SPATABGR": new FormControl(''),
      "Checkbox_Comment_SPATABGR": new FormControl(''),
      "SPATCOMS": new FormControl(''),
      "Checkbox_Comment_SPATCOMS": new FormControl(''),
      "PATOCVRD": new FormControl(''),
      "Checkbox_Comment_PATOCVRD": new FormControl(''),
      "PATOUNCV": new FormControl(''),
      "Checkbox_Comment_PATOUNCV": new FormControl(''),
      "PATOENCL": new FormControl(''),
      "Checkbox_Comment_PATOENCL": new FormControl(''),
      "PATOBRCK": new FormControl(''),
      "Checkbox_Comment_PATOBRCK": new FormControl(''),
      "PATOCONC": new FormControl(''),
      "Checkbox_Comment_PATOCONC": new FormControl(''),
      "PATOWOOD": new FormControl(''),
      "Checkbox_Comment_PATOWOOD": new FormControl(''),
      "PATOSTON": new FormControl(''),
      "Checkbox_Comment_PATOSTON": new FormControl(''),
      "PATOSCRN": new FormControl(''),
      "Checkbox_Comment_PATOSCRN": new FormControl(''),
      "PATOOTHR": new FormControl(''),
      "Checkbox_Comment_PATOOTHR": new FormControl(''),
      "DETSSHOP": new FormControl(''),
      "Checkbox_Comment_DETSSHOP": new FormControl(''),
      "DETSGEST": new FormControl(''),
      "Checkbox_Comment_DETSGEST": new FormControl(''),
      "DETSBARN": new FormControl(''),
      "Checkbox_Comment_DETSBARN": new FormControl(''),
      "DETSKENL": new FormControl(''),
      "Checkbox_Comment_DETSKENL": new FormControl(''),
      "DETSSHED": new FormControl(''),
      "Checkbox_Comment_DETSSHED": new FormControl(''),
      "DETS2HOU": new FormControl(''),
      "Checkbox_Comment_DETS2HOU": new FormControl(''),
      "DETSGRNH": new FormControl(''),
      "Checkbox_Comment_DETSGRNH": new FormControl(''),
      "DETSOTHR": new FormControl(''),
      "Checkbox_Comment_DETSOTHR": new FormControl(''),
      "SPECFLOD": new FormControl(''),
      "Checkbox_Comment_SPECFLOD": new FormControl(''),
      "SPECAGTR": new FormControl(''),
      "Checkbox_Comment_SPECAGTR": new FormControl(''),
      "SPECDEPR": new FormControl(''),
      "Checkbox_Comment_SPECDEPR": new FormControl(''),
      "SPECFLIT": new FormControl(''),
      "Checkbox_Comment_SPECFLIT": new FormControl(''),
      "SPECSEER": new FormControl(''),
      "Checkbox_Comment_SPECSEER": new FormControl(''),
      "IRPTHOME": new FormControl(''),
      "Checkbox_Comment_IRPTHOME": new FormControl(''),
      "IRPTROOF": new FormControl(''),
      "Checkbox_Comment_IRPTROOF": new FormControl(''),
      "IRPTPEST": new FormControl(''),
      "Checkbox_Comment_IRPTPEST": new FormControl(''),
      "IRPTSEPT": new FormControl(''),
      "Checkbox_Comment_IRPTSEPT": new FormControl(''),
      "IRPTWELR": new FormControl(''),
      "Checkbox_Comment_IRPTWELR": new FormControl(''),
      "IRPTWTRP": new FormControl(''),
      "Checkbox_Comment_IRPTWTRP": new FormControl(''),
      "IRPTCC&R": new FormControl(''),
      "Checkbox_Comment_IRPTCC&R": new FormControl(''),
      "IRPTRULE": new FormControl(''),
      "Checkbox_Comment_IRPTRULE": new FormControl(''),
      "IRPTBYLW": new FormControl(''),
      "Checkbox_Comment_IRPTBYLW": new FormControl(''),
      "IRPTBDGT": new FormControl(''),
      "Checkbox_Comment_IRPTBDGT": new FormControl(''),
      "IRPTFINC": new FormControl(''),
      "Checkbox_Comment_IRPTFINC": new FormControl(''),
      "IRPTOTHR": new FormControl(''),
      "Checkbox_Comment_IRPTOTHR": new FormControl(''),
      "IRPTAPPR": new FormControl(''),
      "Checkbox_Comment_IRPTAPPR": new FormControl(''),
      "IRPTNHDD": new FormControl(''),
      "Checkbox_Comment_IRPTNHDD": new FormControl(''),
      "IRPTPREL": new FormControl(''),
      "Checkbox_Comment_IRPTPREL": new FormControl(''),
      "1SLNASSM": new FormControl(''),
      "Checkbox_Comment_1SLNASSM": new FormControl(''),
      "1SLNNONA": new FormControl(''),
      "Checkbox_Comment_1SLNNONA": new FormControl(''),
      "1SLNPRVT": new FormControl(''),
      "Checkbox_Comment_1SLNPRVT": new FormControl(''),
      "1SLNFRCL": new FormControl(''),
      "Checkbox_Comment_1SLNFRCL": new FormControl(''),
      "2LNASSM": new FormControl(''),
      "Checkbox_Comment_2LNASSM": new FormControl(''),
      "2LNPRVT": new FormControl(''),
      "Checkbox_Comment_2LNPRVT": new FormControl(''),
      "2LNNONA": new FormControl(''),
      "Checkbox_Comment_2LNNONA": new FormControl(''),
      "TERMGOVT": new FormControl(''),
      "Checkbox_Comment_TERMGOVT": new FormControl(''),
      "TERMCONV": new FormControl(''),
      "Checkbox_Comment_TERMCONV": new FormControl(''),
      "TERMLSOP": new FormControl(''),
      "Checkbox_Comment_TERMLSOP": new FormControl(''),
      "TERMCASH": new FormControl(''),
      "Checkbox_Comment_TERMCASH": new FormControl(''),
      "TERMOWNR": new FormControl(''),
      "Checkbox_Comment_TERMOWNR": new FormControl(''),
      "TERMLIMI": new FormControl(''),
      "Checkbox_Comment_TERMLIMI": new FormControl(''),
      "AUCT": new FormControl('', [Validators.required]),
      "HSSB": new FormControl(''),
      "STTD": new FormControl(''),
      "1530STTD": new FormControl(''),
      "SDTD": new FormControl(''),
      "1540SDTD": new FormControl(''),
      "1550SSSS": new FormControl(''),
      "ADDD": new FormControl(''),
      "1560ADDD": new FormControl(''),
      "UNIQPROB": new FormControl(''),
      "Checkbox_Comment_UNIQPROB": new FormControl(''),
      "UNIQESTA": new FormControl(''),
      "Checkbox_Comment_UNIQESTA": new FormControl(''),
      "UNIQBKRP": new FormControl(''),
      "Checkbox_Comment_UNIQBKRP": new FormControl(''),
      "UNIQCORT": new FormControl(''),
      "Checkbox_Comment_UNIQCORT": new FormControl(''),
      "UNIQRELO": new FormControl(''),
      "Checkbox_Comment_UNIQRELO": new FormControl(''),
      "UNIQEXCH": new FormControl(''),
      "Checkbox_Comment_UNIQEXCH": new FormControl(''),
      "HOAA": new FormControl('', [Validators.required]),
      "1610RENT": new FormControl(''),
      "RENT": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('HOAA').value == 'Yes')]),
      "1620FREQ": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('HOAA').value == 'Yes')]),
      "HOAAMENITIES": new FormGroup({
        "PRKFPOOL": new FormControl(''),
        "Checkbox_Comment_PRKFPOOL": new FormControl(''),
        "PRKFSPA": new FormControl(''),
        "Checkbox_Comment_PRKFSPA": new FormControl(''),
        "PRKFSAUN": new FormControl(''),
        "Checkbox_Comment_PRKFSAUN": new FormControl(''),
        "PRKFCLUB": new FormControl(''),
        "Checkbox_Comment_PRKFCLUB": new FormControl(''),
        "PRKFEXRM": new FormControl(''),
        "Checkbox_Comment_PRKFEXRM": new FormControl(''),
        "PRKFGRNA": new FormControl(''),
        "Checkbox_Comment_PRKFGRNA": new FormControl(''),
        "PRKFRVST": new FormControl(''),
        "Checkbox_Comment_PRKFRVST": new FormControl(''),
        "PRKFTENN": new FormControl(''),
        "Checkbox_Comment_PRKFTENN": new FormControl(''),
        "PRKFRQTB": new FormControl(''),
        "Checkbox_Comment_PRKFRQTB": new FormControl(''),
        "PRKFSECG": new FormControl(''),
        "Checkbox_Comment_PRKFSECG": new FormControl(''),
        "PRKFLKPN": new FormControl(''),
        "Checkbox_Comment_PRKFLKPN": new FormControl(''),
        "PRKFPLAY": new FormControl(''),
        "Checkbox_Comment_PRKFPLAY": new FormControl(''),
        "PRKFLDSC": new FormControl(''),
        "Checkbox_Comment_PRKFLDSC": new FormControl(''),
        "PRKFGATE": new FormControl(''),
        "Checkbox_Comment_PRKFGATE": new FormControl(''),
        "PRKFOTNN": new FormControl(''),
        "Checkbox_Comment_PRKFOTNN": new FormControl('')
      }),
      "SEON": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('HOAA').value == 'Yes')]),
      "1640SEON": new FormControl(''),
      "INPK": new FormControl('', [Validators.required]),
      "1720INPK": new FormControl(''),
      "1730RAMT": new FormControl(''),
      "RAMT": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "NUM_RAMT": new FormControl(''),
      "DESC": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "1740DESC": new FormControl(''),
      "TYPE": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "1750TYPE": new FormControl(''),
      "PRMF": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "1770HUDN": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "1780LICS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "1790SNUM": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "1800PKNM": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "1810MODL": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('INPK').value == 'YES' || this.form.get('Prop_Subtype_0001').value == 'MNFG')]),
      "WANTSIGN": new FormControl(''),
      "WANTSIGNCOMMENT":  new FormControl(''),
    }, { updateOn: 'change' });


    // Customized conditionals and form setters
    this.form.get('POOLTYPE').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('Pool').value == 'Y')]);
    this.form.get('HOAAMENITIES').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('HOAA').value == 'Yes')]);
    this.form.get('KITCHENFEATURES').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('AKIT').value == 'YESS')]);

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      const temp = value;
      Object.keys(temp).forEach(key => {
        if(!temp[key] || temp[key] == '' || temp[key] == null)
          delete temp[key];
      });
      localStorage.setItem('cachedFormData', JSON.stringify(temp));

      this.form.get('Co_Office_MLS_ID').updateValueAndValidity({onlySelf: true});
      this.form.get('Co_Agent_MLS_ID').updateValueAndValidity({onlySelf: true});
    })

    this.formSubscription.add(this.form.get('Office_ID').valueChanges.subscribe(val => {
      this.form.get('Office_MLS_ID').setValue(val);
    }));

    this.formSubscription.add(this.form.get('Street_Number').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Direction').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Address').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Suffix').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.navigationMode = new CustomNavigationMode(this.form);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.key = params.key;
      if(params.key) {
        this.dataSubscription = this.responseService.getResponse(params.key).subscribe(payload => {
          this.form.patchValue(payload.data);
          this.responseCache = payload;
        })
      }
    })
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if(this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if(this.responseCache)
      this.responseCache = null;

    if(this.formSubscription)
      this.formSubscription.unsubscribe();
  }

  get f() { return this.form.controls; }

  submit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if(this.form.valid) {
      this.isLoading = true;
      let temp = this.form.value;
//      if(temp["Dollar_Percent"] == '$') {
//        temp["Commission_1"] = '$' + temp["Commission_Amount"];
//      } else if(temp["Dollar_Percent"] == '%') {
//        temp["Commission_1"] = temp["Commission_Amount"] + '%';
//      }
      temp["Office_MLS_ID"] = temp["Office_ID"]; 


      const data = this.customFormsService.sanitizeFormPayload(temp);

      if(!this.responseCache) {
        const response = {
          form: '5ea75840c62db4d34e01a4e1',
          organization: '',
          name: 'Save_' + Math.random().toString(36).substr(2, 5),
          data: temp,
          submitted: true
        }
        this.responseService.postResponse(response).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        })
      } else {
        this.responseService.patchResponse(this.responseCache._id, {data: data, submitted: true }).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        });
      }
    } else {
      this.numErrors = 0;
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.numErrors++;
              console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });
      this.toastrService.error('You have not completed the form!', 'Submission Failed');
    }
  }

  save() {
    const self = this;
    this.isSaving = true;

    // Remove empty/null inputs to store less
    const data = this.customFormsService.sanitizeFormPayload(this.form.value);
    if(!this.responseCache) {
      this.modalService.openModal('SaveResponse', { backdrop: 'static', keyboard: false }, {formData: data, formId: '5ea75840c62db4d34e01a4e1'}, function(data) {
        console.log(data);
        if(data.err)
          self.toastrService.error('Save was unsuccessful, please try again.', 'Error 500');
        else {
          self.toastrService.success('Save was successful', 'Success');
          self.router.navigate(['.'], { relativeTo: self.route, queryParams: {key: data._id}, queryParamsHandling: 'merge' });
          self.form.markAsPristine();
        }
      });
    } else {
      if(this.form.dirty) {
        this.responseService.patchResponse(this.responseCache._id, {data: data}).subscribe(res => {
          this.isSaving = false;
          this.responseCache = res;
          this.form.markAsPristine();
          this.toastrService.success('Save was successful', 'Success');
        });
      } else {
        this.toastrService.info('No changes detected', 'Unsuccessful Save');
      }
    }
  }

  enableControl(condition: boolean, controlNames: string[]) {
    if(condition) {
      controlNames.forEach(name => {
        this.form.controls[name].enable();
      })
    } else {
      controlNames.forEach(name => {
        this.form.controls[name].disable();
      })
    }
  }

  validate() {
    this.submitted = true;
    this.numErrors = 0;
    this.errorList = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.numErrors++;
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          let errorElem = document.querySelector("[formControlName='" + key + "']");
          if(!errorElem) {
            let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
            errorElem = document.querySelector("[formControlName='" + childKey + "']");
          }

          let stepId = errorElem.closest('aw-wizard-step').getAttribute('stepid')
          this.errorList.push({
            key: key,
            stepId: stepId,
          })
        });
      }
    });
  }

  goToError(error) {
    const key = error.key
    const index = this.wizard.getIndexOfStepWithId(error.stepId);
    this.wizard.goToStep(index);

    let errorElem = document.querySelector("[formControlName='" + key + "']");
    if(!errorElem) {
      let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
      errorElem = document.querySelector("[formControlName='" + childKey + "']");
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + childKey + "']").getBoundingClientRect().top - 250
      }, 100)
    } else {
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + error.key + "']").getBoundingClientRect().top - 200
      }, 100)
    }
  }

  complete() {
    this.layoutService.setSideNavHidden(false);
    this.router.navigate(['../history'], { relativeTo: this.route });
  }

  navigateToSign() {
    this.router.navigate(['../sign-request'], { relativeTo: this.route });
  }
}
