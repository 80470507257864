<div [formGroup]="form" class="h-100">
  <aw-wizard class="h-100" #wizard
    [awNavigationMode]="navigationMode"
    navBarLocation="left"
    navBarLayout="large-empty-symbols">
    <div class="form-header p-2">
      <h4 *ngIf="!responseCache; else saveTitle" class="font-weight-bold">
        Sign Request Form
        <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Fresno MLS</small></div>
      </h4>
      <ng-template #saveTitle>
        <h4 class="font-weight-bold">
          {{ responseCache.name }}
          <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Updated {{ responseCache.timeUpdated | amTimeAgo }}</small></div>
        </h4>
      </ng-template>
      <img src="assets/images/RClogo.png" class="img-fluid" width="120" alt="Realty Concepts"/>
    </div>
    <div class="form-container card card-body">
      <aw-wizard-step
        stepId="Requests"
        stepTitle="Requests" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">1</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Requests">

          <div class="alert alert-info" role="alert">
            Realty Concepts will coordinate your sign install and removal with the appropriate sign installer, determined by the location of your listing, as we work with several vendors who service areas in the Central Valley. Out of respect for our vendors, we ask that you do not contact them directly. <br>
            Sign installs and removal requests, if ordered by 3:00pm, are typically completed within 24-48 hours. Please note that sign schedules may be altered due to the holidays.
          </div>
          <div class="form-group">
            <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.TYPE.errors }">Request Type*</label>
            <ng-select
              formControlName="TYPE"
              [multiple]="false"
              required>
              <ng-option value="INSTALL">Sign Install</ng-option>
              <ng-option value="REMOVAL">Sign Removal (special circumstances)</ng-option>
              <ng-option value="CHANGE">Change to Existing Sign</ng-option>
              <ng-option value="DAMAGELOSS">Report Sign Damage or Loss</ng-option>
              <ng-option value="OTHER">Other</ng-option>
            </ng-select>
          </div>

          <hr>
          <ng-container *ngIf="form.get('TYPE').value == 'CHANGE'">
            <div class="text-danger">Please note that a fee of $25 will be passed on to the agent for any change requests that require an additional trip to the property (within service boundaries)</div>
          </ng-container>

          <ng-container *ngIf="form.get('TYPE').value != ''">
            <div class="row">
              <div class="form-group col-md-6">
                <label class="form-label text-danger">Street Address*</label>
                <input 
                  type="text"
                  class="form-control"
                  placeholder="123 Example Avenue"
                  formControlName="FULL_ADDRESS"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.FULL_ADDRESS.errors }">
              </div>
              <div class="form-group col-md-3">
                <label class="form-label text-danger">City*</label>
                <input 
                  type="text"
                  class="form-control"
                  placeholder="City"
                  formControlName="CITY"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.CITY.errors }">
              </div>
              <div class="form-group col-md-3">
                <label class="form-label text-danger">Zip Code*</label>
                <input 
                  type="text"
                  class="form-control"
                  placeholder="12345-1234"
                  mask="00000-9999"
                  formControlName="ZIP"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.ZIP.errors }">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label text-danger">Cross Streets*</label>
                <input 
                  type="text"
                  class="form-control"
                  placeholder="123 Example Avenue"
                  formControlName="CROSS_STREETS"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.CROSS_STREETS.errors }">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label class="form-label text-danger">Agent Name*</label>
                <input 
                  type="text"
                  class="form-control"
                  placeholder="John Doe"
                  formControlName="AGENT_NAME"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.AGENT_NAME.errors }">
              </div>
              <div class="form-group col-md-6">
                <label class="form-label text-danger">Phone Number*</label>
                <input 
                  type="text"
                  class="form-control"
                  placeholder="(123) 123-1234"
                  formControlName="PHONE_NUMBER"
                  required
                  mask="(000) 000-0000" 
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.PHONE_NUMBER.errors }">
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="form.get('TYPE').value == 'INSTALL'">
            <div class="row">
              <div class="form-group col-md-10">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.INSTALL_DATE.errors }">Preferred Install Date*</label>
                <input
                  type="text"
                  class="form-control bg-white"
                  mwlFlatpickr
                  placeholder="January 1, 2022"
                  [altInput]="true" 
                  [convertModelValue]="true"
                  [minDate]="tomorrow"
                  formControlName="INSTALL_DATE"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.INSTALL_DATE.errors }">
              </div>
              <div class="form-group col-md-2">
                <label class="form-label text-danger">Quantity (max 5)*</label>
                <input 
                  type="number"
                  class="form-control"
                  max="5"
                  placeholder="1"
                  formControlName="QUANTITY"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.QUANTITY.errors }">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.RIDERS.errors }">Additional Riders Needed?*</label>
                <ng-select
                  formControlName="RIDERS"
                  [multiple]="false"
                  required>
                  <ng-option value="APPOINTMENT">Appointment Only</ng-option>
                  <ng-option value="POOL">Pool</ng-option>
                  <ng-option value="NONE">None</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.MAPPABLE.errors }">Is this listing land, farm, or lot?*</label>
                <ng-select
                  formControlName="MAPPABLE"
                  [multiple]="false"
                  required>
                  <ng-option value="YES">Yes</ng-option>
                  <ng-option value="NO">No</ng-option>
                </ng-select>
              </div>

              <div *ngIf="f.MAPPABLE.value === 'YES'" class="w-100">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.LOCATION.errors }">Select location on map</label>
                <small class="mx-2">(Interact/click with the map below to provide an accurate location of the sign.)</small>
                <div style="height: 500px;" class="w-100 mx-2" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)" (leafletClick)="dropMarker($event)"></div>
                <input 
                  type="text"
                  class="form-control d-none"
                  placeholder="123 Example Avenue"
                  formControlName="LOCATION"
                  required>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label">Comments/Others</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Flyer boxes, lights, etc..."
                  formControlName="COMMENTS"></textarea>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="form.get('TYPE').value == 'REMOVAL'">
            <div class="row">
              <div class="form-group col-md-10">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.REMOVAL_DATE.errors }">Preferred Removal Date*</label>
                <input
                  type="text"
                  class="form-control bg-white"
                  mwlFlatpickr
                  placeholder="January 1, 2022"
                  [altInput]="true" 
                  [convertModelValue]="true"
                  [minDate]="tomorrow"
                  formControlName="REMOVAL_DATE"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.REMOVAL_DATE.errors }">
              </div>
              <div class="form-group col-md-2">
                <label class="form-label text-danger">Quantity (max 5)*</label>
                <input 
                  type="number"
                  class="form-control"
                  max="5"
                  placeholder="1"
                  formControlName="QUANTITY"
                  required
                  [ngClass]="{ 'is-invalid alert-danger': submitted && f.QUANTITY.errors }">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label">Comments/Others</label> <br>
                <small>If sign has already been removed and needs to be picked up from a specified location, please provide details</small>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Details about removal..."
                  formControlName="COMMENTS"></textarea>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="form.get('TYPE').value == 'CHANGE'">
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.RIDERS.errors }">Additional Riders Needed?*</label>
                <ng-select
                  formControlName="RIDERS"
                  [multiple]="false"
                  required>
                  <ng-option value="APPOINTMENT">Appointment Only</ng-option>
                  <ng-option value="POOL">Pool</ng-option>
                  <ng-option value="PENDING">Pending</ng-option>
                  <ng-option value="SOLD">Sold</ng-option>
                  <ng-option value="NONE">None</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label">Comments/Other</label> <br>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Details about change (change of location, add sign rider, etc...)"
                  formControlName="COMMENTS"></textarea>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="form.get('TYPE').value == 'DAMAGELOSS'">
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.REPLACEREMOVE.errors }">Replace or remove sign?*</label>
                <ng-select
                  formControlName="REPLACEREMOVE"
                  [multiple]="false"
                  required>
                  <ng-option value="REPLACE">Replace</ng-option>
                  <ng-option value="REMOVE">Remove</ng-option>
                  <ng-option value="DONOTHING">Do Nothing</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.DAMAGELOSS.errors }">Please explain what happened to the sign</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Details about sign damage/loss..."
                  formControlName="DAMAGELOSS"></textarea>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="form.get('TYPE').value == 'OTHER'">
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label">Comments/Other</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Details/notes..."
                  formControlName="COMMENTS"></textarea>
              </div>
            </div>
          </ng-container>


        </div>
      </aw-wizard-step>

      <aw-wizard-step 
        stepTitle="Review" (stepEnter)="validate()" awOptionalStep class="h-100">
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">7</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <ng-container *ngIf="this.errorList.length === 0; else notValidForm">
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-success">All required fields completed!</div>
              <div class="font-weight-light mb-2">Please review to make sure all information is correct and up-to-date before submitting.</div>
              <button class="btn btn-lg btn-primary w-100" (click)="submit()">Submit</button>
            </div>
          </div>
        </ng-container>
        <ng-template #notValidForm>
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-danger">Missing required fields!</div>
              <div class="font-weight-light mb-2">Please review below to make sure all required fields are completed.</div>
              <div class="card overflow-auto p-2 my-2" style="height: 250px;">
                <ul *ngFor="let error of errorList" class="list-group list-group-flush">
                  <li class="d-flex justify-content-between align-items-center list-group-item">{{error.key}}<button class="btn btn-sm btn-secondary" (click)="goToError(error)">Go to</button></li>
                </ul>
              </div>
              <button class="btn btn-lg btn-primary w-100" disabled>Submit</button>
            </div>
          </div>
        </ng-template>
      </aw-wizard-step>
    </div>
    <div class="form-footer p-2">
      <button type="button" class="btn btn-secondary mx-1" (click)="save()">Save</button>
      <div class="btn-group mx-1">
        <button type="button" class="btn btn-secondary" awPreviousStep>Previous</button>
        <button type="button" class="btn btn-secondary" awNextStep>Next</button>
      </div>
    </div>
  </aw-wizard>
</div>

<swal
  #submitSuccess
  title="Submission Sent"
  text="Your response was submitted successfully!"
  icon="success"
  (confirm)="complete()">
</swal>

<swal
  #submitError
  title="Submission Error"
  text="An error occurred during submission, please try again!"
  icon="error">
</swal>