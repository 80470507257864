<ng-container *ngIf="response; else loading">
  <div class="modal-header">
     <h5 class="modal-title d-flex flex-column">
        <span>{{ formName }} - {{ response.data.FULL_ADDRESS }}</span>
        <small class="text-muted">{{ response.user.email || 'N/A'}} - updated {{ response.timeUpdated | amTimeAgo }}</small>
     </h5>
     <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="printable-form" [formGroup]="form">
      <div id="Listing Information Form">
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between pt-1">
            <label for="selCountyComboBox" class= "reqtext pr-2 py-2">*County:</label>
            <select id="selCountyComboBox" formControlName="County_ComboBox" class="form-control col-md-8" required>
                <option value="Fresno" selected>Fresno</option>
                <option value="Kern">Kern</option>
                <option value="Kings">Kings</option>
                <option value="Madera">Madera</option>
                <option value="Mariposa">Mariposa</option>
                <option value="Merced">Merced</option>
                <option value="Mono">Mono</option>
                <option value="Orange">Orange</option>
                <option value="Placer">Placer</option>
                <option value="Riverside">Riverside</option>
                <option value="San Bernardino">San Bernardino</option>
                <option value="San Joaquin">San Joaquin</option>
                <option value="Santa Clara">Santa Clara</option>
                <option value="Stanislaus">Stanislaus</option>
                <option value="Tehama">Tehama</option>
                <option value="Tulare">Tulare</option>
                <option value="Tuolumne">Tuolumne</option>
                <option value="Ventura">Ventura</option>
                <option value="Out of Area">Out of Area</option>
                <option value="Unknown">Unknown</option>
                <option value="Alameda">Alameda</option>
                <option value="Alpine">Alpine</option>
                <option value="Amador">Amador</option>
                <option value="Butte">Butte</option>
                <option value="Calaveras">Calaveras</option>
                <option value="Colusa">Colusa</option>
                <option value="Contra Costa">Contra Costa</option>
                <option value="Del Norte">Del Norte</option>
                <option value="El Dorado">El Dorado</option>
                <option value="Glenn">Glenn</option>
                <option value="Humboldt">Humboldt</option>
                <option value="Imperial">Imperial</option>
                <option value="Inyo">Inyo</option>
                <option value="Lake">Lake</option>
                <option value="Lassen">Lassen</option>
                <option value="Los Angeles">Los Angeles</option>
                <option value="Marin">Marin</option>
                <option value="Mendocino">Mendocino</option>
                <option value="Modoc">Modoc</option>
                <option value="Monterey">Monterey</option>
                <option value="Napa">Napa</option>
                <option value="Nevada">Nevada</option>
                <option value="Plumas">Plumas</option>
                <option value="Sacramento">Sacramento</option>
                <option value="San Benito">San Benito</option>
                <option value="San Diego">San Diego</option>
                <option value="San Francisco">San Francisco</option>
                <option value="San Luis Obispo">San Luis Obispo</option>
                <option value="San Mateo">San Mateo</option>
                <option value="Santa Barbara">Santa Barbara</option>
                <option value="Santa Cruz">Santa Cruz</option>
                <option value="Shasta">Shasta</option>
                <option value="Sierra">Sierra</option>
                <option value="Siskiyou">Siskiyou</option>
                <option value="Solano">Solano</option>
                <option value="Sonoma">Sonoma</option>
                <option value="Sutter">Sutter</option>
                <option value="Trinity">Trinity</option>
                <option value="Yolo">Yolo</option>
                <option value="Yuba">Yuba</option>
            </select>			
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="APN_1" class="reqtext pr-2 py-2">*APN:</label>
            <div class="d-flex">
              <input type="text" id="APN_1" formControlName="APN_1" class="form-control" size="3" maxlength="3" required>
              <label for="APN_2">&nbsp;-&nbsp;</label>
              <input type="text" id="APN_2" formControlName="APN_2" class="form-control" size="3" maxlength="3" required>
              <label for="APN_3">&nbsp;-&nbsp;</label>
              <input type="text" id="APN_3" formControlName="APN_3" class="form-control" size="3" maxlength="3" required>
            </div>
          </div>
        </div>
  
  
        <div class="row fixcheck form-inline">
          <div class="form-check col-12 justify-content-end">
            <label class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="New_Construction" formControlName="New_Construction" class="form-check-input">New / Under Contruction / NA</label>
          </div>
        </div>
  
  
        <!---->
        <h6 class="subtitle p-1">Address</h6>
        <input type="text" formControlName="FULL_ADDRESS" class="d-none form-control">
        <!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Number" class= "reqtext pr-2 py-2">*Street # - Modifier:</label>
            <div class="d-flex">
              <input type="text" id="Street_Number" formControlName="Street_Number" class="form-control" size="6" maxlength="6" required>
              <label for="Street_Number_Modifier">-</label>
              <input type="text" id="Street_Number_Modifier" formControlName="Street_Number_Modifier" class="form-control" size="6" maxlength="8">
            </div>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Direction" class="nonreqtext pr-2 py-2">Direction:</label>
            <select id="Street_Direction" formControlName="Street_Direction" class="form-control col-md-6 col-lg-8">
              <option value="" selected="">  </option>
              <option value="E"> East </option>
              <option value="N"> North </option>
              <option value="NE"> Northeast </option>
              <option value="NW"> Northwest </option>
              <option value="S"> South </option>
              <option value="SE"> Southeast </option>
              <option value="SW"> Southwest </option>
              <option value="W"> West </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Address" class= "reqtext pr-2 py-2">*Street Name:</label>
            <input type="text" id="Street_Address" formControlName="Street_Address" class="form-control col-sm-8" size="" maxlength="30" required>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Suffix" class="nonreqtext pr-2 py-2">Suffix:</label>
            <select id="Street_Suffix"  formControlName="Street_Suffix" class="form-control col-md-6 col-lg-8">
                <option value="" selected=""> </option>
                <option value="Ave"> Avenue </option>
                <option value="Blvd"> Boulevard </option>
                <option value="Cir"> Circle </option>
                <option value="Ct"> Court </option>
                <option value="Dr"> Drive </option>
                <option value="Jct"> Junction </option>
                <option value="Ln"> Lane </option>
                <option value="Pass"> Pass </option>
                <option value="Pkwy"> Parkway </option>
                <option value="Pl"> Place </option>
                <option value="Rd"> Road </option>
                <option value="Sqr"> Square </option>
                <option value="St"> Street </option>
                <option value="Way"> Way </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 offset-md-6 justify-content-between">
            <label for="Post_Direction" class="nonreqtext pr-2 py-2">Post Direction:</label>
            <select id="Post_Direction" formControlName="Post_Direction" class="form-control col-md-6 col-lg-8">
                <option value="" selected="">  </option>
                <option value="E"> East </option>
                <option value="N"> North </option>
                <option value="NE"> Northeast </option>
                <option value="NW"> Northwest </option>
                <option value="S"> South </option>
                <option value="SE"> Southeast </option>
                <option value="SW"> Southwest </option>
                <option value="W"> West </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Unit_Number" class="nonreqtext pr-2 py-2">Unit #:</label>
            <input type="text" id="Unit_Number" formControlName="Unit_Number" class="form-control col-md-8" size="6" maxlength="5">
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="City_Fill_In" class= "reqtext pr-2 py-2">*City:</label>
            <input type="text" id="City_Fill_In" formControlName="City_Fill_In" size="16" maxlength="20" class="form-control col-sm-8" required>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Zip_Code" class="reqtext pr-2 py-2">*Zip Code:</label>
            <div class="d-flex">
              <input type="text" id="Zip_Code" formControlName="Zip_Code" class="form-control" size="5" maxlength="5" required>
              <label for="Zip_Plus_4">
              -</label>
                <input type="text" id="Zip_Plus_4" formControlName="Zip_Plus_4" class="form-control" size="4" maxlength="4">
            </div>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Cross_Street" class="reqtext pr-2 py-2">
            *Cross Street:</label>
              <input type="text" id="Cross_Street" formControlName="Cross_Street" class="form-control col-sm-8" size="16" maxlength="30" required>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="txtOfficeAuto" class= "reqtext pr-2 py-2">
            *Office ID:</label>
            <select id="Office_ID" formControlName="Office_ID" class="form-control col-sm-8" required>
                <option value=""> </option>
                <option value="630">630-Fresno</option>
                <option value="63004">63004-Clovis</option>
                <option value="63005">63005-Commercial</option>
                <option value="63006">63006-Oakhurst2</option>
            </select>
            <input type="text" id="txtOfficeAuto" formControlName="Office_MLS_ID" class="d-none form-control col-sm-8" size="40" maxlength="50" required>
          </div>
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Agent_MLS_ID" class= "reqtext pr-2 py-2">
            *Agent ID:</label>
              <input type="text" id="Agent_MLS_ID" formControlName="Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" required>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Co_Office_MLS_ID" class= "nonreqtext pr-2 py-2">
            Co-Office ID:</label>
              <input type="text" id="Co_Office_MLS_ID" formControlName="Co_Office_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50">
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Co_Agent_MLS_ID" class= "nonreqtext pr-2 py-2">
            Co-Agent ID:</label>
              <input type="text" id="Co_Agent_MLS_ID" formControlName="Co_Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50">
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="row pb-2">
          <h6 class="col reqtext">*Property Subtypes (select all that apply):</h6>
        </div>
  
  
        <div class="row form-inline">
  
          <div class="form-group col-md-4 justify-content-between">
            <label for="Prop_Subtype_0001" class= "nonreqtext pr-2 py-2">Primary:</label>
            <select id="Prop_Subtype_0001" formControlName="Prop_Subtype_0001" class="form-control">
              <option value=""></option>
              <option value="DPLX">Duplex</option>
              <option value="TRIX">Triplex</option>
              <option value="FORX">Quadruplex</option>
                          <option value="55T1">5 to 15 Units</option>
                          <option value="11UP">16 Units & More</option>
            </select>
          </div>
  
          <div class="form-group col-md-4 justify-content-between">
            <label for="Prop_Subtype_0002" class= "nonreqtext pr-2 py-2">Secondary:</label>
            <select id="Prop_Subtype_0002" formControlName="Prop_Subtype_0002" class="form-control">
              <option value=""></option>
              <option value="DPLX">Duplex</option>
              <option value="TRIX">Triplex</option>
              <option value="FORX">Quadruplex</option>
                          <option value="55T1">5 to 15 Units</option>
                          <option value="11UP">16 Units & More</option>
            </select>
          </div>
  
          <div class="form-group col-md-4 justify-content-between">
            <label for="Prop_Subtype_0003" class= "nonreqtext pr-2 py-2">Other:</label>
            <select id="Prop_Subtype_0003" formControlName="Prop_Subtype_0003" class="form-control">
              <option value=""></option>
              <option value="DPLX">Duplex</option>
              <option value="TRIX">Triplex</option>
              <option value="FORX">Quadruplex</option>
                          <option value="55T1">5 to 15 Units</option>
                          <option value="11UP">16 Units & More</option>
            </select>
          </div>
  
        </div>
  
  
              <br>
  
  
        <div class="row form-inline justify-content-between">
          <div class="form-group col-md-4 justify-content-between">
            <label for="NUM_TTLU" class="reqtext pr-2 py-2">*Total Units:</label>
                      <input type="text" id="NUM_TTLU" formControlName="TTLU" class="form-control" size="4" maxlength="4" value="" required>
          </div>
                  <div class="form-group col-md-6 justify-content-end">
            <input type="text" id="0050TTLU" formControlName="0050TTLU" class="form-control col-sm-8" size="20" maxlength="40" value="" placeholder="comment...">
                  </div>
              </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-md-4 justify-content-between">
            <label for="Transaction" class= "reqtext pr-2 py-2">*Transaction Type:</label>
            <select id="Transaction" formControlName="Transaction" class="form-control" required>
                <option value="S" selected="">Sale</option>
                <option value="L">Lease</option>
            </select>
          </div>
          <div class="form-group col-md-4 justify-content-between">
            <label for="CB_SSLE" class= "reqtext pr-2 py-2">*Short Sale:</label>
            <select id="CB_SSLE" formControlName="SSLE" class="form-control" required>
                <option value=""> </option>
                <option value="YESS">Yes</option>
                <option value="NONO">No</option>
            </select>
          </div>
          <div class="form-group col-md-4 justify-content-between">
            <label for="CB_REBK" class= "reqtext pr-2 py-2">*REO/Bank Owned:</label>
            <select id="CB_REBK" formControlName="REBK" class="form-control" required>
                <option value=""> </option>
                <option value="YESS">Yes</option>
                <option value="NONO">No</option>
            </select>
          </div>
        </div>
  
  
              <br>
  
        <ng-container *ngIf="form.get('Transaction').value == 'L'">
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Lease_Price" class= "reqtext pr-2 pb-2 ">*Lease (monthly):</label>
            <input type="text" id="Lease_Price" formControlName="Lease_Price" class="form-control col-md-8" size="10" maxlength="10" required>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Lease_Type" class="nonreqtext pr-2 py-2">Lease Type:</label>
            <select id="Lease_Type"  formControlName="Lease_Type" class="form-control col-md-8">
                <option value="" selected="">  </option>
                <option value="G"> Gross </option>
                <option value="N"> Net </option>
                <option value="O"> Other </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Sub_Lease" class="nonreqtext pr-2 py-2">Sub-Lease:</label>
            <select id="Sub_Lease" formControlName="Sub_Lease" class="form-control">
                <option value="" selected=""> </option>
                <option value="Y"> Yes </option>
                            <option value="N"> No </option>
            </select>
            <label for="Available_Date" class="nonreqtext pr-2 py-2">Available Date:</label>
            <input type="text" id="Available_Date" formControlName="Available_Date" class="form-control" size="10" maxlength="8" value="">
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Lease_Expiration" class="nonreqtext pr-2 py-2">Sub-Lease Expiration:</label>
            <input type="text" id="Lease_Expiration" formControlName="Lease_Expiration" class="form-control" size="9" maxlength="8" value="">
          </div>
        </div>
        </ng-container>
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="d-flex row form-inline">
          <div class="col-sm-6">
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="CB_APNS" class= "nonreqtext pr-2 py-2">Additional APNs:</label>
              </div>
              <div class="form-group col-sm-4">
                <select id="CB_APNS" formControlName="APNS" class="form-control">
                <option value=" "></option>
                <option class="mBlackText" value="YESS">Yes</option>
                <option class="mBlackText" value="NONO">No</option>
                </select>			
              </div>
            </div>
          </div>
        </div>
  
  
        <div class="d-flex form-inline row">
  
          <!-- Left Column-->
          <div class="col-md-6 align-self-start">
  
  
            <!-- One row for an input -->
            <div class="row">
                
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="0120APN2" class="nonreqtext pr-2 py-2 col-form-label">APN #2:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0120APN2" formControlName="0120APN2" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
            <!-- One row for an input -->
            <div class="row">
                
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="0130APN3" class="nonreqtext pr-2 py-2 col-form-label">APN #3:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0130APN3" formControlName="0130APN3" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
          <!--End of left column-->
          </div>
  
  
          <div class="col-md-6 align-self-start">
          <!--Right Column-->
  
  
            <!-- One row for an input -->
            <div class="row">
                
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="0140APN4" class="nonreqtext pr-2 py-2 col-form-label">APN #4:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0140APN4" formControlName="0140APN4" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="0150APN5" class="nonreqtext pr-2 py-2 col-form-label">APN #5:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0150APN5" formControlName="0150APN5" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
          <!--End of right column-->
          </div>
  
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-md-5 justify-content-between">
            <label for="Listing_Price_1" class= "reqtext pr-2 py-2">Listing Price:</label>
            <div class="d-flex">
              <input type="text" id="Listing_Price_1" formControlName="Listing_Price_1" class="form-control" size="6" maxlength="6" value="" required>
              <label for="Listing_Price_2">&nbsp;,&nbsp;</label>
              <input type="text" id="Listing_Price_2" formControlName="Listing_Price_2" class="form-control" size="3" maxlength="3" value="000">
            </div>
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Agreement_Type_ComboBox" class="reqtext pr-2 py-2">*Agreement Type:</label>
            <select id="Agreement_Type_ComboBox" formControlName="Agreement_Type_ComboBox" class="form-control col-sm-5" required>
                <option class="mBlackText" value="" selected=""> </option>
                <option class="mBlackText" value="ERTS"> Excl. Right to Sell </option>
                <option class="mBlackText" value="EXAG"> Exclusive Agency </option>
                <option class="mBlackText" value="OPEN"> Open </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="CB_SCOP" class="nonreqtext pr-2 py-2">Scope of Service:</label>
            <select id="CB_SCOP" formControlName="SCOP" class="form-control col-sm-5">
                <option value=" "> </option>
                <option class="mBlackText" value="ENTY">Entry Only </option>
                <option class="mBlackText" value="LMTD">Limited Service </option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="0255SCOP" formControlName="0255SCOP" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="CB_DUAL" class="nonreqtext pr-2 py-2">Dual Agency:</label>
            <select id="CB_DUAL" formControlName="DUAL" class="form-control col-sm-3">
              <option value=""> </option>
              <option class="mBlackText" value="YES">Yes</option>
              <option class="mBlackText" value="NO">No</option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="0240DUAL" formControlName="0240DUAL" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Publish_To_Internet" class="nonreqtext pr-2 py-2">Public Viewable:</label>
            <select id="Publish_To_Internet" formControlName="Publish_To_Internet" class="form-control px-sm-0 px-md-3 col-sm-3">
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Show_AVM_On_VOW" class="reqtext pr-2 py-2">*Show AVM on VOW:</label>
            <select id="Show_AVM_On_VOW" formControlName="Show_AVM_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" required>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Show_Address_to_Public" class="reqtext pr-2 py-2">*Show Addr to Public:</label>
            <select id="Show_Address_to_Public" formControlName="Show_Address_to_Public" class="form-control px-sm-0 px-md-3 col-sm-3" required>
                <option value="yes" selected="">Yes</option>
                <option value="no">No</option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Show_Comments_On_VOW" class="reqtext pr-2 py-2">*Show Cmnts on VOW:</label>
            <select id="Show_Comments_On_VOW" formControlName="Show_Comments_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
    
  
        <!--Begin Section-->
        <div class="d-flex form-inline row">
  
          <!--Begin column-->
          <div class="col-lg-5">
  
            <!--entries-->
            <div class="row">
  
              <div class="form-group col-sm-6">
                <label for="CB_EOFF" class="reqtext pr-2 py-2 col-form-label">*Email Offers:</label>
              </div>
  
              <div class="form-group col-sm-4 offset-sm-2 pr-xs-0">
                <select id="CB_EOFF" formControlName="EOFF" class="form-control col-sm-8" required>
                  <option value=""></option>
                  <option class="mBlackText" value="YESY"> Yes </option>
                  <option class="mBlackText" value="NONO"> No </option>
                </select>
              </div>
  
            </div>
  
          </div>
  
  
          <!--Begin column-->
          <div class="col-lg-7">
            <!--entries-->
            <div class="row">
  
              <div class="form-group col-sm-3 justify-content-end">
                <label for="0400EOAD" class="reqtext pr-2 py-2 col-form-label">*Email:</label>
              </div>
  
              <div class="form-group col-sm-9 justify-content-between">
                <input type="email" id="0400EOAD" formControlName="0400EOAD" class="form-control col-sm-12" rows="1" cols="40" maxlength="60">
              </div>
  
            </div>
  
          </div>
  
        </div> <!--end of section-->
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <!---->
        <h6 class="subtitle pr-1 py-2">Listing Period:</h6>
        <!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Listing_Date" class="reqtext pr-2 py-2">*Listing Date:</label>
            <input type="text" id="Listing_Date" formControlName="Listing_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" required>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Expiration_Date" class="nonreqtext pr-2 py-2">Expiration Date:</label>
            <input type="text" id="Expiration_Date" formControlName="Expiration_Date" class="form-control col-sm-3" size="8" maxlength="8" value="">						
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Owner_Name" class= "nonreqtext pr-2 py-2">Owner Name:</label>
            <input type="text" id="Owner_Name" formControlName="Owner_Name" class="form-control col-sm-8" size="30" maxlength="50" value="">
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Owner_Phone_Area" class="nonreqtext pr-2 py-2">Owner Phone #:</label>
            <div class="d-flex">
              <input type="text" id="Owner_Phone_Area" formControlName="Owner_Phone_Area" class="form-control" size="3" maxlength="3" value="">
              <label for="Owner_Phone_Prefix">&nbsp;-&nbsp;</label>
              <input type="text" id="Owner_Phone_Prefix" formControlName="Owner_Phone_Prefix" class="form-control" size="3" maxlength="3" value="">
              <label for="Owner_Phone_Suffix">&nbsp;-&nbsp;</label>
              <input type="text" id="Owner_Phone_Suffix" formControlName="Owner_Phone_Suffix" class="form-control" size="4" maxlength="4" value="">
            </div>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Occupant_Type" class="reqtext pr-2 py-2">*Occupant Type:</label>
            <select id="Occupant_Type" formControlName="Occupant_Type" class="form-control col-sm-3" required>
                <option value="" selected="">  </option>
                <option value="C"> Caretaker </option>
                <option value="O"> Owner </option>
                <option value="T"> Tenant </option>
                <option value="V"> Vacant </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Occupant_Name" class= "nonreqtext pr-2 py-2">Occupant Name:</label>
            <input type="text" id="Occupant_Name" formControlName="Occupant_Name" class="form-control col-sm-8" size="30" maxlength="30" value="">
            </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Phone_to_Show_Area" class="nonreqtext pr-2 py-2">Phone to Show:</label>
            <div class="d-flex">
              <input type="text" id="Phone_to_Show_Area" formControlName="Phone_to_Show_Area" class="form-control" size="3" maxlength="3" value="">
              <label for="Phone_to_Show_Prefix">&nbsp;-&nbsp;</label>
              <input type="text" id="Phone_to_Show_Prefix" formControlName="Phone_to_Show_Prefix" class="form-control" size="3" maxlength="3" value="">
              <label for="Phone_to_Show_Suffix">&nbsp;-&nbsp;</label>
              <input type="text" id="Phone_to_Show_Suffix" formControlName="Phone_to_Show_Suffix" class="form-control" size="4" maxlength="4" value="">
            </div>
          </div>
        </div>
  
  
        <br>
      </div>
      <div id="Basic Information Form">
        <div class="d-flex form-inline row">

          <!-- Left Column-->
          <div class="col-md-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="Bedrooms_ComboBox" class="reqtext pr-2 py-2">*Bedroom(s):</label>
              </div>
              <div class="form-group col-sm-3">
                <select id="Bedrooms_ComboBox" formControlName="Bedrooms_ComboBox" class="form-control col-sm-10" required>
                  <option value="" selected=""> </option>
                  <option value="  0.00">0 </option>
                  <option value="  1.00">1 </option>
                  <option value="  2.00">2 </option>
                  <option value="  3.00">3 </option>
                  <option value="  4.00">4 </option>
                  <option value="  5.00">5 </option>
                  <option value="  6.00">6 </option>
                  <option value="  7.00">7 </option>
                  <option value="  8.00">8 </option>
                  <option value="  9.00">9 </option>
                  <option value=" 10.00">10 </option>
                  <option value=" 11.00">11 </option>
                  <option value=" 12.00">12 </option>
                  <option value=" 13.00">13 </option>
                  <option value=" 14.00">14 </option>
                  <option value=" 15.00">15 </option>
                  <option value=" 16.00">16 </option>
                  <option value=" 17.00">17 </option>
                  <option value=" 18.00">18 </option>
                  <option value=" 19.00">19 </option>
                  <option value=" 20.00">20 </option>
                  <option value=" 21.00">21 </option>
                  <option value=" 22.00">22 </option>
                  <option value=" 23.00">23 </option>
                  <option value=" 24.00">24 </option>
                  <option value=" 25.00">25 </option>
                  <option value=" 26.00">26 </option>
                  <option value=" 27.00">27 </option>
                  <option value=" 28.00">28 </option>
                  <option value=" 29.00">29 </option>
                  <option value=" 30.00">30 </option>
                  <option value=" 31.00">31 </option>
                  <option value=" 32.00">32 </option>
                  <option value=" 33.00">33 </option>
                  <option value=" 34.00">34 </option>
                  <option value=" 35.00">35 </option>
                  <option value=" 36.00">36 </option>
                  <option value=" 37.00">37 </option>
                  <option value=" 38.00">38 </option>
                  <option value=" 39.00">39 </option>
                  <option value=" 40.00">40 </option>
                  <option value=" 41.00">41 </option>
                  <option value=" 42.00">42 </option>
                  <option value=" 43.00">43 </option>
                  <option value=" 44.00">44 </option>
                  <option value=" 45.00">45 </option>
                  <option value=" 46.00">46 </option>
                  <option value=" 47.00">47 </option>
                  <option value=" 48.00">48 </option>
                  <option value=" 49.00">49 </option>
                  <option value=" 50.00">50 </option>
                  <option value=" 51.00">51 </option>
                  <option value=" 52.00">52 </option>
                  <option value=" 53.00">53 </option>
                  <option value=" 54.00">54 </option>
                  <option value=" 55.00">55 </option>
                  <option value=" 56.00">56 </option>
                  <option value=" 57.00">57 </option>
                  <option value=" 58.00">58 </option>
                  <option value=" 59.00">59 </option>
                  <option value=" 60.00">60 </option>
                  <option value=" 61.00">61 </option>
                  <option value=" 62.00">62 </option>
                  <option value=" 63.00">63 </option>
                  <option value=" 64.00">64 </option>
                  <option value=" 65.00">65 </option>
                  <option value=" 66.00">66 </option>
                  <option value=" 67.00">67 </option>
                  <option value=" 68.00">68 </option>
                  <option value=" 69.00">69 </option>
                  <option value=" 70.00">70 </option>
                  <option value=" 71.00">71 </option>
                  <option value=" 72.00">72 </option>
                  <option value=" 73.00">73 </option>
                  <option value=" 74.00">74 </option>
                  <option value=" 75.00">75 </option>
                  <option value=" 76.00">76 </option>
                  <option value=" 77.00">77 </option>
                  <option value=" 78.00">78 </option>
                  <option value=" 79.00">79 </option>
                  <option value=" 80.00">80 </option>
                  <option value=" 81.00">81 </option>
                  <option value=" 82.00">82 </option>
                  <option value=" 83.00">83 </option>
                  <option value=" 84.00">84 </option>
                  <option value=" 85.00">85 </option>
                  <option value=" 86.00">86 </option>
                  <option value=" 87.00">87 </option>
                  <option value=" 88.00">88 </option>
                  <option value=" 89.00">89 </option>
                  <option value=" 90.00">90 </option>
                </select>
              </div>
            </div>
          </div> <!--end of left column-->

        <!--Right Column-->
        <div class="col-md-6 align-self-start">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="Bathrooms_ComboBox" class="reqtext pr-2 py-2">*Bathrooms:</label>
            </div>
            <div class="form-group col-sm-3">
              <select id="Bathrooms_ComboBox" formControlName="Bathrooms_ComboBox" class="form-control col-sm-10" required>
                <option value="" selected="">  </option>
                <option value="  0.00"> 0 </option>
                <option value="  1.00"> 1 </option>
                <option value="  1.50"> 1 1/2 </option>
                <option value="  1.75"> 1 3/4 </option>
                <option value="  2.00"> 2 </option>
                <option value="  2.50"> 2 1/2 </option>
                <option value="  2.75"> 2 3/4 </option>
                <option value="  3.00"> 3 </option>
                <option value="  3.50"> 3 1/2 </option>
                <option value="  3.75"> 3 3/4 </option>
                <option value="  4.00"> 4 </option>
                <option value="  4.50"> 4 1/2 </option>
                <option value="  4.75"> 4 3/4 </option>
                <option value="  5.00"> 5 </option>
                <option value="  6.00"> 6 </option>
                <option value="  7.00"> 7 </option>
                <option value="  8.00"> 8 </option>
                <option value="  9.00"> 9 </option>
                <option value=" 10.00"> 10 </option>
                <option value=" 11.00"> 11 </option>
                <option value=" 12.00"> 12 </option>
                <option value=" 13.00"> 13 </option>
                <option value=" 14.00"> 14 </option>
                <option value=" 15.00"> 15 </option>
                <option value=" 16.00"> 16 </option>
                <option value=" 17.00"> 17 </option>
                <option value=" 18.00"> 18 </option>
                <option value=" 19.00"> 19 </option>
                <option value=" 20.00"> 20 </option>
                <option value=" 21.00"> 21 </option>
                <option value=" 22.00"> 22 </option>
                <option value=" 23.00"> 23 </option>
                <option value=" 24.00"> 24 </option>
                <option value=" 25.00"> 25 </option>
                <option value=" 26.00"> 26 </option>
                <option value=" 27.00"> 27 </option>
                <option value=" 28.00"> 28 </option>
                <option value=" 29.00"> 29 </option>
                <option value=" 30.00"> 30 </option>
                <option value=" 31.00"> 31 </option>
                <option value=" 32.00"> 32 </option>
                <option value=" 33.00"> 33 </option>
                <option value=" 34.00"> 34 </option>
                <option value=" 35.00"> 35 </option>
                <option value=" 36.00"> 36 </option>
                <option value=" 37.00"> 37 </option>
                <option value=" 38.00"> 38 </option>
                <option value=" 39.00"> 39 </option>
                <option value=" 40.00"> 40 </option>
                <option value=" 41.00"> 41 </option>
                <option value=" 42.00"> 42 </option>
                <option value=" 43.00"> 43 </option>
                <option value=" 44.00"> 44 </option>
                <option value=" 45.00"> 45 </option>
                <option value=" 46.00"> 46 </option>
                <option value=" 47.00"> 47 </option>
                <option value=" 48.00"> 48 </option>
                <option value=" 49.00"> 49 </option>
                <option value=" 50.00"> 50 </option>
                <option value=" 51.00"> 51 </option>
                <option value=" 52.00"> 52 </option>
                <option value=" 53.00"> 53 </option>
                <option value=" 54.00"> 54 </option>
                <option value=" 55.00"> 55 </option>
                <option value=" 56.00"> 56 </option>
                <option value=" 57.00"> 57 </option>
                <option value=" 58.00"> 58 </option>
                <option value=" 59.00"> 59 </option>
                <option value=" 60.00"> 60 </option>
                <option value=" 61.00"> 61 </option>
                <option value=" 62.00"> 62 </option>
                <option value=" 63.00"> 63 </option>
                <option value=" 64.00"> 64 </option>
                <option value=" 65.00"> 65 </option>
                <option value=" 66.00"> 66 </option>
                <option value=" 67.00"> 67 </option>
                <option value=" 68.00"> 68 </option>
                <option value=" 69.00"> 69 </option>
                <option value=" 70.00"> 70 </option>
                <option value=" 71.00"> 71 </option>
                <option value=" 72.00"> 72 </option>
                <option value=" 73.00"> 73 </option>
                <option value=" 74.00"> 74 </option>
                <option value=" 75.00"> 75 </option>
                <option value=" 76.00"> 76 </option>
                <option value=" 77.00"> 77 </option>
                <option value=" 78.00"> 78 </option>
                <option value=" 79.00"> 79 </option>
                <option value=" 80.00"> 80 </option>
                <option value=" 81.00"> 81 </option>
                <option value=" 82.00"> 82 </option>
                <option value=" 83.00"> 83 </option>
                <option value=" 84.00"> 84 </option>
                <option value=" 85.00"> 85 </option>
                <option value=" 86.00"> 86 </option>
                <option value=" 87.00"> 87 </option>
                <option value=" 88.00"> 88 </option>
                <option value=" 89.00"> 89 </option>
                <option value=" 90.00"> 90 </option>
              </select>
            </div>
          </div>
        </div> <!--end of right column-->

      </div> <!--end of page/section-->


      <div class="d-flex form-inline row">

        <!-- Left Column-->
        <div class="col-md-6 align-self-start">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="Struc_Sq_Ft" class= "reqtext pr-2 py-2">*Aprx Sq Ft </label>
            </div>
            <div class="form-group col-sm-4">
              <input type="text" id="Struc_Sq_Ft" formControlName="Struc_Sq_Ft" class="form-control col-sm-10" size="9" maxlength="9" placeholder="0" required>
            </div>
          </div>
        </div> <!--end of left column-->

        <!--Right Column-->
        <div class="col-md-6 align-self-start">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="selSqFtSource" class="reqtext pr-2 py-2">*Sq Ft Source:</label>
            </div>
            <div class="form-group col-sm-4">
              <select id="selSqFtSource" formControlName="SqFt_Source" class="form-control col-sm-10" required>
                  <option value="" selected="">  </option>
                  <option value="SLLR"> Seller </option>
                  <option value="APPR"> Appraiser </option>
                  <option value="TAXR"> Tax Records </option>
                  <option value="OTHR"> Other </option>
              </select>
            </div>
          </div>
        </div> <!--end of right column-->

      </div> <!--end of page/section-->


      <div class="d-flex form-inline row">

        <!-- Left Column-->
        <div class="col-lg-6 align-self-start">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="Lot_Sq_Ft" class= "nonreqtext pr-2 py-2">Lot Size:</label>
            </div>
            <div class="form-group col-sm-4">
              <input type="text" id="Lot_Sq_Ft" formControlName="Lot_Sq_Ft" class="form-control col-sm-10" size="12" maxlength="9" value="">
            </div>
            <div class="d-flex form-group col-sm-4">
              <label for="Lot_Measurement" class="nonreqtext px-1">
                <input type="radio" id="Lot_Measurement" formControlName="Lot_Measurement" class="form-control" value="S" checked="">&nbsp;SqFt
              </label>
              <label for="Lot_Measurement_Acres" class="nonreqtext px-1">
                <input type="radio" id="Lot_Measurement_Acres" formControlName="Lot_Measurement" class="form-control" value="A">&nbsp;Acres
              </label>
            </div>
          </div>
        </div> <!--end of left column-->

        <!--Right Column-->
        <div class="col-lg-6 align-self-start">
          <div class="row">
            <div class="form-group col-sm-4">
              <label for="selLotSizeSource" class="nonreqtext pr-2 py-2">Lot Size Source:</label>
            </div>
            <div class="form-group col-sm-4">
              <select id="selLotSizeSource" formControlName="LotSize_Source" class="form-control col-sm-10">
                <option value="" selected="">  </option>
                <option value="APPR"> Appraiser </option>
                <option value="OTHR"> Other </option>
                <option value="SLLR"> Seller </option>
                <option value="TAXR"> Tax Records </option>
              </select>
            </div>
          </div>
        </div> <!--end of right column-->


      </div> <!--end of page/section-->


      <div class="d-flex form-inline row">

        <!-- Left Column-->
        <div class="col-md-4 align-self-start">
          <div class="row">
            <div class="form-group col-sm-6">
              <label for="Year_Built" class= "reqtext pr-2 py-2">*Year Built:</label>
            </div>
            <div class="form-group col-sm-6">
              <input type="text" id="Year_Built" formControlName="Year_Built" class="form-control col-sm-10" size="8" maxlength="4" value="" required>
            </div>
          </div>
        </div> <!--end of left column-->

        <!--Right Column-->
        <div class="col-md-6 align-self-start">
          <div class="row">
            <div class="form-group col-sm-5">
              <label for="Unknown_Year" class="nonreqtext p-2">- or (select one) -</label>
            </div>
            <div class="form-group col-sm-6">
              <select id="Unknown_Year" formControlName="Unknown_Year" class="form-control">
                  <option value="0" selected="">  </option>
                  <option value="1"> New Construction </option>
                  <option value="2"> Under Construction </option>
                  <option value="3"> Unknown </option>
              </select>
            </div>
          </div>
        </div> <!--end of right column-->

      </div> <!--end of page/section-->


      <br>


      <div class="row">
        <div class="col">
          <h6><label for="Property_Description"><span class="reqsubtitle pr-1">*Marketing Remarks:</span></label><span class="reqtext formnote">&nbsp;(Maximum 1500)</span></h6>
            <textarea id="Property_Description" formControlName="Property_Description" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1500" required></textarea>
        </div>
      </div>


      <br>


      <div class="row">
        <div class="col">
          <h6><label for="Agent_Only_Remarks"><span class="subtitle pr-1">Agent Remarks:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 1000)</span></h6>
            <textarea id="Agent_Only_Remarks" formControlName="Agent_Only_Remarks" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"></textarea>
        </div>
      </div>


      <br>


      <div class="row">
        <div class="col">
          <h6><label for="Directions"><span class="reqsubtitle pr-1">*Directions to Property:</span></label><span class="reqtext formnote">&nbsp;(Maximum 250)</span></h6>
            <textarea id="Directions" formControlName="Directions" class="form-control" spellcheck="true" rows="4" cols=""  maxlength="250" required></textarea>
        </div>
      </div>


      <br>


      <div class="row form-inline">
        <div class="form-group col-sm-6 justify-content-between">
          <label for="School_District_Fill_In" class= "reqtext pr-2 py-2">*School District:</label>
          <input type="text" id="School_District_Fill_In" formControlName="School_District_Fill_In" class="form-control col-sm-8" size="20" maxlength="20" value="" required>
        </div>

        <div class="form-group col-sm-6 justify-content-between">
          <label for="School_Code_1" class= "reqtext pr-2 py-2">*Grade School:</label>
          <input type="text" id="School_Code_1" formControlName="School_Code_1" class="form-control col-sm-8" size="10" maxlength="20" value="" required>
        </div>
      </div>


      <div class="row form-inline">
        <div class="form-group col-sm-6 justify-content-between">
          <label for="School_Code_2" class= "reqtext pr-2 py-2">*Jr High School :</label>
          <input type="text" id="School_Code_2" formControlName="School_Code_2" class="form-control col-sm-8" size="20" maxlength="20" value="" required>
        </div>

        <div class="form-group col-sm-6 justify-content-between">
          <label for="School_Code_3" class= "reqtext pr-2 py-2">*Sr High School:</label>
          <input type="text" id="School_Code_3" formControlName="School_Code_3" class="form-control col-sm-8" size="20" maxlength="20" value="" required>
        </div>
      </div>


      <div class="row form-inline">
        <div class="form-group col-sm-6 justify-content-between">
          <label for="0610SCMT" class= "nonreqtext pr-1 py-1">Comments:<span class="formnote">&nbsp;(Maximum 60)&nbsp;</span></label>
          <textarea id="0610SCMT" formControlName="0610SCMT" class="form-control col-sm-8" spellcheck="true" rows="2" cols="" maxlength="60"></textarea>
        </div>
      </div>


      <br>
      </div>
      <div id="General Information Form">
        <div class="d-flex form-inline row">


          <!-- Left Column-->
  
  
          <div class="col-lg-6 align-self-start">
            
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="NUM_CNSS" class= "nonreqtext pr-2 py-2 col-form-label">Census Tract:</label>
                <input type="text" id="NUM_CNSS" formControlName="CNSS" class="form-control col-sm-5" size="" maxlength="6">
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0650CNSS" formControlName="0650CNSS" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_AREA" class="reqtext pr-2 py-2 col-form-label">*Region:</label>
                <select id="CB_AREA" formControlName="AREA" class="form-control col-sm-5" required>
                  <option value=""></option>
                  <option class="mBlackText" value="URBN">Urban </option>
                  <option class="mBlackText" value="SUBU">Rural </option>
                  <option class="mBlackText" value="FTHL">Foothill </option>
                  <option class="mBlackText" value="MNTN">Mountain </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="0660AREA" formControlName="0660AREA" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="Area_Fill_In" class="reqtext pr-2 py-2 col-form-label">*Area:</label>
                <input type="text" id="Area_Fill_In" formControlName="Area_Fill_In" class="form-control col-sm-5" size="" maxlength="60" required>
              </div>
            </div>
  
  
            <div class="row form-group col-sm-12">
              <span class="reqtext pr-2 py-2 formnote">AREA refers to the last 3 digits of the property zipcode. Example: 93711. AREA = 711.</span>
            </div>
            
  
            <!--break for next group in the left column-->
            <br>
  
            <div formGroupName="NUMOFSTORIES" required>
              <span class="row col"><span class="pr-2 py-2 reqtext">*# of Stories:&nbsp;<span class="reqtext formnote">(1 entry allowed)</span></span></span>
    
    
              <!-- One row for an input -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="STORSNGL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORSNGL" formControlName="STORSNGL" class="form-check-input">Single Story</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORSNGL" formControlName="Checkbox_Comment_STORSNGL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="STORTWOS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORTWOS" formControlName="STORTWOS" class="form-check-input">Two Story</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORTWOS" formControlName="Checkbox_Comment_STORTWOS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="STORTHRM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORTHRM" formControlName="STORTHRM" class="form-check-input">Three or More</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORTHRM" formControlName="Checkbox_Comment_STORTHRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="STORSPLT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORSPLT" formControlName="STORSPLT" class="form-check-input">Multi/Split</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORSPLT" formControlName="Checkbox_Comment_STORSPLT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
  
            </div>
            <!--break for next group in the left column-->
            <br>
  
            <div formGroupName="ROOFING" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Roofing:</span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="ROOFSHAK" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFSHAK" formControlName="ROOFSHAK" class="form-check-input">Shake</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFSHAK" formControlName="Checkbox_Comment_ROOFSHAK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFWOOD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFWOOD" formControlName="ROOFWOOD" class="form-check-input">Wood</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFWOOD" formControlName="Checkbox_Comment_ROOFWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFCOMP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFCOMP" formControlName="ROOFCOMP" class="form-check-input">Composition</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFCOMP" formControlName="Checkbox_Comment_ROOFCOMP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFROLL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFROLL" formControlName="ROOFROLL" class="form-check-input">Rolled Composition</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFROLL" formControlName="Checkbox_Comment_ROOFROLL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFTLSL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFTLSL" formControlName="ROOFTLSL" class="form-check-input">Tile</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFTLSL" formControlName="Checkbox_Comment_ROOFTLSL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFTRGR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFTRGR" formControlName="ROOFTRGR" class="form-check-input">Tar & Gravel</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFTRGR" formControlName="Checkbox_Comment_ROOFTRGR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFCONC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFCONC" formControlName="ROOFCONC" class="form-check-input">Concrete</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFCONC" formControlName="Checkbox_Comment_ROOFCONC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFMETL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFMETL" formControlName="ROOFMETL" class="form-check-input">Metal</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFMETL" formControlName="Checkbox_Comment_ROOFMETL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFFIBR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFFIBR" formControlName="ROOFFIBR" class="form-check-input">Fiberglass</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFFIBR" formControlName="Checkbox_Comment_ROOFFIBR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="ROOFOTHR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="ROOFOTHR" formControlName="ROOFOTHR" class="form-check-input">Other</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_ROOFOTHR" formControlName="Checkbox_Comment_ROOFOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
            </div>
  
          <!--End of left column-->
        </div>
  
        <!--Right Column-->
  
        <div class="col-lg-6 align-self-start">
          
          <div formGroupName="EXTERIOR" required>
          <span class="row col"><span class="pr-2 py-2 reqtext">*Exterior:</span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="EXTRSTUC" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRSTUC" formControlName="EXTRSTUC" class="form-check-input">Stucco</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRSTUC" formControlName="Checkbox_Comment_EXTRSTUC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTRWOOD" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRWOOD" formControlName="EXTRWOOD" class="form-check-input">Wood</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRWOOD" formControlName="Checkbox_Comment_EXTRWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTRBRIK" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRBRIK" formControlName="EXTRBRIK" class="form-check-input">Brick</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRBRIK" formControlName="Checkbox_Comment_EXTRBRIK" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTRBLCK" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRBLCK" formControlName="EXTRBLCK" class="form-check-input">Block</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRBLCK" formControlName="Checkbox_Comment_EXTRBLCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTRSTON" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRSTON" formControlName="EXTRSTON" class="form-check-input">Stone</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRSTON" formControlName="Checkbox_Comment_EXTRSTON" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTRMETL" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRMETL" formControlName="EXTRMETL" class="form-check-input">Metal</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRMETL" formControlName="Checkbox_Comment_EXTRMETL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTRSHGL" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRSHGL" formControlName="EXTRSHGL" class="form-check-input">Shingle</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRSHGL" formControlName="Checkbox_Comment_EXTRSHGL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTRLOGG" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTRLOGG" formControlName="EXTRLOGG" class="form-check-input">Log</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTRLOGG" formControlName="Checkbox_Comment_EXTRLOGG" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="EXTROTHR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="EXTROTHR" formControlName="EXTROTHR" class="form-check-input">Other</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_EXTROTHR" formControlName="Checkbox_Comment_EXTROTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
          </div>
            <!--break for next group in the right column-->
            <br>
  
            <div formGroupName="SEWERWATER" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Sewer/Water:</span></span>
  
  
            <!-- One row for an input -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="WATRPWTR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="WATRPWTR" formControlName="WATRPWTR" class="form-check-input">Public Water</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_WATRPWTR" formControlName="Checkbox_Comment_WATRPWTR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="WATRDWTR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="WATRDWTR" formControlName="WATRDWTR" class="form-check-input">Domestic Water</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_WATRDWTR" formControlName="Checkbox_Comment_WATRDWTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="WATRPVWT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="WATRPVWT" formControlName="WATRPVWT" class="form-check-input">Private Water</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_WATRPVWT" formControlName="Checkbox_Comment_WATRPVWT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="WATRSWEL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="WATRSWEL" formControlName="WATRSWEL" class="form-check-input">Shared Well</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_WATRSWEL" formControlName="Checkbox_Comment_WATRSWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="WATRCWEL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="WATRCWEL" formControlName="WATRCWEL" class="form-check-input">Community Well</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_WATRCWEL" formControlName="Checkbox_Comment_WATRCWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="WATRPSWR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="WATRPSWR" formControlName="WATRPSWR" class="form-check-input">Public Sewer</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_WATRPSWR" formControlName="Checkbox_Comment_WATRPSWR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="WATRSEPT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="WATRSEPT" formControlName="WATRSEPT" class="form-check-input">Septic Tank</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_WATRSEPT" formControlName="Checkbox_Comment_WATRSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            </div>
            <!--break for next group in the right column-->
            <br>
  
          <div formGroupName="GASELECTRIC" required>
          <span class="row col"><span class="pr-2 py-2 reqtext">*Gas/Electric:</span></span>
  
  
          <!--First row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="GSELPUBU" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="GSELPUBU" formControlName="GSELPUBU" class="form-check-input">Public Utilities</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_GSELPUBU" formControlName="Checkbox_Comment_GSELPUBU" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="GSELNGAS" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="GSELNGAS" formControlName="GSELNGAS" class="form-check-input">Natural Gas</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_GSELNGAS" formControlName="Checkbox_Comment_GSELNGAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="GSELPROP" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="GSELPROP" formControlName="GSELPROP" class="form-check-input">Propane</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_GSELPROP" formControlName="Checkbox_Comment_GSELPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="GSELNOGS" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="GSELNOGS" formControlName="GSELNOGS" class="form-check-input">No Gas</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_GSELNOGS" formControlName="Checkbox_Comment_GSELNOGS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
          </div>
          <!--End of right column-->
        </div>				
      </div>
  
  
      <!-------------------------------------------------------------->
      <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
      <!-------------------------------------------------------------->
  
  
      <div class="d-flex form-inline row">
  
  
        <!-- Left Column-->
        <div class="col-lg-6 align-self-start">
  
  
          <span class="row col"><span class="pr-2 py-2 nonreqtext">Special Disclosure:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="SPECSPSZ" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECSPSZ" formControlName="SPECSPSZ" class="form-check-input">Special Studies Zone</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECSPSZ" formControlName="Checkbox_Comment_SPECSPSZ" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECFLOD" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECFLOD" formControlName="SPECFLOD" class="form-check-input">Flood Zone</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECFLOD" formControlName="Checkbox_Comment_SPECFLOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECTOXI" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECTOXI" formControlName="SPECTOXI" class="form-check-input">Toxic Waste Area</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECTOXI" formControlName="Checkbox_Comment_SPECTOXI" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECSMOK" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECSMOK" formControlName="SPECSMOK" class="form-check-input">Smoke Detectors Req.</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECSMOK" formControlName="Checkbox_Comment_SPECSMOK" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECBLDR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECBLDR" formControlName="SPECBLDR" class="form-check-input">Building Restriction</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECBLDR" formControlName="Checkbox_Comment_SPECBLDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECDEED" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECDEED" formControlName="SPECDEED" class="form-check-input">Deed Restrictions</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECDEED" formControlName="Checkbox_Comment_SPECDEED" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECEASM" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECEASM" formControlName="SPECEASM" class="form-check-input">Easments</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECEASM" formControlName="Checkbox_Comment_SPECEASM" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECCTTX" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECCTTX" formControlName="SPECCTTX" class="form-check-input">City Transfer Tax</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECCTTX" formControlName="Checkbox_Comment_SPECCTTX" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECASSM" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECASSM" formControlName="SPECASSM" class="form-check-input">Assessments</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECASSM" formControlName="Checkbox_Comment_SPECASSM" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECTFEX" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECTFEX" formControlName="SPECTFEX" class="form-check-input">Trust Fund Excluded</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECTFEX" formControlName="Checkbox_Comment_SPECTFEX" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECHOEX" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECHOEX" formControlName="SPECHOEX" class="form-check-input">Home Owner Exemption</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECHOEX" formControlName="Checkbox_Comment_SPECHOEX" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECCALL" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECCALL" formControlName="SPECCALL" class="form-check-input">Call Agent</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECCALL" formControlName="Checkbox_Comment_SPECCALL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECAGOW" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECAGOW" formControlName="SPECAGOW" class="form-check-input">Agent/Owner Related</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECAGOW" formControlName="Checkbox_Comment_SPECAGOW" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECDEPR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECDEPR" formControlName="SPECDEPR" class="form-check-input">Death on Property</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECDEPR" formControlName="Checkbox_Comment_SPECDEPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECFLIT" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECFLIT" formControlName="SPECFLIT" class="form-check-input">Flight Path</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECFLIT" formControlName="Checkbox_Comment_SPECFLIT" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECOTHR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECOTHR" formControlName="SPECOTHR" class="form-check-input">Other</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECOTHR" formControlName="Checkbox_Comment_SPECOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SPECSEER" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SPECSEER" formControlName="SPECSEER" class="form-check-input">See Remrks/Attchmnts</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SPECSEER" formControlName="Checkbox_Comment_SPECSEER" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <div class="p-2 showbreak">&nbsp;</div>
            </div>
          </div>
  
  
        </div> <!--end of left column-->
  
  
        <!--Right Column-->
  
  
        <div class="col-lg-6 align-self-start">
  
  
          <span class="row col"><span class="pr-2 py-2 nonreqtext">Documents Available:&nbsp;<span class="nonreqtext formnote">(Up to 9 entries allowed)</span></span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="DILOROOF" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOROOF" formControlName="DILOROOF" class="form-check-input">Roof Inspection</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOROOF" formControlName="Checkbox_Comment_DILOROOF" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOPEST" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOPEST" formControlName="DILOPEST" class="form-check-input">Pest</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOPEST" formControlName="Checkbox_Comment_DILOPEST" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOSEPT" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOSEPT" formControlName="DILOSEPT" class="form-check-input">Septic</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOSEPT" formControlName="Checkbox_Comment_DILOSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOWELL" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOWELL" formControlName="DILOWELL" class="form-check-input">Well Report</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOWELL" formControlName="Checkbox_Comment_DILOWELL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOASOC" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOASOC" formControlName="DILOASOC" class="form-check-input">Association Bylaws</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOASOC" formControlName="Checkbox_Comment_DILOASOC" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILODEMO" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILODEMO" formControlName="DILODEMO" class="form-check-input">Demographics</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILODEMO" formControlName="Checkbox_Comment_DILODEMO" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOFLOR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOFLOR" formControlName="DILOFLOR" class="form-check-input">Floor Plan</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOFLOR" formControlName="Checkbox_Comment_DILOFLOR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOLEGA" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOLEGA" formControlName="DILOLEGA" class="form-check-input">Legal Description</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOLEGA" formControlName="Checkbox_Comment_DILOLEGA" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOZONR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOZONR" formControlName="DILOZONR" class="form-check-input">Zoning Description</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOZONR" formControlName="Checkbox_Comment_DILOZONR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOPUBR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOPUBR" formControlName="DILOPUBR" class="form-check-input">Public Restrictions</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOPUBR" formControlName="Checkbox_Comment_DILOPUBR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOLEAS" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOLEAS" formControlName="DILOLEAS" class="form-check-input">Leases</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOLEAS" formControlName="Checkbox_Comment_DILOLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOAPPR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOAPPR" formControlName="DILOAPPR" class="form-check-input">Appraisal</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOAPPR" formControlName="Checkbox_Comment_DILOAPPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOPNLS" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOPNLS" formControlName="DILOPNLS" class="form-check-input">Profit & Loss</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOPNLS" formControlName="Checkbox_Comment_DILOPNLS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOINCX" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOINCX" formControlName="DILOINCX" class="form-check-input">Income Tax Return</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOINCX" formControlName="Checkbox_Comment_DILOINCX" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOCASH" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOCASH" formControlName="DILOCASH" class="form-check-input">Cash Flow Analysis</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOCASH" formControlName="Checkbox_Comment_DILOCASH" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOINVL" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOINVL" formControlName="DILOINVL" class="form-check-input">Inventory List</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOINVL" formControlName="Checkbox_Comment_DILOINVL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DILOFIXT" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DILOFIXT" formControlName="DILOFIXT" class="form-check-input">Fixtures & Equipment</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DILOFIXT" formControlName="Checkbox_Comment_DILOFIXT" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
  
  
        <!--End of right column-->
        </div>				
      </div>
  
  
      <!-------------------------------------------------------------->
      <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
      <!-------------------------------------------------------------->
  
  
      <div class="d-flex form-inline row">
  
  
        <!-- Left Column-->
        <div class="col-lg-6 align-self-start">
   
          <div formGroupName="SHOWINGINSTRUCTIONS" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Showing Instructions:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
    
    
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="SHOWLOCK" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWLOCK" formControlName="SHOWLOCK" class="form-check-input">Lockbox</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWLOCK" formControlName="Checkbox_Comment_SHOWLOCK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCLLO" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCLLO" formControlName="SHOWCLLO" class="form-check-input">Call Listing Office</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCLLO" formControlName="Checkbox_Comment_SHOWCLLO" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCLTN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCLTN" formControlName="SHOWCLTN" class="form-check-input">Call Tenants</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCLTN" formControlName="Checkbox_Comment_SHOWCLTN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCLSM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCLSM" formControlName="SHOWCLSM" class="form-check-input">Call On-Site Manager</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCLSM" formControlName="Checkbox_Comment_SHOWCLSM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCLOW" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCLOW" formControlName="SHOWCLOW" class="form-check-input">Call Owner</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCLOW" formControlName="Checkbox_Comment_SHOWCLOW" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWAPPT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWAPPT" formControlName="SHOWAPPT" class="form-check-input">Confirmed Appt Only</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWAPPT" formControlName="Checkbox_Comment_SHOWAPPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWDNDT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWDNDT" formControlName="SHOWDNDT" class="form-check-input">Do Not Disturb</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWDNDT" formControlName="Checkbox_Comment_SHOWDNDT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOW24HR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOW24HR" formControlName="SHOW24HR" class="form-check-input">24 Hour Notice</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOW24HR" formControlName="Checkbox_Comment_SHOW24HR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWNOSN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWNOSN" formControlName="SHOWNOSN" class="form-check-input">Not on Sunday</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWNOSN" formControlName="Checkbox_Comment_SHOWNOSN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWSNST" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWSNST" formControlName="SHOWSNST" class="form-check-input">No Sunset Fri/Sat</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWSNST" formControlName="Checkbox_Comment_SHOWSNST" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWSEAS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWSEAS" formControlName="SHOWSEAS" class="form-check-input">Seasonal Access</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWSEAS" formControlName="Checkbox_Comment_SHOWSEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWFWDR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWFWDR" formControlName="SHOWFWDR" class="form-check-input">4 Wheel Drive</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWFWDR" formControlName="Checkbox_Comment_SHOWFWDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWCBSC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWCBSC" formControlName="SHOWCBSC" class="form-check-input">CBS Code Required</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWCBSC" formControlName="Checkbox_Comment_SHOWCBSC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWVTGS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWVTGS" formControlName="SHOWVTGS" class="form-check-input">Vacant Go Show</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWVTGS" formControlName="Checkbox_Comment_SHOWVTGS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SHOWALRM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SHOWALRM" formControlName="SHOWALRM" class="form-check-input">Alarm or Security</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SHOWALRM" formControlName="Checkbox_Comment_SHOWALRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            </div>
    
            <span class="row col"><span class="p-2 py-2 reqtext formnote">Alarm code may only be entered in MLS with sellers written consent</span></span>
    
    
            <br>

        </div> <!--end of left column-->
  
  
        <!--Right Column-->
  
  
        <div class="col-lg-6 align-self-start">
  
          <div class="row">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_LKBS" class="reqtext pr-2 py-2 col-form-label">*Supra Lockbox:</label>
              <select id="CB_LKBS" formControlName="LKBS" class="form-control" required>
                <option value=""></option>
                <option class="mBlackText" value="YES">Yes </option>
                <option class="mBlackText" value="NO">No </option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <input type="text" id="0760LKBS" formControlName="0760LKBS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
          <!--break for next group in the left column-->
          <br>
  
          <div class="row">
            <div class="form-group col-sm-8">
              <label for="Showing_Instructions" class="nonreqtext pr-2 py-2 col-form-label">Showing Instructions:&nbsp;<span class="formnote">(Maximum 60)</span></label>
            </div>
            <div class="form-group col-sm-12 justify-content-end">
              <textarea id="Showing_Instructions" formControlName="Showing_Instructions" class="form-control flex-grow-1" spellcheck="true" rows="" cols="50" maxlength="60"></textarea>
            </div>
          </div>
  
  
          <br>
  
        <!--End of right column-->
        </div>				
      </div>
      </div>
      <div id="Property Features Form">
        <div class="d-flex form-inline row">
          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
  
  
            <!--next row-->
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="Garage_Spaces" class="reqtext pr-2 py-2 col-form-label">*Garage Spaces:</label>
                <input type="text" id="Garage_Spaces" formControlName="Garage_Spaces" class="form-control col-sm-4" value="0" size="" maxlength="3" required>
              </div>
            </div>
  
  
  
  
  
            <!--next row-->
            <ng-container *ngIf="form.get('Garage_Spaces').value > 0">
            <div class="row">
              <div class="form-group col-sm-6">
                <span class="row col"><span class="pr-2 py-2 reqtext"><label for="CB_GART">*Garage Type:</label></span></span>
                <select id="CB_GART" formControlName="GART" class="form-control" required>
                  <option value=" "></option>
                  <option class="mBlackText" value="ATTD">Attached </option>
                  <option class="mBlackText" value="DTTD">Detached </option>
                </select>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0842GART" formControlName="0842GART" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
            </ng-container>
  
  
          <!--break for next group in the left column-->
          <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Additional Parking:</span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="PARKGARG" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="PARKGARG" formControlName="PARKGARG" class="form-check-input">Garage</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_PARKGARG" formControlName="Checkbox_Comment_PARKGARG" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="PARKCRPT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="PARKCRPT" formControlName="PARKCRPT" class="form-check-input">Carport</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_PARKCRPT" formControlName="Checkbox_Comment_PARKCRPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="PARKCVRD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="PARKCVRD" formControlName="PARKCVRD" class="form-check-input">Covered</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_PARKCVRD" formControlName="Checkbox_Comment_PARKCVRD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="PARKUCVR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="PARKUCVR" formControlName="PARKUCVR" class="form-check-input">Uncovered</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_PARKUCVR" formControlName="Checkbox_Comment_PARKUCVR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="PARKOSPU" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="PARKOSPU" formControlName="PARKOSPU" class="form-check-input">One Space per Unit</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_PARKOSPU" formControlName="Checkbox_Comment_PARKOSPU" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="PARKMTOS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="PARKMTOS" formControlName="PARKMTOS" class="form-check-input">More than One Space</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_PARKMTOS" formControlName="Checkbox_Comment_PARKMTOS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <br>
  
            <!---->
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="NUM_CRPT" class= "reqtext pr-2 py-2 col-form-label">*Carport Spaces:</label>
                <input type="text" id="NUM_CRPT" formControlName="CRPT" class="form-control col-sm-5" size="" maxlength="6" required>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0860CRPT" formControlName="0860CRPT" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
              </div>
            </div>
  
  
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <div class="p-2 showbreak">&nbsp;</div>
              </div>
            </div>
  
  
  
          </div> <!--end of left column-->
  
  
          <!--Right Column-->
          <div class="col-lg-6 align-self-start">
  
            <div formGroupName="FOUNDATION" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Foundation:&nbsp;<span class="nonreqtext formnote">(1 entry allowed)</span></span></span>
  
  
            <!--First row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FLORCONC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FLORCONC" formControlName="FLORCONC" class="form-check-input">Concrete Perimeter</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FLORCONC" formControlName="Checkbox_Comment_FLORCONC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FLORWOOD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FLORWOOD" formControlName="FLORWOOD" class="form-check-input">Wood Subfloor</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FLORWOOD" formControlName="Checkbox_Comment_FLORWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FLORBOTH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FLORBOTH" formControlName="FLORBOTH" class="form-check-input">Both</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FLORBOTH" formControlName="Checkbox_Comment_FLORBOTH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Facilities:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="FCLTPOOL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTPOOL" formControlName="FCLTPOOL" class="form-check-input">Pool</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTPOOL" formControlName="Checkbox_Comment_FCLTPOOL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTJCZI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTJCZI" formControlName="FCLTJCZI" class="form-check-input">Jacuzzi</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTJCZI" formControlName="Checkbox_Comment_FCLTJCZI" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTCBNA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTCBNA" formControlName="FCLTCBNA" class="form-check-input">Cabana</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTCBNA" formControlName="Checkbox_Comment_FCLTCBNA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTTNSC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTTNSC" formControlName="FCLTTNSC" class="form-check-input">Tennis Court</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTTNSC" formControlName="Checkbox_Comment_FCLTTNSC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTBBAL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTBBAL" formControlName="FCLTBBAL" class="form-check-input">Basketball Court</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTBBAL" formControlName="Checkbox_Comment_FCLTBBAL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTXRCS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTXRCS" formControlName="FCLTXRCS" class="form-check-input">Exercise Room</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTXRCS" formControlName="Checkbox_Comment_FCLTXRCS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTPLAY" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTPLAY" formControlName="FCLTPLAY" class="form-check-input">Play Yard</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTPLAY" formControlName="Checkbox_Comment_FCLTPLAY" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTPUTT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTPUTT" formControlName="FCLTPUTT" class="form-check-input">Putting Green</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTPUTT" formControlName="Checkbox_Comment_FCLTPUTT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="FCLTUTIL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="FCLTUTIL" formControlName="FCLTUTIL" class="form-check-input">Utility Room</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_FCLTUTIL" formControlName="Checkbox_Comment_FCLTUTIL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
            </div>
  
          </div> <!--end of right column-->
        </div> <!--end of page/section-->
  
  
      <!-------------------------------------------------------------->
      <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
      <!-------------------------------------------------------------->
  
  
      <div class="d-flex form-inline row">
  
  
        <!-- Left Column-->
        <div class="col-lg-6 align-self-start">
  
  
          <span class="row col"><span class="pr-2 py-2 nonreqtext">Special Features:</span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="SFTRPRVP" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRPRVP" formControlName="SFTRPRVP" class="form-check-input">Private Patios</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRPRVP" formControlName="Checkbox_Comment_SFTRPRVP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SFTRPRVB" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRPRVB" formControlName="SFTRPRVB" class="form-check-input">Private Balconies</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRPRVB" formControlName="Checkbox_Comment_SFTRPRVB" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SFTRXTRA" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRXTRA" formControlName="SFTRXTRA" class="form-check-input">Extra Storage</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRXTRA" formControlName="Checkbox_Comment_SFTRXTRA" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SFTRONST" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRONST" formControlName="SFTRONST" class="form-check-input">On-Site Manager</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRONST" formControlName="Checkbox_Comment_SFTRONST" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SFTRLAUN" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRLAUN" formControlName="SFTRLAUN" class="form-check-input">Laundry in Each Unit</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRLAUN" formControlName="Checkbox_Comment_SFTRLAUN" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SFTRSECG" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRSECG" formControlName="SFTRSECG" class="form-check-input">Security Gate</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRSECG" formControlName="Checkbox_Comment_SFTRSECG" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SFTRSECP" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRSECP" formControlName="SFTRSECP" class="form-check-input">Security Patrol</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRSECP" formControlName="Checkbox_Comment_SFTRSECP" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SFTRFURN" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SFTRFURN" formControlName="SFTRFURN" class="form-check-input">Furnished</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SFTRFURN" formControlName="Checkbox_Comment_SFTRFURN" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <div class="p-2 showbreak">&nbsp;</div>
            </div>
          </div>
  
  
  
          <br>
  
  
  
          <span class="row col"><span class="pr-2 py-2 nonreqtext">Leased Equipment:</span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="LEASWSDR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="LEASWSDR" formControlName="LEASWSDR" class="form-check-input">Washer/Dryer (s)</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_LEASWSDR" formControlName="Checkbox_Comment_LEASWSDR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="LEASPROP" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="LEASPROP" formControlName="LEASPROP" class="form-check-input">Propane</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_LEASPROP" formControlName="Checkbox_Comment_LEASPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="LEASWTRS" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="LEASWTRS" formControlName="LEASWTRS" class="form-check-input">Water Softener</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_LEASWTRS" formControlName="Checkbox_Comment_LEASWTRS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="LEASSESS" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="LEASSESS" formControlName="LEASSESS" class="form-check-input">Security System</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_LEASSESS" formControlName="Checkbox_Comment_LEASSESS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="LEASSOLP" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="LEASSOLP" formControlName="LEASSOLP" class="form-check-input">Solar Panels</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_LEASSOLP" formControlName="Checkbox_Comment_LEASSOLP" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <div class="p-2 showbreak">&nbsp;</div>
            </div>
          </div>
  
  
        </div> <!--end of left column-->
  
  
        <!--Right Column-->
  
  
        <div class="col-lg-6 align-self-start">
  
  
          <span class="row col"><span class="pr-2 py-2 nonreqtext">Site Amenities:</span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="SITECRNR" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SITECRNR" formControlName="SITECRNR" class="form-check-input">Corner</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SITECRNR" formControlName="Checkbox_Comment_SITECRNR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SITECULD" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SITECULD" formControlName="SITECULD" class="form-check-input">Cul De Sac</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SITECULD" formControlName="Checkbox_Comment_SITECULD" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SITEBLUF" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SITEBLUF" formControlName="SITEBLUF" class="form-check-input">Bluff View</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SITEBLUF" formControlName="Checkbox_Comment_SITEBLUF" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SITELAKE" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SITELAKE" formControlName="SITELAKE" class="form-check-input">Lake View</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SITELAKE" formControlName="Checkbox_Comment_SITELAKE" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SITEWTRF" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SITEWTRF" formControlName="SITEWTRF" class="form-check-input">Water Front</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SITEWTRF" formControlName="Checkbox_Comment_SITEWTRF" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SITEGFCV" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SITEGFCV" formControlName="SITEGFCV" class="form-check-input">Golf Course View</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SITEGFCV" formControlName="Checkbox_Comment_SITEGFCV" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="SITEPRKV" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="SITEPRKV" formControlName="SITEPRKV" class="form-check-input">Park View</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_SITEPRKV" formControlName="Checkbox_Comment_SITEPRKV" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <br>
  
  
          <span class="row col"><span class="pr-2 py-2 nonreqtext">Yard:</span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="YARDSPRF" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="YARDSPRF" formControlName="YARDSPRF" class="form-check-input">Sprinkler - Front</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_YARDSPRF" formControlName="Checkbox_Comment_YARDSPRF" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="YARDSPRB" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="YARDSPRB" formControlName="YARDSPRB" class="form-check-input">Sprinkler - Back</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_YARDSPRB" formControlName="Checkbox_Comment_YARDSPRB" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="YARDSPRA" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="YARDSPRA" formControlName="YARDSPRA" class="form-check-input">Sprinkler - Auto</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_YARDSPRA" formControlName="Checkbox_Comment_YARDSPRA" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="YARDSPRM" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="YARDSPRM" formControlName="YARDSPRM" class="form-check-input">Sprinkler - Manual</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_YARDSPRM" formControlName="Checkbox_Comment_YARDSPRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="YARDFENC" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="YARDFENC" formControlName="YARDFENC" class="form-check-input">Fenced</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_YARDFENC" formControlName="Checkbox_Comment_YARDFENC" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="YARDLAND" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="YARDLAND" formControlName="YARDLAND" class="form-check-input">Mature Landscape</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_YARDLAND" formControlName="Checkbox_Comment_YARDLAND" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--break for next group in the right column-->
          <br>
  
  
          <div class="row">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="NUM_TTLB" class= "nonreqtext pr-2 py-2 col-form-label">Total Buildings:</label>
              <input type="text" id="NUM_TTLB" formControlName="TTLB" class="form-control col-sm-5" size="" maxlength="6">
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0930TTLB" formControlName="0930TTLB" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
            </div>
          </div>
  
  
          <br>
  
  
        <!--End of right column-->
        </div>				
      </div>
      </div>
      <div id="Unit Features Form">
          
        <div class="d-flex form-inline row pt-1">
  
          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
  
  
            <div formGroupName="HEATCOOL" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Heat/Cool:</span></span>
  
  
            <!--First row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="HTCLCNHC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="HTCLCNHC" formControlName="HTCLCNHC" class="form-check-input">Central Heat/Cool</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_HTCLCNHC" formControlName="Checkbox_Comment_HTCLCNHC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="HTCLCNHT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="HTCLCNHT" formControlName="HTCLCNHT" class="form-check-input">Central Heat</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_HTCLCNHT" formControlName="Checkbox_Comment_HTCLCNHT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="HTCLCNCL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="HTCLCNCL" formControlName="HTCLCNCL" class="form-check-input">Central Cooling</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_HTCLCNCL" formControlName="Checkbox_Comment_HTCLCNCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="HTCLEVAP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="HTCLEVAP" formControlName="HTCLEVAP" class="form-check-input">Evaporative</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_HTCLEVAP" formControlName="Checkbox_Comment_HTCLEVAP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="HTCLFLWL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="HTCLFLWL" formControlName="HTCLFLWL" class="form-check-input">Floor or Wall Heat</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_HTCLFLWL" formControlName="Checkbox_Comment_HTCLFLWL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="HTCLWNWL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="HTCLWNWL" formControlName="HTCLWNWL" class="form-check-input">Window or Wall A/C</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_HTCLWNWL" formControlName="Checkbox_Comment_HTCLWNWL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="HTCLNONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="HTCLNONE" formControlName="HTCLNONE" class="form-check-input">None</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_HTCLNONE" formControlName="Checkbox_Comment_HTCLNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
            </div>
  
            <!---->
            <br>
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_FIRE" class="reqtext pr-2 py-2 col-form-label">*Fireplace:</label>
                <select id="CB_FIRE" formControlName="FIRE" class="form-control col-sm-5" required>
                  <option value=""></option>
                  <option class="mBlackText" value="NONE">None </option>
                  <option class="mBlackText" value="ONEU">One Unit </option>
                  <option class="mBlackText" value="MTOU">More than One Unit </option>
                  <option class="mBlackText" value="ALLU">All Units </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="0970FIRE" formControlName="0970FIRE" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
    
    
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_STOV" class="nonreqtext pr-2 py-2 col-form-label">Stove:</label>
                <select id="CB_STOV" formControlName="STOV" class="form-control col-sm-5">
                  <option value=""></option>
                  <option class="mBlackText" value="NONE">None </option>
                  <option class="mBlackText" value="ONEU">One Unit </option>
                  <option class="mBlackText" value="MTOU">More than One Unit </option>
                  <option class="mBlackText" value="ALLU">All Units </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="0980STOV" formControlName="0980STOV" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
    
    
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_REFR" class="nonreqtext pr-2 py-2 col-form-label">Refrigerator:</label>
                <select id="CB_REFR" formControlName="REFR" class="form-control col-sm-5">
                  <option value=""></option>
                  <option class="mBlackText" value="NONE">None </option>
                  <option class="mBlackText" value="ONEU">One Unit </option>
                  <option class="mBlackText" value="MTOU">More than One Unit </option>
                  <option class="mBlackText" value="ALLU">All Units </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="0990REFR" formControlName="0990REFR" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
    
    
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_DISH" class="nonreqtext pr-2 py-2 col-form-label">Dishwasher:</label>
                <select id="CB_DISH" formControlName="DISH" class="form-control col-sm-5">
                  <option value=""></option>
                  <option class="mBlackText" value="NONE">None </option>
                  <option class="mBlackText" value="ONEU">One Unit </option>
                  <option class="mBlackText" value="MTOU">More than One Unit </option>
                  <option class="mBlackText" value="ALLU">All Units </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="1000DISH" formControlName="1000DISH" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
    
    
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_DISP" class="nonreqtext pr-2 py-2 col-form-label">Disposal:</label>
                <select id="CB_DISP" formControlName="DISP" class="form-control col-sm-5">
                  <option value=""></option>
                  <option class="mBlackText" value="NONE">None </option>
                  <option class="mBlackText" value="ONEU">One Unit </option>
                  <option class="mBlackText" value="MTOU">More than One Unit </option>
                  <option class="mBlackText" value="ALLU">All Units </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="1010DISP" formControlName="1010DISP" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
  
  
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <div class="p-2 showbreak">&nbsp;</div>
              </div>
            </div>
  
          <!--End of left column-->
        </div>
  
        <!--Right Column-->
        <div class="col-lg-6 align-self-start">
  
  
          <span class="row col"><span class="pr-2 py-2 nonreqtext">Disabled Features:</span></span>
  
  
          <!-- One row for a new input group -->
          <div class="row fixcheck">
            
            <!-- Within that row, specify column length  -->
            <div class="form-group col-sm-6">
              <label for="DSABWLCA" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DSABWLCA" formControlName="DSABWLCA" class="form-check-input">Wheel Chair Access</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DSABWLCA" formControlName="Checkbox_Comment_DSABWLCA" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DSABRAMP" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DSABRAMP" formControlName="DSABRAMP" class="form-check-input">Ramp</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DSABRAMP" formControlName="Checkbox_Comment_DSABRAMP" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DSABWIDD" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DSABWIDD" formControlName="DSABWIDD" class="form-check-input">Wide Doors</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DSABWIDD" formControlName="Checkbox_Comment_DSABWIDD" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DSABHAND" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DSABHAND" formControlName="DSABHAND" class="form-check-input">Hand Rails</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DSABHAND" formControlName="Checkbox_Comment_DSABHAND" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DSABBATH" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DSABBATH" formControlName="DSABBATH" class="form-check-input">Bathroom Fixtures</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DSABBATH" formControlName="Checkbox_Comment_DSABBATH" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DSABSHOW" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DSABSHOW" formControlName="DSABSHOW" class="form-check-input">Access in Shower</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DSABSHOW" formControlName="Checkbox_Comment_DSABSHOW" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
  
          <!--next row-->
          <div class="row fixcheck">
            <div class="form-group col-sm-6">
              <label for="DSAB1LVL" class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="DSAB1LVL" formControlName="DSAB1LVL" class="form-check-input">One Level Floor</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="Checkbox_Comment_DSAB1LVL" formControlName="Checkbox_Comment_DSAB1LVL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
          </div>
  
          <br>
  
        <!--End of right column-->
        </div>				
      </div>
  
  
      <br>
      </div>
      <div id="Financial Data Form">
          
        <div class="d-flex form-inline row pt-1">
  
  
          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="NUM_AINC" class= "reqtext pr-2 py-2 col-form-label">*Annual Income:&nbsp;</label><span class="nonreqtext">$</span>
                <input type="text" id="NUM_AINC" formControlName="AINC" class="form-control col-sm-5" size="" maxlength="6" required>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="1050AINC" formControlName="1050AINC" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="NUM_AEXP" class= "reqtext pr-2 py-2 col-form-label">*Annual Expense:</label><span class="nonreqtext">$</span>
                <input type="text" id="NUM_AEXP" formControlName="AEXP" class="form-control col-sm-5" size="" maxlength="6" required>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="1060AEXP" formControlName="1060AEXP" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="NUM_NINC" class= "reqtext pr-2 py-2 col-form-label">*Net Income:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label><span class="nonreqtext">$</span>
                <input type="text" id="NUM_NINC" formControlName="NINC" class="form-control col-sm-5" size="" maxlength="6" required>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="1070NINC" formControlName="1070NINC" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
  
  
            <!--break for next group in the left column-->
            <br>
  
            <div formGroupName="TENANTPAYS" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Tenant Pays:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="TNTPNONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPNONE" formControlName="TNTPNONE" class="form-check-input">None</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPNONE" formControlName="Checkbox_Comment_TNTPNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPALLL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPALLL" formControlName="TNTPALLL" class="form-check-input">All Utilities</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPALLL" formControlName="Checkbox_Comment_TNTPALLL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPELEC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPELEC" formControlName="TNTPELEC" class="form-check-input">Electricity</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPELEC" formControlName="Checkbox_Comment_TNTPELEC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPGASS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPGASS" formControlName="TNTPGASS" class="form-check-input">Gas</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPGASS" formControlName="Checkbox_Comment_TNTPGASS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPPROP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPPROP" formControlName="TNTPPROP" class="form-check-input">Propane</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPPROP" formControlName="Checkbox_Comment_TNTPPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPWATR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPWATR" formControlName="TNTPWATR" class="form-check-input">Water</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPWATR" formControlName="Checkbox_Comment_TNTPWATR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPSEWR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPSEWR" formControlName="TNTPSEWR" class="form-check-input">Sewer</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPSEWR" formControlName="Checkbox_Comment_TNTPSEWR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPTRSH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPTRSH" formControlName="TNTPTRSH" class="form-check-input">Trash Collection</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPTRSH" formControlName="Checkbox_Comment_TNTPTRSH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TNTPCBLE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TNTPCBLE" formControlName="TNTPCBLE" class="form-check-input">Cable TV</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TNTPCBLE" formControlName="Checkbox_Comment_TNTPCBLE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            </div>
            <!--break for next group in the left column-->
            <br>
  
            <div formGroupName="INCOMEINCLUDES">
            <span class="row col"><span class="pr-2 py-2 reqtext">*Income Includes:</span></span>
  
  
            <!--First row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCIRENT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCIRENT" formControlName="INCIRENT" class="form-check-input">Unit Rent</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCIRENT" formControlName="Checkbox_Comment_INCIRENT" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCIGARG" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCIGARG" formControlName="INCIGARG" class="form-check-input">Garage Rent</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCIGARG" formControlName="Checkbox_Comment_INCIGARG" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCILNDR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCILNDR" formControlName="INCILNDR" class="form-check-input">Laundry</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCILNDR" formControlName="Checkbox_Comment_INCILNDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCIOTHR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCIOTHR" formControlName="INCIOTHR" class="form-check-input">Other</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCIOTHR" formControlName="Checkbox_Comment_INCIOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCIALLL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCIALLL" formControlName="INCIALLL" class="form-check-input">All</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCIALLL" formControlName="Checkbox_Comment_INCIALLL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCIMNTH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCIMNTH" formControlName="INCIMNTH" class="form-check-input">Month to Month</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCIMNTH" formControlName="Checkbox_Comment_INCIMNTH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCILEAS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCILEAS" formControlName="INCILEAS" class="form-check-input">Lease</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCILEAS" formControlName="Checkbox_Comment_INCILEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="INCIBOTH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="INCIBOTH" formControlName="INCIBOTH" class="form-check-input">Both</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_INCIBOTH" formControlName="Checkbox_Comment_INCIBOTH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
            </div>
  
          <!--End of left column-->
        </div>
  
        <!--Right Column-->
        <div class="col-lg-6 align-self-start">
  
            <div formGroupName="EXPENSESINCLUDE" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Expenses Include:&nbsp;<span class="reqtext formnote">(Up to 14 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="EXPIALLL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIALLL" formControlName="EXPIALLL" class="form-check-input">All</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIALLL" formControlName="Checkbox_Comment_EXPIALLL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIMNGT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIMNGT" formControlName="EXPIMNGT" class="form-check-input">Management</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIMNGT" formControlName="Checkbox_Comment_EXPIMNGT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIGRDN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIGRDN" formControlName="EXPIGRDN" class="form-check-input">Gardener</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIGRDN" formControlName="Checkbox_Comment_EXPIGRDN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIMNTN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIMNTN" formControlName="EXPIMNTN" class="form-check-input">Maintenence/Repair</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIMNTN" formControlName="Checkbox_Comment_EXPIMNTN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIINSR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIINSR" formControlName="EXPIINSR" class="form-check-input">Insurance</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIINSR" formControlName="Checkbox_Comment_EXPIINSR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIACCT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIACCT" formControlName="EXPIACCT" class="form-check-input">Accounting/Legal</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIACCT" formControlName="Checkbox_Comment_EXPIACCT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPILICN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPILICN" formControlName="EXPILICN" class="form-check-input">License/Ads</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPILICN" formControlName="Checkbox_Comment_EXPILICN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIGASS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIGASS" formControlName="EXPIGASS" class="form-check-input">Gas</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIGASS" formControlName="Checkbox_Comment_EXPIGASS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIPROP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIPROP" formControlName="EXPIPROP" class="form-check-input">Propane</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIPROP" formControlName="Checkbox_Comment_EXPIPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIELEC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIELEC" formControlName="EXPIELEC" class="form-check-input">Electric</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIELEC" formControlName="Checkbox_Comment_EXPIELEC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIWATR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIWATR" formControlName="EXPIWATR" class="form-check-input">Water</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIWATR" formControlName="Checkbox_Comment_EXPIWATR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPISEWR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPISEWR" formControlName="EXPISEWR" class="form-check-input">Sewer</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPISEWR" formControlName="Checkbox_Comment_EXPISEWR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIPOOL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIPOOL" formControlName="EXPIPOOL" class="form-check-input">Pool</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIPOOL" formControlName="Checkbox_Comment_EXPIPOOL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPITAXS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPITAXS" formControlName="EXPITAXS" class="form-check-input">Taxes</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPITAXS" formControlName="Checkbox_Comment_EXPITAXS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPIVCNC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPIVCNC" formControlName="EXPIVCNC" class="form-check-input">Vacancy Factor</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPIVCNC" formControlName="Checkbox_Comment_EXPIVCNC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="EXPINONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="EXPINONE" formControlName="EXPINONE" class="form-check-input">None</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_EXPINONE" formControlName="Checkbox_Comment_EXPINONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
            </div>
  
            <!--break for next group in the right column-->
            <br>
  
            <div formGroupName="SOURCESOFINCEXP" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Sources of Inc/Exp:</span></span>
  
  
            <!--First row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SRCSTAXR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SRCSTAXR" formControlName="SRCSTAXR" class="form-check-input">Tax Return</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SRCSTAXR" formControlName="Checkbox_Comment_SRCSTAXR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SRCSACCT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SRCSACCT" formControlName="SRCSACCT" class="form-check-input">Accountant</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SRCSACCT" formControlName="Checkbox_Comment_SRCSACCT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SRCSOWNR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SRCSOWNR" formControlName="SRCSOWNR" class="form-check-input">Owner</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SRCSOWNR" formControlName="Checkbox_Comment_SRCSOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SRCSPROJ" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SRCSPROJ" formControlName="SRCSPROJ" class="form-check-input">Projected</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SRCSPROJ" formControlName="Checkbox_Comment_SRCSPROJ" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SRCSLMTD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SRCSLMTD" formControlName="SRCSLMTD" class="form-check-input">Limited</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SRCSLMTD" formControlName="Checkbox_Comment_SRCSLMTD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SRCSPMAN" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SRCSPMAN" formControlName="SRCSPMAN" class="form-check-input">Property Manager</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SRCSPMAN" formControlName="Checkbox_Comment_SRCSPMAN" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SRCSNONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SRCSNONE" formControlName="SRCSNONE" class="form-check-input">None</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SRCSNONE" formControlName="Checkbox_Comment_SRCSNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
            </div>
  
          <!--End of right column-->
        </div>				
      </div>
  
  
      <!-------------------------------------------------------------->
      <!--Page Break--><div><hr class="HRstyle"></div><!--Page Break-->
      <!-------------------------------------------------------------->
  
  
        <div class="d-flex form-inline row pt-1">
  
  
          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">1st Loan Type:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="1SLNASSM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="1SLNASSM" formControlName="1SLNASSM" class="form-check-input">Assumable</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_1SLNASSM" formControlName="Checkbox_Comment_1SLNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="1SLNNASS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="1SLNNASS" formControlName="1SLNNASS" class="form-check-input">Non-Assumable</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_1SLNNASS" formControlName="Checkbox_Comment_1SLNNASS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="1SLNPRIV" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="1SLNPRIV" formControlName="1SLNPRIV" class="form-check-input">Private</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_1SLNPRIV" formControlName="Checkbox_Comment_1SLNPRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="1SLNFRCL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="1SLNFRCL" formControlName="1SLNFRCL" class="form-check-input">Free & Clear</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_1SLNFRCL" formControlName="Checkbox_Comment_1SLNFRCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--break for next group in the left column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">2nd Loan Type:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="2LNASSM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="2LNASSM" formControlName="2LNASSM" class="form-check-input">Assumable</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_2LNASSM" formControlName="Checkbox_Comment_2LNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="2LNPRIV" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="2LNPRIV" formControlName="2LNPRIV" class="form-check-input">Private</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_2LNPRIV" formControlName="Checkbox_Comment_2LNPRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="2LNNONA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="2LNNONA" formControlName="2LNNONA" class="form-check-input">Non-Assumable</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_2LNNONA" formControlName="Checkbox_Comment_2LNNONA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--break for next group in the left column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Seller Will Consider:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="TERMGOVT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMGOVT" formControlName="TERMGOVT" class="form-check-input">Government</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMGOVT" formControlName="Checkbox_Comment_TERMGOVT" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMCONV" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMCONV" formControlName="TERMCONV" class="form-check-input">Conventional</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMCONV" formControlName="Checkbox_Comment_TERMCONV" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMCASH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMCASH" formControlName="TERMCASH" class="form-check-input">Cash</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMCASH" formControlName="Checkbox_Comment_TERMCASH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMSUBM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMSUBM" formControlName="TERMSUBM" class="form-check-input">Submit</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMSUBM" formControlName="Checkbox_Comment_TERMSUBM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMOWNR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMOWNR" formControlName="TERMOWNR" class="form-check-input">Owner Carry</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMOWNR" formControlName="Checkbox_Comment_TERMOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMLIMI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMLIMI" formControlName="TERMLIMI" class="form-check-input">Limited Financing</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMLIMI" formControlName="Checkbox_Comment_TERMLIMI" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
  
          <!--End of left column-->
                  </div>
  
                  <!--Right Column-->
                  <div class="col-lg-6 align-self-start">
  
            <div class="row">
              <div class="form-group col-sm-7 justify-content-between">
                <label for="CB_HSSB" class="nonreqtext pr-2 py-2 col-form-label">Hardship Submitted:</label>
                <select id="CB_HSSB" formControlName="HSSB" class="form-control col-sm-3">
                  <option value=""></option>
                  <option class="mBlackText" value="YESS">Yes </option>
                  <option class="mBlackText" value="NONO">No </option>
                </select>
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-7 justify-content-between">
                <label for="NUM_STTD" class= "reqtext pr-2 py-2 col-form-label">*1st Trust Deed Amt.:&nbsp;&nbsp;</label><span class="nonreqtext">$</span>
                <input type="text" id="NUM_STTD" formControlName="STTD" class="form-control col-sm-4" size="" maxlength="6">
              </div>
              <div class="form-group col-sm-5 justify-content-end">
                <input type="text" id="1180STTD" formControlName="1180STTD" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-7 justify-content-between">
                <label for="NUM_SDTD" class= "reqtext pr-2 py-2 col-form-label">*2nd Trust Deed Amt.:</label><span class="nonreqtext">$</span>
                <input type="text" id="NUM_SDTD" formControlName="SDTD" class="form-control col-sm-4" size="" maxlength="6">
              </div>
              <div class="form-group col-sm-5 justify-content-end">
                <input type="text" id="1190SDTD" formControlName="1190SDTD" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
            <div class="row form-inline">
              <div class="form-group col-sm-12 justify-content-between">
                <label for="1200SSSS" class= "nonreqtext pr-1 py-1">Notes:<span class="formnote">&nbsp;(Maximum 100)&nbsp;</span></label>
                <textarea id="1200SSSS" formControlName="1200SSSS" class="form-control col-sm-8" spellcheck="true" rows="2" cols="" maxlength="100"></textarea>
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
            <div class="row">
              <div class="form-group col-sm-7 justify-content-between">
                <label for="NUM_ADDD" class= "reqtext pr-2 py-2 col-form-label">*Additional Debt:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label><span class="nonreqtext">$</span>
                <input type="text" id="NUM_ADDD" formControlName="ADDD" class="form-control col-sm-4" size="" maxlength="6">
              </div>
              <div class="form-group col-sm-5 justify-content-end">
                <input type="text" id="1201ADDD" formControlName="1201ADDD" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Unique Conditions:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="UNIQPROB" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQPROB" formControlName="UNIQPROB" class="form-check-input">Probate</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQPROB" formControlName="Checkbox_Comment_UNIQPROB" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UNIQESTA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQESTA" formControlName="UNIQESTA" class="form-check-input">Estate Sale</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQESTA" formControlName="Checkbox_Comment_UNIQESTA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UNIQCORT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQCORT" formControlName="UNIQCORT" class="form-check-input">Court Approval Req.</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQCORT" formControlName="Checkbox_Comment_UNIQCORT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UNIQSHRT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQSHRT" formControlName="UNIQSHRT" class="form-check-input">Short Sale</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQSHRT" formControlName="Checkbox_Comment_UNIQSHRT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UNIQEXCH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQEXCH" formControlName="UNIQEXCH" class="form-check-input">1031 Exchange</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQEXCH" formControlName="Checkbox_Comment_UNIQEXCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Sale Options:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="SALETTLP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SALETTLP" formControlName="SALETTLP" class="form-check-input">Total Parcel Only</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SALETTLP" formControlName="Checkbox_Comment_SALETTLP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SALEDIVD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SALEDIVD" formControlName="SALEDIVD" class="form-check-input">Divided</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SALEDIVD" formControlName="Checkbox_Comment_SALEDIVD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SALEADJL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SALEADJL" formControlName="SALEADJL" class="form-check-input">Adjacent Land Avail</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SALEADJL" formControlName="Checkbox_Comment_SALEADJL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
          <!--End of right column-->
            </div>				
          </div>
      </div>
      <div id="Unit Details Form">
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="UNITDETAILSNUM" class="reqtext pr-2 py-2">*Number of Units (max 4)</label>
            <select id="UNITDETAILSNUM" formControlName="UNITDETAILSNUM" class="form-control col-md-8" required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
          </select>
          </div>
        </div>

        <ng-container *ngIf="form.get('UNITDETAILSNUM').value >= 1">
          <hr>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_DESCRIPTION" class= "reqtext pr-2 py-2">*Unit Description:</label>
              <input type="text" id="UNIT1_DESCRIPTION" formControlName="UNIT1_DESCRIPTION" class="form-control col-sm-8" size="" maxlength="30" required>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_NUMBER" class= "nonreqtext pr-2 py-2">Unit Number</label>
              <input type="text" id="UNIT1_NUMBER" formControlName="UNIT1_NUMBER" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_IDENUNITS" class= "nonreqtext pr-2 py-2">Identical Units:</label>
              <input type="text" id="UNIT1_IDENUNITS" formControlName="UNIT1_IDENUNITS" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_SQFEET" class= "nonreqtext pr-2 py-2">Square Feet:</label>
              <input type="text" id="UNIT1_SQFEET" formControlName="UNIT1_SQFEET" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_OCCUPIED" class="nonreqtext pr-2 py-2">Occupied</label>
              <select id="UNIT1_OCCUPIED" formControlName="UNIT1_OCCUPIED" class="form-control col-md-8" defa>
                <option [ngValue]="false">No</option>
                <option [ngValue]="true">Yes</option>
              </select>
            </div>
          </div>
          
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_RENT" class= "nonreqtext pr-2 py-2">Rent:</label>
              <input type="text" id="UNIT1_RENT" formControlName="UNIT1_RENT" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_BEDROOMS" class="reqtext pr-2 py-2">*Bedrooms</label>
              <select id="UNIT1_BEDROOMS" formControlName="UNIT1_BEDROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT1_BATHROOMS" class="reqtext pr-2 py-2">*Bathrooms</label>
              <select id="UNIT1_BATHROOMS" formControlName="UNIT1_BATHROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('UNITDETAILSNUM').value >= 2">
          <hr>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_DESCRIPTION" class= "reqtext pr-2 py-2">*Unit Description:</label>
              <input type="text" id="UNIT2_DESCRIPTION" formControlName="UNIT2_DESCRIPTION" class="form-control col-sm-8" size="" maxlength="30" required>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_NUMBER" class= "nonreqtext pr-2 py-2">Unit Number</label>
              <input type="text" id="UNIT2_NUMBER" formControlName="UNIT2_NUMBER" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_IDENUNITS" class= "nonreqtext pr-2 py-2">Identical Units:</label>
              <input type="text" id="UNIT2_IDENUNITS" formControlName="UNIT2_IDENUNITS" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_SQFEET" class= "nonreqtext pr-2 py-2">Square Feet:</label>
              <input type="text" id="UNIT2_SQFEET" formControlName="UNIT2_SQFEET" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_OCCUPIED" class="nonreqtext pr-2 py-2">Occupied</label>
              <select id="UNIT2_OCCUPIED" formControlName="UNIT2_OCCUPIED" class="form-control col-md-8" defa>
                <option [ngValue]="false">No</option>
                <option [ngValue]="true">Yes</option>
              </select>
            </div>
          </div>
          
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_RENT" class= "nonreqtext pr-2 py-2">Rent:</label>
              <input type="text" id="UNIT2_RENT" formControlName="UNIT2_RENT" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_BEDROOMS" class="reqtext pr-2 py-2">*Bedrooms</label>
              <select id="UNIT2_BEDROOMS" formControlName="UNIT2_BEDROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT2_BATHROOMS" class="reqtext pr-2 py-2">*Bathrooms</label>
              <select id="UNIT2_BATHROOMS" formControlName="UNIT2_BATHROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('UNITDETAILSNUM').value >= 3">
          <hr>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_DESCRIPTION" class= "reqtext pr-2 py-2">*Unit Description:</label>
              <input type="text" id="UNIT3_DESCRIPTION" formControlName="UNIT3_DESCRIPTION" class="form-control col-sm-8" size="" maxlength="30" required>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_NUMBER" class= "nonreqtext pr-2 py-2">Unit Number</label>
              <input type="text" id="UNIT3_NUMBER" formControlName="UNIT3_NUMBER" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_IDENUNITS" class= "nonreqtext pr-2 py-2">Identical Units:</label>
              <input type="text" id="UNIT3_IDENUNITS" formControlName="UNIT3_IDENUNITS" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_SQFEET" class= "nonreqtext pr-2 py-2">Square Feet:</label>
              <input type="text" id="UNIT3_SQFEET" formControlName="UNIT3_SQFEET" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_OCCUPIED" class="nonreqtext pr-2 py-2">Occupied</label>
              <select id="UNIT3_OCCUPIED" formControlName="UNIT3_OCCUPIED" class="form-control col-md-8" defa>
                <option [ngValue]="false">No</option>
                <option [ngValue]="true">Yes</option>
              </select>
            </div>
          </div>
          
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_RENT" class= "nonreqtext pr-2 py-2">Rent:</label>
              <input type="text" id="UNIT3_RENT" formControlName="UNIT3_RENT" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_BEDROOMS" class="reqtext pr-2 py-2">*Bedrooms</label>
              <select id="UNIT3_BEDROOMS" formControlName="UNIT3_BEDROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT3_BATHROOMS" class="reqtext pr-2 py-2">*Bathrooms</label>
              <select id="UNIT3_BATHROOMS" formControlName="UNIT3_BATHROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.get('UNITDETAILSNUM').value >= 4">
          <hr>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_DESCRIPTION" class= "reqtext pr-2 py-2">*Unit Description:</label>
              <input type="text" id="UNIT4_DESCRIPTION" formControlName="UNIT4_DESCRIPTION" class="form-control col-sm-8" size="" maxlength="30" required>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_NUMBER" class= "nonreqtext pr-2 py-2">Unit Number</label>
              <input type="text" id="UNIT4_NUMBER" formControlName="UNIT4_NUMBER" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_IDENUNITS" class= "nonreqtext pr-2 py-2">Identical Units:</label>
              <input type="text" id="UNIT4_IDENUNITS" formControlName="UNIT4_IDENUNITS" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_SQFEET" class= "nonreqtext pr-2 py-2">Square Feet:</label>
              <input type="text" id="UNIT4_SQFEET" formControlName="UNIT4_SQFEET" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_OCCUPIED" class="nonreqtext pr-2 py-2">Occupied</label>
              <select id="UNIT4_OCCUPIED" formControlName="UNIT4_OCCUPIED" class="form-control col-md-8" defa>
                <option [ngValue]="false">No</option>
                <option [ngValue]="true">Yes</option>
              </select>
            </div>
          </div>
          
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_RENT" class= "nonreqtext pr-2 py-2">Rent:</label>
              <input type="text" id="UNIT4_RENT" formControlName="UNIT4_RENT" class="form-control col-sm-8" size="" maxlength="30">
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_BEDROOMS" class="reqtext pr-2 py-2">*Bedrooms</label>
              <select id="UNIT4_BEDROOMS" formControlName="UNIT4_BEDROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>

          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="UNIT4_BATHROOMS" class="reqtext pr-2 py-2">*Bathrooms</label>
              <select id="UNIT4_BATHROOMS" formControlName="UNIT4_BATHROOMS" class="form-control col-md-8" required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="download()" [disabled]="isDownloading">
      <span *ngIf="isDownloading" class="spinner-border" role="status" aria-hidden="true"></span>
      Download as PDF
    </button>
    <button type="button" class="btn btn-default" (click)="close()">Close</button>
  </div>
</ng-container>
<ng-template #loading>
  <div class="modal-header">
    <h5 class="modal-title d-flex flex-column">
      <span>{{ formName }}</span>
    </h5>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body h-100">
    <div class="sk-circle sk-center">
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  </div>
</ng-template>
