import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { LayoutService } from 'src/app/services/layout.service';
import { CustomNavigationMode } from '../../services/custom-navigation';
import { CustomFormsService } from '../../services/custom-forms.service';
import { Response } from 'src/app/models/response.model';
import { ModalService } from 'src/app/modals/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-multi-family',
  templateUrl: './multi-family.component.html',
  styleUrls: ['./multi-family.component.scss']
})
export class MultiFamilyComponent implements OnInit, OnDestroy {
  @ViewChild('submitSuccess') private submitSuccess: SwalComponent;
  @ViewChild('submitError') private submitError: SwalComponent;
  @ViewChild('wizard') private wizard: WizardComponent;
  public errorList = [];

  public form: FormGroup
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public numErrors: number = 0;
  public key: string = '';
  public responseCache: Response = null;

  private routeSubscription: Subscription;
  private dataSubscription: Subscription;
  private formSubscription: Subscription;

  public navigationMode: CustomNavigationMode;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private layoutService: LayoutService,
    private customFormsService: CustomFormsService,
    private modalService: ModalService,
    private toastrService: ToastrService) {
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(true);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl(''),

      "County_ComboBox": new FormControl('', [Validators.required]),
      "APN_1": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_2": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_3": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "New_Construction": new FormControl(''),
      "Street_Number": new FormControl('', [Validators.required]),
      "Street_Number_Modifier": new FormControl(''),
      "Street_Direction": new FormControl(''),
      "Street_Address": new FormControl('', [Validators.required]),
      "Street_Suffix": new FormControl(''),
      "Post_Direction": new FormControl(''),
      "Unit_Number": new FormControl(''),
      "City_Fill_In": new FormControl('', [Validators.required]),
      "Zip_Code": new FormControl('', [Validators.required]),
      "Zip_Plus_4": new FormControl(''),
      "Cross_Street": new FormControl('', [Validators.required]),
      "Office_ID": new FormControl('', [Validators.required]),
      "Office_MLS_ID": new FormControl('', [Validators.required]),
      "Agent_MLS_ID": new FormControl('', [Validators.required]),
      "Co_Office_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Agent_MLS_ID').value != '')]),
      "Co_Agent_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Office_MLS_ID').value != '')]),
      "Prop_Subtype_0001": new FormControl(''),
      "Prop_Subtype_0002": new FormControl(''),
      "Prop_Subtype_0003": new FormControl(''),
      "TTLU": new FormControl(''),
      "0050TTLU": new FormControl(''),
      "Transaction": new FormControl('', [Validators.required]),
      "SSLE": new FormControl('', [Validators.required]),
      "REBK": new FormControl('', [Validators.required]),
      "Lease_Price": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Lease_Type": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Sub_Lease": new FormControl(''),
      "Available_Date": new FormControl(''),
      "Lease_Expiration": new FormControl(''),
      "APNS": new FormControl(''),
      "0120APN2": new FormControl(''),
      "0130APN3": new FormControl(''),
      "0140APN4": new FormControl(''),
      "0150APN5": new FormControl(''),
      "Listing_Price_1": new FormControl('', [Validators.required]),
      "Listing_Price_2": new FormControl(''),
      "Agreement_Type_ComboBox": new FormControl('', [Validators.required]),
      "SCOP": new FormControl(''),
      "0255SCOP": new FormControl(''),
      "DUAL": new FormControl(''),
      "0240DUAL": new FormControl(''),
      "Publish_To_Internet": new FormControl(''),
      "Show_AVM_On_VOW": new FormControl('', [Validators.required]),
      "Show_Address_to_Public": new FormControl('', [Validators.required]),
      "Show_Comments_On_VOW": new FormControl('', [Validators.required]),
      //"Dollar_Percent": new FormControl('', [Validators.required]),
      //"Commission_1": new FormControl(''),
      //"Commission_Amount": new FormControl('', [Validators.required]),
      //"Variable_Rate_Comm": new FormControl('', [Validators.required]),
      "Comm_Comments": new FormControl(''),
      "EOFF": new FormControl('', [Validators.required]),
      "0400EOAD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('EOFF').value == 'YESY')]),
      "Listing_Date": new FormControl('', [Validators.required]),
      "Expiration_Date": new FormControl('', [Validators.required]),
      "Owner_Name": new FormControl(''),
      "Owner_Phone_Area": new FormControl(''),
      "Owner_Phone_Prefix": new FormControl(''),
      "Owner_Phone_Suffix": new FormControl(''),
      "Occupant_Type": new FormControl('', [Validators.required]),
      "Occupant_Name": new FormControl(''),
      "Phone_to_Show_Area": new FormControl(''),
      "Phone_to_Show_Prefix": new FormControl(''),
      "Phone_to_Show_Suffix": new FormControl(''),
      "Bedrooms_ComboBox": new FormControl('', [Validators.required]),
      "Bathrooms_ComboBox": new FormControl('', [Validators.required]),
      "Struc_Sq_Ft": new FormControl('', [Validators.required]),
      "SqFt_Source": new FormControl('', [Validators.required]),
      "Lot_Sq_Ft": new FormControl(''),
      "Lot_Measurement": new FormControl(''),
      //"Lot_Measurement": new FormControl(''),
      "LotSize_Source": new FormControl(''),
      "Year_Built": new FormControl('', [Validators.required]),
      "Unknown_Year": new FormControl(''),
      "Property_Description": new FormControl('', [Validators.required]),
      "Agent_Only_Remarks": new FormControl(''),
      "Directions": new FormControl('', [Validators.required]),
      "School_District_Fill_In": new FormControl('', [Validators.required]),
      "School_Code_1": new FormControl('', [Validators.required]),
      "School_Code_2": new FormControl('', [Validators.required]),
      "School_Code_3": new FormControl('', [Validators.required]),
      "0610SCMT": new FormControl(''),
      "CNSS": new FormControl(''),
      "0650CNSS": new FormControl(''),
      "AREA": new FormControl('', [Validators.required]),
      "0660AREA": new FormControl(''),
      "Area_Fill_In": new FormControl('', [Validators.required]),
      "NUMOFSTORIES": new FormGroup({
        "STORSNGL": new FormControl(''),
        "Checkbox_Comment_STORSNGL": new FormControl(''),
        "STORTWOS": new FormControl(''),
        "Checkbox_Comment_STORTWOS": new FormControl(''),
        "STORTHRM": new FormControl(''),
        "Checkbox_Comment_STORTHRM": new FormControl(''),
        "STORSPLT": new FormControl(''),
        "Checkbox_Comment_STORSPLT": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "ROOFING": new FormGroup({
        "ROOFSHAK": new FormControl(''),
        "Checkbox_Comment_ROOFSHAK": new FormControl(''),
        "ROOFWOOD": new FormControl(''),
        "Checkbox_Comment_ROOFWOOD": new FormControl(''),
        "ROOFCOMP": new FormControl(''),
        "Checkbox_Comment_ROOFCOMP": new FormControl(''),
        "ROOFROLL": new FormControl(''),
        "Checkbox_Comment_ROOFROLL": new FormControl(''),
        "ROOFTLSL": new FormControl(''),
        "Checkbox_Comment_ROOFTLSL": new FormControl(''),
        "ROOFTRGR": new FormControl(''),
        "Checkbox_Comment_ROOFTRGR": new FormControl(''),
        "ROOFCONC": new FormControl(''),
        "Checkbox_Comment_ROOFCONC": new FormControl(''),
        "ROOFMETL": new FormControl(''),
        "Checkbox_Comment_ROOFMETL": new FormControl(''),
        "ROOFFIBR": new FormControl(''),
        "Checkbox_Comment_ROOFFIBR": new FormControl(''),
        "ROOFOTHR": new FormControl(''),
        "Checkbox_Comment_ROOFOTHR": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "EXTERIOR": new FormGroup({
        "EXTRSTUC": new FormControl(''),
        "Checkbox_Comment_EXTRSTUC": new FormControl(''),
        "EXTRWOOD": new FormControl(''),
        "Checkbox_Comment_EXTRWOOD": new FormControl(''),
        "EXTRBRIK": new FormControl(''),
        "Checkbox_Comment_EXTRBRIK": new FormControl(''),
        "EXTRBLCK": new FormControl(''),
        "Checkbox_Comment_EXTRBLCK": new FormControl(''),
        "EXTRSTON": new FormControl(''),
        "Checkbox_Comment_EXTRSTON": new FormControl(''),
        "EXTRMETL": new FormControl(''),
        "Checkbox_Comment_EXTRMETL": new FormControl(''),
        "EXTRSHGL": new FormControl(''),
        "Checkbox_Comment_EXTRSHGL": new FormControl(''),
        "EXTRLOGG": new FormControl(''),
        "Checkbox_Comment_EXTRLOGG": new FormControl(''),
        "EXTROTHR": new FormControl(''),
        "Checkbox_Comment_EXTROTHR": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SEWERWATER": new FormGroup({
        "WATRPWTR": new FormControl(''),
        "Checkbox_Comment_WATRPWTR": new FormControl(''),
        "WATRDWTR": new FormControl(''),
        "Checkbox_Comment_WATRDWTR": new FormControl(''),
        "WATRPVWT": new FormControl(''),
        "Checkbox_Comment_WATRPVWT": new FormControl(''),
        "WATRSWEL": new FormControl(''),
        "Checkbox_Comment_WATRSWEL": new FormControl(''),
        "WATRCWEL": new FormControl(''),
        "Checkbox_Comment_WATRCWEL": new FormControl(''),
        "WATRPSWR": new FormControl(''),
        "Checkbox_Comment_WATRPSWR": new FormControl(''),
        "WATRSEPT": new FormControl(''),
        "Checkbox_Comment_WATRSEPT": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "GASELECTRIC": new FormGroup({
        "GSELPUBU": new FormControl(''),
        "Checkbox_Comment_GSELPUBU": new FormControl(''),
        "GSELNGAS": new FormControl(''),
        "Checkbox_Comment_GSELNGAS": new FormControl(''),
        "GSELPROP": new FormControl(''),
        "Checkbox_Comment_GSELPROP": new FormControl(''),
        "GSELNOGS": new FormControl(''),
        "Checkbox_Comment_GSELNOGS": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SPECSPSZ": new FormControl(''),
      "Checkbox_Comment_SPECSPSZ": new FormControl(''),
      "SPECFLOD": new FormControl(''),
      "Checkbox_Comment_SPECFLOD": new FormControl(''),
      "SPECTOXI": new FormControl(''),
      "Checkbox_Comment_SPECTOXI": new FormControl(''),
      "SPECSMOK": new FormControl(''),
      "Checkbox_Comment_SPECSMOK": new FormControl(''),
      "SPECBLDR": new FormControl(''),
      "Checkbox_Comment_SPECBLDR": new FormControl(''),
      "SPECDEED": new FormControl(''),
      "Checkbox_Comment_SPECDEED": new FormControl(''),
      "SPECEASM": new FormControl(''),
      "Checkbox_Comment_SPECEASM": new FormControl(''),
      "SPECCTTX": new FormControl(''),
      "Checkbox_Comment_SPECCTTX": new FormControl(''),
      "SPECASSM": new FormControl(''),
      "Checkbox_Comment_SPECASSM": new FormControl(''),
      "SPECTFEX": new FormControl(''),
      "Checkbox_Comment_SPECTFEX": new FormControl(''),
      "SPECHOEX": new FormControl(''),
      "Checkbox_Comment_SPECHOEX": new FormControl(''),
      "SPECCALL": new FormControl(''),
      "Checkbox_Comment_SPECCALL": new FormControl(''),
      "SPECAGOW": new FormControl(''),
      "Checkbox_Comment_SPECAGOW": new FormControl(''),
      "SPECDEPR": new FormControl(''),
      "Checkbox_Comment_SPECDEPR": new FormControl(''),
      "SPECFLIT": new FormControl(''),
      "Checkbox_Comment_SPECFLIT": new FormControl(''),
      "SPECOTHR": new FormControl(''),
      "Checkbox_Comment_SPECOTHR": new FormControl(''),
      "SPECSEER": new FormControl(''),
      "Checkbox_Comment_SPECSEER": new FormControl(''),
      "DILOROOF": new FormControl(''),
      "Checkbox_Comment_DILOROOF": new FormControl(''),
      "DILOPEST": new FormControl(''),
      "Checkbox_Comment_DILOPEST": new FormControl(''),
      "DILOSEPT": new FormControl(''),
      "Checkbox_Comment_DILOSEPT": new FormControl(''),
      "DILOWELL": new FormControl(''),
      "Checkbox_Comment_DILOWELL": new FormControl(''),
      "DILOASOC": new FormControl(''),
      "Checkbox_Comment_DILOASOC": new FormControl(''),
      "DILODEMO": new FormControl(''),
      "Checkbox_Comment_DILODEMO": new FormControl(''),
      "DILOFLOR": new FormControl(''),
      "Checkbox_Comment_DILOFLOR": new FormControl(''),
      "DILOLEGA": new FormControl(''),
      "Checkbox_Comment_DILOLEGA": new FormControl(''),
      "DILOZONR": new FormControl(''),
      "Checkbox_Comment_DILOZONR": new FormControl(''),
      "DILOPUBR": new FormControl(''),
      "Checkbox_Comment_DILOPUBR": new FormControl(''),
      "DILOLEAS": new FormControl(''),
      "Checkbox_Comment_DILOLEAS": new FormControl(''),
      "DILOAPPR": new FormControl(''),
      "Checkbox_Comment_DILOAPPR": new FormControl(''),
      "DILOPNLS": new FormControl(''),
      "Checkbox_Comment_DILOPNLS": new FormControl(''),
      "DILOINCX": new FormControl(''),
      "Checkbox_Comment_DILOINCX": new FormControl(''),
      "DILOCASH": new FormControl(''),
      "Checkbox_Comment_DILOCASH": new FormControl(''),
      "DILOINVL": new FormControl(''),
      "Checkbox_Comment_DILOINVL": new FormControl(''),
      "DILOFIXT": new FormControl(''),
      "Checkbox_Comment_DILOFIXT": new FormControl(''),
      "LKBS": new FormControl('', [Validators.required]),
      "0760LKBS": new FormControl(''),
      "Showing_Instructions": new FormControl(''),
      "SHOWINGINSTRUCTIONS": new FormGroup({
        "SHOWLOCK": new FormControl(''),
        "Checkbox_Comment_SHOWLOCK": new FormControl(''),
        "SHOWCLLO": new FormControl(''),
        "Checkbox_Comment_SHOWCLLO": new FormControl(''),
        "SHOWCLTN": new FormControl(''),
        "Checkbox_Comment_SHOWCLTN": new FormControl(''),
        "SHOWCLSM": new FormControl(''),
        "Checkbox_Comment_SHOWCLSM": new FormControl(''),
        "SHOWCLOW": new FormControl(''),
        "Checkbox_Comment_SHOWCLOW": new FormControl(''),
        "SHOWAPPT": new FormControl(''),
        "Checkbox_Comment_SHOWAPPT": new FormControl(''),
        "SHOWDNDT": new FormControl(''),
        "Checkbox_Comment_SHOWDNDT": new FormControl(''),
        "SHOW24HR": new FormControl(''),
        "Checkbox_Comment_SHOW24HR": new FormControl(''),
        "SHOWNOSN": new FormControl(''),
        "Checkbox_Comment_SHOWNOSN": new FormControl(''),
        "SHOWSNST": new FormControl(''),
        "Checkbox_Comment_SHOWSNST": new FormControl(''),
        "SHOWSEAS": new FormControl(''),
        "Checkbox_Comment_SHOWSEAS": new FormControl(''),
        "SHOWFWDR": new FormControl(''),
        "Checkbox_Comment_SHOWFWDR": new FormControl(''),
        "SHOWCBSC": new FormControl(''),
        "Checkbox_Comment_SHOWCBSC": new FormControl(''),
        "SHOWVTGS": new FormControl(''),
        "Checkbox_Comment_SHOWVTGS": new FormControl(''),
        "SHOWALRM": new FormControl(''),
        "Checkbox_Comment_SHOWALRM": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "Garage_Spaces": new FormControl('', [Validators.required]),
      "GART": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Garage_Spaces').value > 0)]),
      "0842GART": new FormControl(''),
      "PARKGARG": new FormControl(''),
      "Checkbox_Comment_PARKGARG": new FormControl(''),
      "PARKCRPT": new FormControl(''),
      "Checkbox_Comment_PARKCRPT": new FormControl(''),
      "PARKCVRD": new FormControl(''),
      "Checkbox_Comment_PARKCVRD": new FormControl(''),
      "PARKUCVR": new FormControl(''),
      "Checkbox_Comment_PARKUCVR": new FormControl(''),
      "PARKOSPU": new FormControl(''),
      "Checkbox_Comment_PARKOSPU": new FormControl(''),
      "PARKMTOS": new FormControl(''),
      "Checkbox_Comment_PARKMTOS": new FormControl(''),
      "CRPT": new FormControl('', [Validators.required]),
      "0860CRPT": new FormControl(''),
      "FOUNDATION": new FormGroup({
        "FLORCONC": new FormControl(''),
        "Checkbox_Comment_FLORCONC": new FormControl(''),
        "FLORWOOD": new FormControl(''),
        "Checkbox_Comment_FLORWOOD": new FormControl(''),
        "FLORBOTH": new FormControl(''),
        "Checkbox_Comment_FLORBOTH": new FormControl(''),
        "FCLTPOOL": new FormControl(''),
        "Checkbox_Comment_FCLTPOOL": new FormControl(''),
        "FCLTJCZI": new FormControl(''),
        "Checkbox_Comment_FCLTJCZI": new FormControl(''),
        "FCLTCBNA": new FormControl(''),
        "Checkbox_Comment_FCLTCBNA": new FormControl(''),
        "FCLTTNSC": new FormControl(''),
        "Checkbox_Comment_FCLTTNSC": new FormControl(''),
        "FCLTBBAL": new FormControl(''),
        "Checkbox_Comment_FCLTBBAL": new FormControl(''),
        "FCLTXRCS": new FormControl(''),
        "Checkbox_Comment_FCLTXRCS": new FormControl(''),
        "FCLTPLAY": new FormControl(''),
        "Checkbox_Comment_FCLTPLAY": new FormControl(''),
        "FCLTPUTT": new FormControl(''),
        "Checkbox_Comment_FCLTPUTT": new FormControl(''),
        "FCLTUTIL": new FormControl(''),
        "Checkbox_Comment_FCLTUTIL": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SFTRPRVP": new FormControl(''),
      "Checkbox_Comment_SFTRPRVP": new FormControl(''),
      "SFTRPRVB": new FormControl(''),
      "Checkbox_Comment_SFTRPRVB": new FormControl(''),
      "SFTRXTRA": new FormControl(''),
      "Checkbox_Comment_SFTRXTRA": new FormControl(''),
      "SFTRONST": new FormControl(''),
      "Checkbox_Comment_SFTRONST": new FormControl(''),
      "SFTRLAUN": new FormControl(''),
      "Checkbox_Comment_SFTRLAUN": new FormControl(''),
      "SFTRSECG": new FormControl(''),
      "Checkbox_Comment_SFTRSECG": new FormControl(''),
      "SFTRSECP": new FormControl(''),
      "Checkbox_Comment_SFTRSECP": new FormControl(''),
      "SFTRFURN": new FormControl(''),
      "Checkbox_Comment_SFTRFURN": new FormControl(''),
      "LEASWSDR": new FormControl(''),
      "Checkbox_Comment_LEASWSDR": new FormControl(''),
      "LEASPROP": new FormControl(''),
      "Checkbox_Comment_LEASPROP": new FormControl(''),
      "LEASWTRS": new FormControl(''),
      "Checkbox_Comment_LEASWTRS": new FormControl(''),
      "LEASSESS": new FormControl(''),
      "Checkbox_Comment_LEASSESS": new FormControl(''),
      "LEASSOLP": new FormControl(''),
      "Checkbox_Comment_LEASSOLP": new FormControl(''),
      "SITECRNR": new FormControl(''),
      "Checkbox_Comment_SITECRNR": new FormControl(''),
      "SITECULD": new FormControl(''),
      "Checkbox_Comment_SITECULD": new FormControl(''),
      "SITEBLUF": new FormControl(''),
      "Checkbox_Comment_SITEBLUF": new FormControl(''),
      "SITELAKE": new FormControl(''),
      "Checkbox_Comment_SITELAKE": new FormControl(''),
      "SITEWTRF": new FormControl(''),
      "Checkbox_Comment_SITEWTRF": new FormControl(''),
      "SITEGFCV": new FormControl(''),
      "Checkbox_Comment_SITEGFCV": new FormControl(''),
      "SITEPRKV": new FormControl(''),
      "Checkbox_Comment_SITEPRKV": new FormControl(''),
      "YARDSPRF": new FormControl(''),
      "Checkbox_Comment_YARDSPRF": new FormControl(''),
      "YARDSPRB": new FormControl(''),
      "Checkbox_Comment_YARDSPRB": new FormControl(''),
      "YARDSPRA": new FormControl(''),
      "Checkbox_Comment_YARDSPRA": new FormControl(''),
      "YARDSPRM": new FormControl(''),
      "Checkbox_Comment_YARDSPRM": new FormControl(''),
      "YARDFENC": new FormControl(''),
      "Checkbox_Comment_YARDFENC": new FormControl(''),
      "YARDLAND": new FormControl(''),
      "Checkbox_Comment_YARDLAND": new FormControl(''),
      "TTLB": new FormControl(''),
      "0930TTLB": new FormControl(''),
      "HEATCOOL": new FormGroup({
        "HTCLCNHC": new FormControl(''),
        "Checkbox_Comment_HTCLCNHC": new FormControl(''),
        "HTCLCNHT": new FormControl(''),
        "Checkbox_Comment_HTCLCNHT": new FormControl(''),
        "HTCLCNCL": new FormControl(''),
        "Checkbox_Comment_HTCLCNCL": new FormControl(''),
        "HTCLEVAP": new FormControl(''),
        "Checkbox_Comment_HTCLEVAP": new FormControl(''),
        "HTCLFLWL": new FormControl(''),
        "Checkbox_Comment_HTCLFLWL": new FormControl(''),
        "HTCLWNWL": new FormControl(''),
        "Checkbox_Comment_HTCLWNWL": new FormControl(''),
        "HTCLNONE": new FormControl(''),
        "Checkbox_Comment_HTCLNONE": new FormControl('')
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "FIRE": new FormControl('', [Validators.required]),
      "0970FIRE": new FormControl(''),
      "STOV": new FormControl(''),
      "0980STOV": new FormControl(''),
      "REFR": new FormControl(''),
      "0990REFR": new FormControl(''),
      "DISH": new FormControl(''),
      "1000DISH": new FormControl(''),
      "DISP": new FormControl(''),
      "1010DISP": new FormControl(''),
      "DSABWLCA": new FormControl(''),
      "Checkbox_Comment_DSABWLCA": new FormControl(''),
      "DSABRAMP": new FormControl(''),
      "Checkbox_Comment_DSABRAMP": new FormControl(''),
      "DSABWIDD": new FormControl(''),
      "Checkbox_Comment_DSABWIDD": new FormControl(''),
      "DSABHAND": new FormControl(''),
      "Checkbox_Comment_DSABHAND": new FormControl(''),
      "DSABBATH": new FormControl(''),
      "Checkbox_Comment_DSABBATH": new FormControl(''),
      "DSABSHOW": new FormControl(''),
      "Checkbox_Comment_DSABSHOW": new FormControl(''),
      "DSAB1LVL": new FormControl(''),
      "Checkbox_Comment_DSAB1LVL": new FormControl(''),
      "AINC": new FormControl('', [Validators.required]),
      "1050AINC": new FormControl(''),
      "AEXP": new FormControl('', [Validators.required]),
      "1060AEXP": new FormControl(''),
      "NINC": new FormControl('', [Validators.required]),
      "1070NINC": new FormControl(''),
      "TENANTPAYS": new FormGroup({
        "TNTPNONE": new FormControl(''),
        "Checkbox_Comment_TNTPNONE": new FormControl(''),
        "TNTPALLL": new FormControl(''),
        "Checkbox_Comment_TNTPALLL": new FormControl(''),
        "TNTPELEC": new FormControl(''),
        "Checkbox_Comment_TNTPELEC": new FormControl(''),
        "TNTPGASS": new FormControl(''),
        "Checkbox_Comment_TNTPGASS": new FormControl(''),
        "TNTPPROP": new FormControl(''),
        "Checkbox_Comment_TNTPPROP": new FormControl(''),
        "TNTPWATR": new FormControl(''),
        "Checkbox_Comment_TNTPWATR": new FormControl(''),
        "TNTPSEWR": new FormControl(''),
        "Checkbox_Comment_TNTPSEWR": new FormControl(''),
        "TNTPTRSH": new FormControl(''),
        "Checkbox_Comment_TNTPTRSH": new FormControl(''),
        "TNTPCBLE": new FormControl(''),
        "Checkbox_Comment_TNTPCBLE": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "INCOMEINCLUDES": new FormGroup({
        "INCIRENT": new FormControl(''),
        "Checkbox_Comment_INCIRENT": new FormControl(''),
        "INCIGARG": new FormControl(''),
        "Checkbox_Comment_INCIGARG": new FormControl(''),
        "INCILNDR": new FormControl(''),
        "Checkbox_Comment_INCILNDR": new FormControl(''),
        "INCIOTHR": new FormControl(''),
        "Checkbox_Comment_INCIOTHR": new FormControl(''),
        "INCIALLL": new FormControl(''),
        "Checkbox_Comment_INCIALLL": new FormControl(''),
        "INCIMNTH": new FormControl(''),
        "Checkbox_Comment_INCIMNTH": new FormControl(''),
        "INCILEAS": new FormControl(''),
        "Checkbox_Comment_INCILEAS": new FormControl(''),
        "INCIBOTH": new FormControl(''),
        "Checkbox_Comment_INCIBOTH": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "EXPENSESINCLUDE": new FormGroup({
        "EXPIALLL": new FormControl(''),
        "Checkbox_Comment_EXPIALLL": new FormControl(''),
        "EXPIMNGT": new FormControl(''),
        "Checkbox_Comment_EXPIMNGT": new FormControl(''),
        "EXPIGRDN": new FormControl(''),
        "Checkbox_Comment_EXPIGRDN": new FormControl(''),
        "EXPIMNTN": new FormControl(''),
        "Checkbox_Comment_EXPIMNTN": new FormControl(''),
        "EXPIINSR": new FormControl(''),
        "Checkbox_Comment_EXPIINSR": new FormControl(''),
        "EXPIACCT": new FormControl(''),
        "Checkbox_Comment_EXPIACCT": new FormControl(''),
        "EXPILICN": new FormControl(''),
        "Checkbox_Comment_EXPILICN": new FormControl(''),
        "EXPIGASS": new FormControl(''),
        "Checkbox_Comment_EXPIGASS": new FormControl(''),
        "EXPIPROP": new FormControl(''),
        "Checkbox_Comment_EXPIPROP": new FormControl(''),
        "EXPIELEC": new FormControl(''),
        "Checkbox_Comment_EXPIELEC": new FormControl(''),
        "EXPIWATR": new FormControl(''),
        "Checkbox_Comment_EXPIWATR": new FormControl(''),
        "EXPISEWR": new FormControl(''),
        "Checkbox_Comment_EXPISEWR": new FormControl(''),
        "EXPIPOOL": new FormControl(''),
        "Checkbox_Comment_EXPIPOOL": new FormControl(''),
        "EXPITAXS": new FormControl(''),
        "Checkbox_Comment_EXPITAXS": new FormControl(''),
        "EXPIVCNC": new FormControl(''),
        "Checkbox_Comment_EXPIVCNC": new FormControl(''),
        "EXPINONE": new FormControl(''),
        "Checkbox_Comment_EXPINONE": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SOURCESOFINCEXP": new FormGroup({
        "SRCSTAXR": new FormControl(''),
        "Checkbox_Comment_SRCSTAXR": new FormControl(''),
        "SRCSACCT": new FormControl(''),
        "Checkbox_Comment_SRCSACCT": new FormControl(''),
        "SRCSOWNR": new FormControl(''),
        "Checkbox_Comment_SRCSOWNR": new FormControl(''),
        "SRCSPROJ": new FormControl(''),
        "Checkbox_Comment_SRCSPROJ": new FormControl(''),
        "SRCSLMTD": new FormControl(''),
        "Checkbox_Comment_SRCSLMTD": new FormControl(''),
        "SRCSPMAN": new FormControl(''),
        "Checkbox_Comment_SRCSPMAN": new FormControl(''),
        "SRCSNONE": new FormControl(''),
        "Checkbox_Comment_SRCSNONE": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "1SLNASSM": new FormControl(''),
      "Checkbox_Comment_1SLNASSM": new FormControl(''),
      "1SLNNASS": new FormControl(''),
      "Checkbox_Comment_1SLNNASS": new FormControl(''),
      "1SLNPRIV": new FormControl(''),
      "Checkbox_Comment_1SLNPRIV": new FormControl(''),
      "1SLNFRCL": new FormControl(''),
      "Checkbox_Comment_1SLNFRCL": new FormControl(''),
      "2LNASSM": new FormControl(''),
      "Checkbox_Comment_2LNASSM": new FormControl(''),
      "2LNPRIV": new FormControl(''),
      "Checkbox_Comment_2LNPRIV": new FormControl(''),
      "2LNNONA": new FormControl(''),
      "Checkbox_Comment_2LNNONA": new FormControl(''),
      "TERMGOVT": new FormControl(''),
      "Checkbox_Comment_TERMGOVT": new FormControl(''),
      "TERMCONV": new FormControl(''),
      "Checkbox_Comment_TERMCONV": new FormControl(''),
      "TERMCASH": new FormControl(''),
      "Checkbox_Comment_TERMCASH": new FormControl(''),
      "TERMSUBM": new FormControl(''),
      "Checkbox_Comment_TERMSUBM": new FormControl(''),
      "TERMOWNR": new FormControl(''),
      "Checkbox_Comment_TERMOWNR": new FormControl(''),
      "TERMLIMI": new FormControl(''),
      "Checkbox_Comment_TERMLIMI": new FormControl(''),
      "HSSB": new FormControl(''),
      "STTD": new FormControl(''),
      "1180STTD": new FormControl(''),
      "SDTD": new FormControl(''),
      "1190SDTD": new FormControl(''),
      "1200SSSS": new FormControl(''),
      "ADDD": new FormControl(''),
      "1201ADDD": new FormControl(''),
      "UNIQPROB": new FormControl(''),
      "Checkbox_Comment_UNIQPROB": new FormControl(''),
      "UNIQESTA": new FormControl(''),
      "Checkbox_Comment_UNIQESTA": new FormControl(''),
      "UNIQCORT": new FormControl(''),
      "Checkbox_Comment_UNIQCORT": new FormControl(''),
      "UNIQSHRT": new FormControl(''),
      "Checkbox_Comment_UNIQSHRT": new FormControl(''),
      "UNIQEXCH": new FormControl(''),
      "Checkbox_Comment_UNIQEXCH": new FormControl(''),
      "SALETTLP": new FormControl(''),
      "Checkbox_Comment_SALETTLP": new FormControl(''),
      "SALEDIVD": new FormControl(''),
      "Checkbox_Comment_SALEDIVD": new FormControl(''),
      "SALEADJL": new FormControl(''),
      "Checkbox_Comment_SALEADJL": new FormControl(''),

      "UNITDETAILSNUM": new FormControl('', [Validators.required]),


      "UNIT1_DESCRIPTION": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 1)]),
      "UNIT1_NUMBER": new FormControl(''),
      "UNIT1_IDENUNITS": new FormControl(''),
      "UNIT1_SQFEET": new FormControl(''),
      "UNIT1_OCCUPIED": new FormControl(false),
      "UNIT1_RENT": new FormControl(''),
      "UNIT1_BEDROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 1)]),
      "UNIT1_BATHROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 1)]),

      "UNIT2_DESCRIPTION": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 2)]),
      "UNIT2_NUMBER": new FormControl(''),
      "UNIT2_IDENUNITS": new FormControl(''),
      "UNIT2_SQFEET": new FormControl(''),
      "UNIT2_OCCUPIED": new FormControl(false),
      "UNIT2_RENT": new FormControl(''),
      "UNIT2_BEDROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 2)]),
      "UNIT2_BATHROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 2)]),

      "UNIT3_DESCRIPTION": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 3)]),
      "UNIT3_NUMBER": new FormControl(''),
      "UNIT3_IDENUNITS": new FormControl(''),
      "UNIT3_SQFEET": new FormControl(''),
      "UNIT3_OCCUPIED": new FormControl(false),
      "UNIT3_RENT": new FormControl(''),
      "UNIT3_BEDROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 3)]),
      "UNIT3_BATHROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 3)]),

      "UNIT4_DESCRIPTION": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 4)]),
      "UNIT4_NUMBER": new FormControl(''),
      "UNIT4_IDENUNITS": new FormControl(''),
      "UNIT4_SQFEET": new FormControl(''),
      "UNIT4_OCCUPIED": new FormControl(false),
      "UNIT4_RENT": new FormControl(''),
      "UNIT4_BEDROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 4)]),
      "UNIT4_BATHROOMS": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('UNITDETAILSNUM').value >= 4)]),

    }, { updateOn: 'change' });


    // Customized conditionals and form setters
    //this.form.get('POOLTYPE').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('Pool').value == 'Yes')]);
    //this.form.get('HOAAMENITIES').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('HOAA').value == 'Yes')]);

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      const temp = value;
      Object.keys(temp).forEach(key => {
        if(!temp[key] || temp[key] == '' || temp[key] == null)
          delete temp[key];
      });
      localStorage.setItem('cachedFormData', JSON.stringify(temp));

      this.form.get('Co_Office_MLS_ID').updateValueAndValidity({onlySelf: true});
      this.form.get('Co_Agent_MLS_ID').updateValueAndValidity({onlySelf: true});
    })

    this.form.get('Office_ID').valueChanges.subscribe(val => {
      this.form.get('Office_MLS_ID').setValue(val);
    })

    this.formSubscription.add(this.form.get('Street_Number').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Direction').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Address').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Suffix').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.navigationMode = new CustomNavigationMode(this.form);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.key = params.key;
      if(params.key) {
        this.dataSubscription = this.responseService.getResponse(params.key).subscribe(payload => {
          this.form.patchValue(payload.data);
          this.responseCache = payload;
        })
      } else {
        if(localStorage.getItem('cachedFormData')) {
          let confirmation = confirm("You have saved form data, would you like to load it?")

          if(confirmation) {
            let data = localStorage.getItem('cachedFormData');
            this.form.patchValue(JSON.parse(data));
          } else {
            localStorage.removeItem('cachedFormData');
          }
        }
      }
    })
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if(this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if(this.formSubscription)
      this.formSubscription.unsubscribe();

    if(this.responseCache)
      this.responseCache = null;
  }

  get f() { return this.form.controls; }

  submit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if(this.form.valid) {
      this.isLoading = true;
      let temp = this.form.value;
      //if(temp["Dollar_Percent"] == '$') {
      //  temp["Commission_1"] = '$' + temp["Commission_Amount"];
      //} else if(temp["Dollar_Percent"] == '%') {
      //  temp["Commission_1"] = temp["Commission_Amount"] + '%';
      //}
      temp["Office_MLS_ID"] = temp["Office_ID"]; 


      const data = this.customFormsService.sanitizeFormPayload(temp);

      if(!this.responseCache) {
        const response = {
          form: '5f8a24c081ec97dbf6a032ee',
          organization: '',
          name: 'Save_' + Math.random().toString(36).substr(2, 5),
          data: temp,
          submitted: true
        }
        this.responseService.postResponse(response).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        })
      } else {
        this.responseService.patchResponse(this.responseCache._id, {data: data, submitted: true }).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        });
      }
    } else {
      this.numErrors = 0;
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.numErrors++;
              console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });
      this.toastrService.error('You have not completed the form!', 'Submission Failed');
    }
  }

  save() {
    const self = this;
    this.isSaving = true;

    // Remove empty/null inputs to store less
    const data = this.customFormsService.sanitizeFormPayload(this.form.value);
    if(!this.responseCache) {
      this.modalService.openModal('SaveResponse', { backdrop: 'static', keyboard: false }, {formData: data, formId: '5f8a24c081ec97dbf6a032ee'}, function(data) {
        console.log(data);
        if(data.err)
          self.toastrService.error('Save was unsuccessful, please try again.', 'Error 500');
        else {
          self.toastrService.success('Save was successful', 'Success');
          self.router.navigate(['.'], { relativeTo: self.route, queryParams: {key: data._id}, queryParamsHandling: 'merge' });
          self.form.markAsPristine();
        }
      });
    } else {
      if(this.form.dirty) {
        this.responseService.patchResponse(this.responseCache._id, {data: data}).subscribe(res => {
          this.isSaving = false;
          this.responseCache = res;
          this.form.markAsPristine();
          this.toastrService.success('Save was successful', 'Success');
        });
      } else {
        this.toastrService.info('No changes detected', 'Unsuccessful Save');
      }
    }
  }

  validate() {
    this.submitted = true;
    this.numErrors = 0;
    this.errorList = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.numErrors++;
          //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          let errorElem = document.querySelector("[formControlName='" + key + "']");
          if(!errorElem) {
            let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
            errorElem = document.querySelector("[formControlName='" + childKey + "']");
          }

          let stepId = errorElem.closest('aw-wizard-step').getAttribute('stepid')
          this.errorList.push({
            key: key,
            stepId: stepId,
          })
        });
      }
    });
  }

  goToError(error) {
    const key = error.key
    const index = this.wizard.getIndexOfStepWithId(error.stepId);
    this.wizard.goToStep(index);

    let errorElem = document.querySelector("[formControlName='" + key + "']");
    if(!errorElem) {
      let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
      errorElem = document.querySelector("[formControlName='" + childKey + "']");
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + childKey + "']").getBoundingClientRect().top - 250
      }, 100)
    } else {
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + error.key + "']").getBoundingClientRect().top - 200
      }, 100)
    }
  }

  enableControl(condition: boolean, controlNames: string[]) {
    if(condition) {
      controlNames.forEach(name => {
        this.form.controls[name].enable();
      })
    } else {
      controlNames.forEach(name => {
        this.form.controls[name].disable();
      })
    }
  }

  complete() {
    this.layoutService.setSideNavHidden(false);
    this.router.navigate(['../history'], { relativeTo: this.route });
  }

  navigateToSign() {
    this.router.navigate(['../sign-request'], { relativeTo: this.route });
  }
}
