import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from 'src/app/modals/services/modal.service';
import { AccessService } from 'src/app/services/access.service';
import { Subscription, from, empty } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { concatMap, tap, map } from 'rxjs/operators';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-initialization',
  templateUrl: './initialization.component.html',
  styleUrls: ['./initialization.component.scss']
})
export class InitializationComponent implements OnInit, OnDestroy {
  private subscription: Subscription

  constructor(
    private modalService: ModalService,
    private accessService: AccessService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // If the user has no organization found (new signup), then open a Modal to initialize a new organization to be opened
    // Otherwise, find the cache organization, localStorage (?)
    // If none found in localStorage, then query a random one belonging to user
    this.subscription = this.accessService.doesUserHaveOrganization()
      .pipe(
        concatMap(res => {
          if(res)
            return this.organizationService.getOrganization(res.org, 'alias');
          else {
            this.modalService.openModal('NewUserInit', { backdrop: 'static', centered: true, keyboard: false })
            return empty();
          }
        }),
        map((res: any) => {
          if(res)
            this.router.navigate([res.alias]);
        })
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
