import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, concat } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, map, retry, retryWhen, delay, take } from 'rxjs/operators';
import { Router, RouterEvent } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Permissions } from '../models/role.model';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth0Client$ = (from(
    createAuth0Client({
      domain: "ctrlfill.auth0.com",
      client_id: "PWRhr0244imynC5wwxA30fsnmpHgP2zX",
      redirect_uri: environment.base_url + "auth",
      useRefreshTokens: true,
      useCookiesForTransactions: true // Authentication is dependant on this, because we extract code_verifier from cookies
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1),
    catchError(err => throwError(err))
  );

  private loggedIn: boolean = null;

  private userProfileSubject$ = new BehaviorSubject<any>(null);
  public userProfile$ = this.userProfileSubject$.asObservable();

  private userRoleSubject$ = new BehaviorSubject<number>(null);
  public userRole$ = this.userRoleSubject$.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    if(sessionStorage.getItem('userProfile')) {
      this.userProfileSubject$.next(JSON.parse(sessionStorage.getItem('userProfile')));
    }
  }

  public setOrganization$(organization: string): Observable<any> {
    return this.http.post('/api/auth/organization', {organization: organization});
  }

  public setRole$(): Observable<any> {
    // TODO: Once a user has entered an organization, obtain role from Auth0 account
    return this.http.post('/api/auth/role', {}).pipe(
      tap((res: any) => this.userRoleSubject$.next(res.role))
    );
  }

  /*
  public setUser$() {
    if(sessionStorage.getItem('userProfile') === null) {
      return this.http.get('api/auth/identity').pipe(
        tap((res: any) => {
          if(res.data.body) {
            const userProfile = jwt_decode(res.data.body.id_token);
            this.userProfileSubject$.next(userProfile);
          } else {
            this.userProfileSubject$.next(res.data);
          }
        })
      )
    }
    return null;
  }
  */

  // Quick fix for auth guard, use a non-observable function
  public setUser() {
    if(sessionStorage.getItem('userProfile') === null) {
      this.http.get('api/auth/identity').pipe(
        tap((res: any) => {
          if(res.data.body) {
            const userProfile = jwt_decode(res.data.body.id_token);
            this.userProfileSubject$.next(userProfile);
            sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
          } else {
            this.userProfileSubject$.next(res.data);
            sessionStorage.setItem('userProfile', JSON.stringify(res.data));
          }
        })
      ).subscribe();
    }
  }

  public login() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        redirect_uri: environment.base_url + "auth"
      });
    });
  }

  public logout() {
    sessionStorage.removeItem('userProfile');
    const logout = this.auth0Client$.pipe(
      tap(res => {
        this.http.get('/api/auth/logout').subscribe();
      })
    );

    logout.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: "PWRhr0244imynC5wwxA30fsnmpHgP2zX",
        returnTo: `${window.location.origin}`
      });
    });
  }

  public canAccess(permission: Permissions) {
    const access = new BehaviorSubject<boolean>(false);
    this.userRole$.pipe(
      tap(role => {
        if(role & permission) 
          access.next(true);
        else
          access.next(false);
      })
    ).subscribe();
    return access.asObservable();
  }

  // Have a cookie to allow to bypass so server does not need to repeatly check on server when you refresh page
  // Handles checking if an access token has expired by testing to check if a 403 error is returned
  // If expired use a refresh token
  // OR if no access token is found, then force login
  public isAuthenticated$(): Observable<boolean> {
    //if(this.cookieService.check('ct.isAuth'))
    //  return of(true);
    return this.http.get('/api/auth/identity', { observe: 'response' }).pipe(
      map(res => {
        return true;
      }),
      catchError(err => {
        return of(false);
      })
    )
  }

  // Handles initial auth login attempts
  public handleAuthCallback$(code: string) {
    return this.http.post('/api/auth/identity', {code: code}, { observe: 'response' }).pipe(
      retry(2),
      map((res: any) => {
        // Set use profile to be cached and used
        const userProfile = jwt_decode(res.body.id_token);
        this.userProfileSubject$.next(userProfile);

        // Set a cookie so we don't need to do another HTTP request
        //this.cookieService.set('ct.isAuth', 'true', 1);

        // Navigate user to home page
        this.router.navigate(['/']);
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

}
