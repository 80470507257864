import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { Organization } from 'src/app/models/organization.model';
import { take, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-user-init',
  templateUrl: './new-user-init.component.html',
  styleUrls: ['./new-user-init.component.scss']
})
export class NewUserInitComponent {
  public isValid: boolean = true;
  public alreadyExist: boolean = false;
  public organizationName: string = '';

  public isOrganizationCreating: boolean = false;

  constructor(
    private ngbModal: NgbModal,
    private organizationService: OrganizationService,
    private router: Router
  ) { }

  close() {
    this.ngbModal.dismissAll();
    this.isValid = true;
    this.isOrganizationCreating = false;
    this.organizationName = '';
  }

  createOrganization() {
    if(this.organizationName.length < 1 || !/^[a-zA-Z0-9\s]*$/g.test(this.organizationName))
      this.isValid = false;
    else {
      this.alreadyExist = false;
      this.isValid = true;
      this.isOrganizationCreating = true;

      const organization = new Organization(this.organizationName);
      this.organizationService.postOrganization(organization)
        .pipe(take(1))
        .subscribe(data => {
          // TODO: Take user to new organization URL route
          // TODO: Load new organization data
          this.router.navigate([data.alias])
          console.log(data);
          this.close();
        }, err => {
          this.alreadyExist = true;
          this.isOrganizationCreating = false;
        })
    }
    //this.organizationService.postOrganization()
  }
}
