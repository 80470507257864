import { Component, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-residential',
  templateUrl: './view-residential.component.html',
  styleUrls: ['./view-residential.component.scss']
})
export class ViewResidentialComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl({value: '', disabled: true}),

      "Commission_1": new FormControl({value: '', disabled: true}),
      "Property_Type_ComboBox": new FormControl({value: '', disabled: true}),
      "Office_ID": new FormControl({value: '', disabled: true}),
      "Office_MLS_ID": new FormControl({value: '', disabled: true}),
      "Agent_MLS_ID": new FormControl({value: '', disabled: true}),
      "Co_Office_MLS_ID": new FormControl({value: '', disabled: true}),
      "Co_Agent_MLS_ID": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0001": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0002": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0003": new FormControl({value: '', disabled: true}),
      "Transaction": new FormControl({value: '', disabled: true}),
      "APN_1": new FormControl({value: '', disabled: true}),
      "APN_2": new FormControl({value: '', disabled: true}),
      "APN_3": new FormControl({value: '', disabled: true}),
      "Dollar_Percent": new FormControl({value: '', disabled: true}),
      "SSLE": new FormControl({value: '', disabled: true}),
      "REBK": new FormControl({value: '', disabled: true}),
      "Lease_Price": new FormControl({value: '', disabled: true}),
      "Lease_Type": new FormControl({value: '', disabled: true}),
      "Sub_Lease": new FormControl({value: '', disabled: true}),
      "Available_Date": new FormControl({value: '', disabled: true}),
      "Lease_Expiration": new FormControl({value: '', disabled: true}),
      "County_ComboBox": new FormControl({value: '', disabled: true}),
      "New_Construction": new FormControl({value: '', disabled: true}),
      "APNS": new FormControl({value: '', disabled: true}),
      "0100APN2": new FormControl({value: '', disabled: true}),
      "0110APN3": new FormControl({value: '', disabled: true}),
      "0120APN4": new FormControl({value: '', disabled: true}),
      "0130APN5": new FormControl({value: '', disabled: true}),
      "Street_Number": new FormControl({value: '', disabled: true}),
      "Street_Number_Modifier": new FormControl({value: '', disabled: true}),
      "Street_Direction": new FormControl({value: '', disabled: true}),
      "Street_Address": new FormControl({value: '', disabled: true}),
      "Street_Suffix": new FormControl({value: '', disabled: true}),
      "Post_Direction": new FormControl({value: '', disabled: true}),
      "Unit_Number": new FormControl({value: '', disabled: true}),
      "City_Fill_In": new FormControl({value: '', disabled: true}),
      "Zip_Code": new FormControl({value: '', disabled: true}),
      "Zip_Plus_4": new FormControl({value: '', disabled: true}),
      "Cross_Street": new FormControl({value: '', disabled: true}),
      "Listing_Price_1": new FormControl({value: '', disabled: true}),
      "Listing_Price_2": new FormControl({value: '', disabled: true}),
      "Agreement_Type_ComboBox": new FormControl({value: '', disabled: true}),
      "SCOP": new FormControl({value: '', disabled: true}),
      "0225SCOP": new FormControl({value: '', disabled: true}),
      "DUAL": new FormControl({value: '', disabled: true}),
      "0230DUAL": new FormControl({value: '', disabled: true}),
      "Publish_To_Internet": new FormControl({value: '', disabled: true}),
      "Show_Address_to_Public": new FormControl({value: '', disabled: true}),
      "Show_AVM_On_VOW": new FormControl({value: '', disabled: true}),
      "Show_Comments_On_VOW": new FormControl({value: '', disabled: true}),
      //"Commission_Amount": new FormControl({value: '', disabled: true}),
      //"Variable_Rate_Comm": new FormControl({value: '', disabled: true}),
      //"Comm_Comments": new FormControl({value: '', disabled: true}),
      "EOFF": new FormControl({value: '', disabled: true}),
      "0400EOAD": new FormControl({value: '', disabled: true}),
      "Bedrooms_ComboBox": new FormControl({value: '', disabled: true}),
      "Bathrooms_ComboBox": new FormControl({value: '', disabled: true}),
      "Struc_Sq_Ft": new FormControl({value: '', disabled: true}),
      "SqFt_Source": new FormControl({value: '', disabled: true}),
      "Lot_Sq_Ft": new FormControl({value: '', disabled: true}),
      "Lot_Measurement": new FormControl({value: '', disabled: true}),
      "LotSize_Source": new FormControl({value: '', disabled: true}),
      "Year_Built": new FormControl({value: '', disabled: true}),
      "Unknown_Year": new FormControl({value: '', disabled: true}),
      "Listing_Date": new FormControl({value: '', disabled: true}),
      "Expiration_Date": new FormControl({value: '', disabled: true}),
      "OnMarket_Date": new FormControl({value: '', disabled: true}),
      "Owner_Name": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Area": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Prefix": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Suffix": new FormControl({value: '', disabled: true}),
      "Occupant_Type": new FormControl({value: '', disabled: true}),
      "Occupant_Name": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Area": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Prefix": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Suffix": new FormControl({value: '', disabled: true}),
      "Enable_Character_Count": new FormControl({value: '', disabled: true}),
      "Property_Description": new FormControl({value: '', disabled: true}),
      "Agent_Only_Remarks": new FormControl({value: '', disabled: true}),
      "0560XSLE": new FormControl({value: '', disabled: true}),
      "Directions": new FormControl({value: '', disabled: true}),
      "School_District_Fill_In": new FormControl({value: '', disabled: true}),
      "SchoolType1": new FormControl('Grade School'),
      "School_Code_1": new FormControl({value: '', disabled: true}),
      "SchoolType2": new FormControl('Jr High School'),
      "School_Code_2": new FormControl({value: '', disabled: true}),
      "SchoolType3": new FormControl('Sr High School'),
      "School_Code_3": new FormControl({value: '', disabled: true}),
      "0870SCMT": new FormControl({value: '', disabled: true}),
      "STYLRNCH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLRNCH": new FormControl({value: '', disabled: true}),
      "STYLBUNG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLBUNG": new FormControl({value: '', disabled: true}),
      "STYLCLNL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLCLNL": new FormControl({value: '', disabled: true}),
      "STYLSPAN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLSPAN": new FormControl({value: '', disabled: true}),
      "STYLCONT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLCONT": new FormControl({value: '', disabled: true}),
      "STYLTUDR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLTUDR": new FormControl({value: '', disabled: true}),
      "STYLVICT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLVICT": new FormControl({value: '', disabled: true}),
      "STYLMEDI": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLMEDI": new FormControl({value: '', disabled: true}),
      "STYLTOWN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLTOWN": new FormControl({value: '', disabled: true}),
      "STYLCOTT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLCOTT": new FormControl({value: '', disabled: true}),
      "STYLCABN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLCABN": new FormControl({value: '', disabled: true}),
      "STYLMDCM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STYLMDCM": new FormControl({value: '', disabled: true}),
      "CNSS": new FormControl({value: '', disabled: true}),
      "0650CNSS": new FormControl({value: '', disabled: true}),
      "AREA": new FormControl({value: '', disabled: true}),
      "0660AREA": new FormControl({value: '', disabled: true}),
      "Area_Fill_In": new FormControl({value: '', disabled: true}),
      "0690ZONE": new FormControl({value: '', disabled: true}),
      "0700LTDM": new FormControl({value: '', disabled: true}),
      "0710TRCT": new FormControl({value: '', disabled: true}),
      "NUMOFSTORIES": new FormGroup({
        "STORSNGL": new FormControl({value: '', disabled: true}),
        "STORTWOS": new FormControl({value: '', disabled: true}),
        "STORSPLT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_STORSNGL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_STORTWOS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_STORSPLT": new FormControl({value: '', disabled: true})
      }),
      "MBLV": new FormControl({value: '', disabled: true}),
      "SEWERWATER": new FormGroup({
        "WATRPWTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRPWTR": new FormControl({value: '', disabled: true}),
        "WATRPWEL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRPWEL": new FormControl({value: '', disabled: true}),
        "WATRSEPT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRSEPT": new FormControl({value: '', disabled: true}),
        "WATRSWEL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRSWEL": new FormControl({value: '', disabled: true}),
        "WATRCWEL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRCWEL": new FormControl({value: '', disabled: true}),
        "WATRPRVT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRPRVT": new FormControl({value: '', disabled: true}),
      }),
      "GASELECTRIC": new FormGroup({
        "GSELPUBU": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_GSELPUBU": new FormControl({value: '', disabled: true}),
        "GSELPROP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_GSELPROP": new FormControl({value: '', disabled: true}),
        "GSELNOGS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_GSELNOGS": new FormControl({value: '', disabled: true}),
        "GSELALEL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_GSELALEL": new FormControl({value: '', disabled: true}),
        "GSELGENR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_GSELGENR": new FormControl({value: '', disabled: true}),
        "GSELNOEL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_GSELNOEL": new FormControl({value: '', disabled: true}),
      }),
      "CB_UDRS": new FormControl({value: '', disabled: true}),
      "1190UDRS": new FormControl({value: '', disabled: true}),
      "UNDSGRDF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNDSGRDF": new FormControl({value: '', disabled: true}),
      "UNDSSECF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNDSSECF": new FormControl({value: '', disabled: true}),
      "UNDSSPLT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNDSSPLT": new FormControl({value: '', disabled: true}),
      "UNDSENDU": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNDSENDU": new FormControl({value: '', disabled: true}),
      "UNDSINSD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNDSINSD": new FormControl({value: '', disabled: true}),
      "UNDSFRNT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNDSFRNT": new FormControl({value: '', disabled: true}),
      "UNDSREAR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNDSREAR": new FormControl({value: '', disabled: true}),
      "SPFTISBD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTISBD": new FormControl({value: '', disabled: true}),
      "SPFTISBT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTISBT": new FormControl({value: '', disabled: true}),
      "SPFTSTDO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTSTDO": new FormControl({value: '', disabled: true}),
      "SPFTSSYS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTSSYS": new FormControl({value: '', disabled: true}),
      "SPFTSGAT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTSGAT": new FormControl({value: '', disabled: true}),
      "SPFTCABN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTCABN": new FormControl({value: '', disabled: true}),
      "SPFTCTLV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTCTLV": new FormControl({value: '', disabled: true}),
      "SPFTSKYL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTSKYL": new FormControl({value: '', disabled: true}),
      "SPFTWTRS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTWTRS": new FormControl({value: '', disabled: true}),
      "SPFTWTRF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTWTRF": new FormControl({value: '', disabled: true}),
      "SPFTBARR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTBARR": new FormControl({value: '', disabled: true}),
      "SPFTDISH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTDISH": new FormControl({value: '', disabled: true}),
      "SPFTINTC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTINTC": new FormControl({value: '', disabled: true}),
      "SPFTHTEK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTHTEK": new FormControl({value: '', disabled: true}),
      "SPFTMPHN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTMPHN": new FormControl({value: '', disabled: true}),
      "SPFTTENN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTTENN": new FormControl({value: '', disabled: true}),
      "SPFTOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTOTHR": new FormControl({value: '', disabled: true}),
      "SPFTHSAL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTHSAL": new FormControl({value: '', disabled: true}),
      "SPFTHSET": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPFTHSET": new FormControl({value: '', disabled: true}),
      "DSFTWHCR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTWHCR": new FormControl({value: '', disabled: true}),
      "DSFTLWHC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTLWHC": new FormControl({value: '', disabled: true}),
      "DSFTRAMP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTRAMP": new FormControl({value: '', disabled: true}),
      "DSFTWDDR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTWDDR": new FormControl({value: '', disabled: true}),
      "DSFTHDRL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTHDRL": new FormControl({value: '', disabled: true}),
      "DSFTBTHF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTBTHF": new FormControl({value: '', disabled: true}),
      "DSFTWCHS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTWCHS": new FormControl({value: '', disabled: true}),
      "DSFT1LVL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFT1LVL": new FormControl({value: '', disabled: true}),
      "DSFTELEV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DSFTELEV": new FormControl({value: '', disabled: true}),
      "ENERDPWN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ENERDPWN": new FormControl({value: '', disabled: true}),
      "ENERTPWN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ENERTPWN": new FormControl({value: '', disabled: true}),
      "ENERSLPH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ENERSLPH": new FormControl({value: '', disabled: true}),
      "ENERSLWH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ENERSLWH": new FormControl({value: '', disabled: true}),
      "ENERSEAC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ENERSEAC": new FormControl({value: '', disabled: true}),
      "ENERTANK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ENERTANK": new FormControl({value: '', disabled: true}),
      "ENEROTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ENEROTHR": new FormControl({value: '', disabled: true}),
      "SOLA": new FormControl({value: '', disabled: true}),
      "SOLR": new FormControl({value: '', disabled: true}),
      "0810SOLR": new FormControl({value: '', disabled: true}),
      "LSDESECS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDESECS": new FormControl({value: '', disabled: true}),
      "LSDEPROP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDEPROP": new FormControl({value: '', disabled: true}),
      "LSDEWTRS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDEWTRS": new FormControl({value: '', disabled: true}),
      "LSDESATD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDESATD": new FormControl({value: '', disabled: true}),
      "LSDESOLP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDESOLP": new FormControl({value: '', disabled: true}),
      "SECO": new FormControl({value: '', disabled: true}),
      "0830SECO": new FormControl({value: '', disabled: true}),
      "LBOX": new FormControl({value: '', disabled: true}),
      "0840LBOX": new FormControl({value: '', disabled: true}),
      "1120LOCL": new FormControl({value: '', disabled: true}),
      "SHOWING": new FormGroup({
        "SHOWLOCK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWLOCK": new FormControl({value: '', disabled: true}),
        "SHOWKEYO": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWKEYO": new FormControl({value: '', disabled: true}),
        "SHOWGOOC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWGOOC": new FormControl({value: '', disabled: true}),
        "SHOWCALL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCALL": new FormControl({value: '', disabled: true}),
        "SHOWAPPT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWAPPT": new FormControl({value: '', disabled: true}),
        "SHOW24HR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOW24HR": new FormControl({value: '', disabled: true}),
        "SHOWNOSN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWNOSN": new FormControl({value: '', disabled: true}),
        "SHOWSNST": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWSNST": new FormControl({value: '', disabled: true}),
        "SHOWCLAG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCLAG": new FormControl({value: '', disabled: true}),
        "SHOWDOGI": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWDOGI": new FormControl({value: '', disabled: true}),
        "SHOWDOGO": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWDOGO": new FormControl({value: '', disabled: true}),
        "SHOWRSTC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWRSTC": new FormControl({value: '', disabled: true}),
        "SHOWLKGT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWLKGT": new FormControl({value: '', disabled: true}),
        "SHOWSEAS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWSEAS": new FormControl({value: '', disabled: true}),
        "SHOWSHOW": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWSHOW": new FormControl({value: '', disabled: true}),
        "SHOWFWDR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWFWDR": new FormControl({value: '', disabled: true}),
        "SHOWCBSC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCBSC": new FormControl({value: '', disabled: true}),
        "SHOWVTGS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWVTGS": new FormControl({value: '', disabled: true}),
        "SHOWALRM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWALRM": new FormControl({value: '', disabled: true})
      }),
      "0890GTEC": new FormControl({value: '', disabled: true}),
      "Showing_Instructions": new FormControl({value: '', disabled: true}),
      "WLDP": new FormControl({value: '', disabled: true}),
      "0910WLDP": new FormControl({value: '', disabled: true}),
      "WTRL": new FormControl({value: '', disabled: true}),
      "0930WTRL": new FormControl({value: '', disabled: true}),
      "GPM": new FormControl({value: '', disabled: true}),
      "0940GPM": new FormControl({value: '', disabled: true}),
      "WLHP": new FormControl({value: '', disabled: true}),
      "0950WLHP": new FormControl({value: '', disabled: true}),
      "HEATINGANDCOOLING": new FormGroup({
        "HTCLDCSP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLDCSP": new FormControl({value: '', disabled: true}),
        "HTCLCNHC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLCNHC": new FormControl({value: '', disabled: true}),
        "HTCLCNHT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLCNHT": new FormControl({value: '', disabled: true}),
        "HTCLCNCL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLCNCL": new FormControl({value: '', disabled: true}),
        "HTCLEVAP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLEVAP": new FormControl({value: '', disabled: true}),
        "HTCLFLWL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLFLWL": new FormControl({value: '', disabled: true}),
        "HTCLWNWL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLWNWL": new FormControl({value: '', disabled: true}),
        "HTCLWHOL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLWHOL": new FormControl({value: '', disabled: true}),
        "HTCLNONE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLNONE": new FormControl({value: '', disabled: true}),
        "HTCLOTHR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLOTHR": new FormControl({value: '', disabled: true})
      }),
      "FLOO": new FormControl({value: '', disabled: true}),
      "0990FLOO": new FormControl({value: '', disabled: true}),
      "FLORCARP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FLORCARP": new FormControl({value: '', disabled: true}),
      "FLORTILE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FLORTILE": new FormControl({value: '', disabled: true}),
      "FLORVINY": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FLORVINY": new FormControl({value: '', disabled: true}),
      "FLORHDWD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FLORHDWD": new FormControl({value: '', disabled: true}),
      "FLOROTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FLOROTHR": new FormControl({value: '', disabled: true}),
      "FRPN": new FormControl({value: '', disabled: true}),
      "1010FRPN": new FormControl({value: '', disabled: true}),
      "FRPTMASN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FRPTMASN": new FormControl({value: '', disabled: true}),
      "FRPTGASO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FRPTGASO": new FormControl({value: '', disabled: true}),
      "FRPTWOOD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FRPTWOOD": new FormControl({value: '', disabled: true}),
      "FRPTZERO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FRPTZERO": new FormControl({value: '', disabled: true}),
      "FRPTFREE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FRPTFREE": new FormControl({value: '', disabled: true}),
      "FRPTPELL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FRPTPELL": new FormControl({value: '', disabled: true}),
      "1030FIRL": new FormControl({value: '', disabled: true}),
      "LAUNDRY": new FormGroup({
        "LNDRINSD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRINSD": new FormControl({value: '', disabled: true}),
        "LNDRINGR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRINGR": new FormControl({value: '', disabled: true}),
        "LNDRINCL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRINCL": new FormControl({value: '', disabled: true}),
        "LNDRUTRM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRUTRM": new FormControl({value: '', disabled: true}),
        "LNDRONPR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRONPR": new FormControl({value: '', disabled: true}),
        "LNDRONUP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRONUP": new FormControl({value: '', disabled: true}),
        "LNDRONLW": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRONLW": new FormControl({value: '', disabled: true}),
        "LNDRGSHK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRGSHK": new FormControl({value: '', disabled: true}),
        "LNDRELHK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRELHK": new FormControl({value: '', disabled: true}),
        "LNDRCMMN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRCMMN": new FormControl({value: '', disabled: true}),
        "LNDRCOIN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRCOIN": new FormControl({value: '', disabled: true}),
        "LNDRNONE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LNDRNONE": new FormControl({value: '', disabled: true})
      }),
      "BTHFTUBS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BTHFTUBS": new FormControl({value: '', disabled: true}),
      "BTHFSHWR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BTHFSHWR": new FormControl({value: '', disabled: true}),
      "BTHFTUB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BTHFTUB": new FormControl({value: '', disabled: true}),
      "BTHFOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BTHFOTHR": new FormControl({value: '', disabled: true}),
      "BTHFJTUB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BTHFJTUB": new FormControl({value: '', disabled: true}),
      "BTHFOTUB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BTHFOTUB": new FormControl({value: '', disabled: true}),
      "BTHFRTUB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BTHFRTUB": new FormControl({value: '', disabled: true}),
      "KITCRNOV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCRNOV": new FormControl({value: '', disabled: true}),
      "KITCBLRO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCBLRO": new FormControl({value: '', disabled: true}),
      "KITCGASA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCGASA": new FormControl({value: '', disabled: true}),
      "KITCELCA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCELCA": new FormControl({value: '', disabled: true}),
      "KITCDISP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCDISP": new FormControl({value: '', disabled: true}),
      "KITCDISH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCDISH": new FormControl({value: '', disabled: true}),
      "KITCMICR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCMICR": new FormControl({value: '', disabled: true}),
      "KITCTRSH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCTRSH": new FormControl({value: '', disabled: true}),
      "KITCEATA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCEATA": new FormControl({value: '', disabled: true}),
      "KITCBBAR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCBBAR": new FormControl({value: '', disabled: true}),
      "KITCPANT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCPANT": new FormControl({value: '', disabled: true}),
      "KITCFRIG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCFRIG": new FormControl({value: '', disabled: true}),
      "KITCWINR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCWINR": new FormControl({value: '', disabled: true}),
      "KITCOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_KITCOTHR": new FormControl({value: '', disabled: true}),
      "DINFFRML": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DINFFRML": new FormControl({value: '', disabled: true}),
      "DINFLVRA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DINFLVRA": new FormControl({value: '', disabled: true}),
      "DINFFMRA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DINFFMRA": new FormControl({value: '', disabled: true}),
      "BSSQ": new FormControl({value: '', disabled: true}),
      "1080BSSQ": new FormControl({value: '', disabled: true}),
      "BSMDFULL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BSMDFULL": new FormControl({value: '', disabled: true}),
      "BSMDPART": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BSMDPART": new FormControl({value: '', disabled: true}),
      "BSMDFINS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BSMDFINS": new FormControl({value: '', disabled: true}),
      "BSMDUNFN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BSMDUNFN": new FormControl({value: '', disabled: true}),
      "BSMDNONE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_BSMDNONE": new FormControl({value: '', disabled: true}),
      "XTRRGRTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRRGRTR": new FormControl({value: '', disabled: true}),
      "XTRROFC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRROFC": new FormControl({value: '', disabled: true}),
      "XTRRFMRM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRRFMRM": new FormControl({value: '', disabled: true}),
      "XTRRDENS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRRDENS": new FormControl({value: '', disabled: true}),
      "XTRRLOFT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRRLOFT": new FormControl({value: '', disabled: true}),
      "XTRRGAME": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRRGAME": new FormControl({value: '', disabled: true}),
      "XTRRCNVG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRRCNVG": new FormControl({value: '', disabled: true}),
      "XTRRWINE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRRWINE": new FormControl({value: '', disabled: true}),
      "XTRROTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XTRROTHR": new FormControl({value: '', disabled: true}),
      "LIVL": new FormControl({value: '', disabled: true}),
      "1130LIVL": new FormControl({value: '', disabled: true}),
      "FRML": new FormControl({value: '', disabled: true}),
      "1140FRML": new FormControl({value: '', disabled: true}),
      "KTCL": new FormControl({value: '', disabled: true}),
      "1150KTCL": new FormControl({value: '', disabled: true}),
      "EATL": new FormControl({value: '', disabled: true}),
      "1160EATL": new FormControl({value: '', disabled: true}),
      "FAML": new FormControl({value: '', disabled: true}),
      "1170FAML": new FormControl({value: '', disabled: true}),
      "GRTL": new FormControl({value: '', disabled: true}),
      "1180GRTL": new FormControl({value: '', disabled: true}),
      "MAIL": new FormControl({value: '', disabled: true}),
      "1190MAIL": new FormControl({value: '', disabled: true}),
      "SBDR": new FormControl({value: '', disabled: true}),
      "1200SBDR": new FormControl({value: '', disabled: true}),
      "TBDR": new FormControl({value: '', disabled: true}),
      "1210TBDR": new FormControl({value: '', disabled: true}),
      "FBDR": new FormControl({value: '', disabled: true}),
      "1220FBDR": new FormControl({value: '', disabled: true}),
      "XTRL": new FormControl({value: '', disabled: true}),
      "1230XTRL": new FormControl({value: '', disabled: true}),
      "UTLL": new FormControl({value: '', disabled: true}),
      "1240UTLL": new FormControl({value: '', disabled: true}),
      "SITECRNR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITECRNR": new FormControl({value: '', disabled: true}),
      "SITECULD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITECULD": new FormControl({value: '', disabled: true}),
      "SITEBLUF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITEBLUF": new FormControl({value: '', disabled: true}),
      "SITEGOLF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITEGOLF": new FormControl({value: '', disabled: true}),
      "SITERIVR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITERIVR": new FormControl({value: '', disabled: true}),
      "SITELAKE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITELAKE": new FormControl({value: '', disabled: true}),
      "SITEPRKV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITEPRKV": new FormControl({value: '', disabled: true}),
      "SITEWTRF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITEWTRF": new FormControl({value: '', disabled: true}),
      "SITECRSF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITECRSF": new FormControl({value: '', disabled: true}),
      "SITEPAST": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITEPAST": new FormControl({value: '', disabled: true}),
      "SITEOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SITEOTHR": new FormControl({value: '', disabled: true}),
      "Garage_Spaces": new FormControl({value: '', disabled: true}),
      "GART": new FormControl({value: '', disabled: true}),
      "1290GART": new FormControl({value: '', disabled: true}),
      "CRPT": new FormControl({value: '', disabled: true}),
      "1310CRPT": new FormControl({value: '', disabled: true}),
      "PARKEXRV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKEXRV": new FormControl({value: '', disabled: true}),
      "PARKPTRV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKPTRV": new FormControl({value: '', disabled: true}),
      "PARKCRPT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKCRPT": new FormControl({value: '', disabled: true}),
      "PARKUNCV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKUNCV": new FormControl({value: '', disabled: true}),
      "PARKWKSH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKWKSH": new FormControl({value: '', disabled: true}),
      "PARKDRTH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKDRTH": new FormControl({value: '', disabled: true}),
      "PARKAOPN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKAOPN": new FormControl({value: '', disabled: true}),
      "PARKSTRT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKSTRT": new FormControl({value: '', disabled: true}),
      "PARKCIRC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKCIRC": new FormControl({value: '', disabled: true}),
      "PARKTNDM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKTNDM": new FormControl({value: '', disabled: true}),
      "PARKOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PARKOTHR": new FormControl({value: '', disabled: true}),
      "Pool": new FormControl({value: '', disabled: true}),
      "POOLTYPE": new FormGroup({
        "POLTINGR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POLTINGR": new FormControl({value: '', disabled: true}),
        "POLTABGR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POLTABGR": new FormControl({value: '', disabled: true}),
      }),
      "PLFEFNCD": new FormControl({value: '', disabled: true}),
      "PLFECMTY": new FormControl({value: '', disabled: true}),
      "PLFEGUNI": new FormControl({value: '', disabled: true}),
      "PLFEVINY": new FormControl({value: '', disabled: true}),
      "PLFEFIBR": new FormControl({value: '', disabled: true}),
      "PLFEHEAT": new FormControl({value: '', disabled: true}),
      "PLFEGRAS": new FormControl({value: '', disabled: true}),
      "PLFEFIBO": new FormControl({value: '', disabled: true}),
      "PLFEPEBB": new FormControl({value: '', disabled: true}),
      "PLFEWTRF": new FormControl({value: '', disabled: true}),
      "ROOFING": new FormGroup({
        "ROOFCOMP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFCOMP": new FormControl({value: '', disabled: true}),
        "ROOFTILE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFTILE": new FormControl({value: '', disabled: true}),
        "ROOFWOOD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFWOOD": new FormControl({value: '', disabled: true}),
        "ROOFMETL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFMETL": new FormControl({value: '', disabled: true}),
        "ROOFTARG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFTARG": new FormControl({value: '', disabled: true}),
        "ROOFROLL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFROLL": new FormControl({value: '', disabled: true}),
        "ROOFFIBR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFFIBR": new FormControl({value: '', disabled: true}),
        "ROOFOTHR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFOTHR": new FormControl({value: '', disabled: true})
      }),
      "EXTERIOR": new FormGroup({
        "EXTRSTUC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRSTUC": new FormControl({value: '', disabled: true}),
        "EXTRWOOD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRWOOD": new FormControl({value: '', disabled: true}),
        "EXTRBRIK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRBRIK": new FormControl({value: '', disabled: true}),
        "EXTRBLCK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRBLCK": new FormControl({value: '', disabled: true}),
        "EXTRSTON": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRSTON": new FormControl({value: '', disabled: true}),
        "EXTRMETL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRMETL": new FormControl({value: '', disabled: true}),
        "EXTRSHGL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRSHGL": new FormControl({value: '', disabled: true}),
        "EXTRLOGG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRLOGG": new FormControl({value: '', disabled: true}),
        "EXTRVNYL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTRVNYL": new FormControl({value: '', disabled: true}),
        "EXTROTHR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_EXTROTHR": new FormControl({value: '', disabled: true})
      }),
      "YARDSPKF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDSPKF": new FormControl({value: '', disabled: true}),
      "YARDSPKB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDSPKB": new FormControl({value: '', disabled: true}),
      "YARDSPKA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDSPKA": new FormControl({value: '', disabled: true}),
      "YARDSPKM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDSPKM": new FormControl({value: '', disabled: true}),
      "YARDFNCD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDFNCD": new FormControl({value: '', disabled: true}),
      "YARDMTRL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDMTRL": new FormControl({value: '', disabled: true}),
      "YARDFRUT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDFRUT": new FormControl({value: '', disabled: true}),
      "YARDGRDA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDGRDA": new FormControl({value: '', disabled: true}),
      "YARDSYNT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDSYNT": new FormControl({value: '', disabled: true}),
      "YARDDRIP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDDRIP": new FormControl({value: '', disabled: true}),
      "YARDOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_YARDOTHR": new FormControl({value: '', disabled: true}),
      "SPAY": new FormControl({value: '', disabled: true}),
      "1380SPAY": new FormControl({value: '', disabled: true}),
      "SPATINGR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPATINGR": new FormControl({value: '', disabled: true}),
      "SPATABGR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPATABGR": new FormControl({value: '', disabled: true}),
      "SPATCOMS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPATCOMS": new FormControl({value: '', disabled: true}),
      "PATOCVRD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOCVRD": new FormControl({value: '', disabled: true}),
      "PATOUNCV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOUNCV": new FormControl({value: '', disabled: true}),
      "PATOENCL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOENCL": new FormControl({value: '', disabled: true}),
      "PATOBRCK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOBRCK": new FormControl({value: '', disabled: true}),
      "PATOCONC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOCONC": new FormControl({value: '', disabled: true}),
      "PATOWOOD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOWOOD": new FormControl({value: '', disabled: true}),
      "PATOSTON": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOSTON": new FormControl({value: '', disabled: true}),
      "PATOSCRN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOSCRN": new FormControl({value: '', disabled: true}),
      "PATOOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PATOOTHR": new FormControl({value: '', disabled: true}),
      "DETSSHOP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETSSHOP": new FormControl({value: '', disabled: true}),
      "DETSGEST": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETSGEST": new FormControl({value: '', disabled: true}),
      "DETSBARN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETSBARN": new FormControl({value: '', disabled: true}),
      "DETSKENL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETSKENL": new FormControl({value: '', disabled: true}),
      "DETSSHED": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETSSHED": new FormControl({value: '', disabled: true}),
      "DETS2HOU": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETS2HOU": new FormControl({value: '', disabled: true}),
      "DETSGRNH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETSGRNH": new FormControl({value: '', disabled: true}),
      "DETSOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_DETSOTHR": new FormControl({value: '', disabled: true}),
      "SPECFLOD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPECFLOD": new FormControl({value: '', disabled: true}),
      "SPECAGTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPECAGTR": new FormControl({value: '', disabled: true}),
      "SPECDEPR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPECDEPR": new FormControl({value: '', disabled: true}),
      "SPECFLIT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPECFLIT": new FormControl({value: '', disabled: true}),
      "SPECSEER": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SPECSEER": new FormControl({value: '', disabled: true}),
      "IRPTHOME": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTHOME": new FormControl({value: '', disabled: true}),
      "IRPTROOF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTROOF": new FormControl({value: '', disabled: true}),
      "IRPTPEST": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTPEST": new FormControl({value: '', disabled: true}),
      "IRPTSEPT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTSEPT": new FormControl({value: '', disabled: true}),
      "IRPTWELR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTWELR": new FormControl({value: '', disabled: true}),
      "IRPTWTRP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTWTRP": new FormControl({value: '', disabled: true}),
      "IRPTCC&R": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTCC&R": new FormControl({value: '', disabled: true}),
      "IRPTRULE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTRULE": new FormControl({value: '', disabled: true}),
      "IRPTBYLW": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTBYLW": new FormControl({value: '', disabled: true}),
      "IRPTBDGT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTBDGT": new FormControl({value: '', disabled: true}),
      "IRPTFINC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTFINC": new FormControl({value: '', disabled: true}),
      "IRPTOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTOTHR": new FormControl({value: '', disabled: true}),
      "IRPTAPPR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTAPPR": new FormControl({value: '', disabled: true}),
      "IRPTNHDD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTNHDD": new FormControl({value: '', disabled: true}),
      "IRPTPREL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_IRPTPREL": new FormControl({value: '', disabled: true}),
      "1SLNASSM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_1SLNASSM": new FormControl({value: '', disabled: true}),
      "1SLNNONA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_1SLNNONA": new FormControl({value: '', disabled: true}),
      "1SLNPRVT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_1SLNPRVT": new FormControl({value: '', disabled: true}),
      "1SLNFRCL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_1SLNFRCL": new FormControl({value: '', disabled: true}),
      "2LNASSM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_2LNASSM": new FormControl({value: '', disabled: true}),
      "2LNPRVT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_2LNPRVT": new FormControl({value: '', disabled: true}),
      "2LNNONA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_2LNNONA": new FormControl({value: '', disabled: true}),
      "TERMGOVT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMGOVT": new FormControl({value: '', disabled: true}),
      "TERMCONV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMCONV": new FormControl({value: '', disabled: true}),
      "TERMLSOP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMLSOP": new FormControl({value: '', disabled: true}),
      "TERMCASH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMCASH": new FormControl({value: '', disabled: true}),
      "TERMOWNR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMOWNR": new FormControl({value: '', disabled: true}),
      "TERMLIMI": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMLIMI": new FormControl({value: '', disabled: true}),
      "AUCT": new FormControl({value: '', disabled: true}),
      "HSSB": new FormControl({value: '', disabled: true}),
      "STTD": new FormControl({value: '', disabled: true}),
      "1530STTD": new FormControl({value: '', disabled: true}),
      "SDTD": new FormControl({value: '', disabled: true}),
      "1540SDTD": new FormControl({value: '', disabled: true}),
      "1550SSSS": new FormControl({value: '', disabled: true}),
      "ADDD": new FormControl({value: '', disabled: true}),
      "1560ADDD": new FormControl({value: '', disabled: true}),
      "UNIQPROB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQPROB": new FormControl({value: '', disabled: true}),
      "UNIQESTA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQESTA": new FormControl({value: '', disabled: true}),
      "UNIQBKRP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQBKRP": new FormControl({value: '', disabled: true}),
      "UNIQCORT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQCORT": new FormControl({value: '', disabled: true}),
      "UNIQRELO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQRELO": new FormControl({value: '', disabled: true}),
      "UNIQEXCH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQEXCH": new FormControl({value: '', disabled: true}),
      "HOAA": new FormControl({value: '', disabled: true}),
      "1610RENT": new FormControl({value: '', disabled: true}),
      "RENT": new FormControl({value: '', disabled: true}),
      "1620FREQ": new FormControl({value: '', disabled: true}),
      "HOAAMENITIES": new FormGroup({
        "PRKFPOOL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFPOOL": new FormControl({value: '', disabled: true}),
        "PRKFSPA": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFSPA": new FormControl({value: '', disabled: true}),
        "PRKFSAUN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFSAUN": new FormControl({value: '', disabled: true}),
        "PRKFCLUB": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFCLUB": new FormControl({value: '', disabled: true}),
        "PRKFEXRM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFEXRM": new FormControl({value: '', disabled: true}),
        "PRKFGRNA": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFGRNA": new FormControl({value: '', disabled: true}),
        "PRKFRVST": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFRVST": new FormControl({value: '', disabled: true}),
        "PRKFTENN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFTENN": new FormControl({value: '', disabled: true}),
        "PRKFRQTB": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFRQTB": new FormControl({value: '', disabled: true}),
        "PRKFSECG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFSECG": new FormControl({value: '', disabled: true}),
        "PRKFLKPN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFLKPN": new FormControl({value: '', disabled: true}),
        "PRKFPLAY": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFPLAY": new FormControl({value: '', disabled: true}),
        "PRKFLDSC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFLDSC": new FormControl({value: '', disabled: true}),
        "PRKFGATE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFGATE": new FormControl({value: '', disabled: true}),
        "PRKFOTNN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PRKFOTNN": new FormControl({value: '', disabled: true}),
      }),
      "SEON": new FormControl({value: '', disabled: true}),
      "1640SEON": new FormControl({value: '', disabled: true}),
      "INPK": new FormControl({value: '', disabled: true}),
      "1720INPK": new FormControl({value: '', disabled: true}),
      "1730RAMT": new FormControl({value: '', disabled: true}),
      "RAMT": new FormControl({value: '', disabled: true}),
      "NUM_RAMT": new FormControl({value: '', disabled: true}),
      "DESC": new FormControl({value: '', disabled: true}),
      "1740DESC": new FormControl({value: '', disabled: true}),
      "TYPE": new FormControl({value: '', disabled: true}),
      "1750TYPE": new FormControl({value: '', disabled: true}),
      "PRMF": new FormControl({value: '', disabled: true}),
      "1770HUDN": new FormControl({value: '', disabled: true}),
      "1780LICS": new FormControl({value: '', disabled: true}),
      "1790SNUM": new FormControl({value: '', disabled: true}),
      "1800PKNM": new FormControl({value: '', disabled: true}),
      "1810MODL": new FormControl({value: '', disabled: true}),
      "WANTSIGN": new FormControl({value: '', disabled: true}),
      "WANTSIGNCOMMENT":  new FormControl({value: '', disabled: true}),
    });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';
      newDiv.innerHTML = this.form.get( formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }

}
