import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-farm',
  templateUrl: './view-farm.component.html',
  styleUrls: ['./view-farm.component.scss']
})
export class ViewFarmComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
     // For display purposes
     "FULL_ADDRESS": new FormControl({value: '', disabled: true}),

     "County_ComboBox": new FormControl({value: '', disabled: true}),
     "APN_1": new FormControl({value: '', disabled: true}),
     "APN_2": new FormControl({value: '', disabled: true}),
     "APN_3": new FormControl({value: '', disabled: true}),
     "New_Construction": new FormControl({value: '', disabled: true}),
     "Street_Number": new FormControl({value: '', disabled: true}),
     "Street_Number_Modifier": new FormControl({value: '', disabled: true}),
     "Street_Direction": new FormControl({value: '', disabled: true}),
     "Street_Address": new FormControl({value: '', disabled: true}),
     "Street_Suffix": new FormControl({value: '', disabled: true}),
     "Post_Direction": new FormControl({value: '', disabled: true}),
     "Unit_Number": new FormControl({value: '', disabled: true}),
     "City_Fill_In": new FormControl({value: '', disabled: true}),
     "Zip_Code": new FormControl({value: '', disabled: true}),
     "Zip_Plus_4": new FormControl({value: '', disabled: true}),
     "Cross_Street": new FormControl({value: '', disabled: true}),
     "Office_ID": new FormControl({value: '', disabled: true}),
     "Office_MLS_ID": new FormControl({value: '', disabled: true}),
     "Agent_MLS_ID": new FormControl({value: '', disabled: true}),
     "Co_Office_MLS_ID": new FormControl({value: '', disabled: true}),
     "Co_Agent_MLS_ID": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0001": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0002": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0003": new FormControl({value: '', disabled: true}),
     "Transaction": new FormControl({value: '', disabled: true}),
     "SSLE": new FormControl({value: '', disabled: true}),
     "REBK": new FormControl({value: '', disabled: true}),
     "Lease_Price": new FormControl({value: '', disabled: true}),
     "Lease_Type": new FormControl({value: '', disabled: true}),
     "Sub_Lease": new FormControl({value: '', disabled: true}),
     "Available_Date": new FormControl({value: '', disabled: true}),
     "Lease_Expiration": new FormControl({value: '', disabled: true}),
     "APNS": new FormControl({value: '', disabled: true}),
     "0100APN2": new FormControl({value: '', disabled: true}),
     "0110APN3": new FormControl({value: '', disabled: true}),
     "0120APN4": new FormControl({value: '', disabled: true}),
     "0130APN5": new FormControl({value: '', disabled: true}),
     "Listing_Price_1": new FormControl({value: '', disabled: true}),
     "Listing_Price_2": new FormControl({value: '', disabled: true}),
     "Agreement_Type_ComboBox": new FormControl({value: '', disabled: true}),
     "SCOP": new FormControl({value: '', disabled: true}),
     "0255SCOP": new FormControl({value: '', disabled: true}),
     "DUAL": new FormControl({value: '', disabled: true}),
     "0240DUAL": new FormControl({value: '', disabled: true}),
     "Publish_To_Internet": new FormControl({value: '', disabled: true}),
     "Show_AVM_On_VOW": new FormControl({value: '', disabled: true}),
     "Show_Address_to_Public": new FormControl({value: '', disabled: true}),
     "Show_Comments_On_VOW": new FormControl({value: '', disabled: true}),
     //"Dollar_Percent": new FormControl({value: '', disabled: true}),
     //"Commission_1": new FormControl({value: '', disabled: true}),
     //"Commission_Amount": new FormControl({value: '', disabled: true}),
     //"Variable_Rate_Comm": new FormControl({value: '', disabled: true}),
     "Comm_Comments": new FormControl({value: '', disabled: true}),
     "EOFF": new FormControl({value: '', disabled: true}),
     "0380EOAD": new FormControl({value: '', disabled: true}),
     "Listing_Date": new FormControl({value: '', disabled: true}),
     "Expiration_Date": new FormControl({value: '', disabled: true}),
     "Owner_Name": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Area": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Prefix": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Suffix": new FormControl({value: '', disabled: true}),
     "Occupant_Type": new FormControl({value: '', disabled: true}),
     "Occupant_Name": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Area": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Prefix": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Suffix": new FormControl({value: '', disabled: true}),
     "Bedrooms_ComboBox": new FormControl({value: '', disabled: true}),
     "Bathrooms_ComboBox": new FormControl({value: '', disabled: true}),
     "Struc_Sq_Ft": new FormControl({value: '', disabled: true}),
     "SqFt_Source": new FormControl({value: '', disabled: true}),
     "Lot_Sq_Ft": new FormControl({value: '', disabled: true}),
     "Lot_Measurement": new FormControl({value: '', disabled: true}),
     //"Lot_Measurement": new FormControl({value: '', disabled: true}),
     "LotSize_Source": new FormControl({value: '', disabled: true}),
     "Year_Built": new FormControl({value: '', disabled: true}),
     "Unknown_Year": new FormControl({value: '', disabled: true}),
     "Property_Description": new FormControl({value: '', disabled: true}),
     "Agent_Only_Remarks": new FormControl({value: '', disabled: true}),
     "Directions": new FormControl({value: '', disabled: true}),
     "School_District_Fill_In": new FormControl({value: '', disabled: true}),
     "School_Code_1": new FormControl({value: '', disabled: true}),
     "School_Code_2": new FormControl({value: '', disabled: true}),
     "School_Code_3": new FormControl({value: '', disabled: true}),
     "0590SCMT": new FormControl({value: '', disabled: true}),
     "0620MCRT": new FormControl({value: '', disabled: true}),
     "CPIN": new FormControl({value: '', disabled: true}),
     "0630CPIN": new FormControl({value: '', disabled: true}),
     "0640PLV1": new FormControl({value: '', disabled: true}),
     "ACR1": new FormControl({value: '', disabled: true}),
     "0650ACR1": new FormControl({value: '', disabled: true}),
     "0660AGE1": new FormControl({value: '', disabled: true}),
     "0670PRO1": new FormControl({value: '', disabled: true}),
     "0680PLV2": new FormControl({value: '', disabled: true}),
     "ACR2": new FormControl({value: '', disabled: true}),
     "0690ACR2": new FormControl({value: '', disabled: true}),
     "0700AGE2": new FormControl({value: '', disabled: true}),
     "0710PRO2": new FormControl({value: '', disabled: true}),
     "0720PLV3": new FormControl({value: '', disabled: true}),
     "ACR3": new FormControl({value: '', disabled: true}),
     "0730ACR3": new FormControl({value: '', disabled: true}),
     "0740AGE3": new FormControl({value: '', disabled: true}),
     "0750PRO3": new FormControl({value: '', disabled: true}),
     "0760PLV4": new FormControl({value: '', disabled: true}),
     "ACR4": new FormControl({value: '', disabled: true}),
     "0770ACR4": new FormControl({value: '', disabled: true}),
     "0780AGE4": new FormControl({value: '', disabled: true}),
     "0790PRO4": new FormControl({value: '', disabled: true}),
     "0800PLV5": new FormControl({value: '', disabled: true}),
     "ACR5": new FormControl({value: '', disabled: true}),
     "0810ACR5": new FormControl({value: '', disabled: true}),
     "0820AGE5": new FormControl({value: '', disabled: true}),
     "0830PRO5": new FormControl({value: '', disabled: true}),
     "CNSS": new FormControl({value: '', disabled: true}),
     "0870CNSS": new FormControl({value: '', disabled: true}),
     "0880IRDT": new FormControl({value: '', disabled: true}),
     "0890HWAY": new FormControl({value: '', disabled: true}),
     "AREA": new FormControl({value: '', disabled: true}),
     "0900AREA": new FormControl({value: '', disabled: true}),
     "Area_Fill_In": new FormControl({value: '', disabled: true}),
     "0940TOPO": new FormControl({value: '', disabled: true}),
     "DIST": new FormControl({value: '', disabled: true}),
     "0950DIST": new FormControl({value: '', disabled: true}),
     "WATERSOURCE": new FormGroup({
       "WTRSWELL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSWELL": new FormControl({value: '', disabled: true}),
       "WTRSDSTW": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSDSTW": new FormControl({value: '', disabled: true}),
       "WTRSSCRK": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSSCRK": new FormControl({value: '', disabled: true}),
       "WTRSLAKE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSLAKE": new FormControl({value: '', disabled: true}),
       "WTRSSPNG": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSSPNG": new FormControl({value: '', disabled: true}),
       "WTRSWSTS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSWSTS": new FormControl({value: '', disabled: true}),
       "WTRSPOND": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSPOND": new FormControl({value: '', disabled: true}),
       "WTRSNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WTRSNONE": new FormControl({value: '', disabled: true}),
     }),
     "IRRIGATIONWELLS": new FormGroup({
       "IRRWNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IRRWNONE": new FormControl({value: '', disabled: true}),
       "IRRWONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IRRWONE": new FormControl({value: '', disabled: true}),
       "IRRWTWOM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IRRWTWOM": new FormControl({value: '', disabled: true}),
       "IRRWELEC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IRRWELEC": new FormControl({value: '', disabled: true}),
       "IRRWGEAR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IRRWGEAR": new FormControl({value: '', disabled: true}),
      }),
     "DOMESTICWELLS": new FormGroup({
       "DOMWNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWNONE": new FormControl({value: '', disabled: true}),
       "DOMWONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWONE": new FormControl({value: '', disabled: true}),
       "DOMWTWOM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWTWOM": new FormControl({value: '', disabled: true}),
       "DOMWCITW": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWCITW": new FormControl({value: '', disabled: true}),
       "DOMWSHAR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWSHAR": new FormControl({value: '', disabled: true}),
       "DOMWSUBM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWSUBM": new FormControl({value: '', disabled: true}),
       "DOMWTURB": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWTURB": new FormControl({value: '', disabled: true}),
       "DOMWWIND": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWWIND": new FormControl({value: '', disabled: true}),
       "DOMWGNRA": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_DOMWGNRA": new FormControl({value: '', disabled: true}),
      }),
     "SEWERSEPTIC": new FormGroup({
       "SEWRNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SEWRNONE": new FormControl({value: '', disabled: true}),
       "SEWRCITS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SEWRCITS": new FormControl({value: '', disabled: true}),
       "SEWRSEPT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SEWRSEPT": new FormControl({value: '', disabled: true}),
       "SEWRSHAR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SEWRSHAR": new FormControl({value: '', disabled: true}),
       "SEWRENGN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SEWRENGN": new FormControl({value: '', disabled: true}),
      }),
     "UTILITIES": new FormGroup({
       "UTILNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILNONE": new FormControl({value: '', disabled: true}),
       "UTILELEC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILELEC": new FormControl({value: '', disabled: true}),
       "UTILGAS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILGAS": new FormControl({value: '', disabled: true}),
       "UTILPROP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILPROP": new FormControl({value: '', disabled: true}),
       "UTILELET": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILELET": new FormControl({value: '', disabled: true}),
       "UTILPHON": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILPHON": new FormControl({value: '', disabled: true}),
       "UTILELEN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILELEN": new FormControl({value: '', disabled: true}),
       "UTILGASC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILGASC": new FormControl({value: '', disabled: true}),
       "UTILGASN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_UTILGASN": new FormControl({value: '', disabled: true}),
      }),
     "SPECSPSZ": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSPSZ": new FormControl({value: '', disabled: true}),
     "SPECTOXI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECTOXI": new FormControl({value: '', disabled: true}),
     "SPECFLIT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECFLIT": new FormControl({value: '', disabled: true}),
     "SPECASCN": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECASCN": new FormControl({value: '', disabled: true}),
     "SPECAORD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECAORD": new FormControl({value: '', disabled: true}),
     "SPECSPSA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSPSA": new FormControl({value: '', disabled: true}),
     "SPECOTHR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECOTHR": new FormControl({value: '', disabled: true}),
     "SPECSEER": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSEER": new FormControl({value: '', disabled: true}),
     "SPECWILL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECWILL": new FormControl({value: '', disabled: true}),
     "DILOPLOT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOPLOT": new FormControl({value: '', disabled: true}),
     "DILOLEGA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOLEGA": new FormControl({value: '', disabled: true}),
     "DILODEED": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILODEED": new FormControl({value: '', disabled: true}),
     "DILOZONR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOZONR": new FormControl({value: '', disabled: true}),
     "DILOBLDR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOBLDR": new FormControl({value: '', disabled: true}),
     "DILOSURV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOSURV": new FormControl({value: '', disabled: true}),
     "DILOSOIL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOSOIL": new FormControl({value: '', disabled: true}),
     "DILOPERC": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOPERC": new FormControl({value: '', disabled: true}),
     "DILOAERA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOAERA": new FormControl({value: '', disabled: true}),
     "DILOTOPO": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOTOPO": new FormControl({value: '', disabled: true}),
     "DILOLEAS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOLEAS": new FormControl({value: '', disabled: true}),
     "DILOAPPR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOAPPR": new FormControl({value: '', disabled: true}),
     "DILOEQPM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOEQPM": new FormControl({value: '', disabled: true}),
     "DILOPROD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOPROD": new FormControl({value: '', disabled: true}),
     "DILOLESS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOLESS": new FormControl({value: '', disabled: true}),
     "LBOX": new FormControl({value: '', disabled: true}),
     "1030LBOX": new FormControl({value: '', disabled: true}),
     "1040LOCL": new FormControl({value: '', disabled: true}),
     "Showing_Instructions": new FormControl({value: '', disabled: true}),
     "SHOWINGINSTRUCTIONS": new FormGroup({
       "SHOWLOCK": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWLOCK": new FormControl({value: '', disabled: true}),
       "SHOWKEYO": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWKEYO": new FormControl({value: '', disabled: true}),
       "SHOWGOOC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWGOOC": new FormControl({value: '', disabled: true}),
       "SHOWCALL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCALL": new FormControl({value: '', disabled: true}),
       "SHOWAPPT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWAPPT": new FormControl({value: '', disabled: true}),
       "SHOW24HR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOW24HR": new FormControl({value: '', disabled: true}),
       "SHOWNOSN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWNOSN": new FormControl({value: '', disabled: true}),
       "SHOWSNST": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWSNST": new FormControl({value: '', disabled: true}),
       "SHOWCLAG": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCLAG": new FormControl({value: '', disabled: true}),
       "SHOWVAC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWVAC": new FormControl({value: '', disabled: true}),
       "SHOWDOGI": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWDOGI": new FormControl({value: '', disabled: true}),
       "SHOWDOGO": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWDOGO": new FormControl({value: '', disabled: true}),
       "SHOWRSTC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWRSTC": new FormControl({value: '', disabled: true}),
       "SHOWSEAS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWSEAS": new FormControl({value: '', disabled: true}),
       "SHOWFWDR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWFWDR": new FormControl({value: '', disabled: true}),
       "SHOWCBSC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCBSC": new FormControl({value: '', disabled: true}),
       "SHOWVTGS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWVTGS": new FormControl({value: '', disabled: true}),
       "SHOWALRM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWALRM": new FormControl({value: '', disabled: true}),
      }),
     "BUILDINGONSITE": new FormGroup({
       "BONSNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSNONE": new FormControl({value: '', disabled: true}),
       "BONSHOUS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSHOUS": new FormControl({value: '', disabled: true}),
       "BONSRENT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSRENT": new FormControl({value: '', disabled: true}),
       "BONSMOBL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSMOBL": new FormControl({value: '', disabled: true}),
       "BONSBARN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSBARN": new FormControl({value: '', disabled: true}),
       "BONSSHOP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSSHOP": new FormControl({value: '', disabled: true}),
       "BONSPOLE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSPOLE": new FormControl({value: '', disabled: true}),
       "BONSOUTB": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSOUTB": new FormControl({value: '', disabled: true}),
       "BONSOTHR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_BONSOTHR": new FormControl({value: '', disabled: true}),
      }),
     "MNRLNONE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MNRLNONE": new FormControl({value: '', disabled: true}),
     "MNRLPART": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MNRLPART": new FormControl({value: '', disabled: true}),
     "MNRLAVAL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MNRLAVAL": new FormControl({value: '', disabled: true}),
     "MNRLLEAS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MNRLLEAS": new FormControl({value: '', disabled: true}),
     "TRANCITS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANCITS": new FormControl({value: '', disabled: true}),
     "TRANCNTR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANCNTR": new FormControl({value: '', disabled: true}),
     "TRANPRIV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANPRIV": new FormControl({value: '', disabled: true}),
     "TRANSTHG": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANSTHG": new FormControl({value: '', disabled: true}),
     "TRANFRWY": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANFRWY": new FormControl({value: '', disabled: true}),
     "TRANEASM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANEASM": new FormControl({value: '', disabled: true}),
     "TRANPAVE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANPAVE": new FormControl({value: '', disabled: true}),
     "TRANGRAV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANGRAV": new FormControl({value: '', disabled: true}),
     "TRANRAIL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANRAIL": new FormControl({value: '', disabled: true}),
     "TRANAIRP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANAIRP": new FormControl({value: '', disabled: true}),
     "TRAN4WDR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRAN4WDR": new FormControl({value: '', disabled: true}),
     "MISCCRFN": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MISCCRFN": new FormControl({value: '', disabled: true}),
     "MISCESMT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MISCESMT": new FormControl({value: '', disabled: true}),
     "MISCLEAS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MISCLEAS": new FormControl({value: '', disabled: true}),
     "MISCHUNT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MISCHUNT": new FormControl({value: '', disabled: true}),
     "MISCGRAZ": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_MISCGRAZ": new FormControl({value: '', disabled: true}),
     "SALETOPO": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALETOPO": new FormControl({value: '', disabled: true}),
     "SALESUB": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALESUB": new FormControl({value: '', disabled: true}),
     "SALEBULD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALEBULD": new FormControl({value: '', disabled: true}),
     "SALEADJL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALEADJL": new FormControl({value: '', disabled: true}),
     "1SLNASSM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNASSM": new FormControl({value: '', disabled: true}),
     "1SLNNASS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNNASS": new FormControl({value: '', disabled: true}),
     "1SLNPRVT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNPRVT": new FormControl({value: '', disabled: true}),
     "1SLNFRCL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNFRCL": new FormControl({value: '', disabled: true}),
     "2LNASSM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNASSM": new FormControl({value: '', disabled: true}),
     "2LNNASS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNNASS": new FormControl({value: '', disabled: true}),
     "2LNPRVT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNPRVT": new FormControl({value: '', disabled: true}),
     "TERMCASH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMCASH": new FormControl({value: '', disabled: true}),
     "TERMSUBT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMSUBT": new FormControl({value: '', disabled: true}),
     "TERMLEAS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMLEAS": new FormControl({value: '', disabled: true}),
     "TERMOWNR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMOWNR": new FormControl({value: '', disabled: true}),
     "TERMLIMI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMLIMI": new FormControl({value: '', disabled: true}),
     "HSSB": new FormControl({value: '', disabled: true}),
     "STTD": new FormControl({value: '', disabled: true}),
     "1250STTD": new FormControl({value: '', disabled: true}),
     "SDTD": new FormControl({value: '', disabled: true}),
     "1260SDTD": new FormControl({value: '', disabled: true}),
     "1270SSSS": new FormControl({value: '', disabled: true}),
     "ADDD": new FormControl({value: '', disabled: true}),
     "1271ADDD": new FormControl({value: '', disabled: true}),
     "UNIQBANK": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQBANK": new FormControl({value: '', disabled: true}),
     "UNIQPROB": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQPROB": new FormControl({value: '', disabled: true}),
     "UNIQESTA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQESTA": new FormControl({value: '', disabled: true}),
     "UNIQCORT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQCORT": new FormControl({value: '', disabled: true}),
     "UNIQBID": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQBID": new FormControl({value: '', disabled: true}),
     "UNIQEXCH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQEXCH": new FormControl('')
    }, { updateOn: 'change' });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';
      newDiv.innerHTML = this.form.get( formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }
}
