<div class="modal-header">
  <h5 class="modal-title">
    <span> Create an organization </span> <br>
    <small class="text-muted">Organization name must be unique</small>
  </h5>
  <button type="button" class="close" (click)="close()">&times;</button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col">
      <label class="form-label">Organization Name</label>
      <input type="text" class="form-control" [ngClass]="{'is-invalid': !isValid}" maxlength="30" [(ngModel)]="organizationName">
      <small *ngIf="!isValid" class="form-text text-danger">Organization name must only contain alphanumeric characters</small>
      <small *ngIf="alreadyExist" class="form-text text-danger">This organization name already exists!</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">Close</button>
  <button type="button" class="btn btn-primary" (click)="createOrganization()" [disabled]="isOrganizationCreating">
    <span *ngIf="isOrganizationCreating" class="spinner-border" role="status" aria-hidden="true"></span>
    Create
  </button>
</div>