import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { LayoutService } from 'src/app/services/layout.service';
import { CustomNavigationMode } from '../../services/custom-navigation';
import { CustomFormsService } from '../../services/custom-forms.service';
import { Response } from 'src/app/models/response.model';
import { ModalService } from 'src/app/modals/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss']
})
export class CommercialComponent implements OnInit, OnDestroy {
  @ViewChild('submitSuccess') private submitSuccess: SwalComponent;
  @ViewChild('submitError') private submitError: SwalComponent;
  @ViewChild('wizard') private wizard: WizardComponent;
  public errorList = [];

  public form: FormGroup
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public numErrors: number = 0;
  public key: string = '';
  public responseCache: Response = null;

  private routeSubscription: Subscription;
  private dataSubscription: Subscription;
  private formSubscription: Subscription;

  public navigationMode: CustomNavigationMode;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private layoutService: LayoutService,
    private customFormsService: CustomFormsService,
    private modalService: ModalService,
    private toastrService: ToastrService) {
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(true);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl(''),

      "County_ComboBox": new FormControl('', [Validators.required]),
      "APN_1": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_2": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "APN_3": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('New_Construction').value == false)]),
      "New_Construction": new FormControl(''),
      "Street_Number": new FormControl('', [Validators.required]),
      "Street_Number_Modifier": new FormControl(''),
      "Street_Direction": new FormControl(''),
      "Street_Address": new FormControl('', [Validators.required]),
      "Street_Suffix": new FormControl(''),
      "Post_Direction": new FormControl(''),
      "Unit_Number": new FormControl(''),
      "City_Fill_In": new FormControl('', [Validators.required]),
      "Zip_Code": new FormControl('', [Validators.required]),
      "Zip_Plus_4": new FormControl(''),
      "Cross_Street": new FormControl('', [Validators.required]),
      "Office_ID": new FormControl('', [Validators.required]),
      "Office_MLS_ID": new FormControl('', [Validators.required]),
      "Agent_MLS_ID": new FormControl('', [Validators.required]),
      "Co_Office_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Agent_MLS_ID').value != '')]),
      "Co_Agent_MLS_ID": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Co_Office_MLS_ID').value != '')]),
      "Prop_Subtype_0001": new FormControl(''),
      "Prop_Subtype_0002": new FormControl(''),
      "Prop_Subtype_0003": new FormControl(''),
      "Transaction": new FormControl('', [Validators.required]),
      "SSLE": new FormControl('', [Validators.required]),
      "REBK": new FormControl('', [Validators.required]),
      "Lease_Price": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Lease_Type": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('Transaction').value == 'L')]),
      "Sub_Lease": new FormControl(''),
      "Available_Date": new FormControl(''),
      "Lease_Expiration": new FormControl(''),
      "APNS": new FormControl(''),
      "0100APN2": new FormControl(''),
      "0110APN3": new FormControl(''),
      "0120APN4": new FormControl(''),
      "0130APN5": new FormControl(''),
      "Listing_Price_1": new FormControl('', [Validators.required]),
      "Listing_Price_2": new FormControl(''),
      "Agreement_Type_ComboBox": new FormControl('', [Validators.required]),
      "SCOP": new FormControl(''),
      "0235SCOP": new FormControl(''),
      "DUAL": new FormControl(''),
      "0240DUAL": new FormControl(''),
      "Publish_To_Internet": new FormControl(''),
      "Show_AVM_On_VOW": new FormControl('', [Validators.required]),
      "Show_Address_to_Public": new FormControl('', [Validators.required]),
      "Show_Comments_On_VOW": new FormControl('', [Validators.required]),
      //"Dollar_Percent": new FormControl('', [Validators.required]),
      //"Commission_1": new FormControl(''),
      //"Commission_Amount": new FormControl('', [Validators.required]),
      //"Variable_Rate_Comm": new FormControl('', [Validators.required]),
      "Comm_Comments": new FormControl(''),
      "EOFF": new FormControl('', [Validators.required]),
      "0390EOAD": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('EOFF').value == 'YESY')]),
      "Listing_Date": new FormControl('', [Validators.required]),
      "Expiration_Date": new FormControl('', [Validators.required]),
      "Owner_Name": new FormControl(''),
      "Owner_Phone_Area": new FormControl(''),
      "Owner_Phone_Prefix": new FormControl(''),
      "Owner_Phone_Suffix": new FormControl(''),
      "Occupant_Type": new FormControl('', [Validators.required]),
      "Occupant_Name": new FormControl(''),
      "Phone_to_Show_Area": new FormControl(''),
      "Phone_to_Show_Prefix": new FormControl(''),
      "Phone_to_Show_Suffix": new FormControl(''),
      "Bedrooms_ComboBox": new FormControl('', [Validators.required]),
      "Bathrooms_ComboBox": new FormControl('', [Validators.required]),
      "Struc_Sq_Ft": new FormControl('', [Validators.required]),
      "SqFt_Source": new FormControl('', [Validators.required]),
      "Lot_Sq_Ft": new FormControl(''),
      "Lot_Measurement": new FormControl(''),
      //"Lot_Measurement": new FormControl(''),
      "LotSize_Source": new FormControl(''),
      "Year_Built": new FormControl('', [Validators.required]),
      "Unknown_Year": new FormControl(''),
      "Property_Description": new FormControl('', [Validators.required]),
      "Agent_Only_Remarks": new FormControl(''),
      "Directions": new FormControl('', [Validators.required]),
      "POTENTIALUSE": new FormGroup({
        "POTNAUTO": new FormControl(''),
        "Checkbox_Comment_POTNAUTO": new FormControl(''),
        "POTNBANK": new FormControl(''),
        "Checkbox_Comment_POTNBANK": new FormControl(''),
        "POTNBARB": new FormControl(''),
        "Checkbox_Comment_POTNBARB": new FormControl(''),
        "POTNBOWL": new FormControl(''),
        "Checkbox_Comment_POTNBOWL": new FormControl(''),
        "POTNCHUR": new FormControl(''),
        "Checkbox_Comment_POTNCHUR": new FormControl(''),
        "POTNMRKT": new FormControl(''),
        "Checkbox_Comment_POTNMRKT": new FormControl(''),
        "POTNDCAR": new FormControl(''),
        "Checkbox_Comment_POTNDCAR": new FormControl(''),
        "POTNDEPT": new FormControl(''),
        "Checkbox_Comment_POTNDEPT": new FormControl(''),
        "POTNDISC": new FormControl(''),
        "Checkbox_Comment_POTNDISC": new FormControl(''),
        "POTNGNRL": new FormControl(''),
        "Checkbox_Comment_POTNGNRL": new FormControl(''),
        "POTNGOVT": new FormControl(''),
        "Checkbox_Comment_POTNGOVT": new FormControl(''),
        "POTNHLTH": new FormControl(''),
        "Checkbox_Comment_POTNHLTH": new FormControl(''),
        "POTNHOSP": new FormControl(''),
        "Checkbox_Comment_POTNHOSP": new FormControl(''),
        "POTNHOTL": new FormControl(''),
        "Checkbox_Comment_POTNHOTL": new FormControl(''),
        "POTNENGN": new FormControl(''),
        "Checkbox_Comment_POTNENGN": new FormControl(''),
        "POTNMNFG": new FormControl(''),
        "Checkbox_Comment_POTNMNFG": new FormControl(''),
        "POTNLAUN": new FormControl(''),
        "Checkbox_Comment_POTNLAUN": new FormControl(''),
        "POTNMATS": new FormControl(''),
        "Checkbox_Comment_POTNMATS": new FormControl(''),
        "POTNMDCL": new FormControl(''),
        "Checkbox_Comment_POTNMDCL": new FormControl(''),
        "POTNMOTL": new FormControl(''),
        "Checkbox_Comment_POTNMOTL": new FormControl(''),
        "POTNPRIV": new FormControl(''),
        "Checkbox_Comment_POTNPRIV": new FormControl(''),
        "POTNREST": new FormControl(''),
        "Checkbox_Comment_POTNREST": new FormControl(''),
        "POTNFAST": new FormControl(''),
        "Checkbox_Comment_POTNFAST": new FormControl(''),
        "POTNRETL": new FormControl(''),
        "Checkbox_Comment_POTNRETL": new FormControl(''),
        "POTNSERV": new FormControl(''),
        "Checkbox_Comment_POTNSERV": new FormControl(''),
        "POTNSHPN": new FormControl(''),
        "Checkbox_Comment_POTNSHPN": new FormControl(''),
        "POTNSHPC": new FormControl(''),
        "Checkbox_Comment_POTNSHPC": new FormControl(''),
        "POTNSHPR": new FormControl(''),
        "Checkbox_Comment_POTNSHPR": new FormControl(''),
        "POTNTHTR": new FormControl(''),
        "Checkbox_Comment_POTNTHTR": new FormControl(''),
        "POTNWARD": new FormControl(''),
        "Checkbox_Comment_POTNWARD": new FormControl(''),
        "POTNWARS": new FormControl(''),
        "Checkbox_Comment_POTNWARS": new FormControl(''),
        "POTNRMRK": new FormControl(''),
        "Checkbox_Comment_POTNRMRK": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "OFSQ": new FormControl(''),
      "0590OFSQ": new FormControl(''),
      "CNSS": new FormControl(''),
      "0600CNSS": new FormControl(''),
      "AREA": new FormControl(''),
      "0620AREA": new FormControl(''),
      "Area_Fill_In": new FormControl(''),
      "STORONE": new FormControl(''),
      "Checkbox_Comment_STORONE": new FormControl(''),
      "STORTWO": new FormControl(''),
      "Checkbox_Comment_STORTWO": new FormControl(''),
      "STORTHRE": new FormControl(''),
      "Checkbox_Comment_STORTHRE": new FormControl(''),
      "STORSLIT": new FormControl(''),
      "Checkbox_Comment_STORSLIT": new FormControl(''),
      "STORBASE": new FormControl(''),
      "Checkbox_Comment_STORBASE": new FormControl(''),
      "LCTNCRNR": new FormControl(''),
      "Checkbox_Comment_LCTNCRNR": new FormControl(''),
      "LCTNINTR": new FormControl(''),
      "Checkbox_Comment_LCTNINTR": new FormControl(''),
      "LCTNCULD": new FormControl(''),
      "Checkbox_Comment_LCTNCULD": new FormControl(''),
      "LCTNINDU": new FormControl(''),
      "Checkbox_Comment_LCTNINDU": new FormControl(''),
      "LCTNMALL": new FormControl(''),
      "Checkbox_Comment_LCTNMALL": new FormControl(''),
      "LCTNMAJR": new FormControl(''),
      "Checkbox_Comment_LCTNMAJR": new FormControl(''),
      "LCTNFRNG": new FormControl(''),
      "Checkbox_Comment_LCTNFRNG": new FormControl(''),
      "0670ZONE": new FormControl(''),
      "PARKING": new FormGroup({
        "PARK1TO5": new FormControl(''),
        "Checkbox_Comment_PARK1TO5": new FormControl(''),
        "PARK6T10": new FormControl(''),
        "Checkbox_Comment_PARK6T10": new FormControl(''),
        "PARK1020": new FormControl(''),
        "Checkbox_Comment_PARK1020": new FormControl(''),
        "PARKOV21": new FormControl(''),
        "Checkbox_Comment_PARKOV21": new FormControl(''),
        "PARKASSN": new FormControl(''),
        "Checkbox_Comment_PARKASSN": new FormControl(''),
        "PARKCVRD": new FormControl(''),
        "Checkbox_Comment_PARKCVRD": new FormControl(''),
        "PARKSTRT": new FormControl(''),
        "Checkbox_Comment_PARKSTRT": new FormControl(''),
        "PARKPBLC": new FormControl(''),
        "Checkbox_Comment_PARKPBLC": new FormControl(''),
        "PARKUNPV": new FormControl(''),
        "Checkbox_Comment_PARKUNPV": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "ACCESS": new FormGroup({
        "ACCSINTR": new FormControl(''),
        "Checkbox_Comment_ACCSINTR": new FormControl(''),
        "ACCSFRWY": new FormControl(''),
        "Checkbox_Comment_ACCSFRWY": new FormControl(''),
        "ACCSSTAT": new FormControl(''),
        "Checkbox_Comment_ACCSSTAT": new FormControl(''),
        "ACCSMAJR": new FormControl(''),
        "Checkbox_Comment_ACCSMAJR": new FormControl(''),
        "ACCSCITY": new FormControl(''),
        "Checkbox_Comment_ACCSCITY": new FormControl(''),
        "ACCSPRIV": new FormControl(''),
        "Checkbox_Comment_ACCSPRIV": new FormControl(''),
        "ACCSPAVE": new FormControl(''),
        "Checkbox_Comment_ACCSPAVE": new FormControl(''),
        "ACCSUNIM": new FormControl(''),
        "Checkbox_Comment_ACCSUNIM": new FormControl(''),
        "ACCSEASE": new FormControl(''),
        "Checkbox_Comment_ACCSEASE": new FormControl(''),
        "ACCSCNTR": new FormControl(''),
        "Checkbox_Comment_ACCSCNTR": new FormControl(''),
        "ACCSGRVL": new FormControl(''),
        "Checkbox_Comment_ACCSGRVL": new FormControl(''),
        "ACCSAIR1": new FormControl(''),
        "Checkbox_Comment_ACCSAIR1": new FormControl(''),
        "ACCSAIR2": new FormControl(''),
        "Checkbox_Comment_ACCSAIR2": new FormControl(''),
        "ACCSAIR4": new FormControl(''),
        "Checkbox_Comment_ACCSAIR4": new FormControl(''),
        "ACCSAIR6": new FormControl(''),
        "Checkbox_Comment_ACCSAIR6": new FormControl(''),     
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "BLDG": new FormControl(''),
      "0690BLDG": new FormControl(''),
      "RSTR": new FormControl(''),
      "0700RSTR": new FormControl(''),
      "MISCSECL": new FormControl(''),
      "Checkbox_Comment_MISCSECL": new FormControl(''),
      "MISCISTR": new FormControl(''),
      "Checkbox_Comment_MISCISTR": new FormControl(''),
      "MISCOSTR": new FormControl(''),
      "Checkbox_Comment_MISCOSTR": new FormControl(''),
      "MISCOPEN": new FormControl(''),
      "Checkbox_Comment_MISCOPEN": new FormControl(''),
      "MISCFIRE": new FormControl(''),
      "Checkbox_Comment_MISCFIRE": new FormControl(''),
      "MISCRAIL": new FormControl(''),
      "Checkbox_Comment_MISCRAIL": new FormControl(''),
      "MISCRACC": new FormControl(''),
      "Checkbox_Comment_MISCRACC": new FormControl(''),
      "MISCWETS": new FormControl(''),
      "Checkbox_Comment_MISCWETS": new FormControl(''),
      "MISCDEKG": new FormControl(''),
      "Checkbox_Comment_MISCDEKG": new FormControl(''),
      "MISCDEKT": new FormControl(''),
      "Checkbox_Comment_MISCDEKT": new FormControl(''),
      "MISCFRET": new FormControl(''),
      "Checkbox_Comment_MISCFRET": new FormControl(''),
      "MISCCNVY": new FormControl(''),
      "Checkbox_Comment_MISCCNVY": new FormControl(''),
      "MISCSCAL": new FormControl(''),
      "Checkbox_Comment_MISCSCAL": new FormControl(''),
      "MISCDISP": new FormControl(''),
      "Checkbox_Comment_MISCDISP": new FormControl(''),
      "MISCTIXT": new FormControl(''),
      "Checkbox_Comment_MISCTIXT": new FormControl(''),
      "MISCBLDG": new FormControl(''),
      "Checkbox_Comment_MISCBLDG": new FormControl(''),
      "MISCHAND": new FormControl(''),
      "Checkbox_Comment_MISCHAND": new FormControl(''),
      "MISCLICS": new FormControl(''),
      "Checkbox_Comment_MISCLICS": new FormControl(''),
      "MISCLITE": new FormControl(''),
      "Checkbox_Comment_MISCLITE": new FormControl(''),
      "MISCSIGN": new FormControl(''),
      "Checkbox_Comment_MISCSIGN": new FormControl(''),
      "ROOF": new FormGroup({
        "ROOFCOMP": new FormControl(''),
        "Checkbox_Comment_ROOFCOMP": new FormControl(''),
        "ROOFCONC": new FormControl(''),
        "Checkbox_Comment_ROOFCONC": new FormControl(''),
        "ROOFMETL": new FormControl(''),
        "Checkbox_Comment_ROOFMETL": new FormControl(''),
        "ROOFTARG": new FormControl(''),
        "Checkbox_Comment_ROOFTARG": new FormControl(''),
        "ROOFFLAT": new FormControl(''),
        "Checkbox_Comment_ROOFFLAT": new FormControl(''),
        "ROOFTILE": new FormControl(''),
        "Checkbox_Comment_ROOFTILE": new FormControl(''),
        "ROOFWOOD": new FormControl(''),
        "Checkbox_Comment_ROOFWOOD": new FormControl(''),
        "ROOFOTHR": new FormControl(''),
        "Checkbox_Comment_ROOFOTHR": new FormControl(''),
        "ROOFSTLJ": new FormControl(''),
        "Checkbox_Comment_ROOFSTLJ": new FormControl(''),
        "ROOFWODJ": new FormControl(''),
        "Checkbox_Comment_ROOFWODJ": new FormControl(''),
        "ROOFSTLT": new FormControl(''),
        "Checkbox_Comment_ROOFSTLT": new FormControl(''),
        "ROOFBILT": new FormControl(''),
        "Checkbox_Comment_ROOFBILT": new FormControl(''),
        "ROOFCDEK": new FormControl(''),
        "Checkbox_Comment_ROOFCDEK": new FormControl(''),
        "ROOFALUM": new FormControl(''),
        "Checkbox_Comment_ROOFALUM": new FormControl(''),
        "ROOFASBS": new FormControl(''),
        "Checkbox_Comment_ROOFASBS": new FormControl(''),
        "ROOFCORR": new FormControl(''),
        "Checkbox_Comment_ROOFCORR": new FormControl(''),
        "ROOFSHNG": new FormControl(''),
        "Checkbox_Comment_ROOFSHNG": new FormControl(''),
        "ROOFINSL": new FormControl(''),
        "Checkbox_Comment_ROOFINSL": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "EXTRMETL": new FormControl(''),
      "Checkbox_Comment_EXTRMETL": new FormControl(''),
      "EXTRBLCK": new FormControl(''),
      "Checkbox_Comment_EXTRBLCK": new FormControl(''),
      "EXTRWALL": new FormControl(''),
      "Checkbox_Comment_EXTRWALL": new FormControl(''),
      "EXTRBRCK": new FormControl(''),
      "Checkbox_Comment_EXTRBRCK": new FormControl(''),
      "EXTRROCK": new FormControl(''),
      "Checkbox_Comment_EXTRROCK": new FormControl(''),
      "EXTRSHNG": new FormControl(''),
      "Checkbox_Comment_EXTRSHNG": new FormControl(''),
      "EXTRSTUC": new FormControl(''),
      "Checkbox_Comment_EXTRSTUC": new FormControl(''),
      "EXTRWOOD": new FormControl(''),
      "Checkbox_Comment_EXTRWOOD": new FormControl(''),
      "EXTROTHR": new FormControl(''),
      "Checkbox_Comment_EXTROTHR": new FormControl(''),
      "EXTRMASN": new FormControl(''),
      "Checkbox_Comment_EXTRMASN": new FormControl(''),
      "EXTRGLAS": new FormControl(''),
      "Checkbox_Comment_EXTRGLAS": new FormControl(''),
      "HEATINGANDCOOLING": new FormGroup({
        "HTCLCHCL": new FormControl(''),
        "Checkbox_Comment_HTCLCHCL": new FormControl(''),
        "HTCLCNTA": new FormControl(''),
        "Checkbox_Comment_HTCLCNTA": new FormControl(''),
        "HTCLWNDC": new FormControl(''),
        "Checkbox_Comment_HTCLWNDC": new FormControl(''),
        "HTCLOFAC": new FormControl(''),
        "Checkbox_Comment_HTCLOFAC": new FormControl(''),
        "HTCLNOCL": new FormControl(''),
        "Checkbox_Comment_HTCLNOCL": new FormControl(''),
        "HTCLCGAS": new FormControl(''),
        "Checkbox_Comment_HTCLCGAS": new FormControl(''),
        "HTCLCELC": new FormControl(''),
        "Checkbox_Comment_HTCLCELC": new FormControl(''),
        "HTCLNOHT": new FormControl(''),
        "Checkbox_Comment_HTCLNOHT": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "FLORCONC": new FormControl(''),
      "Checkbox_Comment_FLORCONC": new FormControl(''),
      "FLORWOOD": new FormControl(''),
      "Checkbox_Comment_FLORWOOD": new FormControl(''),
      "LOADDOCK": new FormControl(''),
      "Checkbox_Comment_LOADDOCK": new FormControl(''),
      "LOADDRIV": new FormControl(''),
      "Checkbox_Comment_LOADDRIV": new FormControl(''),
      "LOADLODF": new FormControl(''),
      "Checkbox_Comment_LOADLODF": new FormControl(''),
      "LOADLODR": new FormControl(''),
      "Checkbox_Comment_LOADLODR": new FormControl(''),
      "LOADDOOR": new FormControl(''),
      "Checkbox_Comment_LOADDOOR": new FormControl(''),
      "LOADOTHR": new FormControl(''),
      "Checkbox_Comment_LOADOTHR": new FormControl(''),
      "LOADNONE": new FormControl(''),
      "Checkbox_Comment_LOADNONE": new FormControl(''),
      "WATERSEWERGAS": new FormGroup({
        "WATRCTYW": new FormControl(''),
        "Checkbox_Comment_WATRCTYW": new FormControl(''),
        "WATRPRVW": new FormControl(''),
        "Checkbox_Comment_WATRPRVW": new FormControl(''),
        "WATRCOPW": new FormControl(''),
        "Checkbox_Comment_WATRCOPW": new FormControl(''),
        "WATRWELL": new FormControl(''),
        "Checkbox_Comment_WATRWELL": new FormControl(''),
        "WATRMMWL": new FormControl(''),
        "Checkbox_Comment_WATRMMWL": new FormControl(''),
        "WATRIMWL": new FormControl(''),
        "Checkbox_Comment_WATRIMWL": new FormControl(''),
        "WATRNWTR": new FormControl(''),
        "Checkbox_Comment_WATRNWTR": new FormControl(''),
        "WATRCTYS": new FormControl(''),
        "Checkbox_Comment_WATRCTYS": new FormControl(''),
        "WATRPRVS": new FormControl(''),
        "Checkbox_Comment_WATRPRVS": new FormControl(''),
        "WATRNSWR": new FormControl(''),
        "Checkbox_Comment_WATRNSWR": new FormControl(''),
        "WATRSEPT": new FormControl(''),
        "Checkbox_Comment_WATRSEPT": new FormControl(''),
        "WATRPHON": new FormControl(''),
        "Checkbox_Comment_WATRPHON": new FormControl(''),
        "WATRGASS": new FormControl(''),
        "Checkbox_Comment_WATRGASS": new FormControl(''),
        "WATRPROP": new FormControl(''),
        "Checkbox_Comment_WATRPROP": new FormControl(''),
        "WATRNGAS": new FormControl(''),
        "Checkbox_Comment_WATRNGAS": new FormControl(''),
        "WATRMGMT": new FormControl(''),
        "Checkbox_Comment_WATRMGMT": new FormControl(''),
        "WATRIGAS": new FormControl(''),
        "Checkbox_Comment_WATRIGAS": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "ELECTRIC": new FormGroup({
        "ELECMRMR": new FormControl(''),
        "Checkbox_Comment_ELECMRMR": new FormControl(''),
        "ELECIMTR": new FormControl(''),
        "Checkbox_Comment_ELECIMTR": new FormControl(''),
        "ELECELEC": new FormControl(''),
        "Checkbox_Comment_ELECELEC": new FormControl(''),
        "ELECCOOP": new FormControl(''),
        "Checkbox_Comment_ELECCOOP": new FormControl(''),
        "ELEC220V": new FormControl(''),
        "Checkbox_Comment_ELEC220V": new FormControl(''),
        "ELEC440V": new FormControl(''),
        "Checkbox_Comment_ELEC440V": new FormControl(''),
        "ELECSNGL": new FormControl(''),
        "Checkbox_Comment_ELECSNGL": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "FENCLINK": new FormControl(''),
      "Checkbox_Comment_FENCLINK": new FormControl(''),
      "FENCPRIV": new FormControl(''),
      "Checkbox_Comment_FENCPRIV": new FormControl(''),
      "FENCDECO": new FormControl(''),
      "Checkbox_Comment_FENCDECO": new FormControl(''),
      "FENCFULL": new FormControl(''),
      "Checkbox_Comment_FENCFULL": new FormControl(''),
      "FENCPART": new FormControl(''),
      "Checkbox_Comment_FENCPART": new FormControl(''),
      "FENCGATE": new FormControl(''),
      "Checkbox_Comment_FENCGATE": new FormControl(''),
      "CEIL": new FormControl(''),
      "0810CEIL": new FormControl(''),
      "ALRMFIRE": new FormControl(''),
      "Checkbox_Comment_ALRMFIRE": new FormControl(''),
      "ALRMBRGL": new FormControl(''),
      "Checkbox_Comment_ALRMBRGL": new FormControl(''),
      "ALRMMNTR": new FormControl(''),
      "Checkbox_Comment_ALRMMNTR": new FormControl(''),
      "ALRMSPRN": new FormControl(''),
      "Checkbox_Comment_ALRMSPRN": new FormControl(''),
      "ALRMSECP": new FormControl(''),
      "Checkbox_Comment_ALRMSECP": new FormControl(''),
      "ALRMSECG": new FormControl(''),
      "Checkbox_Comment_ALRMSECG": new FormControl(''),
      "LBOX": new FormControl(''),
      "0830LBOX": new FormControl(''),
      "0840LOCL": new FormControl(''),
      "SHOWINGINSTRUCTIONS": new FormGroup({
        "SHOWCLLO": new FormControl(''),
        "Checkbox_Comment_SHOWCLLO": new FormControl(''),
        "SHOWLOCK": new FormControl(''),
        "Checkbox_Comment_SHOWLOCK": new FormControl(''),
        "SHOWCLOW": new FormControl(''),
        "Checkbox_Comment_SHOWCLOW": new FormControl(''),
        "SHOWCLTN": new FormControl(''),
        "Checkbox_Comment_SHOWCLTN": new FormControl(''),
        "SHOWDRIV": new FormControl(''),
        "Checkbox_Comment_SHOWDRIV": new FormControl(''),
        "SHOWCLMG": new FormControl(''),
        "Checkbox_Comment_SHOWCLMG": new FormControl(''),
        "SHOWREST": new FormControl(''),
        "Checkbox_Comment_SHOWREST": new FormControl(''),
        "SHOWAPPT": new FormControl(''),
        "Checkbox_Comment_SHOWAPPT": new FormControl(''),
        "SHOWOWOC": new FormControl(''),
        "Checkbox_Comment_SHOWOWOC": new FormControl(''),
        "SHOWALRM": new FormControl(''),
        "Checkbox_Comment_SHOWALRM": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "Showing_Instructions": new FormControl(''),
      "OINC": new FormControl(''),
      "0920OINC": new FormControl(''),
      "OEXP": new FormControl(''),
      "0930OEXP": new FormControl(''),
      "TAXR": new FormControl(''),
      "0940TAXR": new FormControl(''),
      "LNDV": new FormControl(''),
      "0950LNDV": new FormControl(''),
      "IMPV": new FormControl(''),
      "0960IMPV": new FormControl(''),
      "TTLV": new FormControl(''),
      "0970TTLV": new FormControl(''),
      "SALESINCLUDES": new FormGroup({
        "SINCLNDO": new FormControl(''),
        "Checkbox_Comment_SINCLNDO": new FormControl(''),
        "SINCLNDI": new FormControl(''),
        "Checkbox_Comment_SINCLNDI": new FormControl(''),
        "SINCBLDG": new FormControl(''),
        "Checkbox_Comment_SINCBLDG": new FormControl(''),
        "SINCBUSN": new FormControl(''),
        "Checkbox_Comment_SINCBUSN": new FormControl(''),
        "SINCINVN": new FormControl(''),
        "Checkbox_Comment_SINCINVN": new FormControl(''),
        "SINCFRCH": new FormControl(''),
        "Checkbox_Comment_SINCFRCH": new FormControl(''),
        "SINCEQIP": new FormControl(''),
        "Checkbox_Comment_SINCEQIP": new FormControl(''),
        "SINCFURN": new FormControl(''),
        "Checkbox_Comment_SINCFURN": new FormControl(''),
        "SINCLICS": new FormControl(''),
        "Checkbox_Comment_SINCLICS": new FormControl(''),
        "SINCOTHR": new FormControl(''),
        "Checkbox_Comment_SINCOTHR": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "LSDESECS": new FormControl(''),
      "Checkbox_Comment_LSDESECS": new FormControl(''),
      "LSDEPROP": new FormControl(''),
      "Checkbox_Comment_LSDEPROP": new FormControl(''),
      "LSDEWTRS": new FormControl(''),
      "Checkbox_Comment_LSDEWTRS": new FormControl(''),
      "LSDESATD": new FormControl(''),
      "Checkbox_Comment_LSDESATD": new FormControl(''),
      "LSDESOLP": new FormControl(''),
      "Checkbox_Comment_LSDESOLP": new FormControl(''),
      "PRPTDOPT": new FormControl(''),
      "Checkbox_Comment_PRPTDOPT": new FormControl(''),
      "PRPTDMAN": new FormControl(''),
      "Checkbox_Comment_PRPTDMAN": new FormControl(''),
      "PRPTPARK": new FormControl(''),
      "Checkbox_Comment_PRPTPARK": new FormControl(''),
      "OCCPCPIC": new FormControl(''),
      "Checkbox_Comment_OCCPCPIC": new FormControl(''),
      "OCCPFULL": new FormControl(''),
      "Checkbox_Comment_OCCPFULL": new FormControl(''),
      "OCCPNLES": new FormControl(''),
      "Checkbox_Comment_OCCPNLES": new FormControl(''),
      "OCCPGLES": new FormControl(''),
      "Checkbox_Comment_OCCPGLES": new FormControl(''),
      "OCCPMLES": new FormControl(''),
      "Checkbox_Comment_OCCPMLES": new FormControl(''),
      "OCCPPLES": new FormControl(''),
      "Checkbox_Comment_OCCPPLES": new FormControl(''),
      "OCCPSLES": new FormControl(''),
      "Checkbox_Comment_OCCPSLES": new FormControl(''),
      "OCCPFLES": new FormControl(''),
      "Checkbox_Comment_OCCPFLES": new FormControl(''),
      "OCCPSUIT": new FormControl(''),
      "Checkbox_Comment_OCCPSUIT": new FormControl(''),
      "TENANTPAYS": new FormGroup({
        "TNNTUTIL": new FormControl(''),
        "Checkbox_Comment_TNNTUTIL": new FormControl(''),
        "TNNTCOMM": new FormControl(''),
        "Checkbox_Comment_TNNTCOMM": new FormControl(''),
        "TNNTMNTN": new FormControl(''),
        "Checkbox_Comment_TNNTMNTN": new FormControl(''),
        "TNNTTAXS": new FormControl(''),
        "Checkbox_Comment_TNNTTAXS": new FormControl(''),
        "TNNTINSR": new FormControl(''),
        "Checkbox_Comment_TNNTINSR": new FormControl(''),
        "TNNTOTHR": new FormControl(''),
        "Checkbox_Comment_TNNTOTHR": new FormControl(''),
        "TNNTPARK": new FormControl(''),
        "Checkbox_Comment_TNNTPARK": new FormControl(''),
        "TNNTJNTR": new FormControl(''),
        "Checkbox_Comment_TNNTJNTR": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "OWNERPAYS": new FormGroup({
        "OWNRUTIL": new FormControl(''),
        "Checkbox_Comment_OWNRUTIL": new FormControl(''),
        "OWNRTAXS": new FormControl(''),
        "Checkbox_Comment_OWNRTAXS": new FormControl(''),
        "OWNRINSR": new FormControl(''),
        "Checkbox_Comment_OWNRINSR": new FormControl(''),
        "OWNRMNTN": new FormControl(''),
        "Checkbox_Comment_OWNRMNTN": new FormControl(''),
        "OWNRPARK": new FormControl(''),
        "Checkbox_Comment_OWNRPARK": new FormControl(''),
        "OWNRJNTR": new FormControl(''),
        "Checkbox_Comment_OWNRJNTR": new FormControl(''),
        "OWNRNONE": new FormControl(''),
        "Checkbox_Comment_OWNRNONE": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "LOAN": new FormControl(''),
      "1060LOAN": new FormControl(''),
      "SELLERWILLCONSIDER": new FormGroup({
        "TERMCONV": new FormControl(''),
        "Checkbox_Comment_TERMCONV": new FormControl(''),
        "TERMLEAS": new FormControl(''),
        "Checkbox_Comment_TERMLEAS": new FormControl(''),
        "TERMCASH": new FormControl(''),
        "Checkbox_Comment_TERMCASH": new FormControl(''),
        "TERMSUBM": new FormControl(''),
        "Checkbox_Comment_TERMSUBM": new FormControl(''),
        "TERMOWNR": new FormControl(''),
        "Checkbox_Comment_TERMOWNR": new FormControl(''),
        "TERMLIMI": new FormControl(''),
        "Checkbox_Comment_TERMLIMI": new FormControl(''),
      }, this.customFormsService.requireCheckboxesToBeCheckedValidator(() => true)),
      "SOPTTTLP": new FormControl(''),
      "Checkbox_Comment_SOPTTTLP": new FormControl(''),
      "SOPTDIVD": new FormControl(''),
      "Checkbox_Comment_SOPTDIVD": new FormControl(''),
      "SOPTADJL": new FormControl(''),
      "Checkbox_Comment_SOPTADJL": new FormControl(''),
      "UNIQBANK": new FormControl(''),
      "Checkbox_Comment_UNIQBANK": new FormControl(''),
      "UNIQPROB": new FormControl(''),
      "Checkbox_Comment_UNIQPROB": new FormControl(''),
      "UNIQESTA": new FormControl(''),
      "Checkbox_Comment_UNIQESTA": new FormControl(''),
      "UNIQCORT": new FormControl(''),
      "Checkbox_Comment_UNIQCORT": new FormControl(''),
      "UNIQBIDD": new FormControl(''),
      "Checkbox_Comment_UNIQBIDD": new FormControl(''),
      "UNIQEXCH": new FormControl(''),
      "Checkbox_Comment_UNIQEXCH": new FormControl(''),
    }, { updateOn: 'change' });


    // Customized conditionals and form setters
    //this.form.get('POOLTYPE').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('Pool').value == 'Yes')]);
    //this.form.get('HOAAMENITIES').setValidators([this.customFormsService.requireCheckboxesToBeCheckedValidator(() => this.form.get('HOAA').value == 'Yes')]);

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      const temp = value;
      Object.keys(temp).forEach(key => {
        if(!temp[key] || temp[key] == '' || temp[key] == null)
          delete temp[key];
      });
      localStorage.setItem('cachedFormData', JSON.stringify(temp));

      this.form.get('Co_Office_MLS_ID').updateValueAndValidity({onlySelf: true});
      this.form.get('Co_Agent_MLS_ID').updateValueAndValidity({onlySelf: true});
    })

    this.form.get('Office_ID').valueChanges.subscribe(val => {
      this.form.get('Office_MLS_ID').setValue(val);
    })

    this.formSubscription.add(this.form.get('Street_Number').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Direction').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Address').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Suffix').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.navigationMode = new CustomNavigationMode(this.form);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.key = params.key;
      if(params.key) {
        this.dataSubscription = this.responseService.getResponse(params.key).subscribe(payload => {
          this.form.patchValue(payload.data);
          this.responseCache = payload;
        })
      } else {
        if(localStorage.getItem('cachedFormData')) {
          let confirmation = confirm("You have saved form data, would you like to load it?")

          if(confirmation) {
            let data = localStorage.getItem('cachedFormData');
            this.form.patchValue(JSON.parse(data));
          } else {
            localStorage.removeItem('cachedFormData');
          }
        }
      }
    })
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if(this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if(this.formSubscription)
      this.formSubscription.unsubscribe();

    if(this.responseCache)
      this.responseCache = null;
  }

  get f() { return this.form.controls; }

  submit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if(this.form.valid) {
      this.isLoading = true;
      let temp = this.form.value;
      //if(temp["Dollar_Percent"] == '$') {
      //  temp["Commission_1"] = '$' + temp["Commission_Amount"];
      //} else if(temp["Dollar_Percent"] == '%') {
      //  temp["Commission_1"] = temp["Commission_Amount"] + '%';
      //}
      temp["Office_MLS_ID"] = temp["Office_ID"]; 


      const data = this.customFormsService.sanitizeFormPayload(temp);

      if(!this.responseCache) {
        const response = {
          form: '5eed223f5e7b2a7e2568f1fa',
          organization: '',
          name: 'Save_' + Math.random().toString(36).substr(2, 5),
          data: temp,
          submitted: true
        }
        this.responseService.postResponse(response).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        })
      } else {
        this.responseService.patchResponse(this.responseCache._id, {data: data, submitted: true }).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        });
      }
    } else {
      this.numErrors = 0;
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.numErrors++;
              console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });
      this.toastrService.error('You have not completed the form!', 'Submission Failed');
    }
  }

  save() {
    const self = this;
    this.isSaving = true;

    // Remove empty/null inputs to store less
    const data = this.customFormsService.sanitizeFormPayload(this.form.value);
    if(!this.responseCache) {
      this.modalService.openModal('SaveResponse', { backdrop: 'static', keyboard: false }, {formData: data, formId: '5eed223f5e7b2a7e2568f1fa'}, function(data) {
        console.log(data);
        if(data.err)
          self.toastrService.error('Save was unsuccessful, please try again.', 'Error 500');
        else {
          self.toastrService.success('Save was successful', 'Success');
          self.router.navigate(['.'], { relativeTo: self.route, queryParams: {key: data._id}, queryParamsHandling: 'merge' });
          self.form.markAsPristine();
        }
      });
    } else {
      if(this.form.dirty) {
        this.responseService.patchResponse(this.responseCache._id, {data: data}).subscribe(res => {
          this.isSaving = false;
          this.responseCache = res;
          this.form.markAsPristine();
          this.toastrService.success('Save was successful', 'Success');
        });
      } else {
        this.toastrService.info('No changes detected', 'Unsuccessful Save');
      }
    }
  }

  validate() {
    this.submitted = true;
    this.numErrors = 0;
    this.errorList = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.numErrors++;
          //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          let errorElem = document.querySelector("[formControlName='" + key + "']");
          if(!errorElem) {
            let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
            errorElem = document.querySelector("[formControlName='" + childKey + "']");
          }

          let stepId = errorElem.closest('aw-wizard-step').getAttribute('stepid')
          this.errorList.push({
            key: key,
            stepId: stepId,
          })
        });
      }
    });
  }

  goToError(error) {
    const key = error.key
    const index = this.wizard.getIndexOfStepWithId(error.stepId);
    this.wizard.goToStep(index);

    let errorElem = document.querySelector("[formControlName='" + key + "']");
    if(!errorElem) {
      let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
      errorElem = document.querySelector("[formControlName='" + childKey + "']");
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + childKey + "']").getBoundingClientRect().top - 250
      }, 100)
    } else {
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + error.key + "']").getBoundingClientRect().top - 200
      }, 100)
    }
  }

  enableControl(condition: boolean, controlNames: string[]) {
    if(condition) {
      controlNames.forEach(name => {
        this.form.controls[name].enable();
      })
    } else {
      controlNames.forEach(name => {
        this.form.controls[name].disable();
      })
    }
  }

  complete() {
    this.layoutService.setSideNavHidden(false);
    this.router.navigate(['../history'], { relativeTo: this.route });
  }

  navigateToSign() {
    this.router.navigate(['../sign-request'], { relativeTo: this.route });
  }
}
