import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-landlots',
  templateUrl: './view-landlots.component.html',
  styleUrls: ['./view-landlots.component.scss']
})
export class ViewLandlotsComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
     // For display purposes
     "FULL_ADDRESS": new FormControl({value: '', disabled: true}),
     "County_ComboBox": new FormControl({value: '', disabled: true}),
     "APN_1": new FormControl({value: '', disabled: true}),
     "APN_2": new FormControl({value: '', disabled: true}),
     "APN_3": new FormControl({value: '', disabled: true}),
     "New_Construction": new FormControl({value: '', disabled: true}),
     "Street_Number": new FormControl({value: '', disabled: true}),
     "Street_Number_Modifier": new FormControl({value: '', disabled: true}),
     "Street_Direction": new FormControl({value: '', disabled: true}),
     "Street_Address": new FormControl({value: '', disabled: true}),
     "Street_Suffix": new FormControl({value: '', disabled: true}),
     "Post_Direction": new FormControl({value: '', disabled: true}),
     "Unit_Number": new FormControl({value: '', disabled: true}),
     "City_Fill_In": new FormControl({value: '', disabled: true}),
     "Zip_Code": new FormControl({value: '', disabled: true}),
     "Zip_Plus_4": new FormControl({value: '', disabled: true}),
     "Cross_Street": new FormControl({value: '', disabled: true}),
     "Office_ID": new FormControl({value: '', disabled: true}),
     "Office_MLS_ID": new FormControl({value: '', disabled: true}),
     "Agent_MLS_ID": new FormControl({value: '', disabled: true}),
     "Co_Office_MLS_ID": new FormControl({value: '', disabled: true}),
     "Co_Agent_MLS_ID": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0001": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0002": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0003": new FormControl({value: '', disabled: true}),
     "Transaction": new FormControl({value: '', disabled: true}),
     "SSLE": new FormControl({value: '', disabled: true}),
     "REBK": new FormControl({value: '', disabled: true}),
     "Lease_Price": new FormControl({value: '', disabled: true}),
     "Lease_Type": new FormControl({value: '', disabled: true}),
     "Sub_Lease": new FormControl({value: '', disabled: true}),
     "Available_Date": new FormControl({value: '', disabled: true}),
     "Lease_Expiration": new FormControl({value: '', disabled: true}),
     "APNS": new FormControl({value: '', disabled: true}),
     "0100APN2": new FormControl({value: '', disabled: true}),
     "0110APN3": new FormControl({value: '', disabled: true}),
     "0120APN4": new FormControl({value: '', disabled: true}),
     "0130APN5": new FormControl({value: '', disabled: true}),
     "Listing_Price_1": new FormControl({value: '', disabled: true}),
     "Listing_Price_2": new FormControl({value: '', disabled: true}),
     "Agreement_Type_ComboBox": new FormControl({value: '', disabled: true}),
     "SCOP": new FormControl({value: '', disabled: true}),
     "0235SCOP": new FormControl({value: '', disabled: true}),
     "DUAL": new FormControl({value: '', disabled: true}),
     "0240DUAL": new FormControl({value: '', disabled: true}),
     "Publish_To_Internet": new FormControl({value: '', disabled: true}),
     "Show_AVM_On_VOW": new FormControl({value: '', disabled: true}),
     "Show_Address_to_Public": new FormControl({value: '', disabled: true}),
     "Show_Comments_On_VOW": new FormControl({value: '', disabled: true}),
     //"Dollar_Percent": new FormControl({value: '', disabled: true}),
     //"Commission_1": new FormControl({value: '', disabled: true}),
     //"Commission_Amount": new FormControl({value: '', disabled: true}),
     //"Variable_Rate_Comm": new FormControl({value: '', disabled: true}),
     //"Comm_Comments": new FormControl({value: '', disabled: true}),
     "EOFF": new FormControl({value: '', disabled: true}),
     "0380EOAD": new FormControl({value: '', disabled: true}),
     "Lot_Sq_Ft": new FormControl({value: '', disabled: true}),
     "Lot_Measurement": new FormControl({value: '', disabled: true}),
     "0410ZONE": new FormControl({value: '', disabled: true}),
     "LotSize_Source": new FormControl({value: '', disabled: true}),
     "Listing_Date": new FormControl({value: '', disabled: true}),
     "Expiration_Date": new FormControl({value: '', disabled: true}),
     "Owner_Name": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Area": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Prefix": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Suffix": new FormControl({value: '', disabled: true}),
     "Showing_Instructions": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Area": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Prefix": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Suffix": new FormControl({value: '', disabled: true}),
     "Property_Description": new FormControl({value: '', disabled: true}),
     "Agent_Only_Remarks": new FormControl({value: '', disabled: true}),
     "Directions": new FormControl({value: '', disabled: true}),
     "School_District_Fill_In": new FormControl({value: '', disabled: true}),
     "School_Code_1": new FormControl({value: '', disabled: true}),
     "School_Code_2": new FormControl({value: '', disabled: true}),
     "School_Code_3": new FormControl({value: '', disabled: true}),
     "0570SCMT": new FormControl({value: '', disabled: true}),
     "CNSS": new FormControl({value: '', disabled: true}),
     "0650CNSS": new FormControl({value: '', disabled: true}),
     "AREA": new FormControl({value: '', disabled: true}),
     "0660AREA": new FormControl({value: '', disabled: true}),
     "Area_Fill_In": new FormControl({value: '', disabled: true}),
     "LANDLOTDESC": new FormGroup({
       "LOTDIRRE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDIRRE": new FormControl({value: '', disabled: true}),
       "LOTDLESS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDLESS": new FormControl({value: '', disabled: true}),
       "LOTDLEVL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDLEVL": new FormControl({value: '', disabled: true}),
       "LOTDROLL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDROLL": new FormControl({value: '', disabled: true}),
       "LOTDHILL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDHILL": new FormControl({value: '', disabled: true}),
       "LOTDELEV": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDELEV": new FormControl({value: '', disabled: true}),
       "LOTDAC15": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDAC15": new FormControl({value: '', disabled: true}),
       "LOTDMOR5": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_LOTDMOR5": new FormControl('')
     }),
     "SEWERWATER": new FormGroup({
       "WATRPWTR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRPWTR": new FormControl({value: '', disabled: true}),
       "WATRPWEL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRPWEL": new FormControl({value: '', disabled: true}),
       "WATRDWTR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRDWTR": new FormControl({value: '', disabled: true}),
       "WATRSWEL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRSWEL": new FormControl({value: '', disabled: true}),
       "WATRSEPT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRSEPT": new FormControl({value: '', disabled: true}),
       "WATRCWEL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRCWEL": new FormControl({value: '', disabled: true}),
       "WATRCSEW": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRCSEW": new FormControl({value: '', disabled: true}),
       "WATRNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRNONE": new FormControl({value: '', disabled: true}),
       "WATRPWLL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRPWLL": new FormControl('')
      }),
     "GASELECTRIC": new FormGroup({
       "GSECPROP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSECPROP": new FormControl({value: '', disabled: true}),
       "GSECNOGS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSECNOGS": new FormControl({value: '', disabled: true}),
       "GSECPUBU": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSECPUBU": new FormControl({value: '', disabled: true}),
       "GSECNOEL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSECNOEL": new FormControl({value: '', disabled: true}),
       "GSECNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSECNONE": new FormControl({value: '', disabled: true}),
      }),
     "SPECFLOD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECFLOD": new FormControl({value: '', disabled: true}),
     "SPECTOXI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECTOXI": new FormControl({value: '', disabled: true}),
     "SPECPUBL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECPUBL": new FormControl({value: '', disabled: true}),
     "SPECSPSA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSPSA": new FormControl({value: '', disabled: true}),
     "SPECSPFE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSPFE": new FormControl({value: '', disabled: true}),
     "SPECFLIT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECFLIT": new FormControl({value: '', disabled: true}),
     "SPECSEER": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSEER": new FormControl({value: '', disabled: true}),
     "IRPTHOME": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTHOME": new FormControl({value: '', disabled: true}),
     "IRPTROOF": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTROOF": new FormControl({value: '', disabled: true}),
     "IRPTPEST": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTPEST": new FormControl({value: '', disabled: true}),
     "IRPTSEPT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTSEPT": new FormControl({value: '', disabled: true}),
     "IRPTWELR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTWELR": new FormControl({value: '', disabled: true}),
     "IRPTWTRP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTWTRP": new FormControl({value: '', disabled: true}),
     "IRPTCC&R": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTCC&R": new FormControl({value: '', disabled: true}),
     "IRPTRULE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTRULE": new FormControl({value: '', disabled: true}),
     "IRPTBYLW": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTBYLW": new FormControl({value: '', disabled: true}),
     "IRPTBDGT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTBDGT": new FormControl({value: '', disabled: true}),
     "IRPTFINC": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTFINC": new FormControl({value: '', disabled: true}),
     "IRPTOTHR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_IRPTOTHR": new FormControl({value: '', disabled: true}),
     "POTERESI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTERESI": new FormControl({value: '', disabled: true}),
     "POTEMULT": new FormControl({value: '', disabled: true}),
     "POTECONP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTECONP": new FormControl({value: '', disabled: true}),
     "POTECOMM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTECOMM": new FormControl({value: '', disabled: true}),
     "POTEINDU": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTEINDU": new FormControl({value: '', disabled: true}),
     "POTEMANU": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTEMANU": new FormControl({value: '', disabled: true}),
     "POTEOFFI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTEOFFI": new FormControl({value: '', disabled: true}),
     "POTERETA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTERETA": new FormControl({value: '', disabled: true}),
     "POTEMOHO": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTEMOHO": new FormControl({value: '', disabled: true}),
     "POTEMOBL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTEMOBL": new FormControl({value: '', disabled: true}),
     "POTEHORS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_POTEHORS": new FormControl({value: '', disabled: true}),
     "IMPROVEMENTS": new FormGroup({
       "IMPRNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IMPRNONE": new FormControl({value: '', disabled: true}),
       "IMPRSTRM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IMPRSTRM": new FormControl({value: '', disabled: true}),
       "IMPRSTRL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IMPRSTRL": new FormControl({value: '', disabled: true}),
       "IMPRSIDE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IMPRSIDE": new FormControl({value: '', disabled: true}),
       "IMPRCURB": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_IMPRCURB": new FormControl('')
      }),
     "TRANCITS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANCITS": new FormControl({value: '', disabled: true}),
     "TRANCNTR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANCNTR": new FormControl({value: '', disabled: true}),
     "TRANPRIV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANPRIV": new FormControl({value: '', disabled: true}),
     "TRANSTHG": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANSTHG": new FormControl({value: '', disabled: true}),
     "TRANFRWY": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANFRWY": new FormControl({value: '', disabled: true}),
     "TRANEASM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANEASM": new FormControl({value: '', disabled: true}),
     "TRANPAVE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANPAVE": new FormControl({value: '', disabled: true}),
     "TRANGRVL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANGRVL": new FormControl({value: '', disabled: true}),
     "TRANRAIL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANRAIL": new FormControl({value: '', disabled: true}),
     "TRANAIRP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANAIRP": new FormControl({value: '', disabled: true}),
     "TRAN4WDR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRAN4WDR": new FormControl({value: '', disabled: true}),
     "TRANSEAS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TRANSEAS": new FormControl({value: '', disabled: true}),
     "SITECRNR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITECRNR": new FormControl({value: '', disabled: true}),
     "SITECDES": new FormControl({value: '', disabled: true}),
     "SITEBLUF": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEBLUF": new FormControl({value: '', disabled: true}),
     "SITEGOLF": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEGOLF": new FormControl({value: '', disabled: true}),
     "SITERIVR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITERIVR": new FormControl({value: '', disabled: true}),
     "SITELAKE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITELAKE": new FormControl({value: '', disabled: true}),
     "SITEWATR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEWATR": new FormControl({value: '', disabled: true}),
     "SITECREK": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITECREK": new FormControl({value: '', disabled: true}),
     "SITESCRK": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITESCRK": new FormControl({value: '', disabled: true}),
     "SITEGATE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEGATE": new FormControl({value: '', disabled: true}),
     "SITEPRKV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEPRKV": new FormControl({value: '', disabled: true}),
     "SALETOPO": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALETOPO": new FormControl({value: '', disabled: true}),
     "SALESUBD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALESUBD": new FormControl({value: '', disabled: true}),
     "SALEBTOS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALEBTOS": new FormControl({value: '', disabled: true}),
     "SALEADJL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALEADJL": new FormControl({value: '', disabled: true}),
     "1SLNASSM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNASSM": new FormControl({value: '', disabled: true}),
     "1SLNPRVT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNPRVT": new FormControl({value: '', disabled: true}),
     "1SLNFRCL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNFRCL": new FormControl({value: '', disabled: true}),
     "2LNASSM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNASSM": new FormControl({value: '', disabled: true}),
     "2LNPRVT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNPRVT": new FormControl({value: '', disabled: true}),
     "TERMCONV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMCONV": new FormControl({value: '', disabled: true}),
     "TERMSUB": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMSUB": new FormControl({value: '', disabled: true}),
     "TERMLSOP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMLSOP": new FormControl({value: '', disabled: true}),
     "TERMCASH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMCASH": new FormControl({value: '', disabled: true}),
     "TERMOWNR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMOWNR": new FormControl({value: '', disabled: true}),
     "TERMLIMI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMLIMI": new FormControl({value: '', disabled: true}),
     "UNIQPROB": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQPROB": new FormControl({value: '', disabled: true}),
     "UNIQESTA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQESTA": new FormControl({value: '', disabled: true}),
     "UNIQCORT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQCORT": new FormControl({value: '', disabled: true}),
     "UNIQEXCH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQEXCH": new FormControl('')
    }, { updateOn: 'change' });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';
      newDiv.innerHTML = this.form.get( formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }
}
