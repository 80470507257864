import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response, ResponseStatisticsOptions, ResponseQueryOptions } from '../models/response.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  constructor(private http: HttpClient) { }

  // Responses[] => any, should create a 'PagedData' model
  public getHistory(page: any): Observable<any> {
    return this.http.get<any>('api/response/history', {params: page});
  }

  public getSubmitted(form: string, page: any): Observable<any> {
    return this.http.get<any>('api/response/form/' + form, {params: page});
  }

  public getResponse(key: string): Observable<Response> {
    return this.http.get<Response>('api/response/' + key);
  }

  public getViewOnlyResponse(key: string): Observable<Response> {
    return this.http.get<Response>('api/response/view/' + key);
  }

  public postResponse(body: Response): Observable<Response> {
    return this.http.post<Response>('/api/response', body)
  }

  public patchResponse(id: string, body: Partial<Response>): Observable<Response> {
    return this.http.patch<Response>('/api/response/' + id, body);
  }

  public queryResponses(query: ResponseQueryOptions): Observable<Response[]> {
    return this.http.get<Response[]>('api/response', { params: <any>query });
  }

  public queryResponseStatistics(query: ResponseStatisticsOptions): Observable<any> {
    return this.http.get<any>('api/response/statistics', { params: <any>query });
  }
}
