import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, AfterViewInit, OnDestroy {
  // Prevent "blink" effect
  public initialized = false;
  public hidden$: Observable<boolean>;

  constructor(
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.hidden$ = this.layoutService.sideNavHidden$;
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(false);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialized = true;

      this.layoutService.init();
      this.layoutService.update();
      this.layoutService.setAutoUpdate(true);
    });

    setTimeout(() => {
      if(!sessionStorage.getItem('sessionNotification')) {
        this.toastrService.warning('If you are on a workstation, ensure you are logged into your account!', 'Realty Concepts Notification', {
          timeOut: 10000,
          extendedTimeOut: 10000
        });
        sessionStorage.setItem('sessionNotification', 'true');
      }
    }, 3000)
    console.log(this.route.snapshot.data);
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.layoutService.destroy();
    });
  }

  closeSidenav() {
    setTimeout(() => {
      this.layoutService.setCollapsed(true);
    });
  }
}
