import { ConfigurableNavigationMode, WizardComponent } from 'angular-archwizard';
import { FormGroup } from '@angular/forms';

export class CustomNavigationMode extends ConfigurableNavigationMode {
  constructor(public form: FormGroup) {
    super()
  }

  protected transition(wizard: WizardComponent, destinationIndex: number): void {
    let curStep: any = wizard.currentStep;
    let destStep: any = wizard.wizardSteps[destinationIndex].stepId;
    let self = this;

    curStep.completed = true;
    curStep.touched = true;

    if(destStep)
      if(document.getElementById(destStep).classList.contains('error'))
        document.getElementById(destStep).classList.remove('error');
    
    if(document.getElementById(curStep.stepId + ' Form')) {
      document.getElementById(curStep.stepId + ' Form').querySelectorAll('[required]').forEach(function(i) {
        let control = i.getAttribute('formControlName')
        let group = i.getAttribute('formGroupName');
        
        if(self.form.get(control)) {
          if(self.form.get(control).errors) {
            curStep.completed = false;
            if(!document.getElementById(curStep.stepId).classList.contains('error'))
              document.getElementById(curStep.stepId).classList.add('error');
          }
        }

        if(self.form.get(group)) {
          if(self.form.get(group).errors) {
            curStep.completed = false;
            if(!document.getElementById(curStep.stepId).classList.contains('error'))
              document.getElementById(curStep.stepId).classList.add('error');
          }
        }
      })
    }
    this.form.updateValueAndValidity();
    wizard.currentStepIndex = destinationIndex;
  }

  protected canTransitionToStep(wizard: WizardComponent, destinationIndex: number): boolean {
    return true;
  }

  public isNavigable(wizard: WizardComponent, destinationIndex: number): boolean {
    return true;
  }
}