<div [formGroup]="form" class="h-100">
  <aw-wizard class="h-100" #wizard
    [awNavigationMode]="navigationMode"
    navBarLocation="left"
    navBarLayout="large-empty-symbols">
    <div class="form-header p-2">
      <h4 *ngIf="!responseCache; else saveTitle" class="font-weight-bold">
        FRESNO MLS
        <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Commercial Listing Input</small></div>
      </h4>
      <ng-template #saveTitle>
        <h4 class="font-weight-bold">
          {{ responseCache.name }}
          <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Updated {{ responseCache.timeUpdated | amTimeAgo }}</small></div>
        </h4>
      </ng-template>
      <img src="assets/images/RClogo.png" class="img-fluid" width="120" alt="Realty Concepts"/>
    </div>
    <div class="form-container card card-body">
      <aw-wizard-step
        stepId="Listing Information"
        stepTitle="Listing Information" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">1</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Listing Information Form">
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between pt-1">
              <label for="selCountyComboBox" class= "reqtext pr-2 py-2">*County:</label>
              <select id="selCountyComboBox" formControlName="County_ComboBox" class="form-control col-md-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.County_ComboBox.errors }"  required>
                <option value="Fresno" selected>Fresno</option>
                <option value="Kern">Kern</option>
                <option value="Kings">Kings</option>
                <option value="Madera">Madera</option>
                <option value="Mariposa">Mariposa</option>
                <option value="Merced">Merced</option>
                <option value="Mono">Mono</option>
                <option value="Orange">Orange</option>
                <option value="Placer">Placer</option>
                <option value="Riverside">Riverside</option>
                <option value="San Bernardino">San Bernardino</option>
                <option value="San Joaquin">San Joaquin</option>
                <option value="Santa Clara">Santa Clara</option>
                <option value="Stanislaus">Stanislaus</option>
                <option value="Tehama">Tehama</option>
                <option value="Tulare">Tulare</option>
                <option value="Tuolumne">Tuolumne</option>
                <option value="Ventura">Ventura</option>
                <option value="Out of Area">Out of Area</option>
                <option value="Unknown">Unknown</option>
                <option value="Alameda">Alameda</option>
                <option value="Alpine">Alpine</option>
                <option value="Amador">Amador</option>
                <option value="Butte">Butte</option>
                <option value="Calaveras">Calaveras</option>
                <option value="Colusa">Colusa</option>
                <option value="Contra Costa">Contra Costa</option>
                <option value="Del Norte">Del Norte</option>
                <option value="El Dorado">El Dorado</option>
                <option value="Glenn">Glenn</option>
                <option value="Humboldt">Humboldt</option>
                <option value="Imperial">Imperial</option>
                <option value="Inyo">Inyo</option>
                <option value="Lake">Lake</option>
                <option value="Lassen">Lassen</option>
                <option value="Los Angeles">Los Angeles</option>
                <option value="Marin">Marin</option>
                <option value="Mendocino">Mendocino</option>
                <option value="Modoc">Modoc</option>
                <option value="Monterey">Monterey</option>
                <option value="Napa">Napa</option>
                <option value="Nevada">Nevada</option>
                <option value="Plumas">Plumas</option>
                <option value="Sacramento">Sacramento</option>
                <option value="San Benito">San Benito</option>
                <option value="San Diego">San Diego</option>
                <option value="San Francisco">San Francisco</option>
                <option value="San Luis Obispo">San Luis Obispo</option>
                <option value="San Mateo">San Mateo</option>
                <option value="Santa Barbara">Santa Barbara</option>
                <option value="Santa Cruz">Santa Cruz</option>
                <option value="Shasta">Shasta</option>
                <option value="Sierra">Sierra</option>
                <option value="Siskiyou">Siskiyou</option>
                <option value="Solano">Solano</option>
                <option value="Sonoma">Sonoma</option>
                <option value="Sutter">Sutter</option>
                <option value="Trinity">Trinity</option>
                <option value="Yolo">Yolo</option>
                <option value="Yuba">Yuba</option>
              </select>
            </div>
            <div class="form-group col-md-6 justify-content-between">
              <label for="APN_1" class="reqtext pr-2 py-2">*APN:</label>
              <div class="d-flex">
                <input type="text" id="APN_1" formControlName="APN_1" class="form-control" size="3" maxlength="3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_1.errors }"  required>
                <label for="APN_2">&nbsp;-&nbsp;</label>
                <input type="text" id="APN_2" formControlName="APN_2" class="form-control" size="3" maxlength="3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_2.errors }"  required>
                <label for="APN_3">&nbsp;-&nbsp;</label>
                <input type="text" id="APN_3" formControlName="APN_3" class="form-control" size="3" maxlength="3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.APN_3.errors }"  required>
              </div>
            </div>
          </div>
          <div class="row fixcheck form-inline">
            <div class="form-check col-12 justify-content-end">
              <label class="form-check-label nonreqtext p-2">
              <input type="checkbox" id="New_Construction" formControlName="New_Construction" class="form-check-input">New / Under Contruction / NA</label>
            </div>
          </div>
          <!---->
					<h6 class="subtitle p-1">Address</h6>
					<input type="text" formControlName="FULL_ADDRESS" class="d-none form-control">
          <!---->
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Number" class= "reqtext pr-2 py-2">*Street # - Modifier:</label>
              <div class="d-flex">
                <input type="text" id="Street_Number" formControlName="Street_Number" class="form-control" size="6" maxlength="6" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Street_Number.errors }" required>
                <label for="Street_Number_Modifier">-</label>
                <input type="text" id="Street_Number_Modifier" formControlName="Street_Number_Modifier" class="form-control" size="6" maxlength="8">
              </div>
            </div>
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Direction" class="nonreqtext pr-2 py-2">Direction:</label>
              <select id="Street_Direction" formControlName="Street_Direction" class="form-control col-md-6 col-lg-8">
                <option value="" selected="">  </option>
                <option value="E"> East </option>
                <option value="N"> North </option>
                <option value="NE"> Northeast </option>
                <option value="NW"> Northwest </option>
                <option value="S"> South </option>
                <option value="SE"> Southeast </option>
                <option value="SW"> Southwest </option>
                <option value="W"> West </option>
              </select>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Address" class= "reqtext pr-2 py-2">*Street Name:</label>
              <input type="text" id="Street_Address" formControlName="Street_Address" class="form-control col-sm-8" size="" maxlength="30" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Street_Address.errors }" required>
            </div>
            <div class="form-group col-md-6 justify-content-between">
              <label for="Street_Suffix" class="nonreqtext pr-2 py-2">Sufix:</label>
              <select id="Street_Suffix"  formControlName="Street_Suffix" class="form-control col-md-6 col-lg-8">
                <option value="" selected=""> </option>
                <option value="Ave"> Avenue </option>
                <option value="Blvd"> Boulevard </option>
                <option value="Cir"> Circle </option>
                <option value="Ct"> Court </option>
                <option value="Dr"> Drive </option>
                <option value="Jct"> Junction </option>
                <option value="Ln"> Lane </option>
                <option value="Pass"> Pass </option>
                <option value="Pkwy"> Parkway </option>
                <option value="Pl"> Place </option>
                <option value="Rd"> Road </option>
                <option value="Sqr"> Square </option>
                <option value="St"> Street </option>
                <option value="Way"> Way </option>
              </select>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 offset-md-6 justify-content-between">
              <label for="Post_Direction" class="nonreqtext pr-2 py-2">Post Direction:</label>
              <select id="Post_Direction" formControlName="Post_Direction" class="form-control col-md-6 col-lg-8">
                <option value="" selected="">  </option>
                <option value="E"> East </option>
                <option value="N"> North </option>
                <option value="NE"> Northeast </option>
                <option value="NW"> Northwest </option>
                <option value="S"> South </option>
                <option value="SE"> Southeast </option>
                <option value="SW"> Southwest </option>
                <option value="W"> West </option>
              </select>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Unit_Number" class="nonreqtext pr-2 py-2">Unit #:</label>
              <input type="text" id="Unit_Number" formControlName="Unit_Number" class="form-control col-md-8" size="6" maxlength="5">
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="City_Fill_In" class= "reqtext pr-2 py-2">*City:</label>
              <input type="text" id="City_Fill_In" formControlName="City_Fill_In" size="16" maxlength="20" class="form-control col-sm-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.City_Fill_In.errors }" required>
            </div>
            <div class="form-group col-md-6 justify-content-between">
              <label for="Zip_Code" class="reqtext pr-2 py-2">*Zip Code:</label>
              <div class="d-flex">
                <input type="text" id="Zip_Code" formControlName="Zip_Code" class="form-control" size="5" maxlength="5" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Zip_Code.errors }" required>
                <label for="Zip_Plus_4">
                -</label>
                <input type="text" id="Zip_Plus_4" formControlName="Zip_Plus_4" class="form-control" size="4" maxlength="4">
              </div>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Cross_Street" class="reqtext pr-2 py-2">
              *Cross Street:</label>
              <input type="text" id="Cross_Street" formControlName="Cross_Street" class="form-control col-sm-8" size="16" maxlength="30" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Cross_Street.errors }" required>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="txtOfficeAuto" class= "reqtext pr-2 py-2">
              *Office ID:</label>
              <select id="Office_ID" formControlName="Office_ID" class="form-control col-sm-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Office_ID.errors }" required>
                <option value=""> </option>
                <option value="630">630-Fresno</option>
                <option value="63004">63004-Clovis</option>
                <option value="63005">63005-Commercial</option>
                <option value="63006">63006-Oakhurst2</option>
              </select>
              <input type="text" id="txtOfficeAuto" formControlName="Office_MLS_ID" class="d-none form-control col-sm-8" size="40" maxlength="50" required>
            </div>
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Agent_MLS_ID" class= "reqtext pr-2 py-2">
              *Agent ID:</label>
              <input type="text" id="Agent_MLS_ID" formControlName="Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Agent_MLS_ID.errors }" required>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Office_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Office ID:</label>
                <input type="text" id="Co_Office_MLS_ID" formControlName="Co_Office_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Co_Office_MLS_ID.errors }">
            </div>
    
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Co_Agent_MLS_ID" class= "nonreqtext pr-2 py-2">
              Co-Agent ID:</label>
                <input type="text" id="Co_Agent_MLS_ID" formControlName="Co_Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Co_Agent_MLS_ID.errors }">
            </div>
          </div>
          <!---->
          <div>
            <hr class="HRstyle">
          </div>
          <!---->
          <div class="row pb-2">
            <h6 class="col reqtext">*Property Subtypes (select all that apply):</h6>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0001" class= "nonreqtext pr-2 py-2">Primary:</label>
              <select id="Prop_Subtype_0001" formControlName="Prop_Subtype_0001" class="form-control">
                <option value=""></option>
                <option value="SHOP">Shopping Center</option>
                <option value="OFFC">Office</option>
                <option value="INDS">Industrial</option>
                <option value="RETL">Retail</option>
                <option value="WARE">Warehouse</option>
                <option value="MEDC">Medical/Dental</option>
                <option value="MNFG">Manufacturing</option>
                <option value="SHOW">Showroom/Tech</option>
                <option value="OTHR">Other</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0002" class= "nonreqtext pr-2 py-2">Secondary:</label>
              <select id="Prop_Subtype_0002" formControlName="Prop_Subtype_0002" class="form-control">
                <option value=""></option>
                <option value="SHOP">Shopping Center</option>
                <option value="OFFC">Office</option>
                <option value="INDS">Industrial</option>
                <option value="RETL">Retail</option>
                <option value="WARE">Warehouse</option>
                <option value="MEDC">Medical/Dental</option>
                <option value="MNFG">Manufacturing</option>
                <option value="SHOW">Showroom/Tech</option>
                <option value="OTHR">Other</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="Prop_Subtype_0003" class= "nonreqtext pr-2 py-2">Other:</label>
              <select id="Prop_Subtype_0003" formControlName="Prop_Subtype_0003" class="form-control">
                <option value=""></option>
                <option value="SHOP">Shopping Center</option>
                <option value="OFFC">Office</option>
                <option value="INDS">Industrial</option>
                <option value="RETL">Retail</option>
                <option value="WARE">Warehouse</option>
                <option value="MEDC">Medical/Dental</option>
                <option value="MNFG">Manufacturing</option>
                <option value="SHOW">Showroom/Tech</option>
                <option value="OTHR">Other</option>
              </select>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-4 justify-content-between">
              <label for="Transaction" class= "reqtext pr-2 py-2">*Transaction Type:</label>
              <select id="Transaction" (change)="enableControl(f.Transaction.value == 'L', ['Lease_Price', 'Lease_Type'])" formControlName="Transaction" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Transaction.errors }" required>
                <option value="S" selected="">Sale</option>
                <option value="L">Lease</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="CB_SSLE" class= "reqtext pr-2 py-2">*Short Sale:</label>
              <select id="CB_SSLE" formControlName="SSLE" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.SSLE.errors }" required>
                <option value=""> </option>
                <option value="YESS">Yes</option>
                <option value="NONO">No</option>
              </select>
            </div>
            <div class="form-group col-md-4 justify-content-between">
              <label for="CB_REBK" class= "reqtext pr-2 py-2">*REO/Bank Owned:</label>
              <select id="CB_REBK" formControlName="REBK" class="form-control" [ngClass]="{ 'is-invalid alert-danger': submitted && f.REBK.errors }" required>
                <option value=""> </option>
                <option value="YESS">Yes</option>
                <option value="NONO">No</option>
              </select>
            </div>
          </div>
          <!---->
          <div>
            <hr class="HRstyle">
          </div>
          <!---->
          <ng-container *ngIf="form.get('Transaction').value == 'L'">
            <div class="row form-inline">
              <div class="form-group col-md-6 justify-content-between">
                <label for="Lease_Price" class= "reqtext pr-2 pb-2 ">*Lease (Monthly):</label>
                <input type="text" id="Lease_Price" formControlName="Lease_Price" class="form-control col-md-8" size="10" maxlength="10" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Lease_Price.errors }" required>
              </div>
              <div class="form-group col-md-6 justify-content-between">
                <label for="Lease_Type" class="reqtext pr-2 py-2">*Lease Type:</label>
                <select id="Lease_Type"  formControlName="Lease_Type" class="form-control col-md-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Lease_Type.errors }"  required>
                  <option value="" selected="">  </option>
                  <option value="G"> Gross </option>
                  <option value="N"> Net </option>
                  <option value="O"> Other </option>
                </select>
              </div>
            </div>
            <div class="row form-inline">
              <div class="form-group col-md-6 justify-content-between">
                <label for="Sub_Lease" class="nonreqtext pr-2 py-2">Sub-Lease:</label>
                <select id="Sub_Lease" formControlName="Sub_Lease" class="form-control">
                  <option value="" selected=""> </option>
                  <option value="Y"> Yes </option>
                  <option value="N"> No </option>
                </select>
                <label for="Available_Date" class="nonreqtext pr-2 py-2">Available Date:</label>
                <input type="text" id="Available_Date" formControlName="Available_Date" class="form-control" size="10" maxlength="8" value="">
              </div>
              <div class="form-group col-md-6 justify-content-between">
                <label for="Lease_Expiration" class="nonreqtext pr-2 py-2">Sub-Lease Expiration:</label>
                <input type="text" id="Lease_Expiration" formControlName="Lease_Expiration" class="form-control" size="9" maxlength="8" value="">
              </div>
            </div>
          </ng-container>
          <!---->
          <div>
            <hr class="HRstyle">
          </div>
          <!---->
          <div class="d-flex row form-inline">
            <div class="col-sm-6">
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="CB_APNS" class= "nonreqtext pr-2 py-2">Additional APNs:</label>
                </div>
                <div class="form-group col-sm-4">
                  <select id="CB_APNS" formControlName="APNS" class="form-control">
                    <option value=" "></option>
                    <option class="mBlackText" value="YESS">Yes</option>
                    <option class="mBlackText" value="NONO">No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex form-inline row">
            <!-- Left Column-->
            <div class="col-md-6 align-self-start">
              <!-- One row for an input -->
              <div class="row">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0100APN2" class="nonreqtext pr-2 py-2 col-form-label">APN #2:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0100APN2" formControlName="0100APN2" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
              <!-- One row for an input -->
              <div class="row">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0110APN3" class="nonreqtext pr-2 py-2 col-form-label">APN #3:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0110APN3" formControlName="0110APN3" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <div class="col-md-6 align-self-start">
              <!--Right Column-->
              <!-- One row for an input -->
              <div class="row">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="0120APN4" class="nonreqtext pr-2 py-2 col-form-label">APN #4:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0120APN4" formControlName="0120APN4" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="0130APN5" class="nonreqtext pr-2 py-2 col-form-label">APN #5:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0130APN5" formControlName="0130APN5" class="form-control flex-grow-1" size="" maxlength="20" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!---->
          <div>
            <hr class="HRstyle">
          </div>
          <!---->
          <div class="row form-inline">
            <div class="form-group col-md-5 justify-content-between">
              <label for="Listing_Price_1" class= "reqtext pr-2 py-2">Listing Price:</label>
              <div class="d-flex">
                <input type="text" id="Listing_Price_1" formControlName="Listing_Price_1" class="form-control" size="6" maxlength="6" value="" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Listing_Price_1.errors }" required>
                <label for="Listing_Price_2">&nbsp;,&nbsp;</label>
                <input type="text" id="Listing_Price_2" formControlName="Listing_Price_2" class="form-control" size="3" maxlength="3" value="000">
              </div>
            </div>
          </div>
          <!---->
          <div>
            <hr class="HRstyle">
          </div>
          <!---->
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Agreement_Type_ComboBox" class="reqtext pr-2 py-2">*Agreement Type:</label>
              <select id="Agreement_Type_ComboBox" formControlName="Agreement_Type_ComboBox" class="form-control col-sm-5" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Agreement_Type_ComboBox.errors }" required>
                <option class="mBlackText" value="" selected=""> </option>
                <option class="mBlackText" value="ERTS"> Excl. Right to Sell </option>
                <option class="mBlackText" value="EXAG"> Exclusive Agency </option>
                <option class="mBlackText" value="OPEN"> Open </option>
              </select>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_SCOP" class="nonreqtext pr-2 py-2">Scope of Service:</label>
              <select id="CB_SCOP" formControlName="SCOP" class="form-control col-sm-5">
                <option value=" "> </option>
                <option class="mBlackText" value="ENTY">Entry Only </option>
                <option class="mBlackText" value="LMTD">Limited Service </option>
              </select>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0235SCOP" formControlName="0235SCOP" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="CB_DUAL" class="nonreqtext pr-2 py-2">Dual Agency:</label>
              <select id="CB_DUAL" formControlName="DUAL" class="form-control col-sm-3">
                <option value=""> </option>
                <option class="mBlackText" value="YES">Yes</option>
                <option class="mBlackText" value="NO">No</option>
              </select>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
              <input type="text" id="0240DUAL" formControlName="0240DUAL" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Publish_To_Internet" class="nonreqtext pr-2 py-2">Public Viewable:</label>
              <select id="Publish_To_Internet" formControlName="Publish_To_Internet" class="form-control px-sm-0 px-md-3 col-sm-3">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_AVM_On_VOW" class="reqtext pr-2 py-2">*Show AVM on VOW:</label>
              <select id="Show_AVM_On_VOW" formControlName="Show_AVM_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_AVM_On_VOW.errors }" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Address_to_Public" class="reqtext pr-2 py-2">*Show Addr to Public:</label>
              <select id="Show_Address_to_Public" formControlName="Show_Address_to_Public" class="form-control px-sm-0 px-md-3 col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_Address_to_Public.errors }" required>
                <option value="yes" selected="">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div class="form-group col-sm-6 justify-content-between">
              <label for="Show_Comments_On_VOW" class="reqtext pr-2 py-2">*Show Cmnts on VOW:</label>
              <select id="Show_Comments_On_VOW" formControlName="Show_Comments_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Show_Comments_On_VOW.errors }" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <!---->
          <div>
            <hr class="HRstyle">
          </div>
          <!---->
          <!--Begin Section-->
          <div class="d-flex form-inline row">
            <!--Begin column-->
            <div class="col-lg-5">
              <!--entries-->
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="CB_EOFF" class="reqtext pr-2 py-2 col-form-label">*Email Offers:</label>
                </div>
                <div class="form-group col-sm-4 offset-sm-2 pr-xs-0">
                  <select id="CB_EOFF" (change)="enableControl(f.EOFF.value == 'YESY', ['0390EOAD'])" formControlName="EOFF" class="form-control col-sm-8" [ngClass]="{ 'is-invalid alert-danger': submitted && f.EOFF.errors }" required>
                  <option value=""></option>
                  <option class="mBlackText" value="YESY"> Yes </option>
                  <option class="mBlackText" value="NONO"> No </option>
                  </select>
                </div>
              </div>
            </div>
            <!--Begin column-->
            <div class="col-lg-7">
              <!--entries-->
              <div class="row">
                <div class="form-group col-sm-3 justify-content-end">
                  <label for="0390EOAD" class="reqtext pr-2 py-2 col-form-label">*Email:</label>
                </div>
                <div class="form-group col-sm-9 justify-content-between">
                  <input type="email" id="0390EOAD" formControlName="0390EOAD" class="form-control col-sm-12" [ngClass]="{ 'is-invalid alert-danger': submitted && f['0390EOAD'].errors }" >
                </div>
              </div>
            </div>
          </div>
          <!--end of section-->
          <!---->
          <div>
            <hr class="HRstyle">
          </div>
          <!---->
          <!---->
          <h6 class="subtitle pr-1 py-2">Listing Period:</h6>
          <!---->
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Listing_Date" class="reqtext pr-2 py-2">*Listing Date:</label>
              <input type="text" id="Listing_Date" formControlName="Listing_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Listing_Date.errors }" required>
            </div>
            <div class="form-group col-md-6 justify-content-between">
              <label for="Expiration_Date" class="reqtext pr-2 py-2">*Expiration Date:</label>
              <input type="text" id="Expiration_Date" formControlName="Expiration_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Expiration_Date.errors }">						
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Name" class= "nonreqtext pr-2 py-2">Owner Name:</label>
              <input type="text" id="Owner_Name" formControlName="Owner_Name" class="form-control col-sm-8" size="30" maxlength="50" value="">
            </div>
            <div class="form-group col-md-6 justify-content-between">
              <label for="Owner_Phone_Area" class="nonreqtext pr-2 py-2">Owner Phone #:</label>
              <div class="d-flex">
                <input type="text" id="Owner_Phone_Area" formControlName="Owner_Phone_Area" class="form-control" size="3" maxlength="3" value="">
                <label for="Owner_Phone_Prefix">&nbsp;-&nbsp;</label>
                <input type="text" id="Owner_Phone_Prefix" formControlName="Owner_Phone_Prefix" class="form-control" size="3" maxlength="3" value="">
                <label for="Owner_Phone_Suffix">&nbsp;-&nbsp;</label>
                <input type="text" id="Owner_Phone_Suffix" formControlName="Owner_Phone_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Type" class="reqtext pr-2 py-2">*Occupant Type:</label>
              <select id="Occupant_Type" formControlName="Occupant_Type" class="form-control col-sm-3" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Occupant_Type.errors }" required>
                <option value="" selected="">  </option>
                <option value="C"> Caretaker </option>
                <option value="O"> Owner </option>
                <option value="T"> Tenant </option>
                <option value="V"> Vacant </option>
              </select>
            </div>
          </div>
          <div class="row form-inline">
            <div class="form-group col-md-6 justify-content-between">
              <label for="Occupant_Name" class= "nonreqtext pr-2 py-2">Occupant Name:</label>
              <input type="text" id="Occupant_Name" formControlName="Occupant_Name" class="form-control col-sm-8" size="30" maxlength="30" value="">
            </div>
            <div class="form-group col-md-6 justify-content-between">
              <label for="Phone_to_Show_Area" class="nonreqtext pr-2 py-2">Phone to Show:</label>
              <div class="d-flex">
                <input type="text" id="Phone_to_Show_Area" formControlName="Phone_to_Show_Area" class="form-control" size="3" maxlength="3" value="">
                <label for="Phone_to_Show_Prefix">&nbsp;-&nbsp;</label>
                <input type="text" id="Phone_to_Show_Prefix" formControlName="Phone_to_Show_Prefix" class="form-control" size="3" maxlength="3" value="">
                <label for="Phone_to_Show_Suffix">&nbsp;-&nbsp;</label>
                <input type="text" id="Phone_to_Show_Suffix" formControlName="Phone_to_Show_Suffix" class="form-control" size="4" maxlength="4" value="">
              </div>
            </div>
          </div>
          <br>
          <!---->
          <h6 class="subtitle pr-2 py-2">Property Information</h6>
          <!---->
          <div class="d-flex form-inline row">
            <!-- Left Column-->
            <div class="col-md-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="Bedrooms_ComboBox" class="reqtext pr-2 py-2">*Bedroom(s):</label>
                </div>
                <div class="form-group col-sm-3">
                  <select id="Bedrooms_ComboBox" formControlName="Bedrooms_ComboBox" class="form-control col-sm-10" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Bedrooms_ComboBox.errors }" required>
                    <option value="  0.00" selected> </option>
                    <option value="  1.00">1 </option>
                    <option value="  2.00">2 </option>
                    <option value="  3.00">3 </option>
                    <option value="  4.00">4 </option>
                    <option value="  5.00">5 </option>
                    <option value="  6.00">6 </option>
                    <option value="  7.00">7 </option>
                    <option value="  8.00">8 </option>
                    <option value="  9.00">9 </option>
                    <option value=" 10.00">10 </option>
                    <option value=" 11.00">11 </option>
                    <option value=" 12.00">12 </option>
                    <option value=" 13.00">13 </option>
                    <option value=" 14.00">14 </option>
                    <option value=" 15.00">15 </option>
                    <option value=" 16.00">16 </option>
                    <option value=" 17.00">17 </option>
                    <option value=" 18.00">18 </option>
                    <option value=" 19.00">19 </option>
                    <option value=" 20.00">20 </option>
                    <option value=" 21.00">21 </option>
                    <option value=" 22.00">22 </option>
                    <option value=" 23.00">23 </option>
                    <option value=" 24.00">24 </option>
                    <option value=" 25.00">25 </option>
                    <option value=" 26.00">26 </option>
                    <option value=" 27.00">27 </option>
                    <option value=" 28.00">28 </option>
                    <option value=" 29.00">29 </option>
                    <option value=" 30.00">30 </option>
                    <option value=" 31.00">31 </option>
                    <option value=" 32.00">32 </option>
                    <option value=" 33.00">33 </option>
                    <option value=" 34.00">34 </option>
                    <option value=" 35.00">35 </option>
                    <option value=" 36.00">36 </option>
                    <option value=" 37.00">37 </option>
                    <option value=" 38.00">38 </option>
                    <option value=" 39.00">39 </option>
                    <option value=" 40.00">40 </option>
                    <option value=" 41.00">41 </option>
                    <option value=" 42.00">42 </option>
                    <option value=" 43.00">43 </option>
                    <option value=" 44.00">44 </option>
                    <option value=" 45.00">45 </option>
                    <option value=" 46.00">46 </option>
                    <option value=" 47.00">47 </option>
                    <option value=" 48.00">48 </option>
                    <option value=" 49.00">49 </option>
                    <option value=" 50.00">50 </option>
                    <option value=" 51.00">51 </option>
                    <option value=" 52.00">52 </option>
                    <option value=" 53.00">53 </option>
                    <option value=" 54.00">54 </option>
                    <option value=" 55.00">55 </option>
                    <option value=" 56.00">56 </option>
                    <option value=" 57.00">57 </option>
                    <option value=" 58.00">58 </option>
                    <option value=" 59.00">59 </option>
                    <option value=" 60.00">60 </option>
                    <option value=" 61.00">61 </option>
                    <option value=" 62.00">62 </option>
                    <option value=" 63.00">63 </option>
                    <option value=" 64.00">64 </option>
                    <option value=" 65.00">65 </option>
                    <option value=" 66.00">66 </option>
                    <option value=" 67.00">67 </option>
                    <option value=" 68.00">68 </option>
                    <option value=" 69.00">69 </option>
                    <option value=" 70.00">70 </option>
                    <option value=" 71.00">71 </option>
                    <option value=" 72.00">72 </option>
                    <option value=" 73.00">73 </option>
                    <option value=" 74.00">74 </option>
                    <option value=" 75.00">75 </option>
                    <option value=" 76.00">76 </option>
                    <option value=" 77.00">77 </option>
                    <option value=" 78.00">78 </option>
                    <option value=" 79.00">79 </option>
                    <option value=" 80.00">80 </option>
                    <option value=" 81.00">81 </option>
                    <option value=" 82.00">82 </option>
                    <option value=" 83.00">83 </option>
                    <option value=" 84.00">84 </option>
                    <option value=" 85.00">85 </option>
                    <option value=" 86.00">86 </option>
                    <option value=" 87.00">87 </option>
                    <option value=" 88.00">88 </option>
                    <option value=" 89.00">89 </option>
                    <option value=" 90.00">90 </option>
                  </select>
                </div>
              </div>
            </div>
            <!--end of left column-->
            <!--Right Column-->
            <div class="col-md-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="Bathrooms_ComboBox" class="reqtext pr-2 py-2">*Bathrooms:</label>
                </div>
                <div class="form-group col-sm-3">
                  <select id="Bathrooms_ComboBox" formControlName="Bathrooms_ComboBox" class="form-control col-sm-10" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Bathrooms_ComboBox.errors }" required>
                    <option value="  0.00" selected></option>
                    <option value="  1.00"> 1 </option>
                    <option value="  1.50"> 1 1/2 </option>
                    <option value="  1.75"> 1 3/4 </option>
                    <option value="  2.00"> 2 </option>
                    <option value="  2.50"> 2 1/2 </option>
                    <option value="  2.75"> 2 3/4 </option>
                    <option value="  3.00"> 3 </option>
                    <option value="  3.50"> 3 1/2 </option>
                    <option value="  3.75"> 3 3/4 </option>
                    <option value="  4.00"> 4 </option>
                    <option value="  4.50"> 4 1/2 </option>
                    <option value="  4.75"> 4 3/4 </option>
                    <option value="  5.00"> 5 </option>
                    <option value="  6.00"> 6 </option>
                    <option value="  7.00"> 7 </option>
                    <option value="  8.00"> 8 </option>
                    <option value="  9.00"> 9 </option>
                    <option value=" 10.00"> 10 </option>
                    <option value=" 11.00"> 11 </option>
                    <option value=" 12.00"> 12 </option>
                    <option value=" 13.00"> 13 </option>
                    <option value=" 14.00"> 14 </option>
                    <option value=" 15.00"> 15 </option>
                    <option value=" 16.00"> 16 </option>
                    <option value=" 17.00"> 17 </option>
                    <option value=" 18.00"> 18 </option>
                    <option value=" 19.00"> 19 </option>
                    <option value=" 20.00"> 20 </option>
                    <option value=" 21.00"> 21 </option>
                    <option value=" 22.00"> 22 </option>
                    <option value=" 23.00"> 23 </option>
                    <option value=" 24.00"> 24 </option>
                    <option value=" 25.00"> 25 </option>
                    <option value=" 26.00"> 26 </option>
                    <option value=" 27.00"> 27 </option>
                    <option value=" 28.00"> 28 </option>
                    <option value=" 29.00"> 29 </option>
                    <option value=" 30.00"> 30 </option>
                    <option value=" 31.00"> 31 </option>
                    <option value=" 32.00"> 32 </option>
                    <option value=" 33.00"> 33 </option>
                    <option value=" 34.00"> 34 </option>
                    <option value=" 35.00"> 35 </option>
                    <option value=" 36.00"> 36 </option>
                    <option value=" 37.00"> 37 </option>
                    <option value=" 38.00"> 38 </option>
                    <option value=" 39.00"> 39 </option>
                    <option value=" 40.00"> 40 </option>
                    <option value=" 41.00"> 41 </option>
                    <option value=" 42.00"> 42 </option>
                    <option value=" 43.00"> 43 </option>
                    <option value=" 44.00"> 44 </option>
                    <option value=" 45.00"> 45 </option>
                    <option value=" 46.00"> 46 </option>
                    <option value=" 47.00"> 47 </option>
                    <option value=" 48.00"> 48 </option>
                    <option value=" 49.00"> 49 </option>
                    <option value=" 50.00"> 50 </option>
                    <option value=" 51.00"> 51 </option>
                    <option value=" 52.00"> 52 </option>
                    <option value=" 53.00"> 53 </option>
                    <option value=" 54.00"> 54 </option>
                    <option value=" 55.00"> 55 </option>
                    <option value=" 56.00"> 56 </option>
                    <option value=" 57.00"> 57 </option>
                    <option value=" 58.00"> 58 </option>
                    <option value=" 59.00"> 59 </option>
                    <option value=" 60.00"> 60 </option>
                    <option value=" 61.00"> 61 </option>
                    <option value=" 62.00"> 62 </option>
                    <option value=" 63.00"> 63 </option>
                    <option value=" 64.00"> 64 </option>
                    <option value=" 65.00"> 65 </option>
                    <option value=" 66.00"> 66 </option>
                    <option value=" 67.00"> 67 </option>
                    <option value=" 68.00"> 68 </option>
                    <option value=" 69.00"> 69 </option>
                    <option value=" 70.00"> 70 </option>
                    <option value=" 71.00"> 71 </option>
                    <option value=" 72.00"> 72 </option>
                    <option value=" 73.00"> 73 </option>
                    <option value=" 74.00"> 74 </option>
                    <option value=" 75.00"> 75 </option>
                    <option value=" 76.00"> 76 </option>
                    <option value=" 77.00"> 77 </option>
                    <option value=" 78.00"> 78 </option>
                    <option value=" 79.00"> 79 </option>
                    <option value=" 80.00"> 80 </option>
                    <option value=" 81.00"> 81 </option>
                    <option value=" 82.00"> 82 </option>
                    <option value=" 83.00"> 83 </option>
                    <option value=" 84.00"> 84 </option>
                    <option value=" 85.00"> 85 </option>
                    <option value=" 86.00"> 86 </option>
                    <option value=" 87.00"> 87 </option>
                    <option value=" 88.00"> 88 </option>
                    <option value=" 89.00"> 89 </option>
                    <option value=" 90.00"> 90 </option>
                  </select>
                </div>
              </div>
            </div>
            <!--end of right column-->
          </div>
          <!--end of page/section-->
          <div class="d-flex form-inline row">
            <!-- Left Column-->
            <div class="col-md-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="Struc_Sq_Ft" class= "reqtext pr-2 py-2">*Aprx Sq Ft </label>
                </div>
                <div class="form-group col-sm-4">
                  <input type="text" id="Struc_Sq_Ft" formControlName="Struc_Sq_Ft" class="form-control col-sm-10" size="9" maxlength="9" placeholder="0" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Struc_Sq_Ft.errors }" required>
                </div>
              </div>
            </div>
            <!--end of left column-->
            <!--Right Column-->
            <div class="col-md-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="selSqFtSource" class="reqtext pr-2 py-2">*Sq Ft Source:</label>
                </div>
                <div class="form-group col-sm-4">
                  <select id="selSqFtSource" formControlName="SqFt_Source" class="form-control col-sm-10" [ngClass]="{ 'is-invalid alert-danger': submitted && f.SqFt_Source.errors }" required>
                    <option value="" selected="">  </option>
                    <option value="SLLR"> Seller </option>
                    <option value="APPR"> Appraiser </option>
                    <option value="TAXR"> Tax Records </option>
                    <option value="OTHR"> Other </option>
                  </select>
                </div>
              </div>
            </div>
            <!--end of right column-->
          </div>
          <!--end of page/section-->
          <div class="d-flex form-inline row">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="Lot_Sq_Ft" class= "nonreqtext pr-2 py-2">Lot Size:</label>
                </div>
                <div class="form-group col-sm-4">
                  <input type="text" id="Lot_Sq_Ft" formControlName="Lot_Sq_Ft" class="form-control col-sm-10" size="12" maxlength="9" value="">
                </div>
                <div class="d-flex form-group col-sm-4">
                  <label for="Lot_Measurement" class="nonreqtext px-1">
                  <input type="radio" id="Lot_Measurement" formControlName="Lot_Measurement" class="form-control" value="S" checked="">&nbsp;SqFt
                  </label>
                  <label for="Lot_Measurement_Acres" class="nonreqtext px-1">
                  <input type="radio" id="Lot_Measurement_Acres" formControlName="Lot_Measurement" class="form-control" value="A">&nbsp;Acres
                  </label>
                </div>
              </div>
            </div>
            <!--end of left column-->
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label for="selLotSizeSource" class="nonreqtext pr-2 py-2">Lot Size Source:</label>
                </div>
                <div class="form-group col-sm-4">
                  <select id="selLotSizeSource" formControlName="LotSize_Source" class="form-control col-sm-10">
                    <option value="" selected="">  </option>
                    <option value="APPR"> Appraiser </option>
                    <option value="OTHR"> Other </option>
                    <option value="SLLR"> Seller </option>
                    <option value="TAXR"> Tax Records </option>
                  </select>
                </div>
              </div>
            </div>
            <!--end of right column-->
          </div>
          <!--end of page/section-->
          <div class="d-flex form-inline row">
            <!-- Left Column-->
            <div class="col-md-4 align-self-start">
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="Year_Built" class= "reqtext pr-2 py-2">*Year Built:</label>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="Year_Built" formControlName="Year_Built" class="form-control col-sm-10" size="8" maxlength="4" value="" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Year_Built.errors }" required>
                </div>
              </div>
            </div>
            <!--end of left column-->
            <!--Right Column-->
            <div class="col-md-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-5">
                  <label for="Unknown_Year" class="nonreqtext p-2">- or (select one) -</label>
                </div>
                <div class="form-group col-sm-6">
                  <select id="Unknown_Year" formControlName="Unknown_Year" class="form-control">
                    <option value="0" selected="">  </option>
                    <option value="1"> New Construction </option>
                    <option value="2"> Under Construction </option>
                    <option value="3"> Unknown </option>
                  </select>
                </div>
              </div>
            </div>
            <!--end of right column-->
          </div>
          <!--end of page/section-->
          <br>
          <div class="row">
            <div class="col">
              <h6><label for="Property_Description_contnet"><span class="reqsubtitle pr-1">*Marketing Remarks:</span></label><span class="reqtext formnote">&nbsp;(Maximum 1000)</span></h6>
              <textarea id="Property_Description" formControlName="Property_Description" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Property_Description.errors }" required></textarea>
              <span class="reqtext formnote">&nbsp;{{ (1000 - f.Property_Description.value.length) }} characters left</span>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col">
              <h6><label for="Agent_Only_Remarks"><span class="subtitle pr-1">Agent Remarks:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 1000)</span></h6>
              <textarea id="Agent_Only_Remarks" formControlName="Agent_Only_Remarks" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"></textarea>
              <span class="reqtext formnote">&nbsp;{{ (1000 - f.Agent_Only_Remarks.value.length) }} characters left</span>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col">
              <h6><label for="Directions"><span class="reqsubtitle pr-1">*Directions to Property:</span></label><span class="reqtext formnote">&nbsp;(Maximum 250)</span></h6>
              <textarea id="Directions" formControlName="Directions" class="form-control" spellcheck="true" rows="4" cols=""  maxlength="250" [ngClass]="{ 'is-invalid alert-danger': submitted && f.Directions.errors }" required></textarea>
              <span class="reqtext formnote">&nbsp;{{ (250 - f.Directions.value.length) }} characters left</span>
            </div>
          </div>
          <br>
        </div>
      </aw-wizard-step>
      <aw-wizard-step  
        stepId="Property Features"
        stepTitle="Property Features" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">2</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Property Features Form">
          <div formGroupName="POTENTIALUSE" required>
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.POTENTIALUSE.errors }"><span class="pr-2 py-2 reqtext">*Potential Use:&nbsp;<span class="reqtext formnote">(Up to 32 entries allowed)</span></span></span>
                <!-- One row for a new input group -->
                <div class="row fixcheck">
                  <!-- Within that row, specify column length  -->
                  <div class="form-group col-sm-6">
                    <label for="POTNAUTO" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNAUTO" formControlName="POTNAUTO" class="form-check-input">Automotive</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNAUTO" formControlName="Checkbox_Comment_POTNAUTO" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNBANK" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNBANK" formControlName="POTNBANK" class="form-check-input">Bank</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNBANK" formControlName="Checkbox_Comment_POTNBANK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNBARB" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNBARB" formControlName="POTNBARB" class="form-check-input">Barber/Beauty Shop</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNBARB" formControlName="Checkbox_Comment_POTNBARB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNBOWL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNBOWL" formControlName="POTNBOWL" class="form-check-input">Bowling Alley</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNBOWL" formControlName="Checkbox_Comment_POTNBOWL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNCHUR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNCHUR" formControlName="POTNCHUR" class="form-check-input">Church</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNCHUR" formControlName="Checkbox_Comment_POTNCHUR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNMRKT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNMRKT" formControlName="POTNMRKT" class="form-check-input">Convenience Market</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNMRKT" formControlName="Checkbox_Comment_POTNMRKT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNDCAR" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNDCAR" formControlName="POTNDCAR" class="form-check-input">Day Care</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNDCAR" formControlName="Checkbox_Comment_POTNDCAR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNDEPT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNDEPT" formControlName="POTNDEPT" class="form-check-input">Department Store</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNDEPT" formControlName="Checkbox_Comment_POTNDEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNDISC" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNDISC" formControlName="POTNDISC" class="form-check-input">Disc. Store</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNDISC" formControlName="Checkbox_Comment_POTNDISC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNGNRL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNGNRL" formControlName="POTNGNRL" class="form-check-input">General Office</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNGNRL" formControlName="Checkbox_Comment_POTNGNRL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNGOVT" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNGOVT" formControlName="POTNGOVT" class="form-check-input">Government</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNGOVT" formControlName="Checkbox_Comment_POTNGOVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNHLTH" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNHLTH" formControlName="POTNHLTH" class="form-check-input">Health Club</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNHLTH" formControlName="Checkbox_Comment_POTNHLTH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNHOSP" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNHOSP" formControlName="POTNHOSP" class="form-check-input">Hospital</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNHOSP" formControlName="Checkbox_Comment_POTNHOSP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNHOTL" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNHOTL" formControlName="POTNHOTL" class="form-check-input">Hotel</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNHOTL" formControlName="Checkbox_Comment_POTNHOTL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNENGN" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNENGN" formControlName="POTNENGN" class="form-check-input">Industrial Engineer</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNENGN" formControlName="Checkbox_Comment_POTNENGN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--next row-->
                <div class="row fixcheck">
                  <div class="form-group col-sm-6">
                    <label for="POTNMNFG" class="form-check-label nonreqtext p-2">
                    <input type="checkbox" id="POTNMNFG" formControlName="POTNMNFG" class="form-check-input">Ind. Manufacture</label>
                  </div>
                  <div class="form-group col-sm-6 justify-content-end">
                    <input type="text" id="Checkbox_Comment_POTNMNFG" formControlName="Checkbox_Comment_POTNMNFG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                  </div>
                </div>
                <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <div class="p-2 hidebreak"><br></div>
            </div>
            </div> 
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNLAUN" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNLAUN" formControlName="POTNLAUN" class="form-check-input">Laundromat</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNLAUN" formControlName="Checkbox_Comment_POTNLAUN" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNMATS" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNMATS" formControlName="POTNMATS" class="form-check-input">Material Storage</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNMATS" formControlName="Checkbox_Comment_POTNMATS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNMDCL" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNMDCL" formControlName="POTNMDCL" class="form-check-input">Medical Office</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNMDCL" formControlName="Checkbox_Comment_POTNMDCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNMOTL" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNMOTL" formControlName="POTNMOTL" class="form-check-input">Motel</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNMOTL" formControlName="Checkbox_Comment_POTNMOTL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNPRIV" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNPRIV" formControlName="POTNPRIV" class="form-check-input">Private Club</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNPRIV" formControlName="Checkbox_Comment_POTNPRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNREST" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNREST" formControlName="POTNREST" class="form-check-input">Restaurant</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNREST" formControlName="Checkbox_Comment_POTNREST" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNFAST" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNFAST" formControlName="POTNFAST" class="form-check-input">Restaurant-Fast Food</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNFAST" formControlName="Checkbox_Comment_POTNFAST" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNRETL" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNRETL" formControlName="POTNRETL" class="form-check-input">Retail Store</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNRETL" formControlName="Checkbox_Comment_POTNRETL" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNSERV" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNSERV" formControlName="POTNSERV" class="form-check-input">Service Station</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNSERV" formControlName="Checkbox_Comment_POTNSERV" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNSHPN" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNSHPN" formControlName="POTNSHPN" class="form-check-input">Shop - Neighborhood</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNSHPN" formControlName="Checkbox_Comment_POTNSHPN" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNSHPC" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNSHPC" formControlName="POTNSHPC" class="form-check-input">Shop - Commercial</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNSHPC" formControlName="Checkbox_Comment_POTNSHPC" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNSHPR" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNSHPR" formControlName="POTNSHPR" class="form-check-input">Shop - Regional</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNSHPR" formControlName="Checkbox_Comment_POTNSHPR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNTHTR" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNTHTR" formControlName="POTNTHTR" class="form-check-input">Theater</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNTHTR" formControlName="Checkbox_Comment_POTNTHTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNWARD" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNWARD" formControlName="POTNWARD" class="form-check-input">Warehouse/Dist</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNWARD" formControlName="Checkbox_Comment_POTNWARD" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNWARS" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNWARS" formControlName="POTNWARS" class="form-check-input">Warehouse/Storage</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNWARS" formControlName="Checkbox_Comment_POTNWARS" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--next row-->
            <div class="row fixcheck">
            <div class="form-group col-sm-6">
            <label for="POTNRMRK" class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="POTNRMRK" formControlName="POTNRMRK" class="form-check-input">See Remarks</label>
            </div>
            <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="Checkbox_Comment_POTNRMRK" formControlName="Checkbox_Comment_POTNRMRK" class="form-control flex-grow-1" size="" maxlength="40" value="">
            </div>
            </div>
            <!--End of right column-->
            </div>	
          </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_OFSQ" class= "nonreqtext pr-2 py-2 col-form-label">Office Sq Ft:</label>
                  <input type="text" id="NUM_OFSQ" formControlName="OFSQ" class="form-control col-sm-5" size="" maxlength="6">
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0590OFSQ" formControlName="0590OFSQ" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_CNSS" class= "nonreqtext pr-2 py-2 col-form-label">Census Tract:</label>
                  <input type="text" id="NUM_CNSS" formControlName="CNSS" class="form-control col-sm-5" size="" maxlength="6">
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0600CNSS" formControlName="0600CNSS" class="form-control flex-grow-1" size="" maxlength="40" placeholder="">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_AREA" class="nonreqtext pr-2 py-2 col-form-label">Region:</label>
                  <select id="CB_AREA" formControlName="AREA" class="form-control col-sm-5">
                    <option value=""></option>
                    <option class="mBlackText" value="URBN">Urban </option>
                    <option class="mBlackText" value="SUBU">Suburban </option>
                    <option class="mBlackText" value="FTHL">Foothill </option>
                    <option class="mBlackText" value="MNTN">Mountain </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0620AREA" formControlName="0620AREA" class="form-control flex-grow-1" size="" maxlength="40" placeholder="">
                </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="Area_Fill_In" class="reqtext pr-2 py-2 col-form-label">*Area:</label>
                  <input type="text" id="Area_Fill_In" formControlName="Area_Fill_In" class="form-control col-sm-5" size="" maxlength="60"  required [ngClass]="{ 'is-invalid alert-danger': submitted && f.Area_Fill_In.errors }">
                </div>
              </div>
              <div class="row form-group col-sm-12">
                <span class="reqtext pr-2 py-2 formnote">AREA refers to the last 3 digits of the property zipcode. Example: 93711. AREA = 711.</span>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Stories:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="STORONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORONE" formControlName="STORONE" class="form-check-input">One</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORONE" formControlName="Checkbox_Comment_STORONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="STORTWO" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORTWO" formControlName="STORTWO" class="form-check-input">Two</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORTWO" formControlName="Checkbox_Comment_STORTWO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="STORTHRE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORTHRE" formControlName="STORTHRE" class="form-check-input">Three Or More</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORTHRE" formControlName="Checkbox_Comment_STORTHRE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="STORSLIT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORSLIT" formControlName="STORSLIT" class="form-check-input">Multi/Split</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORSLIT" formControlName="Checkbox_Comment_STORSLIT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="STORBASE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="STORBASE" formControlName="STORBASE" class="form-check-input">Basement</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_STORBASE" formControlName="Checkbox_Comment_STORBASE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Location:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="LCTNCRNR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNCRNR" formControlName="LCTNCRNR" class="form-check-input">Corner</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNCRNR" formControlName="Checkbox_Comment_LCTNCRNR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNINTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNINTR" formControlName="LCTNINTR" class="form-check-input">Interior</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNINTR" formControlName="Checkbox_Comment_LCTNINTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNCULD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNCULD" formControlName="LCTNCULD" class="form-check-input">Cul De Sac</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNCULD" formControlName="Checkbox_Comment_LCTNCULD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNINDU" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNINDU" formControlName="LCTNINDU" class="form-check-input">Industrial Bus. Park</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNINDU" formControlName="Checkbox_Comment_LCTNINDU" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNMALL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNMALL" formControlName="LCTNMALL" class="form-check-input">Mall/Strip Mall</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNMALL" formControlName="Checkbox_Comment_LCTNMALL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNMAJR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNMAJR" formControlName="LCTNMAJR" class="form-check-input">Major Arteries</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNMAJR" formControlName="Checkbox_Comment_LCTNMAJR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LCTNFRNG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LCTNFRNG" formControlName="LCTNFRNG" class="form-check-input">Frontage</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LCTNFRNG" formControlName="Checkbox_Comment_LCTNFRNG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="0670ZONE" class="nonreqtext pr-2 py-2 col-form-label">Zoning:</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0670ZONE" formControlName="0670ZONE" class="form-control flex-grow-1" size="" maxlength="40">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div formGroupName="PARKING" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.PARKING.errors }"><span class="pr-2 py-2 reqtext">*Additional Parking:&nbsp;<span class="reqtext formnote">(Up to 9 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="PARK1TO5" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARK1TO5" formControlName="PARK1TO5" class="form-check-input">1 - 5 Spaces</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARK1TO5" formControlName="Checkbox_Comment_PARK1TO5" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARK6T10" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARK6T10" formControlName="PARK6T10" class="form-check-input">6 - 10 Spaces</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARK6T10" formControlName="Checkbox_Comment_PARK6T10" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARK1020" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARK1020" formControlName="PARK1020" class="form-check-input">10 - 20 Spaces</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARK1020" formControlName="Checkbox_Comment_PARK1020" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARKOV21" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARKOV21" formControlName="PARKOV21" class="form-check-input">Over 21 Spaces</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARKOV21" formControlName="Checkbox_Comment_PARKOV21" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARKASSN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARKASSN" formControlName="PARKASSN" class="form-check-input">Assigned</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARKASSN" formControlName="Checkbox_Comment_PARKASSN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARKCVRD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARKCVRD" formControlName="PARKCVRD" class="form-check-input">Covered</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARKCVRD" formControlName="Checkbox_Comment_PARKCVRD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARKSTRT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARKSTRT" formControlName="PARKSTRT" class="form-check-input">Street</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARKSTRT" formControlName="Checkbox_Comment_PARKSTRT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARKPBLC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARKPBLC" formControlName="PARKPBLC" class="form-check-input">Public Garage</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARKPBLC" formControlName="Checkbox_Comment_PARKPBLC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PARKUNPV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PARKUNPV" formControlName="PARKUNPV" class="form-check-input">Unpaved</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PARKUNPV" formControlName="Checkbox_Comment_PARKUNPV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div formGroupName="ACCESS" required>
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.ACCESS.errors }"><span class="pr-2 py-2 reqtext">*Access:&nbsp;<span class="reqtext formnote">(Up to 10 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="ACCSINTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSINTR" formControlName="ACCSINTR" class="form-check-input">Interstate</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSINTR" formControlName="Checkbox_Comment_ACCSINTR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSFRWY" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSFRWY" formControlName="ACCSFRWY" class="form-check-input">Freeway</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSFRWY" formControlName="Checkbox_Comment_ACCSFRWY" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSSTAT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSSTAT" formControlName="ACCSSTAT" class="form-check-input">State Highway</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSSTAT" formControlName="Checkbox_Comment_ACCSSTAT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSMAJR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSMAJR" formControlName="ACCSMAJR" class="form-check-input">Major Highway</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSMAJR" formControlName="Checkbox_Comment_ACCSMAJR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSCITY" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSCITY" formControlName="ACCSCITY" class="form-check-input">City Street</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSCITY" formControlName="Checkbox_Comment_ACCSCITY" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSPRIV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSPRIV" formControlName="ACCSPRIV" class="form-check-input">Private Road</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSPRIV" formControlName="Checkbox_Comment_ACCSPRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSPAVE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSPAVE" formControlName="ACCSPAVE" class="form-check-input">Paved Road</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSPAVE" formControlName="Checkbox_Comment_ACCSPAVE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSUNIM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSUNIM" formControlName="ACCSUNIM" class="form-check-input">Unimproved</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSUNIM" formControlName="Checkbox_Comment_ACCSUNIM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <div class="p-2 hidebreak"><br></div>
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSEASE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSEASE" formControlName="ACCSEASE" class="form-check-input">Road Easement</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSEASE" formControlName="Checkbox_Comment_ACCSEASE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSCNTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSCNTR" formControlName="ACCSCNTR" class="form-check-input">Country Road</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSCNTR" formControlName="Checkbox_Comment_ACCSCNTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSGRVL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSGRVL" formControlName="ACCSGRVL" class="form-check-input">Gravel Road</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSGRVL" formControlName="Checkbox_Comment_ACCSGRVL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSAIR1" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSAIR1" formControlName="ACCSAIR1" class="form-check-input">Airport 1 Mile</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSAIR1" formControlName="Checkbox_Comment_ACCSAIR1" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSAIR2" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSAIR2" formControlName="ACCSAIR2" class="form-check-input">Airport 2 - 3 Miles</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSAIR2" formControlName="Checkbox_Comment_ACCSAIR2" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSAIR4" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSAIR4" formControlName="ACCSAIR4" class="form-check-input">Airport 4 - 5 Miles</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSAIR4" formControlName="Checkbox_Comment_ACCSAIR4" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ACCSAIR6" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ACCSAIR6" formControlName="ACCSAIR6" class="form-check-input">Airport 6+ Miles</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ACCSAIR6" formControlName="Checkbox_Comment_ACCSAIR6" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_BLDG" class= "nonreqtext pr-2 py-2 col-form-label">Total Buildings:</label>
                  <input type="text" id="NUM_BLDG" formControlName="BLDG" class="form-control col-sm-5" size="" maxlength="3">
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0690BLDG" formControlName="0690BLDG" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="NUM_RSTR" class= "nonreqtext pr-2 py-2 col-form-label">Restrooms:</label>
                  <input type="text" id="NUM_RSTR" formControlName="RSTR" class="form-control col-sm-5" size="" maxlength="2">
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="0700RSTR" formControlName="0700RSTR" class="form-control flex-grow-1" size="" maxlength="40" placeholder="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Miscellaneous:&nbsp;<span class="non	reqtext formnote">(Up to 13 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="MISCSECL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCSECL" formControlName="MISCSECL" class="form-check-input">Security Lighting</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCSECL" formControlName="Checkbox_Comment_MISCSECL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCISTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCISTR" formControlName="MISCISTR" class="form-check-input">Inside Storage</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCISTR" formControlName="Checkbox_Comment_MISCISTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCOSTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCOSTR" formControlName="MISCOSTR" class="form-check-input">Outside Storage</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCOSTR" formControlName="Checkbox_Comment_MISCOSTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCOPEN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCOPEN" formControlName="MISCOPEN" class="form-check-input">Open Span</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCOPEN" formControlName="Checkbox_Comment_MISCOPEN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCFIRE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCFIRE" formControlName="MISCFIRE" class="form-check-input">Fire Walls</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCFIRE" formControlName="Checkbox_Comment_MISCFIRE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCRAIL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCRAIL" formControlName="MISCRAIL" class="form-check-input">Railroad Spur</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCRAIL" formControlName="Checkbox_Comment_MISCRAIL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCRACC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCRACC" formControlName="MISCRACC" class="form-check-input">Railroad Access</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCRACC" formControlName="Checkbox_Comment_MISCRACC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCWETS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCWETS" formControlName="MISCWETS" class="form-check-input">Wet Sprinklers</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCWETS" formControlName="Checkbox_Comment_MISCWETS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCDEKG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCDEKG" formControlName="MISCDEKG" class="form-check-input">Deck Grade</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCDEKG" formControlName="Checkbox_Comment_MISCDEKG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCDEKT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCDEKT" formControlName="MISCDEKT" class="form-check-input">Deck Truck</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCDEKT" formControlName="Checkbox_Comment_MISCDEKT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <div class="p-2 hidebreak"><br></div>
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCFRET" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCFRET" formControlName="MISCFRET" class="form-check-input">Freight Elevator</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCFRET" formControlName="Checkbox_Comment_MISCFRET" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCCNVY" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCCNVY" formControlName="MISCCNVY" class="form-check-input">Conveyor</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCCNVY" formControlName="Checkbox_Comment_MISCCNVY" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCSCAL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCSCAL" formControlName="MISCSCAL" class="form-check-input">Truck Scale</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCSCAL" formControlName="Checkbox_Comment_MISCSCAL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCDISP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCDISP" formControlName="MISCDISP" class="form-check-input">Display Window</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCDISP" formControlName="Checkbox_Comment_MISCDISP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCTIXT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCTIXT" formControlName="MISCTIXT" class="form-check-input">Fixtures Furnished</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCTIXT" formControlName="Checkbox_Comment_MISCTIXT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCBLDG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCBLDG" formControlName="MISCBLDG" class="form-check-input">Additional Buildings</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCBLDG" formControlName="Checkbox_Comment_MISCBLDG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCHAND" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCHAND" formControlName="MISCHAND" class="form-check-input">Handicap Facilities</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCHAND" formControlName="Checkbox_Comment_MISCHAND" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCLICS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCLICS" formControlName="MISCLICS" class="form-check-input">License</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCLICS" formControlName="Checkbox_Comment_MISCLICS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCLITE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCLITE" formControlName="MISCLITE" class="form-check-input">Security Lighting</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCLITE" formControlName="Checkbox_Comment_MISCLITE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="MISCSIGN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="MISCSIGN" formControlName="MISCSIGN" class="form-check-input">Signage</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_MISCSIGN" formControlName="Checkbox_Comment_MISCSIGN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div formGroupName="ROOF" required>
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.ROOF.errors }"><span class="pr-2 py-2 reqtext">*Roof:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="ROOFCOMP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFCOMP" formControlName="ROOFCOMP" class="form-check-input">Composition</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFCOMP" formControlName="Checkbox_Comment_ROOFCOMP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFCONC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFCONC" formControlName="ROOFCONC" class="form-check-input">Concrete</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFCONC" formControlName="Checkbox_Comment_ROOFCONC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFMETL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFMETL" formControlName="ROOFMETL" class="form-check-input">Metal</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFMETL" formControlName="Checkbox_Comment_ROOFMETL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFTARG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFTARG" formControlName="ROOFTARG" class="form-check-input">Tar/Gravel</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFTARG" formControlName="Checkbox_Comment_ROOFTARG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFFLAT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFFLAT" formControlName="ROOFFLAT" class="form-check-input">Flat</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFFLAT" formControlName="Checkbox_Comment_ROOFFLAT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFTILE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFTILE" formControlName="ROOFTILE" class="form-check-input">Tile</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFTILE" formControlName="Checkbox_Comment_ROOFTILE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFWOOD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFWOOD" formControlName="ROOFWOOD" class="form-check-input">Wood</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFWOOD" formControlName="Checkbox_Comment_ROOFWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFOTHR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFOTHR" formControlName="ROOFOTHR" class="form-check-input">Other</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFOTHR" formControlName="Checkbox_Comment_ROOFOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFSTLJ" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFSTLJ" formControlName="ROOFSTLJ" class="form-check-input">Steel Joists</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFSTLJ" formControlName="Checkbox_Comment_ROOFSTLJ" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <div class="p-2 hidebreak"><br></div>
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFWODJ" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFWODJ" formControlName="ROOFWODJ" class="form-check-input">Wood Joists</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFWODJ" formControlName="Checkbox_Comment_ROOFWODJ" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFSTLT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFSTLT" formControlName="ROOFSTLT" class="form-check-input">Steel Trusses</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFSTLT" formControlName="Checkbox_Comment_ROOFSTLT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFBILT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFBILT" formControlName="ROOFBILT" class="form-check-input">Built Up</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFBILT" formControlName="Checkbox_Comment_ROOFBILT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFCDEK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFCDEK" formControlName="ROOFCDEK" class="form-check-input">Concrete Deck</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFCDEK" formControlName="Checkbox_Comment_ROOFCDEK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFALUM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFALUM" formControlName="ROOFALUM" class="form-check-input">Aluminum</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFALUM" formControlName="Checkbox_Comment_ROOFALUM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFASBS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFASBS" formControlName="ROOFASBS" class="form-check-input">Asbestos Shingle</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFASBS" formControlName="Checkbox_Comment_ROOFASBS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFCORR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFCORR" formControlName="ROOFCORR" class="form-check-input">Corrugated Steel</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFCORR" formControlName="Checkbox_Comment_ROOFCORR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFSHNG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFSHNG" formControlName="ROOFSHNG" class="form-check-input">Shingle</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFSHNG" formControlName="Checkbox_Comment_ROOFSHNG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ROOFINSL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ROOFINSL" formControlName="ROOFINSL" class="form-check-input">Insulated</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ROOFINSL" formControlName="Checkbox_Comment_ROOFINSL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Exterior:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="EXTRMETL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRMETL" formControlName="EXTRMETL" class="form-check-input">Metal</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRMETL" formControlName="Checkbox_Comment_EXTRMETL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRBLCK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRBLCK" formControlName="EXTRBLCK" class="form-check-input">Concrete Block</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRBLCK" formControlName="Checkbox_Comment_EXTRBLCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRWALL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRWALL" formControlName="EXTRWALL" class="form-check-input">Tilt Wall</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRWALL" formControlName="Checkbox_Comment_EXTRWALL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRBRCK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRBRCK" formControlName="EXTRBRCK" class="form-check-input">Brick/Block</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRBRCK" formControlName="Checkbox_Comment_EXTRBRCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRROCK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRROCK" formControlName="EXTRROCK" class="form-check-input">Rock</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRROCK" formControlName="Checkbox_Comment_EXTRROCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRSHNG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRSHNG" formControlName="EXTRSHNG" class="form-check-input">Shingle</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRSHNG" formControlName="Checkbox_Comment_EXTRSHNG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRSTUC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRSTUC" formControlName="EXTRSTUC" class="form-check-input">Stucco</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRSTUC" formControlName="Checkbox_Comment_EXTRSTUC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRWOOD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRWOOD" formControlName="EXTRWOOD" class="form-check-input">Wood</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRWOOD" formControlName="Checkbox_Comment_EXTRWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTROTHR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTROTHR" formControlName="EXTROTHR" class="form-check-input">Other</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTROTHR" formControlName="Checkbox_Comment_EXTROTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRMASN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRMASN" formControlName="EXTRMASN" class="form-check-input">Mason Steel</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRMASN" formControlName="Checkbox_Comment_EXTRMASN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="EXTRGLAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="EXTRGLAS" formControlName="EXTRGLAS" class="form-check-input">Steel Glass</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_EXTRGLAS" formControlName="Checkbox_Comment_EXTRGLAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div formGroupName="HEATINGANDCOOLING" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.HEATINGANDCOOLING.errors }"><span class="pr-2 py-2 reqtext">*Heating & Cooling:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="HTCLCHCL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLCHCL" formControlName="HTCLCHCL" class="form-check-input">Central Heat/Cool</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLCHCL" formControlName="Checkbox_Comment_HTCLCHCL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="HTCLCNTA" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLCNTA" formControlName="HTCLCNTA" class="form-check-input">Central Air</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLCNTA" formControlName="Checkbox_Comment_HTCLCNTA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="HTCLWNDC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLWNDC" formControlName="HTCLWNDC" class="form-check-input">Window Unit</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLWNDC" formControlName="Checkbox_Comment_HTCLWNDC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="HTCLOFAC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLOFAC" formControlName="HTCLOFAC" class="form-check-input">Office A/C</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLOFAC" formControlName="Checkbox_Comment_HTCLOFAC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="HTCLNOCL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLNOCL" formControlName="HTCLNOCL" class="form-check-input">No Cooling</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLNOCL" formControlName="Checkbox_Comment_HTCLNOCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="HTCLCGAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLCGAS" formControlName="HTCLCGAS" class="form-check-input">Central Heat - Gas</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLCGAS" formControlName="Checkbox_Comment_HTCLCGAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="HTCLCELC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLCELC" formControlName="HTCLCELC" class="form-check-input">Central Heat - Elec.</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLCELC" formControlName="Checkbox_Comment_HTCLCELC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="HTCLNOHT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="HTCLNOHT" formControlName="HTCLNOHT" class="form-check-input">No Heating</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_HTCLNOHT" formControlName="Checkbox_Comment_HTCLNOHT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Floor:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="FLORCONC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FLORCONC" formControlName="FLORCONC" class="form-check-input">Concrete Perimeter</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FLORCONC" formControlName="Checkbox_Comment_FLORCONC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FLORWOOD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FLORWOOD" formControlName="FLORWOOD" class="form-check-input">Wood Subfloor</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FLORWOOD" formControlName="Checkbox_Comment_FLORWOOD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Loading:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="LOADDOCK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOADDOCK" formControlName="LOADDOCK" class="form-check-input">Dock High</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOADDOCK" formControlName="Checkbox_Comment_LOADDOCK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOADDRIV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOADDRIV" formControlName="LOADDRIV" class="form-check-input">Drive In Loading</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOADDRIV" formControlName="Checkbox_Comment_LOADDRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOADLODF" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOADLODF" formControlName="LOADLODF" class="form-check-input">Loading In Front</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOADLODF" formControlName="Checkbox_Comment_LOADLODF" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOADLODR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOADLODR" formControlName="LOADLODR" class="form-check-input">Loading In Rear</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOADLODR" formControlName="Checkbox_Comment_LOADLODR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <div class="p-2 hidebreak"><br></div>
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOADDOOR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOADDOOR" formControlName="LOADDOOR" class="form-check-input">Vehicle Door</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOADDOOR" formControlName="Checkbox_Comment_LOADDOOR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOADOTHR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOADOTHR" formControlName="LOADOTHR" class="form-check-input">Other</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOADOTHR" formControlName="Checkbox_Comment_LOADOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOADNONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOADNONE" formControlName="LOADNONE" class="form-check-input">None</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOADNONE" formControlName="Checkbox_Comment_LOADNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <div formGroupName="WATERSEWERGAS" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.WATERSEWERGAS.errors }"><span class="pr-2 py-2 reqtext">*Water/Sewer/Gas:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="WATRCTYW" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRCTYW" formControlName="WATRCTYW" class="form-check-input">City Water</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRCTYW" formControlName="Checkbox_Comment_WATRCTYW" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRPRVW" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRPRVW" formControlName="WATRPRVW" class="form-check-input">Private Water</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRPRVW" formControlName="Checkbox_Comment_WATRPRVW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRCOPW" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRCOPW" formControlName="WATRCOPW" class="form-check-input">Co-op Water</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRCOPW" formControlName="Checkbox_Comment_WATRCOPW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRWELL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRWELL" formControlName="WATRWELL" class="form-check-input">Well</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRWELL" formControlName="Checkbox_Comment_WATRWELL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRMMWL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRMMWL" formControlName="WATRMMWL" class="form-check-input">Master Meter Well</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRMMWL" formControlName="Checkbox_Comment_WATRMMWL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRIMWL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRIMWL" formControlName="WATRIMWL" class="form-check-input">Ind. Meter Well</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRIMWL" formControlName="Checkbox_Comment_WATRIMWL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRNWTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRNWTR" formControlName="WATRNWTR" class="form-check-input">No Water</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRNWTR" formControlName="Checkbox_Comment_WATRNWTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRCTYS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRCTYS" formControlName="WATRCTYS" class="form-check-input">City Sewer</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRCTYS" formControlName="Checkbox_Comment_WATRCTYS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRPRVS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRPRVS" formControlName="WATRPRVS" class="form-check-input">Private Sewer</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRPRVS" formControlName="Checkbox_Comment_WATRPRVS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRNSWR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRNSWR" formControlName="WATRNSWR" class="form-check-input">No Sewer</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRNSWR" formControlName="Checkbox_Comment_WATRNSWR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRSEPT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRSEPT" formControlName="WATRSEPT" class="form-check-input">Septic</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRSEPT" formControlName="Checkbox_Comment_WATRSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRPHON" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRPHON" formControlName="WATRPHON" class="form-check-input">Telephone Available</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRPHON" formControlName="Checkbox_Comment_WATRPHON" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRGASS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRGASS" formControlName="WATRGASS" class="form-check-input">Gas Available</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRGASS" formControlName="Checkbox_Comment_WATRGASS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRPROP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRPROP" formControlName="WATRPROP" class="form-check-input">Propane Gas</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRPROP" formControlName="Checkbox_Comment_WATRPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRNGAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRNGAS" formControlName="WATRNGAS" class="form-check-input">Natural Gas</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRNGAS" formControlName="Checkbox_Comment_WATRNGAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRMGMT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRMGMT" formControlName="WATRMGMT" class="form-check-input">Master Gas Meter</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRMGMT" formControlName="Checkbox_Comment_WATRMGMT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRIGAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRIGAS" formControlName="WATRIGAS" class="form-check-input">Individual Gas</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRIGAS" formControlName="Checkbox_Comment_WATRIGAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div formGroupName="ELECTRIC" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.ELECTRIC.errors }"><span class="pr-2 py-2 reqtext">*Electric:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="ELECMRMR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ELECMRMR" formControlName="ELECMRMR" class="form-check-input">Master Meter</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ELECMRMR" formControlName="Checkbox_Comment_ELECMRMR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ELECIMTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ELECIMTR" formControlName="ELECIMTR" class="form-check-input">Individual Meter</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ELECIMTR" formControlName="Checkbox_Comment_ELECIMTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ELECELEC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ELECELEC" formControlName="ELECELEC" class="form-check-input">Electric Available</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ELECELEC" formControlName="Checkbox_Comment_ELECELEC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ELECCOOP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ELECCOOP" formControlName="ELECCOOP" class="form-check-input">Co-op Electric</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ELECCOOP" formControlName="Checkbox_Comment_ELECCOOP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ELEC220V" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ELEC220V" formControlName="ELEC220V" class="form-check-input">220 Volts</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ELEC220V" formControlName="Checkbox_Comment_ELEC220V" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ELEC440V" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ELEC440V" formControlName="ELEC440V" class="form-check-input">440 Volts</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ELEC440V" formControlName="Checkbox_Comment_ELEC440V" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ELECSNGL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ELECSNGL" formControlName="ELECSNGL" class="form-check-input">Single Phase</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ELECSNGL" formControlName="Checkbox_Comment_ELECSNGL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Fence:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="FENCLINK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FENCLINK" formControlName="FENCLINK" class="form-check-input">Chain Link</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FENCLINK" formControlName="Checkbox_Comment_FENCLINK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FENCPRIV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FENCPRIV" formControlName="FENCPRIV" class="form-check-input">Privacy</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FENCPRIV" formControlName="Checkbox_Comment_FENCPRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FENCDECO" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FENCDECO" formControlName="FENCDECO" class="form-check-input">Decorative</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FENCDECO" formControlName="Checkbox_Comment_FENCDECO" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FENCFULL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FENCFULL" formControlName="FENCFULL" class="form-check-input">Full</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FENCFULL" formControlName="Checkbox_Comment_FENCFULL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FENCPART" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FENCPART" formControlName="FENCPART" class="form-check-input">Partial</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FENCPART" formControlName="Checkbox_Comment_FENCPART" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="FENCGATE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="FENCGATE" formControlName="FENCGATE" class="form-check-input">Electric Gate</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_FENCGATE" formControlName="Checkbox_Comment_FENCGATE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <div class="row ">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_CEIL" class="nonreqtext pr-2 py-2 col-form-label">Ceiling Height:</label>
                  <select id="CB_CEIL" formControlName="CEIL" class="form-control col-sm-5">
                    <option value=""> </option>
                    <option class="mBlackText" value="10FT">Under 10 ft.&nbsp;</option>
                    <option class="mBlackText" value="1016">10 - 16 ft.&nbsp;</option>
                    <option class="mBlackText" value="1618">16 - 18 ft.&nbsp;</option>
                    <option class="mBlackText" value="1822">18 - 22 ft.&nbsp;</option>
                    <option class="mBlackText" value="2225">22 - 25 ft.&nbsp;</option>
                    <option class="mBlackText" value="25FT">Over 25 ft.&nbsp;</option>
                    <option class="mBlackText" value="SUSP">Suspended Ceiling&nbsp;</option>
                    <option class="mBlackText" value="UNKN">Unknown&nbsp;</option>
                    <option class="mBlackText" value="OTHR">Other&nbsp;</option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0810CEIL" formControlName="0810CEIL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Alarm & Security Sys:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="ALRMFIRE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ALRMFIRE" formControlName="ALRMFIRE" class="form-check-input">Fire/Smoke</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ALRMFIRE" formControlName="Checkbox_Comment_ALRMFIRE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ALRMBRGL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ALRMBRGL" formControlName="ALRMBRGL" class="form-check-input">Burglar</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ALRMBRGL" formControlName="Checkbox_Comment_ALRMBRGL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ALRMMNTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ALRMMNTR" formControlName="ALRMMNTR" class="form-check-input">Monitored</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ALRMMNTR" formControlName="Checkbox_Comment_ALRMMNTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ALRMSPRN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ALRMSPRN" formControlName="ALRMSPRN" class="form-check-input">Fire Sprinkler</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ALRMSPRN" formControlName="Checkbox_Comment_ALRMSPRN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ALRMSECP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ALRMSECP" formControlName="ALRMSECP" class="form-check-input">Security Patrol</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ALRMSECP" formControlName="Checkbox_Comment_ALRMSECP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="ALRMSECG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="ALRMSECG" formControlName="ALRMSECG" class="form-check-input">Security Gates</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_ALRMSECG" formControlName="Checkbox_Comment_ALRMSECG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between">
                  <label for="CB_LBOX" class="reqtext pr-2 py-2 col-form-label">*Supra Lockbox:</label>
                  <select id="CB_LBOX" formControlName="LBOX" class="form-control"  required [ngClass]="{ 'is-invalid alert-danger': submitted && f.LBOX.errors }">
                    <option value=""></option>
                    <option class="mBlackText" value="YES">Yes </option>
                    <option class="mBlackText" value="NO">No </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0830LBOX" formControlName="0830LBOX" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-8">
                  <label for="0840LOCL" class="reqtext pr-2 py-2 col-form-label">*Lock Box Location:&nbsp;<span class="formnote">(Maximum 60)</span></label>
                </div>
                <div class="form-group col-sm-12 justify-content-end">
                  <textarea id="0840LOCL" formControlName="0840LOCL" class="form-control flex-grow-1" spellcheck="true" rows="" cols="50" maxlength="60"  required [ngClass]="{ 'is-invalid alert-danger': submitted && f['0840LOCL'].errors }"></textarea>
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div formGroupName="SHOWINGINSTRUCTIONS" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.SHOWINGINSTRUCTIONS.errors }"><span class="pr-2 py-2 reqtext">*Showing Instructions:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="SHOWCLLO" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWCLLO" formControlName="SHOWCLLO" class="form-check-input">Call listing Office</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWCLLO" formControlName="Checkbox_Comment_SHOWCLLO" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWLOCK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWLOCK" formControlName="SHOWLOCK" class="form-check-input">Lockbox</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWLOCK" formControlName="Checkbox_Comment_SHOWLOCK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWCLOW" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWCLOW" formControlName="SHOWCLOW" class="form-check-input">Call Owner</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWCLOW" formControlName="Checkbox_Comment_SHOWCLOW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWCLTN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWCLTN" formControlName="SHOWCLTN" class="form-check-input">Call Tenant</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWCLTN" formControlName="Checkbox_Comment_SHOWCLTN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWDRIV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWDRIV" formControlName="SHOWDRIV" class="form-check-input">Drive By</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWDRIV" formControlName="Checkbox_Comment_SHOWDRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWCLMG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWCLMG" formControlName="SHOWCLMG" class="form-check-input">Call Manager</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWCLMG" formControlName="Checkbox_Comment_SHOWCLMG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWREST" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWREST" formControlName="SHOWREST" class="form-check-input">Restricted Hours</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWREST" formControlName="Checkbox_Comment_SHOWREST" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWAPPT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWAPPT" formControlName="SHOWAPPT" class="form-check-input">By Appointment</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWAPPT" formControlName="Checkbox_Comment_SHOWAPPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWOWOC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWOWOC" formControlName="SHOWOWOC" class="form-check-input">Owner Occupied</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWOWOC" formControlName="Checkbox_Comment_SHOWOWOC" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SHOWALRM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SHOWALRM" formControlName="SHOWALRM" class="form-check-input">Alarm Security</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SHOWALRM" formControlName="Checkbox_Comment_SHOWALRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <div class="row">
                <div class="form-group col-sm-8">
                  <label for="Showing_Instructions" class="nonreqtext pr-2 py-2 col-form-label">Showing Instructions:&nbsp;<span class="formnote">(Maximum 60)</span></label>
                </div>
                <div class="form-group col-sm-12 justify-content-end">
                  <textarea id="Showing_Instructions" formControlName="Showing_Instructions" class="form-control flex-grow-1" spellcheck="true" rows="" cols="50" maxlength="60"></textarea>
                </div>
              </div>
              <div class="row form-group col-sm-12">
                <span class="reqtext pr-2 py-2 formnote">Alarm code may only be entered in MLS with sellers written consent</span>
              </div>
              <!--End of right column-->
            </div>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepId="Financial Data"
        stepTitle="Financial Data" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">3</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Financial Data Form">
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between flex-nowrap">
									<label for="NUM_OINC" class="nonreqtext pr-2 py-2 col-form-label">Operating Income:</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">$</span>
										</div>
										<input type="text" id="NUM_OINC" formControlName="OINC" class="form-control" size="" maxlength="5" value="">
									</div>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0920OINC" formControlName="0920OINC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between flex-nowrap">
                  <label for="NUM_OEXP" class="nonreqtext pr-2 py-2 col-form-label">Operating Expense:</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">$</span>
										</div>
                  	<input type="text" id="NUM_OEXP" formControlName="OEXP" class="form-control" size="" maxlength="5" value="">
									</div>
								</div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0930OEXP" formControlName="0930OEXP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between flex-nowrap">
                  <label for="NUM_TAXR" class="nonreqtext pr-2 py-2 col-form-label">Tax Rate:</label>
									<div class="input-group">
										<input type="text" id="NUM_TAXR" formControlName="TAXR" class="form-control" size="" maxlength="5" value="">
										<div class="input-group-append">
											<span class="input-group-text">%</span>
										</div>
									</div>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0940TAXR" formControlName="0940TAXR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between flex-nowrap">
                  <label for="NUM_LNDV" class="nonreqtext pr-2 py-2 col-form-label">Land Value:</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">$</span>
										</div>
										<input type="text" id="NUM_LNDV" formControlName="LNDV" class="form-control" size="" maxlength="5" value="">
									</div>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0950LNDV" formControlName="0950LNDV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between flex-nowrap">
                  <label for="NUM_IMPV" class="nonreqtext pr-2 py-2 col-form-label">Improvement Value:</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">$</span>
										</div>
										<input type="text" id="NUM_IMPV" formControlName="IMPV" class="form-control" size="" maxlength="5" value="">
									</div>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0960IMPV" formControlName="0960IMPV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-between flex-nowrap">
                  <label for="NUM_TTLV" class="nonreqtext pr-2 py-2 col-form-label">Total Value:</label>
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">$</span>
										</div>
										<input type="text" id="NUM_TTLV" formControlName="TTLV" class="form-control" size="" maxlength="5" value="">
									</div>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="0970TTLV" formControlName="0970TTLV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
						<!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.SALESINCLUDES.errors }"><span class="pr-2 py-2 reqtext">*Sales Includes:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
							<!-- One row for a new input group -->
							<div formGroupName="SALESINCLUDES" required>
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="SINCLNDO" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCLNDO" formControlName="SINCLNDO" class="form-check-input">Land Only</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCLNDO" formControlName="Checkbox_Comment_SINCLNDO" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCLNDI" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCLNDI" formControlName="SINCLNDI" class="form-check-input">Land & Improvements</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCLNDI" formControlName="Checkbox_Comment_SINCLNDI" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCBLDG" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCBLDG" formControlName="SINCBLDG" class="form-check-input">Building (s)</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCBLDG" formControlName="Checkbox_Comment_SINCBLDG" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCBUSN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCBUSN" formControlName="SINCBUSN" class="form-check-input">Business</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCBUSN" formControlName="Checkbox_Comment_SINCBUSN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCINVN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCINVN" formControlName="SINCINVN" class="form-check-input">Inventory</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCINVN" formControlName="Checkbox_Comment_SINCINVN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCFRCH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCFRCH" formControlName="SINCFRCH" class="form-check-input">Franshise</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCFRCH" formControlName="Checkbox_Comment_SINCFRCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCEQIP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCEQIP" formControlName="SINCEQIP" class="form-check-input">Equipment</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCEQIP" formControlName="Checkbox_Comment_SINCEQIP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCFURN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCFURN" formControlName="SINCFURN" class="form-check-input">Furniture</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCFURN" formControlName="Checkbox_Comment_SINCFURN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCLICS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCLICS" formControlName="SINCLICS" class="form-check-input">Licenses</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCLICS" formControlName="Checkbox_Comment_SINCLICS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SINCOTHR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SINCOTHR" formControlName="SINCOTHR" class="form-check-input">Other</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SINCOTHR" formControlName="Checkbox_Comment_SINCOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
							</div>
							</div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Leased Equipment:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="LSDESECS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LSDESECS" formControlName="LSDESECS" class="form-check-input">Security System</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LSDESECS" formControlName="Checkbox_Comment_LSDESECS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LSDEPROP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LSDEPROP" formControlName="LSDEPROP" class="form-check-input">Propane Tank</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LSDEPROP" formControlName="Checkbox_Comment_LSDEPROP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LSDEWTRS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LSDEWTRS" formControlName="LSDEWTRS" class="form-check-input">Water Softner</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LSDEWTRS" formControlName="Checkbox_Comment_LSDEWTRS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LSDESATD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LSDESATD" formControlName="LSDESATD" class="form-check-input">Satellite Dish</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LSDESATD" formControlName="Checkbox_Comment_LSDESATD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LSDESOLP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LSDESOLP" formControlName="LSDESOLP" class="form-check-input">Solar Panels</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LSDESOLP" formControlName="Checkbox_Comment_LSDESOLP" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of left column-->
						</div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Property Association:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="PRPTDOPT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PRPTDOPT" formControlName="PRPTDOPT" class="form-check-input">Dues Optional</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PRPTDOPT" formControlName="Checkbox_Comment_PRPTDOPT" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PRPTDMAN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PRPTDMAN" formControlName="PRPTDMAN" class="form-check-input">Dues Mandatory</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PRPTDMAN" formControlName="Checkbox_Comment_PRPTDMAN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="PRPTPARK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="PRPTPARK" formControlName="PRPTPARK" class="form-check-input">Parking Fees</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_PRPTPARK" formControlName="Checkbox_Comment_PRPTPARK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Occupancy:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="OCCPCPIC" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPCPIC" formControlName="OCCPCPIC" class="form-check-input">CPI Change</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPCPIC" formControlName="Checkbox_Comment_OCCPCPIC" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPFULL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPFULL" formControlName="OCCPFULL" class="form-check-input">Full Service</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPFULL" formControlName="Checkbox_Comment_OCCPFULL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPNLES" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPNLES" formControlName="OCCPNLES" class="form-check-input">Net Lease</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPNLES" formControlName="Checkbox_Comment_OCCPNLES" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPGLES" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPGLES" formControlName="OCCPGLES" class="form-check-input">Gross Lease</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPGLES" formControlName="Checkbox_Comment_OCCPGLES" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPMLES" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPMLES" formControlName="OCCPMLES" class="form-check-input">Modified Gross Lease</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPMLES" formControlName="Checkbox_Comment_OCCPMLES" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPPLES" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPPLES" formControlName="OCCPPLES" class="form-check-input">Percentage Lease</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPPLES" formControlName="Checkbox_Comment_OCCPPLES" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPSLES" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPSLES" formControlName="OCCPSLES" class="form-check-input">Sub Lease</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPSLES" formControlName="Checkbox_Comment_OCCPSLES" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPFLES" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPFLES" formControlName="OCCPFLES" class="form-check-input">Fixed Lease</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPFLES" formControlName="Checkbox_Comment_OCCPFLES" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OCCPSUIT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OCCPSUIT" formControlName="OCCPSUIT" class="form-check-input">Build to Suit</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OCCPSUIT" formControlName="Checkbox_Comment_OCCPSUIT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
						<!-- Left Column-->
            <div formGroupName="TENANTPAYS" class="col-lg-6 align-self-start" required>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.TENANTPAYS.errors }"><span class="pr-2 py-2 reqtext">*Tenant Pays:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="TNNTUTIL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTUTIL" formControlName="TNNTUTIL" class="form-check-input">Utilities</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTUTIL" formControlName="Checkbox_Comment_TNNTUTIL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TNNTCOMM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTCOMM" formControlName="TNNTCOMM" class="form-check-input">Common Area</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTCOMM" formControlName="Checkbox_Comment_TNNTCOMM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TNNTMNTN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTMNTN" formControlName="TNNTMNTN" class="form-check-input">Maintenance Fees</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTMNTN" formControlName="Checkbox_Comment_TNNTMNTN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TNNTTAXS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTTAXS" formControlName="TNNTTAXS" class="form-check-input">Taxes</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTTAXS" formControlName="Checkbox_Comment_TNNTTAXS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TNNTINSR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTINSR" formControlName="TNNTINSR" class="form-check-input">Insurance</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTINSR" formControlName="Checkbox_Comment_TNNTINSR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TNNTOTHR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTOTHR" formControlName="TNNTOTHR" class="form-check-input">Other</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTOTHR" formControlName="Checkbox_Comment_TNNTOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TNNTPARK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTPARK" formControlName="TNNTPARK" class="form-check-input">Parking Area</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTPARK" formControlName="Checkbox_Comment_TNNTPARK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TNNTJNTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TNNTJNTR" formControlName="TNNTJNTR" class="form-check-input">Janitorial</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TNNTJNTR" formControlName="Checkbox_Comment_TNNTJNTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of left column-->
						</div>
						<!--Right Column-->
            <div formGroupName="OWNERPAYS" class="col-lg-6 align-self-start" requird>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.OWNERPAYS.errors }"><span class="pr-2 py-2 reqtext">*Owner Pays:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="OWNRUTIL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OWNRUTIL" formControlName="OWNRUTIL" class="form-check-input">Utilities</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OWNRUTIL" formControlName="Checkbox_Comment_OWNRUTIL" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OWNRTAXS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OWNRTAXS" formControlName="OWNRTAXS" class="form-check-input">Taxes</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OWNRTAXS" formControlName="Checkbox_Comment_OWNRTAXS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OWNRINSR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OWNRINSR" formControlName="OWNRINSR" class="form-check-input">Insurance</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OWNRINSR" formControlName="Checkbox_Comment_OWNRINSR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OWNRMNTN" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OWNRMNTN" formControlName="OWNRMNTN" class="form-check-input">Maintenance Fees</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OWNRMNTN" formControlName="Checkbox_Comment_OWNRMNTN" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OWNRPARK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OWNRPARK" formControlName="OWNRPARK" class="form-check-input">Parking Area</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OWNRPARK" formControlName="Checkbox_Comment_OWNRPARK" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OWNRJNTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OWNRJNTR" formControlName="OWNRJNTR" class="form-check-input">Janitorial</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OWNRJNTR" formControlName="Checkbox_Comment_OWNRJNTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="OWNRNONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="OWNRNONE" formControlName="OWNRNONE" class="form-check-input">None</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_OWNRNONE" formControlName="Checkbox_Comment_OWNRNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
						</div>
          </div>
          <!--Page Break-->
          <div>
            <hr class="HRstyle">
          </div>
          <!--Page Break-->
          <div class="d-flex form-inline row pt-1">
            <!-- Left Column-->
            <div class="col-lg-6 align-self-start">
              <div class="row form-group col-sm-12 justify-content-between">
                <label for="CB_LOAN" class="reqtext pr-2 py-2 col-form-label">*Existing Loan:</label>
              </div>
              <div class="row">
                <div class="form-group col-sm-6 justify-content-end">
                  <select id="CB_LOAN" formControlName="LOAN" class="form-control"  required [ngClass]="{ 'is-invalid alert-danger': submitted && f.LOAN.errors }">
                    <option value=""></option>
                    <option class="mBlackText" value="CONV">Conventional </option>
                    <option class="mBlackText" value="PRIV">Private </option>
                    <option class="mBlackText" value="ASSM">Assumable </option>
                    <option class="mBlackText" value="NASS">Non-Assumable </option>
                    <option class="mBlackText" value="FRCL">Free & Clear </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <input type="text" id="1060LOAN" formControlName="1060LOAN" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="">
                </div>
              </div>
              <!--break for next group in the left column-->
              <br>
              <span class="row col" [ngClass]="{ 'alert-danger': submitted && f.SELLERWILLCONSIDER.errors }"><span class="pr-2 py-2 reqtext">*Seller Will Consider:&nbsp;<span class="reqtext formnote">(Up to 8 entries allowed)</span></span></span>
							<!-- One row for a new input group -->
							<div formGroupName="SELLERWILLCONSIDER" required>
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="TERMCONV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMCONV" formControlName="TERMCONV" class="form-check-input">Conventional</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMCONV" formControlName="Checkbox_Comment_TERMCONV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMLEAS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMLEAS" formControlName="TERMLEAS" class="form-check-input">Lease Option</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMLEAS" formControlName="Checkbox_Comment_TERMLEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMCASH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMCASH" formControlName="TERMCASH" class="form-check-input">Cash</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMCASH" formControlName="Checkbox_Comment_TERMCASH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMSUBM" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMSUBM" formControlName="TERMSUBM" class="form-check-input">Submit</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMSUBM" formControlName="Checkbox_Comment_TERMSUBM" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMOWNR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMOWNR" formControlName="TERMOWNR" class="form-check-input">Owner Carry</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMOWNR" formControlName="Checkbox_Comment_TERMOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="TERMLIMI" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="TERMLIMI" formControlName="TERMLIMI" class="form-check-input">Limited Financing</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_TERMLIMI" formControlName="Checkbox_Comment_TERMLIMI" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
							</div>
							</div>
              <!--End of left column-->
            </div>
            <!--Right Column-->
            <div class="col-lg-6 align-self-start">
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Sale Options:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="SOPTTTLP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOPTTTLP" formControlName="SOPTTTLP" class="form-check-input">Total Parcel Only</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOPTTTLP" formControlName="Checkbox_Comment_SOPTTTLP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SOPTDIVD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOPTDIVD" formControlName="SOPTDIVD" class="form-check-input">Divided</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOPTDIVD" formControlName="Checkbox_Comment_SOPTDIVD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="SOPTADJL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="SOPTADJL" formControlName="SOPTADJL" class="form-check-input">Adjacent Land Avail.</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_SOPTADJL" formControlName="Checkbox_Comment_SOPTADJL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--break for next group in the right column-->
              <br>
              <span class="row col"><span class="pr-2 py-2 nonreqtext">Special Conditions:</span></span>
              <!-- One row for a new input group -->
              <div class="row fixcheck">
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="UNIQBANK" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="UNIQBANK" formControlName="UNIQBANK" class="form-check-input">Bankrupcty</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_UNIQBANK" formControlName="Checkbox_Comment_UNIQBANK" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="UNIQPROB" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="UNIQPROB" formControlName="UNIQPROB" class="form-check-input">Probate Listing</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_UNIQPROB" formControlName="Checkbox_Comment_UNIQPROB" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="UNIQESTA" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="UNIQESTA" formControlName="UNIQESTA" class="form-check-input">Estate Sale</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_UNIQESTA" formControlName="Checkbox_Comment_UNIQESTA" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="UNIQCORT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="UNIQCORT" formControlName="UNIQCORT" class="form-check-input">Court Approval Req.</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_UNIQCORT" formControlName="Checkbox_Comment_UNIQCORT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="UNIQBIDD" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="UNIQBIDD" formControlName="UNIQBIDD" class="form-check-input">Bidding Required</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_UNIQBIDD" formControlName="Checkbox_Comment_UNIQBIDD" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="UNIQEXCH" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="UNIQEXCH" formControlName="UNIQEXCH" class="form-check-input">1031 Exchange</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_UNIQEXCH" formControlName="Checkbox_Comment_UNIQEXCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
              <!--End of right column-->
            </div>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step 
        stepTitle="Review" (stepEnter)="validate()" awOptionalStep class="h-100">
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">4</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <ng-container *ngIf="form.valid; else notValidForm">
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-success">All required fields completed!</div>
              <div class="font-weight-light mb-2">Please review to make sure all information is correct and up-to-date before submitting.</div>
              <button class="btn btn-lg btn-primary w-100" (click)="submit()">Submit</button>
            </div>
          </div>
        </ng-container>
        <ng-template #notValidForm>
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-danger">Missing required fields!</div>
              <div class="font-weight-light mb-2">Please review below to make sure all required fields are completed.</div>
              <div class="card overflow-auto p-2 my-2" style="height: 250px;">
                <ul *ngFor="let error of errorList" class="list-group list-group-flush">
                  <li class="d-flex justify-content-between align-items-center list-group-item">{{error.key}}<button class="btn btn-sm btn-secondary" (click)="goToError(error)">Go to</button></li>
                </ul>
              </div>
              <button class="btn btn-lg btn-primary w-100" disabled>Submit</button>
            </div>
          </div>
        </ng-template>
      </aw-wizard-step>
    </div>
    <div class="form-footer p-2">
      <button type="button" class="btn btn-secondary mx-1" (click)="save()">Save</button>
      <div class="btn-group mx-1">
        <button type="button" class="btn btn-secondary" awPreviousStep>Previous</button>
        <button type="button" class="btn btn-secondary" awNextStep>Next</button>
      </div>
    </div>
  </aw-wizard>
</div>

<swal
  #submitSuccess
  title="Submission Sent"
  text="Your response was submitted successfully!"
  icon="success"
  confirmButtonText="Finish"
  (confirm)="complete()">

  <div *swalPortal class="alert alert-info">
    <div class="additional-form-container">
      <small class="mb-2">Request a sign install by selecting the button below!</small>
      <button class="btn btn-primary btn-lg" (click)="navigateToSign()">REQUEST SIGN INSTALL HERE</button>
    </div>
    <!--
    Do you need to fill any additional forms?
    <div class="additional-form-container mt-2">
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h6>Request Sign Request Here</h6>
              <button class="btn btn-primary" (click)="navigateToSign()">Request Sign Request</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>

</swal>

<swal
  #submitError
  title="Submission Error"
  text="An error occurred during submission, please try again!"
  icon="error">
</swal>
