import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(private http: HttpClient) { }

  // Uses session id.
  // Checks if a user has an organization. If so check in session if user was previously browsing an organization.
  // Return that Organization ID. If a user has no organization, return null;
  public doesUserHaveOrganization(): Observable<any> {
    return this.http.get('/api/access/organization');
  }

  // Get all user's accessess. What organization/role a user has
  public getCurrentUsersAccesses(): Observable<any> {
    return this.http.get('/api/access/user');
  }

  // Uses session id.
  // Gets all members of session's organization.
  public getMembers(page: any): Observable<any> {
    return this.http.get('/api/access/members', { params: page });
  }

  public addMember(email: string, name: string, role: number): Observable<any> {
    return this.http.post('/api/access', {email: email, name: name, role: role});
  }

  // Payload follows below schema...
  // {
  //    email: string
  //    name: string
  //    role: string
  // }
  public editMember(userId: string, payload: any): Observable<any> {
    return this.http.patch('/api/access/' + userId, payload)
  }

  public deleteMember(accessId: string): Observable<any> {
    return this.http.delete('api/access/' + accessId);
  }

  // TODO: Add querying for more stats
  public queryAccessStatistics(): Observable<any> {
    return this.http.get('/api/access/statistics', {});
  }
}
