<div [formGroup]="form" class="h-100">
  <aw-wizard class="h-100" #wizard
    [awNavigationMode]="navigationMode"
    navBarLocation="left"
    navBarLayout="large-empty-symbols">
    <div class="form-header p-2">
      <h4 *ngIf="!responseCache; else saveTitle" class="font-weight-bold">
        FRESNO MLS
        <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Special Request Input</small></div>
      </h4>
      <ng-template #saveTitle>
        <h4 class="font-weight-bold">
          {{ responseCache.name }}
          <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Updated {{ responseCache.timeUpdated | amTimeAgo }}</small></div>
        </h4>
      </ng-template>
      <img src="assets/images/RClogo.png" class="img-fluid" width="120" alt="Realty Concepts"/>
    </div>
    <div class="form-container card card-body">
      <aw-wizard-step
        stepId="Requests"
        stepTitle="Requests" awOptionalStep>
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">1</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <div id="Requests">

          <div class="form-group">
            <label class="form-label text-danger" [ngClass]="{ 'is-invalid alert-danger': submitted && f.TYPE.errors }">Request Type*</label>
            <ng-select
              formControlName="TYPE"
              [multiple]="false"
              required>
              <ng-option value="YARDSIGN">Yard Sign</ng-option>
              <ng-option value="OTHER">Other</ng-option>
            </ng-select>
          </div>

          <hr>

          <ng-container *ngIf="form.get('TYPE').value == 'YARDSIGN'">
            <div class="form-group">
              <label class="form-label text-danger">Listing Address*</label>
              <input 
                type="text"
                class="form-control"
                placeholder="123 Example Avenue"
                formControlName="FULL_ADDRESS"
                required
                [ngClass]="{ 'is-invalid alert-danger': submitted && f.FULL_ADDRESS.errors }">
            </div>
            <div class="form-group">
              <label class="form-label">Comments/Additional Details</label>
              <textarea
                class="form-control"
                rows="4"
                formControlName="YARDSIGN_COMMENTS"></textarea>
            </div>
          </ng-container>

          <ng-container *ngIf="form.get('TYPE').value == 'OTHER'">
            <div class="form-group">
              <label class="form-label text-danger">Written Request*</label>
              <textarea
                class="form-control"
                rows="4"
                formControlName="WRITTEN_REQUEST"
                required
                [ngClass]="{ 'is-invalid alert-danger': submitted && f.WRITTEN_REQUEST.errors }"></textarea>
            </div>
          </ng-container>


        </div>
      </aw-wizard-step>

      <aw-wizard-step 
        stepTitle="Review" (stepEnter)="validate()" awOptionalStep class="h-100">
        <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
          <div *ngIf="!wizardStep.completed && !wizardStep.touched">7</div>
          <i *ngIf="wizardStep.completed && wizardStep.touched" class="ion ion-md-checkmark"></i>
          <i *ngIf="!wizardStep.completed && wizardStep.touched" class="ion ion-md-close"></i>
        </ng-template>
        <ng-container *ngIf="form.valid; else notValidForm">
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-success">All required fields completed!</div>
              <div class="font-weight-light mb-2">Please review to make sure all information is correct and up-to-date before submitting.</div>
              <button class="btn btn-lg btn-primary w-100" (click)="submit()">Submit</button>
            </div>
          </div>
        </ng-container>
        <ng-template #notValidForm>
          <div class="d-flex col-md justify-content-center align-items-center p-3 h-100">
            <div class="text-center">
              <div class="mb-2 font-weight-bold text-danger">Missing required fields!</div>
              <div class="font-weight-light mb-2">Please review below to make sure all required fields are completed.</div>
              <div class="card overflow-auto p-2 my-2" style="height: 250px;">
                <ul *ngFor="let error of errorList" class="list-group list-group-flush">
                  <li class="d-flex justify-content-between align-items-center list-group-item">{{error.key}}<button class="btn btn-sm btn-secondary" (click)="goToError(error)">Go to</button></li>
                </ul>
              </div>
              <button class="btn btn-lg btn-primary w-100" disabled>Submit</button>
            </div>
          </div>
        </ng-template>
      </aw-wizard-step>
    </div>
    <div class="form-footer p-2">
      <button type="button" class="btn btn-secondary mx-1" (click)="save()">Save</button>
      <div class="btn-group mx-1">
        <button type="button" class="btn btn-secondary" awPreviousStep>Previous</button>
        <button type="button" class="btn btn-secondary" awNextStep>Next</button>
      </div>
    </div>
  </aw-wizard>
</div>

<swal
  #submitSuccess
  title="Submission Sent"
  text="Your response was submitted successfully!"
  icon="success"
  (confirm)="complete()">
</swal>

<swal
  #submitError
  title="Submission Error"
  text="An error occurred during submission, please try again!"
  icon="error">
</swal>