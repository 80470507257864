import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { LayoutService } from 'src/app/services/layout.service';
import { CustomNavigationMode } from '../../services/custom-navigation';
import { CustomFormsService } from '../../services/custom-forms.service';
import { Response } from 'src/app/models/response.model';
import { ModalService } from 'src/app/modals/services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from 'angular-archwizard';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as L from 'leaflet';

@Component({
  selector: 'app-sign-request',
  templateUrl: './sign-request.component.html',
  styleUrls: ['./sign-request.component.scss']
})
export class SignRequestComponent implements OnInit {
  @ViewChild('submitSuccess') private submitSuccess: SwalComponent;
  @ViewChild('submitError') private submitError: SwalComponent;
  @ViewChild('wizard') private wizard: WizardComponent;
  public errorList = [];

  public form: FormGroup
  public submitted: boolean = false;
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public numErrors: number = 0;
  public key: string = '';
  public responseCache: Response = null;

  private routeSubscription: Subscription;
  private dataSubscription: Subscription;
  private formSubscription: Subscription;

  public navigationMode: CustomNavigationMode;
  public tomorrow: Date;

  private map: L.Map = null;
  private marker: L.Marker = null;
  public options = {
    layers: [
      L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 12,
    center: L.latLng(36.746841, -119.772591)
  };

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private layoutService: LayoutService,
    private customFormsService: CustomFormsService,
    private modalService: ModalService,
    private toastrService: ToastrService) {
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
          e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  }

  ngOnInit() {
    this.layoutService.setSideNavHidden(true);
    this.form = new FormGroup({
      "TYPE": new FormControl('', [Validators.required]),
      "FULL_ADDRESS": new FormControl('', [Validators.required]),
      "CITY": new FormControl('', [Validators.required]),
      "ZIP": new FormControl('', [Validators.required]),
      "CROSS_STREETS": new FormControl('', [Validators.required]),

      "AGENT_NAME": new FormControl('', [Validators.required]),
      "PHONE_NUMBER": new FormControl('', [Validators.required]),

      "INSTALL_DATE": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'INSTALL')]),
      "REMOVAL_DATE": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'REMOVAL')]),

      "QUANTITY": new FormControl('', [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'INSTALL' || this.form.get('TYPE').value == 'REMOVAL')]),
      "RIDERS": new FormControl(null, [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'INSTALL' || this.form.get('TYPE').value == 'CHANGE')]),

      "COMMENTS": new FormControl('', []),

      "MAPPABLE": new FormControl(null, [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'INSTALL')]),
      "LOCATION": new FormControl(null, [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'INSTALL' && this.form.get('MAPPABLE').value == 'YES')]),
      
      "DAMAGELOSS": new FormControl(null, [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'DAMAGELOSS')]),
      "REPLACEREMOVE": new FormControl(null, [this.customFormsService.requiredIfValidator(() => this.form.get('TYPE').value == 'DAMAGELOSS')]),

    }, { updateOn: 'change' });


    this.formSubscription = this.form.valueChanges.subscribe(value => {
      const temp = value;
      Object.keys(temp).forEach(key => {
        if(!temp[key] || temp[key] == '' || temp[key] == null)
          delete temp[key];
      });
      localStorage.setItem('cachedFormData', JSON.stringify(temp));

      console.log(value);

      //this.form.get('Co_Office_MLS_ID').updateValueAndValidity({onlySelf: true});
      //this.form.get('Co_Agent_MLS_ID').updateValueAndValidity({onlySelf: true});
    })

    this.form.get('TYPE').valueChanges.subscribe(type => {
      this.marker = null;
      this.form.get('LOCATION').setValue(null);
    })

    this.form.get('MAPPABLE').valueChanges.subscribe(mappable => {
      this.marker = null;
      this.form.get('LOCATION').setValue(null);
    })

    const today = new Date();
    this.tomorrow = new Date(today)
    this.tomorrow.setDate(this.tomorrow.getDate() + 1)

    /*
    this.form.get('Office_ID').valueChanges.subscribe(val => {
      this.form.get('Office_MLS_ID').setValue(val);
    })

    this.formSubscription.add(this.form.get('Street_Number').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Direction').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Address').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))

    this.formSubscription.add(this.form.get('Street_Suffix').valueChanges.subscribe(val => {
      this.form.get('FULL_ADDRESS').setValue(this.form.get('Street_Number').value + ' ' + this.form.get('Street_Direction').value + ' ' + this.form.get('Street_Address').value + ' ' + this.form.get('Street_Suffix').value);
    }))
    */

    this.navigationMode = new CustomNavigationMode(this.form);
  }

  ngAfterViewInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      this.key = params.key;
      if(params.key) {
        this.dataSubscription = this.responseService.getResponse(params.key).subscribe(payload => {
          this.form.patchValue(payload.data);
          this.responseCache = payload;
        })
      }
    })
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    if(this.dataSubscription)
      this.dataSubscription.unsubscribe();

    if(this.formSubscription)
      this.formSubscription.unsubscribe();

    if(this.responseCache)
      this.responseCache = null;
  }

  get f() { return this.form.controls; }

  onMapReady(map: L.Map) {
    this.map = map;
  }

  dropMarker(event) {
    if(this.map) {

      if(!this.marker) {
        this.marker = L.marker([event.latlng.lat, event.latlng.lng], { icon: L.icon({ iconUrl: 'https://www.dropbox.com/s/dhtk7lu1v3iay55/pin_blue.png?raw=1', iconSize: [32, 32], iconAnchor: [16, 32] })});
        this.marker.addTo(this.map);
      }
      else
        this.marker.setLatLng([event.latlng.lat, event.latlng.lng]);

      this.form.get('LOCATION').setValue({lat: event.latlng.lat, lng: event.latlng.lng});
    }
  }

  submit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if(this.errorList.length === 0) {
      this.isLoading = true;
      //if(this.form.get('FULL_ADDRESS').value == '' || !this.form.get('FULL_ADDRESS').value) {
      //  this.form.get('FULL_ADDRESS').setValue('N/A');
      //}
      let temp = this.form.value;

      /*
      if(temp["Dollar_Percent"] == '$') {
        temp["Commission_1"] = '$' + temp["Commission_Amount"];
      } else if(temp["Dollar_Percent"] == '%') {
        temp["Commission_1"] = temp["Commission_Amount"] + '%';
      }
      temp["Office_MLS_ID"] = temp["Office_ID"]; 
      */

      //const data = this.customFormsService.sanitizeFormPayload(temp);

      if(!this.responseCache) {
        const response = {
          form: '61f9e34e7d575cf418e413c2',
          organization: '',
          name: 'Save_' + Math.random().toString(36).substr(2, 5),
          data: temp,
          submitted: true
        }
        this.responseService.postResponse(response).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        })
      } else {
        this.responseService.patchResponse(this.responseCache._id, {data: temp, submitted: true }).subscribe((res: any) => {
          if(res.err)
            this.submitError.fire();
          else {
            this.submitSuccess.fire();
          }
        });
      }
    } else {
      this.numErrors = 0;
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.numErrors++;
              console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });
      this.toastrService.error('You have not completed the form!', 'Submission Failed');
    }
  }

  save() {
    const self = this;
    this.isSaving = true;

    // Remove empty/null inputs to store less
    const data = this.customFormsService.sanitizeFormPayload(this.form.value);
    if(!this.responseCache) {
      this.modalService.openModal('SaveResponse', { backdrop: 'static', keyboard: false }, {formData: data, formId: '61f9e34e7d575cf418e413c2'}, function(data) {
        console.log(data);
        if(data.err)
          self.toastrService.error('Save was unsuccessful, please try again.', 'Error 500');
        else {
          self.toastrService.success('Save was successful', 'Success');
          self.router.navigate(['.'], { relativeTo: self.route, queryParams: {key: data._id}, queryParamsHandling: 'merge' });
          self.form.markAsPristine();
        }
      });
    } else {
      if(this.form.dirty) {
        this.responseService.patchResponse(this.responseCache._id, {data: data}).subscribe(res => {
          this.isSaving = false;
          this.responseCache = res;
          this.form.markAsPristine();
          this.toastrService.success('Save was successful', 'Success');
        });
      } else {
        this.toastrService.info('No changes detected', 'Unsuccessful Save');
      }
    }
  }

  validate() {
    this.submitted = true;
    this.numErrors = 0;
    this.errorList = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          this.numErrors++;
          //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          let errorElem = document.querySelector("[formControlName='" + key + "']");
          if(!errorElem) {
            //console.log(key, console.log((<FormGroup>this.form.get(key))));
            if(!Object.keys((<FormGroup>this.form.get(key)))) {
              let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
              //console.log(childKey);
              errorElem = document.querySelector("[formControlName='" + childKey + "']");
            }
          }

          if(errorElem) {
            let stepId = errorElem.closest('aw-wizard-step').getAttribute('stepid')
            this.errorList.push({
              key: key,
              stepId: stepId,
            })
          }
        });
      }
    });
    console.log(this.errorList);
  }

  goToError(error) {
    const key = error.key
    const index = this.wizard.getIndexOfStepWithId(error.stepId);
    this.wizard.goToStep(index);

    let errorElem = document.querySelector("[formControlName='" + key + "']");
    if(!errorElem) {
      let childKey = Object.keys((<FormGroup>this.form.get(key)).controls)[0];
      errorElem = document.querySelector("[formControlName='" + childKey + "']");
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + childKey + "']").getBoundingClientRect().top - 250
      }, 100)
    } else {
      setTimeout(() => {
        var container = document.querySelector('.form-container');
        container.scrollTop = document.querySelector("[formControlName='" + error.key + "']").getBoundingClientRect().top - 200
      }, 100)
    }
  }

  enableControl(condition: boolean, controlNames: string[]) {
    if(condition) {
      controlNames.forEach(name => {
        this.form.controls[name].enable();
      })
    } else {
      controlNames.forEach(name => {
        this.form.controls[name].disable();
      })
    }
  }

  complete() {
    this.layoutService.setSideNavHidden(false);
    this.router.navigate(['../history'], { relativeTo: this.route });
  }
}
