<ng-container *ngIf="response; else loading">
  <div class="modal-header">
     <h5 class="modal-title d-flex flex-column">
        <span>{{ formName }} - {{ response.data.FULL_ADDRESS }}</span>
        <small class="text-muted">{{ response.user.email || 'N/A'}} - updated {{ response.timeUpdated | amTimeAgo }}</small>
     </h5>
     <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="printable-form" [formGroup]="form">
      <div id="Listing Information Form">
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between pt-1">
            <label for="selCountyComboBox" class= "reqtext pr-2 py-2">*County:</label>
            <select id="selCountyComboBox" formControlName="County_ComboBox" class="form-control col-md-8" required>
                <option value="Fresno" selected>Fresno</option>
                <option value="Kern">Kern</option>
                <option value="Kings">Kings</option>
                <option value="Madera">Madera</option>
                <option value="Mariposa">Mariposa</option>
                <option value="Merced">Merced</option>
                <option value="Mono">Mono</option>
                <option value="Orange">Orange</option>
                <option value="Placer">Placer</option>
                <option value="Riverside">Riverside</option>
                <option value="San Bernardino">San Bernardino</option>
                <option value="San Joaquin">San Joaquin</option>
                <option value="Santa Clara">Santa Clara</option>
                <option value="Stanislaus">Stanislaus</option>
                <option value="Tehama">Tehama</option>
                <option value="Tulare">Tulare</option>
                <option value="Tuolumne">Tuolumne</option>
                <option value="Ventura">Ventura</option>
                <option value="Out of Area">Out of Area</option>
                <option value="Unknown">Unknown</option>
                <option value="Alameda">Alameda</option>
                <option value="Alpine">Alpine</option>
                <option value="Amador">Amador</option>
                <option value="Butte">Butte</option>
                <option value="Calaveras">Calaveras</option>
                <option value="Colusa">Colusa</option>
                <option value="Contra Costa">Contra Costa</option>
                <option value="Del Norte">Del Norte</option>
                <option value="El Dorado">El Dorado</option>
                <option value="Glenn">Glenn</option>
                <option value="Humboldt">Humboldt</option>
                <option value="Imperial">Imperial</option>
                <option value="Inyo">Inyo</option>
                <option value="Lake">Lake</option>
                <option value="Lassen">Lassen</option>
                <option value="Los Angeles">Los Angeles</option>
                <option value="Marin">Marin</option>
                <option value="Mendocino">Mendocino</option>
                <option value="Modoc">Modoc</option>
                <option value="Monterey">Monterey</option>
                <option value="Napa">Napa</option>
                <option value="Nevada">Nevada</option>
                <option value="Plumas">Plumas</option>
                <option value="Sacramento">Sacramento</option>
                <option value="San Benito">San Benito</option>
                <option value="San Diego">San Diego</option>
                <option value="San Francisco">San Francisco</option>
                <option value="San Luis Obispo">San Luis Obispo</option>
                <option value="San Mateo">San Mateo</option>
                <option value="Santa Barbara">Santa Barbara</option>
                <option value="Santa Cruz">Santa Cruz</option>
                <option value="Shasta">Shasta</option>
                <option value="Sierra">Sierra</option>
                <option value="Siskiyou">Siskiyou</option>
                <option value="Solano">Solano</option>
                <option value="Sonoma">Sonoma</option>
                <option value="Sutter">Sutter</option>
                <option value="Trinity">Trinity</option>
                <option value="Yolo">Yolo</option>
                <option value="Yuba">Yuba</option>
            </select>			
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="APN_1" class="reqtext pr-2 py-2">*APN:</label>
            <div class="d-flex">
              <input type="text" id="APN_1" formControlName="APN_1" class="form-control" size="3" maxlength="3" required>
              <label for="APN_2">&nbsp;-&nbsp;</label>
              <input type="text" id="APN_2" formControlName="APN_2" class="form-control" size="3" maxlength="3" required>
              <label for="APN_3">&nbsp;-&nbsp;</label>
              <input type="text" id="APN_3" formControlName="APN_3" class="form-control" size="3" maxlength="3" required>
            </div>
          </div>
        </div>
  
  
        <div class="row fixcheck form-inline">
          <div class="form-check col-12 justify-content-end">
            <label class="form-check-label nonreqtext p-2">
            <input type="checkbox" id="New_Construction" formControlName="New_Construction" class="form-check-input">New / Under Contruction / NA</label>
          </div>
        </div>
  
  
        <!---->
        <h6 class="subtitle p-1">Address</h6>
        <input type="text" formControlName="FULL_ADDRESS" class="d-none form-control">
        <!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Number" class= "reqtext pr-2 py-2">*Street # - Modifier:</label>
            <div class="d-flex">
              <input type="text" id="Street_Number" formControlName="Street_Number" class="form-control" size="6" maxlength="6" required>
              <label for="Street_Number_Modifier">-</label>
              <input type="text" id="Street_Number_Modifier" formControlName="Street_Number_Modifier" class="form-control" size="6" maxlength="8">
            </div>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Direction" class="nonreqtext pr-2 py-2">Direction:</label>
            <select id="Street_Direction" formControlName="Street_Direction" class="form-control col-md-6 col-lg-8">
              <option value="" selected="">  </option>
              <option value="E"> East </option>
              <option value="N"> North </option>
              <option value="NE"> Northeast </option>
              <option value="NW"> Northwest </option>
              <option value="S"> South </option>
              <option value="SE"> Southeast </option>
              <option value="SW"> Southwest </option>
              <option value="W"> West </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Address" class= "reqtext pr-2 py-2">*Street Name:</label>
            <input type="text" id="Street_Address" formControlName="Street_Address" class="form-control col-sm-8" size="" maxlength="30" required> 
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Street_Suffix" class="nonreqtext pr-2 py-2">Suffix:</label>
            <select id="Street_Suffix"  formControlName="Street_Suffix" class="form-control col-md-6 col-lg-8">
                <option value="" selected=""> </option>
                <option value="Ave"> Avenue </option>
                <option value="Blvd"> Boulevard </option>
                <option value="Cir"> Circle </option>
                <option value="Ct"> Court </option>
                <option value="Dr"> Drive </option>
                <option value="Jct"> Junction </option>
                <option value="Ln"> Lane </option>
                <option value="Pass"> Pass </option>
                <option value="Pkwy"> Parkway </option>
                <option value="Pl"> Place </option>
                <option value="Rd"> Road </option>
                <option value="Sqr"> Square </option>
                <option value="St"> Street </option>
                <option value="Way"> Way </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 offset-md-6 justify-content-between">
            <label for="Post_Direction" class="nonreqtext pr-2 py-2">Post Direction:</label>
            <select id="Post_Direction" formControlName="Post_Direction" class="form-control col-md-6 col-lg-8">
                <option value="" selected="">  </option>
                <option value="E"> East </option>
                <option value="N"> North </option>
                <option value="NE"> Northeast </option>
                <option value="NW"> Northwest </option>
                <option value="S"> South </option>
                <option value="SE"> Southeast </option>
                <option value="SW"> Southwest </option>
                <option value="W"> West </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Unit_Number" class="nonreqtext pr-2 py-2">Unit #:</label>
            <input type="text" id="Unit_Number" formControlName="Unit_Number" class="form-control col-md-8" size="6" maxlength="5">
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="City_Fill_In" class= "reqtext pr-2 py-2">*City:</label>
            <input type="text" id="City_Fill_In" formControlName="City_Fill_In" size="16" maxlength="20" class="form-control col-sm-8" required>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Zip_Code" class="reqtext pr-2 py-2">*Zip Code:</label>
            <div class="d-flex">
              <input type="text" id="Zip_Code" formControlName="Zip_Code" class="form-control" size="5" maxlength="5" required>
              <label for="Zip_Plus_4">
              -</label>
                <input type="text" id="Zip_Plus_4" formControlName="Zip_Plus_4" class="form-control" size="4" maxlength="4">
            </div>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Cross_Street" class="reqtext pr-2 py-2">
            *Cross Street:</label>
              <input type="text" id="Cross_Street" formControlName="Cross_Street" class="form-control col-sm-8" size="16" maxlength="30" required>
          </div>
          </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="txtOfficeAuto" class= "reqtext pr-2 py-2">
            *Office ID:</label>
            <select id="Office_ID" formControlName="Office_ID" class="form-control col-sm-8" required>
                <option value=""> </option>
                <option value="630">630-Fresno</option>
                <option value="63004">63004-Clovis</option>
                <option value="63005">63005-Commercial</option>
                <option value="63006">63006-Oakhurst2</option>
            </select>
            <input type="text" id="txtOfficeAuto" formControlName="Office_MLS_ID" class="d-none form-control col-sm-8" size="40" maxlength="50" required>
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Agent_MLS_ID" class= "reqtext pr-2 py-2">
            *Agent ID:</label>
              <input type="text" id="Agent_MLS_ID" formControlName="Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50" required>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Co_Office_MLS_ID" class= "nonreqtext pr-2 py-2">
            Co-Office ID:</label>
              <input type="text" id="Co_Office_MLS_ID" formControlName="Co_Office_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50">
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Co_Agent_MLS_ID" class= "nonreqtext pr-2 py-2">
            Co-Agent ID:</label>
              <input type="text" id="Co_Agent_MLS_ID" formControlName="Co_Agent_MLS_ID" class="form-control col-sm-8" size="40" maxlength="50">
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="row pb-2">
          <h6 class="col reqtext">*Property Subtypes (select all that apply):</h6>
        </div>
  
  
        <div class="row form-inline">
  
          <div class="form-group col-md-4 justify-content-between">
            <label for="Prop_Subtype_0001" class= "reqtext pr-2 py-2">*Primary:</label>
            <select id="Prop_Subtype_0001" formControlName="Prop_Subtype_0001" class="form-control">
              <option value=""></option>
              <option value="SFRS">Single Family</option>
              <option value="MULT">Apartment</option>
              <option value="PUDC">PUD</option>
                          <option value="RETL">Retail</option>
                          <option value="COMM">Commercial</option>
              <option value="INDU">Industrial</option>
              <option value="AGRI">Agricultural</option>
                          <option value="MNFG">Manufacturing</option>
            </select>
          </div>
  
          <div class="form-group col-md-4 justify-content-between">
            <label for="Prop_Subtype_0002" class= "nonreqtext pr-2 py-2">Secondary:</label>
            <select id="Prop_Subtype_0002" formControlName="Prop_Subtype_0002" class="form-control">
              <option value=""></option>
              <option value="SFRS">Single Family</option>
              <option value="MULT">Apartment</option>
              <option value="PUDC">PUD</option>
              <option value="RETL">Retail</option>
              <option value="COMM">Commercial</option>
              <option value="INDU">Industrial</option>
              <option value="AGRI">Agricultural</option>
              <option value="MNFG">Manufacturing</option>
            </select>
          </div>
  
          <div class="form-group col-md-4 justify-content-between">
            <label for="Prop_Subtype_0003" class= "nonreqtext pr-2 py-2">Other:</label>
            <select id="Prop_Subtype_0003" formControlName="Prop_Subtype_0003" class="form-control">
              <option value=""></option>
              <option value="SFRS">Single Family</option>
              <option value="MULT">Apartment</option>
              <option value="PUDC">PUD</option>
              <option value="RETL">Retail</option>
              <option value="COMM">Commercial</option>
              <option value="INDU">Industrial</option>
              <option value="AGRI">Agricultural</option>
              <option value="MNFG">Manufacturing</option>
            </select>
          </div>
  
        </div>
          
  
        <div class="row form-inline">
          <div class="form-group col-md-4 justify-content-between">
            <label for="Transaction" class= "reqtext pr-2 py-2">*Transaction Type:</label>
            <select id="Transaction" formControlName="Transaction" class="form-control">
                <option value="S" selected="">Sale</option>
                <option value="L">Lease</option>
            </select>
          </div>
          <div class="form-group col-md-4 justify-content-between">
            <label for="CB_SSLE" class= "reqtext pr-2 py-2">*Short Sale:</label>
            <select id="CB_SSLE" formControlName="SSLE" class="form-control" required>
                <option value=""> </option>
                <option value="YESS">Yes</option>
                <option value="NONO">No</option>
            </select>
          </div>
          <div class="form-group col-md-4 justify-content-between">
            <label for="CB_REBK" class= "reqtext pr-2 py-2">*REO/Bank Owned:</label>
            <select id="CB_REBK" formControlName="REBK" class="form-control" required>
                <option value=""> </option>
                <option value="YESS">Yes</option>
                <option value="NONO">No</option>
            </select>
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
        <ng-container *ngIf="form.get('Transaction').value == 'L'">
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Lease_Price" class= "reqtext pr-2 pb-2 ">*Lease (acre):</label>
            <input type="text" id="Lease_Price" formControlName="Lease_Price" class="form-control col-md-8" size="10" maxlength="10" required>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Lease_Type" class="reqtext pr-2 py-2">*Lease Type:</label>
            <select id="Lease_Type"  formControlName="Lease_Type" class="form-control col-md-8" required>
                <option value="" selected="">  </option>
                <option value="G"> Gross </option>
                <option value="N"> Net </option>
                <option value="O"> Other </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Sub_Lease" class="nonreqtext pr-2 py-2">Sub-Lease:</label>
            <select id="Sub_Lease" formControlName="Sub_Lease" class="form-control">
                <option value="" selected=""> </option>
                <option value="Y"> Yes </option>
                            <option value="N"> No </option>
            </select>
            <label for="Available_Date" class="nonreqtext pr-2 py-2">Available Date:</label>
            <input type="text" id="Available_Date" formControlName="Available_Date" class="form-control" size="10" maxlength="8" value="">
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Lease_Expiration" class="nonreqtext pr-2 py-2">Sub-Lease Expiration:</label>
            <input type="text" id="Lease_Expiration" formControlName="Lease_Expiration" class="form-control" size="9" maxlength="8" value="">
          </div>
        </div>
  
        </ng-container>
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="d-flex row form-inline">
          <div class="col-sm-6">
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="CB_APNS" class= "nonreqtext pr-2 py-2">Additional APNs:</label>
              </div>
              <div class="form-group col-sm-4">
                <select id="CB_APNS" formControlName="APNS" class="form-control">
                <option value=" "></option>
                <option class="mBlackText" value="YESS">Yes</option>
                <option class="mBlackText" value="NONO">No</option>
                </select>			
              </div>
            </div>
          </div>
        </div>
  
  
        <div class="d-flex form-inline row">
  
          <!-- Left Column-->
          <div class="col-md-6 align-self-start">
  
  
            <!-- One row for an input -->
            <div class="row">
                
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="0100APN2" class="nonreqtext pr-2 py-2 col-form-label">APN #2:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0100APN2" formControlName="0100APN2" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
            <!-- One row for an input -->
            <div class="row">
                
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="0110APN3" class="nonreqtext pr-2 py-2 col-form-label">APN #3:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0110APN3" formControlName="0110APN3" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
          <!--End of left column-->
          </div>
  
  
          <div class="col-md-6 align-self-start">
          <!--Right Column-->
  
  
            <!-- One row for an input -->
            <div class="row">
                
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="0120APN4" class="nonreqtext pr-2 py-2 col-form-label">APN #4:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0120APN4" formControlName="0120APN4" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="0130APN5" class="nonreqtext pr-2 py-2 col-form-label">APN #5:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0130APN5" formControlName="0130APN5" class="form-control flex-grow-1" size="" maxlength="20" value="">
              </div>
            </div>
  
  
          <!--End of right column-->
          </div>
  
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-md-5 justify-content-between">
            <label for="Listing_Price_1" class= "reqtext pr-2 py-2">Listing Price:</label>
            <div class="d-flex">
              <input type="text" id="Listing_Price_1" formControlName="Listing_Price_1" class="form-control" size="6" maxlength="6" value="" required>
              <label for="Listing_Price_2">&nbsp;,&nbsp;</label>
              <input type="text" id="Listing_Price_2" formControlName="Listing_Price_2" class="form-control" size="3" maxlength="3" value="000">
            </div>
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Agreement_Type_ComboBox" class="reqtext pr-2 py-2">*Agreement Type:</label>
            <select id="Agreement_Type_ComboBox" formControlName="Agreement_Type_ComboBox" class="form-control col-sm-5">
                <option class="mBlackText" value="" selected=""> </option>
                <option class="mBlackText" value="ERTS"> Excl. Right to Sell </option>
                <option class="mBlackText" value="EXAG"> Exclusive Agency </option>
                <option class="mBlackText" value="OPEN"> Open </option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="CB_SCOP" class="nonreqtext pr-2 py-2">Scope of Service:</label>
            <select id="CB_SCOP" formControlName="SCOP" class="form-control col-sm-5">
                <option value=" "> </option>
                <option class="mBlackText" value="ENTY">Entry Only </option>
                <option class="mBlackText" value="LMTD">Limited Service </option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="0235SCOP" formControlName="0235SCOP" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="CB_DUAL" class="nonreqtext pr-2 py-2">Dual Agency:</label>
            <select id="CB_DUAL" formControlName="DUAL" class="form-control col-sm-3">
              <option value=""> </option>
              <option class="mBlackText" value="YES">Yes</option>
              <option class="mBlackText" value="NO">No</option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-end">
            <input type="text" id="0240DUAL" formControlName="0240DUAL" class="form-control col-sm-10" size="40" maxlength="40" value="" placeholder="comment...">
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Publish_To_Internet" class="nonreqtext pr-2 py-2">Public Viewable:</label>
            <select id="Publish_To_Internet" formControlName="Publish_To_Internet" class="form-control px-sm-0 px-md-3 col-sm-3">
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Show_AVM_On_VOW" class="reqtext pr-2 py-2">*Show AVM on VOW:</label>
            <select id="Show_AVM_On_VOW" formControlName="Show_AVM_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" required>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Show_Address_to_Public" class="reqtext pr-2 py-2">*Show Addr to Public:</label>
            <select id="Show_Address_to_Public" formControlName="Show_Address_to_Public" class="form-control px-sm-0 px-md-3 col-sm-3" required>
                <option value="yes" selected="">Yes</option>
                <option value="no">No</option>
            </select>
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="Show_Comments_On_VOW" class="reqtext pr-2 py-2">*Show Cmnts on VOW:</label>
            <select id="Show_Comments_On_VOW" formControlName="Show_Comments_On_VOW" class="form-control px-sm-0 px-md-3 col-sm-3" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
  
        <!--Begin Section-->
        <div class="d-flex form-inline row">
  
          <!--Begin column-->
          <div class="col-lg-5">
  
            <!--entries-->
            <div class="row">
  
              <div class="form-group col-sm-6">
                <label for="CB_EOFF" class="reqtext pr-2 py-2 col-form-label">*Email Offers:</label>
              </div>
  
              <div class="form-group col-sm-4 offset-sm-2 pr-xs-0">
                <select id="CB_EOFF" formControlName="EOFF" class="form-control col-sm-8" required>
                  <option value=""></option>
                  <option class="mBlackText" value="YESY"> Yes </option>
                  <option class="mBlackText" value="NONO"> No </option>
                </select>
              </div>
  
            </div>
  
          </div>
  
  
          <!--Begin column-->
          <div class="col-lg-7">
            <!--entries-->
            <div class="row">
  
              <div class="form-group col-sm-3 justify-content-end">
                <label for="0380EOAD" class="reqtext pr-2 py-2 col-form-label">*Email:</label>
              </div>
  
              <div class="form-group col-sm-9 justify-content-between">
                <input type="email" id="0380EOAD" formControlName="0380EOAD" class="form-control col-sm-12" rows="1" cols="40" maxlength="60">
              </div>
  
            </div>
  
          </div>
  
        </div> <!--end of section-->
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
        <!---->
        <h6 class="subtitle pr-2 py-2">Property Information</h6>
        <!---->
  
        <div class="d-flex form-inline row">
  
          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="Lot_Sq_Ft" class= "reqtext pr-2 py-2">Lot Size:</label>
              </div>
              <div class="form-group col-sm-4">
                <input type="text" id="Lot_Sq_Ft" formControlName="Lot_Sq_Ft" class="form-control col-sm-10" size="12" maxlength="9" value="" required>
              </div>
              <div class="d-flex form-group col-sm-4">
                <label for="Lot_Measurement" class="nonreqtext px-1">
                  <input type="radio" id="Lot_Measurement" formControlName="Lot_Measurement" class="form-control" value="S" checked="">&nbsp;SqFt
                </label>
                <label for="Lot_Measurement_Acres" class="nonreqtext px-1">
                  <input type="radio" id="Lot_Measurement_Acres" formControlName="Lot_Measurement" class="form-control" value="A">&nbsp;Acres
                </label>
              </div>
            </div>
  
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="0410ZONE" class="nonreqtext pr-2 py-2 col-form-label">Zoning:</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0410ZONE" formControlName="0410ZONE" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
  
          </div> <!--end of left column-->
  
          <!--Right Column-->
          <div class="col-lg-6 align-self-start">
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="selLotSizeSource" class="reqtext pr-2 py-2">Lot Size Source:</label>
              </div>
              <div class="form-group col-sm-4">
                <select id="selLotSizeSource" formControlName="LotSize_Source" class="form-control col-sm-10" required>
                  <option value="" selected="">  </option>
                  <option value="APPR"> Appraiser </option>
                  <option value="OTHR"> Other </option>
                  <option value="SLLR"> Seller </option>
                  <option value="TAXR"> Tax Records </option>
                </select>
              </div>
            </div>
          </div> <!--end of right column-->
  
        </div> <!--end of page/section-->
  
        <!----><div><hr class="HRstyle"></div><!---->
  
        <!---->
        <h6 class="subtitle pr-1 py-2">Listing Period:</h6>
        <!---->
  
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Listing_Date" class="reqtext pr-2 py-2">*Listing Date:</label>
            <input type="text" id="Listing_Date" formControlName="Listing_Date" class="form-control col-sm-3" size="8" maxlength="8" value="" required>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Expiration_Date" class="nonreqtext pr-2 py-2">Expiration Date:</label>
            <input type="text" id="Expiration_Date" formControlName="Expiration_Date" class="form-control col-sm-3" size="8" maxlength="8" value="">						
          </div>
        </div>
  
        <br>
  
        <div class="row form-inline">
          <div class="form-group col-md-6 justify-content-between">
            <label for="Owner_Name" class= "nonreqtext pr-2 py-2">Owner Name:</label>
            <input type="text" id="Owner_Name" formControlName="Owner_Name" class="form-control col-sm-8" size="30" maxlength="50" value="">
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Owner_Phone_Area" class="nonreqtext pr-2 py-2">Owner Phone #:</label>
            <div class="d-flex">
              <input type="text" id="Owner_Phone_Area" formControlName="Owner_Phone_Area" class="form-control" size="3" maxlength="3" value="">
              <label for="Owner_Phone_Prefix">&nbsp;-&nbsp;</label>
              <input type="text" id="Owner_Phone_Prefix" formControlName="Owner_Phone_Prefix" class="form-control" size="3" maxlength="3" value="">
              <label for="Owner_Phone_Suffix">&nbsp;-&nbsp;</label>
              <input type="text" id="Owner_Phone_Suffix" formControlName="Owner_Phone_Suffix" class="form-control" size="4" maxlength="4" value="">
            </div>
          </div>
        </div>
  
        <div class="row form-inline">
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Showing_Instructions" class= "nonreqtext pr-2 py-2">Showing Instructions:&nbsp;<span class="formnote">(Maximum 60)</span></label>
            <textarea id="Showing_Instructions" formControlName="Showing_Instructions" class="form-control col-sm-12 flex-grow-1" rows="" spellcheck="true" cols="50" maxlength="60"></textarea>
          </div>
  
          <div class="form-group col-md-6 justify-content-between">
            <label for="Phone_to_Show_Area" class="nonreqtext pr-2 py-2">Phone to Show:</label>
            <div class="d-flex">
              <input type="text" id="Phone_to_Show_Area" formControlName="Phone_to_Show_Area" class="form-control" size="3" maxlength="3" value="">
              <label for="Phone_to_Show_Prefix">&nbsp;-&nbsp;</label>
              <input type="text" id="Phone_to_Show_Prefix" formControlName="Phone_to_Show_Prefix" class="form-control" size="3" maxlength="3" value="">
              <label for="Phone_to_Show_Suffix">&nbsp;-&nbsp;</label>
              <input type="text" id="Phone_to_Show_Suffix" formControlName="Phone_to_Show_Suffix" class="form-control" size="4" maxlength="4" value="">
            </div>
          </div>
        </div>
  
  
        <!----><div><hr class="HRstyle"></div><!---->
  
  
        <br>
  
  
        <div class="row">
          <div class="col">
            <h6><label for="Property_Description"><span class="subtitle pr-1">Marketing Remarks:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 1500)</span></h6>
              <textarea id="Property_Description" formControlName="Property_Description" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1500"></textarea>
          </div>
        </div>
  
  
        <br>
  
  
        <div class="row">
          <div class="col">
            <h6><label for="Agent_Only_Remarks"><span class="subtitle pr-1">Agent Remarks:</span></label><span class="nonreqtext formnote">&nbsp;(Maximum 1000)</span></h6>
              <textarea id="Agent_Only_Remarks" formControlName="Agent_Only_Remarks" class="form-control" spellcheck="true" rows="6" cols="" maxlength="1000"></textarea>
          </div>
        </div>
  
  
        <br>
  
  
        <div class="row">
          <div class="col">
            <h6><label for="Directions"><span class="reqsubtitle pr-1">*Directions to Property:</span></label><span class="reqtext formnote">&nbsp;(Maximum 250)</span></h6>
              <textarea id="Directions" formControlName="Directions" class="form-control" spellcheck="true" rows="4" cols=""  maxlength="250" required></textarea>
          </div>
        </div>
  
  
        <br>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="School_District_Fill_In" class= "reqtext pr-2 py-2">*School District:</label>
            <input type="text" id="School_District_Fill_In" formControlName="School_District_Fill_In" class="form-control col-sm-8" size="20" maxlength="20" value="" required>
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="School_Code_1" class= "reqtext pr-2 py-2">*Grade School:</label>
            <input type="text" id="School_Code_1" formControlName="School_Code_1" class="form-control col-sm-8" size="10" maxlength="20" value="" required>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="School_Code_2" class= "reqtext pr-2 py-2">*Jr High School :</label>
            <input type="text" id="School_Code_2" formControlName="School_Code_2" class="form-control col-sm-8" size="20" maxlength="20" value="" required>
          </div>
  
          <div class="form-group col-sm-6 justify-content-between">
            <label for="School_Code_3" class= "reqtext pr-2 py-2">*Sr High School:</label>
            <input type="text" id="School_Code_3" formControlName="School_Code_3" class="form-control col-sm-8" size="20" maxlength="20" value="" required>
          </div>
        </div>
  
  
        <div class="row form-inline">
          <div class="form-group col-sm-6 justify-content-between">
            <label for="0570SCMT" class= "nonreqtext pr-1 py-1">Comments:<span class="formnote">&nbsp;(Maximum 60)&nbsp;</span></label>
            <textarea id="0570SCMT" formControlName="0570SCMT" class="form-control col-sm-8" spellcheck="true" rows="2" cols="" maxlength="60"></textarea>
          </div>
        </div>
  
        <br>
      </div>
      <div id="General Information Form">
        <div class="d-flex form-inline row">


          <!-- Left Column-->
  
  
          <div class="col-lg-6 align-self-start">
            
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="NUM_CNSS" class= "nonreqtext pr-2 py-2 col-form-label">Census Tract:</label>
                <input type="text" id="NUM_CNSS" formControlName="CNSS" class="form-control col-sm-5" size="" maxlength="6">
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="0650CNSS" formControlName="0650CNSS" class="form-control flex-grow-1" size="" maxlength="40" placeholder="comment...">
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="CB_AREA" class="reqtext pr-2 py-2 col-form-label">*Region:</label>
                <select id="CB_AREA" formControlName="AREA" class="form-control col-sm-5" required>
                  <option value=""></option>
                  <option class="mBlackText" value="URBN">Urban </option>
                  <option class="mBlackText" value="SUBU">Rural </option>
                  <option class="mBlackText" value="FTHL">Foothill </option>
                  <option class="mBlackText" value="MNTN">Mountain </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <input type="text" id="0660AREA" formControlName="0660AREA" class="form-control flex-grow-1" size="" maxlength="40">
              </div>
            </div>
  
  
            <div class="row">
              <div class="form-group col-sm-6 justify-content-between">
                <label for="Area_Fill_In" class="reqtext pr-2 py-2 col-form-label">*Area:</label>
                <input type="text" id="Area_Fill_In" formControlName="Area_Fill_In" class="form-control col-sm-5" size="" maxlength="60" required>
              </div>
            </div>
  
  
            <div class="row form-group col-sm-12">
              <span class="reqtext pr-2 py-2 formnote">AREA refers to the last 3 digits of the property zipcode. Example: 93711. AREA = 711.</span>
            </div>
            
  
            <!--break for next group in the left column-->
            <br>
  
            <div formGroupName="LANDLOTDESC" required>
              <span class="row col"><span class="pr-2 py-2 reqtext">*Land/Lot Description:</span></span>
    
              <!-- One row for an input -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="LOTDIRRE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDIRRE" formControlName="LOTDIRRE" class="form-check-input">Irregular</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDIRRE" formControlName="Checkbox_Comment_LOTDIRRE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOTDLESS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDLESS" formControlName="LOTDLESS" class="form-check-input">Less than 1 Acre</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDLESS" formControlName="Checkbox_Comment_LOTDLESS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOTDLEVL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDLEVL" formControlName="LOTDLEVL" class="form-check-input">Level</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDLEVL" formControlName="Checkbox_Comment_LOTDLEVL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOTDROLL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDROLL" formControlName="LOTDROLL" class="form-check-input">Rolling</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDROLL" formControlName="Checkbox_Comment_LOTDROLL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOTDHILL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDHILL" formControlName="LOTDHILL" class="form-check-input">Hillside</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDHILL" formControlName="Checkbox_Comment_LOTDHILL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOTDELEV" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDELEV" formControlName="LOTDELEV" class="form-check-input">Elevated</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDELEV" formControlName="Checkbox_Comment_LOTDELEV" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOTDAC15" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDAC15" formControlName="LOTDAC15" class="form-check-input">1 - 5 Acres</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDAC15" formControlName="Checkbox_Comment_LOTDAC15" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="LOTDMOR5" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="LOTDMOR5" formControlName="LOTDMOR5" class="form-check-input">More Than 5 Acres</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_LOTDMOR5" formControlName="Checkbox_Comment_LOTDMOR5" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
            </div>
            <!--break for next group in the left column-->
            <br>
  
            <div formGroupName="SEWERWATER" required>
              <span class="row col"><span class="pr-2 py-2 reqtext">*Sewer/Water:</span></span>
    
    
              <!-- One row for an input -->
              <div class="row fixcheck">
                
                <!-- Within that row, specify column length  -->
                <div class="form-group col-sm-6">
                  <label for="WATRPWTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRPWTR" formControlName="WATRPWTR" class="form-check-input">Public Water</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRPWTR" formControlName="Checkbox_Comment_WATRPWTR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRPWEL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRPWEL" formControlName="WATRPWEL" class="form-check-input">Public Sewer</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRPWEL" formControlName="Checkbox_Comment_WATRPWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRDWTR" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRDWTR" formControlName="WATRDWTR" class="form-check-input">Domestic Water</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRDWTR" formControlName="Checkbox_Comment_WATRDWTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRSWEL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRSWEL" formControlName="WATRSWEL" class="form-check-input">Shared Well</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRSWEL" formControlName="Checkbox_Comment_WATRSWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRSEPT" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRSEPT" formControlName="WATRSEPT" class="form-check-input">Septic Tank</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRSEPT" formControlName="Checkbox_Comment_WATRSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRCWEL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRCWEL" formControlName="WATRCWEL" class="form-check-input">Community Well</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRCWEL" formControlName="Checkbox_Comment_WATRCWEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRCSEW" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRCSEW" formControlName="WATRCSEW" class="form-check-input">Community Sewer</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRCSEW" formControlName="Checkbox_Comment_WATRCSEW" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRNONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRNONE" formControlName="WATRNONE" class="form-check-input">None</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRNONE" formControlName="Checkbox_Comment_WATRNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="WATRPWLL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="WATRPWLL" formControlName="WATRPWLL" class="form-check-input">Private Well</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_WATRPWLL" formControlName="Checkbox_Comment_WATRPWLL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
            <!--End of left column-->
            </div>
          </div>
  
          <!--Right Column-->
  
          <div class="col-lg-6 align-self-start">
  
            <div formGroupName="GASELECTRIC" required>
              <span class="row col"><span class="pr-2 py-2 reqtext">*Gas/Electric:</span></span>
    
    
              <!--First row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="GSECPROP" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="GSECPROP" formControlName="GSECPROP" class="form-check-input">Propane</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_GSECPROP" formControlName="Checkbox_Comment_GSECPROP" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="GSECNOGS" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="GSECNOGS" formControlName="GSECNOGS" class="form-check-input">No Gas</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_GSECNOGS" formControlName="Checkbox_Comment_GSECNOGS" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="GSECPUBU" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="GSECPUBU" formControlName="GSECPUBU" class="form-check-input">Public Utilities</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_GSECPUBU" formControlName="Checkbox_Comment_GSECPUBU" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="GSECNOEL" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="GSECNOEL" formControlName="GSECNOEL" class="form-check-input">No Electricity</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_GSECNOEL" formControlName="Checkbox_Comment_GSECNOEL" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
    
    
              <!--next row-->
              <div class="row fixcheck">
                <div class="form-group col-sm-6">
                  <label for="GSECNONE" class="form-check-label nonreqtext p-2">
                  <input type="checkbox" id="GSECNONE" formControlName="GSECNONE" class="form-check-input">None</label>
                </div>
                <div class="form-group col-sm-6 justify-content-end">
                  <input type="text" id="Checkbox_Comment_GSECNONE" formControlName="Checkbox_Comment_GSECNONE" class="form-control flex-grow-1" size="" maxlength="40" value="">
                </div>
              </div>
            </div>
  
            <!--break for next group in the right column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Special Disclosure:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="SPECFLOD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECFLOD" formControlName="SPECFLOD" class="form-check-input">Flood Zone</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECFLOD" formControlName="Checkbox_Comment_SPECFLOD" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECTOXI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECTOXI" formControlName="SPECTOXI" class="form-check-input">Toxic Waste Area</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECTOXI" formControlName="Checkbox_Comment_SPECTOXI" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECPUBL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECPUBL" formControlName="SPECPUBL" class="form-check-input">Public Report</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECPUBL" formControlName="Checkbox_Comment_SPECPUBL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECSPSA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECSPSA" formControlName="SPECSPSA" class="form-check-input">Special Studies Area</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECSPSA" formControlName="Checkbox_Comment_SPECSPSA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECSPFE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECSPFE" formControlName="SPECSPFE" class="form-check-input">Special Fees</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECSPFE" formControlName="Checkbox_Comment_SPECSPFE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECFLIT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECFLIT" formControlName="SPECFLIT" class="form-check-input">Flight Path</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECFLIT" formControlName="Checkbox_Comment_SPECFLIT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SPECSEER" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SPECSEER" formControlName="SPECSEER" class="form-check-input">See Remrks/Attchmnts</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SPECSEER" formControlName="Checkbox_Comment_SPECSEER" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Documents Available:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="IRPTHOME" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTHOME" formControlName="IRPTHOME" class="form-check-input">Plot Map</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTHOME" formControlName="Checkbox_Comment_IRPTHOME" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTROOF" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTROOF" formControlName="IRPTROOF" class="form-check-input">Legal Description</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTROOF" formControlName="Checkbox_Comment_IRPTROOF" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTPEST" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTPEST" formControlName="IRPTPEST" class="form-check-input">Deed Restriction</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTPEST" formControlName="Checkbox_Comment_IRPTPEST" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTSEPT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTSEPT" formControlName="IRPTSEPT" class="form-check-input">C.C. & R's</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTSEPT" formControlName="Checkbox_Comment_IRPTSEPT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTWELR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTWELR" formControlName="IRPTWELR" class="form-check-input">Zoning Restriction</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTWELR" formControlName="Checkbox_Comment_IRPTWELR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTWTRP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTWTRP" formControlName="IRPTWTRP" class="form-check-input">Building Restriction</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTWTRP" formControlName="Checkbox_Comment_IRPTWTRP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTCC&R" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTCC&R" formControlName="IRPTCC&R" class="form-check-input">Survey</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTCC&R" formControlName="Checkbox_Comment_IRPTCC&R" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTRULE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTRULE" formControlName="IRPTRULE" class="form-check-input">Soils Report</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTRULE" formControlName="Checkbox_Comment_IRPTRULE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTBYLW" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTBYLW" formControlName="IRPTBYLW" class="form-check-input">Percolation Report</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTBYLW" formControlName="Checkbox_Comment_IRPTBYLW" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTBDGT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTBDGT" formControlName="IRPTBDGT" class="form-check-input">Aerial Photograph</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTBDGT" formControlName="Checkbox_Comment_IRPTBDGT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTFINC" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTFINC" formControlName="IRPTFINC" class="form-check-input">Topographical Map</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTFINC" formControlName="Checkbox_Comment_IRPTFINC" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IRPTOTHR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IRPTOTHR" formControlName="IRPTOTHR" class="form-check-input">Appraisal</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IRPTOTHR" formControlName="Checkbox_Comment_IRPTOTHR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
          <br>
  
          <!--End of right column-->
          </div>				
        </div>
      </div>
      <div id="Property Features Form">
        <div class="d-flex form-inline row pt-1">


          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Potential Use:&nbsp;<span class="nonreqtext formnote">(Up to 10 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="POTERESI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTERESI" formControlName="POTERESI" class="form-check-input">Residential</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTERESI" formControlName="Checkbox_Comment_POTERESI" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTEMULT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTEMULT" formControlName="POTEMULT" class="form-check-input">Multi Family</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="POTEMULT" formControlName="POTEMULT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTECONP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTECONP" formControlName="POTECONP" class="form-check-input">Condo/PUD</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTECONP" formControlName="Checkbox_Comment_POTECONP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTECOMM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTECOMM" formControlName="POTECOMM" class="form-check-input">Commercial</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTECOMM" formControlName="Checkbox_Comment_POTECOMM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTEINDU" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTEINDU" formControlName="POTEINDU" class="form-check-input">Industrial</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTEINDU" formControlName="Checkbox_Comment_POTEINDU" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTEMANU" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTEMANU" formControlName="POTEMANU" class="form-check-input">Manufacturing</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTEMANU" formControlName="Checkbox_Comment_POTEMANU" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTEOFFI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTEOFFI" formControlName="POTEOFFI" class="form-check-input">Office</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTEOFFI" formControlName="Checkbox_Comment_POTEOFFI" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTERETA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTERETA" formControlName="POTERETA" class="form-check-input">Retail</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTERETA" formControlName="Checkbox_Comment_POTERETA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTEMOHO" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTEMOHO" formControlName="POTEMOHO" class="form-check-input">Motel/Hotel</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTEMOHO" formControlName="Checkbox_Comment_POTEMOHO" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTEMOBL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTEMOBL" formControlName="POTEMOBL" class="form-check-input">Mobile Home Park</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTEMOBL" formControlName="Checkbox_Comment_POTEMOBL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="POTEHORS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="POTEHORS" formControlName="POTEHORS" class="form-check-input">Horses Allowed</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_POTEHORS" formControlName="Checkbox_Comment_POTEHORS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--break for next group in the left column-->
            <br>
            
            <div formGroupName="IMPROVEMENTS" required>
            <span class="row col"><span class="pr-2 py-2 reqtext">*Improvements:</span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="IMPRNONE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IMPRNONE" formControlName="IMPRNONE" class="form-check-input">None</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IMPRNONE" formControlName="Checkbox_Comment_IMPRNONE" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IMPRSTRM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IMPRSTRM" formControlName="IMPRSTRM" class="form-check-input">Storm Drain</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IMPRSTRM" formControlName="Checkbox_Comment_IMPRSTRM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IMPRSTRL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IMPRSTRL" formControlName="IMPRSTRL" class="form-check-input">Street Lights</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IMPRSTRL" formControlName="Checkbox_Comment_IMPRSTRL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IMPRSIDE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IMPRSIDE" formControlName="IMPRSIDE" class="form-check-input">Sidewalks</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IMPRSIDE" formControlName="Checkbox_Comment_IMPRSIDE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="IMPRCURB" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="IMPRCURB" formControlName="IMPRCURB" class="form-check-input">Curbs/Gutters</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_IMPRCURB" formControlName="Checkbox_Comment_IMPRCURB" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
          <!--End of left column-->
                  </div>
                  </div>
  
                  <!--Right Column-->
  
  
          <div class="col-lg-6 align-self-start">
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Accessibility:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="TRANCITS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANCITS" formControlName="TRANCITS" class="form-check-input">City Streets</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANCITS" formControlName="Checkbox_Comment_TRANCITS" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANCNTR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANCNTR" formControlName="TRANCNTR" class="form-check-input">County Road</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANCNTR" formControlName="Checkbox_Comment_TRANCNTR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANPRIV" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANPRIV" formControlName="TRANPRIV" class="form-check-input">Private Road</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANPRIV" formControlName="Checkbox_Comment_TRANPRIV" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANSTHG" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANSTHG" formControlName="TRANSTHG" class="form-check-input">State Highway</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANSTHG" formControlName="Checkbox_Comment_TRANSTHG" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANFRWY" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANFRWY" formControlName="TRANFRWY" class="form-check-input">Freeway</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANFRWY" formControlName="Checkbox_Comment_TRANFRWY" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANEASM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANEASM" formControlName="TRANEASM" class="form-check-input">Easement Road</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANEASM" formControlName="Checkbox_Comment_TRANEASM" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANPAVE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANPAVE" formControlName="TRANPAVE" class="form-check-input">Paved Road</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANPAVE" formControlName="Checkbox_Comment_TRANPAVE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANGRVL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANGRVL" formControlName="TRANGRVL" class="form-check-input">Gravel Road</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANGRVL" formControlName="Checkbox_Comment_TRANGRVL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANRAIL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANRAIL" formControlName="TRANRAIL" class="form-check-input">Railroad Siding</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANRAIL" formControlName="Checkbox_Comment_TRANRAIL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANAIRP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANAIRP" formControlName="TRANAIRP" class="form-check-input">Airport - 1 mile</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANAIRP" formControlName="Checkbox_Comment_TRANAIRP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRAN4WDR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRAN4WDR" formControlName="TRAN4WDR" class="form-check-input">Four Wheel Drive</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRAN4WDR" formControlName="Checkbox_Comment_TRAN4WDR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TRANSEAS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TRANSEAS" formControlName="TRANSEAS" class="form-check-input">Seasonal Access</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TRANSEAS" formControlName="Checkbox_Comment_TRANSEAS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Site Amenities:&nbsp;<span class="nonreqtext formnote">(Up to 10 entries allowed)</span></span></span>
    
    
            <!-- One row for a new input group -->
            <div class="row fixcheck">
                
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="SITECRNR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITECRNR" formControlName="SITECRNR" class="form-check-input">Corner</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITECRNR" formControlName="Checkbox_Comment_SITECRNR" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITECDES" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITECDES" formControlName="SITECDES" class="form-check-input">Cul De Sac</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="SITECDES" formControlName="SITECDES" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITEBLUF" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITEBLUF" formControlName="SITEBLUF" class="form-check-input">Bluff View</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITEBLUF" formControlName="Checkbox_Comment_SITEBLUF" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITEGOLF" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITEGOLF" formControlName="SITEGOLF" class="form-check-input">Golf Course</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITEGOLF" formControlName="Checkbox_Comment_SITEGOLF" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITERIVR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITERIVR" formControlName="SITERIVR" class="form-check-input">River View</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITERIVR" formControlName="Checkbox_Comment_SITERIVR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITELAKE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITELAKE" formControlName="SITELAKE" class="form-check-input">Lake View</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITELAKE" formControlName="Checkbox_Comment_SITELAKE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITEWATR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITEWATR" formControlName="SITEWATR" class="form-check-input">Water Front</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITEWATR" formControlName="Checkbox_Comment_SITEWATR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITECREK" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITECREK" formControlName="SITECREK" class="form-check-input">Year Round Creek</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITECREK" formControlName="Checkbox_Comment_SITECREK" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITESCRK" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITESCRK" formControlName="SITESCRK" class="form-check-input">Seasonal Creek</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITESCRK" formControlName="Checkbox_Comment_SITESCRK" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITEGATE" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITEGATE" formControlName="SITEGATE" class="form-check-input">Gated</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITEGATE" formControlName="Checkbox_Comment_SITEGATE" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SITEPRKV" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SITEPRKV" formControlName="SITEPRKV" class="form-check-input">Park View</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SITEPRKV" formControlName="Checkbox_Comment_SITEPRKV" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
          <!--End of right column-->
            </div>				
          </div>
  
        <br>
      </div>
      <div id="Financial Data Form">
        <div class="d-flex form-inline row pt-1">


          <!-- Left Column-->
          <div class="col-lg-6 align-self-start">
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Sale Options:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="SALETOPO" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SALETOPO" formControlName="SALETOPO" class="form-check-input">Total Parcel Only</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SALETOPO" formControlName="Checkbox_Comment_SALETOPO" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SALESUBD" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SALESUBD" formControlName="SALESUBD" class="form-check-input">Subdivided In Escrow</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SALESUBD" formControlName="Checkbox_Comment_SALESUBD" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SALEBTOS" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SALEBTOS" formControlName="SALEBTOS" class="form-check-input">Build to Suit</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SALEBTOS" formControlName="Checkbox_Comment_SALEBTOS" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="SALEADJL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="SALEADJL" formControlName="SALEADJL" class="form-check-input">Adjacent Land Avail</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_SALEADJL" formControlName="Checkbox_Comment_SALEADJL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--break for next group in the left column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">1st Loan Type:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="1SLNASSM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="1SLNASSM" formControlName="1SLNASSM" class="form-check-input">Assumable</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_1SLNASSM" formControlName="Checkbox_Comment_1SLNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="1SLNPRVT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="1SLNPRVT" formControlName="1SLNPRVT" class="form-check-input">Private</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_1SLNPRVT" formControlName="Checkbox_Comment_1SLNPRVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="1SLNFRCL" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="1SLNFRCL" formControlName="1SLNFRCL" class="form-check-input">Free & Clear</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_1SLNFRCL" formControlName="Checkbox_Comment_1SLNFRCL" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
            <!--break for next group in the left column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">2nd Loan Type:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="2LNASSM" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="2LNASSM" formControlName="2LNASSM" class="form-check-input">Assumable</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_2LNASSM" formControlName="Checkbox_Comment_2LNASSM" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="2LNPRVT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="2LNPRVT" formControlName="2LNPRVT" class="form-check-input">Private</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_2LNPRVT" formControlName="Checkbox_Comment_2LNPRVT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
          <!--End of left column-->
                  </div>
  
                  <!--Right Column-->
                  <div class="col-lg-6 align-self-start">
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Seller Will Consider:&nbsp;<span class="nonreqtext formnote">(Up to 8 entries allowed)</span></span></span>
  
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
              
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="TERMCONV" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMCONV" formControlName="TERMCONV" class="form-check-input">Conventional</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMCONV" formControlName="Checkbox_Comment_TERMCONV" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMSUB" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMSUB" formControlName="TERMSUB" class="form-check-input">Subordinate</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMSUB" formControlName="Checkbox_Comment_TERMSUB" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMLSOP" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMLSOP" formControlName="TERMLSOP" class="form-check-input">Lease Option</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMLSOP" formControlName="Checkbox_Comment_TERMLSOP" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMCASH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMCASH" formControlName="TERMCASH" class="form-check-input">Cash</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMCASH" formControlName="Checkbox_Comment_TERMCASH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMOWNR" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMOWNR" formControlName="TERMOWNR" class="form-check-input">Owner Carry</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMOWNR" formControlName="Checkbox_Comment_TERMOWNR" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
    
    
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="TERMLIMI" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="TERMLIMI" formControlName="TERMLIMI" class="form-check-input">Limited Financing</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_TERMLIMI" formControlName="Checkbox_Comment_TERMLIMI" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--break for next group in the right column-->
            <br>
  
  
            <span class="row col"><span class="pr-2 py-2 nonreqtext">Unique Conditions:</span></span>
  
            <!-- One row for a new input group -->
            <div class="row fixcheck">
  
              <!-- Within that row, specify column length  -->
              <div class="form-group col-sm-6">
                <label for="UNIQPROB" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQPROB" formControlName="UNIQPROB" class="form-check-input">Probate</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQPROB" formControlName="Checkbox_Comment_UNIQPROB" class="form-control flex-grow-1" size="" maxlength="40" value="" placeholder="comment...">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UNIQESTA" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQESTA" formControlName="UNIQESTA" class="form-check-input">Estate Sale</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQESTA" formControlName="Checkbox_Comment_UNIQESTA" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UNIQCORT" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQCORT" formControlName="UNIQCORT" class="form-check-input">Court Approval Req.</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQCORT" formControlName="Checkbox_Comment_UNIQCORT" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--next row-->
            <div class="row fixcheck">
              <div class="form-group col-sm-6">
                <label for="UNIQEXCH" class="form-check-label nonreqtext p-2">
                <input type="checkbox" id="UNIQEXCH" formControlName="UNIQEXCH" class="form-check-input">1031 Exchange</label>
              </div>
              <div class="form-group col-sm-6 justify-content-end">
                <input type="text" id="Checkbox_Comment_UNIQEXCH" formControlName="Checkbox_Comment_UNIQEXCH" class="form-control flex-grow-1" size="" maxlength="40" value="">
              </div>
            </div>
  
  
            <!--End of right column-->
            </div>				
          </div>
      </div>
    </div>      
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="download()" [disabled]="isDownloading">
      <span *ngIf="isDownloading" class="spinner-border" role="status" aria-hidden="true"></span>
      Download as PDF
    </button>
    <button type="button" class="btn btn-default" (click)="close()">Close</button>
  </div>
</ng-container>
<ng-template #loading>
  <div class="modal-header">
    <h5 class="modal-title d-flex flex-column">
      <span>{{ formName }}</span>
    </h5>
    <button type="button" class="close" (click)="close()">&times;</button>
  </div>
  <div class="modal-body h-100">
    <div class="sk-circle sk-center">
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
      <div class="sk-circle-dot"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
  </div>
</ng-template>
