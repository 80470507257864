<sidenav [orientation]="orientation" [ngClass]="getClasses()">

  <!-- Inner -->
  <div class="sidenav-inner" [ngClass]="{ 'py-1': orientation !== 'horizontal' }">

    <ng-container *ngIf="(role$ | async) as role">
      <sidenav-router-link *ngIf="(canAccess(PERMISSIONS.UserAccess) | async)" icon="ion ion-md-home" route="./home" [active]="isActive('/home')">Home</sidenav-router-link>
      <sidenav-router-link *ngIf="(canAccess(PERMISSIONS.UserAccess) | async)" icon="ion ion-md-create" route="./fill" [active]="isActive('/fill')">New Fill</sidenav-router-link>
      <sidenav-router-link *ngIf="(canAccess(PERMISSIONS.UserAccess) | async)" icon="ion ion-md-filing" route="./history" [active]="isActive('/history')">History</sidenav-router-link>
      <sidenav-menu *ngIf="(canAccess(PERMISSIONS.AdminAccess) | async)" icon="ion ion-md-key" text="Admin">
        <sidenav-router-link *ngIf="(canAccess(PERMISSIONS.AdminAccess) | async)" icon="ion ion-md-people" route="./members" [active]="isActive('/members')">Members</sidenav-router-link>
        <sidenav-router-link *ngIf="(canAccess(PERMISSIONS.AdminAccess) | async)" icon="ion ion-md-document" route="./forms" [active]="isActive('/forms')">Forms</sidenav-router-link>
        <!-- <sidenav-router-link *ngIf="(canAccess(PERMISSIONS.OwnerAccess) | async)" icon="ion ion-md-clipboard" route="./forms" [active]="isActive('/forms')">Forms</sidenav-router-link> -->
      </sidenav-menu>
    </ng-container>

  </div>
</sidenav>
