import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { OrganizationService } from '../services/organization.service';
import { concatMap, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MemberGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const access = new Subject<boolean>();
      this.organizationService.getOrganization(next.paramMap.get('id'), 'name').pipe(
        concatMap(res => this.authService.setOrganization$(res._id)),
        tap(res => {
          if(res) {
            console.log('Access granted');
            access.next(true);
          }
        }),
        catchError(err => {
          if(err) {
            console.log('The organization does not exist, or user does not have access to it.');
            this.router.navigateByUrl('/');
            access.next(false)
            return err;
          }
        })
      ).subscribe();
      return access.asObservable();
  }
  
}
