import { Injectable } from '@angular/core';
import { Validators, ValidatorFn, FormGroup } from '@angular/forms';
import { isObject } from 'util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewResidentialComponent } from '../modals/view-residential/view-residential.component';
import { ViewCommercialComponent } from '../modals/view-commercial/view-commercial.component';
import { ViewBusinessComponent } from '../modals/view-business/view-business.component';
import { ViewLandlotsComponent } from '../modals/view-landlots/view-landlots.component';
import { ViewMultifamilyComponent } from '../modals/view-multifamily/view-multifamily.component';
import { ViewFarmComponent } from '../modals/view-farm/view-farm.component';
import { ViewSpecialRequestComponent } from '../modals/view-special-request/view-special-request.component';
import { ViewSignRequestComponent } from '../modals/view-sign-request/view-sign-request.component';

@Injectable({
  providedIn: 'root'
})
export class CustomFormsService {
  private modalComponents = {
    'Residential': ViewResidentialComponent,
    'Commercial': ViewCommercialComponent,
    'Business Opp.': ViewBusinessComponent,
    'Land/Lots': ViewLandlotsComponent,
    'Multi Family': ViewMultifamilyComponent,
    'Farm': ViewFarmComponent,
    'Special Request': ViewSpecialRequestComponent,
    'Sign Request': ViewSignRequestComponent
  }

  constructor(private ngbModal: NgbModal) { }

  public requiredIfValidator(predicate) {
    return (formControl => {
      if(!formControl.parent) {
        return null;
      }
      if(predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  public requireCheckboxesToBeCheckedValidator(predicate, minRequired = 1): ValidatorFn {
    return function validate (formGroup: FormGroup) {
      let checked = 0;
      if(predicate()) {
        Object.keys(formGroup.controls).forEach(key => {
          const control = formGroup.controls[key];
  
          if (control.value === true) {
            checked ++;
          }
        });
        if (checked < minRequired) {
          return {
            requireCheckboxesToBeChecked: true,
          };
        }
      }
      return null;
    };
  }

  sanitizeFormPayload(payload: any) {
    let temp = Object.assign({}, payload);
    Object.keys(temp).forEach(key => {
      if(!temp[key] || temp[key] == '' || temp[key] == null)
        delete temp[key];
      else if(isObject(temp[key])) {
        Object.keys(temp[key]).forEach(innerKey => {
          if(!temp[key][innerKey] || temp[key][innerKey] == '' || temp[key][innerKey] == null)
            delete temp[key][innerKey];
        })

        if(Object.entries(temp[key]).length === 0) {
          delete temp[key];
        }
      }
    });
    return temp;
  }

  public openModal(key: string = 'Residential', id: string) {
    const ref = this.ngbModal.open(this.modalComponents[key], {size: 'xl', scrollable: true})
    ref.componentInstance.id = id;
    ref.componentInstance.formName = key 
  }

}
