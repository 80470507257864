import { Component, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import * as L from 'leaflet';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-sign-request',
  templateUrl: './view-sign-request.component.html',
  styleUrls: ['./view-sign-request.component.scss']
})
export class ViewSignRequestComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  private map: L.Map = null;
  private marker: L.Marker = null;
  public options = {
    layers: [
      L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 12,
    center: L.latLng(36.746841, -119.772591)
  };

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  get f() { return this.form.controls; }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
      "TYPE": new FormControl({value: '', disabled: true}),
      "FULL_ADDRESS": new FormControl({value: '', disabled: true}),
      "CITY": new FormControl({value: '', disabled: true}),
      "ZIP": new FormControl({value: '', disabled: true}),
      "CROSS_STREETS": new FormControl({value: '', disabled: true}),

      "AGENT_NAME": new FormControl({value: '', disabled: true}),
      "PHONE_NUMBER": new FormControl({value: '', disabled: true}),

      "INSTALL_DATE": new FormControl({value: '', disabled: true}),
      "QUANTITY": new FormControl({value: '', disabled: true}),

      "RIDERS": new FormControl({value: '', disabled: true}),

      "REMOVAL_DATE": new FormControl({value: '', disabled: true}),

      "COMMENTS": new FormControl({value: '', disabled: true}),

      "MAPPABLE": new FormControl({value: '', disabled: true}),
      "LOCATION": new FormControl({value: '', disabled: true}),
      "DAMAGELOSS": new FormControl({value: '', disabled: true}),
      "REPLACEREMOVE": new FormControl({value: '', disabled: true}),
    });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  onMapReady(map: L.Map) {
    this.map = map;
    if(this.map) {
      if(!this.marker) {
        const latlng = this.form.get('LOCATION').value
        this.marker = L.marker([latlng.lat, latlng.lng]);
        this.marker.addTo(this.map);
      }
    }
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  openGoogleMaps() {
    window.open("https://maps.google.com?q=" + this.f.LOCATION.value.lat + "," + this.f.LOCATION.value.lng);
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';
      newDiv.innerHTML = this.form.get( formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }

}
