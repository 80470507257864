import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './components/content/content.component';
import { AuthGuard } from './guards/auth.guard';
import { MemberGuard } from './guards/member.guard';
import { AuthComponent } from './components/auth/auth.component';
import { InitializationComponent } from './components/initialization/initialization.component';
import { OrganizationResolver } from './resolvers/organization.resolvers';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: InitializationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    pathMatch: 'full',
    component: AuthComponent
  },
  { 
    path: ':id',
    component: ContentComponent,
    loadChildren: () => import('./components/content/content.module').then(m => m.ContentModule),
    canActivate: [AuthGuard, MemberGuard],
    resolve: {
      organization: OrganizationResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}