import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-commercial',
  templateUrl: './view-commercial.component.html',
  styleUrls: ['./view-commercial.component.scss']
})
export class ViewCommercialComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl({value: '', disabled: true}),

      "County_ComboBox": new FormControl({value: '', disabled: true}),
      "APN_1": new FormControl({value: '', disabled: true}),
      "APN_2": new FormControl({value: '', disabled: true}),
      "APN_3": new FormControl({value: '', disabled: true}),
      "New_Construction": new FormControl({value: '', disabled: true}),
      "Street_Number": new FormControl({value: '', disabled: true}),
      "Street_Number_Modifier": new FormControl({value: '', disabled: true}),
      "Street_Direction": new FormControl({value: '', disabled: true}),
      "Street_Address": new FormControl({value: '', disabled: true}),
      "Street_Suffix": new FormControl({value: '', disabled: true}),
      "Post_Direction": new FormControl({value: '', disabled: true}),
      "Unit_Number": new FormControl({value: '', disabled: true}),
      "City_Fill_In": new FormControl({value: '', disabled: true}),
      "Zip_Code": new FormControl({value: '', disabled: true}),
      "Zip_Plus_4": new FormControl({value: '', disabled: true}),
      "Cross_Street": new FormControl({value: '', disabled: true}),
      "Office_ID": new FormControl({value: '', disabled: true}),
      "Office_MLS_ID": new FormControl({value: '', disabled: true}),
      "Agent_MLS_ID": new FormControl({value: '', disabled: true}),
      "Co_Office_MLS_ID": new FormControl({value: '', disabled: true}),
      "Co_Agent_MLS_ID": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0001": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0002": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0003": new FormControl({value: '', disabled: true}),
      "Transaction": new FormControl({value: '', disabled: true}),
      "SSLE": new FormControl({value: '', disabled: true}),
      "REBK": new FormControl({value: '', disabled: true}),
      "Lease_Price": new FormControl({value: '', disabled: true}),
      "Lease_Type": new FormControl({value: '', disabled: true}),
      "Sub_Lease": new FormControl({value: '', disabled: true}),
      "Available_Date": new FormControl({value: '', disabled: true}),
      "Lease_Expiration": new FormControl({value: '', disabled: true}),
      "APNS": new FormControl({value: '', disabled: true}),
      "0100APN2": new FormControl({value: '', disabled: true}),
      "0110APN3": new FormControl({value: '', disabled: true}),
      "0120APN4": new FormControl({value: '', disabled: true}),
      "0130APN5": new FormControl({value: '', disabled: true}),
      "Listing_Price_1": new FormControl({value: '', disabled: true}),
      "Listing_Price_2": new FormControl({value: '', disabled: true}),
      "Agreement_Type_ComboBox": new FormControl({value: '', disabled: true}),
      "SCOP": new FormControl({value: '', disabled: true}),
      "0235SCOP": new FormControl({value: '', disabled: true}),
      "DUAL": new FormControl({value: '', disabled: true}),
      "0240DUAL": new FormControl({value: '', disabled: true}),
      "Publish_To_Internet": new FormControl({value: '', disabled: true}),
      "Show_AVM_On_VOW": new FormControl({value: '', disabled: true}),
      "Show_Address_to_Public": new FormControl({value: '', disabled: true}),
      "Show_Comments_On_VOW": new FormControl({value: '', disabled: true}),
      //"Dollar_Percent": new FormControl({value: '', disabled: true}),
      //"Commission_1": new FormControl({value: '', disabled: true}),
      //"Commission_Amount": new FormControl({value: '', disabled: true}),
      //"Variable_Rate_Comm": new FormControl({value: '', disabled: true}),
      "Comm_Comments": new FormControl({value: '', disabled: true}),
      "EOFF": new FormControl({value: '', disabled: true}),
      "0390EOAD": new FormControl({value: '', disabled: true}),
      "Listing_Date": new FormControl({value: '', disabled: true}),
      "Expiration_Date": new FormControl({value: '', disabled: true}),
      "Owner_Name": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Area": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Prefix": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Suffix": new FormControl({value: '', disabled: true}),
      "Occupant_Type": new FormControl({value: '', disabled: true}),
      "Occupant_Name": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Area": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Prefix": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Suffix": new FormControl({value: '', disabled: true}),
      "Bedrooms_ComboBox": new FormControl({value: '', disabled: true}),
      "Bathrooms_ComboBox": new FormControl({value: '', disabled: true}),
      "Struc_Sq_Ft": new FormControl({value: '', disabled: true}),
      "SqFt_Source": new FormControl({value: '', disabled: true}),
      "Lot_Sq_Ft": new FormControl({value: '', disabled: true}),
      "Lot_Measurement": new FormControl({value: '', disabled: true}),
      //"Lot_Measurement": new FormControl({value: '', disabled: true}),
      "LotSize_Source": new FormControl({value: '', disabled: true}),
      "Year_Built": new FormControl({value: '', disabled: true}),
      "Unknown_Year": new FormControl({value: '', disabled: true}),
      "Property_Description": new FormControl({value: '', disabled: true}),
      "Agent_Only_Remarks": new FormControl({value: '', disabled: true}),
      "Directions": new FormControl({value: '', disabled: true}),
      "POTENTIALUSE": new FormGroup({
        "POTNAUTO": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNAUTO": new FormControl({value: '', disabled: true}),
        "POTNBANK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNBANK": new FormControl({value: '', disabled: true}),
        "POTNBARB": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNBARB": new FormControl({value: '', disabled: true}),
        "POTNBOWL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNBOWL": new FormControl({value: '', disabled: true}),
        "POTNCHUR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNCHUR": new FormControl({value: '', disabled: true}),
        "POTNMRKT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNMRKT": new FormControl({value: '', disabled: true}),
        "POTNDCAR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNDCAR": new FormControl({value: '', disabled: true}),
        "POTNDEPT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNDEPT": new FormControl({value: '', disabled: true}),
        "POTNDISC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNDISC": new FormControl({value: '', disabled: true}),
        "POTNGNRL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNGNRL": new FormControl({value: '', disabled: true}),
        "POTNGOVT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNGOVT": new FormControl({value: '', disabled: true}),
        "POTNHLTH": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNHLTH": new FormControl({value: '', disabled: true}),
        "POTNHOSP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNHOSP": new FormControl({value: '', disabled: true}),
        "POTNHOTL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNHOTL": new FormControl({value: '', disabled: true}),
        "POTNENGN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNENGN": new FormControl({value: '', disabled: true}),
        "POTNMNFG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNMNFG": new FormControl({value: '', disabled: true}),
        "POTNLAUN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNLAUN": new FormControl({value: '', disabled: true}),
        "POTNMATS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNMATS": new FormControl({value: '', disabled: true}),
        "POTNMDCL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNMDCL": new FormControl({value: '', disabled: true}),
        "POTNMOTL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNMOTL": new FormControl({value: '', disabled: true}),
        "POTNPRIV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNPRIV": new FormControl({value: '', disabled: true}),
        "POTNREST": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNREST": new FormControl({value: '', disabled: true}),
        "POTNFAST": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNFAST": new FormControl({value: '', disabled: true}),
        "POTNRETL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNRETL": new FormControl({value: '', disabled: true}),
        "POTNSERV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNSERV": new FormControl({value: '', disabled: true}),
        "POTNSHPN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNSHPN": new FormControl({value: '', disabled: true}),
        "POTNSHPC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNSHPC": new FormControl({value: '', disabled: true}),
        "POTNSHPR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNSHPR": new FormControl({value: '', disabled: true}),
        "POTNTHTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNTHTR": new FormControl({value: '', disabled: true}),
        "POTNWARD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNWARD": new FormControl({value: '', disabled: true}),
        "POTNWARS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNWARS": new FormControl({value: '', disabled: true}),
        "POTNRMRK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_POTNRMRK": new FormControl({value: '', disabled: true}),
      }),
      "OFSQ": new FormControl({value: '', disabled: true}),
      "0590OFSQ": new FormControl({value: '', disabled: true}),
      "CNSS": new FormControl({value: '', disabled: true}),
      "0600CNSS": new FormControl({value: '', disabled: true}),
      "AREA": new FormControl({value: '', disabled: true}),
      "0620AREA": new FormControl({value: '', disabled: true}),
      "Area_Fill_In": new FormControl({value: '', disabled: true}),
      "STORONE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STORONE": new FormControl({value: '', disabled: true}),
      "STORTWO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STORTWO": new FormControl({value: '', disabled: true}),
      "STORTHRE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STORTHRE": new FormControl({value: '', disabled: true}),
      "STORSLIT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STORSLIT": new FormControl({value: '', disabled: true}),
      "STORBASE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_STORBASE": new FormControl({value: '', disabled: true}),
      "LCTNCRNR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LCTNCRNR": new FormControl({value: '', disabled: true}),
      "LCTNINTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LCTNINTR": new FormControl({value: '', disabled: true}),
      "LCTNCULD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LCTNCULD": new FormControl({value: '', disabled: true}),
      "LCTNINDU": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LCTNINDU": new FormControl({value: '', disabled: true}),
      "LCTNMALL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LCTNMALL": new FormControl({value: '', disabled: true}),
      "LCTNMAJR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LCTNMAJR": new FormControl({value: '', disabled: true}),
      "LCTNFRNG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LCTNFRNG": new FormControl({value: '', disabled: true}),
      "0670ZONE": new FormControl({value: '', disabled: true}),
      "PARKING": new FormGroup({
        "PARK1TO5": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARK1TO5": new FormControl({value: '', disabled: true}),
        "PARK6T10": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARK6T10": new FormControl({value: '', disabled: true}),
        "PARK1020": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARK1020": new FormControl({value: '', disabled: true}),
        "PARKOV21": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKOV21": new FormControl({value: '', disabled: true}),
        "PARKASSN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKASSN": new FormControl({value: '', disabled: true}),
        "PARKCVRD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKCVRD": new FormControl({value: '', disabled: true}),
        "PARKSTRT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKSTRT": new FormControl({value: '', disabled: true}),
        "PARKPBLC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKPBLC": new FormControl({value: '', disabled: true}),
        "PARKUNPV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKUNPV": new FormControl({value: '', disabled: true}),
      }),
      "ACCESS": new FormGroup({
        "ACCSINTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSINTR": new FormControl({value: '', disabled: true}),
        "ACCSFRWY": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSFRWY": new FormControl({value: '', disabled: true}),
        "ACCSSTAT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSSTAT": new FormControl({value: '', disabled: true}),
        "ACCSMAJR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSMAJR": new FormControl({value: '', disabled: true}),
        "ACCSCITY": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSCITY": new FormControl({value: '', disabled: true}),
        "ACCSPRIV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSPRIV": new FormControl({value: '', disabled: true}),
        "ACCSPAVE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSPAVE": new FormControl({value: '', disabled: true}),
        "ACCSUNIM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSUNIM": new FormControl({value: '', disabled: true}),
        "ACCSEASE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSEASE": new FormControl({value: '', disabled: true}),
        "ACCSCNTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSCNTR": new FormControl({value: '', disabled: true}),
        "ACCSGRVL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSGRVL": new FormControl({value: '', disabled: true}),
        "ACCSAIR1": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSAIR1": new FormControl({value: '', disabled: true}),
        "ACCSAIR2": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSAIR2": new FormControl({value: '', disabled: true}),
        "ACCSAIR4": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSAIR4": new FormControl({value: '', disabled: true}),
        "ACCSAIR6": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ACCSAIR6": new FormControl({value: '', disabled: true}),     
      }),
      "BLDG": new FormControl({value: '', disabled: true}),
      "0690BLDG": new FormControl({value: '', disabled: true}),
      "RSTR": new FormControl({value: '', disabled: true}),
      "0700RSTR": new FormControl({value: '', disabled: true}),
      "MISCSECL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCSECL": new FormControl({value: '', disabled: true}),
      "MISCISTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCISTR": new FormControl({value: '', disabled: true}),
      "MISCOSTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCOSTR": new FormControl({value: '', disabled: true}),
      "MISCOPEN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCOPEN": new FormControl({value: '', disabled: true}),
      "MISCFIRE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCFIRE": new FormControl({value: '', disabled: true}),
      "MISCRAIL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCRAIL": new FormControl({value: '', disabled: true}),
      "MISCRACC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCRACC": new FormControl({value: '', disabled: true}),
      "MISCWETS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCWETS": new FormControl({value: '', disabled: true}),
      "MISCDEKG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCDEKG": new FormControl({value: '', disabled: true}),
      "MISCDEKT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCDEKT": new FormControl({value: '', disabled: true}),
      "MISCFRET": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCFRET": new FormControl({value: '', disabled: true}),
      "MISCCNVY": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCCNVY": new FormControl({value: '', disabled: true}),
      "MISCSCAL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCSCAL": new FormControl({value: '', disabled: true}),
      "MISCDISP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCDISP": new FormControl({value: '', disabled: true}),
      "MISCTIXT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCTIXT": new FormControl({value: '', disabled: true}),
      "MISCBLDG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCBLDG": new FormControl({value: '', disabled: true}),
      "MISCHAND": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCHAND": new FormControl({value: '', disabled: true}),
      "MISCLICS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCLICS": new FormControl({value: '', disabled: true}),
      "MISCLITE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCLITE": new FormControl({value: '', disabled: true}),
      "MISCSIGN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_MISCSIGN": new FormControl({value: '', disabled: true}),
      "ROOF": new FormGroup({
        "ROOFCOMP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFCOMP": new FormControl({value: '', disabled: true}),
        "ROOFCONC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFCONC": new FormControl({value: '', disabled: true}),
        "ROOFMETL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFMETL": new FormControl({value: '', disabled: true}),
        "ROOFTARG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFTARG": new FormControl({value: '', disabled: true}),
        "ROOFFLAT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFFLAT": new FormControl({value: '', disabled: true}),
        "ROOFTILE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFTILE": new FormControl({value: '', disabled: true}),
        "ROOFWOOD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFWOOD": new FormControl({value: '', disabled: true}),
        "ROOFOTHR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFOTHR": new FormControl({value: '', disabled: true}),
        "ROOFSTLJ": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFSTLJ": new FormControl({value: '', disabled: true}),
        "ROOFWODJ": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFWODJ": new FormControl({value: '', disabled: true}),
        "ROOFSTLT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFSTLT": new FormControl({value: '', disabled: true}),
        "ROOFBILT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFBILT": new FormControl({value: '', disabled: true}),
        "ROOFCDEK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFCDEK": new FormControl({value: '', disabled: true}),
        "ROOFALUM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFALUM": new FormControl({value: '', disabled: true}),
        "ROOFASBS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFASBS": new FormControl({value: '', disabled: true}),
        "ROOFCORR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFCORR": new FormControl({value: '', disabled: true}),
        "ROOFSHNG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFSHNG": new FormControl({value: '', disabled: true}),
        "ROOFINSL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ROOFINSL": new FormControl({value: '', disabled: true}),
      }),
      "EXTRMETL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRMETL": new FormControl({value: '', disabled: true}),
      "EXTRBLCK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRBLCK": new FormControl({value: '', disabled: true}),
      "EXTRWALL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRWALL": new FormControl({value: '', disabled: true}),
      "EXTRBRCK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRBRCK": new FormControl({value: '', disabled: true}),
      "EXTRROCK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRROCK": new FormControl({value: '', disabled: true}),
      "EXTRSHNG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRSHNG": new FormControl({value: '', disabled: true}),
      "EXTRSTUC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRSTUC": new FormControl({value: '', disabled: true}),
      "EXTRWOOD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRWOOD": new FormControl({value: '', disabled: true}),
      "EXTROTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTROTHR": new FormControl({value: '', disabled: true}),
      "EXTRMASN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRMASN": new FormControl({value: '', disabled: true}),
      "EXTRGLAS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_EXTRGLAS": new FormControl({value: '', disabled: true}),
      "HEATINGANDCOOLING": new FormGroup({
        "HTCLCHCL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLCHCL": new FormControl({value: '', disabled: true}),
        "HTCLCNTA": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLCNTA": new FormControl({value: '', disabled: true}),
        "HTCLWNDC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLWNDC": new FormControl({value: '', disabled: true}),
        "HTCLOFAC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLOFAC": new FormControl({value: '', disabled: true}),
        "HTCLNOCL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLNOCL": new FormControl({value: '', disabled: true}),
        "HTCLCGAS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLCGAS": new FormControl({value: '', disabled: true}),
        "HTCLCELC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLCELC": new FormControl({value: '', disabled: true}),
        "HTCLNOHT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_HTCLNOHT": new FormControl({value: '', disabled: true}),
      }),
      "FLORCONC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FLORCONC": new FormControl({value: '', disabled: true}),
      "FLORWOOD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FLORWOOD": new FormControl({value: '', disabled: true}),
      "LOADDOCK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LOADDOCK": new FormControl({value: '', disabled: true}),
      "LOADDRIV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LOADDRIV": new FormControl({value: '', disabled: true}),
      "LOADLODF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LOADLODF": new FormControl({value: '', disabled: true}),
      "LOADLODR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LOADLODR": new FormControl({value: '', disabled: true}),
      "LOADDOOR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LOADDOOR": new FormControl({value: '', disabled: true}),
      "LOADOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LOADOTHR": new FormControl({value: '', disabled: true}),
      "LOADNONE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LOADNONE": new FormControl({value: '', disabled: true}),
      "WATERSEWERGAS": new FormGroup({
        "WATRCTYW": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRCTYW": new FormControl({value: '', disabled: true}),
        "WATRPRVW": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRPRVW": new FormControl({value: '', disabled: true}),
        "WATRCOPW": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRCOPW": new FormControl({value: '', disabled: true}),
        "WATRWELL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRWELL": new FormControl({value: '', disabled: true}),
        "WATRMMWL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRMMWL": new FormControl({value: '', disabled: true}),
        "WATRIMWL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRIMWL": new FormControl({value: '', disabled: true}),
        "WATRNWTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRNWTR": new FormControl({value: '', disabled: true}),
        "WATRCTYS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRCTYS": new FormControl({value: '', disabled: true}),
        "WATRPRVS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRPRVS": new FormControl({value: '', disabled: true}),
        "WATRNSWR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRNSWR": new FormControl({value: '', disabled: true}),
        "WATRSEPT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRSEPT": new FormControl({value: '', disabled: true}),
        "WATRPHON": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRPHON": new FormControl({value: '', disabled: true}),
        "WATRGASS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRGASS": new FormControl({value: '', disabled: true}),
        "WATRPROP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRPROP": new FormControl({value: '', disabled: true}),
        "WATRNGAS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRNGAS": new FormControl({value: '', disabled: true}),
        "WATRMGMT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRMGMT": new FormControl({value: '', disabled: true}),
        "WATRIGAS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_WATRIGAS": new FormControl({value: '', disabled: true}),
      }),
      "ELECTRIC": new FormGroup({
        "ELECMRMR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ELECMRMR": new FormControl({value: '', disabled: true}),
        "ELECIMTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ELECIMTR": new FormControl({value: '', disabled: true}),
        "ELECELEC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ELECELEC": new FormControl({value: '', disabled: true}),
        "ELECCOOP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ELECCOOP": new FormControl({value: '', disabled: true}),
        "ELEC220V": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ELEC220V": new FormControl({value: '', disabled: true}),
        "ELEC440V": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ELEC440V": new FormControl({value: '', disabled: true}),
        "ELECSNGL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_ELECSNGL": new FormControl({value: '', disabled: true}),
      }),
      "FENCLINK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FENCLINK": new FormControl({value: '', disabled: true}),
      "FENCPRIV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FENCPRIV": new FormControl({value: '', disabled: true}),
      "FENCDECO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FENCDECO": new FormControl({value: '', disabled: true}),
      "FENCFULL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FENCFULL": new FormControl({value: '', disabled: true}),
      "FENCPART": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FENCPART": new FormControl({value: '', disabled: true}),
      "FENCGATE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FENCGATE": new FormControl({value: '', disabled: true}),
      "CEIL": new FormControl({value: '', disabled: true}),
      "0810CEIL": new FormControl({value: '', disabled: true}),
      "ALRMFIRE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ALRMFIRE": new FormControl({value: '', disabled: true}),
      "ALRMBRGL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ALRMBRGL": new FormControl({value: '', disabled: true}),
      "ALRMMNTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ALRMMNTR": new FormControl({value: '', disabled: true}),
      "ALRMSPRN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ALRMSPRN": new FormControl({value: '', disabled: true}),
      "ALRMSECP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ALRMSECP": new FormControl({value: '', disabled: true}),
      "ALRMSECG": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_ALRMSECG": new FormControl({value: '', disabled: true}),
      "LBOX": new FormControl({value: '', disabled: true}),
      "0830LBOX": new FormControl({value: '', disabled: true}),
      "0840LOCL": new FormControl({value: '', disabled: true}),
      "SHOWINGINSTRUCTIONS": new FormGroup({
        "SHOWCLLO": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCLLO": new FormControl({value: '', disabled: true}),
        "SHOWLOCK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWLOCK": new FormControl({value: '', disabled: true}),
        "SHOWCLOW": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCLOW": new FormControl({value: '', disabled: true}),
        "SHOWCLTN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCLTN": new FormControl({value: '', disabled: true}),
        "SHOWDRIV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWDRIV": new FormControl({value: '', disabled: true}),
        "SHOWCLMG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCLMG": new FormControl({value: '', disabled: true}),
        "SHOWREST": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWREST": new FormControl({value: '', disabled: true}),
        "SHOWAPPT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWAPPT": new FormControl({value: '', disabled: true}),
        "SHOWOWOC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWOWOC": new FormControl({value: '', disabled: true}),
        "SHOWALRM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWALRM": new FormControl({value: '', disabled: true}),
      }),
      "Showing_Instructions": new FormControl({value: '', disabled: true}),
      "OINC": new FormControl({value: '', disabled: true}),
      "0920OINC": new FormControl({value: '', disabled: true}),
      "OEXP": new FormControl({value: '', disabled: true}),
      "0930OEXP": new FormControl({value: '', disabled: true}),
      "TAXR": new FormControl({value: '', disabled: true}),
      "0940TAXR": new FormControl({value: '', disabled: true}),
      "LNDV": new FormControl({value: '', disabled: true}),
      "0950LNDV": new FormControl({value: '', disabled: true}),
      "IMPV": new FormControl({value: '', disabled: true}),
      "0960IMPV": new FormControl({value: '', disabled: true}),
      "TTLV": new FormControl({value: '', disabled: true}),
      "0970TTLV": new FormControl({value: '', disabled: true}),
      "SALESINCLUDES": new FormGroup({
        "SINCLNDO": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCLNDO": new FormControl({value: '', disabled: true}),
        "SINCLNDI": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCLNDI": new FormControl({value: '', disabled: true}),
        "SINCBLDG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCBLDG": new FormControl({value: '', disabled: true}),
        "SINCBUSN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCBUSN": new FormControl({value: '', disabled: true}),
        "SINCINVN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCINVN": new FormControl({value: '', disabled: true}),
        "SINCFRCH": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCFRCH": new FormControl({value: '', disabled: true}),
        "SINCEQIP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCEQIP": new FormControl({value: '', disabled: true}),
        "SINCFURN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCFURN": new FormControl({value: '', disabled: true}),
        "SINCLICS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCLICS": new FormControl({value: '', disabled: true}),
        "SINCOTHR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SINCOTHR": new FormControl({value: '', disabled: true}),
      }),
      "LSDESECS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDESECS": new FormControl({value: '', disabled: true}),
      "LSDEPROP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDEPROP": new FormControl({value: '', disabled: true}),
      "LSDEWTRS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDEWTRS": new FormControl({value: '', disabled: true}),
      "LSDESATD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDESATD": new FormControl({value: '', disabled: true}),
      "LSDESOLP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LSDESOLP": new FormControl({value: '', disabled: true}),
      "PRPTDOPT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PRPTDOPT": new FormControl({value: '', disabled: true}),
      "PRPTDMAN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PRPTDMAN": new FormControl({value: '', disabled: true}),
      "PRPTPARK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_PRPTPARK": new FormControl({value: '', disabled: true}),
      "OCCPCPIC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPCPIC": new FormControl({value: '', disabled: true}),
      "OCCPFULL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPFULL": new FormControl({value: '', disabled: true}),
      "OCCPNLES": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPNLES": new FormControl({value: '', disabled: true}),
      "OCCPGLES": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPGLES": new FormControl({value: '', disabled: true}),
      "OCCPMLES": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPMLES": new FormControl({value: '', disabled: true}),
      "OCCPPLES": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPPLES": new FormControl({value: '', disabled: true}),
      "OCCPSLES": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPSLES": new FormControl({value: '', disabled: true}),
      "OCCPFLES": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPFLES": new FormControl({value: '', disabled: true}),
      "OCCPSUIT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_OCCPSUIT": new FormControl({value: '', disabled: true}),
      "TENANTPAYS": new FormGroup({
        "TNNTUTIL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTUTIL": new FormControl({value: '', disabled: true}),
        "TNNTCOMM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTCOMM": new FormControl({value: '', disabled: true}),
        "TNNTMNTN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTMNTN": new FormControl({value: '', disabled: true}),
        "TNNTTAXS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTTAXS": new FormControl({value: '', disabled: true}),
        "TNNTINSR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTINSR": new FormControl({value: '', disabled: true}),
        "TNNTOTHR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTOTHR": new FormControl({value: '', disabled: true}),
        "TNNTPARK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTPARK": new FormControl({value: '', disabled: true}),
        "TNNTJNTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TNNTJNTR": new FormControl({value: '', disabled: true}),
      }),
      "OWNERPAYS": new FormGroup({
        "OWNRUTIL": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_OWNRUTIL": new FormControl({value: '', disabled: true}),
        "OWNRTAXS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_OWNRTAXS": new FormControl({value: '', disabled: true}),
        "OWNRINSR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_OWNRINSR": new FormControl({value: '', disabled: true}),
        "OWNRMNTN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_OWNRMNTN": new FormControl({value: '', disabled: true}),
        "OWNRPARK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_OWNRPARK": new FormControl({value: '', disabled: true}),
        "OWNRJNTR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_OWNRJNTR": new FormControl({value: '', disabled: true}),
        "OWNRNONE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_OWNRNONE": new FormControl({value: '', disabled: true}),
      }),
      "LOAN": new FormControl({value: '', disabled: true}),
      "1060LOAN": new FormControl({value: '', disabled: true}),
      "SELLERWILLCONSIDER": new FormGroup({
        "TERMCONV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TERMCONV": new FormControl({value: '', disabled: true}),
        "TERMLEAS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TERMLEAS": new FormControl({value: '', disabled: true}),
        "TERMCASH": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TERMCASH": new FormControl({value: '', disabled: true}),
        "TERMSUBM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TERMSUBM": new FormControl({value: '', disabled: true}),
        "TERMOWNR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TERMOWNR": new FormControl({value: '', disabled: true}),
        "TERMLIMI": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_TERMLIMI": new FormControl({value: '', disabled: true}),
      }),
      "SOPTTTLP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SOPTTTLP": new FormControl({value: '', disabled: true}),
      "SOPTDIVD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SOPTDIVD": new FormControl({value: '', disabled: true}),
      "SOPTADJL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_SOPTADJL": new FormControl({value: '', disabled: true}),
      "UNIQBANK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQBANK": new FormControl({value: '', disabled: true}),
      "UNIQPROB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQPROB": new FormControl({value: '', disabled: true}),
      "UNIQESTA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQESTA": new FormControl({value: '', disabled: true}),
      "UNIQCORT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQCORT": new FormControl({value: '', disabled: true}),
      "UNIQBIDD": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQBIDD": new FormControl({value: '', disabled: true}),
      "UNIQEXCH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_UNIQEXCH": new FormControl({value: '', disabled: true}),
    }, { updateOn: 'change' });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';
      newDiv.innerHTML = this.form.get( formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }

}
