<div class="modal-header">
  <h5 class="modal-title">
    <span>Switch Organization</span> <br>
    <small class="text-muted">View another organization you are a part of</small>
  </h5>
  <button type="button" class="close" (click)="close()">&times;</button>
</div>
<div class="modal-body">
  <ng-container *ngIf="(accesses$ | async) as accesses">
    <div class="organization-container">
      <div *ngFor="let access of accesses" class="card">
        <div class="card-body">
          <h5 class="card-title m-0">{{ access.organization.name }}</h5>
          <p *ngIf="access.role === 1" class="text-muted small">Member</p>
          <p *ngIf="access.role === 3" class="text-muted small">Admin</p>
          <p *ngIf="access.role === 7" class="text-muted small">Owner</p>
          <button (click)="navigate(access)" class="btn btn-primary">Go</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
</div>