export class Organization {
  public _id?: string;
  public name: string;
  public alias: string;
  //public members: string[];

  constructor(name: string) {
    this.name = name.trim().replace(/ +(?= )/g,'');
    this.alias = this.name.toLowerCase().replace(/ /g, '-');
    //this.members = [];
  }
}