import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-multifamily',
  templateUrl: './view-multifamily.component.html',
  styleUrls: ['./view-multifamily.component.scss']
})
export class ViewMultifamilyComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
     // For display purposes
     "FULL_ADDRESS": new FormControl({value: '', disabled: true}),

     "County_ComboBox": new FormControl({value: '', disabled: true}),
     "APN_1": new FormControl({value: '', disabled: true}),
     "APN_2": new FormControl({value: '', disabled: true}),
     "APN_3": new FormControl({value: '', disabled: true}),
     "New_Construction": new FormControl({value: '', disabled: true}),
     "Street_Number": new FormControl({value: '', disabled: true}),
     "Street_Number_Modifier": new FormControl({value: '', disabled: true}),
     "Street_Direction": new FormControl({value: '', disabled: true}),
     "Street_Address": new FormControl({value: '', disabled: true}),
     "Street_Suffix": new FormControl({value: '', disabled: true}),
     "Post_Direction": new FormControl({value: '', disabled: true}),
     "Unit_Number": new FormControl({value: '', disabled: true}),
     "City_Fill_In": new FormControl({value: '', disabled: true}),
     "Zip_Code": new FormControl({value: '', disabled: true}),
     "Zip_Plus_4": new FormControl({value: '', disabled: true}),
     "Cross_Street": new FormControl({value: '', disabled: true}),
     "Office_ID": new FormControl({value: '', disabled: true}),
     "Office_MLS_ID": new FormControl({value: '', disabled: true}),
     "Agent_MLS_ID": new FormControl({value: '', disabled: true}),
     "Co_Office_MLS_ID": new FormControl({value: '', disabled: true}),
     "Co_Agent_MLS_ID": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0001": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0002": new FormControl({value: '', disabled: true}),
     "Prop_Subtype_0003": new FormControl({value: '', disabled: true}),
     "TTLU": new FormControl({value: '', disabled: true}),
     "0050TTLU": new FormControl({value: '', disabled: true}),
     "Transaction": new FormControl({value: '', disabled: true}),
     "SSLE": new FormControl({value: '', disabled: true}),
     "REBK": new FormControl({value: '', disabled: true}),
     "Lease_Price": new FormControl({value: '', disabled: true}),
     "Lease_Type": new FormControl({value: '', disabled: true}),
     "Sub_Lease": new FormControl({value: '', disabled: true}),
     "Available_Date": new FormControl({value: '', disabled: true}),
     "Lease_Expiration": new FormControl({value: '', disabled: true}),
     "APNS": new FormControl({value: '', disabled: true}),
     "0120APN2": new FormControl({value: '', disabled: true}),
     "0130APN3": new FormControl({value: '', disabled: true}),
     "0140APN4": new FormControl({value: '', disabled: true}),
     "0150APN5": new FormControl({value: '', disabled: true}),
     "Listing_Price_1": new FormControl({value: '', disabled: true}),
     "Listing_Price_2": new FormControl({value: '', disabled: true}),
     "Agreement_Type_ComboBox": new FormControl({value: '', disabled: true}),
     "SCOP": new FormControl({value: '', disabled: true}),
     "0255SCOP": new FormControl({value: '', disabled: true}),
     "DUAL": new FormControl({value: '', disabled: true}),
     "0240DUAL": new FormControl({value: '', disabled: true}),
     "Publish_To_Internet": new FormControl({value: '', disabled: true}),
     "Show_AVM_On_VOW": new FormControl({value: '', disabled: true}),
     "Show_Address_to_Public": new FormControl({value: '', disabled: true}),
     "Show_Comments_On_VOW": new FormControl({value: '', disabled: true}),
     "Dollar_Percent": new FormControl({value: '', disabled: true}),
     "Commission_1": new FormControl({value: '', disabled: true}),
     "Commission_Amount": new FormControl({value: '', disabled: true}),
     "Variable_Rate_Comm": new FormControl({value: '', disabled: true}),
     "Comm_Comments": new FormControl({value: '', disabled: true}),
     "EOFF": new FormControl({value: '', disabled: true}),
     "0400EOAD": new FormControl({value: '', disabled: true}),
     "Listing_Date": new FormControl({value: '', disabled: true}),
     "Expiration_Date": new FormControl({value: '', disabled: true}),
     "Owner_Name": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Area": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Prefix": new FormControl({value: '', disabled: true}),
     "Owner_Phone_Suffix": new FormControl({value: '', disabled: true}),
     "Occupant_Type": new FormControl({value: '', disabled: true}),
     "Occupant_Name": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Area": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Prefix": new FormControl({value: '', disabled: true}),
     "Phone_to_Show_Suffix": new FormControl({value: '', disabled: true}),
     "Bedrooms_ComboBox": new FormControl({value: '', disabled: true}),
     "Bathrooms_ComboBox": new FormControl({value: '', disabled: true}),
     "Struc_Sq_Ft": new FormControl({value: '', disabled: true}),
     "SqFt_Source": new FormControl({value: '', disabled: true}),
     "Lot_Sq_Ft": new FormControl({value: '', disabled: true}),
     "Lot_Measurement": new FormControl({value: '', disabled: true}),
     //"Lot_Measurement": new FormControl({value: '', disabled: true}),
     "LotSize_Source": new FormControl({value: '', disabled: true}),
     "Year_Built": new FormControl({value: '', disabled: true}),
     "Unknown_Year": new FormControl({value: '', disabled: true}),
     "Property_Description": new FormControl({value: '', disabled: true}),
     "Agent_Only_Remarks": new FormControl({value: '', disabled: true}),
     "Directions": new FormControl({value: '', disabled: true}),
     "School_District_Fill_In": new FormControl({value: '', disabled: true}),
     "School_Code_1": new FormControl({value: '', disabled: true}),
     "School_Code_2": new FormControl({value: '', disabled: true}),
     "School_Code_3": new FormControl({value: '', disabled: true}),
     "0610SCMT": new FormControl({value: '', disabled: true}),
     "CNSS": new FormControl({value: '', disabled: true}),
     "0650CNSS": new FormControl({value: '', disabled: true}),
     "AREA": new FormControl({value: '', disabled: true}),
     "0660AREA": new FormControl({value: '', disabled: true}),
     "Area_Fill_In": new FormControl({value: '', disabled: true}),
     "NUMOFSTORIES": new FormGroup({
       "STORSNGL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_STORSNGL": new FormControl({value: '', disabled: true}),
       "STORTWOS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_STORTWOS": new FormControl({value: '', disabled: true}),
       "STORTHRM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_STORTHRM": new FormControl({value: '', disabled: true}),
       "STORSPLT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_STORSPLT": new FormControl('')
     }),
     "ROOFING": new FormGroup({
       "ROOFSHAK": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFSHAK": new FormControl({value: '', disabled: true}),
       "ROOFWOOD": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFWOOD": new FormControl({value: '', disabled: true}),
       "ROOFCOMP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFCOMP": new FormControl({value: '', disabled: true}),
       "ROOFROLL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFROLL": new FormControl({value: '', disabled: true}),
       "ROOFTLSL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFTLSL": new FormControl({value: '', disabled: true}),
       "ROOFTRGR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFTRGR": new FormControl({value: '', disabled: true}),
       "ROOFCONC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFCONC": new FormControl({value: '', disabled: true}),
       "ROOFMETL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFMETL": new FormControl({value: '', disabled: true}),
       "ROOFFIBR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFFIBR": new FormControl({value: '', disabled: true}),
       "ROOFOTHR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_ROOFOTHR": new FormControl({value: '', disabled: true}),
      }),
     "EXTERIOR": new FormGroup({
       "EXTRSTUC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRSTUC": new FormControl({value: '', disabled: true}),
       "EXTRWOOD": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRWOOD": new FormControl({value: '', disabled: true}),
       "EXTRBRIK": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRBRIK": new FormControl({value: '', disabled: true}),
       "EXTRBLCK": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRBLCK": new FormControl({value: '', disabled: true}),
       "EXTRSTON": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRSTON": new FormControl({value: '', disabled: true}),
       "EXTRMETL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRMETL": new FormControl({value: '', disabled: true}),
       "EXTRSHGL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRSHGL": new FormControl({value: '', disabled: true}),
       "EXTRLOGG": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTRLOGG": new FormControl({value: '', disabled: true}),
       "EXTROTHR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXTROTHR": new FormControl({value: '', disabled: true}),
      }),
     "SEWERWATER": new FormGroup({
       "WATRPWTR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRPWTR": new FormControl({value: '', disabled: true}),
       "WATRDWTR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRDWTR": new FormControl({value: '', disabled: true}),
       "WATRPVWT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRPVWT": new FormControl({value: '', disabled: true}),
       "WATRSWEL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRSWEL": new FormControl({value: '', disabled: true}),
       "WATRCWEL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRCWEL": new FormControl({value: '', disabled: true}),
       "WATRPSWR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRPSWR": new FormControl({value: '', disabled: true}),
       "WATRSEPT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_WATRSEPT": new FormControl({value: '', disabled: true}),
      }),
     "GASELECTRIC": new FormGroup({
       "GSELPUBU": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSELPUBU": new FormControl({value: '', disabled: true}),
       "GSELNGAS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSELNGAS": new FormControl({value: '', disabled: true}),
       "GSELPROP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSELPROP": new FormControl({value: '', disabled: true}),
       "GSELNOGS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_GSELNOGS": new FormControl({value: '', disabled: true}),
      }),
     "SPECSPSZ": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSPSZ": new FormControl({value: '', disabled: true}),
     "SPECFLOD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECFLOD": new FormControl({value: '', disabled: true}),
     "SPECTOXI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECTOXI": new FormControl({value: '', disabled: true}),
     "SPECSMOK": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSMOK": new FormControl({value: '', disabled: true}),
     "SPECBLDR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECBLDR": new FormControl({value: '', disabled: true}),
     "SPECDEED": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECDEED": new FormControl({value: '', disabled: true}),
     "SPECEASM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECEASM": new FormControl({value: '', disabled: true}),
     "SPECCTTX": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECCTTX": new FormControl({value: '', disabled: true}),
     "SPECASSM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECASSM": new FormControl({value: '', disabled: true}),
     "SPECTFEX": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECTFEX": new FormControl({value: '', disabled: true}),
     "SPECHOEX": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECHOEX": new FormControl({value: '', disabled: true}),
     "SPECCALL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECCALL": new FormControl({value: '', disabled: true}),
     "SPECAGOW": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECAGOW": new FormControl({value: '', disabled: true}),
     "SPECDEPR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECDEPR": new FormControl({value: '', disabled: true}),
     "SPECFLIT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECFLIT": new FormControl({value: '', disabled: true}),
     "SPECOTHR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECOTHR": new FormControl({value: '', disabled: true}),
     "SPECSEER": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SPECSEER": new FormControl({value: '', disabled: true}),
     "DILOROOF": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOROOF": new FormControl({value: '', disabled: true}),
     "DILOPEST": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOPEST": new FormControl({value: '', disabled: true}),
     "DILOSEPT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOSEPT": new FormControl({value: '', disabled: true}),
     "DILOWELL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOWELL": new FormControl({value: '', disabled: true}),
     "DILOASOC": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOASOC": new FormControl({value: '', disabled: true}),
     "DILODEMO": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILODEMO": new FormControl({value: '', disabled: true}),
     "DILOFLOR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOFLOR": new FormControl({value: '', disabled: true}),
     "DILOLEGA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOLEGA": new FormControl({value: '', disabled: true}),
     "DILOZONR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOZONR": new FormControl({value: '', disabled: true}),
     "DILOPUBR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOPUBR": new FormControl({value: '', disabled: true}),
     "DILOLEAS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOLEAS": new FormControl({value: '', disabled: true}),
     "DILOAPPR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOAPPR": new FormControl({value: '', disabled: true}),
     "DILOPNLS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOPNLS": new FormControl({value: '', disabled: true}),
     "DILOINCX": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOINCX": new FormControl({value: '', disabled: true}),
     "DILOCASH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOCASH": new FormControl({value: '', disabled: true}),
     "DILOINVL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOINVL": new FormControl({value: '', disabled: true}),
     "DILOFIXT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DILOFIXT": new FormControl({value: '', disabled: true}),
     "LKBS": new FormControl({value: '', disabled: true}),
     "0760LKBS": new FormControl({value: '', disabled: true}),
     "Showing_Instructions": new FormControl({value: '', disabled: true}),
     "SHOWINGINSTRUCTIONS": new FormGroup({
       "SHOWLOCK": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWLOCK": new FormControl({value: '', disabled: true}),
       "SHOWCLLO": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCLLO": new FormControl({value: '', disabled: true}),
       "SHOWCLTN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCLTN": new FormControl({value: '', disabled: true}),
       "SHOWCLSM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCLSM": new FormControl({value: '', disabled: true}),
       "SHOWCLOW": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCLOW": new FormControl({value: '', disabled: true}),
       "SHOWAPPT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWAPPT": new FormControl({value: '', disabled: true}),
       "SHOWDNDT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWDNDT": new FormControl({value: '', disabled: true}),
       "SHOW24HR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOW24HR": new FormControl({value: '', disabled: true}),
       "SHOWNOSN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWNOSN": new FormControl({value: '', disabled: true}),
       "SHOWSNST": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWSNST": new FormControl({value: '', disabled: true}),
       "SHOWSEAS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWSEAS": new FormControl({value: '', disabled: true}),
       "SHOWFWDR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWFWDR": new FormControl({value: '', disabled: true}),
       "SHOWCBSC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWCBSC": new FormControl({value: '', disabled: true}),
       "SHOWVTGS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWVTGS": new FormControl({value: '', disabled: true}),
       "SHOWALRM": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SHOWALRM": new FormControl({value: '', disabled: true}),
      }),
     "Garage_Spaces": new FormControl({value: '', disabled: true}),
     "GART": new FormControl({value: '', disabled: true}),
     "0842GART": new FormControl({value: '', disabled: true}),
     "PARKGARG": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_PARKGARG": new FormControl({value: '', disabled: true}),
     "PARKCRPT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_PARKCRPT": new FormControl({value: '', disabled: true}),
     "PARKCVRD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_PARKCVRD": new FormControl({value: '', disabled: true}),
     "PARKUCVR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_PARKUCVR": new FormControl({value: '', disabled: true}),
     "PARKOSPU": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_PARKOSPU": new FormControl({value: '', disabled: true}),
     "PARKMTOS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_PARKMTOS": new FormControl({value: '', disabled: true}),
     "CRPT": new FormControl({value: '', disabled: true}),
     "0860CRPT": new FormControl({value: '', disabled: true}),
     "FOUNDATION": new FormGroup({
       "FLORCONC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FLORCONC": new FormControl({value: '', disabled: true}),
       "FLORWOOD": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FLORWOOD": new FormControl({value: '', disabled: true}),
       "FLORBOTH": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FLORBOTH": new FormControl({value: '', disabled: true}),
       "FCLTPOOL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTPOOL": new FormControl({value: '', disabled: true}),
       "FCLTJCZI": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTJCZI": new FormControl({value: '', disabled: true}),
       "FCLTCBNA": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTCBNA": new FormControl({value: '', disabled: true}),
       "FCLTTNSC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTTNSC": new FormControl({value: '', disabled: true}),
       "FCLTBBAL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTBBAL": new FormControl({value: '', disabled: true}),
       "FCLTXRCS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTXRCS": new FormControl({value: '', disabled: true}),
       "FCLTPLAY": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTPLAY": new FormControl({value: '', disabled: true}),
       "FCLTPUTT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTPUTT": new FormControl({value: '', disabled: true}),
       "FCLTUTIL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_FCLTUTIL": new FormControl({value: '', disabled: true}),
      }),
     "SFTRPRVP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRPRVP": new FormControl({value: '', disabled: true}),
     "SFTRPRVB": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRPRVB": new FormControl({value: '', disabled: true}),
     "SFTRXTRA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRXTRA": new FormControl({value: '', disabled: true}),
     "SFTRONST": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRONST": new FormControl({value: '', disabled: true}),
     "SFTRLAUN": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRLAUN": new FormControl({value: '', disabled: true}),
     "SFTRSECG": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRSECG": new FormControl({value: '', disabled: true}),
     "SFTRSECP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRSECP": new FormControl({value: '', disabled: true}),
     "SFTRFURN": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SFTRFURN": new FormControl({value: '', disabled: true}),
     "LEASWSDR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_LEASWSDR": new FormControl({value: '', disabled: true}),
     "LEASPROP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_LEASPROP": new FormControl({value: '', disabled: true}),
     "LEASWTRS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_LEASWTRS": new FormControl({value: '', disabled: true}),
     "LEASSESS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_LEASSESS": new FormControl({value: '', disabled: true}),
     "LEASSOLP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_LEASSOLP": new FormControl({value: '', disabled: true}),
     "SITECRNR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITECRNR": new FormControl({value: '', disabled: true}),
     "SITECULD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITECULD": new FormControl({value: '', disabled: true}),
     "SITEBLUF": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEBLUF": new FormControl({value: '', disabled: true}),
     "SITELAKE": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITELAKE": new FormControl({value: '', disabled: true}),
     "SITEWTRF": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEWTRF": new FormControl({value: '', disabled: true}),
     "SITEGFCV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEGFCV": new FormControl({value: '', disabled: true}),
     "SITEPRKV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SITEPRKV": new FormControl({value: '', disabled: true}),
     "YARDSPRF": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_YARDSPRF": new FormControl({value: '', disabled: true}),
     "YARDSPRB": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_YARDSPRB": new FormControl({value: '', disabled: true}),
     "YARDSPRA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_YARDSPRA": new FormControl({value: '', disabled: true}),
     "YARDSPRM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_YARDSPRM": new FormControl({value: '', disabled: true}),
     "YARDFENC": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_YARDFENC": new FormControl({value: '', disabled: true}),
     "YARDLAND": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_YARDLAND": new FormControl({value: '', disabled: true}),
     "TTLB": new FormControl({value: '', disabled: true}),
     "0930TTLB": new FormControl({value: '', disabled: true}),
     "HEATCOOL": new FormGroup({
       "HTCLCNHC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_HTCLCNHC": new FormControl({value: '', disabled: true}),
       "HTCLCNHT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_HTCLCNHT": new FormControl({value: '', disabled: true}),
       "HTCLCNCL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_HTCLCNCL": new FormControl({value: '', disabled: true}),
       "HTCLEVAP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_HTCLEVAP": new FormControl({value: '', disabled: true}),
       "HTCLFLWL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_HTCLFLWL": new FormControl({value: '', disabled: true}),
       "HTCLWNWL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_HTCLWNWL": new FormControl({value: '', disabled: true}),
       "HTCLNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_HTCLNONE": new FormControl('')
      }),
     "FIRE": new FormControl({value: '', disabled: true}),
     "0970FIRE": new FormControl({value: '', disabled: true}),
     "STOV": new FormControl({value: '', disabled: true}),
     "0980STOV": new FormControl({value: '', disabled: true}),
     "REFR": new FormControl({value: '', disabled: true}),
     "0990REFR": new FormControl({value: '', disabled: true}),
     "DISH": new FormControl({value: '', disabled: true}),
     "1000DISH": new FormControl({value: '', disabled: true}),
     "DISP": new FormControl({value: '', disabled: true}),
     "1010DISP": new FormControl({value: '', disabled: true}),
     "DSABWLCA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DSABWLCA": new FormControl({value: '', disabled: true}),
     "DSABRAMP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DSABRAMP": new FormControl({value: '', disabled: true}),
     "DSABWIDD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DSABWIDD": new FormControl({value: '', disabled: true}),
     "DSABHAND": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DSABHAND": new FormControl({value: '', disabled: true}),
     "DSABBATH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DSABBATH": new FormControl({value: '', disabled: true}),
     "DSABSHOW": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DSABSHOW": new FormControl({value: '', disabled: true}),
     "DSAB1LVL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_DSAB1LVL": new FormControl({value: '', disabled: true}),
     "AINC": new FormControl({value: '', disabled: true}),
     "1050AINC": new FormControl({value: '', disabled: true}),
     "AEXP": new FormControl({value: '', disabled: true}),
     "1060AEXP": new FormControl({value: '', disabled: true}),
     "NINC": new FormControl({value: '', disabled: true}),
     "1070NINC": new FormControl({value: '', disabled: true}),
     "TENANTPAYS": new FormGroup({
       "TNTPNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPNONE": new FormControl({value: '', disabled: true}),
       "TNTPALLL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPALLL": new FormControl({value: '', disabled: true}),
       "TNTPELEC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPELEC": new FormControl({value: '', disabled: true}),
       "TNTPGASS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPGASS": new FormControl({value: '', disabled: true}),
       "TNTPPROP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPPROP": new FormControl({value: '', disabled: true}),
       "TNTPWATR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPWATR": new FormControl({value: '', disabled: true}),
       "TNTPSEWR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPSEWR": new FormControl({value: '', disabled: true}),
       "TNTPTRSH": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPTRSH": new FormControl({value: '', disabled: true}),
       "TNTPCBLE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_TNTPCBLE": new FormControl({value: '', disabled: true}),
      }),
     "INCOMEINCLUDES": new FormGroup({
       "INCIRENT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCIRENT": new FormControl({value: '', disabled: true}),
       "INCIGARG": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCIGARG": new FormControl({value: '', disabled: true}),
       "INCILNDR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCILNDR": new FormControl({value: '', disabled: true}),
       "INCIOTHR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCIOTHR": new FormControl({value: '', disabled: true}),
       "INCIALLL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCIALLL": new FormControl({value: '', disabled: true}),
       "INCIMNTH": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCIMNTH": new FormControl({value: '', disabled: true}),
       "INCILEAS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCILEAS": new FormControl({value: '', disabled: true}),
       "INCIBOTH": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_INCIBOTH": new FormControl({value: '', disabled: true}),
      }),
     "EXPENSESINCLUDE": new FormGroup({
       "EXPIALLL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIALLL": new FormControl({value: '', disabled: true}),
       "EXPIMNGT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIMNGT": new FormControl({value: '', disabled: true}),
       "EXPIGRDN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIGRDN": new FormControl({value: '', disabled: true}),
       "EXPIMNTN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIMNTN": new FormControl({value: '', disabled: true}),
       "EXPIINSR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIINSR": new FormControl({value: '', disabled: true}),
       "EXPIACCT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIACCT": new FormControl({value: '', disabled: true}),
       "EXPILICN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPILICN": new FormControl({value: '', disabled: true}),
       "EXPIGASS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIGASS": new FormControl({value: '', disabled: true}),
       "EXPIPROP": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIPROP": new FormControl({value: '', disabled: true}),
       "EXPIELEC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIELEC": new FormControl({value: '', disabled: true}),
       "EXPIWATR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIWATR": new FormControl({value: '', disabled: true}),
       "EXPISEWR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPISEWR": new FormControl({value: '', disabled: true}),
       "EXPIPOOL": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIPOOL": new FormControl({value: '', disabled: true}),
       "EXPITAXS": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPITAXS": new FormControl({value: '', disabled: true}),
       "EXPIVCNC": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPIVCNC": new FormControl({value: '', disabled: true}),
       "EXPINONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_EXPINONE": new FormControl({value: '', disabled: true}),
      }),
     "SOURCESOFINCEXP": new FormGroup({
       "SRCSTAXR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SRCSTAXR": new FormControl({value: '', disabled: true}),
       "SRCSACCT": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SRCSACCT": new FormControl({value: '', disabled: true}),
       "SRCSOWNR": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SRCSOWNR": new FormControl({value: '', disabled: true}),
       "SRCSPROJ": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SRCSPROJ": new FormControl({value: '', disabled: true}),
       "SRCSLMTD": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SRCSLMTD": new FormControl({value: '', disabled: true}),
       "SRCSPMAN": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SRCSPMAN": new FormControl({value: '', disabled: true}),
       "SRCSNONE": new FormControl({value: '', disabled: true}),
       "Checkbox_Comment_SRCSNONE": new FormControl({value: '', disabled: true}),
      }),
     "1SLNASSM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNASSM": new FormControl({value: '', disabled: true}),
     "1SLNNASS": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNNASS": new FormControl({value: '', disabled: true}),
     "1SLNPRIV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNPRIV": new FormControl({value: '', disabled: true}),
     "1SLNFRCL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_1SLNFRCL": new FormControl({value: '', disabled: true}),
     "2LNASSM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNASSM": new FormControl({value: '', disabled: true}),
     "2LNPRIV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNPRIV": new FormControl({value: '', disabled: true}),
     "2LNNONA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_2LNNONA": new FormControl({value: '', disabled: true}),
     "TERMGOVT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMGOVT": new FormControl({value: '', disabled: true}),
     "TERMCONV": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMCONV": new FormControl({value: '', disabled: true}),
     "TERMCASH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMCASH": new FormControl({value: '', disabled: true}),
     "TERMSUBM": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMSUBM": new FormControl({value: '', disabled: true}),
     "TERMOWNR": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMOWNR": new FormControl({value: '', disabled: true}),
     "TERMLIMI": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_TERMLIMI": new FormControl({value: '', disabled: true}),
     "HSSB": new FormControl({value: '', disabled: true}),
     "STTD": new FormControl({value: '', disabled: true}),
     "1180STTD": new FormControl({value: '', disabled: true}),
     "SDTD": new FormControl({value: '', disabled: true}),
     "1190SDTD": new FormControl({value: '', disabled: true}),
     "1200SSSS": new FormControl({value: '', disabled: true}),
     "ADDD": new FormControl({value: '', disabled: true}),
     "1201ADDD": new FormControl({value: '', disabled: true}),
     "UNIQPROB": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQPROB": new FormControl({value: '', disabled: true}),
     "UNIQESTA": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQESTA": new FormControl({value: '', disabled: true}),
     "UNIQCORT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQCORT": new FormControl({value: '', disabled: true}),
     "UNIQSHRT": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQSHRT": new FormControl({value: '', disabled: true}),
     "UNIQEXCH": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_UNIQEXCH": new FormControl({value: '', disabled: true}),
     "SALETTLP": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALETTLP": new FormControl({value: '', disabled: true}),
     "SALEDIVD": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALEDIVD": new FormControl({value: '', disabled: true}),
     "SALEADJL": new FormControl({value: '', disabled: true}),
     "Checkbox_Comment_SALEADJL": new FormControl({value: '', disabled: true}),

     "UNITDETAILSNUM": new FormControl({value: '', disabled: true}),


     "UNIT1_DESCRIPTION": new FormControl({value: '', disabled: true}),
     "UNIT1_NUMBER": new FormControl({value: '', disabled: true}),
     "UNIT1_IDENUNITS": new FormControl({value: '', disabled: true}),
     "UNIT1_SQFEET": new FormControl({value: '', disabled: true}),
     "UNIT1_OCCUPIED": new FormControl({value: '', disabled: true}),
     "UNIT1_RENT": new FormControl({value: '', disabled: true}),
     "UNIT1_BEDROOMS": new FormControl({value: '', disabled: true}),
     "UNIT1_BATHROOMS": new FormControl({value: '', disabled: true}),

     "UNIT2_DESCRIPTION": new FormControl({value: '', disabled: true}),
     "UNIT2_NUMBER": new FormControl({value: '', disabled: true}),
     "UNIT2_IDENUNITS": new FormControl({value: '', disabled: true}),
     "UNIT2_SQFEET": new FormControl({value: '', disabled: true}),
     "UNIT2_OCCUPIED": new FormControl({value: '', disabled: true}),
     "UNIT2_RENT": new FormControl({value: '', disabled: true}),
     "UNIT2_BEDROOMS": new FormControl({value: '', disabled: true}),
     "UNIT2_BATHROOMS": new FormControl({value: '', disabled: true}),

     "UNIT3_DESCRIPTION": new FormControl({value: '', disabled: true}),
     "UNIT3_NUMBER": new FormControl({value: '', disabled: true}),
     "UNIT3_IDENUNITS": new FormControl({value: '', disabled: true}),
     "UNIT3_SQFEET": new FormControl({value: '', disabled: true}),
     "UNIT3_OCCUPIED": new FormControl({value: '', disabled: true}),
     "UNIT3_RENT": new FormControl({value: '', disabled: true}),
     "UNIT3_BEDROOMS": new FormControl({value: '', disabled: true}),
     "UNIT3_BATHROOMS": new FormControl({value: '', disabled: true}),

     "UNIT4_DESCRIPTION": new FormControl({value: '', disabled: true}),
     "UNIT4_NUMBER": new FormControl({value: '', disabled: true}),
     "UNIT4_IDENUNITS": new FormControl({value: '', disabled: true}),
     "UNIT4_SQFEET": new FormControl({value: '', disabled: true}),
     "UNIT4_OCCUPIED": new FormControl({value: '', disabled: true}),
     "UNIT4_RENT": new FormControl({value: '', disabled: true}),
     "UNIT4_BEDROOMS": new FormControl({value: '', disabled: true}),
     "UNIT4_BATHROOMS": new FormControl({value: '', disabled: true}),
    }, { updateOn: 'change' });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';
      newDiv.innerHTML = this.form.get( formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }
}
