import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseService } from 'src/app/services/response.service';
import { Response } from 'src/app/models/response.model';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-business',
  templateUrl: './view-business.component.html',
  styleUrls: ['./view-business.component.scss']
})
export class ViewBusinessComponent implements OnInit {
  @Input() id: string;
  @Input() formName: string;

  public form: FormGroup
  public response$: Observable<Response>;
  public response: any;

  public isDownloading: boolean = false;

  private subscription: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.response$ = this.responseService.getViewOnlyResponse(this.id);
    this.form = new FormGroup({
      // For display purposes
      "FULL_ADDRESS": new FormControl({value: '', disabled: true}),

      "County_ComboBox": new FormControl({value: '', disabled: true}),
      "APN_1": new FormControl({value: '', disabled: true}),
      "APN_2": new FormControl({value: '', disabled: true}),
      "APN_3": new FormControl({value: '', disabled: true}),
      "New_Construction": new FormControl({value: '', disabled: true}),
      "Street_Number": new FormControl({value: '', disabled: true}),
      "Street_Number_Modifier": new FormControl({value: '', disabled: true}),
      "Street_Direction": new FormControl({value: '', disabled: true}),
      "Street_Address": new FormControl({value: '', disabled: true}),
      "Street_Suffix": new FormControl({value: '', disabled: true}),
      "Post_Direction": new FormControl({value: '', disabled: true}),
      "Unit_Number": new FormControl({value: '', disabled: true}),
      "City_Fill_In": new FormControl({value: '', disabled: true}),
      "Zip_Code": new FormControl({value: '', disabled: true}),
      "Zip_Plus_4": new FormControl({value: '', disabled: true}),
      "Cross_Street": new FormControl({value: '', disabled: true}),
      "Office_ID": new FormControl({value: '', disabled: true}),
      "Office_MLS_ID": new FormControl({value: '', disabled: true}),
      "Agent_MLS_ID": new FormControl({value: '', disabled: true}),
      "Co_Office_MLS_ID": new FormControl({value: '', disabled: true}),
      "Co_Agent_MLS_ID": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0001": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0002": new FormControl({value: '', disabled: true}),
      "Prop_Subtype_0003": new FormControl({value: '', disabled: true}),
      "Transaction": new FormControl({value: '', disabled: true}),
      "SSLE": new FormControl({value: '', disabled: true}),
      "REBK": new FormControl({value: '', disabled: true}),
      "Lease_Price": new FormControl({value: '', disabled: true}),
      "Lease_Type": new FormControl({value: '', disabled: true}),
      "Sub_Lease": new FormControl({value: '', disabled: true}),
      "Available_Date": new FormControl({value: '', disabled: true}),
      "Lease_Expiration": new FormControl({value: '', disabled: true}),
      "APNS": new FormControl({value: '', disabled: true}),
      "0100APN2": new FormControl({value: '', disabled: true}),
      "0110APN3": new FormControl({value: '', disabled: true}),
      "0120APN4": new FormControl({value: '', disabled: true}),
      "0130APN5": new FormControl({value: '', disabled: true}),
      "Listing_Price_1": new FormControl({value: '', disabled: true}),
      "Listing_Price_2": new FormControl({value: '', disabled: true}),
      "Agreement_Type_ComboBox": new FormControl({value: '', disabled: true}),
      "SCOP": new FormControl({value: '', disabled: true}),
      "0235SCOP": new FormControl({value: '', disabled: true}),
      "DUAL": new FormControl({value: '', disabled: true}),
      "0240DUAL": new FormControl({value: '', disabled: true}),
      "Publish_To_Internet": new FormControl({value: '', disabled: true}),
      "Show_AVM_On_VOW": new FormControl({value: '', disabled: true}),
      "Show_Address_to_Public": new FormControl({value: '', disabled: true}),
      "Show_Comments_On_VOW": new FormControl({value: '', disabled: true}),
      //"Dollar_Percent": new FormControl({value: '', disabled: true}),
      //"Commission_1": new FormControl({value: '', disabled: true}),
      //"Commission_Amount": new FormControl({value: '', disabled: true}),
      //"Variable_Rate_Comm": new FormControl({value: '', disabled: true}),
      "Comm_Comments": new FormControl({value: '', disabled: true}),
      "EOFF": new FormControl({value: '', disabled: true}),
      "0390EOAD": new FormControl({value: '', disabled: true}),
      "Listing_Date":new FormControl({value: '', disabled: true}),
      "Expiration_Date": new FormControl({value: '', disabled: true}),
      "Owner_Name": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Area": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Prefix": new FormControl({value: '', disabled: true}),
      "Owner_Phone_Suffix": new FormControl({value: '', disabled: true}),
      "Occupant_Type": new FormControl({value: '', disabled: true}),
      "Occupant_Name": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Area": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Prefix": new FormControl({value: '', disabled: true}),
      "Phone_to_Show_Suffix": new FormControl({value: '', disabled: true}),
      "Bedrooms_ComboBox": new FormControl({value: '', disabled: true}),
      "Bathrooms_ComboBox": new FormControl({value: '', disabled: true}),
      "Struc_Sq_Ft": new FormControl({value: '', disabled: true}),
      "SqFt_Source": new FormControl({value: '', disabled: true}),
      "Lot_Sq_Ft": new FormControl({value: '', disabled: true}),
      "Lot_Measurement": new FormControl({value: '', disabled: true}),
      "LotSize_Source": new FormControl({value: '', disabled: true}),
      "Year_Built": new FormControl({value: '', disabled: true}),
      "Unknown_Year": new FormControl({value: '', disabled: true}),
      "Property_Description": new FormControl({value: '', disabled: true}),
      "Agent_Only_Remarks": new FormControl({value: '', disabled: true}),
      "Directions": new FormControl({value: '', disabled: true}),
      "0600NAME": new FormControl({value: '', disabled: true}),
      "CNSS": new FormControl({value: '', disabled: true}),
      "0610CNSS": new FormControl({value: '', disabled: true}),
      "AREA": new FormControl({value: '', disabled: true}),
      "0620AREA": new FormControl({value: '', disabled: true}),
      "Area_Fill_In": new FormControl({value: '', disabled: true}),
      "0650ZONE": new FormControl({value: '', disabled: true}),
      "BUSINESSTYPE": new FormGroup({
        "BUSTREST": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTREST": new FormControl({value: '', disabled: true}),
        "BUSTBUTY": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTBUTY": new FormControl({value: '', disabled: true}),
        "BUSTGRCY": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTGRCY": new FormControl({value: '', disabled: true}),
        "BUSTFSTF": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTFSTF": new FormControl({value: '', disabled: true}),
        "BUSTMNIM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTMNIM": new FormControl({value: '', disabled: true}),
        "BUSTLIQR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTLIQR": new FormControl({value: '', disabled: true}),
        "BUSTBAR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTBAR": new FormControl({value: '', disabled: true}),
        "BUSTMRCH": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTMRCH": new FormControl({value: '', disabled: true}),
        "BUSTCLTH": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_BUSTCLTH": new FormControl('')
      }),
      "LOCATION": new FormGroup({
        "LCTNCRNR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNCRNR": new FormControl({value: '', disabled: true}),
        "LCTNBUSD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNBUSD": new FormControl({value: '', disabled: true}),
        "LCTNINDD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNINDD": new FormControl({value: '', disabled: true}),
        "LCTNPARK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNPARK": new FormControl({value: '', disabled: true}),
        "LCTNBLOK": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNBLOK": new FormControl({value: '', disabled: true}),
        "LCTNSTRP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNSTRP": new FormControl({value: '', disabled: true}),
        "LCTNSHOP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNSHOP": new FormControl({value: '', disabled: true}),
        "LCTNPROF": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_LCTNPROF": new FormControl('')
      }),
      "INCLUDEDINSALE": new FormGroup({
        "INCLASST": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLASST": new FormControl({value: '', disabled: true}),
        "INCLBUSE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLBUSE": new FormControl({value: '', disabled: true}),
        "INCLINVN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLINVN": new FormControl({value: '', disabled: true}),
        "INCLFIXT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLFIXT": new FormControl({value: '', disabled: true}),
        "INCLEQIP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLEQIP": new FormControl({value: '', disabled: true}),
        "INCLNONC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLNONC": new FormControl({value: '', disabled: true}),
        "INCLTRNG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLTRNG": new FormControl({value: '', disabled: true}),
        "INCLLICN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLLICN": new FormControl({value: '', disabled: true}),
        "INCLLEAS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLLEAS": new FormControl({value: '', disabled: true}),
        "INCLPHON": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLPHON": new FormControl({value: '', disabled: true}),
        "INCLSECR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLSECR": new FormControl({value: '', disabled: true}),
        "INCLFRCH": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLFRCH": new FormControl({value: '', disabled: true}),
        "INCLGOOD": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_INCLGOOD": new FormControl({value: '', disabled: true}),
      }),
      "XLICBEER": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICBEER": new FormControl({value: '', disabled: true}),
      "XLICLIQR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICLIQR": new FormControl({value: '', disabled: true}),
      "XLICPROF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICPROF": new FormControl({value: '', disabled: true}),
      "XLICRSTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICRSTR": new FormControl({value: '', disabled: true}),
      "XLICPERM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICPERM": new FormControl({value: '', disabled: true}),
      "XLICBUSL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICBUSL": new FormControl({value: '', disabled: true}),
      "XLICONSL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICONSL": new FormControl({value: '', disabled: true}),
      "XLICOFSL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_XLICOFSL": new FormControl({value: '', disabled: true}),
      "FCLTREST": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTREST": new FormControl({value: '', disabled: true}),
      "FCLTDOOR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTDOOR": new FormControl({value: '', disabled: true}),
      "FCLTDOCK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTDOCK": new FormControl({value: '', disabled: true}),
      "FCLTSPRK": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTSPRK": new FormControl({value: '', disabled: true}),
      "FCLTSECS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTSECS": new FormControl({value: '', disabled: true}),
      "FCLTDISP": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTDISP": new FormControl({value: '', disabled: true}),
      "FCLTSIGN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTSIGN": new FormControl({value: '', disabled: true}),
      "FCLTHAND": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTHAND": new FormControl({value: '', disabled: true}),
      "FCLTELEV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_FCLTELEV": new FormControl({value: '', disabled: true}),
      "PRKS": new FormControl({value: '', disabled: true}),
      "0710PRKS": new FormControl({value: '', disabled: true}),
      "ADDITIONALPARKING": new FormGroup({
        "PARKUNCV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKUNCV": new FormControl({value: '', disabled: true}),
        "PARKCOVR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKCOVR": new FormControl({value: '', disabled: true}),
        "PARKGARG": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKGARG": new FormControl({value: '', disabled: true}),
        "PARKASSN": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKASSN": new FormControl({value: '', disabled: true}),
        "PARKSTRT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKSTRT": new FormControl({value: '', disabled: true}),
        "PARKNONE": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_PARKNONE": new FormControl({value: '', disabled: true}),
      }),
      "FEMP": new FormControl({value: '', disabled: true}),
      "0730FEMP": new FormControl({value: '', disabled: true}),
      "DOCUMENTSAVAILABLE": new FormGroup({
        "DILOPRLS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOPRLS": new FormControl({value: '', disabled: true}),
        "DILOAPPR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOAPPR": new FormControl({value: '', disabled: true}),
        "DILOFIXT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOFIXT": new FormControl({value: '', disabled: true}),
        "DILOLIQP": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOLIQP": new FormControl({value: '', disabled: true}),
        "DILOINVT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOINVT": new FormControl({value: '', disabled: true}),
        "DILOLEAS": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOLEAS": new FormControl({value: '', disabled: true}),
        "DILOITAX": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOITAX": new FormControl({value: '', disabled: true}),
        "DILOCASH": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILOCASH": new FormControl({value: '', disabled: true}),
        "DILONDOC": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_DILONDOC": new FormControl({value: '', disabled: true}),
      }),
      "SHOWINGINSTRUCTIONS": new FormGroup({
        "SHOWCALA": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCALA": new FormControl({value: '', disabled: true}),
        "SHOWDNDB": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWDNDB": new FormControl({value: '', disabled: true}),
        "SHOWDRIV": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWDRIV": new FormControl({value: '', disabled: true}),
        "SHOWCALO": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCALO": new FormControl({value: '', disabled: true}),
        "SHOWCALT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SHOWCALT": new FormControl({value: '', disabled: true}),
      }),
      "Showing_Instructions": new FormControl({value: '', disabled: true}),
      "GSAL": new FormControl({value: '', disabled: true}),
      "0820GSAL": new FormControl({value: '', disabled: true}),
      "GEXP": new FormControl({value: '', disabled: true}),
      "0830GEXP": new FormControl({value: '', disabled: true}),
      "NINC": new FormControl({value: '', disabled: true}),
      "0840NINC": new FormControl({value: '', disabled: true}),
      "REIN": new FormControl({value: '', disabled: true}),
      "0850REIN": new FormControl({value: '', disabled: true}),
      "REAL": new FormControl({value: '', disabled: true}),
      "0860REAL": new FormControl({value: '', disabled: true}),
      "FRCH": new FormControl({value: '', disabled: true}),
      "0870FRCH": new FormControl({value: '', disabled: true}),
      "CHAN": new FormControl({value: '', disabled: true}),
      "0880CHAN": new FormControl({value: '', disabled: true}),
      "0890EQTY": new FormControl({value: '', disabled: true}),
      "LEASSR8T": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASSR8T": new FormControl({value: '', disabled: true}),
      "LEASMTOM": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASMTOM": new FormControl({value: '', disabled: true}),
      "LEASPRCT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASPRCT": new FormControl({value: '', disabled: true}),
      "LEASESCL": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASESCL": new FormControl({value: '', disabled: true}),
      "LEASRENW": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASRENW": new FormControl({value: '', disabled: true}),
      "LEASPRCH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASPRCH": new FormControl({value: '', disabled: true}),
      "LEASNET": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASNET": new FormControl({value: '', disabled: true}),
      "LEASTNET": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASTNET": new FormControl({value: '', disabled: true}),
      "LEASASSN": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASASSN": new FormControl({value: '', disabled: true}),
      "LEASNEGO": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_LEASNEGO": new FormControl({value: '', disabled: true}),
      "LRMN": new FormControl({value: '', disabled: true}),
      "0910LRMN": new FormControl({value: '', disabled: true}),
      "FORM": new FormControl({value: '', disabled: true}),
      "0920FORM": new FormControl({value: '', disabled: true}),
      "SOURCEOFDATA": new FormGroup({
        "SOFDTAXR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SOFDTAXR": new FormControl({value: '', disabled: true}),
        "SOFDACCT": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SOFDACCT": new FormControl({value: '', disabled: true}),
        "SOFDOWNR": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SOFDOWNR": new FormControl({value: '', disabled: true}),
        "SOFDESTM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SOFDESTM": new FormControl({value: '', disabled: true}),
        "SOFDPROJ": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SOFDPROJ": new FormControl({value: '', disabled: true}),
        "SOFDINCM": new FormControl({value: '', disabled: true}),
        "Checkbox_Comment_SOFDINCM": new FormControl({value: '', disabled: true}),
      }),
      "TEXPGAS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPGAS": new FormControl({value: '', disabled: true}),
      "TEXPELEC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPELEC": new FormControl({value: '', disabled: true}),
      "TEXPTRSH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPTRSH": new FormControl({value: '', disabled: true}),
      "TEXPWATR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPWATR": new FormControl({value: '', disabled: true}),
      "TEXPSEWR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPSEWR": new FormControl({value: '', disabled: true}),
      "TEXPTAXS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPTAXS": new FormControl({value: '', disabled: true}),
      "TEXPJNTR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPJNTR": new FormControl({value: '', disabled: true}),
      "TEXPIMNT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPIMNT": new FormControl({value: '', disabled: true}),
      "TEXPEMNT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPEMNT": new FormControl({value: '', disabled: true}),
      "TEXPSMNT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPSMNT": new FormControl({value: '', disabled: true}),
      "TEXPCMNT": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPCMNT": new FormControl({value: '', disabled: true}),
      "TEXPIVAC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPIVAC": new FormControl({value: '', disabled: true}),
      "TEXPROOF": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPROOF": new FormControl({value: '', disabled: true}),
      "TEXPFIRE": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPFIRE": new FormControl({value: '', disabled: true}),
      "TEXPLIAB": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPLIAB": new FormControl({value: '', disabled: true}),
      "TEXPGLAS": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPGLAS": new FormControl({value: '', disabled: true}),
      "TEXPELEV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPELEV": new FormControl({value: '', disabled: true}),
      "TEXPTXEC": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPTXEC": new FormControl({value: '', disabled: true}),
      "TEXPMRCH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TEXPMRCH": new FormControl({value: '', disabled: true}),
      "TERMCONV": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMCONV": new FormControl({value: '', disabled: true}),
      "TERMSBA": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMSBA": new FormControl({value: '', disabled: true}),
      "TERMOWNR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMOWNR": new FormControl({value: '', disabled: true}),
      "TERMCASH": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMCASH": new FormControl({value: '', disabled: true}),
      "TERMOTHR": new FormControl({value: '', disabled: true}),
      "Checkbox_Comment_TERMOTHR": new FormControl('')
    }, { updateOn: 'change' });

    this.subscription = this.response$
    .pipe(
      catchError(err => {
        console.log(err);
        if(err.error.data) {
          const data = err.error.data;
          this.form.patchValue(data.data);

          this.response = data;

          return err;
        }
      })
    )
    .subscribe((res: any) => {
      this.form.patchValue(res.data);
      this.response = res;
    });
  }

  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  close() {
    this.ngbModal.dismissAll();
  }

  download() {
    this.isDownloading = true;
    document.querySelector(".printable-form").classList.add('p-5');

    var textareas = document.getElementsByTagName('textarea');
    for(let i = 0; i < textareas.length; i++) {
      let parent = textareas[i].parentElement;
      let formId = textareas[i].id;

      let newDiv = document.createElement('div');
      newDiv.classList.add('form-control');
      newDiv.style.backgroundColor = '#f1f1f2'
      newDiv.style.height = 'auto';
      newDiv.id = formId + '-print';
      newDiv.innerHTML = this.form.get( formId).value;

      textareas[i].classList.add('d-none');
      parent.appendChild(newDiv);
    }

    html2canvas(document.querySelector(".printable-form")).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm');
      var position = 0;
      
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, 'Farm', 'FAST');
        heightLeft -= pageHeight;
      }
      var randName = Math.random().toString(36).substr(2, 5)
      doc.save(randName + '.pdf');
      document.querySelector(".printable-form").classList.remove('p-5');
      for(let i = 0; i < textareas.length; i++) {
        textareas[i].classList.remove('d-none');
        document.getElementById(textareas[i].id + '-print').remove();
      }
      this.isDownloading = false;
    });
  }

}
